import {useLocation} from 'react-router-dom';
import {RouteName} from 'src/constants/routeName';

const useIsCpaCenterV2 = () => {
  const {pathname} = useLocation();
  const isCpaCenterV2 = pathname.includes(RouteName.CpaCenterV2);

  const getCpaCenterV2Route = (rawRoute: string) => {
    if (isCpaCenterV2) {
      return `${RouteName.CpaCenterV2}${rawRoute}`;
    }
    return rawRoute;
  };

  return {
    isCpaCenterV2,
    getCpaCenterV2Route,
  };
};

export default useIsCpaCenterV2;
