import {Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {colorFader, getDateDifference} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import ReviewAndEsignContext, {ReviewAndEsignQuery} from '../../ReviewAndEsignContext';
import ReactHtmlParser from 'react-html-parser';

interface RenderQueryBoxProps extends ReviewAndEsignQuery {
  isFaded: boolean;
}

const RenderQueryBox: React.FC<RenderQueryBoxProps> = ({
  title,
  messages,
  status,
  queryId,
  timestamp,
  isFaded,
}) => {
  const {flyfin_user_id, docId} = useParams();
  const lastMessage = messages?.[messages.length - 1];
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const navigateToSelectedQueryView = () => {
    if (!!flyfin_user_id && !!docId) {
      navigate(queryId.toString());
    }
  };

  return (
    <div
      onClick={navigateToSelectedQueryView}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        padding: '8px 12px',
        backgroundColor: colorFader(
          isHovered ? themmeColor.offWhite : themmeColor.white,
          isFaded,
        ),
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: themmeColor.offWhite,
      }}>
      <Typography
        color={colorFader(themmeColor.black, isFaded)}
        fontWeight={'600'}>
        {title}
      </Typography>
      <div style={{display: 'flex', marginTop: 8, marginBottom: 8}}>
        <Typography
          style={{marginRight: 4}}
          color={colorFader(
            !lastMessage?.fromUs ? themmeColor.errorRed : themmeColor.black,
            isFaded,
          )}
          fontWeight={'600'}>
          {!lastMessage?.fromUs ? 'User:' : 'You:'}
        </Typography>
        <div>{ReactHtmlParser(lastMessage?.text)}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          fontWeight={'600'}
          fontSize={10}
          color={colorFader(themmeColor.flyfinYellow, isFaded)}>
          {status}
        </Typography>
        <Typography
          fontSize={13}
          color={colorFader(themmeColor.silver, isFaded)}>
          {getDateDifference(timestamp)}
        </Typography>
      </div>
    </div>
  );
};

const REAllQueries = () => {
  const {docId} = useParams();
  const {documents} = useContext(ReviewAndEsignContext);

  const selectedDocumentId = docId
    ? parseInt(docId, 10)
    : undefined;

  const selectedDocumentObject = documents?.filter?.(
    (document) => document.documentId === selectedDocumentId,
  )?.[0];

  if (!selectedDocumentId || !selectedDocumentObject) return <></>;
  if (
    !selectedDocumentObject.queries ||
    selectedDocumentObject.queries.length === 0
  )
    return <></>;

  const queries = selectedDocumentObject.queries;

  const resolved = queries.filter((query) => query.resolved);
  const unresolved = queries.filter((query) => !query.resolved);
  return (
    <div>
      <div
        style={{
          backgroundColor: themmeColor.focusedBlue,
          height: 42,
          paddingLeft: 16,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Typography
          fontWeight={'600'}
          style={{display: 'flex'}}
          color={themmeColor.errorRed}>
          {queries.filter((query) => query.resolved).length}/{queries.length}
        </Typography>
        <Typography fontWeight={'600'} color={themmeColor.black}>
          {` - User Questions addressed`.toUpperCase()}
        </Typography>
      </div>
      <div style={{height: '75vh', overflowY: 'auto'}}>
        <div>
          {unresolved.map((query) => (
            <RenderQueryBox isFaded={false} {...query} />
          ))}
        </div>
        <div>
          <div
            style={{
              paddingLeft: 16,
              height: 32,
              alignItems: 'center',
              display: 'flex',
              backgroundColor: themmeColor.focusedBlue,
            }}>
            <Typography
              fontSize={12}
              fontWeight={'600'}
              textTransform={'uppercase'}>
              Resolved Queries
            </Typography>
          </div>
          <div>
            {resolved.map((query) => (
              <RenderQueryBox isFaded {...query} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default REAllQueries;
