import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {FileUploader} from 'react-drag-drop-files';
import Box from 'src/DesignSystem/Box/Box';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {themmeColor} from 'src/constants/constants';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import DSButton from 'src/DesignSystem/Button/Button';
import DeleteIcon from 'src/icons/DeleteIcon';

const DEFAULT_SUPPORTED_FILE_TYPES = ['pdf', 'jpg', 'png', 'jpeg'];

interface MultiDocumentUploadProps {
  files: any[];
  setFiles: React.Dispatch<any>;
}

const MultiDocumentUpload = ({files, setFiles}: MultiDocumentUploadProps) => {
  const [isDropping, setIsDropping] = useState(false);

  const selectFile = (newFiles: any) => {
    setFiles((prev: any[]) => [...prev, ...newFiles]);
  };

  const onDelete = (index: number) => {
    setFiles((prev: any[]) => prev.filter((_, i) => i !== index));
  };

  const {notify} = useNotify();
  const notifyFileTypeError = (err: string) => {
    notify(
      `${err}, Supported type ${DEFAULT_SUPPORTED_FILE_TYPES.join(', ')}`,
      NotificationType.error,
    );
  };

  const atLeastOneFileIsSelected = files?.length > 0;

  return (
    <FileUploader
      multiple={true}
      dropMessageStyle={{backgroundColor: '#00000000'}}
      hoverTitle={<h2 color={themmeColor.black}>Drop the file</h2>}
      onDraggingStateChange={setIsDropping}
      types={DEFAULT_SUPPORTED_FILE_TYPES}
      onTypeError={notifyFileTypeError}
      handleChange={selectFile}>
      <Box
        style={{
          flexDirection: 'column',
          justifyContent: atLeastOneFileIsSelected ? undefined : 'center',
          alignItems: atLeastOneFileIsSelected ? undefined : 'center',
          backgroundColor: themmeColor.cpaCenterV2Bg,
          height: '100%',
          opacity: isDropping ? 0.1 : 1,
        }}>
        {atLeastOneFileIsSelected ? (
          <div
            style={{
              padding: 16,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              maxHeight: '100%',
            }}>
            <Typography style={{fontSize: 20, fontWeight: 800}}>
              Selected Files
            </Typography>
            <div style={{overflowY: 'auto'}}>
              {files?.map((file, index) => {
                return (
                  <Box
                    style={{
                      marginTop: 12,
                      padding: 12,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography style={{fontWeight: 500}}>
                      {file.name}
                    </Typography>
                    <div
                      onClickCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(index);
                      }}>
                      <DeleteIcon />
                    </div>
                  </Box>
                );
              })}
            </div>
          </div>
        ) : (
          <>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 12}}>
                <UploadCloudIcon />
              </div>
              <Typography style={{fontWeight: 600}}>
                Drag & drop the files
              </Typography>
            </div>
            <Typography style={{marginBottom: 12}}>OR</Typography>
            <DSButton
              type="primary"
              onClick={() => {}}
              text="Select file"
              style={{width: '30%'}}
            />
          </>
        )}
      </Box>
    </FileUploader>
  );
};

export default MultiDocumentUpload;
