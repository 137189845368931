import React from 'react';

interface CpaListContextProps {
  fetchUserList: () => Promise<any>;
}

// @ts-ignore
const CpaListContext = React.createContext<CpaListContextProps>(null);

export default CpaListContext;
