import {isNumber, isObject} from 'lodash';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {
  BusinessDetail,
  BusinessDetailsField,
} from 'src/store/businessDetails/businessDetails.types';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {TaxFormFieldIds, TaxFormType} from 'src/store/taxForms/taxForms.types';
import BusinessVehicleDetails from './BusinessVehicleDetails';
import {filterProfitAndLossExpenseField} from 'src/store/taxForms/taxForms.utils';
import SingleBusinessDeductionsTable from './SingleBusinessDeductionsTable';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';

interface SingleBusinessDeductionsProps {
  biz: BusinessDetail;
}

const SingleBusinessDeductions = ({biz}: SingleBusinessDeductionsProps) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);

  const {navigateToDocumentReview} = usePartnershipNavigation();

  const {onAddTaxForm, onEditTaxForm} = usePartnerTaxProfileReview();

  const linkedProfitLossTF = useMemo(() => {
    return taxForms.filter(
      (tf) =>
        tf.formType === TaxFormType.PROFIT_LOSS_FORM &&
        tf.linked_business_id === biz[BusinessDetailsField.id],
    )?.[0];
  }, [biz, taxForms]);

  const profitLossDoc = useMemo(() => {
    if (isObject(linkedProfitLossTF) && isNumber(linkedProfitLossTF.docId)) {
      return documents.filter(
        (doc) => doc.docId === linkedProfitLossTF.docId,
      )?.[0];
    }
    return undefined;
  }, [documents, linkedProfitLossTF]);

  const linkedBusinessVehicles = useMemo(() => {
    return taxForms.filter((tf) => {
      return (
        tf.formType === TaxFormType.BUSINESS_VEHICLE &&
        tf.linked_business_id === biz[BusinessDetailsField.id]
      );
    });
  }, [taxForms, biz]);

  const onEditProfitAndLossForm = () => {
    if (isObject(linkedProfitLossTF)) {
      onEditTaxForm({
        taxFormId: linkedProfitLossTF.taxFormId,
        taxFormType: linkedProfitLossTF.formType,
        title: 'Edit expenses',
        filterFields: filterProfitAndLossExpenseField,
      });
    } else {
      onAddTaxForm({
        taxFormType: TaxFormType.PROFIT_LOSS_FORM,
        title: 'Add expenses',
        multi_tax_forms_entity_id: biz[BusinessDetailsField.id],
        filterFields: filterProfitAndLossExpenseField,
      });
    }
  };

  const onAddVehicle = () => {
    onAddTaxForm({
      taxFormType: TaxFormType.BUSINESS_VEHICLE,
      title: 'Add vehicle',
      multi_tax_forms_entity_id: biz[BusinessDetailsField.id],
    });
  };

  return (
    <DSAccordion
      name={`${biz[BusinessDetailsField.name]} (${
        biz[BusinessDetailsField.entity_type]
      })`}
      defaultExpanded>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={`${biz[BusinessDetailsField.name]} (${
            biz[BusinessDetailsField.entity_type]
          })`}
        />
        <DSButton
          type="primary"
          text={isObject(linkedProfitLossTF) ? 'Edit' : 'Add'}
          onClick={onEditProfitAndLossForm}
        />
      </div>
      {isObject(profitLossDoc) ? (
        <>
          <Text
            type={TEXT_TYPES.BASE}
            text={'Related documents'}
            containerStyle={{marginTop: 12}}
          />
          <DocumentPointer
            doc={profitLossDoc}
            dontShowStatus
            border
            onDocumentClick={() =>
              navigateToDocumentReview(profitLossDoc.docId)
            }
          />
        </>
      ) : null}

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginBlock: 12,
          }}>
          <Text type={TEXT_TYPES.BASE} text={'Business vehicle details'} />
          <DSButton
            type="secondary"
            text={'Add vehicle'}
            onClick={onAddVehicle}
          />
        </div>
        <div>
          {linkedBusinessVehicles.map((vehicle, index) => (
            <BusinessVehicleDetails
              key={vehicle.docId}
              index={index}
              vehicleTaxForm={vehicle}
            />
          ))}
        </div>
      </div>
      <div>
        <SingleBusinessDeductionsTable
          form={linkedProfitLossTF}
          headerName="Expenses"
        />
      </div>
    </DSAccordion>
  );
};

export default SingleBusinessDeductions;
