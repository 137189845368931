import React from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {
  DOCUMENT_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import SubSectionBox from '../../common/SubSectionBox';
import {ReduxStateType} from 'src/store/store';
import {mapTaxProfileSectionToIncomeSource} from '../Income.constants';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const CryptoIncome = () => {
  const {activeYear} = useActiveYear();
  const earnedCryptoIncome = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.EARNED_FROM_DIGITAL_ASSETS,
      activeYear,
    ),
  );
  const userAddedIncomes =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.TAX_PROFILE_INCOME_SOURCES,
        activeYear,
      ),
    ) ?? [];

  const {documents} = useSelector(selectDocuments);

  const digitalAssetDocuments = documents.filter(
    (doc) => doc.docType === DOCUMENT_TYPES.DIGITAL_ASSETS,
  );
  const subsection = TaxProfileSubsection.DigitalAssetIncome;

  return (
    <SubSectionBox
      name="Crypto Income"
      taxprofileSubsection={subsection}
      querieNotAllowed={
        !userAddedIncomes.includes(
          mapTaxProfileSectionToIncomeSource[subsection] ?? '',
        )
      }>
      <SimpleKeyValueField
        name={`Did you receive, sell, exchange a digital asset (commonly called cryptos) anytime during ${activeYear}?`}
        value={earnedCryptoIncome}
      />
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.black60,
          marginBottom: 4,
        }}>
        Digital asset documents
      </Typography>
      {digitalAssetDocuments.map((doc) => (
        <DocumentPointer doc={doc} dontShowStatus border />
      ))}
    </SubSectionBox>
  );
};

export default CryptoIncome;
