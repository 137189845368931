import {ReduxStateType} from '../store';
import {TaxReturn} from '../taxReturns/taxReturns.reducer';
import {EfileLog} from './efileLogs.reducer';

export const selectEfileLogsForCurrReturn = (
  state: ReduxStateType,
  currentReturn: Pick<TaxReturn, 'return_id' | 'return_type'>,
) => {
  return {
    efileLogs:
      state.efileLogs.efileLogs.find(
        (efileData) =>
          efileData.return_id === currentReturn.return_id &&
          efileData.return_type === currentReturn.return_type,
      )?.logs ?? ([] as EfileLog[]),
    loaded: state.efileLogs.loaded,
  };
};
