import React from 'react';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const StudentLoan = () => {
  const {activeYear} = useActiveYear();
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const paidStudentLoan = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.PAID_STUDENT_LOAN,
      activeYear,
    ),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const studentLoanTF = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.FORM_1098_E,
  );

  return (
    <SubSectionBox
      name="Student loan"
      taxprofileSubsection={TaxProfileSubsection.StudentLoan}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name={`Did you pay any student loans in ${activeYear}?`}
            value={paidStudentLoan}
          />
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.black60,
              marginBottom: 4,
            }}>
            Related document
          </Typography>
          {studentLoanTF.map((tf) => (
            <DocumentPointer
              doc={documents.find((doc) => doc.docId === tf.docId)}
              taxform={tf}
              border
            />
          ))}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.StudentLoan}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default StudentLoan;
