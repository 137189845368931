import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InlineNotification from 'src/DesignSystem/InlineNotification/InlineNotification';
import {InlineNotificationType} from 'src/DesignSystem/InlineNotification/InlineNotification.types';
import {selectDeductionsChangelog} from 'src/store/deductionsChangelog/deductionsChangelog.selector';
import WarningIcon from 'src/icons/WarningIcon';
import DSButton from 'src/DesignSystem/Button/Button';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {DeductionChangelog} from 'src/store/deductionsChangelog/deductionsChangelog.reducer';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {themmeColor} from 'src/constants/constants';
import {CurrencyNumberFormat} from 'src/common/utils';
import {updateDeductionSnapshot} from 'src/appApi';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {fetchDeductionsChangelog} from 'src/store/deductionsChangelog/deductionsChangelog.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';

const DeductionsChangedNudge = () => {
  const {changelog} = useSelector(selectDeductionsChangelog);
  const [changelogPopup, setChangelogPopup] = useState(false);
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  if (changelog.length === 0) {
    return null;
  }

  const onMarkAsUpdated = async () => {
    try {
      setIsLoading(true);
      await updateDeductionSnapshot(userId, activeYear);
      await dispatch(fetchDeductionsChangelog(userId, activeYear));
      notify('Successfully marked as updated', NotificationType.success);
    } catch (e) {
      notify('Failed to mark as updated', NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FullScreenLoading open={isLoading} />
      <div style={{marginInline: 16, marginBottom: 12, alignItems: 'center'}}>
        <InlineNotification
          text="User has made changes to deduction."
          variant={InlineNotificationType.warning}
          icon={<WarningIcon />}
          content={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 1,
              }}>
              <DSButton
                type="secondary"
                text="View"
                onClick={() => {
                  setChangelogPopup(true);
                }}
                style={{marginRight: 12}}
                disabled={isLoading}
              />
              <DSButton
                type="primary"
                text="Mark as updated"
                onClick={onMarkAsUpdated}
                disabled={isLoading}
              />
            </div>
          }
        />
      </div>
      <PopUp
        style={{width: '70vw'}}
        isOpen={changelogPopup}
        onClose={() => setChangelogPopup(false)}>
        <div>
          <Text
            type={TEXT_TYPES.L}
            text={'These deduction categories have changes.'}
          />
          <div style={{overflowY: 'auto', maxHeight: 500, marginTop: 8}}>
            <KeyValueTable
              data={changelog}
              keys={[
                {
                  getValue: (row: DeductionChangelog) => row.name,
                },
                {
                  getValue: (row: DeductionChangelog) =>
                    CurrencyNumberFormat(row.amount_difference),
                  getIcon: (row: DeductionChangelog) =>
                    row.amount_difference >= 0 ? (
                      <ArrowUpward style={{color: themmeColor.successGreen}} />
                    ) : (
                      <ArrowDownward style={{color: themmeColor.errorRed}} />
                    ),
                  cellStyle: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  },
                  textStyle: {
                    fontWeight: 600,
                  },
                },
              ]}
            />
          </div>
        </div>
      </PopUp>
    </>
  );
};

export default DeductionsChangedNudge;
