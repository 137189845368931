import Typography from '@mui/material/Typography/Typography';
import {StatesInfo} from 'src/appApi.types';
import {themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';

interface StatesWithIncomeProps {
  statesWithIncomeDocs: StatesInfo[];
  isLoading: boolean;
  documents: ReduxDocumentType[];
}

const StatesWithIncome = ({
  statesWithIncomeDocs,
  isLoading,
  documents,
}: StatesWithIncomeProps) => {
  return isLoading ? (
    <Skeleton width={'100%'} height={'20vh'} />
  ) : (
    <Box backgroundColor={themmeColor.cpaCenterV2Bg} col>
      {statesWithIncomeDocs.map((state) => {
        const {state: stateName, related_income_forms} = state;
        return (
          <div style={{padding: 12}}>
            <Typography style={{marginBottom: 8}}>{stateName}</Typography>
            {related_income_forms.map((form) => {
              const doc = documents.find((doc) => doc.docId === form.doc_id);
              return !doc ? (
                <Typography>
                  {form.form_type} with no linked document
                </Typography>
              ) : (
                <DocumentPointer
                  doc={doc as ReduxDocumentType}
                  dontShowStatus
                  border
                />
              );
            })}
          </div>
        );
      })}
    </Box>
  );
};

export default StatesWithIncome;
