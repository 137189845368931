import {useParams} from 'react-router-dom';

const useCurrentQueryId = () => {
  const {queryId: rawQueryId} = useParams();
  let queryId = null;
  if (rawQueryId) {
    queryId = parseInt(rawQueryId, 10);
  }
  return {
    queryId,
  };
};

export default useCurrentQueryId;

