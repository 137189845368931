import {
  PartnerTaxProfileSectionToSubsectionMapping,
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {RouteName} from 'src/constants/routeName';
import {
  BusinessDetail,
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';

import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const getTaxProfileSectionFromSubsection = (
  subsection: TaxProfileSubsection,
) => {
  const TaxProfileSectionToSubsection = isPartnership()
    ? PartnerTaxProfileSectionToSubsectionMapping
    : TaxProfileSectionToSubsectionMapping;
  const sections = Object.keys(TaxProfileSectionToSubsection);
  let targetSection = 'batman';
  sections.forEach((section: TaxProfileSection) => {
    if (TaxProfileSectionToSubsectionMapping[section].includes(subsection)) {
      targetSection = section;
    }
  });
  return targetSection as TaxProfileSection;
};

export const getFieldValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return 'NA';
  }
  return value;
};

export const getReturnName = (
  taxReturn: TaxReturn,
  businesses: BusinessDetail[],
) => {
  if (taxReturn.return_type === TaxReturnType.INDIVIDUAL) {
    return 'Individual Return';
  } else if (taxReturn.return_type === TaxReturnType.BUSINESS) {
    const business = businesses.find(
      (data) => data[BusinessDetailsField.id] === taxReturn.business_id,
    );
    return `Business Return - ${business?.[BusinessDetailsField.name]} (${
      business?.[BusinessDetailsField.entity_type]
    })`;
  }

  return '';
};

export const shouldShowPaymentDetails = ({
  taxReturn,
  businesses,
}: {
  taxReturn: TaxReturn;
  businesses: BusinessDetail[];
}) => {
  const currBiz = businesses.find(
    (biz) => biz[BusinessDetailsField.id] === taxReturn.business_id,
  );
  if (taxReturn.return_type === TaxReturnType.BUSINESS) {
    const noPaymentDetailsEntity = [
      BusinessType.S_CORP,
      BusinessType.PARTNERSHIP,
    ];
    if (
      currBiz &&
      noPaymentDetailsEntity.includes(
        currBiz[BusinessDetailsField.entity_type],
      )
    ) {
      return false;
    }
  }

  if (
    taxReturn.return_type === TaxReturnType.INDIVIDUAL ||
    (taxReturn.return_type === TaxReturnType.BUSINESS &&
      currBiz &&
      currBiz[BusinessDetailsField.entity_type] === BusinessType.C_CORP)
  ) {
    const atleastOneRefund = taxReturn.refund_and_due.refunds.log.some(
      (log) => log.amount !== 0,
    );
    const atleastOneDue = taxReturn.refund_and_due.dues.log.some(
      (log) => log.amount !== 0,
    );
    if (!atleastOneRefund && !atleastOneDue) {
      return false;
    }
  }

  return true;
};

export const isPartnership = () => {
  const url = window.location;
  return url.pathname.includes(RouteName.Partnership);
};

export const navigateWithinCPACenter = (
  partnershipFn: Function,
  consumerFn: Function,
) => {
  isPartnership() ? partnershipFn() : consumerFn();
};
