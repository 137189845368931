import {parseISO, isValid as isValidDate} from 'date-fns';

export enum VALIDATOR {
  NON_EMPTY_STRING = 'NON_EMPTY_STRING',
  NON_EMPTY_ARRAY = 'NON_EMPTY_ARRAY',
  VALIDATE_EIN = 'VALIDATE_EIN',
  NON_NULL_VALUE = 'NON_NULL_VALUE',
  VALIDATE_ISO_DATE = 'VALIDATE_ISO_DATE',
  NO_FUTURE_DATE = 'NO_FUTURE_DATE',
  VALIDATE_BOOLEAN = 'VALIDATE_BOOLEAN',
  VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER',
  VALIDATE_ZIP_CODE = 'VALIDATE_ZIP_CODE',
  VALIDATE_SSN = 'VALIDATE_SSN',
  NO_MORE_THAN_100 = 'NO_MORE_THAN_100',
}

const validateNonEmptyString = (value: any) => {
  const isValid = typeof value === 'string' && value.trimStart().length > 0;
  if (!isValid) {
    return {isValid, message: 'must be non-empty string'};
  }
  return {isValid, message: null};
};

const validateNonEmptyArray = (value: any) => {
  const isValid = Array.isArray(value) && value.length > 0;
  if (!isValid) {
    return {isValid, message: 'must select atleast one'};
  }
  return {isValid, message: null};
};

const validateEIN = (value: any) => {
  const isValid = typeof value === 'string' && /^[1-9]\d-\d{7}/.test(value);
  if (!isValid) {
    return {isValid, message: 'Incorrect EIN format'};
  }
  return {isValid, message: null};
};

const nonNullValue = (value: any) => {
  const isValid = value !== null && value !== undefined;
  if (!isValid) {
    return {isValid, message: 'value cannot be null or undefined'};
  }
  return {isValid, message: null};
};

const validateISODate = (value: any) => {
  const isValid = typeof value === 'string' && isValidDate(parseISO(value));
  if (!isValid) {
    return {isValid, message: 'value must be a valid date'};
  }
  return {isValid, message: null};
};

const noFutureDate = (value: any) => {
  const validateDate = validateISODate(value);
  if (!validateDate.isValid) {
    return validateDate;
  }
  const isValid = parseISO(value).getTime() <= new Date().getTime();
  return {
    isValid,
    message: isValid ? null : 'Cannot be a future date value',
  };
};

const validateBoolean = (value: any) => {
  const isValid = typeof value === 'boolean';
  return {
    isValid,
    message: isValid ? null : 'Must be either true or false',
  };
};

const validatePhoneNumber = (value: any) => {
  const reg = /^\(\d{3}\) \d{3}-\d{4}/;
  const isValid = typeof value === 'string' && reg.test(value);
  return {
    isValid,
    message: isValid ? null : 'Invalid phone number format',
  };
};

const validateZipCode = (value: any) => {
  const isValid =
    typeof value === 'string' && /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
  return {
    isValid,
    message: isValid ? null : 'Invalid zip code format',
  };
};

const validateSSN = (value: any) => {
  const isValid =
    typeof value === 'string' &&
    /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value);
  return {
    isValid,
    message: isValid ? null : 'Invalid ssn code format',
  };
};

const noMoreTham100 = (value: any) => {
  const isValid = typeof value === 'number' && value <= 100;
  return {
    isValid,
    message: isValid ? null : 'Value should be number and atmost 100',
  };
};

const VALIDATOR_MAP: {
  [key in VALIDATOR]: (value: any) => {
    isValid: boolean;
    message: string | null;
  };
} = {
  [VALIDATOR.NON_EMPTY_STRING]: validateNonEmptyString,
  [VALIDATOR.NON_EMPTY_ARRAY]: validateNonEmptyArray,
  [VALIDATOR.VALIDATE_EIN]: validateEIN,
  [VALIDATOR.NON_NULL_VALUE]: nonNullValue,
  [VALIDATOR.VALIDATE_ISO_DATE]: validateISODate,
  [VALIDATOR.NO_FUTURE_DATE]: noFutureDate,
  [VALIDATOR.VALIDATE_BOOLEAN]: validateBoolean,
  [VALIDATOR.VALIDATE_PHONE_NUMBER]: validatePhoneNumber,
  [VALIDATOR.VALIDATE_ZIP_CODE]: validateZipCode,
  [VALIDATOR.VALIDATE_SSN]: validateSSN,
  [VALIDATOR.NO_MORE_THAN_100]: noMoreTham100,
};

export const getValidatorFunctions = (validators: VALIDATOR[]) => {
  return validators.map((validator) => VALIDATOR_MAP[validator]);
};
