import {BusinessDetail} from './businessDetails.types';
import {SET_BUSINESS_DETAILS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export enum BUSINESS_DETAILS_INPUT_TYPE {
  ARRAY = 'ARRAY', // STRING OF ARRAY
  DEFAULT = 'DEFAULT', // PRIMITIVE
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS', // ARRAY OF OBJECTS
  SHAREHOLDER_ADDRESS = 'SHAREHOLDER_ADDRESS', // value is array of objects
  SINGLE_SHAREHOLDER = 'SINGLE_SHAREHOLDER', // value is array of objects
  MULTIPLE_SHAREHOLDERS = 'MULTIPLE_SHAREHOLDERS', // ARRAY of objects, WHERE VALUE of each objecs is also array
  MULTIPLE_DOCUMENTS = 'MULTIPLE_DOCUMENTS',
  SINGLE_DOCUMENT = 'SINGLE_DOCUMENT',
}

interface CommonFieldType {
  name: string;
}

interface DefaultFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT;
  value: string | number | boolean | undefined | null | any;
}

interface ArrayFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY;
  value: string[];
}
interface BusinessAddressFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS;
  value: DefaultFieldType[];
}

interface ShareHolderAddressFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.SHAREHOLDER_ADDRESS;
  value: DefaultFieldType[];
}

interface SingleShareHolderFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_SHAREHOLDER;
  value: (DefaultFieldType | ShareHolderAddressFieldType)[];
}

interface SingleDocumentFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_DOCUMENT;
  value: number;
}

interface MultipleDocumentFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS;
  value: SingleDocumentFieldType[];
}

export interface MultipleShareHolderFieldType extends CommonFieldType {
  type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS;
  value: SingleShareHolderFieldType[];
}

export type FinalFieldType =
  | DefaultFieldType
  | ArrayFieldType
  | BusinessAddressFieldType
  | MultipleShareHolderFieldType
  | MultipleDocumentFieldType;

const initialState = {
  businesses: [] as BusinessDetail[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const businessDetails = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_BUSINESS_DETAILS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type BusinessDetailsStateType = typeof initialState;
