import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
  useMutation,
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
  GET_MANY,
} from 'react-admin';

const BulkReviewButton = ({
  selectedIds,
  label,
  className,
  doRefresh = true,
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const {resource} = useListContext();
  const [refreshTxns, {}] = useMutation({
    type: GET_MANY,
    resource: resource,
    payload: {ids: selectedIds},
  });
  const [bulkReview, {loading}] = useMutation(
    {
      type: 'bulk_review',
      resource: 'expenses',
      payload: {ids: selectedIds},
    },
    {
      onSuccess: () => {
        if (doRefresh === true) refresh();
        else refreshTxns();
        unselectAll(resource);
        notify('Bulk Review successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(`Bulk Review failed!: ${error.body['display_msg']}`, 'warning');
      },
    },
  );

  return (
    <Button
      className={className}
      onClick={() => bulkReview()}
      variant="outlined"
      color="primary">
      {label}
    </Button>
  );
};

export default BulkReviewButton;
