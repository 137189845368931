import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxProfileSubsection} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {TaxProfileField} from 'src/store/taxProfile/taxProfile.types';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {EducationalCreditsChoices} from '../Credits.constants';
import {selectDeductionSummary} from 'src/store/deductionSummary/deductionSummary.selector';
import {IRSCategory} from 'src/store/transactions/transactions.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {selectTaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {TaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

export const useEducationCredits = () => {
  const {loaded: taxFormsLoaded, taxForms} = useSelector(
    selectTaxFormsReducer,
  );
  const {loaded: documentsLoaded, documents} = useSelector(selectDocuments);
  const {taxProfileUserSideStatus, loaded: taxProfileStatusLoaded} =
    useSelector(selectTaxProfileUserSideStatus);
  const {activeYear} = useActiveYear();

  const showEducationCredits =
    taxProfileStatusLoaded &&
    ![
      TaxProfileUserSideStatus.NOT_APPLICABLE,
      TaxProfileUserSideStatus.NOT_INITIATED,
    ].includes(
      taxProfileUserSideStatus[TaxProfileSubsection.EducationalCredits]
        ?.status ?? TaxProfileUserSideStatus.NOT_APPLICABLE,
    );

  const selfEducationCredits: EducationalCreditsChoices[] =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.SELF_EDUCATIONAL_CREDITS,
        activeYear,
      ),
    ) ?? [];
  const isSelfStudent =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(state, TaxProfileQuestion.IS_STUDENT, activeYear),
    ) ?? false;
  const spouseEducationCredits: EducationalCreditsChoices[] =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.SPOUSE_EDUCATIONAL_CREDITS,
        activeYear,
      ),
    ) ?? [];
  const isSpouseStudent =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TaxProfileQuestion.IS_SPOUSE_STUDENT,
        activeYear,
      ),
    ) ?? false;
  const deductionSummary = useSelector(selectDeductionSummary);

  const educationExpenses = useMemo(() => {
    const amount = deductionSummary.sch_3_category_wise_data
      .filter(
        ({category_display_name}) =>
          category_display_name === IRSCategory.TutionFees,
      )
      .reduce((prev, deduction) => {
        return prev + (deduction?.amount ?? 0);
      }, 0);

    return amount;
  }, [deductionSummary]);

  const legalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.LEGAL_NAME, activeYear),
  );
  const spouseLegalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.SPOUSE_LEGAL_NAME,
      activeYear,
    ),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const tutionForms = taxForms.filter(
    (tf) => tf.formType === TaxFormType.FORM_1098_T,
  );

  const firstName = legalName?.[TaxProfileField.FIRST_NAME];
  const spouseFName = spouseLegalName?.[TaxProfileField.FIRST_NAME];

  const selfTutionForm = tutionForms.find(
    (tf) =>
      tf.formData[TaxFormFieldIds.DEPENDENT_ID] == null &&
      !tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );
  const selfLinkedDoc = documents.find(
    (doc) => doc.docId === selfTutionForm?.docId,
  );

  const spouseTutionForm = tutionForms.find(
    (tf) => tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );
  const spouseLinkedDoc = documents.find(
    (doc) => doc.docId === spouseTutionForm?.docId,
  );

  const dependents = taxForms.filter(
    (tf) => tf.formType === TaxFormType.DEPENDENT,
  );

  const getSelectedChoices = (
    selectedChoicesArray = [] as any[],
    isStudent: boolean,
  ) => {
    const selectedChoices = [];
    if (selectedChoicesArray?.length > 0) {
      selectedChoices.push(...selectedChoicesArray);
    }
    if (isStudent) {
      selectedChoices.push(TaxFormFieldIds.IS_STUDENT);
    }
    return selectedChoices;
  };

  const dependentsEducationCredits = dependents.map(
    ({taxFormId, formData}) => {
      const linkedTutionForm: GenericTaxForm | undefined = tutionForms.find(
        (tf) => tf.formData[TaxFormFieldIds.DEPENDENT_ID] === taxFormId,
      );
      const linkedDoc = documents.find(
        (doc) => doc.docId === linkedTutionForm?.docId,
      );
      const selectedChoices = getSelectedChoices(
        formData[TaxFormFieldIds.STUDENT_ELIGIBILITY],
        formData[TaxFormFieldIds.IS_STUDENT],
      );
      return {
        name: formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME],
        relation: formData[TaxFormFieldIds.DEPENDENT_RELATION],
        selectedChoices,
        doc: linkedDoc,
        tf: linkedTutionForm,
        amount: educationExpenses,
      };
    },
  );

  const getSpouseEducationCredits = () => {
    if (filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) {
      return [
        {
          name: spouseFName,
          relation: 'Spouse',
          selectedChoices: getSelectedChoices(
            spouseEducationCredits,
            isSpouseStudent,
          ),
          doc: spouseLinkedDoc,
          tf: spouseTutionForm,
          amount: educationExpenses,
        },
      ];
    }

    return [];
  };

  const educationCredits = showEducationCredits
    ? [
        {
          name: firstName,
          relation: 'Self',
          selectedChoices: getSelectedChoices(
            selfEducationCredits,
            isSelfStudent,
          ),
          doc: selfLinkedDoc,
          tf: selfTutionForm,
          amount: educationExpenses,
        },
        ...getSpouseEducationCredits(),
        ...dependentsEducationCredits,
      ]
    : [];

  const isLoading =
    !taxFormsLoaded || !documentsLoaded || !taxProfileStatusLoaded;
  return {educationCredits, isLoading};
};
