import {Modal} from '@mui/material';
import React from 'react';
import DSButton from '../Button/Button';

interface PopUpProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  modalStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  primaryButtonTitle?: string;
  primaryButtonOnClick?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryButtonTitle?: string;
  secondaryButtonOnClick?: () => void;
  secondaryButtonDisabled?: boolean;
  tertiaryButtonTitle?: string;
  tertiaryButtonOnClick?: () => void;
  tertiaryButtonDisabled?: boolean;
}

const PopUp = ({
  isOpen,
  onClose,
  children,
  modalStyle = {},
  style = {},
  primaryButtonTitle,
  primaryButtonOnClick,
  primaryButtonDisabled,
  secondaryButtonTitle,
  secondaryButtonOnClick,
  secondaryButtonDisabled,
  tertiaryButtonTitle,
  tertiaryButtonOnClick,
  tertiaryButtonDisabled,
}: PopUpProps) => {
  const getFlexValue = () => {
    if (primaryButtonTitle && secondaryButtonTitle && tertiaryButtonTitle) {
      return 0.3;
    }
    return 0.45;
  };

  const flex = getFlexValue();

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...modalStyle,
      }}
      open={isOpen}
      onClose={onClose}>
      <div
        style={{
          backgroundColor: 'white',
          padding: 24,
          borderRadius: 12,
          ...style,
        }}>
        {children}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginTop: 12,
          }}>
          {primaryButtonTitle && primaryButtonOnClick && (
            <DSButton
              type="primary"
              text={primaryButtonTitle}
              onClick={primaryButtonOnClick}
              disabled={primaryButtonDisabled}
              style={{flex}}
            />
          )}
          {secondaryButtonTitle && secondaryButtonOnClick && (
            <DSButton
              type="secondary"
              text={secondaryButtonTitle}
              onClick={secondaryButtonOnClick}
              style={{flex}}
              disabled={secondaryButtonDisabled}
            />
          )}
          {tertiaryButtonTitle && tertiaryButtonOnClick && (
            <DSButton
              type="tertiary"
              text={tertiaryButtonTitle}
              onClick={tertiaryButtonOnClick}
              style={{flex}}
              disabled={tertiaryButtonDisabled}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PopUp;
