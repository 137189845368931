import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  TaxProfileSection,
  TaxProfileSubsection,
} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {fetchHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.actions';
import {selectHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import SectionHeader from '../common/SectionHeader';
import AllOtherDeductions from './components/AllOtherDeductions';
import BusinessLoans from './components/BusinessLoans';
import BusinessVehicleDetails from './components/BusinessVehicleDetails';
import HealthInsurancePremium from './components/HealthInsurancePremium';
import HighValueDeductionNudge from './components/HighValueDeductionNudge';
import HighValueDeductionsDrawer from './components/HighValueDeductionsDrawer';
import HomeOfficeDetails from './components/HomeOfficeDetails';
import HSAContributions from './components/HSAContributions';
import IRAContributions from './components/IRAContributions';
import OfficeMortgage from './components/OfficeMortgage';
import PersonalDeductions from './components/PersonalDeductions';
import PhoneAndInternet from './components/PhoneAndInternet';
import SalaryAndWagesPaid from './components/SalaryAndWagesPaid';
import StudentLoan from './components/StudentLoan';
import DistributeDeductionsEntrypointNudge from './DistributeDeductions/components/DistributeDeductionsEntrypointNudge';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const Deductions = () => {
  const {activeYear} = useActiveYear();
  const {loaded: taxFormsLoaded} = useSelector(selectTaxFormsReducer);
  const {loaded: documentsLoaded} = useSelector(selectDocuments);
  const {loaded: taxProfileLoaded} = useSelector(selectTaxProfile);
  const {loaded: highValueDeductionsLoaded} = useSelector(
    selectHighValueDeductions,
  );

  const {TAX_PROFILE_SECTION_HEADER_HEIGHT, spaceLeftBelowTaxFilingSteps} =
    useLayout();

  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  useEffect(() => {
    dispatch(fetchHighValueDeductions(userId, activeYear));
  }, [userId, activeYear]);

  const isLoading = !taxFormsLoaded || !documentsLoaded || !taxProfileLoaded;

  return (
    <div style={{display: 'flex'}}>
      <div>
        <SectionHeader
          sectionName="Deductions"
          section={TaxProfileSection.Deductions}
        />
        <div
          style={{
            width: '75vw',
            height:
              spaceLeftBelowTaxFilingSteps - TAX_PROFILE_SECTION_HEADER_HEIGHT,
            overflowY: 'auto',
          }}>
          {highValueDeductionsLoaded ? (
            <HighValueDeductionNudge />
          ) : (
            <Skeleton
              width={'100%'}
              style={{marginBottom: 20, marginInline: 16}}
              height={60}
            />
          )}
          {isLoading ? (
            <Box col style={{padding: 20}}>
              <Skeleton
                width={'100%'}
                style={{marginBottom: 20}}
                height={150}
              />
              <Skeleton
                width={'100%'}
                style={{marginBottom: 20}}
                height={150}
              />
              <Skeleton
                width={'100%'}
                style={{marginBottom: 20}}
                height={150}
              />
              <Skeleton
                width={'100%'}
                style={{marginBottom: 20}}
                height={150}
              />
            </Box>
          ) : (
            <>
              <DistributeDeductionsEntrypointNudge />

              <HomeOfficeDetails />
              <BusinessVehicleDetails />
              <PhoneAndInternet />
              <OfficeMortgage />
              <StudentLoan />
              <BusinessLoans />
              <SalaryAndWagesPaid />
              {/* <OtherDepreciations /> */}
              <HSAContributions />
              <IRAContributions />
              <HealthInsurancePremium />
              <PersonalDeductions />

              <AllOtherDeductions />
            </>
          )}
        </div>
      </div>
      <div
        style={{
          width: '24vw',
          height: spaceLeftBelowTaxFilingSteps,
        }}>
        <ActionRequired
          dontAllowAdd
          types={[
            CreateQueryDrawerTab.TAX_PROFILE,
            CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
            CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
          ]}
          taxProfileSection={TaxProfileSection.Deductions}
        />
      </div>
      <HighValueDeductionsDrawer />
    </div>
  );
};

export default Deductions;
