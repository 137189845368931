import {getCategoryTaxonomy} from 'src/appApi';
import {getCpaTemplateList} from 'src/appApi';
import {
  SET_CPA_MESSAGE_TEMPLATE,
  SWITCH_ACTIVE_YEAR,
  SET_TAXONOMY,
} from '../actionNames';
import {SUPPORTED_YEARS} from './globals.reducer';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';

const setActiveYear = ({year}: {year: number}) => {
  return {
    type: SWITCH_ACTIVE_YEAR,
    payload: {
      activeYear: year,
    },
  };
};

export const switchActiveYear = ({year}: {year: number}) => {
  return async (dispatch: any) => {
    if (SUPPORTED_YEARS.includes(year)) {
      await dispatch(setActiveYear({year}));
    }
  };
};

const setTaxonomy = (taxonomy: any) => {
  return {
    type: SET_TAXONOMY,
    payload: {taxonomy},
  };
};

export const fetchTaxonomy = () => {
  return async (dispatch: any) => {
    try {
      const response = await getCategoryTaxonomy();
      dispatch(setTaxonomy(response.data));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch taxonomy (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};

const setCPAMessageTemplate = ({
  templates,
}: {
  templates: {title: string; body: string}[];
}) => {
  return {
    type: SET_CPA_MESSAGE_TEMPLATE,
    payload: {
      cpaMessageTemplates: templates,
    },
  };
};

export const fetchCPATemplateContent = () => {
  return async (dispatch: any) => {
    try {
      const resp = await getCpaTemplateList();
      await dispatch(setCPAMessageTemplate(resp.data));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to get cpa template content (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
