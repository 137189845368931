import React, {ChangeEventHandler, useCallback} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import {isNumber} from 'lodash';

interface NumberFieldProps extends InputWrapperBaseType {
  placeholder?: string;
  onChangeValue: (newText: number | null) => void;
  value: string | readonly string[] | number | undefined;
  style?: React.CSSProperties;
}

const NumberField = ({onChangeValue, ...rest}: NumberFieldProps) => {
  const _onChange = useCallback(
    (e: Parameters<ChangeEventHandler<HTMLInputElement>>[0]) => {
      if (onChangeValue) {
        const parsedNumber = +e.target.value;
        if (isNumber(parsedNumber)) {
          onChangeValue(parsedNumber);
        } else {
          onChangeValue(null);
        }
      }
    },
    [onChangeValue],
  );

  return (
    <InputWrapper
      {...rest}
      value={rest.value === null ? '' : rest.value}
      actualValue={rest.value}
      type="number"
      onChange={_onChange}
      // @ts-ignore
      onWheel={(e) => e.target?.blur?.()}
    />
  );
};

export default NumberField;
