import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Modal, TextField} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import JSONPretty from 'react-json-pretty';
import {deleteFlyUser, getRefundDetails} from 'src/appApi';
import {useNotify, useRefresh} from 'react-admin';

interface ConfirmDeleteOverlayProps {
  onClose: () => void;
  isOpen: boolean;
  record: any;
}

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const ConfirmDeleteOverlay = ({
  onClose,
  isOpen,
  record,
}: ConfirmDeleteOverlayProps) => {
  const [loading, setLoading] = useState(false);
  const [refundDetails, setRefundDetails] = useState('{}');
  const [confirmationText, setConfirmationText] = useState('');
  const refresh = useRefresh();

  const notify = useNotify();

  const fly_user_id = record.id;
  const deleteTargetEmail = record.email;

  const fetchRefundDetails = async () => {
    try {
      setLoading(true);
      const response = await getRefundDetails({fly_user_id});
      setRefundDetails(JSON.stringify(response.data));
      setLoading(false);
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      await deleteFlyUser(fly_user_id);
      notify(
        `${fly_user_id} is successfully deleted`,
        'info',
        null,
        false,
        3000,
      );
    } catch (e) {
      notify(
        `${fly_user_id} could not be deleted. Please retry`,
        'error',
        null,
        false,
        3000,
      );
    } finally {
      refresh();
      onClose();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchRefundDetails();
    }
  }, [isOpen]);

  const isConfirmDeleteButtonEnabled =
    confirmationText === 'DELETE USER' && !loading;

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          paddingTop: 0,
          borderRadius: 12,
          width: 600,
          maxHeight: '100vh',
          overflowY: 'auto',
        }}>
        <h3>
          Are you sure you want to delete all data for '{deleteTargetEmail}'?
        </h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: themmeColor.white,
          }}>
          {loading ? (
            <div
              style={{
                minHeight: 200,
                minWidth: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress />
            </div>
          ) : (
            <JSONPretty
              id={`json-pretty-${fly_user_id}`}
              data={refundDetails}
              theme={JSONPrettyMon}
              style={{fontSize: 20, overflowY: 'auto', maxHeight: '50vh'}}
            />
          )}
        </div>
        <p>
          Type "<strong>DELETE USER</strong>" to proceed
        </p>
        <TextField
          label="Confirmation Text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          disabled={loading}
        />
        <div
          style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
          <Button
            style={{color: themmeColor.black}}
            onClick={onClose}
            variant="text">
            No
          </Button>
          <Button
            style={{
              backgroundColor: isConfirmDeleteButtonEnabled
                ? themmeColor.errorRed
                : undefined,
            }}
            disabled={!isConfirmDeleteButtonEnabled}
            onClick={deleteUser}
            variant="contained">
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteOverlay;
