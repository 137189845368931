import React, {useContext, useState} from 'react';
import {
  backendFormsResponseMapper,
  FormType,
  OCR_STATUS,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import ViewDocumentContext from '../../../ViewDocument.context';
import {
  addTaxForm,
  deleteTaxForm,
  populateBusinessDetailsFromDoc,
  updateTaxForm,
} from 'src/appApi';
import {NotificationType} from 'src/store/app/app.reducer';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {isNumber} from 'lodash';
import Box from 'src/DesignSystem/Box/Box';
import {Form} from 'src/DesignSystem/Form/Form';
import {Typography} from '@mui/material';
import {
  DOCUMENT_TYPES,
  themmeColor,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import DeprecatedForm from './DeprecatedForm';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';

interface LegacyRenderOcrDataProps {
  ocrStatus: string;
  forms: {
    [id: number]: FormType;
  };
  setForms: React.Dispatch<
    React.SetStateAction<{
      [id: number]: FormType;
    }>
  >;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LegacyRenderOcrData = ({
  ocrStatus,
  forms,
  setForms,
  isLoading,
  setLoading,
}: LegacyRenderOcrDataProps) => {
  const {
    userId: flyfinUserId,
    isImage,
    document,
    refreshDocuments: fetchDocuments,
    documentsLoading,
  } = useContext(ViewDocumentContext);
  const [showOnlyPriorityFields, setShowOnlyPriorityFields] = useState(false);
  const [seenForms, setSeenForms] = useState(new Set());
  const [addTaxFormPopuo, setAddTaxFormPopup] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<string | null>(null);
  const isReviewComplete = [
    VALID_DOCUMENT_STATUS.ACCEPTED,
    VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA,
  ].includes(document.docStatus);

  const {notify} = useNotify();

  const updateSeenForms = (formId: number) => {
    setSeenForms((prev) => {
      prev.add(formId);
      const newSet = new Set(prev);
      return newSet;
    });
  };
  const addForm = async () => {
    try {
      setLoading(true);
      const res = await addTaxForm({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
        form_type: selectedDocType,
      });
      const formData = backendFormsResponseMapper([res.data]);
      setForms((prev) => {
        return {...prev, ...formData};
      });
      setAddTaxFormPopup(false);
      notify('Added tax form successfully', NotificationType.success);
    } catch (e) {
      notify(`Error Occurred ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormDelete = async (formId: number) => {
    try {
      await deleteTaxForm({tax_form_id: formId});
      setForms((prev) => {
        const prevState = prev;
        delete prevState[formId];
        return {...prevState};
      });
      notify(`Tax form deleted successfully`, NotificationType.success);
    } catch (e) {
      notify(`Failed to delete tax form ${e}`, NotificationType.error);
    }
  };

  const handleFormValueChange = (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => {
    setForms((prev) => {
      let currForm = prev[formId];
      let currFormField = currForm.form_data[fieldKey];

      if (valueIndex !== -1) {
        currFormField.field_values[fieldValueIndex].value[valueIndex] = value;
      } else {
        currFormField.field_values[fieldValueIndex].value = value;
      }

      currForm[fieldKey] = currFormField;

      return {
        ...prev,
        [formId]: currForm,
      };
    });
  };

  const saveDocumentData = async (isReviewed: boolean) => {
    try {
      setLoading(true);
      const formDataToSend = Object.keys(forms).map((formId) => {
        let formOcrData = {};
        Object.keys(forms[Number(formId)].form_data).forEach((fieldKey) => {
          formOcrData[fieldKey] = {
            field_values:
              forms[Number(formId)].form_data[fieldKey].field_values,
          };
        });
        return {
          id: Number(formId),
          form_data: formOcrData,
        };
      });

      await updateTaxForm({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
        forms: formDataToSend,
      });

      await fetchDocuments();
      notify(
        isReviewed ? 'Document data saved' : 'Document review complete',
        NotificationType.success,
      );
    } catch (e) {
      notify(
        `${
          isReviewed ? 'Failed to save data' : 'Failed to mark review complete'
        } ${e}`,
        NotificationType.error,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ocrStatus === OCR_STATUS.IN_PROGRESS ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          OCR IS IN PROGRESS....
        </div>
      ) : (
        <>
          <Box
            borderColor={themmeColor.offWhite}
            style={{padding: 16, marginBottom: 12, marginLeft: 8}}>
            <Form.SwitchField
              title="Show only priority fields"
              onChangeValue={setShowOnlyPriorityFields}
              value={showOnlyPriorityFields}
            />
          </Box>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {document.docType === DOCUMENT_TYPES.OTHER && (
              <div>
                <Typography fontSize={20} fontWeight={600}>
                  Additional document
                </Typography>
                <div
                  style={{
                    borderRadius: 12,
                    borderWidth: 4,
                    borderColor: '#F0F0F0',
                    backgroundColor: themmeColor.offGrey,
                    padding: 12,
                    marginTop: 12,
                    marginBottom: 12,
                  }}>
                  <SimpleKeyValueField
                    name="Note from user"
                    value={document.extras?.note}
                    loading={documentsLoading}
                  />
                </div>
              </div>
            )}
            <div>
              {Object.keys(forms).map((formId, index) => {
                return (
                  <div style={{paddingLeft: 8, paddingBottom: 4}}>
                    <DeprecatedForm
                      form={forms[Number(formId)]}
                      isImage={isImage}
                      key={formId}
                      setForms={setForms}
                      setLoading={setLoading}
                      handleDelete={handleFormDelete}
                      handleFormValueChange={handleFormValueChange}
                      isSeen={seenForms.has(Number(formId))}
                      updateSeenForms={updateSeenForms}
                      index={index}
                      isReviewComplete={isReviewComplete}
                      showOnlyPriorityFields={showOnlyPriorityFields}
                    />
                  </div>
                );
              })}
            </div>

            <DSButton
              style={{margin: 12}}
              type="secondary"
              text="Add tax-form"
              disabled={isLoading || isReviewComplete}
              onClick={() => setAddTaxFormPopup(true)}
            />
            <DSButton
              style={{margin: 12}}
              type="primary"
              text={isReviewComplete ? 'Save' : 'Review complete'}
              disabled={
                Object.keys(forms).some(
                  (formId) => !seenForms.has(Number(formId)),
                ) || isLoading
              }
              onClick={() => saveDocumentData(isReviewComplete)}
            />
          </div>
        </>
      )}
      <PopUp
        isOpen={addTaxFormPopuo}
        primaryButtonTitle="Confirm"
        primaryButtonOnClick={addForm}
        primaryButtonDisabled={isLoading || !selectedDocType}
        secondaryButtonTitle="Cancel"
        style={{width: 400}}
        onClose={() => setAddTaxFormPopup(false)}
        secondaryButtonOnClick={() => setAddTaxFormPopup(false)}
        secondaryButtonDisabled={isLoading}>
        <Typography style={{fontWeight: 600, fontSize: 20, marginBottom: 12}}>
          Add Tax form
        </Typography>
        <Dropdown
          options={Object.values(TaxFormType)}
          onChange={(newOption) => setSelectedDocType(newOption)}
          disabled={isLoading}
          maxWidth
          placeholder="Select document type"
          value={selectedDocType}
        />
      </PopUp>
    </>
  );
};

export default LegacyRenderOcrData;
