import {TAX_FILING_STATUS} from 'src/constants/constants';
import {SET_TAX_RETURNS} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

export enum TaxReturnDocumentType {
  TAX_RETURNS = 'TAX_RETURNS',
  ESIGN_FORM = 'ESIGN_FORM',
  BUSINESS_RETURNS = 'BUSINESS_RETURNS',
}

export enum TaxReturnType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum OcrDataSectionName {
  states_return_summary = 'states_return_summary',
  federal_return_summary = 'federal_return_summary',
}

export enum EsignFieldType {
  SIGNATURE = 'SIGNATURE',
  DATE = 'DATE',
}

export enum TaxReturnStatus {
  SEND_TO_CCH = 'SEND_TO_CCH',
  ADD_RETURN = 'ADD_RETURN',
  REVIEW_SUMMARY = 'REVIEW_SUMMARY',
  CPA_REVIEW = 'CPA_REVIEW',
  REVIEW_ESIGN = 'REVIEW_ESIGN',
  EFILE_STATUS = 'EFILE_STATUS',
}

export enum EFILE_STATUS_INFO {
  ACCEPTED = 'Accepted',
  PENDING = 'Pending',
  PAPER_FILED = 'Paper Filed',
  REJECTED = 'Rejected',
  NOT_EFILED = 'Not Efiled',
}

export const TaxReturnStepsSequence = [
  TaxReturnStatus.SEND_TO_CCH,
  TaxReturnStatus.ADD_RETURN,
  TaxReturnStatus.REVIEW_SUMMARY,
  TaxReturnStatus.CPA_REVIEW,
  TaxReturnStatus.REVIEW_ESIGN,
  TaxReturnStatus.EFILE_STATUS,
];

export interface EsignFieldLocation {
  applicable: boolean;
  field_type: EsignFieldType;
  height: number;
  page_number: number;
  signer_type: string;
  width: number;
  x: number;
  y: number;
}

interface EsignStatusLog {
  title: string;
  date: string;
  completed: boolean;
}

interface TaxReturnRefundOrDueLog {
  title: string;
  amount: number;
}

interface TaxReturnTefundOrDue {
  log: TaxReturnRefundOrDueLog[];
  payment_method: string;
}

export interface TaxReturn {
  return_id: number;
  return_type: TaxReturnType;
  status: TaxReturnStatus;
  return_expiry: Date;
  document_name: string;
  document_id: number;
  document_type: TaxReturnDocumentType;
  document_url: string;
  ocr_data: {
    esign_fields: EsignFieldLocation[];
    [OcrDataSectionName.states_return_summary]: {[key: string]: any};
    [OcrDataSectionName.federal_return_summary]: {[key: string]: any};
  };
  esign_status_log: EsignStatusLog[];
  refund_and_due: {
    dues: TaxReturnTefundOrDue;
    refunds: TaxReturnTefundOrDue;
  };
  efile_status_info: {
    [key: string]: EFILE_STATUS_INFO;
  };
  business_id?: number | null;
  business_name?: string | null;
  cpa_status: TAX_FILING_STATUS;
}

const initialState = {
  taxReturns: [] as TaxReturn[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const taxReturns = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_RETURNS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxReturnsStateType = typeof initialState;
