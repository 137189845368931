import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import DownIcon from 'src/icons/DownIcon';
import Skeleton from 'src/icons/Skeleton';

interface LabelFieldProps {
  label: string;
  value: any;
  onClick?: () => void;
  backgroundColor?: string;
  valueColor?: string;
  Icon?: any;
  loaded?: boolean;
}

const LabelField = ({
  label,
  value,
  onClick,
  backgroundColor,
  valueColor,
  loaded = true,
}: LabelFieldProps) => {
  const onClickStyles: React.CSSProperties = onClick
    ? {
        backgroundColor: backgroundColor ?? themmeColor.silver,
        borderRadius: 20,
        justifyContent: 'center',
        display: 'flex',
        paddingBlock: 4,
        paddingInline: 8,
        alignItems: 'center',
        color: valueColor,
      }
    : {};

  return (
    <div style={{height: 50}} onClick={onClick}>
      <Typography
        style={{
          fontSize: 11,
          fontWeight: 'bold',
          letterSpacing: 0.7,
          textTransform: 'uppercase',
          color: themmeColor.black60,
        }}>
        {label}
      </Typography>
      {loaded ? (
        <Typography
          style={{
            fontSize: 13,
            fontWeight: 'bold',
            color: themmeColor.black60,
            ...onClickStyles,
          }}>
          {value}
          {onClick && <DownIcon />}
        </Typography>
      ) : (
        <Skeleton width={40} radius={4} height={20} />
      )}
    </div>
  );
};

export default LabelField;
