import React from 'react';

const AiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none">
      <path
        d="M13.6635 18.5023L10.943 13.5567C10.8293 13.35 10.6562 13.1821 10.4461 13.0748L6.17304 10.8926C5.48203 10.5398 5.4887 9.55004 6.1844 9.20651L10.4296 7.11026C10.65 7.00141 10.8309 6.82639 10.947 6.60967L13.6585 1.54744C14.0136 0.884528 14.9636 0.883197 15.3205 1.54512L18.0527 6.61177C18.1688 6.82717 18.3491 7.0011 18.5685 7.10945L22.8155 9.2065C23.5112 9.55003 23.5179 10.5398 22.8269 10.8926L18.552 13.0756C18.3429 13.1824 18.1704 13.3492 18.0567 13.5546L15.3154 18.5046C14.9557 19.1542 14.0214 19.1529 13.6635 18.5023Z"
        fill="#0D0F0F"
      />
      <path
        opacity="0.6"
        d="M4.23734 6.52933L3.43545 5.07153C3.39971 5.00656 3.34532 4.95381 3.27929 4.92009L2.02582 4.27997C1.80867 4.16907 1.81077 3.85805 2.0294 3.7501L3.2741 3.13546C3.34337 3.10126 3.40022 3.04626 3.4367 2.97815L4.23577 1.4863C4.34735 1.27797 4.6459 1.27755 4.75807 1.48557L5.56327 2.97881C5.59978 3.0465 5.65643 3.10116 5.72539 3.13521L6.97065 3.7501C7.18928 3.85805 7.19138 4.16908 6.97422 4.27997L5.7202 4.92035C5.65449 4.95391 5.60029 5.00632 5.56454 5.07087L4.75647 6.53007C4.64343 6.7342 4.3498 6.73378 4.23734 6.52933Z"
        fill="#0D0F0F"
      />
      <path
        opacity="0.6"
        d="M6.09642 21.2754L5.0919 19.4493C5.03689 19.3492 4.95315 19.268 4.8515 19.2161L3.30947 18.4286C2.97518 18.2579 2.9784 17.7791 3.31497 17.6129L4.84351 16.8581C4.95015 16.8055 5.03767 16.7208 5.09383 16.616L6.094 14.7486C6.26578 14.4279 6.72539 14.4273 6.89806 14.7475L7.90613 16.617C7.96233 16.7212 8.04954 16.8053 8.1557 16.8577L9.68509 17.6129C10.0217 17.7791 10.0249 18.2579 9.69059 18.4286L8.14772 19.2165C8.04656 19.2682 7.96312 19.3489 7.90809 19.4482L6.8956 21.2766C6.72158 21.5908 6.26955 21.5902 6.09642 21.2754Z"
        fill="#0D0F0F"
      />
    </svg>
  );
};

export default AiIcon;
