import {Button} from '@mui/material';
import React, {useState} from 'react';
import {exportData} from 'src/appApi';

interface ExportDataProps {
  fly_user_id: number | string;
  year: number;
  onSuccess?: () => void;
}

const ExportData = ({
  fly_user_id,
  year,
  onSuccess = () => {},
}: ExportDataProps) => {
  const [loading, setLoading] = useState(false);
  const handleOnClick = async () => {
    setLoading(true);
    try {
      await exportData({fly_user_id, year});
      onSuccess();
    } catch (e) {
      alert(`API Failed ${e}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      variant="contained"
      onClick={handleOnClick}
      disabled={loading}
      style={{height: '100%'}}>
      Export Data {year}
    </Button>
  );
};

export default ExportData;
