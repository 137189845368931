import React, {useMemo, useState} from 'react';
import {TextField, Typography} from '@mui/material';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Document, Page} from 'react-pdf';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {useOcrBoundingBox} from 'src/CpaCenterV2/hooks/useOcrBoundingBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {themmeColor} from 'src/constants/constants';
import {sendDocForReviewEsign} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {MANDATORY_JURISDICTION} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {
  OcrDataSectionName,
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {WarningRounded} from '@mui/icons-material';
import DSButton from 'src/DesignSystem/Button/Button';
import {validateEmail} from 'src/common/utils';
import {bulkSaveTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {selectUser} from 'src/store/user/user.selector';

const getIsSpouseEmailRequired = (filingStatus: FILING_STATUS_ANSWER) => {
  return filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY;
};

const BottomActionBar = ({
  primaryButtonOnClick,
  secondaryButtonOnClick,
  tertiaryButtonOnClick,
  isLoading,
  primaryButtonDisabled,
}: {
  primaryButtonOnClick: (email?: string) => void;
  secondaryButtonOnClick: (email?: string) => Promise<void>;
  tertiaryButtonOnClick: () => void;
  isLoading: boolean;
  primaryButtonDisabled: boolean;
}) => {
  const {activeYear} = useActiveYear();
  const {email: userEmail} = useSelector(selectUser);
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );
  const taxProfileSpouseEmail = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TaxProfileQuestion.SPOUSE_EMAIL, activeYear),
  );

  const [spouseEmail, setSpouseEmail] = useState(taxProfileSpouseEmail);

  const isSpouseEmailRequired = getIsSpouseEmailRequired(filingStatus);
  const isSpouseEmailSameAsTaxpayerEmail =
    isSpouseEmailRequired && userEmail === spouseEmail;
  const isPrimaryButtonDisabled =
    primaryButtonDisabled ||
    isLoading ||
    (isSpouseEmailRequired && (spouseEmail === '' || spouseEmail == null)) ||
    isSpouseEmailSameAsTaxpayerEmail;

  const isSecondaryButtonDisabled =
    isLoading || isSpouseEmailSameAsTaxpayerEmail;

  return (
    <div>
      {isSpouseEmailRequired && (
        <div>
          <div
            style={{
              background: '#FFFAED',
              borderRadius: 4,
              padding: '3px 8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 16,
            }}>
            <WarningRounded style={{color: themmeColor.flyfinYellow}} />
            <Typography
              style={{fontSize: '13', fontWeight: 400, marginLeft: 4}}>
              Spouse email id
            </Typography>
          </div>
          <TextField
            fullWidth
            type={'text'}
            style={{marginTop: 16}}
            variant="outlined"
            value={spouseEmail}
            placeholder="Spouse email id"
            onChange={(e) => setSpouseEmail(e.target.value)}
          />
        </div>
      )}
      {isSpouseEmailSameAsTaxpayerEmail ? (
        <div style={{marginTop: 8}}>
          <Text
            color={themmeColor.errorRed}
            type={Text.TEXT_TYPES.S}
            text="Spouse email should not be same as taxpayer email. Please input a different spouse email by reaching out to the user."
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginTop: 12,
        }}>
        <DSButton
          type="primary"
          text={'Send automatically'}
          onClick={() => primaryButtonOnClick(spouseEmail)}
          disabled={isPrimaryButtonDisabled}
          style={{flex: 0.3}}
        />
        <DSButton
          type="secondary"
          text={'Send manually'}
          onClick={() => secondaryButtonOnClick(spouseEmail)}
          disabled={isSecondaryButtonDisabled}
          style={{flex: 0.3}}
        />
        <DSButton
          type="tertiary"
          text={'Close'}
          onClick={tertiaryButtonOnClick}
          style={{flex: 0.3}}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

interface ReviewReturnPlaceholderPopupProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  onSendManually: () => void;
}

const BUSINESS_RETURN_STATES_ALLOWED_AUTO_ESIGN = [
  'CA',
  'AZ',
  'CO',
  'DE',
  'FL',
  'GA',
  'IL',
  'SC',
  'TX',
  'NY',
];

const ReviewReturnPlaceholderPopup = ({
  url,
  isOpen,
  onClose,
  onSendManually,
}: ReviewReturnPlaceholderPopupProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const dispatch = useDispatch();
  const {canvasPDFRef, numPages, onDocumentLoadSuccess, drawBoundingBox} =
    useOcrBoundingBox();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();

  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();

  const isSpouseEmailRequired = useMemo(
    () => getIsSpouseEmailRequired(filingStatus),
    [filingStatus],
  );

  const _onDocumentLoadSuccess = (props: any) => {
    onDocumentLoadSuccess(props);
  };

  const _onRenderPage = (pageNumber: number) => {
    drawBoundingBox(
      currentReturn.ocr_data.esign_fields.filter((field) => {
        return field.page_number === pageNumber;
      }),
    );
  };

  const onClickSendManually = async (email: string = '') => {
    if (isSpouseEmailRequired) {
      if (!validateEmail(email)) {
        notify('Please provide valid spouse email', NotificationType.error);
        return;
      }
      await Promise.all([
        dispatch(
          bulkSaveTaxProfileQuestions(
            userId,
            [
              {
                question_id: TaxProfileQuestion.SPOUSE_EMAIL,
                answer: email,
              },
            ],
            activeYear,
          ),
        ),
      ]);
    }
    onSendManually();
  };

  const onSendAutomatically = async (email: string = '') => {
    try {
      setIsLoading(true);
      if (isSpouseEmailRequired) {
        if (!validateEmail(email)) {
          notify('Please provide valid spouse email', NotificationType.error);
          return;
        }
        await Promise.all([
          dispatch(
            bulkSaveTaxProfileQuestions(
              userId,
              [
                {
                  question_id: TaxProfileQuestion.SPOUSE_EMAIL,
                  answer: email,
                },
              ],
              activeYear,
            ),
          ),
        ]);
      }
      await sendDocForReviewEsign({
        user: userId,
        year: activeYear,
        filename: currentReturn.document_name,
        esign_fields: currentReturn.ocr_data.esign_fields,
        jurisdiction: MANDATORY_JURISDICTION,
        // sign_now_document_id: signNowId,
        s3_url: url,
        return_id: returnId,
        return_type: currentReturn.return_type,
      });
      await dispatch(fetchTaxReturns(userId, activeYear));
      onClose();
      navigateToTaxReturnDetailedView(
        returnId,
        currentReturn?.return_type,
        TaxReturnStatus.REVIEW_ESIGN,
      );
      notify('Document sent for review esign', NotificationType.success);
    } catch (e) {
      notify(`Failed to send ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSendAutomaticallyDisabled = useMemo(() => {
    if (currentReturn.return_type === TaxReturnType.BUSINESS) {
      return Object.keys(
        currentReturn.ocr_data[OcrDataSectionName.states_return_summary],
      ).some((stateAbbr) =>
        BUSINESS_RETURN_STATES_ALLOWED_AUTO_ESIGN.includes(stateAbbr),
      );
    }

    return false;
  }, [currentReturn]);
  if (!currentReturn) {
    return null;
  }
  return (
    <PopUp isOpen={isOpen} onClose={onClose}>
      <Typography style={{fontWeight: 800, fontSize: 20}}>
        Review the signature & date placeholders
      </Typography>
      <Typography style={{fontSize: 13, marginTop: 4, marginBottom: 2}}>
        Make sure that we are asking user to sign at the correct places.
      </Typography>
      <Typography style={{fontSize: 13, fontWeight: 600, marginBottom: 8}}>
        If there is some issue, upload tax return on “SignNow” and use send
        manually option.
      </Typography>
      <div style={{width: '100%', height: '50vh', overflowY: 'auto'}}>
        <Document file={url} onLoadSuccess={_onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <div
              style={{
                display: 'flex',
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Page
                onRenderSuccess={() => _onRenderPage(index)}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                canvasRef={(ref) => {
                  canvasPDFRef.current[index + 1] = ref;
                }}
                scale={1.2}
              />
            </div>
          ))}
        </Document>
      </div>
      <Typography style={{marginTop: 12}}>
        This document expires on{' '}
        <span style={{fontWeight: 600}}>
          {currentReturn.return_expiry.toDateString()}
        </span>
      </Typography>
      <BottomActionBar
        key={`${isSpouseEmailRequired}`}
        primaryButtonDisabled={isSendAutomaticallyDisabled}
        primaryButtonOnClick={onSendAutomatically}
        secondaryButtonOnClick={onClickSendManually}
        tertiaryButtonOnClick={onClose}
        isLoading={isLoading}
      />
    </PopUp>
  );
};

export default ReviewReturnPlaceholderPopup;
