import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {themmeColor} from 'src/constants/constants';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';

interface ReturnExpiredProps {
  returnId: number;
  returnType: TaxReturnType;
}

const ReturnExpired = ({returnId, returnType}: ReturnExpiredProps) => {
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const currentReturn = taxReturns.find(
    (taxReturn) =>
      taxReturn.return_id === returnId && taxReturn.return_type === returnType,
  );
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  if (currentReturn?.return_expiry.getTime() > new Date().getTime()) {
    return null;
  }
  return (
    <Box
      col
      borderColor={themmeColor.errorRed}
      backgroundColor={'#FFF0F0'}
      style={{padding: 16, marginBottom: 12}}>
      <Box style={{padding: 12, marginBottom: 12}}>
        <Typography>{currentReturn?.document_name}</Typography>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          style={{fontWeight: 600, textTransform: 'uppercase', fontSize: 13}}>
          Document expired: {currentReturn?.return_expiry.toDateString()}
        </Typography>
        <DSButton
          text="Re-upload"
          type="primary"
          onClick={() =>
            navigateToTaxReturnDetailedView(
              returnId,
              returnType,
              TaxReturnStatus.ADD_RETURN,
            )
          }
        />
      </div>
    </Box>
  );
};

export default ReturnExpired;
