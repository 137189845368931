import {Typography} from '@mui/material';
import React, {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {BOI_STATUS} from '../types';
import UpdateBoiStatusOverlay from './UpdateBoiStatusOverlay';

interface BoiStatusCellProps {
  currentBoiStatus: BOI_STATUS;
  boiId: number;
  onSuccess: () => void | Promise<void>;
}

const BoiStatusCell = ({
  currentBoiStatus,
  boiId,
  onSuccess,
}: BoiStatusCellProps) => {
  const currentStatus = currentBoiStatus;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div style={{display: 'inline-block'}}>
      <div
        onClick={openModal}
        style={{
          backgroundColor: '#F4F4F4',
          borderRadius: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
        }}>
        <Typography
          style={{fontSize: 12, color: '#0D0F0F', textOverflow: 'ellipsis'}}>
          {currentStatus}
        </Typography>
        <KeyboardArrowDownIcon style={{marginLeft: 8}} />
      </div>
      {isOpen && (
        <UpdateBoiStatusOverlay
          isVisible={isOpen}
          onClose={closeModal}
          currentBoiStatus={currentBoiStatus}
          boiId={boiId}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default BoiStatusCell;
