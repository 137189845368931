import React from 'react';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import PartnerPersonalIncome from './components/PartnerPersonalIncome';
import PartnerBusinessIncome from './components/PartnerBusinessIncome';

const PartnerIncome = () => {
  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          sectionName="Income"
          section={TaxProfileSection.Income}
        />
        <div style={{overflowY: 'auto'}}>
          <PartnerPersonalIncome />
          <PartnerBusinessIncome />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.25,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <ActionRequired
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          dontAllowAdd
          taxProfileSection={TaxProfileSection.Income}
          title="Comments"
        />
      </div>
    </div>
  );
};

export default PartnerIncome;
