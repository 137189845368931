import axios from 'axios';
import {BULK_TAX_PROFILE_POST_DATA} from './dummy.utils';

const BASE_URL = 'https://api-stg.flyfin.org';

const getConfig = (params = {}, authTokenNotReqd = false, headers = {}) => {
  const token = localStorage.getItem('appToken');
  const instance = {
    baseURL: BASE_URL,
    headers: authTokenNotReqd
      ? {...headers}
      : {
          Authorization: `Token ${token}`,
          ...headers,
        },
    params,
    paramsSerializer: {
      indexes: null,
    },
  };
  return instance;
};

const axiosGet = (
  url: string,
  params = {},
  authTokenNotReqd = false,
  headers = {},
) => {
  const instance = getConfig(params, authTokenNotReqd, headers);
  return axios.get(url, instance);
};

const axiosPost = (
  url: string,
  body: any,
  authTokenNotReqd = false,
  headers = {},
) => {
  const instance = getConfig({}, authTokenNotReqd, headers);
  return axios.post(url, body, instance);
};

const axiosPatch = (
  url: string,
  params = {},
  body = {},
  authTokenNotReqd = false,
  headers = {},
) => {
  const instance = getConfig(params, authTokenNotReqd, headers);
  return axios.patch(url, body, instance);
};

export const sendOtp = (email: string) => {
  return axiosPost('/users/send_otp/', {to: email, new_user: true}, true);
};

// /users/verify_otp/

export const verifyOtp = (email: string, otp: string) => {
  return axiosPost(
    '/users/verify_otp/',
    {to: email, new_user: true, otp, channel_id: ':'},
    true,
  );
};

export const saveBulkTaxProfile = (body: any) => {
  return axiosPost('/tax_engine/save_tax_profile_question_bulk/', body);
};

export const postLevel = (level: string) => {
  return axiosPost('/levels_completed/', {level});
};

export const patchUserProfile = (
  user_id: string,
  first_name: string,
  last_name: string,
) => {
  return axiosPatch(`/users/${user_id}/`, {}, {first_name, last_name});
};

export const postTaxProfile = (body: any) => {
  return axiosPost('/tax_engine/save_tax_profile_question_v2/', body);
};

export const dummmyPlaidAccount = () => {
  return axiosPost('/plaid_item/create_v2/', {}, false, {
    source: 'FF_DUMMY',
  });
};

export const createPlaidAccount = (body: any) => {
  return axiosPost(`/plaid_account/`, body);
};

export const patchTaxFilingJourney = (body: {
  app_year: number;
  completed_return_status?: number;
  completed_return_sub_status?: number;
}) => {
  return axiosPatch(
    '/tax_engine/tax_filing_status/update_or_create_v2/',
    {},
    body,
  );
};
