import React from 'react';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import SectionHeader from '../common/SectionHeader';
import AdoptionCredits from './components/AdoptionCredits';
import DependentsCredits from './components/DependentsCredits';
import EducationalCredits from './components/EducationalCredits';

const Credits = () => {
  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_SECTION_HEADER_HEIGHT} =
    useLayout();
  return (
    <div style={{display: 'flex'}}>
      <div>
        <SectionHeader
          sectionName="Credits"
          section={TaxProfileSection.Credits}
        />
        <div
          style={{
            width: '75vw',
            height:
              spaceLeftBelowTaxFilingSteps - TAX_PROFILE_SECTION_HEADER_HEIGHT,
            overflowY: 'auto',
          }}>
          <DependentsCredits />
          <EducationalCredits />
          <AdoptionCredits />
        </div>
      </div>
      <div style={{width: '24vw', height: spaceLeftBelowTaxFilingSteps}}>
        <ActionRequired
          dontAllowAdd
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          taxProfileSection={TaxProfileSection.Credits}
        />
      </div>
    </div>
  );
};

export default Credits;
