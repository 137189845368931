import {Typography} from '@mui/material';
import React from 'react';

const InfoLabel = (props: {
  label: string | number;
  value: string | number;
}) => {
  return (
    <div>
      <Typography style={{marginBottom: 8}} fontWeight={600}>
        {props.label}
      </Typography>
      <Typography>{props.value}</Typography>
    </div>
  );
};

export default InfoLabel;
