import React, {useMemo} from 'react';
import {isNull, isNumber} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {
  BusinessDetail,
  BusinessDetailsField,
} from 'src/store/businessDetails/businessDetails.types';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  BalanceSheetType,
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import BusinessBalanceSheetDocs from './BusinessBalanceSheetDocs';
import BalanceSheetHeader from './BalanceSheetHeader';
import DSButton from 'src/DesignSystem/Button/Button';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {BalanceSheetCombined} from 'src/store/balanceSheet/balanceSheet.types';
import {fetchBalanceSheet} from 'src/store/balanceSheet/balanceSheet.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface SingleBusinessBalanceSheetProps {
  balanceSheet: BalanceSheetCombined;
  index: number;
}

const SingleBusinessBalanceSheet = ({
  index,
  balanceSheet,
}: SingleBusinessBalanceSheetProps) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);

  const {onEditTaxForm} = usePartnerTaxProfileReview();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const {documents} = useSelector(selectDocuments);
  const dispatch = useDispatch();

  const startDoc = useMemo(() => {
    if (isNumber(balanceSheet.year_start_doc_id)) {
      return (
        documents.find(
          (doc) => doc.docId === balanceSheet.year_start_doc_id,
        ) ?? null
      );
    }
    return null;
  }, [balanceSheet, documents]);

  const startBalanceSheet = useMemo(() => {
    if (isNumber(balanceSheet.year_start_form_id)) {
      return (
        taxForms.find(
          (tf) => tf.taxFormId === balanceSheet.year_start_form_id,
        ) ?? null
      );
    }
    return null;
  }, [balanceSheet, taxForms]);

  const endDoc = useMemo(() => {
    if (isNumber(balanceSheet.year_end_doc_id)) {
      return (
        documents.find((doc) => doc.docId === balanceSheet.year_end_doc_id) ??
        null
      );
    }
    return null;
  }, [balanceSheet, documents]);

  const endBalanceSheet = useMemo(() => {
    if (isNumber(balanceSheet.year_end_form_id)) {
      return (
        taxForms.find(
          (tf) => tf.taxFormId === balanceSheet.year_end_form_id,
        ) ?? null
      );
    }
    return null;
  }, [balanceSheet, taxForms]);

  const onClickEdit = (taxFormId: number) => {
    onEditTaxForm({
      taxFormId,
      taxFormType: TaxFormType.BALANCE_SHEET_FORM,
      title: 'Edit balanace sheet',
      onCommit: () => dispatch(fetchBalanceSheet(userId, activeYear)),
    });
  };

  return (
    <div style={{marginBottom: 8}}>
      <DSAccordion
        defaultExpanded={index === 0}
        name={`Business ${index + 1}`}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Text
            type={TEXT_TYPES.BASE}
            fontWeight={FONT_WEIGHTS.SemiBold}
            text={`${balanceSheet.business_name} (${balanceSheet.business_type})`}
          />
          <div style={{display: 'flex'}}>
            {startBalanceSheet ? (
              <DSButton
                text="Edit start balance sheet"
                onClick={() => onClickEdit(startBalanceSheet.taxFormId)}
                type="primary"
                style={{marginRight: endBalanceSheet ? 8 : 0}}
              />
            ) : null}
            {endBalanceSheet ? (
              <DSButton
                text="Edit end balance sheet"
                onClick={() => onClickEdit(endBalanceSheet.taxFormId)}
                type="primary"
              />
            ) : null}
          </div>
        </div>
        <BusinessBalanceSheetDocs startDoc={startDoc} endDoc={endDoc} />
        {balanceSheet.grouped_category_data.map((categoryData) => (
          <BalanceSheetHeader categoryData={categoryData} />
        ))}
      </DSAccordion>
    </div>
  );
};

export default SingleBusinessBalanceSheet;
