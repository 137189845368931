import { themmeColor } from "src/constants/constants";

const ManualIcon = ({height = 24, width = 24, color = themmeColor.grey}) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.416667"
      y="0.416667"
      width="19.1667"
      height="19.1667"
      rx="1.25"
      stroke={color}
      stroke-width="0.833333"
    />
    <path
      d="M4.91479 5.15137H7.06442L9.9432 12.1779H10.0568L12.9356 5.15137H15.0852V14.8483H13.3996V8.1864H13.3097L10.6298 14.8199H9.37029L6.69036 8.1722H6.6004V14.8483H4.91479V5.15137Z"
      fill={color}
    />
  </svg>
);


export default ManualIcon