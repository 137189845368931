import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface StateSelectionPopupProps {
  isOpen: boolean;
  onConfirm: (state: string | null) => void;
  statesNotAllowed: string[];
  onClose: () => void;
}

const StateSelectionPopup = ({
  isOpen,
  onConfirm,
  onClose,
  statesNotAllowed = [],
}: StateSelectionPopupProps) => {
  const [selectedState, setSelectedState] = useState<string | null>(null);

  const _onConfirm = () => {
    onConfirm(selectedState);
    setSelectedState(null);
    onClose();
  };
  return (
    <PopUp
      isOpen={isOpen}
      primaryButtonTitle="Confirm"
      primaryButtonOnClick={_onConfirm}
      primaryButtonDisabled={!selectedState}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      style={{width: 600}}
      onClose={onClose}>
      <Typography style={{fontWeight: 800, fontSize: 20, marginBottom: 16}}>
        Add state
      </Typography>
      <Dropdown
        options={Object.keys(stateAbbreviationNameMap).filter(
          (state) => !statesNotAllowed.includes(state),
        )}
        getOptionLabel={(option) => stateAbbreviationNameMap[option]}
        value={selectedState}
        onChange={setSelectedState}
        placeholder="Select State"
      />
    </PopUp>
  );
};

export default StateSelectionPopup;
