import React from 'react';
import {useEditTaxForm} from './useEditTaxForm';
import {getPath} from '../utils';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import ProfitLossHeader from './components/ProfitLossHeader';
import ProfitLossExpensesHeader from './components/ProfitLossExpensesHeader';
import Periods from '../EditTaxProfile/components/Periods';
import {Form} from 'src/DesignSystem/Form/Form';
import BalanceSheetLineItems from './components/BalanceSheetLineItems';
interface EditTaxFormProps {
  fields: ReturnType<typeof useEditTaxForm>['fields'];
  linkedBusinessId: ReturnType<typeof useEditTaxForm>['linkedBusinessId'];
}

const EditTaxForm = ({fields, linkedBusinessId}: EditTaxFormProps) => {
  return (
    <div>
      {fields.map((field) => {
        if (
          [
            getPath(TaxFormFieldIds.INCOME, TaxFormFieldIds.SUB_CATEGORY),
            getPath(
              TaxFormFieldIds.COST_OF_GOODS_SOLD,
              TaxFormFieldIds.SUB_CATEGORY,
            ),
            getPath(
              TaxFormFieldIds.OTHER_INCOME,
              TaxFormFieldIds.SUB_CATEGORY,
            ),
          ].includes(field.path)
        ) {
          return <ProfitLossHeader {...field} />;
        }
        if (
          field.path ===
          getPath(TaxFormFieldIds.EXPENSES, TaxFormFieldIds.SUB_CATEGORY)
        ) {
          return (
            <ProfitLossExpensesHeader
              {...field}
              bizId={linkedBusinessId as unknown as number}
            />
          );
        }
        if (
          [
            getPath(TaxFormFieldIds.ASSETS, TaxFormFieldIds.LINE_ITEMS),
            getPath(TaxFormFieldIds.LIABILITIES, TaxFormFieldIds.LINE_ITEMS),
            getPath(TaxFormFieldIds.EQUITY, TaxFormFieldIds.LINE_ITEMS),
          ].includes(field.path)
        ) {
          return (
            <BalanceSheetLineItems
              {...field}
              bizId={linkedBusinessId as unknown as number}
            />
          );
        }
        if (field.path === TaxFormFieldIds.PERIODS) {
          return <Periods {...field} />;
        }
        return (
          <div key={field.path} style={{marginBottom: 12}}>
            <Form {...field} />
          </div>
        );
      })}
    </div>
  );
};

export default EditTaxForm;
