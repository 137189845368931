import React from 'react';
import {themmeColor} from 'src/constants/constants';

const ResetIcon = ({color = themmeColor.white}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.89506 4.56315C3.86881 4.14977 4.18265 3.79338 4.59603 3.76713C5.00941 3.74088 5.3658 4.05472 5.39205 4.4681L3.89506 4.56315ZM4.94166 9.21043L5.07354 9.94874C4.86318 9.98632 4.64679 9.93238 4.47868 9.80047C4.31057 9.66856 4.20671 9.47121 4.19316 9.25795L4.94166 9.21043ZM9.19146 7.68943C9.59922 7.61659 9.98882 7.8881 10.0617 8.29586C10.1345 8.70362 9.86299 9.09322 9.45523 9.16606L9.19146 7.68943ZM6.75107 18.7166C6.43325 18.451 6.39095 17.978 6.65659 17.6602C6.92222 17.3423 7.39521 17.3 7.71303 17.5657L6.75107 18.7166ZM5.39205 4.4681L5.69015 9.1629L4.19316 9.25795L3.89506 4.56315L5.39205 4.4681ZM4.80977 8.47211L9.19146 7.68943L9.45523 9.16606L5.07354 9.94874L4.80977 8.47211ZM12.3594 19.25C16.3634 19.25 19.6094 16.0041 19.6094 12H21.1094C21.1094 16.8325 17.1919 20.75 12.3594 20.75V19.25ZM19.6094 12C19.6094 7.99594 16.3634 4.75 12.3594 4.75V3.25C17.1919 3.25 21.1094 7.16751 21.1094 12H19.6094ZM7.71303 17.5657C8.97155 18.6176 10.5906 19.25 12.3594 19.25V20.75C10.2261 20.75 8.26936 19.9856 6.75107 18.7166L7.71303 17.5657ZM12.3594 4.75C9.34094 4.75 7.05323 6.8049 5.60555 9.55936L4.27777 8.8615C5.8584 5.85409 8.55643 3.25 12.3594 3.25V4.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ResetIcon;
