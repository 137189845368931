import {useContext, useEffect, useState} from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';

import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {makeStyles} from '@mui/styles';
import {ZoomIn, ZoomOut} from '@material-ui/icons';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import ViewDocumentContext from '../ViewDocument.context';
import {RotateRight} from '@mui/icons-material';

const useStyles = makeStyles({
  documentViewerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  documentViewer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  imageRenderer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  pdfRenderer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  pdfPageRenderer: {
    display: 'flex',
    margin: '10px 0px',
    background: themmeColor.backgroundOffWhite,
  },
  footer: {
    height: '70px',
    width: '100%',
    display: 'flex',
    padding: '10px',
    justifyContent: 'flex-end',
  },
  mergeDocuments: {
    backgroundColor: themmeColor.darkBlue,
    padding: '5px 10px',
    borderRadius: 6,
  },
  zoomContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
  },
});

interface ImageRendererProps {
  uri: string;
  canvasRef: any;
  imageScale: number;
  setImageScale: any;
  onZoomClick: (shouldZoomOut: boolean) => void;
}

const ImageRenderer = ({
  uri,
  canvasRef,
  imageScale,
  setImageScale,
  onZoomClick,
}: ImageRendererProps) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const highlightParentContainer =
        document.getElementById(`image-renderer`);
      const canvasContext = canvas?.getContext('2d');
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const background = new Image();
      background.src = uri;

      // Make sure the image is loaded first otherwise nothing will draw.
      background.onload = () => {
        const newImageScale =
          Math.ceil(
            Math.min(
              highlightParentContainer?.clientWidth / background.width,
              highlightParentContainer?.clientHeight / background.height,
            ) * 10,
          ) / 10;
        canvas.width = background.width * newImageScale;
        canvas.height = background.height * newImageScale;

        canvasContext.scale(newImageScale, newImageScale);
        canvasContext.drawImage(background, 0, 0);
        setImageScale(newImageScale);
      };
    }
  }, [uri]);

  const styles = useStyles({});

  return (
    <>
      <div className={styles.zoomContainer}>
        <div onClick={(e) => onZoomClick(true)}>
          <ZoomOut />
        </div>
        <Typography>{imageScale}</Typography>
        <div onClick={(e) => onZoomClick(false)}>
          <ZoomIn />
        </div>
      </div>
      <div className={styles.imageRenderer} id="image-renderer">
        <LightGallery speed={500} plugins={[lgZoom, lgRotate, lgThumbnail]}>
          <a href={uri}>
            <canvas id="canvas" ref={canvasRef} />
          </a>
        </LightGallery>
      </div>
    </>
  );
};

const RenderDocument = () => {
  const {
    document,
    canvasRef,
    canvasPDFRef,
    docReadUrl,
    imageScale,
    setImageScale,
    isReadUrlReady,
    documentsLoading,
    isImage,
    rotate,
    setRotate,
  } = useContext(ViewDocumentContext);

  const [showPDF, setShowPDF] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pdfScale, setPdfScale] = useState(1.3);

  const isLoading = !isReadUrlReady || documentsLoading;

  const currentDocument = document;

  const onPDFDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const onPassword = (callback: any, reason: any) => {
    const callbackProxy = (password: any) => {
      // Cancel button handler
      if (password === null) {
        setShowPDF(false);
      }

      callback(password);
    };

    switch (reason) {
      case 1: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case 2: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  };

  const drawImage = (
    canvas: HTMLCanvasElement,
    imageScale: number,
    imageRotate: number,
  ) => {
    const canvasContext = canvas?.getContext('2d');
    canvasContext?.clearRect(0, 0, canvas.width, canvas.height);
    const background = new Image();
    background.src = docReadUrl;

    let width = background.width * imageScale;
    let height = background.height * imageScale;

    // if (rotate % 4 === 0) {
    //   width = background.width * imageScale;
    //   height = background.height * imageScale;
    // } else if (rotate % 3 === 0) {
    //   width = background.height * imageScale;
    //   height = background.width * imageScale;
    // } else if (rotate % 2 === 0) {
    //   width = background.width * imageScale;
    //   height = background.height * imageScale;
    // } else {
    //   width = background.height * imageScale;
    //   height = background.width * imageScale;
    // }

    // Make sure the image is loaded first otherwise nothing will draw.
    background.onload = () => {
      canvas.width = width;
      canvas.height = height;
      canvasContext?.scale(imageScale, imageScale);
      // canvasContext?.translate(width / 2, height / 2);
      // canvasContext?.rotate((imageRotate * 90 * Math.PI) / 180);
      // canvasContext?.drawImage(background, -width / 2, -height / 2);
      canvasContext?.drawImage(background, 0, 0);
    };
  };

  const onZoomClick = (shouldZoomOut: boolean) => {
    const newImageScale = shouldZoomOut
      ? Math.round((imageScale - 0.1) * 10) / 10
      : Math.round((imageScale + 0.1) * 10) / 10;
    const canvas = canvasRef.current;

    if (canvas) {
      drawImage(canvas, newImageScale, rotate);
    }
    setImageScale(newImageScale);
  };

  const onImageRotate = () => {
    const newRotate = rotate + 1;
    const canvas = canvasRef.current;

    if (canvas) {
      drawImage(canvas, imageScale, newRotate);
    }
    setRotate(newRotate);
  };

  const styles = useStyles({});

  if (!currentDocument) return null;

  return (
    <div className={styles.documentViewerContainer}>
      <div className={styles.documentViewer}>
        {!isImage && (
          <div
            style={{display: 'flex', justifyContent: 'center'}}
            onClick={() => {
              if (isImage) {
                onImageRotate();
              } else {
                setRotate((prev) => prev + 1);
              }
            }}>
            <RotateRight />
          </div>
        )}
        {!isLoading ? (
          isImage ? (
            <ImageRenderer
              uri={docReadUrl}
              canvasRef={canvasRef}
              imageScale={imageScale}
              setImageScale={setImageScale}
              onZoomClick={onZoomClick}
            />
          ) : (
            <>
              <div className={styles.zoomContainer}>
                <div
                  onClick={(e) =>
                    setPdfScale((prev) => Math.round((prev - 0.1) * 10) / 10)
                  }>
                  <ZoomOut />
                </div>
                <Typography>{pdfScale}</Typography>
                <div
                  onClick={(e) =>
                    setPdfScale((prev) => Math.round((prev + 0.1) * 10) / 10)
                  }>
                  <ZoomIn />
                </div>
              </div>
              <div className={styles.pdfRenderer} id="pdf-renderer">
                {showPDF && (
                  <Document
                    file={docReadUrl}
                    onLoadSuccess={onPDFDocumentLoadSuccess}
                    onPassword={onPassword}
                    rotate={rotate * 90}>
                    {Array.from(new Array(numPages), (el, index) => (
                      <div
                        className={styles.pdfPageRenderer}
                        id={`Page-${index + 1}`}>
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          canvasRef={(ref) =>
                            (canvasPDFRef.current[index + 1] = ref)
                          }
                          scale={pdfScale}
                        />
                      </div>
                    ))}
                  </Document>
                )}
              </div>
            </>
          )
        ) : (
          <FullScreenLoading open={isLoading} />
        )}
      </div>
    </div>
  );
};

export default RenderDocument;
