import {
  TaxProfileSection,
  TaxProfileSectionSequence,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import UserDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/UserDetails/UserDetails';
import HomeAddress from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/HomeAddress/HomeAddress';
import SpouseDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/SpouseDetails/SpouseDetails';
import DependentDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/DependentDetails/DependentDetails';
import BusinessDetails from 'src/CpaCenterV2/TaxProfileSections/BasicDetails/components/BusinessDetails/BusinessDetails';
import HomeOfficeDetails from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/HomeOfficeDetails';
import IncomeTypeCard from 'src/CpaCenterV2/TaxProfileSections/Income/components/IncomeTypeCard';
import BusinessVehicleDetails from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/BusinessVehicleDetails';
import PhoneAndInternet from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/PhoneAndInternet';
import OfficeMortgage from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/OfficeMortgage';
import PaymentsSection from 'src/CpaCenterV2/TaxProfileSections/TaxPayments/components/PaymentsSection';
import StatesTaxFilingInformation from 'src/CpaCenterV2/TaxProfileSections/StateTaxes/components/StatesTaxFilingInformation';
import RentalIncome from 'src/CpaCenterV2/TaxProfileSections/Income/components/RentalIncome';
import DependentsCredits from 'src/CpaCenterV2/TaxProfileSections/Credits/components/DependentsCredits';
import EducationalCredits from 'src/CpaCenterV2/TaxProfileSections/Credits/components/EducationalCredits';
import AdoptionCredits from 'src/CpaCenterV2/TaxProfileSections/Credits/components/AdoptionCredits';
import StudentLoan from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/StudentLoan';
import BusinessLoans from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/BusinessLoans';
import SalaryAndWagesPaid from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/SalaryAndWagesPaid';
import OtherDepreciations from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/OtherDepreciations';
import HSAContributions from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/HSAContributions';
import IRAContributions from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/IRAContributions';
import HealthInsurancePremium from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/HealthInsurancePremium';
import PersonalDeductions from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/PersonalDeductions';
import AllOtherDeductions from 'src/CpaCenterV2/TaxProfileSections/Deductions/components/AllOtherDeductions';
import TaxProfileSummary from 'src/CpaCenterV2/common/TaxProfileSummary/components/TaxProfileSummary';
import CryptoIncome from 'src/CpaCenterV2/TaxProfileSections/Income/components/CryptoIncome';

export const GENERAL_SECTION = 'GENERAL_SECTION';

export const TaxProfileSectionsDropdownChoices = [
  {label: 'General', value: GENERAL_SECTION},
  ...TaxProfileSectionSequence.map((k) => ({
    label: k.stepName,
    value: k.section,
  })),
];

export const TaxProfileSubsectionToComponentMap = {
  //Basic Details
  [TaxProfileSubsection.UserDetails]: <UserDetails />,
  [TaxProfileSubsection.HomeAddress]: <HomeAddress />,
  [TaxProfileSubsection.SpouseDetails]: <SpouseDetails />,
  [TaxProfileSubsection.DependentDetails]: <DependentDetails />,
  [TaxProfileSubsection.BusinessDetails]: <BusinessDetails />,

  // Deductions
  [TaxProfileSubsection.HomeOfficeDetails]: <HomeOfficeDetails />,
  [TaxProfileSubsection.BusinessVehicleDetails]: <BusinessVehicleDetails />,
  [TaxProfileSubsection.PhoneAndInternet]: <PhoneAndInternet />,
  [TaxProfileSubsection.OfficeMortgage]: <OfficeMortgage />,
  [TaxProfileSubsection.StudentLoan]: <StudentLoan />,
  [TaxProfileSubsection.BusinessLoans]: <BusinessLoans />,
  [TaxProfileSubsection.SalaryAndWagesPaid]: <SalaryAndWagesPaid />,
  [TaxProfileSubsection.OtherDepreciations]: <OtherDepreciations />,
  [TaxProfileSubsection.HSAContributions]: <HSAContributions />,
  [TaxProfileSubsection.IRAContributions]: <IRAContributions />,
  [TaxProfileSubsection.HealthInsurancePremium]: <HealthInsurancePremium />,
  [TaxProfileSubsection.PersonalDeductions]: <PersonalDeductions />,
  [TaxProfileSubsection.AllOtherDeductions]: <AllOtherDeductions />,

  // Income
  [TaxProfileSubsection.Freelance]: (
    <IncomeTypeCard
      name={'Freelance Income'}
      subsection={TaxProfileSubsection.Freelance}
    />
  ),
  [TaxProfileSubsection.W2Income]: (
    <IncomeTypeCard
      name={'W-2 Income'}
      subsection={TaxProfileSubsection.W2Income}
    />
  ),
  [TaxProfileSubsection.Interest]: (
    <IncomeTypeCard
      name={'Interest Income'}
      subsection={TaxProfileSubsection.Interest}
    />
  ),
  [TaxProfileSubsection.Dividend]: (
    <IncomeTypeCard
      name={'Dividend Income'}
      subsection={TaxProfileSubsection.Dividend}
    />
  ),
  [TaxProfileSubsection.Investment]: (
    <IncomeTypeCard
      name={'Investment Income'}
      subsection={TaxProfileSubsection.Investment}
    />
  ),
  [TaxProfileSubsection.Gambling]: (
    <IncomeTypeCard
      name={'Gambling Income'}
      subsection={TaxProfileSubsection.Gambling}
    />
  ),
  [TaxProfileSubsection.Government]: (
    <IncomeTypeCard
      name={'Government Income'}
      subsection={TaxProfileSubsection.Government}
    />
  ),
  [TaxProfileSubsection.Retirement]: (
    <IncomeTypeCard
      name={'Retirement Income'}
      subsection={TaxProfileSubsection.Retirement}
    />
  ),
  [TaxProfileSubsection.HSA]: (
    <IncomeTypeCard
      name={'HSA Income'}
      subsection={TaxProfileSubsection.HSA}
    />
  ),
  [TaxProfileSubsection.K1_INCOME]: (
    <IncomeTypeCard
      name={'K-1 Income'}
      subsection={TaxProfileSubsection.K1_INCOME}
    />
  ),
  [TaxProfileSubsection.RentalIncome]: <RentalIncome />,
  [TaxProfileSubsection.DigitalAssetIncome]: <CryptoIncome />,

  // Credits
  [TaxProfileSubsection.DependentCredits]: <DependentsCredits />,
  [TaxProfileSubsection.EducationalCredits]: <EducationalCredits />,
  [TaxProfileSubsection.AdoptionCredits]: <AdoptionCredits />,

  // Tax Payments
  [TaxProfileSubsection.Payments]: <PaymentsSection />,

  // States
  [TaxProfileSubsection.StatesTaxFilingInformation]: (
    <StatesTaxFilingInformation />
  ),

  //General
  [GENERAL_SECTION]: (
    <TaxProfileSummary allowNavigateToTaxProfileReview={true} />
  ),
};

export const TaxProfileSectionToDefaultSubsectionMap = {
  [TaxProfileSection.BasicDetails]: TaxProfileSubsection.UserDetails,
  [TaxProfileSection.Income]: TaxProfileSubsection.Freelance,
  [TaxProfileSection.Deductions]: TaxProfileSubsection.HomeOfficeDetails,
  [TaxProfileSection.Credits]: TaxProfileSubsection.DependentCredits,
  [TaxProfileSection.TaxPayments]: TaxProfileSubsection.Payments,
  [TaxProfileSection.StateTaxes]:
    TaxProfileSubsection.StatesTaxFilingInformation,
  [GENERAL_SECTION]: GENERAL_SECTION,
};

const getTaxProfileSubsectionChoices = (section: TaxProfileSection) => {
  return TaxProfileSectionToSubsectionMapping[section].map((subsection) => ({
    label: subsection,
    value: subsection,
  }));
};

export const TaxProfileSectionToSubsectionDropdownChoices = {
  [TaxProfileSection.BasicDetails]: getTaxProfileSubsectionChoices(
    TaxProfileSection.BasicDetails,
  ),
  [TaxProfileSection.Income]: getTaxProfileSubsectionChoices(
    TaxProfileSection.Income,
  ),
  [TaxProfileSection.Deductions]: getTaxProfileSubsectionChoices(
    TaxProfileSection.Deductions,
  ),
  [TaxProfileSection.Credits]: getTaxProfileSubsectionChoices(
    TaxProfileSection.Credits,
  ),
  [TaxProfileSection.TaxPayments]: getTaxProfileSubsectionChoices(
    TaxProfileSection.TaxPayments,
  ),
  [TaxProfileSection.StateTaxes]: getTaxProfileSubsectionChoices(
    TaxProfileSection.StateTaxes,
  ),
  [GENERAL_SECTION]: [
    {
      value: GENERAL_SECTION,
      label: 'General',
    },
  ],
};
