import React from 'react';
import {Checkbox} from '@mui/material';

interface DSCheckBoxProps {
  isChecked: boolean;
  onChange: (newValue: boolean) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DSCheckBox = ({
  isChecked = false,
  onChange,
  style = {},
  disabled = false,
  onClick,
}: DSCheckBoxProps) => {
  return (
    <Checkbox
      onClick={onClick}
      disabled={disabled}
      style={{width: '20px', padding: 0, ...style}}
      onChange={(e) => onChange(e.target.checked)}
      checked={isChecked}
    />
  );
};

export default DSCheckBox;
