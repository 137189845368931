import React from 'react';
import ReadOnlyCell from '../Cells/ReadOnlyCell';
import {themmeColor} from 'src/constants/constants';
import {useDistributDeductions} from '../../useDistributeDeductions';
import {
  BusinessInfo,
  SplitBusinessType,
} from 'src/store/distributeDeductions/distributeDeductions.reducer';

const TitleRow = () => {
  const {business_info, getCellColor} = useDistributDeductions();

  const getBizName = (biz: BusinessInfo) => {
    if (biz.business_type === SplitBusinessType.ACTUAL_BUSINESS) {
      return `${biz.business_name} (${biz.business_sub_type})`;
    }
    return `${biz.business_name} (Rental)`;
  };
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: 40,
      }}>
      <ReadOnlyCell value={''} backgroundColor={themmeColor.cpaCenterV2Bg} />
      <ReadOnlyCell
        value={'Total'}
        textStyle={{
          textTransform: 'uppercase',
          textAlign: 'center',
          fontSize: 11,
          fontWeight: 600,
          color: themmeColor.black60,
        }}
      />
      {business_info.map((biz, index) => {
        return (
          <ReadOnlyCell
            value={getBizName(biz)}
            backgroundColor={getCellColor(index)}
            textStyle={{
              textAlign: 'center',
              fontSize: 11,
              fontWeight: 600,
              color: themmeColor.black60,
            }}
          />
        );
      })}
    </div>
  );
};

export default TitleRow;
