import React from 'react';

const UploadCloudIcon = ({color = '#1A1A1A'}: {color?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M15.9995 16L11.9995 12M11.9995 12L7.99947 16M11.9995 12V21M20.3895 18.39C21.3648 17.8583 22.1353 17.0169 22.5793 15.9986C23.0234 14.9804 23.1157 13.8432 22.8417 12.7667C22.5677 11.6901 21.943 10.7355 21.0661 10.0534C20.1893 9.37137 19.1103 9.00072 17.9995 8.99998H16.7395C16.4368 7.82923 15.8726 6.74232 15.0894 5.82098C14.3062 4.89964 13.3243 4.16783 12.2176 3.68059C11.1108 3.19335 9.90802 2.96334 8.69959 3.00787C7.49116 3.05239 6.30854 3.37028 5.24065 3.93765C4.17276 4.50501 3.24738 5.30709 2.53409 6.28357C1.8208 7.26004 1.33816 8.38552 1.12245 9.57538C0.906742 10.7652 0.963577 11.9885 1.28869 13.1532C1.61379 14.318 2.19871 15.3938 2.99947 16.3"
        stroke={color}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadCloudIcon;
