import React from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {MessageAuthorType} from 'src/store/queries/queries.reducer';
import ReactHtmlParser from 'react-html-parser';
import Box from 'src/DesignSystem/Box/Box';
import {MessageProps} from './Message';
import MessageWrapper from './MessageWrapper';

export const NormalMessage = ({message, query}: MessageProps) => {
  const isFromUser = message.authorType === MessageAuthorType.END_USER;

  return (
    <MessageWrapper query={query} message={message}>
      <Box
        style={{
          display: 'flex',
          paddingBlock: 12,
          paddingInline: 16,
          flexDirection: 'column',
          maxWidth: '35vw',
          backgroundColor: !isFromUser ? themmeColor.textPurple : '#F4F1F9',
          ...(isFromUser
            ? {borderBottomLeftRadius: 0}
            : {borderBottomRightRadius: 0}),
        }}>
        <Typography
          style={{
            color: isFromUser ? themmeColor.textPurple : themmeColor.white,
            overflow: 'auto',
          }}>
          {ReactHtmlParser(message.message)}
        </Typography>
      </Box>
    </MessageWrapper>
  );
};
