import {BusinessDetail} from '../businessDetails/businessDetails.types';

export enum TaxFormType {
  FORM_1099_B = 'FORM_1099_B',
  W2_G_FORM = 'W2_G_FORM',
  FORM_1099_SA = 'FORM_1099_SA',
  MANUAL_FREELANCE_INCOME_FORM = 'MANUAL_FREELANCE_INCOME_FORM',
  W2_FORM = 'W2_FORM',
  FORM_1099_NEC = 'FORM_1099_NEC',
  FORM_1099_MISC = 'FORM_1099_MISC',
  FORM_1099_K = 'FORM_1099_K',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  FORM_1099_INT = 'FORM_1099_INT',
  FORM_1098_E = 'FORM_1098_E',
  FORM_1098_T = 'FORM_1098_T',
  FORM_1099_DIV = 'FORM_1099_DIV',
  FORM_1099_R = 'FORM_1099_R',
  FORM_1099_G = 'FORM_1099_G',
  FORM_1095_A = 'FORM_1095_A',
  FORM_1120 = 'FORM_1120',
  FORM_1120_S = 'FORM_1120_S',
  FORM_1065 = 'FORM_1065',
  OTHER = 'OTHER',
  FORM_1040_NORMAL = 'FORM_1040_NORMAL',
  FORM_1040_SCHEDULE_1 = 'FORM_1040_SCHEDULE_1',
  FORM_1040_SCHEDULE_C = 'FORM_1040_SCHEDULE_C',
  FORM_1040_SCH_K1_1120 = 'FORM_1040_SCH_K1_1120',
  FORM_1040_SCH_K1_1065 = 'FORM_1040_SCH_K1_1065',
  FORM_1040_SCHEDULE_K1 = 'FORM_1040_SCHEDULE_K1',
  BUSINESS_REG_FORM = 'BUSINESS_REG_FORM',
  DEPENDENT = 'DEPENDENT',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  DEPRECIATION = 'DEPRECIATION',
  FORM_1098_MIS = 'FORM_1098_MIS',
  BUSINESS_VEHICLE = 'BUSINESS_VEHICLE',
  HOME_DEPRECIATION = 'HOME_DEPRECIATION',
  CHARITY_CONTRIBUTION_RECEIPT = 'CHARITY_CONTRIBUTION_RECEIPT',
  FORM_SA_5498 = 'FORM_SA_5498',
  STATES_LIVED = 'STATES_LIVED',
  QUARTERLY_TAX_PAYMENTS = 'QUARTERLY_TAX_PAYMENTS',
  RENTAL_INCOME = 'RENTAL_INCOME',
  FORM_K1_1120S = 'FORM_K1_1120S',
  FORM_K1_1065 = 'FORM_K1_1065',
  BOIR_BENEFICIAL_OWNER_DETAILS = 'BOIR_BENEFICIAL_OWNER_DETAILS',
  CHILD_CARE_EXPENSE = 'CHILD_CARE_EXPENSE',
  PROFIT_LOSS_FORM = 'PROFIT_LOSS_FORM',
  BALANCE_SHEET_FORM = 'BALANCE_SHEET_FORM',
}

export enum TaxFormFieldIds {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  ISSUING_STATE = 'issuing_state',
  ALLOCATED_TIPS = 'allocated_tips',
  ACCOUNT_NUMBER = 'account_number',
  AGRICULTURE_PAYMENTS = 'agriculture_payments',
  TXN_IDS = 'txn_ids',
  BOX_12_A = 'box_12_a',
  BOX_12_B = 'box_12_b',
  BOX_12_C = 'box_12_c',
  BOX_12_D = 'box_12_d',
  BOX_2_AMOUNT = 'box_2_amount',
  BOX_2_TRADE_OR_BUSINESS_INCOME = 'box_2_trade_or_business_income',
  BOND_PREMIUM = 'bond_premium',
  BORROWER_ADDRESS = 'borrower_address',
  BORROWER_CITY = 'borrower_city',
  BORROWER_NAME = 'borrower_name',
  BORROWER_STATE = 'borrower_state',
  BORROWER_TIN = 'borrower_tin',
  BORROWER_ZIP_CODE = 'borrower_zip_code',
  CONTROL_NUMBER = 'control_number',
  CROP_INSURANCE_PROCEEDS = 'crop_insurance_proceeds',
  CUSIP_NO = 'cusip_no',
  COLLECTIBLES_GAIN = 'collectibles_gain',
  CASH_LIQUIDATION_DISTRIBUTIONS = 'cash_liquidation_distributions',
  COVERED_INDIVIDUAL_DETAILS = 'covered_individual_details',
  DEPENDENT_CARE_BENEFITS = 'dependent_care_benefits',
  DIRECT_SALES_MTE_5000 = 'direct_sales_mte_5000',
  DIVIDENDS_OR_INTEREST_SUBSTITUTE_PAYMENTS = 'dividends_or_interest_substitute_payments',
  DATE_OF_BIRTH = 'date_of_birth',
  DL_EXPIRY_DATE = 'dl_expiry_date',
  DL_NUMBER = 'dl_number',
  ISSUE_DATE = 'issue_date',
  DOC_NUMBER = 'doc_number',
  IS_SPOUSE = 'is_spouse',
  DISTRIBUTION_CODES = 'distribution_codes',
  DATE_OF_PURCHASE = 'date_of_purchase',
  DATE_PUT_IN_SERVICE = 'date_put_in_service',
  EMPLOYEE_ADDRESS = 'employee_address',
  EMPLOYEE_CITY = 'employee_city',
  EMPLOYEE_FIRST_NAME = 'employee_first_name',
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  EMPLOYEE_SSN = 'employee_ssn',
  EMPLOYEE_STATE = 'employee_state',
  EMPLOYEE_ZIP_CODE = 'employee_zip_code',
  EMPLOYEE_CONTRIBUTIONS = 'employee_contributions',
  EMPLOYER_ADDRESS = 'employer_address',
  EMPLOYER_CITY = 'employer_city',
  EMPLOYER_EIN = 'employer_ein',
  EMPLOYER_NAME = 'employer_name',
  EMPLOYER_STATE = 'employer_state',
  EMPLOYER_STATE_ID_NUMBER = 'employer_state_id_number',
  EMPLOYER_ZIP_CODE = 'employer_zip_code',
  EXCESS_GOLDEN_PARACHUTE_PAYMENTS = 'excess_golden_parachute_payments',
  EARLY_WITHDRAWAL_PENALTY = 'early_withdrawal_penalty',
  EXEMPT_INTEREST_DIVIDENDS = 'exempt_interest_dividends',
  FEDERAL_WITHHELD = 'federal_withheld',
  FISHING_BOAT_PROCEEDS = 'fishing_boat_proceeds',
  FISH_PURCHASED_FOR_RESALE = 'fish_purchased_for_resale',
  FATCA_FILING_REQUIREMENT = 'fatca_filing_requirement',
  FILER_ADDRESS = 'filer_address',
  FILER_CITY = 'filer_city',
  FILER_NAME = 'filer_name',
  FILER_STATE = 'filer_state',
  FILER_ZIP_CODE = 'filer_zip_code',
  FILER_FIN = 'filer_fin',
  FILER_TIN = 'filer_tin',
  GROSS_PROCEEDS_TO_ATTORNEY = 'gross_proceeds_to_attorney',
  GRADUATE_STUDENT = 'graduate_student',
  GROSS_DISTRIBUTION = 'gross_distribution',
  INTEREST_INCOME = 'interest_income',
  INVESTMENT_EXPENSES = 'investment_expenses',
  INCLUDES_AMOUNTS_CURR_YEAR_ACADEMIC = 'includes_amounts_curr_year_academic',
  INSURANCE_CONTRACT_REFUNDS = 'insurance_contract_refunds',
  IRR_ALLOCABLE_AMOUNT = 'irr_allocable_amount',
  IRA_SEP_SIMPLE = 'ira_sep_simple',
  ITEM_NAME = 'item_name',
  ITEM_VALUE = 'item_value',
  INTEREST_PAID = 'interest_paid',
  LOCAL_INCOME_TAX = 'local_income_tax',
  LOCAL_WAGES_TIPS_ETC = 'local_wages_tips_etc',
  LOCALITY_NAME = 'locality_name',
  LOCAL_WITHHELD = 'local_withheld',
  LOCAL_DISTRIBUTION = 'local_distribution',
  LOAN_AMOUNT_PAID = 'loan_amount_paid',
  MEDICARE_TAX_WITHHELD = 'medicare_tax_withheld',
  MEDICARE_WAGES_N_TIPS = 'medicare_wages_n_tips',
  MEDICAL_CARE_PAYMENTS = 'medical_care_payments',
  MARKET_DISCOUNT = 'market_discount',
  MARKET_GAIN = 'market_gain',
  MARKETPLACE_IDENTIFIER = 'marketplace_identifier',
  MARKETPLACE_APN = 'marketplace_apn',
  MORTGAGE_INTEREST_RECEIVED = 'mortgage_interest_received',
  MORTGAGE_ORIGINATION_DATE = 'mortgage_origination_date',
  MORTGAGE_INSURANCE_PREMIUMS = 'mortgage_insurance_premiums',
  MORTGAGE_SECURING_PROPERTY_ADDRESS = 'mortgage_securing_property_address',
  MORTGAGE_TYPE = 'mortgage_type',
  NON_EMPLOYEE_COMPENSATION = 'non_employee_compensation',
  NON_QUALIFIED_PLANS = 'non_qualified_plans',
  NON_QUALIFIED_DEFERRED_COMPENSATION = 'non_qualified_deferred_compensation',
  NON_DIVIDEND_DISTRIBUTIONS = 'non_dividend_distributions',
  NON_CASH_LIQUIDATION_DISTRIBUTIONS = 'non_cash_liquidation_distributions',
  OTHER = 'other',
  OTHER_INCOME = 'other_income',
  OUTSTANDING_MORTGAGE_PRINCIPAL = 'outstanding_mortgage_principal',
  PAYER_ADDRESS = 'payer_address',
  PAYER_CITY = 'payer_city',
  PAYER_NAME = 'payer_name',
  PAYER_STATE = 'payer_state',
  PAYER_TIN = 'payer_tin',
  PAYER_ZIP_CODE = 'payer_zip_code',
  PAID_FOREIGN_TAX = 'paid_foreign_tax',
  POSSESSION = 'possession',
  PRIVATE_ACTIVITY_BOND_INTEREST = 'private_activity_bond_interest',
  PCR_TOTAL_DISTRIBUTION = 'pcr_total_distribution',
  PAYMENT_DATE = 'payment_date',
  PRIOR_YEAR_ADJUSTMENTS = 'prior_year_adjustments',
  POLICY_ISSUER_NAME = 'policy_issuer_name',
  POLICY_START_DATE = 'policy_start_date',
  POLICY_TERMINATION_DATE = 'policy_termination_date',
  PROPERTIES_SECURING_MORTGAGE = 'properties_securing_mortgage',
  PRINCIPAL_RESIDENCE_PURCHASE_POINTS = 'principal_residence_purchase_points',
  QUALIFIED_DIVIDENDS = 'qualified_dividends',
  RECIPIENT_ADDRESS = 'recipient_address',
  RECIPIENT_CITY = 'recipient_city',
  RECIPIENT_NAME = 'recipient_name',
  RECIPIENT_SPOUSE_NAME = 'recipient_spouse_name',
  RECIPIENT_SPOUSE_SSN = 'recipient_spouse_ssn',
  RECIPIENT_SPOUSE_DOB = 'recipient_spouse_dob',
  RECIPIENT_STATE = 'recipient_state',
  RECIPIENT_TIN = 'recipient_tin',
  RECIPIENT_SSN = 'recipient_ssn',
  RECIPIENT_DOB = 'recipient_dob',
  RECIPIENT_ZIP_CODE = 'recipient_zip_code',
  RETIREMENT_PLAN = 'retirement_plan',
  RENTS = 'rents',
  ROYALTIES = 'royalties',
  RECEIVED_TUITION_RELATED_PAYMENT = 'received_tuition_related_payment',
  ROTH_CONTRIBUTION = 'roth_contribution',
  RTAA_PAYMENTS = 'rtaa_payments',
  REFUND_OF_OVERPAID_INTEREST = 'refund_of_overpaid_interest',
  SS_TAX_WITHHELD = 'ss_tax_withheld',
  SS_TIPS = 'ss_tips',
  SS_WAGES = 'ss_wages',
  STATE = 'state',
  STATE_INCOME = 'state_income',
  STATE_INCOME_TAX = 'state_income_tax',
  STATE_WAGES_TIPS_ETC = 'state_wages_tips_etc',
  STATE_WITHHELD = 'state_withheld',
  STATUTORY_EMPLOYEE = 'statutory_employee',
  SECTION_409A_DEFERRALS = 'section_409a_deferrals',
  SECOND_TIN_NOT = 'second_tin_not',
  STATE_ID_NO = 'state_id_no',
  STUDENT_LOAN_INTEREST = 'student_loan_interest',
  STUDENT_LOAN_INTEREST_CHECKBOX = 'student_loan_interest_checkbox',
  STUDENT_NAME = 'student_name',
  STUDENT_ADDRESS = 'student_address',
  STUDENT_CITY = 'student_city',
  STUDENT_STATE = 'student_state',
  STUDENT_TIN = 'student_tin',
  STUDENT_ZIP_CODE = 'student_zip_code',
  SERVICE_PROVIDER_NO = 'service_provider_no',
  STUDENT_HALF_TIME = 'student_half_time',
  SCHOLARSHIPS_GRANTS = 'scholarships_grants',
  SEC_1250_GAIN = 'sec_1250_gain',
  SECTION_1202_GAIN = 'section_1202_gain',
  SECTION_897_DIVIDENDS = 'section_897_dividends',
  SECTION_897_CAPITAL_GAIN = 'section_897_capital_gain',
  SECTION_199A_DIVIDENDS = 'section_199a_dividends',
  SCHOLARSHIPS_GRANTS_ADJUSTMENTS = 'scholarships_grants_adjustments',
  STATE_DISTRIBUTION = 'state_distribution',
  STATE_TAX_REFUND_CREDIT_OFFSET = 'state_tax_refund_credit_offset',
  THIRD_PARTY_SICK_PAY = 'third_party_sick_pay',
  TAX_EXEMPT_INTEREST = 'tax_exempt_interest',
  TREASURY_OBLIGATION_BOND_PREMIUM = 'treasury_obligation_bond_premium',
  TAX_EXEMPT_BOND_PREMIUM = 'tax_exempt_bond_premium',
  TOTAL_ORDINARY_DIVIDENDS = 'total_ordinary_dividends',
  TOTAL_CAPITAL_GAIN = 'total_capital_gain',
  TAXABLE_AMOUNT = 'taxable_amount',
  TAXABLE_AMOUNT_NOT_DETERMINED = 'taxable_amount_not_determined',
  TOTAL_DISTRIBUTION = 'total_distribution',
  TOTAL_EMPLOYEE_CONTRIBUTIONS = 'total_employee_contributions',
  TAXABLE_GRANTS = 'taxable_grants',
  UNEMPLOYMENT_COMPENSATION = 'unemployment_compensation',
  US_BOND_INTEREST = 'us_bond_interest',
  UNREALIZED_APPRECIATION = 'unrealized_appreciation',
  VEHICLE_EXPENSE_TYPE = 'vehicle_expense_type',
  TOTAL_MILES = 'total_miles',
  BUSINESS_MILES = 'business_miles',
  WAGES_TIPS_ETC = 'wages_tips_etc',
  JAN_ENROLLMENT_PREMIUMS = 'jan_enrollment_premiums',
  JAN_SLCSP = 'jan_slcsp',
  JAN_ADVANCE_PAYMENT = 'jan_advance_payment',
  FEB_ENROLLMENT_PREMIUMS = 'feb_enrollment_premiums',
  FEB_SLCSP = 'feb_slcsp',
  FEB_ADVANCE_PAYMENT = 'feb_advance_payment',
  MAR_ENROLLMENT_PREMIUMS = 'mar_enrollment_premiums',
  MAR_SLCSP = 'mar_slcsp',
  MAR_ADVANCE_PAYMENT = 'mar_advance_payment',
  APR_ENROLLMENT_PREMIUMS = 'apr_enrollment_premiums',
  APR_SLCSP = 'apr_slcsp',
  APR_ADVANCE_PAYMENT = 'apr_advance_payment',
  MAY_ENROLLMENT_PREMIUMS = 'may_enrollment_premiums',
  MAY_SLCSP = 'may_slcsp',
  MAY_ADVANCE_PAYMENT = 'may_advance_payment',
  JUN_ENROLLMENT_PREMIUMS = 'jun_enrollment_premiums',
  JUN_SLCSP = 'jun_slcsp',
  JUN_ADVANCE_PAYMENT = 'jun_advance_payment',
  JUL_ENROLLMENT_PREMIUMS = 'jul_enrollment_premiums',
  JUL_SLCSP = 'jul_slcsp',
  JUL_ADVANCE_PAYMENT = 'jul_advance_payment',
  AUG_ENROLLMENT_PREMIUMS = 'aug_enrollment_premiums',
  AUG_SLCSP = 'aug_slcsp',
  AUG_ADVANCE_PAYMENT = 'aug_advance_payment',
  SEPT_ENROLLMENT_PREMIUMS = 'sept_enrollment_premiums',
  SEPT_SLCSP = 'sept_slcsp',
  SEPT_ADVANCE_PAYMENT = 'sept_advance_payment',
  OCT_ENROLLMENT_PREMIUMS = 'oct_enrollment_premiums',
  OCT_SLCSP = 'oct_slcsp',
  OCT_ADVANCE_PAYMENT = 'oct_advance_payment',
  NOV_ENROLLMENT_PREMIUMS = 'nov_enrollment_premiums',
  NOV_SLCSP = 'nov_slcsp',
  NOV_ADVANCE_PAYMENT = 'nov_advance_payment',
  DEC_ENROLLMENT_PREMIUMS = 'dec_enrollment_premiums',
  DEC_SLCSP = 'dec_slcsp',
  DEC_ADVANCE_PAYMENT = 'dec_advance_payment',
  PROPERTY_DESCRIPTION = 'property_description',
  ACQUIRED_DATE = 'acquired_date',
  SOLD_OR_DISPOSED_DATE = 'sold_or_disposed_date',
  PROCEEDS = 'proceeds',
  COST_OR_OTHER_BASIS = 'cost_or_other_basis',
  ACCRUED_MARKET_DISCOUNT = 'accrued_market_discount',
  WASH_SALE_LOSS_DISALLOWED = 'wash_sale_loss_disallowed',
  GAIN_OR_LOSS = 'gain_or_loss',
  PROCEEDS_FROM = 'proceeds_from',
  NON_COVERED_SECURITY = 'non_covered_security',
  REPORTED_TO_IRS = 'reported_to_irs',
  LOSS_NOT_ALLOWED = 'loss_not_allowed',
  REALIZED_PROFIT_LOSS = 'realized_profit_loss',
  PREV_UNREALIZED_PROFIT_LOSS = 'prev_unrealized_profit_loss',
  CURR_UNREALIZED_PROFIT_LOSS = 'curr_unrealized_profit_loss',
  AGGREGATE_PROFIT_LOSS = 'aggregate_profit_loss',
  BASIS_REPORTED_TO_IRS = 'basis_reported_to_irs',
  BARTERING = 'bartering',
  PAYER_IDENTIFICATION_NUMBER = 'payer_identification_number',
  WINNING_REPORTABLE = 'winning_reportable',
  DATE_WON = 'date_won',
  TYPE_OF_WAGER = 'type_of_wager',
  RACE = 'race',
  WINNINGS_FROM_IDENTICAL_WAGERS = 'winnings_from_identical_wagers',
  CASHIER = 'cashier',
  WINNERS_TAX_PAYER_IDENTIFICATION_NUMBER = 'winners_tax_payer_identification_number',
  WINDOW = 'window',
  FIRST_IDENTIFICATION = 'first_identification',
  SECOND_IDENTIFICATION = 'second_identification',
  PAYERS_STATE_IDENTIFICATION_NO = 'payers_state_identification_no',
  STATE_WINNINGS = 'state_winnings',
  LOCAL_WINNINGS = 'local_winnings',
  LOCAL_INCOME_TAX_WITHELD = 'local_income_tax_withheld',
  TRUSTEE_NAME = 'trustee_name',
  EARNINGS_ON_EXCESS_CONT = 'earnings_on_excess_cont',
  FMV_ON_DATE_OF_DEATH = 'fmv_on_date_of_death',
  AMOUNT = 'amount',
  INCOME_EARNER = 'income_earner',
  INDICATE_FILER_IS = 'indicate_filer_is',
  INDICATE_TRANSACTIONS_REPORTED = 'indicate_transactions_reported',
  PSE_NAME = 'pse_name',
  TELEPHONE_NUMBER = 'telephone_number',
  GROSS_AMOUNT_PAYMENT_CARD = 'gross_amount_payment_card',
  CARD_NOT_PRESSENT_TRANSACTIONS = 'card_not_present_transactions',
  MERCHANT_CATEGORY_CODE = 'merchant_category_code',
  NUMBER_OF_PAYMENT_TRANSACTIONS = 'number_of_payment_transactions',
  JAN = 'jan',
  FEB = 'feb',
  MAR = 'mar',
  APR = 'apr',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUG = 'aug',
  SEPT = 'sept',
  OCT = 'oct',
  NOV = 'nov',
  DEC = 'dec',
  LINE_5 = 'line_5',
  BUSINESS_ID = 'business_id',
  BUSINESS_NAME = 'business_name',
  BUSINESS_OWNER_PARTNER_NAME = 'business_owner_partner_name',
  MEMBER_TYPE = 'member_type',
  TAX_RETURN = 'tax_return',
  BUSINESS_EIN = 'business_ein',
  RETURN_FILING_DATE = 'return_filing_date',
  NOTICE_DATE = 'notice_date',
  BUSINESS_ADDRESS = 'business_address',
  INCORPORATION_DATE = 'incorporation_date',
  ACCOUNTING_METHOD = 'accounting_method',
  BUSINESS_ACTIVITY_CODE = 'business_activity_code',
  BUSINESS_ACTIVITY = 'business_activity',
  PRODUCT_OR_SERVICE = 'product_or_service',
  ENTITY_SHAREHOLDERS = 'entity_shareholders',
  INDIVIDUAL_SHAREHOLDERS = 'individual_shareholders',
  S_ELECTION_EFFECTIVE_DATE = 's_election_effective_date',
  BUSINESS_START_DATE = 'business_start_date',
  PARTNERS = 'partners',
  PAYMENT_TYPE = 'payment_type',
  TXN_ID = 'txn_id',
  COUNTY_NAME = 'county_name',
  SCHOOL_DISTRICT_CODE = 'school_district_code',
  PERIODS = 'periods',
  DEPENDENT_FIRST_NAME = 'dependent_first_name',
  DEPENDENT_LAST_NAME = 'dependent_last_name',
  DEPENDENT_SSN = 'dependent_social_security_number',
  DEPENDENT_RELATION = 'relationship_to_you',
  DEPENDENT_BIRTHDATE = 'dependent_birthdate',
  DEPENDENT_US_CITIZEN = 'dependent_us_citizen',
  DEPENDENT_ITIN_NUMBER = 'dependent_itin_number',
  DEPENDENT_HAVE_SSN = 'dependent_have_ssn',
  DEPENDENT_ID = 'dependent_id',
  MORE_THAN_HALF_YEAR_SUPPORT = 'more_than_half_year_support',
  LIVED_WITH_YOU_OR_SCHOOL_FOR_HALF_YEAR = 'lived_with_you_or_school_for_half_year',
  LIVED_IN_HOUSECARE_FOR_HALF_YEAR = 'lived_in_house_care_for_half_year',
  IS_STUDENT = 'is_student',
  STUDENT_ELIGIBILITY = 'student_eligibility',
  IS_ADOPTED = 'is_adopted',
  IS_INCAPABLE = 'is_incapable',
  DID_EARN_MORE_THAN_4700 = 'did_earn_more_than_4700',
  IS_FILING_JOINT_TAX_RETURN_THIS_YEAR = 'is_filing_joint_tax_return_this_year',
  IS_FULL_TIME_STUDENT = 'is_full_time_student',
  LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR = 'lived_with_tax_payer_for_whole_year',

  TAX_FILING_YEAR = 'tax_filing_year',
  ANNUAL_COVERAGE_INFO = 'annual_coverage_info',
  MAKE_YEAR = 'make_year',
  NUMBER_AND_STREET = 'number_and_street',
  APARTMENT_NUMBER = 'apartment_number',
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  LIVED_FOR = 'lived_for',
  COUNTY = 'county',
  INCOME_EARNED = 'income_earned',
  TYPE_OF_PROPERTY = 'type_of_property',
  NUMBER_OF_DAYS_RENTED_FOR = 'number_of_days_rented_for',
  LINKED_MISC_FORMS = 'linked_misc_forms',
  INCOME_TYPE = 'income_type',
  RENTAL_PROPERTY_NAME = 'rental_property_name',
  LONG_TERM_NET_GAIN = 'long_term_net_gain',
  LONG_TERM_NET_LOSS = 'long_term_net_loss',
  SHORT_TERM_NET_GAIN = 'short_term_net_gain',
  SHORT_TERM_NET_LOSS = 'short_term_net_loss',
  UNDETERMINED_NET_GAIN = 'undetermined_net_gain',
  UNDETERMINED_NET_LOSS = 'undetermined_net_loss',
  ContributionType = 'contribution_type',
  CURRENT_YEAR_ALLOCATION_PERCENT = 'current_year_allocation_percent',
  CORPORATION_CITY = 'corporation_city',
  CORPORATION_STATE = 'corporation_state',
  CORPORATION_ZIP = 'corporation_zip',
  SHAREHOLDER_TIN = 'shareholder_tin',
  PARTNER_CITY = 'partner_city',
  PARTNER_ZIP = 'partner_zip',
  PARTNER_STATE = 'partner_state',
  PARTNER_CAPITAL_SHARE = 'partner_capital_share',
  PARTNER_SSN_OR_TIN = 'partner_ssn_or_tin',
  PARTNER_NAME = 'partner_name',
  PARTNER_ADDRESS = 'partner_address',
  CORPORATION_EIN = 'corporation_ein',
  CORPORATION_NAME = 'corporation_name',
  CORPORATION_ADDRESS = 'corporation_address',
  PARTNERSHIP_NAME = 'partnership_name',
  ORDINARY_BUSINESS_INCOME = 'ordinary_business_income',
  ORDINARY_BUSINESS_LOSS = 'ordinary_business_loss',
  ORDINARY_DIVIDENDS = 'ordinary_dividends',
  RENTAL_REAL_ESTATE_INCOME = 'rental_real_estate_income',
  RENTAL_REAL_ESTATE_LOSS = 'rental_real_estate_loss',
  ALTERNATE_BUSINESS_NAME = 'alternate_business_name',
  BUSINESS_SSN_OR_TIN = 'business_ssn_or_tin',
  REQUEST_FINCEN_ID = 'request_fincen_id',
  IS_COMPANY_FOREIGN_POOLED_INVESTMENT_VEHICLE = 'is_company_foreign_pooled_investment_vehicle',
  IS_FINCEN_ID_PRESENT = 'is_fincen_id_present',
  FINCEN_ID = 'fincen_id',
  INDIVIDUAL_LAST_NAME_OR_ENTITY_NAME = 'individual_last_name_or_entity_name',
  ADDRESS_OUTSIDE_USA = 'address_outside_usa',
  ADDRESS = 'address',
  IS_BENEFICIARY_OWNER_MINOR = 'is_beneficiary_owner_minor',
  DOB = 'dob',
  IP_PIN = 'ip_pin',
  LINKED_CHILD_CARE_DOCS = 'linked_child_care_docs',
  HAVE_CHILD_CARE_EXPENSES = 'have_child_care_expenses',
  CAREGIVER_NAME = 'caregiver_name',
  CAREGIVER_ID_TYPE = 'caregiver_id_type',
  CAREGIVER_SSN = 'caregiver_ssn',
  CAREGIVER_EIN = 'caregiver_ein',
  IS_ELIGIBLE_FOR_DEPENDENT_CREDIT = 'is_eligible_for_dependent_credit',
  IS_ELIGIBLE_FOR_EDUCATION_CREDIT = 'is_eligible_for_education_credit',
  IS_ELIGIBLE_FOR_ADOPTION_CREDIT = 'is_eligible_for_adoption_credit',
  FROM = 'from',
  TO = 'to',
  EXPIRY_DATE = 'expiry_date',
  EXTRACTED_FORM_YEAR = 'extracted_form_year',
  SHORT_TERM_REPORTED_TO_IRS_PROCEEDS = 'short_term_reported_to_irs_proceeds',
  SHORT_TERM_REPORTED_TO_IRS_COST_BASIS = 'short_term_reported_to_irs_cost_basis',
  SHORT_TERM_REPORTED_TO_IRS_MARKET_DISCOUNT = 'short_term_reported_to_irs_market_discount',
  SHORT_TERM_REPORTED_TO_IRS_WASH_SALE_LOSS = 'short_term_reported_to_irs_wash_sale_loss',
  SHORT_TERM_REPORTED_TO_IRS_NET_GAIN_LOSS = 'short_term_reported_to_irs_net_gain_loss',

  SHORT_TERM_NOT_REPORTED_TO_IRS_PROCEEDS = 'short_term_not_reported_to_irs_proceeds',
  SHORT_TERM_NOT_REPORTED_TO_IRS_COST_BASIS = 'short_term_not_reported_to_irs_cost_basis',
  SHORT_TERM_NOT_REPORTED_TO_IRS_MARKET_DISCOUNT = 'short_term_not_reported_to_irs_market_discount',
  SHORT_TERM_NOT_REPORTED_TO_IRS_WASH_SALE_LOSS = 'short_term_not_reported_to_irs_wash_sale_loss',
  SHORT_TERM_NOT_REPORTED_TO_IRS_NET_GAIN_LOSS = 'short_term_not_reported_to_irs_net_gain_loss',

  SHORT_TERM_NOT_RECEIVED_PROCEEDS = 'short_term_not_received_proceeds',
  SHORT_TERM_NOT_RECEIVED_COST_BASIS = 'short_term_not_received_cost_basis',
  SHORT_TERM_NOT_RECEIVED_MARKET_DISCOUNT = 'short_term_not_received_market_discount',
  SHORT_TERM_NOT_RECEIVED_WASH_SALE_LOSS = 'short_term_not_received_wash_sale_loss',
  SHORT_TERM_NOT_RECEIVED_NET_GAIN_LOSS = 'short_term_not_received_net_gain_loss',
  SHORT_TERM_NET_GAIN_LOSS = 'short_term_net_gain_loss',

  LONG_TERM_REPORTED_TO_IRS_PROCEEDS = 'long_term_reported_to_irs_proceeds',
  LONG_TERM_REPORTED_TO_IRS_COST_BASIS = 'long_term_reported_to_irs_cost_basis',
  LONG_TERM_REPORTED_TO_IRS_MARKET_DISCOUNT = 'long_term_reported_to_irs_market_discount',
  LONG_TERM_REPORTED_TO_IRS_WASH_SALE_LOSS = 'long_term_reported_to_irs_wash_sale_loss',
  LONG_TERM_REPORTED_TO_IRS_NET_GAIN_LOSS = 'long_term_reported_to_irs_net_gain_loss',

  LONG_TERM_NOT_REPORTED_TO_IRS_PROCEEDS = 'long_term_not_reported_to_irs_proceeds',
  LONG_TERM_NOT_REPORTED_TO_IRS_COST_BASIS = 'long_term_not_reported_to_irs_cost_basis',
  LONG_TERM_NOT_REPORTED_TO_IRS_MARKET_DISCOUNT = 'long_term_not_reported_to_irs_market_discount',
  LONG_TERM_NOT_REPORTED_TO_IRS_WASH_SALE_LOSS = 'long_term_not_reported_to_irs_wash_sale_loss',
  LONG_TERM_NOT_REPORTED_TO_IRS_NET_GAIN_LOSS = 'long_term_not_reported_to_irs_net_gain_loss',

  LONG_TERM_NOT_RECEIVED_PROCEEDS = 'long_term_not_received_proceeds',
  LONG_TERM_NOT_RECEIVED_COST_BASIS = 'long_term_not_received_cost_basis',
  LONG_TERM_NOT_RECEIVED_MARKET_DISCOUNT = 'long_term_not_received_market_discount',
  LONG_TERM_NOT_RECEIVED_WASH_SALE_LOSS = 'long_term_not_received_wash_sale_loss',
  LONG_TERM_NOT_RECEIVED_NET_GAIN_LOSS = 'long_term_not_received_net_gain_loss',
  LONG_TERM_NET_GAIN_LOSS = 'long_term_net_gain_loss',

  UNDETERMINED_NOT_REPORTED_TO_IRS_PROCEEDS = 'undetermined_not_reported_irs_proceeds',
  UNDETERMINED_NOT_REPORTED_TO_IRS_COST_BASIS = 'undetermined_not_reported_irs_cost_basis',
  UNDETERMINED_NOT_REPORTED_TO_IRS_MARKET_DISCOUNT = 'undetermined_not_reported_irs_market_discount',
  UNDETERMINED_NOT_REPORTED_TO_IRS_WASH_SALE_LOSS = 'undetermined_not_reported_irs_wash_sale_loss',
  UNDETERMINED_NOT_REPORTED_TO_IRS_NET_GAIN_LOSS = 'undetermined_not_reported_irs_net_gain_loss',
  UNDETERMINED_NOT_RECEIVED_PROCEEDS = 'undetermined_not_received_proceeds',
  UNDETERMINED_NOT_RECEIVED_COST_BASIS = 'undetermined_not_received_cost_basis',
  UNDETERMINED_NOT_RECEIVED_MARKET_DISCOUNT = 'undetermined_not_received_market_discount',
  UNDETERMINED_NOT_RECEIVED_WASH_SALE_LOSS = 'undetermined_not_received_wash_sale_loss',
  UNDETERMINED_NOT_RECEIVED_NET_GAIN_LOSS = 'undetermined_not_received_net_gain_loss',
  INCOME = 'income',
  SUB_CATEGORY = 'sub_category',
  KEY = 'key',
  SOURCE = 'source',
  CATEGORY = 'category',
  IS_APPLICABLE = 'is_applicable',
  COST_OF_GOODS_SOLD = 'cost_of_goods_sold',
  GROSS_PROFIT = 'gross_profit',
  EXPENSES = 'expenses',
  NET_OTHER_INCOME = 'net_other_income',
  NET_INCOME = 'net_income',
  L2 = 'l2',
  TYPE_OF_EXPENSE = 'type_of_expense',
  ASSETS = 'assets',
  LIABILITIES = 'liabilities',
  EQUITY = 'equity',
  LINE_ITEMS = 'line_items',
  BALANCE_SHEET_TYPE = 'balance_sheet_type',
}

export enum DependentRelations {
  Son = 'Son',
  Daughter = 'Daughter',
  AdoptedChild = 'Adopted child',
  FosterChild = 'Foster child',
  Stepchild = 'Stepchild',
  Grandchild = 'Grandchild',
  Brother = 'Brother',
  Sister = 'Sister',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Uncle = 'Uncle',
  Aunt = 'Aunt',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Mother = 'Mother',
  Father = 'Father',
  UnrelatedDependent = 'Unrelated Dependent',
  FosterParent = 'Foster Parent',
  Cousin = 'Cousin',
  Friend = 'Friend',
}

export enum MortgageTypes {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  HOME_OFFICE = 'HOME_OFFICE',
}

export enum IdentificationType {
  SSN_OR_ITIN = 'SSN_OR_ITIN',
  EIN = 'EIN',
}

export enum QuarterlyPaymentType {
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  CITY = 'CITY',
  BUSINESS_FEDERAL = 'BUSINESS_FEDERAL',
  BUSINESS_STATE = 'BUSINESS_STATE',
  BUSINESS_CITY = 'BUSINESS_CITY',
}

export enum IncomeEarnerType {
  MYSELF = 'MYSELF',
  MY_SPOUSE = 'MY_SPOUSE',
  BUSINESS = 'BUSINESS',
}

export interface OverWriteFormDataExtraParams {
  taxforms: GenericTaxForm[];
  taxprofileMap: any;
  businesses: BusinessDetail[];
}

type DependentDetailsForm = {
  [TaxFormFieldIds.DEPENDENT_FIRST_NAME]: string | null;
  [TaxFormFieldIds.DEPENDENT_LAST_NAME]: string | null;
  [TaxFormFieldIds.DEPENDENT_SSN]: string | null;
  [TaxFormFieldIds.DEPENDENT_RELATION]: DependentRelations | null;
  [TaxFormFieldIds.DEPENDENT_BIRTHDATE]: string | null;
  [TaxFormFieldIds.DEPENDENT_US_CITIZEN]: boolean | null;
  [TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT]: boolean | null;
  [TaxFormFieldIds.LIVED_WITH_YOU_OR_SCHOOL_FOR_HALF_YEAR]: boolean | null;
  [TaxFormFieldIds.LIVED_IN_HOUSECARE_FOR_HALF_YEAR]: boolean | null;
  [TaxFormFieldIds.IS_STUDENT]: boolean | null;
  [TaxFormFieldIds.STUDENT_ELIGIBILITY]: string[] | null;
  [TaxFormFieldIds.IS_ADOPTED]: boolean | null;
  [TaxFormFieldIds.IS_INCAPABLE]: boolean | null;
  [TaxFormFieldIds.DID_EARN_MORE_THAN_4700]: boolean | null;
  [TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR]: boolean | null;
  [TaxFormFieldIds.IS_FULL_TIME_STUDENT]: boolean | null;
  [TaxFormFieldIds.LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR]: boolean | null;
  [TaxFormFieldIds.IP_PIN]: string | null;
  [TaxFormFieldIds.LINKED_CHILD_CARE_DOCS]: number[] | null;
  [TaxFormFieldIds.HAVE_CHILD_CARE_EXPENSES]: boolean | null;
  [TaxFormFieldIds.IS_ELIGIBLE_FOR_DEPENDENT_CREDIT]: boolean | null;
  [TaxFormFieldIds.IS_ELIGIBLE_FOR_EDUCATION_CREDIT]: boolean | null;
  [TaxFormFieldIds.IS_ELIGIBLE_FOR_ADOPTION_CREDIT]: boolean | null;
};

type TaxPaymentsForm = {
  [TaxFormFieldIds.PAYMENT_TYPE]: null | QuarterlyPaymentType;
  [TaxFormFieldIds.STATE]: null | string;
  [TaxFormFieldIds.AMOUNT]: null | number;
  [TaxFormFieldIds.PAYMENT_DATE]: null | string;
};

type StateLivedForm = {
  [TaxFormFieldIds.NUMBER_AND_STREET]: null | string;
  [TaxFormFieldIds.APARTMENT_NUMBER]: null | string;
  [TaxFormFieldIds.CITY]: null | string;
  [TaxFormFieldIds.STATE]: null | string;
  [TaxFormFieldIds.ZIP_CODE]: null | string;
  [TaxFormFieldIds.LIVED_FOR]: null | number;
  [TaxFormFieldIds.SCHOOL_DISTRICT_CODE]: null | string;
  [TaxFormFieldIds.PERIODS]:
    | null
    | {
        [TaxFormFieldIds.FROM]: null | string;
        [TaxFormFieldIds.TO]: null | string;
      }[];
  [TaxFormFieldIds.INCOME_EARNER]: null | IncomeEarnerType;
  [TaxFormFieldIds.COUNTY]: null | number;
};

type W2Form = {
  [TaxFormFieldIds.EMPLOYEE_SSN]: string | null;
  [TaxFormFieldIds.EMPLOYER_EIN]: string | null;
  [TaxFormFieldIds.EMPLOYER_NAME]: string | null;
  [TaxFormFieldIds.EMPLOYER_ADDRESS]: string | null;
  [TaxFormFieldIds.EMPLOYER_CITY]: string | null;
  [TaxFormFieldIds.EMPLOYER_STATE]: string | null;
  [TaxFormFieldIds.EMPLOYER_ZIP_CODE]: string | null;
  [TaxFormFieldIds.CONTROL_NUMBER]: string | null;
  [TaxFormFieldIds.EMPLOYEE_FIRST_NAME]: string | null;
  [TaxFormFieldIds.EMPLOYEE_LAST_NAME]: string | null;
  [TaxFormFieldIds.EMPLOYEE_ADDRESS]: string | null;
  [TaxFormFieldIds.EMPLOYEE_CITY]: string | null;
  [TaxFormFieldIds.EMPLOYEE_STATE]: string | null;
  [TaxFormFieldIds.EMPLOYEE_ZIP_CODE]: string | null;
  [TaxFormFieldIds.WAGES_TIPS_ETC]: number | null;
  [TaxFormFieldIds.FEDERAL_WITHHELD]: number | null;
  [TaxFormFieldIds.SS_WAGES]: number | null;
  [TaxFormFieldIds.SS_TAX_WITHHELD]: number | null;
  [TaxFormFieldIds.MEDICARE_WAGES_N_TIPS]: number | null;
  [TaxFormFieldIds.MEDICARE_TAX_WITHHELD]: number | null;
  [TaxFormFieldIds.SS_TIPS]: number | null;
  [TaxFormFieldIds.ALLOCATED_TIPS]: number | null;
  [TaxFormFieldIds.DEPENDENT_CARE_BENEFITS]: number | null;
  [TaxFormFieldIds.NON_QUALIFIED_PLANS]: number | null;
  [TaxFormFieldIds.BOX_12_A]: string | null;
  [TaxFormFieldIds.BOX_12_B]: string | null;
  [TaxFormFieldIds.BOX_12_C]: string | null;
  [TaxFormFieldIds.BOX_12_D]: string | null;
  [TaxFormFieldIds.STATUTORY_EMPLOYEE]: boolean | null;
  [TaxFormFieldIds.RETIREMENT_PLAN]: boolean | null;
  [TaxFormFieldIds.THIRD_PARTY_SICK_PAY]: boolean | null;
  [TaxFormFieldIds.STATE]: string | null;
  [TaxFormFieldIds.EMPLOYER_STATE_ID_NUMBER]: string | null;
  [TaxFormFieldIds.STATE_WAGES_TIPS_ETC]: number | null;
  [TaxFormFieldIds.STATE_INCOME_TAX]: number | null;
  [TaxFormFieldIds.LOCAL_WAGES_TIPS_ETC]: number | null;
  [TaxFormFieldIds.LOCAL_INCOME_TAX]: number | null;
  [TaxFormFieldIds.LOCALITY_NAME]: string | null;
  [TaxFormFieldIds.OTHER]: string | null;
  [TaxFormFieldIds.TXN_IDS]: number | null;
  [TaxFormFieldIds.INCOME_EARNER]: IncomeEarnerType | null;
  [TaxFormFieldIds.BUSINESS_ID]: number | null;
  [TaxFormFieldIds.EXTRACTED_FORM_YEAR]: number | null;
};

type ManualFreelanceIncomeForm = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.TXN_IDS]: null | number[];
  [TaxFormFieldIds.AMOUNT]: null | number;
  [TaxFormFieldIds.STATE]: null | string;
  [TaxFormFieldIds.INCOME_EARNER]: null | IncomeEarnerType;
  [TaxFormFieldIds.BUSINESS_ID]: null | number;
};

type Form1099Nec = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.NON_EMPLOYEE_COMPENSATION]: null | number;
};

type Form1099Misc = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.RENTS]: null | number;
  [TaxFormFieldIds.ROYALTIES]: null | number;
  [TaxFormFieldIds.OTHER_INCOME]: null | number;
};

type Form1099K = {
  [TaxFormFieldIds.PSE_NAME]: null | string;
  [TaxFormFieldIds.GROSS_AMOUNT_PAYMENT_CARD]: null | number;
};

type Form1099Int = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.PAYER_ADDRESS]: null | string;
  [TaxFormFieldIds.PAYER_CITY]: null | string;
  [TaxFormFieldIds.PAYER_STATE]: null | string;
  [TaxFormFieldIds.PAYER_ZIP_CODE]: null | string;
  [TaxFormFieldIds.PAYER_TIN]: null | string;
  [TaxFormFieldIds.RECIPIENT_TIN]: null | string;
  [TaxFormFieldIds.RECIPIENT_NAME]: null | string;
  [TaxFormFieldIds.RECIPIENT_ADDRESS]: null | string;
  [TaxFormFieldIds.RECIPIENT_CITY]: null | string;
  [TaxFormFieldIds.RECIPIENT_STATE]: null | string;
  [TaxFormFieldIds.RECIPIENT_ZIP_CODE]: null | string;
  [TaxFormFieldIds.FATCA_FILING_REQUIREMENT]: null | boolean;
  [TaxFormFieldIds.ACCOUNT_NUMBER]: null | string;
  [TaxFormFieldIds.SECOND_TIN_NOT]: null | string;
  [TaxFormFieldIds.INTEREST_INCOME]: null | number;
  [TaxFormFieldIds.EARLY_WITHDRAWAL_PENALTY]: null | number;
  [TaxFormFieldIds.US_BOND_INTEREST]: null | number;
  [TaxFormFieldIds.FEDERAL_WITHHELD]: null | number;
  [TaxFormFieldIds.INVESTMENT_EXPENSES]: null | number;
  [TaxFormFieldIds.PAID_FOREIGN_TAX]: null | number;
  [TaxFormFieldIds.POSSESSION]: null | string;
  [TaxFormFieldIds.TAX_EXEMPT_INTEREST]: null | number;
  [TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST]: null | number;
  [TaxFormFieldIds.MARKET_DISCOUNT]: null | number;
  [TaxFormFieldIds.BOND_PREMIUM]: null | number;
  [TaxFormFieldIds.TREASURY_OBLIGATION_BOND_PREMIUM]: null | number;
  [TaxFormFieldIds.TAX_EXEMPT_BOND_PREMIUM]: null | number;
  [TaxFormFieldIds.CUSIP_NO]: null | number;
  [TaxFormFieldIds.STATE]: null | string;
  [TaxFormFieldIds.STATE_ID_NO]: null | string;
  [TaxFormFieldIds.STATE_WITHHELD]: null | number;
  [TaxFormFieldIds.TXN_IDS]: null | number[];
  [TaxFormFieldIds.INCOME_EARNER]: null | IncomeEarnerType;
  [TaxFormFieldIds.BUSINESS_ID]: null | number;
  [TaxFormFieldIds.EXTRACTED_FORM_YEAR]: null | number;
};

type Form1099Div = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.TOTAL_ORDINARY_DIVIDENDS]: null | number;
  [TaxFormFieldIds.TOTAL_CAPITAL_GAIN]: null | number;
  [TaxFormFieldIds.SECTION_1202_GAIN]: null | number;
  [TaxFormFieldIds.SECTION_897_DIVIDENDS]: null | number;
  [TaxFormFieldIds.SEC_1250_GAIN]: null | number;
  [TaxFormFieldIds.COLLECTIBLES_GAIN]: null | number;
  [TaxFormFieldIds.SECTION_897_CAPITAL_GAIN]: null | number;
  [TaxFormFieldIds.NON_DIVIDEND_DISTRIBUTIONS]: null | number;
  [TaxFormFieldIds.SECTION_199A_DIVIDENDS]: null | number;
  [TaxFormFieldIds.CASH_LIQUIDATION_DISTRIBUTIONS]: null | number;
  [TaxFormFieldIds.NON_CASH_LIQUIDATION_DISTRIBUTIONS]: null | number;
  [TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST]: null | number;
};

type Form1099B = {
  [TaxFormFieldIds.INCOME_EARNER]: null | string;
  [TaxFormFieldIds.SHORT_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.SHORT_TERM_NET_LOSS]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_LOSS]: null | number;
  [TaxFormFieldIds.UNDETERMINED_NET_GAIN]: null | number;
  [TaxFormFieldIds.UNDETERMINED_NET_LOSS]: null | number;
};

type W2GForm = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.WINNING_REPORTABLE]: null | number;
  [TaxFormFieldIds.WINNINGS_FROM_IDENTICAL_WAGERS]: null | number;
};

type Form1099G = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.UNEMPLOYMENT_COMPENSATION]: null | number;
  [TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET]: null | number;
  [TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET]: null | number;
  [TaxFormFieldIds.RTAA_PAYMENTS]: null | number;
  [TaxFormFieldIds.TAXABLE_GRANTS]: null | number;
  [TaxFormFieldIds.AGRICULTURE_PAYMENTS]: null | number;
  [TaxFormFieldIds.MARKET_GAIN]: null | number;
};

type Form1099R = {
  [TaxFormFieldIds.PAYER_NAME]: null | string;
  [TaxFormFieldIds.GROSS_DISTRIBUTION]: null | number;
};

type FormK11120S = {
  [TaxFormFieldIds.CORPORATION_NAME]: null | string;
  [TaxFormFieldIds.ORDINARY_BUSINESS_INCOME]: null | number;
  [TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME]: null | number;
  [TaxFormFieldIds.ORDINARY_BUSINESS_LOSS]: null | number;
  [TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS]: null | number;
  [TaxFormFieldIds.INTEREST_INCOME]: null | number;
  [TaxFormFieldIds.QUALIFIED_DIVIDENDS]: null | number;
  [TaxFormFieldIds.ORDINARY_DIVIDENDS]: null | number;
  [TaxFormFieldIds.SHORT_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.SHORT_TERM_NET_LOSS]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_LOSS]: null | number;
};

type Form1099SA = {
  [TaxFormFieldIds.TRUSTEE_NAME]: null | string;
  [TaxFormFieldIds.GROSS_DISTRIBUTION]: null | number;
};

type FormK11065 = {
  [TaxFormFieldIds.PARTNERSHIP_NAME]: null | string;
  [TaxFormFieldIds.ORDINARY_BUSINESS_INCOME]: null | number;
  [TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME]: null | number;
  [TaxFormFieldIds.ORDINARY_BUSINESS_LOSS]: null | number;
  [TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS]: null | number;
  [TaxFormFieldIds.INTEREST_INCOME]: null | number;
  [TaxFormFieldIds.QUALIFIED_DIVIDENDS]: null | number;
  [TaxFormFieldIds.ORDINARY_DIVIDENDS]: null | number;
  [TaxFormFieldIds.SHORT_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_GAIN]: null | number;
  [TaxFormFieldIds.SHORT_TERM_NET_LOSS]: null | number;
  [TaxFormFieldIds.LONG_TERM_NET_LOSS]: null | number;
};

type FormRentalIncome = {
  [TaxFormFieldIds.RENTAL_PROPERTY_NAME]: null | string;
  [TaxFormFieldIds.INCOME_EARNED]: null | number;
};

export enum VehicleExpenseTypes {
  LEASED_OR_RENTED = 'leased_or_rented',
  PAID_INTERESE_ON_LOAN = 'paid_interest_on_loan',
  USED_OWN_VEHICLE = 'used_own_vehicle',
}

export enum InformationSource {
  document = 'document',
  manual = 'manual',
}

export enum ProfitLossIncomeCategory {
  GrossReceipts = 'Gross receipts or sales',
  LessReturnsAndAllowances = 'Less returns and allowances',
  OtherIncome = 'Other Income',
}

export enum ProfitLostCogsCategory {
  Purchases = 'Purchases',
  CostOfLabour = 'Cost of labour',
  OtherCosts = 'Other costs',
}

export enum ProfitLossScorpExpenseCategory {
  CompensationOfOfficers = 'Compensation of officers',
  SalariesAndWages = 'Salaries and wages (less employment credits)',
  RepairsAndMaintenance = 'Repairs and maintenance',
  BadDebts = 'Bad debts',
  Rents = 'Rents',
  TaxesAndLicenses = 'Taxes and licenses',
  PayrollTax = 'Payroll Tax',
  Interest = 'Interest (see instructions) .',
  Depreciation = 'Depreciation from Form 4562 not claimed on Form 1125-A or elsewhere on return',
  Depletion = 'Depletion',
  Advertising = 'Advertising',
  Pension = 'Pension, profit-sharing, etc., plans',
  EmployeeBenefitPrograms = 'Employee benefit programs',
  EnergyEfficientCommercial = 'Energy efficient commercial buildings deduction',
  OtherDeductions = 'Other deductions',
}

export enum ProfitLossPartnershipExpenseCategory {
  SalariesAndWages = 'Salaries and wages (other than to partners)',
  GuaranteedPaymentsToPartners = 'Guaranteed payments to partners',
  RepairsAndMaintenance = 'Repairs and maintenance',
  BadDebts = 'Bad debts',
  Rents = 'Rents',
  TaxesAndLicenses = 'Taxes and licenses',
  PayrollTax = 'Payroll Tax',
  Interest = 'Interest (see instructions) .',
  Depreciation = 'Depreciation',
  Depletion = 'Depletion',
  Advertising = 'Advertising',
  Retirement = 'Retirement plans, etc.',
  EmployeeBenefitPrograms = 'Employee benefit programs',
  EnergyEfficientCommercial = 'Energy efficient commercial buildings deduction',
  OtherDeductions = 'Other deductions',
}

export enum ProfitLossCcorpExpenseCatgory {
  CompensationOfOfficers = 'Compensation of officers',
  SalariesAndWages = 'Salaries and wages (less employment credits)',
  RepairsAndMaintenance = 'Repairs and maintenance',
  BadDebts = 'Bad debts',
  Rents = 'Rents',
  TaxesAndLicenses = 'Taxes and licenses',
  PayrollTax = 'Payroll Tax',
  Interest = 'Interest (see instructions) .',
  CharitableContributions = 'Charitable contributions',
  Depreciation = 'Depreciation from Form 4562 not claimed on Form 1125-A or elsewhere on return',
  Depletion = 'Depletion',
  Advertising = 'Advertising',
  Pension = 'Pension, profit-sharing, etc., plans',
  EmployeeBenefitPrograms = 'Employee benefit programs',
  EnergyEfficientCommercial = 'Energy efficient commercial buildings deduction',
  OtherDeductions = 'Other deductions',
}

export enum ProfitLosssTypeOfExpense {
  Direct = 'Direct',
  InDirect = 'InDirect',
}

interface ProfitLossIncomeRow {
  [TaxFormFieldIds.KEY]: string | null;
  [TaxFormFieldIds.AMOUNT]: number | null;
  [TaxFormFieldIds.SOURCE]: InformationSource;
  [TaxFormFieldIds.CATEGORY]: ProfitLossIncomeCategory | null;
  [TaxFormFieldIds.IS_APPLICABLE]: boolean | null;
}

interface ProfitLossExpenseRow extends ProfitLossIncomeRow {
  [TaxFormFieldIds.L2]: string | null;
  [TaxFormFieldIds.TYPE_OF_EXPENSE]: ProfitLosssTypeOfExpense | null;
}

type FormProfitLoss = {
  [TaxFormFieldIds.INCOME]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
    [TaxFormFieldIds.SUB_CATEGORY]: ProfitLossIncomeRow[];
  };
  [TaxFormFieldIds.COST_OF_GOODS_SOLD]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
    [TaxFormFieldIds.SUB_CATEGORY]: ProfitLossIncomeRow[];
  };
  [TaxFormFieldIds.GROSS_PROFIT]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
  };
  [TaxFormFieldIds.EXPENSES]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
    [TaxFormFieldIds.SUB_CATEGORY]: ProfitLossExpenseRow[];
  };
  [TaxFormFieldIds.OTHER_INCOME]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
    [TaxFormFieldIds.SUB_CATEGORY]: ProfitLossIncomeRow[];
  };
  [TaxFormFieldIds.NET_OTHER_INCOME]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
  };
  [TaxFormFieldIds.NET_INCOME]: {
    [TaxFormFieldIds.AMOUNT]: number | null;
  };
};

export interface BalanceSheetLineItemType {
  [TaxFormFieldIds.KEY]: string;
  [TaxFormFieldIds.AMOUNT]: number;
  [TaxFormFieldIds.SOURCE]: InformationSource;
  [TaxFormFieldIds.CATEGORY]: string | null;
  [TaxFormFieldIds.L2]: string | null;
  [TaxFormFieldIds.IS_APPLICABLE]: boolean;
}

export interface BalanceSheetHeaderType {
  [TaxFormFieldIds.LINE_ITEMS]: BalanceSheetLineItemType[];
}

export enum BalanceSheetType {
  TAX_YEAR_START = 'tax_year_start',
  TAX_YEAR_END = 'tax_year_end',
}

type FormBalanceSheet = {
  [TaxFormFieldIds.BALANCE_SHEET_TYPE]: BalanceSheetType | null;
  [TaxFormFieldIds.ASSETS]: BalanceSheetHeaderType;
  [TaxFormFieldIds.LIABILITIES]: BalanceSheetHeaderType;
  [TaxFormFieldIds.EQUITY]: BalanceSheetHeaderType;
};

type FormDataMap = {
  [TaxFormType.DEPENDENT]: DependentDetailsForm;
  [TaxFormType.QUARTERLY_TAX_PAYMENTS]: TaxPaymentsForm;
  [TaxFormType.STATES_LIVED]: StateLivedForm;
  [TaxFormType.W2_FORM]: W2Form;
  [TaxFormType.MANUAL_FREELANCE_INCOME_FORM]: ManualFreelanceIncomeForm;
  [TaxFormType.FORM_1099_NEC]: Form1099Nec;
  [TaxFormType.FORM_1099_MISC]: Form1099Misc;
  [TaxFormType.FORM_1099_K]: Form1099K;
  [TaxFormType.FORM_1099_INT]: Form1099Int;
  [TaxFormType.FORM_1099_DIV]: Form1099Div;
  [TaxFormType.FORM_1099_B]: Form1099B;
  [TaxFormType.W2_G_FORM]: W2GForm;
  [TaxFormType.FORM_1099_G]: Form1099G;
  [TaxFormType.FORM_1099_R]: Form1099R;
  [TaxFormType.FORM_K1_1120S]: FormK11120S;
  [TaxFormType.FORM_1099_SA]: Form1099SA;
  [TaxFormType.FORM_K1_1065]: FormK11065;
  [TaxFormType.RENTAL_INCOME]: FormRentalIncome;
  [TaxFormType.PROFIT_LOSS_FORM]: FormProfitLoss;
  [TaxFormType.BALANCE_SHEET_FORM]: FormBalanceSheet;
};

export type FormData<T extends keyof FormDataMap> = FormDataMap[T];

export interface GenericTaxForm {
  taxFormId: number;
  formType: TaxFormType;
  formData: {
    [key in TaxFormFieldIds]?: any;
  };
  isEndUserReviewed: boolean;
  docId: number;
  docLink: string;
  year: number;
  linked_business_id: number;
}

export interface TaxForm<T extends keyof FormDataMap>
  extends Omit<GenericTaxForm, 'formData'> {
  formData: FormData<T>;
  formType: T;
}
