import React from 'react';

const TickIcon = ({color = '#60C16A'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.1905 9.52637C17.4812 9.2313 17.4777 8.75644 17.1826 8.46574C16.8876 8.17503 16.4127 8.17857 16.122 8.47363L10.6864 13.9907L8.53426 11.8063C8.24356 11.5112 7.7687 11.5077 7.47363 11.7984C7.17857 12.0891 7.17503 12.564 7.46574 12.859L10.1522 15.5857C10.2931 15.7288 10.4856 15.8094 10.6864 15.8094C10.8873 15.8094 11.0797 15.7288 11.2207 15.5857L17.1905 9.52637Z"
        fill={color}
      />
    </svg>
  );
};

export default TickIcon;
