import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {CircularProgress} from '@mui/material';

const PromoTable = (
  {data, loading}: {data: Array<any>; loading: boolean} = {
    data: [],
    loading: false,
  },
) => {
  const COLUMNS = [
    {
      columnName: 'Coupon code',
      respId: 'coupon_code',
      default: '-',
    },
    {
      columnName: 'Promo code',
      respId: 'promo_code',
      default: '-',
    },
    {
      columnName: 'Expires at',
      respId: 'expires_at',
      default: 'Never',
    },
    {
      columnName: 'Redemption limit',
      respId: 'max_users',
      default: 'No limit',
    },
  ];
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMNS.map((column) => (
              <TableCell>{column.columnName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell>{item[column.respId] ?? column.default}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PromoTable;
