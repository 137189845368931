import React, {useState} from 'react';
import {TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {sendDocForReviewEsign} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';
import {MANDATORY_JURISDICTION} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';
import {ReduxStateType} from 'src/store/store';
import {getFullName} from 'src/common/utils';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {
  FILING_STATUS_ANSWER,
  TaxProfileQuestion,
} from 'src/store/taxProfile/taxProfile.types';
import CopyText from 'src/CpaCenterV2/common/CopyText/CopyText';

interface ManualSignNowIdPopupProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  makeSpouseAsPrimary: boolean;
}

const ManualSignNowIdPopup = ({
  url,
  isOpen,
  onClose,
  makeSpouseAsPrimary,
}: ManualSignNowIdPopupProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const [signNowId, setSignNowId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();
  const dispatch = useDispatch();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();

  const {legalName, spouseLegalName, filingStatus} = useSelector(
    (state: ReduxStateType) => {
      return {
        legalName: getFullName(
          selectTaxProfileAnswer(
            state,
            TaxProfileQuestion.LEGAL_NAME,
            activeYear,
          ),
        ),
        spouseLegalName: getFullName(
          selectTaxProfileAnswer(
            state,
            TaxProfileQuestion.SPOUSE_LEGAL_NAME,
            activeYear,
          ),
        ),
        filingStatus: selectTaxProfileAnswer(
          state,
          TaxProfileQuestion.FILING_STATUS,
          activeYear,
        ),
      };
    },
  );

  const onSend = async () => {
    try {
      setIsLoading(true);
      await sendDocForReviewEsign({
        user: userId,
        year: activeYear,
        filename: currentReturn.document_name,
        jurisdiction: MANDATORY_JURISDICTION,
        sign_now_document_id: signNowId,
        return_id: returnId,
        return_type: currentReturn.return_type,
        use_spouse_as_primary: makeSpouseAsPrimary,
      });
      await dispatch(fetchTaxReturns(userId, activeYear));
      onClose();
      navigateToTaxReturnDetailedView(
        returnId,
        currentReturn?.return_type,
        TaxReturnStatus.REVIEW_ESIGN,
      );
      notify('Document sent for review esign', NotificationType.success);
    } catch (e) {
      notify(`Failed to send ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PopUp
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonTitle="Send"
      primaryButtonDisabled={!signNowId || isLoading}
      primaryButtonOnClick={onSend}
      secondaryButtonTitle="Cancel"
      secondaryButtonDisabled={isLoading}
      secondaryButtonOnClick={onClose}>
      <Typography style={{fontSize: 20, fontWeight: 800}}>
        Recipient names for signnow
      </Typography>
      <Typography
        style={{
          fontSize: 13,
          marginTop: 4,
          color: themmeColor.grey,
          marginBottom: 12,
        }}>
        When you create recipients in signnow document, copy the names from
        below to avoid
        <br />
        any failures while sending manually
      </Typography>
      {legalName ? (
        <CopyText
          label="Taxpayer's name"
          value={makeSpouseAsPrimary ? spouseLegalName : legalName}
        />
      ) : null}
      {filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY ? (
        <CopyText
          label="Spouse's name"
          value={makeSpouseAsPrimary ? legalName : spouseLegalName}
        />
      ) : null}

      <Typography style={{fontSize: 20, fontWeight: 800}}>
        Enter signnow doc id
      </Typography>
      <Typography
        style={{
          fontSize: 13,
          marginTop: 4,
          color: themmeColor.grey,
          marginBottom: 12,
        }}>
        You need to enter signnow document id to enable e-signing for the
        users. Make sure
        <br />
        you are copying the id properly from signnow.
      </Typography>
      <TextField
        fullWidth
        label="Signnow document id"
        type={'text'}
        style={{marginTop: 16, marginBottom: 16}}
        variant="outlined"
        value={signNowId}
        onChange={(e) => setSignNowId(e.target.value)}
      />
    </PopUp>
  );
};

export default ManualSignNowIdPopup;
