import {ReturnSummaryKeys} from 'src/constants/constants';

export enum ReturnSummaryInputType {
  TEXT_INPUT = 'TEXT_INPUT',
  DROPDOWN = 'DROWPDOWN',
}

type ReturnSummaryTextInputProps = {
  type: ReturnSummaryInputType.TEXT_INPUT;
  key: ReturnSummaryKeys;
  value: any;
  valueMandatory?: boolean;
};

type ReturnSummaryDropdownInputProps = {
  type: ReturnSummaryInputType.DROPDOWN;
  key: ReturnSummaryKeys | null;
  value: any;
  options: ReturnSummaryKeys[];
  valueMandatory?: boolean;
};

export type ReturnSummaryInputFieldProps =
  | ReturnSummaryTextInputProps
  | ReturnSummaryDropdownInputProps;

export enum RETURN_SUMMARY_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  C_CORP_FEDERAL = 'C_CORP_FEDERAL',
  S_CORP_FEDERAL = 'S_CORP_FEDERAL',
  PARTNERSHIP_FEDERAL = 'PARTNERSHIP_FEDERAL',
  C_CORP_STATE = 'C_CORP_STATE',
  S_CORP_PARTNERSHIP_STATE = 'S_CORP_PARTNERSHIP_STATE',
}
