import React, {useEffect, useState} from 'react';
import {Autocomplete, Button, TextField} from '@mui/material';
import Papa from 'papaparse';
import {useParams} from 'react-router-dom';
import {
  getMissingExpenseData,
  postExpenseFile,
  updateMissingExpenseStatusToRecieved,
} from '../appApi';
import {getFormattedDateForCSVImport} from '../common/utils';
import InfoLabel from './components/InfoLabel';
import {missingExpensesBackendResponseMapper} from './components/missingexpenses.utils';
import UploadMissingBankStatements from './components/UploadMissingBankStatements';
import MissingExpenseContext, {
  BackendTransformedProps,
  MissingExpenseStatus,
} from './MissingExpenses.context';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const MissingExpenses = () => {
  const {activeYear} = useActiveYear();
  const {flyfin_user_id} = useParams();
  const getInitFileState = () => ({
    isValid: false,
    bankName: '',
    bankId: 0,
    file: null,
  });
  const [file, setFile] = useState(getInitFileState());
  const [missingExpenseData, setMissingExpenseData] =
    useState<BackendTransformedProps | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchMissingExpenseData = async () => {
    if (!flyfin_user_id) return;
    const response = await getMissingExpenseData({
      user: flyfin_user_id,
      year: activeYear,
    });
    setMissingExpenseData(missingExpensesBackendResponseMapper(response.data));
  };

  useEffect(() => {
    fetchMissingExpenseData();
  }, [activeYear]);

  const resetFile = () => {
    setFile(getInitFileState());
  };

  const onParseComplete = async (records: any) => {
    try {
      setLoading(true);
      await postExpenseFile({
        records,
        user: flyfin_user_id,
        csv_filename: file.file?.name,
        insti: file.bankId,
      });
    } catch (e) {
      alert(`Something went wrong, ${e}`);
    } finally {
      await fetchMissingExpenseData();
      resetFile();
      setLoading(false);
    }
  };

  const handleFileUpload = () => {
    if (!flyfin_user_id) return;
    Papa.parse(file.file, {
      header: true,
      skipEmptyLines: true,
      complete: ({data}: {data: any}) => {
        const formattedData = data.map((record: any) => ({
          ...record,
          transaction_date: getFormattedDateForCSVImport(
            record.transaction_date,
          ),
          user_id: flyfin_user_id,
        }));
        onParseComplete(formattedData);
      },
    });
  };

  const handleUpdateMissingExpenseStatus = async () => {
    if (!flyfin_user_id) return;
    try {
      setLoading(true);
      await updateMissingExpenseStatusToRecieved({
        user: flyfin_user_id,
        year: activeYear,
      });
    } catch (e) {
      alert(`Something went wrong, ${e}`);
    } finally {
      await fetchMissingExpenseData();
      setLoading(false);
    }
  };

  if (missingExpenseData === null) return null;
  return (
    <MissingExpenseContext.Provider
      value={{...missingExpenseData, file, setFile}}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 40px',
            marginBottom: 10,
          }}>
          <InfoLabel label={'UserID'} value={missingExpenseData.userId} />
          <InfoLabel label={'Email'} value={missingExpenseData.emailAddress} />
          <InfoLabel label={'Name'} value={missingExpenseData.username} />
          <Autocomplete
            disabled={
              missingExpenseData.status !== MissingExpenseStatus.AWAITING
            }
            getOptionDisabled={(option) =>
              option !== MissingExpenseStatus.PROCESSED
            }
            onChange={(e, v) => handleUpdateMissingExpenseStatus()}
            value={missingExpenseData?.status}
            id="combo-box-demo"
            sx={{width: 300}}
            options={Object.values(MissingExpenseStatus)}
            renderInput={(params) => (
              <TextField {...params} label="Missing Expense Status" />
            )}
          />
        </div>
        <div style={{display: 'flex'}}>
          {missingExpenseData.status !==
            MissingExpenseStatus.NOT_INITIATED && (
            <div style={{width: '75vw'}}>
              <UploadMissingBankStatements />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: 20,
                  paddingRight: 20,
                }}>
                <Button
                  style={{height: '50px', flex: 0.4}}
                  disabled={!file.isValid || loading}
                  onClick={resetFile}
                  variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={handleFileUpload}
                  style={{height: '50px', flex: 0.4, textTransform: 'none'}}
                  variant="contained"
                  disabled={!file.isValid || loading}>
                  {!file.isValid
                    ? 'Choose File First'
                    : `Import ${file?.file?.name} (${file.bankName}) (${file.bankId}) `}
                </Button>
              </div>
            </div>
          )}
          <div style={{width: '25vw'}}></div>
        </div>
      </div>
    </MissingExpenseContext.Provider>
  );
};

export default MissingExpenses;
