import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  exportData,
  patchTaxFiling,
  updateCCHClientId,
  updateTFSegment,
} from 'src/appApi';
import {COMMON_COMPONENTS_ID, themmeColor} from 'src/constants/constants';
import {CpaCenterV2Route} from 'src/constants/routeName';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import Box from 'src/DesignSystem/Box/Box';
import BackIcon from 'src/icons/BackIcon';
import NotesIcon from 'src/icons/NotesIcon';
import TaxProfileIcon from 'src/icons/TaxProfileIcon';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';
import LabelField from './components/LabelField';
import NotesPopUp from './components/NotesPopUp';
import UpdateCCHClientId from './components/UpdateCCHClientId';
import DSButton from 'src/DesignSystem/Button/Button';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';
import {ReduxStateType} from 'src/store/store';
import {selectAppReducerState} from 'src/store/app/app.selector';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import CollapseButton from './components/CollapseButton';
import {TF_SEGMENT} from 'src/store/user/user.reducer';
import UpdateTFSegmentPopup from './components/UpdateTFSegmentPopup';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import ReturnStatusUpdate from './components/ReturnStatusUpdate';
import YearSwitchDropdown from 'src/common/YearSwitchDropdown/YearSwitchDropdown';
import ExtensionStatusUpdate from './components/ExtensionStatusUpdate';

const UserSummaryBar = () => {
  const {activeYear} = useActiveYear();
  const {
    userId,
    userName,
    email,
    lastStatusChange,
    cchClientId,
    taxFilingId,
    notes,
    loaded,
    tfSegment,
  } = useSelector(selectUser);
  const isTaxProfileSummaryDrawer = useSelector<ReduxStateType>((state) =>
    selectAppReducerState(state, AppReducerStates.isTaxProfileSummaryDrawer),
  ) as boolean;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!loaded);
  const [cchPopUp, setCchPopUp] = useState(false);
  const [tfSegmentPopUp, setTFSegmentPopUp] = useState(false);
  const [notesPopUp, setNotesPopUp] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const refreshInfo = async () => {
    await dispatch(fetchUserInfo(userId, activeYear));
    await dispatch(fetchTaxReturns(userId, activeYear));
  };

  const {notify} = useNotify();

  useEffect(() => {
    setLoading(!loaded);
  }, [loaded]);

  const onUpdateCchId = async (newId: number) => {
    try {
      setLoading(true);
      await updateCCHClientId(userId, newId);
    } catch (e) {
      alert('Failed to update CCH Id');
    } finally {
      await refreshInfo();
      setLoading(false);
      setCchPopUp(false);
    }
  };

  const onUpdateNote = async (newNote: string) => {
    try {
      setLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.NOTE]: newNote},
      );
    } catch (e) {
      alert('Failed to update Note');
    } finally {
      await refreshInfo();
      setLoading(false);
      setNotesPopUp(false);
    }
  };

  const onExportData = async () => {
    try {
      setLoading(true);
      await exportData({fly_user_id: userId, year: activeYear});
      notify('Export data success', NotificationType.success);
    } catch (e) {
      notify(`Export data failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateTFSegment = async (selectedSegment: TF_SEGMENT) => {
    try {
      setLoading(true);
      await updateTFSegment({
        user_ids: [userId],
        segment_name: selectedSegment,
      });
      await dispatch(fetchUserInfo(userId, activeYear));
      setTFSegmentPopUp(false);
      notify('Tax Filing Segment updated', NotificationType.success);
    } catch (e) {
      notify(`Tax Filing Segment update failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBack = () => {
    navigate(CpaCenterV2Route.CpaCenterV2List);
  };

  const data = [
    {
      props: {
        label: 'User ID',
        value: userId,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Name',
        value: userName,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Email address',
        value: email,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'CCH Client ID',
        value: cchClientId,
        onClick: () => setCchPopUp(true),
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Status Change',
        value: lastStatusChange,
      },
      Component: LabelField,
    },
    {
      props: {
        setLoading,
        loading,
      },
      Component: ReturnStatusUpdate,
    },
    {
      props: {
        setLoading,
        loading,
      },
      Component: ExtensionStatusUpdate,
    },
    {
      props: {
        label: '',
        onClick: () => setNotesPopUp(true),
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<NotesIcon />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="secondary"
        />
      ),
    },
    {
      props: {
        label: `Export`,
        onClick: onExportData,
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<UploadCloudIcon color={themmeColor.white} />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="primary"
        />
      ),
    },
    {
      props: {},
      Component: () => {
        return (
          <div style={{maxWidth: 200}}>
            <YearSwitchDropdown />
          </div>
        );
      },
    },
    {
      props: {},
      Component: () => (
        <Box
          hoverEffect
          onClick={() =>
            dispatch(
              setAppState(
                AppReducerStates.isTaxProfileSummaryDrawer,
                !isTaxProfileSummaryDrawer,
              ),
            )
          }
          backgroundColor={
            isTaxProfileSummaryDrawer ? themmeColor.black : '#F0F0F0'
          }
          style={{borderColor: themmeColor.silver, padding: 12}}>
          <TaxProfileIcon />
        </Box>
      ),
    },
  ];

  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_USER_SUMMARY_BAR}
      style={{
        position: 'relative',
      }}>
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingInline: 32,
          paddingBlock: isCollapsed ? 0 : 12,
          flexWrap: 'wrap',
          height: isCollapsed ? 0 : undefined,
          overflow: 'hidden',
        }}
        noBR>
        <Box
          borderColor={themmeColor.silver}
          hoverEffect
          onClick={onClickBack}
          style={{paddingBlock: 12, paddingInline: 4}}
          hoverColor={themmeColor.focusedBlue}>
          <BackIcon />
          <Typography>Users List</Typography>
        </Box>
        {data.map(({Component, props}) => (
          <Component {...props} loaded={loaded} />
        ))}
      </Box>
      <CollapseButton
        setIsCollapsed={setIsCollapsed}
        isCollapsed={isCollapsed}
      />
      <UpdateCCHClientId
        key={`cchUpdatePopUp-${cchClientId}-${cchPopUp}`}
        isOpen={cchPopUp}
        onClose={() => setCchPopUp(false)}
        cchClientId={cchClientId}
        loading={loading}
        onUpdate={onUpdateCchId}
      />
      <NotesPopUp
        key={`notesPopUp-${notes}-${notesPopUp}`}
        isOpen={notesPopUp}
        onClose={() => setNotesPopUp(false)}
        loading={loading}
        onUpdate={onUpdateNote}
        notes={notes}
      />
      <UpdateTFSegmentPopup
        key={`extensionStatus-${tfSegment}-${tfSegmentPopUp}`}
        isOpen={tfSegmentPopUp}
        onClose={() => setTFSegmentPopUp(false)}
        currentSegment={tfSegment}
        loading={loading}
        onUpdateSegment={onUpdateTFSegment}
      />
    </div>
  );
};

export default UserSummaryBar;
