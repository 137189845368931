import React from 'react';
import {TEXT_TYPES} from './Text.types';

export const TEXT_TYPES_TO_PROPS: {[key in TEXT_TYPES]: React.CSSProperties} =
  {
    [TEXT_TYPES.H1]: {
      fontSize: 72,
      lineHeight: '88px',
    },
    [TEXT_TYPES.H2]: {
      fontSize: 60,
      lineHeight: '76px',
    },
    [TEXT_TYPES.H3]: {
      fontSize: 48,
      lineHeight: '60px',
    },
    [TEXT_TYPES.H4]: {
      fontSize: 36,
      lineHeight: '48px',
    },
    [TEXT_TYPES.H5]: {
      fontSize: 30,
      lineHeight: '40px',
    },
    [TEXT_TYPES.H6]: {
      fontSize: 24,
      lineHeight: '32px',
    },
    [TEXT_TYPES.L]: {
      fontSize: 20,
      lineHeight: '28px',
    },
    [TEXT_TYPES.BASE]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    [TEXT_TYPES.S]: {
      fontSize: 13,
      lineHeight: '20px',
    },
    [TEXT_TYPES.XS]: {
      fontSize: 11,
      lineHeight: '16px',
      letterSpacing: 0.7,
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  };
