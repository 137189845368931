import React from 'react';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import SectionHeader from '../common/SectionHeader';
import BusinessDetails from './components/BusinessDetails/BusinessDetails';
import DependentDetails from './components/DependentDetails/DependentDetails';
import HomeAddress from './components/HomeAddress/HomeAddress';
import RelatedDocuments from './components/RelatedDocuments/RelatedDocuments';
import SpouseDetails from './components/SpouseDetails/SpouseDetails';
import UserDetails from './components/UserDetails/UserDetails';

const BasicDetails = () => {
  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_SECTION_HEADER_HEIGHT} =
    useLayout();
  return (
    <div>
      <div style={{display: 'flex'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '75vw',
          }}>
          <SectionHeader
            sectionName="Basic Details"
            section={TaxProfileSection.BasicDetails}
          />
          <div
            style={{
              height:
                spaceLeftBelowTaxFilingSteps -
                TAX_PROFILE_SECTION_HEADER_HEIGHT,
              overflowY: 'auto',
            }}>
            <div>
              <RelatedDocuments />
              <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                <UserDetails style={{flex: 0.33}} />
                <HomeAddress style={{flex: 0.33}} />
                <SpouseDetails style={{flex: 0.33}} />
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
              <DependentDetails style={{flex: 0.5}} />
              <BusinessDetails style={{flex: 0.5}} />
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: 'auto',
            width: '24vw',
            flex: 1,
            paddingRight: 10,
            height: spaceLeftBelowTaxFilingSteps,
          }}>
          <ActionRequired
            dontAllowAdd
            types={[CreateQueryDrawerTab.TAX_PROFILE]}
            taxProfileSection={TaxProfileSection.BasicDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
