import React from 'react';
import {Route, Routes} from 'react-router-dom';
import QueryList from 'src/UserQueries/QueryList';
import CpaQueryViewer from 'src/UserQueries/CpaQueryViewer';

const UserQueryRouter = () => {
  return (
    <Routes>
      <Route path={'/'} element={<QueryList/>}/>
      <Route path={':queryId'} element={<CpaQueryViewer/>} />
    </Routes>
  );
};

export default UserQueryRouter;
