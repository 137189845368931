import {getDocument} from 'src/appApi';
import {
  VALID_DOCUMENT_REASON,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import {
  Document,
  DocumentType,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';

export enum DOCUMENT_TAX_PROFILE_KEY_TYPE {
  // NON-PRIMITIVES/COMPOUND FIELDS
  FULL_LEGAL_NAME = 'full_legal_name',
  HOME_ADDRESS = 'home_address',
  INCOME_SOURCES = 'income_sources',

  // PRIMITEVES FROM HERE
  SOCIAL_SECURITY_NUMBER = 'social_security_number',
  INCOME_1099 = '1099_income',
  W2_INCOME = 'w2_income',
  INCOME = 'income',
  TAX_WITHELD = 'tax_withheld',
  CONTIBUTION_401 = '401k_contri',
  NON_EMP_COMP = 'non_emp_comp',
  INTEREST_INCOME = 'intereset_income',
  TOTAL_ORIDNARY_DIV = 'total_ordinary_dividend',
  QUALIFIED_DIVIDEND = 'qualified_dividend',
  TOTAL_CPA = 'total_cap_gain_dist',
  RENT = 'rent',
  ROYALTIES = 'royalties',
  OTHER_INCOME = 'other_income',
  UN_EMPP_COMP = '1099_g_un_emp_comp',
  LOCAL_FUNDS = '1099_g_local_funds',
  B_LOSS = '1099_b_loss',
  CHARITY_AMOUNT = 'charity_amount',
  BIZ_EIN = 'biz_ein',
  IRA_CONT = 'total_ira_contri',
  ALREADY_PAID_TAX = 'already_paid_tax',
}

export enum FIELD_TYPES {
  NUM_INPUT = 'NUM_INPUT',
  STR_INPUT = 'STR_INPUT',
  DROPDOWN = 'DROPDOWN',
  DATE = 'DATE_INPUT',
  CHECK_BOX = 'CHECKBOX',
}

export enum OCR_STATUS {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

export interface FormValueType {
  key_bounding_box: {
    Top: number;
    Left: number;
    Width: number;
    Height: number;
  } | null;
  value: any[] | any | null;
  value_bounding_box: {
    Top: number;
    Left: number;
    Width: number;
    Height: number;
  } | null;
}

export interface FormFieldType {
  field_type: FIELD_TYPES[] | FIELD_TYPES;
  dropdown_choices?: string[];
  field_values: FormValueType[];
  label: string;
}

export interface FormType {
  form_data: {[key: string]: FormFieldType};
  form_type: string;
  id: number;
  page_number: number | null;
}

export interface BackendFormType {
  form_data: {[key: string]: FormFieldType};
  form_type: string;
  id: number;
  page_number?: number;
}

export const DOCUMENT_TAX_PROFILE_VALUE_MAPPING = {
  first_name_and_middle_initial: {
    priority: 0,
    label: 'First Name',
  },
  last_name: {
    priority: 1,
    label: 'Last Name',
  },
  city: {
    priority: 2,
    label: 'City',
  },
  state: {
    priority: 3,
    label: 'State',
  },
  zip_code: {
    priority: 4,
    label: 'Zip-code',
  },
  apartment_number: {
    priority: 5,
    label: 'Apartment number',
  },
  number_and_street: {
    priority: 6,
    label: 'Number and street',
  },
  income_sources: {
    priority: 7,
    label: 'Income sources',
  },
  '1099_income': {
    priority: 8,
    label: '1099 Income',
  },
  w2_income: {
    priority: 9,
    label: 'W2 Income',
  },
  social_security_number: {
    priority: 10,
    label: 'Social security number',
  },
  income: {
    priority: 11,
    label: 'Income',
  },
  tax_withheld: {
    priority: 12,
    label: 'Tax Witheld',
  },
  '401k_contri': {
    priority: 13,
    label: '401(k) Contribution',
  },
  non_emp_comp: {
    priority: 14,
    label: 'Non Employee Compensation',
  },
  intereset_income: {
    priority: 15,
    label: 'Interest Income',
  },
  total_ordinary_dividend: {
    priority: 16,
    label: 'Total Ordinary Dividend',
  },
  qualified_dividend: {
    priority: 17,
    label: 'Qualified Dividends',
  },
  total_cap_gain_dist: {
    priority: 18,
    label: 'Total Capital Gain Dist',
  },
  rent: {
    priority: 19,
    label: 'Rent',
  },
  royalties: {
    priority: 20,
    label: 'Royalties',
  },
  other_income: {
    priority: 21,
    label: 'Other Income',
  },
  '1099_g_un_emp_comp': {
    priority: 22,
    label: 'Unemployment Compensation',
  },
  '1099_g_local_funds': {
    priority: 23,
    label: 'State or local income tax, credits, reunds or offsets',
  },
  '1099_b_loss': {
    priority: 24,
    label: 'Profie or loss in the Financial Year on Closed Contracts',
  },
  charity_amount: {
    priority: 25,
    label: 'Charity Amount',
  },
  biz_ein: {
    priority: 26,
    label: 'EIN of Business',
  },
  total_ira_contri: {
    priority: 27,
    label: 'Total IRA Contribution',
  },
  already_paid_tax: {
    priority: 28,
    label: 'Tax Already Paid In FY Filing For',
  },
};

export const getTaxProfileKeyAndValuesList = (backendDocTaxProfileFields: {
  [key: string]: any;
}) => {
  const keyAndValueList: Array<{
    label: string;
    priority: number;
    value: string;
  }> = [];

  const keys = Object.keys(backendDocTaxProfileFields);

  keys.forEach((key) => {
    if (backendDocTaxProfileFields[key] == null) return;
    switch (key) {
      case DOCUMENT_TAX_PROFILE_KEY_TYPE.FULL_LEGAL_NAME: {
        const k = Object.keys(backendDocTaxProfileFields[key]);
        k.forEach((field: string) =>
          keyAndValueList.push({
            // @ts-ignore
            ...DOCUMENT_TAX_PROFILE_VALUE_MAPPING[field],
            value: `${backendDocTaxProfileFields[key][field]}`,
          }),
        );
        break;
      }
      case DOCUMENT_TAX_PROFILE_KEY_TYPE.HOME_ADDRESS: {
        const k = Object.keys(backendDocTaxProfileFields[key]);
        k.forEach((field: string) => {
          keyAndValueList.push({
            // @ts-ignore
            ...DOCUMENT_TAX_PROFILE_VALUE_MAPPING[field],
            value: `${backendDocTaxProfileFields[key][field]}`,
          });
        });
        break;
      }
      case DOCUMENT_TAX_PROFILE_KEY_TYPE.INCOME_SOURCES: {
        keyAndValueList.push({
          // @ts-ignore
          ...DOCUMENT_TAX_PROFILE_VALUE_MAPPING[key],
          value: `${backendDocTaxProfileFields[key].join(', ')}`,
        });
        break;
      }
      default: {
        keyAndValueList.push({
          // @ts-ignore
          ...DOCUMENT_TAX_PROFILE_VALUE_MAPPING[key],
          value: `${backendDocTaxProfileFields[key]}`,
        });
        break;
      }
    }
  });
  return keyAndValueList.sort((a, b) => a.priority - b.priority);
};

export enum BackendDocTypeStatus {
  USER_ACTION_REQUIRED = 'User action required',
  CAN_REVIEW = 'Can review',
  APPROVED = 'Approved',
  NOT_APPLICABLE = 'Not applicable',
  NEW = 'NEW',
}

interface BackendDocumentsResponse {
  id: number;
  status: BackendDocTypeStatus;
  year: number;
  links: Array<{
    id: number;
    status: VALID_DOCUMENT_STATUS;
    reason: VALID_DOCUMENT_REASON;
    filename: string;
    doc_link: string;
    extras: {
      [key: string]: any;
    };
  }>;
  doc_type: string;
  no_upload_reason: string | null;
}

export const backendDocumentsResponseMapper: (
  documents: Array<BackendDocumentsResponse>,
) => Array<DocumentType> = (documents) => {
  return documents.map((documentType) => {
    const {
      id: documentTypeId,
      status,
      year,
      doc_type: documentTypeName,
      links,
      no_upload_reason,
    } = documentType;

    let pendingForReviewCount = 0;

    const documents: Array<Document> = links.map((link) => {
      const {
        id: documentId,
        status,
        filename,
        doc_link,
        reason,
        extras,
      } = link;
      const documentURI = getDocument(doc_link);
      pendingForReviewCount +=
        status === VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW ||
        status === VALID_DOCUMENT_STATUS.ACCEPTED
          ? 1
          : 0;
      const isImage =
        doc_link.toLowerCase().endsWith('jpg') ||
        doc_link.toLowerCase().endsWith('png') ||
        doc_link.toLowerCase().endsWith('jpeg');
      return {
        documentId,
        status,
        filename,
        documentURI,
        isImage,
        reason,
        taxProfileFields: getTaxProfileKeyAndValuesList(extras),
        documentTypeId,
        documentTypeName,
      };
    });
    return {
      documentTypeId,
      status,
      year,
      documentTypeName,
      documents,
      pendingForReviewCount,
      noUploadReason: no_upload_reason,
    };
  });
};

export const backendFormsResponseMapper: (forms: Array<BackendFormType>) => {
  [id: number]: FormType;
} = (forms) => {
  let formObject: {[id: number]: FormType} = {};
  forms.forEach((form) => {
    const {form_data, form_type, id, page_number = null} = form;
    const formData: {[key: string]: FormFieldType} = {};
    Object.keys(form_data).forEach((formKey) => {
      // convert field values which are null to null array
      if (Array.isArray(form_data[formKey].field_type)) {
        form_data[formKey].field_values.forEach((fieldValue) => {
          if (fieldValue.value === null) {
            fieldValue.value = Array(
              form_data[formKey].field_type.length,
            ).fill(null);
          }
        });
      }

      formData[formKey] = {
        field_values:
          form_data[formKey].field_values.length === 0
            ? [
                {
                  key_bounding_box: null,
                  value: Array.isArray(form_data[formKey].field_type)
                    ? Array(form_data[formKey].field_type.length).fill(null)
                    : null,
                  value_bounding_box: null,
                },
              ]
            : form_data[formKey].field_values,
        field_type: form_data[formKey].field_type,
        label: form_data[formKey].label,
        dropdown_choices: form_data[formKey].dropdown_choices,
      };
    });
    formObject[id] = {
      form_data: formData,
      form_type: form_type,
      id,
      page_number,
    };
  });

  return formObject;
};

export const aggregateDocumentsActionCount = (
  documentsData: Array<DocumentType>,
) => {
  return documentsData
    ?.map((docType) => docType.pendingForReviewCount)
    ?.reduce((n1, n2) => n1 + n2, 0);
};
