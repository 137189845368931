import React from 'react';

const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67442 3.62317C10.7478 1.79227 13.2521 1.79228 14.3255 3.62317L22.3298 17.2758C22.8929 18.2362 22.8556 19.3431 22.4223 20.2065C21.9882 21.0716 21.121 21.75 20.0042 21.75H3.99576C2.87899 21.75 2.0118 21.0716 1.57766 20.2065C1.14438 19.3431 1.1071 18.2362 1.67019 17.2758L9.67442 3.62317ZM10.75 17C10.75 16.3096 11.3096 15.75 12 15.75H12.0122C12.7026 15.75 13.2622 16.3096 13.2622 17C13.2622 17.6904 12.7026 18.25 12.0122 18.25H12C11.3096 18.25 10.75 17.6904 10.75 17ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V13.406C11 13.9583 11.4477 14.406 12 14.406C12.5523 14.406 13 13.9583 13 13.406V8Z"
        fill="#FFC94A"
      />
    </svg>
  );
};

export default WarningIcon;
