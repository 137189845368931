import React, {useMemo} from 'react';
import {isUndefined, keyBy} from 'lodash';
import {useSelector} from 'react-redux';
import {FormTypesRenderOcrDataFromTaxForm} from 'src/store/taxForms/taxForms.constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';
import RenderTaxForm from './RenderTaxForm';

interface NewRenderOcrDataProps {
  taxFormIds: number[];
}

const NewRenderOcrData = ({taxFormIds}: NewRenderOcrDataProps) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);

  const tfMap = useMemo(() => keyBy(taxForms, 'taxFormId'), [taxForms]);

  const taxFormsToRender = useMemo(() => {
    const _: GenericTaxForm[] = [];
    taxFormIds.forEach((tId) => {
      if (
        !isUndefined(tfMap[tId]) &&
        FormTypesRenderOcrDataFromTaxForm.includes(tfMap[tId].formType)
      ) {
        _.push(tfMap[tId]);
      }
    });
    return _;
  }, [taxFormIds, tfMap]);

  return (
    <div style={{paddingInline: 12}}>
      {taxFormsToRender.map((taxform) => {
        return <RenderTaxForm taxform={taxform} />;
      })}
    </div>
  );
};

export default NewRenderOcrData;
