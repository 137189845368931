import {Button, Modal, TextField, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {themmeColor} from 'src/constants/constants';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {Info} from '@mui/icons-material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CreatePromoCodeProps {
  isOpen: boolean;
  onClose: () => void;
  onCreatePromoCode: (data: any) => void;
  loading: boolean;
}

enum FIELD_ID {
  COUPON_CODE = 'coupon_code',
  PROMO_CODE = 'promo_code',
  EXPIRES_AT = 'expires_at',
  MAX_USERS = 'max_users',
}

const CreatePromoCode = ({
  isOpen,
  onClose,
  loading,
  onCreatePromoCode,
}: CreatePromoCodeProps) => {
  const [promoInfo, setPromoInfo] = useState({
    [FIELD_ID.COUPON_CODE]: '',
    [FIELD_ID.PROMO_CODE]: '',
    [FIELD_ID.EXPIRES_AT]: null,
    [FIELD_ID.MAX_USERS]: null,
  });

  const onChange = (key: FIELD_ID, value: any) => {
    if (key === FIELD_ID.EXPIRES_AT) {
      console.log({value});
      setPromoInfo((prev) => ({...prev, [key]: value}));
      return;
    }
    console.log({key, v: value.target.value, promoInfo});
    setPromoInfo((prev) => ({...prev, [key]: value.target.value}));
  };

  const config = [
    {
      placeholder: 'Enter coupon code',
      tooltipText:
        'coupon-code is case sentitive, it should already exists in database',
      key: FIELD_ID.COUPON_CODE,
      Component: TextField,
      extraParams: {
        fullWidth: true,
      },
    },
    {
      placeholder: 'Enter promo code',
      tooltipText: 'promo-code most probably in uppercase',
      key: FIELD_ID.PROMO_CODE,
      Component: TextField,
      extraParams: {
        fullWidth: true,
      },
    },
    {
      placeholder: 'Enter expiry time',
      tooltipText: 'Expiry time in America/Creston timezone',
      key: FIELD_ID.EXPIRES_AT,
      Component: DateTimePicker,
      extraParams: {
        disablePast: true,
        slotProps: {textField: {fullWidth: true}},
        timezone: 'America/Creston',
      },
    },
    {
      placeholder: 'Enter max users',
      tooltipText: '0 max users mean no-limit',
      key: FIELD_ID.MAX_USERS,
      Component: TextField,
      extraParams: {
        fullWidth: true,
      },
    },
  ];

  const _createPromo = () => {
    let datetime = null;
    const {promo_code, coupon_code, expires_at, max_users} = promoInfo;
    try {
      datetime = dayjs(expires_at).toISOString();
    } catch (e) {}
    onCreatePromoCode({
      promo_code,
      coupon_code,
      expires_at: datetime,
      max_users,
    });
  };

  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={isOpen}
      disableAutoFocus={true}
      onClose={onClose}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          padding: 24,
          borderRadius: 12,
          flexDirection: 'column',
          display: 'flex',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {config.map((Field) => (
            <>
              <div
                style={{
                  width: 280,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Field.Component
                  value={promoInfo[Field.key]}
                  placeholder={Field.placeholder}
                  onChange={(e) => onChange(Field.key, e)}
                  {...Field.extraParams}
                />
                <Tooltip key={Field.key} title={Field.tooltipText}>
                  <Info style={{marginLeft: 16}} />
                </Tooltip>
              </div>
              <br />
            </>
          ))}
        </LocalizationProvider>
        <Button disabled={loading} variant="contained" onClick={_createPromo}>
          Create promo-code
        </Button>
      </div>
    </Modal>
  );
};

export default CreatePromoCode;
