import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Outlet, useParams} from 'react-router-dom';
import {getActionCounts, getUserInfo, initTabsActionCount} from 'src/appApi';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import TabSwitchComponent from 'src/CpaCenterUserInfo/components/TabSwitchComponent';
import TopUserInfoBar from 'src/CpaCenterUserInfo/components/TopUserInfoBar';
import UserInfoContext, {UserInfo} from './UserInfoContext';
import {CircularProgress, Grid, useMediaQuery, useTheme} from '@mui/material';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useStyles = makeStyles({
  container: {
    overflowY: 'scroll',
  },
  topUserBar: {
    borderBottom: '1px solid black',
  },
  content: {
    height: '100vh',
  },
  tabSwitchStyles: {
    borderRight: '1px solid black',
    height: '100%',
  },
  outletStyles: {
    overflow: 'hidden',
    height: '100%',
  },
});

const CpaCenterUserInfo = () => {
  const {activeYear} = useActiveYear();
  const {flyfin_user_id} = useParams();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    id: 0,
    fly_user_id: flyfin_user_id,
    fly_user_name: '',
    email: '',
    [BACKEND_RESPONSE_KEYS.NOTE]: '',
    [BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE]: '',
    [BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE]: '',
    [BACKEND_RESPONSE_KEYS.STATUS]: 1,
    [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]: '',
    [BACKEND_RESPONSE_KEYS.CCH_CLIENT_ID]: '',
  });
  const [actionCounts, setActionCounts] = useState(initTabsActionCount());

  const fetchActionCount = async () => {
    if (!flyfin_user_id) return;

    const newCounts = await getActionCounts({
      user: flyfin_user_id,
      year: activeYear,
    });
    setActionCounts((prev) => ({...prev, ...newCounts}));
  };

  const fetchUserDetails = async () => {
    if (!flyfin_user_id) return;
    setLoading(true);
    try {
      const response = await getUserInfo({
        fly_user_id: flyfin_user_id,
        year: activeYear,
      });
      const details = response.data.results?.[0];
      setUserInfo(details);
    } catch (e) {
      alert('Something went wrong!!!');
    } finally {
      setLoading(false);
    }
  };

  const theme = useTheme();
  const lessThanXL = useMediaQuery(theme.breakpoints.down('xl'));
  const styles = useStyles({lessThanXL});

  useEffect(() => {
    fetchUserDetails();
    fetchActionCount();
  }, [activeYear]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <UserInfoContext.Provider value={{userInfo, fetchUserDetails}}>
      <Grid className={styles.container}>
        <Grid className={styles.topUserBar} item lg={12} xl={12}>
          <TopUserInfoBar />
        </Grid>
        <Grid container lg={12} xl={12} className={styles.content}>
          <Grid item md={1} lg={1} xl={1} className={styles.tabSwitchStyles}>
            <TabSwitchComponent {...actionCounts} />
          </Grid>
          <Grid item md={11} lg={11} xl={11} className={styles.outletStyles}>
            <Outlet context={{fetchActionCount, setActionCounts}} />
          </Grid>
        </Grid>
      </Grid>
    </UserInfoContext.Provider>
  );
};

export default CpaCenterUserInfo;
