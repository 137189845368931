import {Typography} from '@mui/material';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import SubSectionBox from '../../common/SubSectionBox';
import EducationCreditsCriteriaCard from './EducationCreditsCriteriaCard';
import {useEducationCredits} from './useEducationCredits';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';

const EducationalCredits = () => {
  const {educationCredits, isLoading} = useEducationCredits();
  const {summaryVariant} = useTaxProfileSummaryFilter();

  return (
    <SubSectionBox
      name="Educational credits"
      taxprofileSubsection={TaxProfileSubsection.EducationalCredits}>
      {isLoading ? (
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {display: 'flex', justifyContent: 'space-between'}
          }>
          <Skeleton width={'32vw'} height={300} />
          <Skeleton width={'32vw'} height={300} />
        </div>
      ) : (
        <>
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                  }
            }>
            {educationCredits.map(
              ({name, relation, selectedChoices, doc, amount, tf}) => {
                return (
                  <Box
                    col
                    borderColor={themmeColor.silver}
                    style={{minWidth: '48%', flex: 0.48, marginBlock: 10}}>
                    <EducationCreditsCriteriaCard
                      name={name}
                      relation={relation}
                      selectedChoices={selectedChoices}
                    />
                    <Typography
                      style={{
                        fontSize: 13,
                        color: themmeColor.black60,
                        marginTop: 20,
                        marginBottom: 4,
                      }}>
                      Related document
                    </Typography>
                    <DocumentPointer
                      border
                      doc={doc}
                      taxform={tf}
                      dontShowStatus
                    />
                    <Typography
                      style={{
                        fontSize: 13,
                        color: themmeColor.black60,
                        marginTop: 20,
                        marginBottom: 4,
                      }}>
                      Applicable deductions
                    </Typography>
                    <KeyValueTable
                      data={[{amount}]}
                      keys={[
                        {getValue: () => 'Other educational expenses'},
                        {
                          getValue: ({amount}) =>
                            amount ? CurrencyNumberFormat(amount) : amount,
                          cellStyle: {
                            justifyContent: 'flex-end',
                            display: 'flex',
                          },
                          textStyle: {fontWeight: 600},
                        },
                      ]}
                    />
                  </Box>
                );
              },
            )}
          </div>
        </>
      )}
    </SubSectionBox>
  );
};

export default EducationalCredits;
