import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useSearchParams} from 'react-router-dom';
import {PartnershipFiltersConfig} from './PartnershipUserList.constants';
import {
  PartnershipFilterType,
  PartnershipFilters,
} from './PartnershipUserList.types';
import {transform} from 'src/DesignSystem/Form/Forms.transformers';
import {
  assignCPAToUser,
  assignReviewerToPartnershipReturn,
  listPartnershipReturns,
  patchTaxFiling,
} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {
  PartnershipReturnFields,
  PartnershipReturnsListApiResponse,
} from 'src/appApi.types';
import Table from 'src/DesignSystem/Table/Table';

const usePartnershipFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const appliedFilters = useMemo(() => {
    // @ts-ignore
    const _filters: {
      [key in PartnershipFilters]: any;
    } = {};
    PartnershipFiltersConfig.forEach((filter) => {
      let valueGetter: (props: string) => string | string[] | null =
        searchParams.get;
      if (filter.type === PartnershipFilterType.multi) {
        valueGetter = searchParams.getAll;
      }
      const rawValue = valueGetter.call(searchParams, filter.filter_name);
      const transformedValue = transform(rawValue, filter.transform);
      _filters[filter.filter_name] = transformedValue;
    });
    return _filters;
  }, [searchParams]);

  const [filterCopy, setFilterCopy] = useState(() => {
    return {...appliedFilters};
  });

  useEffect(() => {
    setFilterCopy({...appliedFilters});
  }, [appliedFilters]);

  const updateFilterCopy = useCallback(
    (key: PartnershipFilters, value: any) => {
      setFilterCopy((old) => ({...old, [key]: value}));
    },
    [],
  );

  const applyFilter = useCallback((filtersToApply: typeof filterCopy) => {
    const keys = Object.keys(filtersToApply);
    const urlsSeachParams = new URLSearchParams();
    keys.forEach((key) => {
      // @ts-ignore
      const value = filtersToApply[key];
      if (Array.isArray(value)) {
        value.forEach((v) => {
          urlsSeachParams.append(key, v);
        });
      } else if (typeof value === 'string' && value.length > 0) {
        urlsSeachParams.append(key, value);
      } else if (Number.isInteger(value)) {
        urlsSeachParams.append(key, value);
      }
    });
    setSearchParams(urlsSeachParams);
  }, []);

  const applyFiltersFromCopy = useCallback(() => {
    // reset page to number 1
    filterCopy[PartnershipFilters.page] = 1;
    applyFilter(filterCopy);
  }, [filterCopy, setSearchParams, applyFilter]);

  const clearAllFilters = useCallback(() => {
    const urlsSeachParams = new URLSearchParams();
    setSearchParams(urlsSeachParams);
  }, []);

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      applyFilter({...filterCopy, [PartnershipFilters.page]: pageNumber});
    },
    [filterCopy, applyFilter],
  );

  const changePageSize = useCallback(
    (pageSize: number) => {
      applyFilter({
        ...filterCopy,
        [PartnershipFilters.page]: 1,
        [PartnershipFilters.page_size]: pageSize,
      });
    },
    [filterCopy, applyFilter],
  );

  return {
    appliedFilters,
    filterCopy,
    updateFilterCopy,
    applyFiltersFromCopy,
    clearAllFilters,
    changePageNumber,
    changePageSize,
  };
};

const usePartnershipUserListContext = () => {
  const value = usePartnershipFilters();
  const {notify} = useNotify();
  const [partnershipReturns, setPartnershipReturns] =
    useState<PartnershipReturnsListApiResponse>({
      count: 0,
      next: null,
      prev: null,
      results: [],
    });
  const [loadingReturns, setLoadingReturns] = useState(false);

  useEffect(() => {
    let ignore = false;

    setLoadingReturns(true);
    listPartnershipReturns(value.appliedFilters)
      .then((response) => {
        if (!ignore) {
          setPartnershipReturns(response.data);
        }
      })
      .catch((e) => {
        notify('Failed to get Partnership returns', NotificationType.error);
      })
      .finally(() => {
        setLoadingReturns(false);
      });

    return () => {
      ignore = true;
    };
  }, [value.appliedFilters]);

  const onUpdateRow: ComponentProps<typeof Table>['processRowUpdate'] = async (
    newRow,
    oldRow,
  ) => {
    try {
      if (
        oldRow[PartnershipReturnFields.assigned_cpa] !==
        newRow[PartnershipReturnFields.assigned_cpa]
      ) {
        await assignCPAToUser(
          newRow[PartnershipReturnFields.assigned_cpa],
          newRow[PartnershipReturnFields.fly_user_id],
        );
        notify('CPA updated', NotificationType.success);
      }
      if (
        oldRow[PartnershipReturnFields.reviewer] !==
        newRow[PartnershipReturnFields.reviewer]
      ) {
        await assignReviewerToPartnershipReturn(
          newRow[PartnershipReturnFields.tax_filing_id],
          {reviewer_email: newRow[PartnershipReturnFields.reviewer]},
        );
        notify('Reviewer updated', NotificationType.success);
      }
      if (
        oldRow[PartnershipReturnFields.notes] !==
        newRow[PartnershipReturnFields.notes]
      ) {
        await patchTaxFiling(
          {
            tax_filing_id: newRow[PartnershipReturnFields.tax_filing_id],
          },
          {
            [PartnershipReturnFields.notes]:
              newRow[PartnershipReturnFields.notes],
          },
        );
        notify('Note updated', NotificationType.success);
      }
      return newRow;
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
      return oldRow;
    }
  };

  return {
    ...value,
    partnershipReturns,
    loadingReturns,
    onUpdateRow,
  };
};

const PartnershipUserListContext =
  // @ts-ignore
  React.createContext<ReturnType<typeof usePartnershipUserListContext>>(null);

export const PartnershipUserListProvider = ({children}: any) => {
  const value = usePartnershipUserListContext();

  return (
    <PartnershipUserListContext.Provider value={value}>
      {children}
    </PartnershipUserListContext.Provider>
  );
};

export const usePartnershipUserList = () => {
  return useContext(PartnershipUserListContext);
};
