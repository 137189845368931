import {PartnersListApiResponse} from 'src/appApi.types';
import {REDUX_STATUS, ReduxActionType} from '../types';
import {SET_PARTNERS} from '../actionNames';

const initialState = {
  partners: [] as PartnersListApiResponse['results'],
  status: REDUX_STATUS.INIT,
  loaded: false,
};

export const partners = (
  state = initialState,
  action: ReduxActionType,
): typeof initialState => {
  switch (action.type) {
    case SET_PARTNERS: {
      return {
        ...state,
        partners: action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type PartnersStateType = typeof initialState;
