import React, {useContext, useMemo, useState} from 'react';
import {
  PartnersDocOcrDataResponse,
  PartnershipReturnCreationItem,
  PartnershipReturnsListItem,
} from 'src/appApi.types';
import {CREATE_RETURN_STATUS} from './CreateReturn.types';
import useUploadDocument from 'src/CpaCenterV2/hooks/useUploadDocument';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {tranformOcrDataToUserRelationsData} from './CreateReturn.utils';
import {DOCUMENT_TYPES} from 'src/constants/constants';

const useCreateReturnProvider = () => {
  const [createdReturns, setCreatedReturns] = useState<
    PartnershipReturnCreationItem[]
  >([]);

  const [ocrDocsData, setOcrDocsData] =
    useState<null | PartnersDocOcrDataResponse>(null);

  const backendTransformedUserData = useMemo(() => {
    if (ocrDocsData === null) return [];
    return tranformOcrDataToUserRelationsData(ocrDocsData);
  }, [ocrDocsData]);

  const pendingDocs = useMemo(() => {
    if (ocrDocsData === null) {
      return [];
    }
    const allNon1040 = ocrDocsData.tax_docs_data.filter(
      (doc) => doc.doc_type !== DOCUMENT_TYPES.LAST_YEAR_TAX_RETURN_FORM_1040,
    );
    return allNon1040;
  }, [ocrDocsData]);

  const {notify} = useNotify();

  const {
    uploadPartnersDocumentOnS3,
    postBulkDocumentForOcr,
    getPartnerOcrDocsData,
  } = useUploadDocument();

  const [status, setStatus] = useState<CREATE_RETURN_STATUS>(
    CREATE_RETURN_STATUS.INIT,
  );

  const onUploadDocument = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    try {
      setStatus(CREATE_RETURN_STATUS.UPLOADING_DOCUMENT);
      const fileInfo = await uploadPartnersDocumentOnS3(files);
      const {ocr_job_ids} = await postBulkDocumentForOcr({
        filesdata: fileInfo,
      });
      setStatus(CREATE_RETURN_STATUS.OCR_IN_PROGRESS);
      const response = await getPartnerOcrDocsData(ocr_job_ids);
      notify('OCR Done successfully', NotificationType.success);
      setOcrDocsData(response.data);
      setStatus(CREATE_RETURN_STATUS.CONFIRM_USER_RELATION);
    } catch (e) {
      setStatus(CREATE_RETURN_STATUS.INIT);
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
    }
  };

  return {
    createdReturns,
    setCreatedReturns,
    onUploadDocument,
    setStatus,
    status,
    ocrDocsData,
    backendTransformedUserData,
    pendingDocs,
  };
};

const Context =
  // @ts-ignore
  React.createContext<ReturnType<typeof useCreateReturnProvider>>(null);

export const CreateReturnProvider = ({children}: any) => {
  const value = useCreateReturnProvider();
  return (
    <Context.Provider value={value}>
      {children}
      <PopUp
        style={{
          height: 300,
          width: 600,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
        isOpen={[
          CREATE_RETURN_STATUS.UPLOADING_DOCUMENT,
          CREATE_RETURN_STATUS.OCR_IN_PROGRESS,
        ].includes(value.status)}
        onClose={() => {}}>
        <Text
          type={TEXT_TYPES.H6}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={
            value.status === CREATE_RETURN_STATUS.UPLOADING_DOCUMENT
              ? 'Uploading document...'
              : 'Performing OCR...'
          }
        />
      </PopUp>
    </Context.Provider>
  );
};

export const useCreateReturn = () => {
  return useContext(Context);
};
