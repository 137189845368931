import {Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {BackendBusinessTaxReturnInfo} from 'src/appApi.types';
import {themmeColor} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import Skeleton from 'src/icons/Skeleton';
import {BusinessType} from 'src/store/businessDetails/businessDetails.types';
import {
  StateReturnType,
  StatesInfo,
} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.reducer';

interface StatesTaxFilingCategoryProps {
  stateReturnsInfo: StatesInfo[];
  businessStateReturnInfo: BackendBusinessTaxReturnInfo[];
  isLoading: boolean;
  getState: (row: StatesInfo) => string;
}

interface BusinessReturnsInfo {
  states: {[key: string]: {name: string; returnType: StateReturnType[]}};
  businessName: string;
}

const businessEntityToStateReturnTypeMap = {
  [BusinessType.C_CORP]: StateReturnType.C_CORP_RETURN,
  [BusinessType.PARTNERSHIP]: StateReturnType.PARTNERSHIP_RETURN,
  [BusinessType.S_CORP]: StateReturnType.S_CORP_RETURN,
};

const StatesTaxFilingCategory = ({
  stateReturnsInfo,
  businessStateReturnInfo,
  isLoading,
  getState,
}: StatesTaxFilingCategoryProps) => {
  const stateWithTaxes = isLoading
    ? []
    : stateReturnsInfo.filter(
        (state) => state.return_type !== StateReturnType.TAX_FILING_NOT_NEEDED,
      );
  const stateWithoutTaxes = isLoading
    ? []
    : stateReturnsInfo.filter(
        (state) => state.return_type === StateReturnType.TAX_FILING_NOT_NEEDED,
      );
  const statesMayRequireCityTaxfiling = isLoading
    ? []
    : [...stateReturnsInfo, ...businessStateReturnInfo].filter(
        (info) => info.is_city_tax_filing_maybe_needed,
      );

  const businessReturns = useMemo(() => {
    let obj = {} as {[key: number]: BusinessReturnsInfo};

    businessStateReturnInfo.forEach((info) => {
      info.related_businesses.forEach((business) => {
        obj[business.id] = {
          ...(obj[business.id] ?? {}),
          businessName: business.name,
          states: {
            ...(obj[business.id]?.states ?? {}),
            [info.state]: {
              name: info.state,
              returnType: [
                ...(obj[business.id]?.states
                  ? obj[business.id]?.states[info.state]?.returnType ?? []
                  : []),
                businessEntityToStateReturnTypeMap[business.entity_type],
              ],
            },
          },
        };
      });

      info.related_shareholders.forEach((shareholder) => {
        obj[shareholder.related_business_id] = {
          ...(obj[shareholder.related_business_id] ?? {}),
          businessName: shareholder.business_name,
          states: {
            ...(obj[shareholder.related_business_id]?.states ?? {}),
            [info.state]: {
              name: info.state,
              returnType: [
                ...(obj[shareholder.related_business_id]?.states
                  ? obj[shareholder.related_business_id]?.states[info.state]
                      ?.returnType ?? []
                  : []),
                StateReturnType.RESIDENT_PARTNER,
              ],
            },
          },
        };
      });
    });

    return obj;
  }, [businessStateReturnInfo]);

  const mapReturnTypeToString = (returnType: StateReturnType) => {
    switch (returnType) {
      case StateReturnType.RESIDENT:
        return 'Resident';
      case StateReturnType.NOT_RESIDENT:
        return 'Non-resident';
      case StateReturnType.PART_YEAR_RESIDENT:
        return 'Part year resident';
      case StateReturnType.TAX_FILING_NOT_NEEDED:
        return 'Tax filing not needed';
    }
    return 'NA';
  };

  const getReturnType = (row: StatesInfo) => {
    const huanReadableStateType = mapReturnTypeToString(row.return_type);

    return `${huanReadableStateType}${
      row.related_income_forms.length > 0 ? ' & Income' : ''
    }`;
  };

  const stateAndTaxesKey = [
    {
      getValue: getState,
    },
    {
      getValue: getReturnType,
      textStyle: {
        fontWeight: 600,
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ];
  return (
    <div>
      <Typography
        style={{fontSize: 13, color: themmeColor.black60, marginBottom: 8}}>
        States that needs filing
      </Typography>
      {isLoading ? (
        <Skeleton width={'100%'} height={'20vh'} />
      ) : (
        <KeyValueTable data={stateWithTaxes} keys={stateAndTaxesKey} />
      )}
      <Typography
        style={{
          fontSize: 13,
          color: themmeColor.black60,
          marginBottom: 8,
          marginTop: 20,
        }}>
        States that don’t need filing
      </Typography>
      {isLoading ? (
        <Skeleton width={'100%'} height={'20vh'} />
      ) : (
        <KeyValueTable data={stateWithoutTaxes} keys={stateAndTaxesKey} />
      )}
      <Typography
        style={{
          fontSize: 13,
          color: themmeColor.black60,
          marginBottom: 8,
          marginTop: 20,
        }}>
        Check for city tax return in following state
      </Typography>
      {isLoading ? (
        <Skeleton width={'100%'} height={'20vh'} />
      ) : (
        <KeyValueTable
          data={statesMayRequireCityTaxfiling}
          keys={[{getValue: getState}]}
        />
      )}
      {isLoading ? (
        <Skeleton width={'100%'} height={'20vh'} />
      ) : (
        Object.values(businessReturns).map((info) => {
          return (
            <>
              <Typography
                style={{
                  fontSize: 13,
                  color: themmeColor.black60,
                  marginBottom: 8,
                  marginTop: 20,
                }}>
                {`Business return filing states - ${info.businessName}`}
              </Typography>
              <KeyValueTable
                data={Object.values(info.states)}
                keys={[
                  {getValue: (data) => data.name},
                  {
                    getValue: (data) => data.returnType.join(' & '),
                    textStyle: {
                      fontWeight: 600,
                    },
                    cellStyle: {
                      display: 'flex',
                      justifyContent: 'flex-end',
                    },
                  },
                ]}
              />
            </>
          );
        })
      )}
    </div>
  );
};

export default StatesTaxFilingCategory;
