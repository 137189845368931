import {SWITCH_ACTIVE_YEAR} from '../actionNames';
import {SUPPORTED_YEARS} from './globals.reducer';

const setActiveYear = ({year}: {year: number}) => {
  return {
    type: SWITCH_ACTIVE_YEAR,
    payload: {
      activeYear: year,
    },
  };
};

export const switchActiveYear = ({year}: {year: number}) => {
  return async (dispatch: any) => {
    if (SUPPORTED_YEARS.includes(year)) {
      await dispatch(setActiveYear({year}));
    }
  };
};
