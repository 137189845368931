import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialButton} from '@material-ui/core/Button';
import {Fragment, useState} from 'react';
import {FormControl, MenuItem, InputLabel, Select} from '@material-ui/core';
import {useQuery, Error, useMutation, useNotify} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  textFieldStyle: {
    marginTop: '2em',
    display: 'grid',
  },
}));

const TxnSummary = ({userId, year}) => {
  const {data, loading, error} = useQuery({
    type: 'getTxnSummaryForExportData',
    resource: 'export_data',
    payload: {fly_user_id: userId, year: year},
  });

  if (loading) return <h4>Loading...</h4>;
  if (error) return <Error />;
  if (!data) return null;
  return (
    <div>
      {data.to_be_classified_txns_count > 0 && (
        <p>
          {data.to_be_classified_txns_count} expenses out of{' '}
          {data.total_txn_count} yet to be classified, amount: $
          {data.to_be_classified_txns_amount}
        </p>
      )}
      {data.to_be_categorised_txns_count > 0 && (
        <p>
          {data.to_be_categorised_txns_count} deductions are yet to be
          categorised, amount: ${data.to_be_categorised_txns_amount}
        </p>
      )}
      {data.is_phone_pct_missing && data.is_internet_pct_missing && (
        <p>Business % not provided for Phone and Internet deductions</p>
      )}
      {data.is_phone_pct_missing && !data.is_internet_pct_missing && (
        <p>Business % not provided for Phone deductions</p>
      )}
      {data.is_internet_pct_missing && !data.is_phone_pct_missing && (
        <p>Business % not provided for Internet deductions</p>
      )}
    </div>
  );
};

const YearSelectionDropdown = ({year, setYear}) => {
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const currYear = new Date().getFullYear();
  const prevYear = new Date().getFullYear() - 1;

  return (
    <FormControl size="medium" style={{width: '4em'}}>
      <InputLabel>Year</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={year}
        label="Year"
        onChange={handleChange}>
        <MenuItem value={prevYear}>{prevYear}</MenuItem>
        <MenuItem value={currYear}>{currYear}</MenuItem>
      </Select>
    </FormControl>
  );
};

const ExportDataDialog = ({open, onClose, userId}) => {
  const [year, setYear] = useState();
  const classes = useStyles();
  const notify = useNotify();

  const [exportData, {loading}] = useMutation(
    {
      type: 'export_data',
      resource: 'export_data',
      payload: {fly_user_id: userId, year: year},
    },
    {
      onSuccess: () => {
        notify('Exported Data successfully!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(`Export Data failed!: ${error.body['display_msg']}`, 'warning');
      },
    },
  );

  const handleCancel = () => {
    setYear();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      scroll="body"
      fullWidth>
      <DialogTitle id="form-dialog-title">Export Data</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <YearSelectionDropdown year={year} setYear={setYear} />
          {year && <TxnSummary userId={userId} year={year} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={handleCancel}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={exportData}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Export Data
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const ExportData = ({userId}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  return (
    <Fragment>
      <MaterialButton
        color="primary"
        variant="contained"
        onClick={handleClick}>
        Export Data
      </MaterialButton>
      <ExportDataDialog
        userId={userId}
        open={open}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default ExportData;
