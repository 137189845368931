import React, {useState} from 'react';

import {
  SimpleForm,
  TextInput,
  BooleanInput,
  Edit,
  useMutation,
  useNotify,
  SelectInput,
  NullableBooleanInput,
} from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {default as MaterialButton} from '@material-ui/core/Button';
import {AutocompleteInput} from 'react-admin';
import {Toolbar, SaveButton, GET_MANY} from 'react-admin';
import {FormDataConsumer} from 'react-admin';

import {
  DeductionClassChoices,
  BlankChoice,
  NotSureCategoryReasonChoices,
  SeverityChoices,
} from '../constants/constants';
import {getSubCategoriesFor} from '../common/ExpenseCategoriesUtils';
import Grid from '@material-ui/core/Grid';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';

import BulkClassifyButton from './BulkClassifyButton';

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        onClick={() => {
          props.setEditMode(false);
        }}
      />
    </Toolbar>
  );
};

export const dataRowStyle = (record, isSubExp) => {
  let bgColor = 'white';
  if (record != null) {
    if (record.plaid_removed) {
      bgColor = '#ffe6e6';
    } else if (record.is_reviewed) {
      bgColor = '#e0e0e0';
    }
    // else if(record.classifier_data != null && record.classifier_data.can_auto_notify){
    //     bgColor = '#efe';
    // }
  }
  return {
    backgroundColor: bgColor,
    border: isSubExp === true ? '2px solid #bab9b6' : '',
  };
};

export const NormalRow = ({
  record,
  nbCols,
  selectedIds,
  expenseCatChoices,
  categoryMap,
  markReviewOnClassify,
  resource,
  id,
  onToggleItem,
  children,
  basePath,
  currentRow,
  setCurrentRow,
  slicedChildren,
  isSubExp,
}) => {
  // This component represent the single transaction,
  // It has the quick action buttons and inline editing features.

  // record : single txn record that this row will show
  // nbCols : No. of columns the data grid have
  // selectedIds : list of ids of the selected records
  // expenseCatChoices : list of expense categories
  // categoryMap : Object containing mapping from category to sub category
  // markReviewOnClassify : boolean flag
  // resource : url resource from where data is coming
  // id : id of the record
  // onToggleItem : Callback for checkbox selection
  // children : list of child nodes
  // basePath : base url
  // currentRow : id of the current row
  // setCurrentRow : callback to set the currentrow
  // slicedChildren : removed last 3 children to add quick buttons
  // isSubExp : boolean value, is coming under any grouped expense.
  const [editMode, setEditMode] = useState(false);
  const notify = useNotify();
  const [refreshTxns] = useMutation({
    type: GET_MANY,
    resource: resource,
    payload: {ids: [id]},
  });

  const [mutate] = useMutation();
  const editTxn = (
    id,
    payloadData,
    ruleText,
    unknown_merchant,
    merchant_error_severity,
    not_sure_category,
    not_sure_cat_reason,
    is_plaid_cat_correct,
  ) =>
    mutate(
      {
        type: 'bulk_partial_update',
        resource: 'expenses',
        payload: {
          ids: [id],
          rule_text: ruleText,
          patch_data: payloadData,
          unknown_merchant,
          merchant_error_severity,
          not_sure_category,
          not_sure_cat_reason,
          is_plaid_cat_correct,
        },
      },
      {
        onSuccess: () => {
          refreshTxns();
          notify('Expense Edit successful!');
        },
        onFailure: (error) => {
          notify(
            `Expense Edit failed!: ${error.body['display_msg']}`,
            'warning',
          );
        },
      },
    );
  const customSave = (props) => {
    var payloadData = {};
    if (markReviewOnClassify === true) payloadData.is_reviewed = true;
    if (!props.classifier_data.unknown_merchant) {
      payloadData.merchant_name = props.updated_merchant_name;
      payloadData.classification_status = props.updated_classification_status;
      payloadData.expense_category = props.updated_expense_category;
      payloadData.expense_sub_category = getSubCategoriesFor(
        props.updated_expense_category,
        categoryMap,
      )[0].id;
    }
    editTxn(
      props.id,
      payloadData,
      props.rule_suggestion,
      props.classifier_data.unknown_merchant ? true : false,
      props.classifier_data.merchant_error_severity,
      props.classifier_data.unknown_merchant
        ? true
        : props.classifier_data.not_sure_category,
      props.classifier_data.unknown_merchant
        ? NotSureCategoryReasonChoices[0].id
        : props.classifier_data.not_sure_cat_reason ?? undefined,
      props.classifier_data.is_plaid_cat_correct ?? undefined,
    );
  };
  return (
    <>
      <TableRow
        style={dataRowStyle(record, isSubExp)}
        onMouseOver={() => setCurrentRow(id)}
        hover={true}
        key={id}>
        {/* first column: selection checkbox */}
        <TableCell padding="none">
          <Checkbox
            checked={selectedIds.includes(id)}
            onClick={(event) => onToggleItem(id, event)}
          />
        </TableCell>
        {/* data columns based on children */}
        {currentRow === id
          ? slicedChildren.map((field) => (
              <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            ))
          : React.Children.map(children, (field) => (
              <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            ))}
        {currentRow === id && (
          <TableCell>
            <MaterialButton onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </MaterialButton>
          </TableCell>
        )}
      </TableRow>
      {editMode && (
        <TableRow key={`${id}-expand`}>
          <TableCell colSpan={nbCols}>
            <Edit
              basePath={basePath}
              resource={resource}
              record={record}
              id={id}>
              <SimpleForm
                redirect={false}
                save={customSave}
                toolbar={<CustomToolbar setEditMode={setEditMode} />}>
                <FormDataConsumer>
                  {({formData, ...rest}) => (
                    <Grid
                      container
                      style={{width: '100%'}}
                      onSelect={(e) => {
                        e.stopPropagation();
                      }}>
                      <Grid xs>
                        <TextInput
                          label="Merchant Name"
                          disabled={formData.classifier_data.unknown_merchant}
                          source="updated_merchant_name"
                        />
                        <Grid container direction="row" alignItems="center">
                          <BooleanInput
                            label="Unknown"
                            source="classifier_data.unknown_merchant"
                          />
                        </Grid>
                        <AutocompleteInput
                          disabled={formData.classifier_data.unknown_merchant}
                          label="Error Severity"
                          source="classifier_data.merchant_error_severity"
                          choices={SeverityChoices}
                        />
                      </Grid>
                      <Grid xs>
                        <AutocompleteInput
                          disabled={formData.classifier_data.unknown_merchant}
                          label="Category"
                          source="updated_expense_category"
                          choices={expenseCatChoices}
                        />
                        <Grid container direction="row" alignItems="center">
                          <BooleanInput
                            label="Not Sure"
                            source="classifier_data.not_sure_category"
                          />
                          <SelectInput
                            disabled={
                              !formData.classifier_data.not_sure_category
                            }
                            label="Reason"
                            source="classifier_data.not_sure_cat_reason"
                            choices={NotSureCategoryReasonChoices.concat(
                              BlankChoice,
                            )}
                          />
                        </Grid>
                        <NullableBooleanInput
                          disabled={formData.classifier_data.unknown_merchant}
                          style={{width: 230}}
                          label="Is Plaid Category Correct?"
                          source="classifier_data.is_plaid_cat_correct"
                        />
                      </Grid>
                      <Grid xs>
                        <AutocompleteInput
                          disabled={formData.classifier_data.unknown_merchant}
                          label="Deduction?"
                          source="updated_classification_status"
                          choices={DeductionClassChoices.concat(BlankChoice)}
                        />
                        <TextInput
                          disabled={formData.classifier_data.unknown_merchant}
                          multiline={true}
                          source="rule_suggestion"
                        />
                      </Grid>
                    </Grid>
                  )}
                </FormDataConsumer>
              </SimpleForm>
            </Edit>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const ExpandButton = ({setEditMode, editMode}) => {
  return editMode === false ? (
    <ExpandMoreIcon
      fontSize="medium"
      style={{padding: '0px 0px 4px 8px'}}
      onClick={() => setEditMode(!editMode)}
    />
  ) : (
    <ExpandLessIcon
      fontSize="medium"
      style={{padding: '0px 0px 4px 8px'}}
      onClick={() => setEditMode(!editMode)}
    />
  );
};

export const GroupedRow = (props) => {
  // This Component represents the grouped row, that contains atleast 2 sub expenses.

  // record : single txn record that this row will show
  // resource : url resource from where data is coming
  // id : id of the record
  // onToggleItem : Callback for checkbox selection
  // categoryMap : Object containing mapping from category to sub category
  // children : list of child nodes
  // selectedIds : list of ids of the selected records
  // markReviewOnClassify : boolean flag
  // basePath : base url
  // slicedChildren : removed last 3 children to add quick buttons
  // currentRow : id of the current row
  // setCurrentRow : callback to set the currentrow

  const {
    record,
    resource,
    id,
    onToggleItem,
    categoryMap,
    children,
    selectedIds,
    markReviewOnClassify,
    basePath,
    slicedChildren,
    currentRow,
    setCurrentRow,
  } = {...props};
  const [editMode, setEditMode] = useState(false);
  return (
    <>
      <TableRow
        style={dataRowStyle(record)}
        onDoubleClick={() => setEditMode(!editMode)}
        onMouseOver={() => setCurrentRow(id)}
        hover={true}
        key={`${id}-group`}>
        {/* first column: selection checkbox */}
        <TableCell padding="none">
          <>
            <Checkbox
              checked={record.subExpenses.every((exp) =>
                selectedIds.includes(exp['id']),
              )}
              onClick={(event) =>
                record.subExpenseIds.forEach((expId) =>
                  onToggleItem(expId, event),
                )
              }
            />
            <ExpandButton editMode={editMode} setEditMode={setEditMode} />
          </>
        </TableCell>
        {/* data columns based on children */}
        {currentRow === id
          ? slicedChildren.map((field) => (
              <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            ))
          : React.Children.map(children, (field) => (
              <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            ))}
        {currentRow === id && (
          <TableCell>
            <BulkClassifyButton
              categoryMap={categoryMap}
              selectedIds={record.subExpenseIds}
              markReviewOnClassify={markReviewOnClassify}
              simple={true}
              label={<EditIcon />}
            />
          </TableCell>
        )}
      </TableRow>
      {editMode &&
        record.subExpenses.map((expense) => (
          <NormalRow
            {...props}
            record={expense}
            id={expense['id']}
            key={expense['id']}
            isSubExp={true}
          />
        ))}
    </>
  );
};
