import React, {useContext} from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Button, Typography} from '@mui/material';

import {themmeColor} from '../../constants/constants';
import MissingExpenseContext, {
  BankInfoProps,
} from '../MissingExpenses.context';
import UploadedStatementCard from './UploadedStatementCard';

const SingleBankInfoCard = ({
  bankId,
  bankName,
  bankStatements,
  startMonth,
  endMonth,
}: BankInfoProps) => {
  const {setFile, file} = useContext(MissingExpenseContext);
  const handleFileUpdate = (event: any) => {
    setFile({
      isValid: true,
      bankId,
      bankName,
      file: event.target.files?.[0],
    });
  };
  return (
    <div style={{paddingLeft: 10, paddingRight: 30, marginBottom: 20}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <Typography style={{marginRight: 8}} fontWeight={'600'}>
            {bankName} ({bankId})
          </Typography>
          <Typography color={themmeColor.grey} fontSize={13}>
            {startMonth} to {endMonth}
          </Typography>
        </div>
        <input
          type="file"
          accept=".csv"
          style={{display: 'none'}}
          id={`${bankName}-${bankId}-label`}
          onChange={handleFileUpdate}
          value={''}
        />
        <label htmlFor={`${bankName}-${bankId}-label`}>
          <Button
            variant="contained"
            component="span"
            disabled={file.isValid}
            startIcon={<UploadFileIcon style={{marginRight: 10}} />}>
            Upload for {bankName}
          </Button>
        </label>
      </div>
      <div>
        {bankStatements.map((statement) => (
          <UploadedStatementCard {...statement} />
        ))}
      </div>
    </div>
  );
};

export default SingleBankInfoCard;
