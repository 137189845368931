import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {TAX_RETURN_STATUS_LABELS} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateReturnStatusOverlay from 'src/CpaCenterList/components/UpdateReturnStatusCell/UpdateReturnStatusOverlay';

interface UpdateReturnStatusCellProps {
  currentReturnStatusId: TAX_FILING_STATUS;
  taxFilingId: number;
  onSuccess: () => void | Promise<void>;
}

const UpdateReturnStatusCell = ({
  currentReturnStatusId,
  taxFilingId,
  onSuccess,
}: UpdateReturnStatusCellProps) => {
  const currentStatus = TAX_RETURN_STATUS_LABELS[currentReturnStatusId] ?? {};
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <div
        onClick={openModal}
        style={{
          backgroundColor: currentStatus.backColor,
          borderRadius: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography
          color={currentStatus.textColor}
          textOverflow="ellipsis"
          fontSize={13}>
          {currentStatus.label ?? 'Not Submitted'}
        </Typography>
        <KeyboardArrowDownIcon style={{marginLeft: 8}} />
      </div>
      {isOpen && (
        <UpdateReturnStatusOverlay
          isVisible={isOpen}
          onClose={closeModal}
          currentReturnStatusId={currentReturnStatusId}
          taxFilingId={taxFilingId}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default UpdateReturnStatusCell;
