import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CpaCenterUserInfo from 'src/CpaCenterUserInfo/CpaCenterUserInfo';
import CpaCenterTaxProfile from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/CpaCenterTaxProfile';
import CpaCenterDocumentsOCR from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentsOCR';
import CpaCenterTaxQueries from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/CpaCenterTaxQueries';
import CpaCenterReviewAndEsign from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/CpaCenterReviewAndEsign';
import CpaCenterCCH from 'src/CpaCenterUserInfo/CpaCenterCCH/CpaCenterCCH';
import DocumentAndQueryViewer from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/DocumentAndQueryViewer/DocumentAndQueryViewer';
import {ReviewEsignRoute, UserInfoRoute} from 'src/constants/routeName';
import ReviewAndEsignQueries from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/DocumentAndQueryViewer/REUserQueries/ReviewAndEsignQueries/ReviewAndEsignQueries';
import REAllQueries from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/DocumentAndQueryViewer/REUserQueries/REAllQueries';
import QueryViewer from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/QueryViewer';
import DocumentSwitcher from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentSwitcher/DocumentSwitcher';
import DocumentViewer from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentSwitcher/DocumentViewer/DocumentViewer';
import UploadEsignDocuments from './CpaCenterReviewAndEsign/UploadEsignDocuments/UploadEsignDocuments';
import RECpaReview from './CpaCenterReviewAndEsign/RECpaReview/RECpaReview';
import BankDetails from './CpaCenterReviewAndEsign/BankDetails/BankDetails';

const UserInfoRouter = () => {
  return (
    <Routes>
      <Route path={':flyfin_user_id'} element={<CpaCenterUserInfo />}>
        <Route
          path={UserInfoRoute.TaxProfile}
          element={<CpaCenterTaxProfile />}
        />
        <Route
          path={UserInfoRoute.DocumentsOCR}
          element={<CpaCenterDocumentsOCR />}>
          <Route path={':docTypeId'} element={<DocumentSwitcher />}>
            <Route path={':docId'} element={<DocumentViewer />} />
          </Route>
        </Route>
        <Route
          path={UserInfoRoute.TaxQueries}
          element={<CpaCenterTaxQueries />}>
          <Route path=":queryId" element={<QueryViewer />} />
        </Route>
        <Route
          path={UserInfoRoute.UserRES}
          element={<CpaCenterReviewAndEsign />}>
          <Route path={':docId'} element={<DocumentAndQueryViewer />}>
            <Route index element={<REAllQueries />} />
            <Route path={':queryId'} element={<ReviewAndEsignQueries />} />
          </Route>
          <Route
            path={ReviewEsignRoute.UploadReturn}
            element={<UploadEsignDocuments />}
          />
          <Route path={ReviewEsignRoute.CpaReview} element={<RECpaReview />} />
          <Route
            path={ReviewEsignRoute.BankDetails}
            element={<BankDetails />}
          />
        </Route>
        <Route path={UserInfoRoute.UserCCH} element={<CpaCenterCCH />} />
      </Route>
    </Routes>
  );
};

export default UserInfoRouter;
