import {AuthorType, QUERY_STATUS} from 'src/constants/constants';
import {
  ReviewAndEsignDocument,
  ReviewAndEsignMessage,
  ReviewAndEsignQuery,
  ReviewEsignDocumentType,
} from './ReviewAndEsignContext';
import {BackendQuery} from 'src/types/query.types';

export enum FORM_TYPE {
  FEDERAL_RETURN = 'FEDERAL_RETURN',
  STATE_RETURN = 'STATE_RETURN',
}

export enum RE_ACTIONS {
  INIT = 'INIT',
  DOCUMENT_PROOF = 'DOCUMENT_PROOF',
  PROOF_OR_INFO_UPDATE = 'PROOF_OR_INFO_UPDATE',
  RESOLVE = 'RESOLVE',
  PROOF_OR_EXPENSE_OR_ACCOUNT = 'PROOF_OR_EXPENSE_OR_ACCOUNT',
}

export const MANDATORY_JURISDICTION = 'Federal';

export enum CPA_REVIEW_THREAD_STATUS {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export enum CPA_REVIEW_MESSAGE_TYPE {
  CHAT = 'CHAT',
}

export enum ReviewDocStateKeys {
  FILE = 'file',
  FILENAME = 'filename',
  JURISDICTION = 'jurisdiction',
  SIGNNOW_DOC_ID = 'sign_now_document_id',
  FEDERAL = 'federal',
  STATE = 'state',
  ESIGN_FIELDS = 'esign_fields',
  S3_KEY = 's3_key',
  URL = 'url',
}

export enum DOC_UPLOAD_ACTION {
  UPLOAD = 'upload',
  RE_UPLOAD = 're-upload',
}

export enum SIGNER_TYPE {
  PRIMARY = 'PRIMARY',
  SPOUSE = 'SPOUSE',
}

export enum ESIGN_FIELD_TYPE {
  SIGNATURE = 'SIGNATURE',
  DATE = 'DATE',
}

export interface StateReturnType {
  state: string;
  value: string;
}

export interface ReviewOcrValues {
  agi: number;
  standard_deduction: number;
  taxable_income: number;
  self_employment_tax: number;
  income_tax: number;
  income_tax_witheld: number;
  tax_due_amount: number;
}

interface BackendReviewAndEsignResponseForm {
  doc_type: ReviewEsignDocumentType;
  id: number;
  links: Array<BackendReviewAndEsignResponseFormDoc>;
  federal_return_summary: any;
  states_return_summary: any;
  status: string;
  year: number;
}

export interface BackendReviewAndEsignResponse {
  returns_esign_forms: Array<BackendReviewAndEsignResponseForm>;
}

export interface CpaReviewMessage {
  id: number;
  created_at: string;
  comment: string;
  author_name: string;
  type: CPA_REVIEW_MESSAGE_TYPE;
}

export interface CpaReviewThread {
  id: number;
  created_at: string;
  comment: string;
  author_name: string;
  resolved_by_name: string | null;
  type: ReviewEsignDocumentType;
  status: CPA_REVIEW_THREAD_STATUS;
  last_message_at: string;
  messages: CpaReviewMessage[];
  unread_messages: number[];
}

export const getFormData = (file: any, fields: {[key: string]: any}) => {
  const fData = new FormData();
  const keys = Object.keys(fields);
  keys.forEach((key) => {
    fData.append(key, fields[key]);
  });
  fData.append('file', file);
  return fData;
};

export interface EsignFields {
  x: number;
  y: number;
  width: number;
  height: number;
  applicable: boolean;
  page_number: number;
  signer_type: SIGNER_TYPE;
  field_type: ESIGN_FIELD_TYPE;
}

interface BackendReviewAndEsignResponseFormDoc {
  id: number;
  doc_link: string;
  extras: {
    [ReviewDocStateKeys.JURISDICTION]: string;
    [ReviewDocStateKeys.SIGNNOW_DOC_ID]?: string;
  };
  final_data: {
    esign_fields: EsignFields[] | null;
    federal_return_summary: ReviewOcrValues | {};
    states_return_summary: {[state: string]: ReviewOcrValues};
  };
  filename: string;
  status: string;
  resolved_queries: number;
  total_queries: number;
  unresolved_queries: number;
  user_query_thread: Array<BackendQuery>;
  read_url: string;
}

export const backendReviewAndEsignResponseMapper = (
  response: BackendReviewAndEsignResponse,
) => {
  const formatedDocs: Array<ReviewAndEsignDocument> = [];
  const queryList: Array<ReviewAndEsignQuery> = [];
  response.returns_esign_forms.forEach(({links, doc_type}) => {
    links.forEach(
      ({
        id,
        filename,
        extras,
        final_data,
        user_query_thread,
        read_url,
        status: doc_status,
      }) => {
        const queries: Array<ReviewAndEsignQuery> = user_query_thread.map(
          ({
            id: queryId,
            status,
            created_at,
            query,
            sub_type,
            conversation,
          }) => {
            const messages: Array<ReviewAndEsignMessage> = conversation.map(
              ({created_at, author_type, message, doc_type}) => ({
                timestamp: new Date(created_at).getTime() / 1000,
                fromUs: AuthorType.CPA === author_type,
                text: message,
                documentType: doc_type,
              }),
            );
            const fromUser = !messages[messages.length - 1].fromUs;
            return {
              queryId,
              status,
              timestamp: new Date(created_at).getTime() / 1000,
              title: query,
              messages,
              resolved: status === QUERY_STATUS.RESOLVED,
              fromUser,
            };
          },
        );

        queryList.push(...queries);

        formatedDocs.push({
          documentURI: read_url,
          documentId: id,
          filename,
          reviewDone: doc_status === 'ACCEPTED',
          documentType: doc_type,
          extras,
          esignFields: final_data?.esign_fields ?? [],
          federal: final_data?.federal_return_summary ?? {},
          state: final_data?.states_return_summary ?? {},
        });
      },
    );
  });
  const documentActionCount = queryList
    .map((query) => (query.fromUser && !query.resolved ? 1 : (0 as number)))
    .reduce((prevVal, newVal) => prevVal + newVal, 0);

  return formatedDocs.map((doc) => {
    if (doc.documentType === ReviewEsignDocumentType.TAX_RETURNS) {
      return {
        ...doc,
        documentActionCount,
        queries: queryList,
      };
    }
    return doc;
  });
};

export const aggregateReviewEsignActionCount = (
  documents: ReviewAndEsignDocument[],
) => {
  return documents
    .map(({documentActionCount}) => documentActionCount)
    .reduce((prev, next) => prev + next, 0);
};

export const aggregateDocumentUnreadThreads = (threads: CpaReviewThread[]) => {
  return threads
    .map(({unread_messages}) => {
      return unread_messages.length === 0 ? 0 : 1;
    })
    .reduce((prev, next) => prev + next, 0);
};
