import _ from 'lodash';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import QueryPreview from 'src/CpaCenterV2/common/QueryPreview/QueryPreview';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import {QueryStatus, ReduxQueryType} from 'src/store/queries/queries.reducer';
import {
  selectLastMessage,
  selectQueriesReducer,
} from 'src/store/queries/queries.selector';

interface QueriesProps {
  queries: ReduxQueryType[];
  onClick: (queryId: number) => void;
}

const getSortedQueries = (queries: ReduxQueryType[] = []) => {
  const queryStatusOrder = [
    QueryStatus.DRAFT,
    QueryStatus.OPEN,
    QueryStatus.USER_RESOLVED,
    QueryStatus.SENT,
    QueryStatus.CPA_RESOLVED,
    QueryStatus.RESOLVED,
  ];
  const compareToQueries = (q1: ReduxQueryType, q2: ReduxQueryType) => {
    const i1 = queryStatusOrder.indexOf(q1.queryStatus);
    const i2 = queryStatusOrder.indexOf(q2.queryStatus);
    if (i1 === i2) {
      const l1 = selectLastMessage(q1);
      const l2 = selectLastMessage(q2);
      return l2.messageCreatedAt.getTime() - l1.messageCreatedAt.getTime();
    }
    return i1 - i2;
  };
  const sorted = [...queries];
  return sorted.sort(compareToQueries);
};

const Queries = ({queries, onClick}: QueriesProps) => {
  const [frozenQueriesId, setFrozenQueriesId] = useState<number[]>(() =>
    getSortedQueries(queries).map((q) => q.queryId),
  );

  const {queryMap} = useSelector(selectQueriesReducer);

  const {queryId} = useCurrentQueryId();
  const selectedQueryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const allQueriesId = queries.map((q) => q.queryId);
    const newQueriesId = _.difference(allQueriesId, frozenQueriesId);
    const deletedQueriesId = _.difference(frozenQueriesId, allQueriesId);

    const finalList = [
      ...newQueriesId,
      ...frozenQueriesId.filter((q) => !deletedQueriesId.includes(q)),
    ];

    if (newQueriesId.length > 0 || deletedQueriesId.length > 0) {
      setFrozenQueriesId(finalList);
    }
  }, [queries, frozenQueriesId]);

  const sortedQueries = useMemo(() => {
    return frozenQueriesId.map((id) => queryMap[id]);
  }, [frozenQueriesId, queryMap]);

  useEffect(() => {
    if (selectedQueryRef.current) {
      selectedQueryRef.current?.scrollIntoView?.({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, [queryId]);
  return (
    <div
      style={{
        borderRight: `solid 1px ${themmeColor.offWhite}`,
        height: '100%',
      }}>
      {sortedQueries.map((query, index) => {
        if (!query) {
          return null;
        }
        const isFirst = index === 0;
        const isLast = index === queries.length - 1;
        return (
          <div
            key={query.queryId}
            ref={query.queryId === queryId ? selectedQueryRef : undefined}>
            <QueryPreview
              bypassStatusCheck
              onClick={onClick}
              isDraftEditDeleteAllowed={false}
              backgroundColor={
                query.queryId === queryId
                  ? themmeColor.lightYellow
                  : themmeColor.white
              }
              query={query}
              noBR
              rootStyle={{
                borderBottom: isLast
                  ? `solid 1px ${themmeColor.offWhite}`
                  : undefined,
                borderTop: !isFirst
                  ? `solid 1px ${themmeColor.offWhite}`
                  : undefined,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Queries;
