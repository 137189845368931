import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import WarningIcon from 'src/icons/WarningIcon';
import {setHighValueDeductionsDrawer} from 'src/store/app/app.actions';
import {selectHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.selector';

const HighValueDeductionNudge = () => {
  const {deductions: highValueDeductions} = useSelector(
    selectHighValueDeductions,
  );
  const dispatch = useDispatch();

  if (highValueDeductions.length === 0) {
    return null;
  }

  return (
    <div>
      <Box style={{padding: 20, marginInline: 16, alignItems: 'center'}}>
        <div style={{marginRight: 12}}>
          <WarningIcon />
        </div>
        <Typography>
          <span style={{fontWeight: 600}}>Deduction with high value</span>:
          Some of the deduction value seems to be higher than normal. You can
          review and raise a query with the user to understand whether they
          have done any mistakes!
        </Typography>
        <DSButton
          type="secondary"
          text="Review"
          style={{marginLeft: 12}}
          onClick={() => dispatch(setHighValueDeductionsDrawer(true))}
        />
      </Box>
    </div>
  );
};

export default HighValueDeductionNudge;
