import {ReduxStateType} from '../store';
import {AppReducerStates} from './app.reducer';

export const selectAppReducerState = (
  state: ReduxStateType,
  path: AppReducerStates,
) => {
  return state.app[path];
};

export const selectNotificatioStatus = (state: ReduxStateType) => {
  return state.app[AppReducerStates.notifcationStatus];
};

export const selectCreateQueryDrawerStatus = (state: ReduxStateType) => {
  return state.app[AppReducerStates.createQueryDrawerStatus];
};

export const selectTaxFormDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.taxformDrawer];
};

export const selectStateInfoDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.statesInfoDrawer];
};

export const selectHighValueDeductionsStatusDrawer = (
  state: ReduxStateType,
) => {
  return state.app[AppReducerStates.highValueDeductionsStatusDrawer];
};

export const selectReuploadDocumentQueryDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.reuploadDocumentQueryDrawer];
};

export const selectDistributeDeductionsQueryDrawer = (
  state: ReduxStateType,
) => {
  return state.app[AppReducerStates.distributeDeductionsQueryDrawer];
};
export const selectFileExtensionDetailsDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.fileExtensionDetailsDrawer];
};
export const selectMessageAttachmentDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.messageAttachmentDrawer];
};

export const selectIpPinDetailsDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.ipPinDetailsDrawer];
};
export const selectChildCareExpenseDrawer = (state: ReduxStateType) => {
  return state.app[AppReducerStates.childCareExpenseDrawer];
};
