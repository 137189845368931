import {getBusinessBalanceSheetData} from 'src/appApi';
import {SET_BALANCE_SHEET_DATA} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';

const setBalanceSheet = (payload: any) => {
  return {
    type: SET_BALANCE_SHEET_DATA,
    payload,
  };
};

export const fetchBalanceSheet = (fly_user_id: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getBusinessBalanceSheetData(fly_user_id, year);
      dispatch(setBalanceSheet(response.data));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch balance sheet (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
