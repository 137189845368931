import {Typography} from '@mui/material';
import _ from 'lodash';
import React, {ReactNode} from 'react';
import {themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';

interface TransactionListProps {
  title: string;
  groupingKey: string;
  expenses: Transaction[];
  getSectionHeaderData: (props: {
    groupName: string;
    expenses: Transaction[];
  }) => any;
  renderSectionHeaderData: ({data}: {data: any}) => ReactNode;
  renderExpense: (props: {
    txn: Transaction;
    groupTxns: Transaction[];
    groupName: string;
  }) => ReactNode;
}

const TransactionList = ({
  title,
  groupingKey,
  expenses,
  getSectionHeaderData,
  renderSectionHeaderData,
  renderExpense,
}: TransactionListProps) => {
  const groups = _.groupBy(expenses, groupingKey);
  return (
    <div>
      <div
        style={{
          paddingBlock: 8,
          paddingInline: 16,
          backgroundColor: themmeColor.black,
          alignItems: 'cemter',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Typography
          style={{
            textTransform: 'uppercase',
            fontSize: 11,
            fontWeight: 600,
            color: themmeColor.white,
          }}>
          {title}
        </Typography>
      </div>
      {Object.entries(groups).map(([groupName, txns]) => {
        const sectionHeaderData = getSectionHeaderData({
          groupName,
          expenses: txns,
        });
        return (
          <div>
            <div>{renderSectionHeaderData({data: sectionHeaderData})}</div>
            <div>
              {txns.map((txn) => {
                return renderExpense({txn, groupName, groupTxns: txns});
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransactionList;
