import {TextField, Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  ANSWER_TYPE,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';

export interface GenerateTaxProfileFieldProps {
  question_id: TAX_PROFILE_FIELDS;
  answer_type: ANSWER_TYPE;
  tax_profile_fields_key_name: string;
  display_name?: string;
  answered: boolean;
  answer: any;
}

const GetTextField = ({label, value}: {label?: string; value: string}) => {
  return <TextField label={label} value={value} disabled={true} />;
};

const GetLabelAndField = ({
  label,
  value,
  notAnswered,
}: {
  label?: string;
  value: string;
  notAnswered: boolean;
}) => {
    // console.log({label,
    //     value,
    //     notAnswered,})
  return (
    <div style={{flexDirection: 'column'}}>
      <Typography
        textTransform={'capitalize'}
        fontWeight={'600'}
        fontSize={13}>
        {label}
      </Typography>
      <div
        style={{
          backgroundColor: themmeColor.backgroundOffWhite,
          padding: '7px 16px',
          borderRadius: 8,
        }}>
        <Typography>{notAnswered ? '-' : value}</Typography>
      </div>
    </div>
  );
};

const GenerateTaxProfileField: React.FC<GenerateTaxProfileFieldProps> = ({
  answer_type,
  display_name,
  answer,
  tax_profile_fields_key_name,
  answered,
}) => {
  if (!answered) return null;
  if (answer_type === ANSWER_TYPE.STRING) {
    return (
      <div style={{display: 'inline-flex', margin: 10}}>
        {/* <GetTextField label={display_name} value={answer} /> */}
        <GetLabelAndField
          label={`${tax_profile_fields_key_name
            .toLowerCase()
            .replaceAll('_', ' ')}`}
          value={answer}
          notAnswered={!answered}
        />
      </div>
    );
  }
  if (answer_type === ANSWER_TYPE.INTEGER) {
    return (
      <div style={{display: 'inline-flex', margin: 10}}>
        {/* <GetTextField label={display_name} value={answer} /> */}
        <GetLabelAndField
          label={`${tax_profile_fields_key_name
            .toLowerCase()
            .replaceAll('_', ' ')}`}
          value={answer}
          notAnswered={!answered}
        />
      </div>
    );
  }

  if (answer_type === ANSWER_TYPE.BOOLEAN) {
    return (
      <div style={{display: 'inline-flex', margin: 10}}>
        {/* <GetTextField label={display_name} value={answer} /> */}
        <GetLabelAndField
          label={`${tax_profile_fields_key_name
            .toLowerCase()
            .replaceAll('_', ' ')}`}
          value={`${answer}`}
          notAnswered={!answered}
        />
      </div>
    );
  }
  if (answer_type === ANSWER_TYPE.OBJECT_KEY_VALUE) {
    return (
        <div style={{flexDirection: 'row'}}>
          {Object.keys(answer).map((key) => (
            <div style={{display: 'inline-flex', margin: 10}}>
              {/* <GetTextField label={display_name} value={answer} /> */}
              <GetLabelAndField
                label={`${key.toLowerCase().replaceAll('_', ' ')}`}
                value={`${answer[key]}`}
                notAnswered={!answered}
              />
            </div>
          ))}
        </div>
    );
  }

  if (answer_type === ANSWER_TYPE.NESTED_OBJECT_KEY_VALUE) {
    return (
      <div
        style={{

          borderSpacing: 10,
          borderWidth: 2,
          borderColor: 'pink',
          borderRadius: 12,
          display: 'inline-flex',
          flexDirection: 'column',
          margin: 4,
        }}>
        <Typography
          textTransform={'capitalize'}
          fontSize={13}
          fontWeight={'600'}
          style={{marginLeft: 6, marginTop: 2}}>
          {tax_profile_fields_key_name.replaceAll('_', ' ').toLowerCase()}
        </Typography>
        {Object.keys(answer).map((state: any) => (
          <div>
            <Typography
              style={{marginLeft: 10, marginTop: 4}}
              fontSize={13}
              fontWeight={'600'}>
              {state}
            </Typography>
            <div style={{flexDirection: 'row'}}>
              {Object.keys(answer[state]).map((key) => (
                <div style={{display: 'inline-flex', margin: '2px 10px 10px'}}>
                  {/* <GetTextField label={display_name} value={answer} /> */}
                  <GetLabelAndField
                    label={`${key.toLowerCase().replaceAll('_', ' ')}`}
                    value={`${answer[state][key]}`}
                    notAnswered={!answered}
                  />
                </div>
              ))}
              <br />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (answer_type === ANSWER_TYPE.LIST_OBJECT_KEY_VALUE) {
    return (
      <div
        style={{

          borderSpacing: 10,
          borderWidth: 2,
          borderColor: 'green',
          borderRadius: 12,
          display: 'inline-flex',
          flexDirection: 'column',
          margin: 4,
        }}>
        <Typography
          textTransform={'capitalize'}
          fontSize={13}
          fontWeight={'600'}
          style={{marginLeft: 6, marginTop: 2}}>
          {tax_profile_fields_key_name.replaceAll('_', ' ').toLowerCase()}
        </Typography>
        {answer.map((object: any) => (
          <div style={{flexDirection: 'row'}}>
            {Object.keys(object).map((key) => (
              <div style={{display: 'inline-flex', margin: 10}}>
                {/* <GetTextField label={display_name} value={answer} /> */}
                <GetLabelAndField
                  label={`${key.toLowerCase().replaceAll('_', ' ')}`}
                  value={`${object[key]}`}
                  notAnswered={!answered}
                />
              </div>
            ))}
            <br />
          </div>
        ))}
      </div>
    );
  }

  if (answer_type === ANSWER_TYPE.LIST) {
    return (
      <div
        style={{

          borderSpacing: 10,
          borderWidth: 2,
          borderColor: 'blue',
          borderRadius: 12,
          display: 'inline-flex',
          flexDirection: 'column',
          margin: 4,
        }}>
        <Typography
          textTransform={'capitalize'}
          fontSize={13}
          fontWeight={'600'}
          style={{
            marginLeft: 6,
            marginTop: 2,
          }}>
          {tax_profile_fields_key_name.replaceAll('_', ' ').toLowerCase()}
        </Typography>
        <div style={{flexDirection: 'row'}}>
          {answer.map((value: string, index: number) => (
            <div style={{display: 'inline-flex', margin: 10}}>
              {/* <GetTextField label={display_name} value={answer} /> */}
              <GetLabelAndField
                // label={`${index}`}
                value={`${value}`}
                notAnswered={!answered}
              />
            </div>
          ))}
          {answer.length === 0 && (
            <Typography style={{marginLeft: 4}} fontSize={13}>Empty</Typography>
          )}
        </div>
      </div>
    );
  }
  if (answer_type === ANSWER_TYPE.OBJECT_KEY_VALUE_LIST) {
    return (
      <div
        style={{

          borderSpacing: 10,
          borderWidth: 2,
          borderColor: 'black',
          borderRadius: 12,
          display: 'inline-flex',
          flexDirection: 'column',
          margin: 4,
        }}>
        <Typography
          textTransform={'capitalize'}
          fontSize={13}
          fontWeight={'600'}
          style={{marginLeft: 6, marginTop: 2}}>
          {tax_profile_fields_key_name.replaceAll('_', ' ').toLowerCase()}
        </Typography>
        <div style={{flexDirection: 'row'}}>
          {Object.keys(answer).map((key) =>
            Array.isArray(answer[key]) ? (
              <div
                style={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                }}>
                <Typography fontSize={13} fontWeight={'600'}>
                  {key}
                </Typography>
                {answer[key].map((value: string, index: number) => (
                  <div
                    style={{
                      display: 'inline-flex',
                      flexDirection: 'row',
                      margin: 10,
                    }}>
                    {/* <GetTextField label={display_name} value={answer} /> */}
                    <GetLabelAndField
                      //   label={`${index}`}
                      value={`${value}`}
                      notAnswered={!answered}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div style={{display: 'inline-flex', margin: 10}}>
                {/* <GetTextField label={display_name} value={answer} /> */}
                <GetLabelAndField
                  label={`${key.toLowerCase().replaceAll('_', ' ')}`}
                  value={`${answer[key]}`}
                  notAnswered={!answered}
                />
              </div>
            ),
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default GenerateTaxProfileField;
