import React from 'react';
import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import Tag from 'src/DesignSystem/Tag/Tag';

interface DependentCardProps {
  index: number;
  data: any;
  fields: any;
}

const DependentCard = ({index, data, fields}: DependentCardProps) => {

  return (
    <Accordion style={{backgroundColor: themmeColor.cpaCenterV2Bg, margin: 0, marginBottom: 20,}}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Tag
          textColor={themmeColor.white}
          backgroundColor={themmeColor.grey}
          text={`Dependent ${index + 1}`}
        />
      </AccordionSummary>
      <AccordionDetails>
        <MultiKeyValueField data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};

export default DependentCard;
