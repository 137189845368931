import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getGoogleSheetData} from 'src/appApi';
import {SPREAD_SHEET_ID} from '../constant';

export default function ViewOnlyList() {
  const {job_id} = useParams();
  const [data, setData] = useState<any[][]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (job_id) {
      getGoogleSheetData({
        sheet_id: SPREAD_SHEET_ID,
        sheet_name: job_id,
      })
        .then((res) => {
          const responseData = res.data;
          if (responseData) {
            // remove first row to avoid headings of sheet
            setData(responseData.slice(1));
          }
        })
        .catch((err) => {
          console.log({err});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [job_id]);

  const onClickView = (id: number) => {
    navigate(`/annotation/view/task/${job_id}/${id}`);
  };

  if (loading) {
    return (
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <h1>Annotations</h1>
      <table style={{width: '100%'}}>
        <thead>
          <tr style={{backgroundColor: 'grey', color: 'white'}}>
            <th>Id</th>
            <th>Title</th>
            <th>PDF Name</th>
            <th>Is completed</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((d) => (
              <tr style={{border: '1px solid black'}} key={d[0]}>
                <td style={{textAlign: 'center'}}>{d[0]}</td>
                <td style={{textAlign: 'center'}}>{d[1]}</td>
                <td style={{textAlign: 'center'}}>{d[2]}</td>
                <td style={{textAlign: 'center'}}>
                  {d[4] ? 'Completed' : 'NA'}
                </td>
                <td style={{textAlign: 'center'}}>
                  <button
                    onClick={() => onClickView(d[0])}
                    style={{
                      outline: 'none',
                      color: 'black',
                      borderRadius: '6px',
                      backgroundColor: 'rgb(74 255 224)',
                      border: 0,
                      padding: '8px 24px',
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}>
                    View
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
