import {Modal, Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {FileUploader} from 'react-drag-drop-files';
import {
  AttachFileOutlined,
  Clear,
  CloudUploadOutlined,
} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  modal: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
  mainContainer: {
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '40%',
    height: '250px',
  },
  container: {
    backgroundColor: themmeColor.white,
    border: `1px solid ${themmeColor.darkBlue}`,
    borderRadius: 8,
    height: '170px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  topText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  divider: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '10px',
  },
  bottomText: {
    backgroundColor: themmeColor.darkBlue,
    padding: '9px 50px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 8,
  },
});

interface CpaReviewDocUploadProps {
  onClose: () => void;
  isOpen: boolean;
  handleReUpload: (file: any) => void;
  loading: boolean;
}

const CpaReviewDocUploadOverlay = ({
  onClose,
  isOpen,
  handleReUpload,
  loading,
}: CpaReviewDocUploadProps) => {
  const styles = useStyles({});
  const fileType = ['pdf'];

  return (
    <Modal className={styles.modal} onClose={onClose} open={isOpen}>
      <div className={styles.mainContainer}>
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div>
            <Typography fontSize={20} fontWeight="600">
              Re-upload document
            </Typography>
            <Typography
              style={{marginBottom: 8}}
              color={themmeColor.grey}
              fontSize={14}>
              Make sure the uploaded document is correct.
            </Typography>
          </div>
          <div onClick={onClose}>
            <Clear />
          </div>
        </div>
        <FileUploader
          multiple={false}
          dropMessageStyle={{backgroundColor: '#00000000'}}
          hoverTitle={
            <h2 color={themmeColor.white}>Drop to upload the file</h2>
          }
          types={fileType}
          handleChange={handleReUpload}>
          <div className={styles.container}>
            <div className={styles.topText}>
              <CloudUploadOutlined style={{marginRight: 11}} />
              <Typography>Drag and Drop the document to Upload</Typography>
            </div>
            <div className={styles.divider}>
              <Typography fontWeight={'500'}>OR</Typography>
            </div>
            <div className={styles.bottomText}>
              <AttachFileOutlined
                style={{marginRight: 11, color: themmeColor.white}}
              />
              <Typography color={themmeColor.white}>Upload from PC</Typography>
            </div>
          </div>
        </FileUploader>
      </div>
    </Modal>
  );
};

export default CpaReviewDocUploadOverlay;
