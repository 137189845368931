import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessBalanceSheet from './SingleBusinessBalanceSheet';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {fetchBalanceSheet} from 'src/store/balanceSheet/balanceSheet.actions';
import {selectBalanceSheetReducer} from 'src/store/balanceSheet/balanceSheet.selector';

const BusinessBalanceSheet = () => {
  const {businesses} = useSelector(selectBusinessDetails);
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {balance_sheet_data} = useSelector(selectBalanceSheetReducer);

  useEffect(() => {
    dispatch(fetchBalanceSheet(userId, activeYear));
  }, [userId, activeYear]);

  return (
    <SubSectionBox
      name="Business balance sheets"
      taxprofileSubsection={TaxProfileSubsection.BusinessBalanceSheet}>
      {balance_sheet_data.map((balanceSheet, index) => (
        <SingleBusinessBalanceSheet
          key={balanceSheet.business_id}
          balanceSheet={balanceSheet}
          index={index}
        />
      ))}
    </SubSectionBox>
  );
};

export default BusinessBalanceSheet;
