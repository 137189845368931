import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {
  BOI_STATUS,
  SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER,
} from './types';
import {BOI_REQUIRED_ANSWERS} from './constants';

export const BOI_FILING_VALID_STEPS_SEQUENCE = [
  BOI_STATUS.FLOW_INITIATED,
  BOI_STATUS.BOI_NOT_REQUIRED,
  BOI_STATUS.DOCS_SUBMITTED,
  BOI_STATUS.DETAILS_SUBMITTED,
  BOI_STATUS.PREP_STARTED,
  BOI_STATUS.REPORT_SENT_TO_USER,
  BOI_STATUS.USER_REVIEW_COMPLETE,
  BOI_STATUS.BOI_FILED,
  BOI_STATUS.REJECTED_BY_FINCEN,
  BOI_STATUS.APPROVED_BY_FINCEN,
];

export const isBoiStepComplete = (
  currentStep: BOI_STATUS,
  targetStep: BOI_STATUS,
) => {
  const currentStepIndex = BOI_FILING_VALID_STEPS_SEQUENCE.findIndex(
    (s) => s === currentStep,
  );
  const targetStepIndex = BOI_FILING_VALID_STEPS_SEQUENCE.findIndex(
    (s) => s === targetStep,
  );
  return targetStepIndex < currentStepIndex;
};

export const isEligibleForBoi = (screeningQuestions: {[key: number]: any}) => {
  if (
    _.get(screeningQuestions, TAX_PROFILE_FIELDS.IS_BOI_REQUIRED, null) ===
    BOI_REQUIRED_ANSWERS.YES
  ) {
    return true;
  }

  if (
    _.get(screeningQuestions, TAX_PROFILE_FIELDS.IS_BOI_REQUIRED, null) ===
    BOI_REQUIRED_ANSWERS.UNSURE
  ) {
    if (
      _.get(
        screeningQuestions,
        TAX_PROFILE_FIELDS.BUSINESS_FORMED_OR_REGISTERED_IN_USA,
        null,
      ) === false
    ) {
      return false;
    }
    if (
      _.get(
        screeningQuestions,
        TAX_PROFILE_FIELDS.BUSINESS_FORMED_OR_REGISTERED_IN_USA,
        null,
      ) === true &&
      _.get(
        screeningQuestions,
        TAX_PROFILE_FIELDS.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024,
        null,
      ) === false
    ) {
      return false;
    }

    if (
      _.get(
        screeningQuestions,
        TAX_PROFILE_FIELDS.BUSINESS_FORMED_OR_REGISTERED_IN_USA,
        null,
      ) === true &&
      _.get(
        screeningQuestions,
        TAX_PROFILE_FIELDS.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024,
        null,
      ) === true
    ) {
      if (
        screeningQuestions[TAX_PROFILE_FIELDS.BUSINESS_LLC_OR_C_CORP] ===
          false &&
        screeningQuestions[
          TAX_PROFILE_FIELDS.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY
        ] === false
      ) {
        return false;
      }

      if (
        _.get(
          screeningQuestions,
          TAX_PROFILE_FIELDS.BUSINESS_LLC_OR_C_CORP,
          null,
        ) === true ||
        (_.get(
          screeningQuestions,
          TAX_PROFILE_FIELDS.BUSINESS_LLC_OR_C_CORP,
          null,
        ) === false &&
          _.get(
            screeningQuestions,
            TAX_PROFILE_FIELDS.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY,
            null,
          ) === true)
      ) {
        if (
          screeningQuestions[
            TAX_PROFILE_FIELDS.SELECT_POINTS_APPLICABLE_FOR_BUSINESS
          ].length ===
          Object.values(SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER)
            .length
        ) {
          return false;
        }
        if (
          screeningQuestions[
            TAX_PROFILE_FIELDS.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES
          ] === false
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  }

  return false;
};
