// const AUTH_HOST = process.env.REACT_APP_REACT_APP_AUTH_HOST
import {LocalStorageKey} from './constants/constants';

export const getCurrentUserId = () => {
  return JSON.parse(localStorage.getItem('id'));
};

const googleAuthProvider = (apiUrl) => ({
  login: (response) => {
    // console.log(response)
    // return Promise.resolve()
    const request = new Request(`${apiUrl}/users/`, {
      method: 'POST',
      body: JSON.stringify({
        provider: 'GMAIL',
        id_token: response.getAuthResponse().id_token,
        is_data_policy_accepted: true,
      }),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          // Promise.reject
          console.log(response);
          throw new Error(response);
        }
        return response.json();
      })
      .then(({token, id, email, username, first_name, last_name}) => {
        localStorage.setItem(LocalStorageKey.Token, token);
        localStorage.setItem(LocalStorageKey.UserId, id);
        localStorage.setItem(LocalStorageKey.UserName, username);
        localStorage.setItem(LocalStorageKey.UserEmail, email);
        localStorage.setItem(LocalStorageKey.UserFirstName, first_name);
        localStorage.setItem(LocalStorageKey.UserLastName, last_name);
      });
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem(LocalStorageKey.Token)
      ? Promise.resolve()
      : Promise.reject(),
  // ...
  getPermissions: () => Promise.resolve(),
});

export default googleAuthProvider;
