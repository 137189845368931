import {useNavigate} from 'react-router-dom';
import {TaxProfileSection} from 'src/constants/constants';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {CpaCenterV2Route, RouteParts} from 'src/constants/routeName';
import useCurrentUserId from './useCurrentUserId';
import {USER_QUERIES_URL_SEARCH_PARAMS} from '../UserQueries/UserQueries.types';

const useCpaCenterV2Navigation = () => {
  const navigate = useNavigate();
  const {userId} = useCurrentUserId();

  const navigateToDocumentReview = () => {
    const to = CpaCenterV2Route.CpaCenterV2DocumentReview.replace(
      RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToDocumentViewer = (docId: number) => {
    const to = CpaCenterV2Route.CpaCenterV2DocumentViewer.replace(
      RouteParts.UserId,
      `${userId}`,
    ).replace(RouteParts.DocumentId, `${docId}`);
    navigate(to);
  };

  const navigateToTaxProfileReview = () => {
    const to = CpaCenterV2Route.CpaCenterV2TaxProfileReview.replace(
      RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToTaxProfileSection = (section: TaxProfileSection) => {
    const to =
      CpaCenterV2Route.CpaCenterV2TaxProfileReview.replace(
        RouteParts.UserId,
        `${userId}`,
      ) + `/${section}`;
    navigate(to);
  };

  const navigateToUserQueries = (
    returnId?: number,
    returnType?: TaxReturnType,
  ) => {
    const filterParams =
      returnId != null && returnType != null
        ? `?${USER_QUERIES_URL_SEARCH_PARAMS.RETURN_ID}=${returnId}&${USER_QUERIES_URL_SEARCH_PARAMS.RETURN_TYPE}=${returnType}`
        : '';
    const to =
      CpaCenterV2Route.CpaCenterV2UserQueries.replace(
        RouteParts.UserId,
        `${userId}`,
      ) + filterParams;
    navigate(to);
  };

  const navigateToMessagesView = (
    queryId: number,
    returnId?: number,
    returnType?: TaxReturnType,
  ) => {
    const filterParams =
      returnId != null && returnType != null
        ? `?${USER_QUERIES_URL_SEARCH_PARAMS.RETURN_ID}=${returnId}&${USER_QUERIES_URL_SEARCH_PARAMS.RETURN_TYPE}=${returnType}`
        : '';
    const to =
      CpaCenterV2Route.CpaCenterV2UserQueriesMessageView.replace(
        RouteParts.UserId,
        `${userId}`,
      ).replace(RouteParts.QueryId, `${queryId}`) + filterParams;
    navigate(to);
  };

  const navigateToReviewEsign = () => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsign.replace(
      RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToSendToCCH = () => {
    const to = CpaCenterV2Route.CpaCenterV2CCH.replace(
      RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToTaxReturnDetailedView = (
    returnId: number,
    returnType: TaxReturnType,
    step: TaxReturnStatus,
  ) => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsignReturnStep.replace(
      RouteParts.UserId,
      `${userId}`,
    )
      .replace(RouteParts.ReturnId, `${returnId}-${returnType}`)
      .replace(RouteParts.TaxReturnSection, step);
    navigate(to);
  };

  const navigateToReviewEsignQuery = (
    returnId: number,
    returnType: TaxReturnType,
    queryId: number,
  ) => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsignQuery.replace(
      RouteParts.UserId,
      `${userId}`,
    )
      .replace(RouteParts.ReturnId, `${returnId}-${returnType}`)
      .replace(RouteParts.TaxReturnSection, TaxReturnStatus.REVIEW_ESIGN)
      .replace(RouteParts.QueryId, `${queryId}`);
    navigate(to);
  };

  const navigateToBrowserBack = () => {
    navigate(-1);
  };

  const navigateToDistributeDeductions = () => {
    const to = CpaCenterV2Route.CpaCenterV2DistributeDeductions.replace(
      RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  return {
    navigateToDocumentReview,
    navigateToDocumentViewer,
    navigateToTaxProfileReview,
    navigateToTaxProfileSection,
    navigateToUserQueries,
    navigateToMessagesView,
    navigateToTaxReturnDetailedView,
    navigateToReviewEsign,
    navigateToReviewEsignQuery,
    navigateToBrowserBack,
    navigateToSendToCCH,
    navigateToDistributeDeductions,
  };
};

export default useCpaCenterV2Navigation;
