import {AuthorType, CPA_CALL_STATUS, CPAQuestionMessageType, QUERY_STATUS} from 'src/constants/constants';
import {Message, Query} from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/TaxQueriesContext';
import { BackendQuery } from 'src/types/query.types';

interface TaxQueriesBackendConversation {
  id: number;
  message: string;
  created_at: Date;
  author__name: string;
  author_type: string;
  type: CPAQuestionMessageType;
  doc_id: number;
  doc_type_id: number;
  filename: string;
  doc_type: string;
}

export const taxQueriesBackendResponseMapper = (
  queries: Array<BackendQuery>,
) => {

  return queries.map((query) => {
    const {
      id: query_id,
      query: title,
      type,
      sub_type,
      status: query_status,
    } = query;
    const query_type = `${type} - ${sub_type}`;
    const resolved = query_status === QUERY_STATUS.RESOLVED;
    const messages: Array<Message> = query.conversation.map((item) => ({
      text: item.message,
      fromUs: item.author_type === AuthorType.CPA,
      timeStamp: new Date(item.created_at).getTime() / 1000,
      tagged_documents: {
        name: item.filename,
        document_type_id: item.doc_type_id,
        document_id: item.doc_id,
        doc_type: item.doc_type,
        document_exists: Number.isInteger(item.doc_id),
        document_type_exists: Number.isInteger(item.doc_type_id),
      },
    }));
    const actionRequired = !messages[messages.length - 1].fromUs && !resolved;
    return {
      query_id,
      title,
      resolved,
      query_type,
      query_status,
      messages,
      actionRequired,
      call_status: query.call_status,
      start_time: query.start_time,
      end_time: query.end_time,
    };
  });
};

export const aggregateTaxQueriesActionCount = (queriesData: Array<Query>) => {
  return queriesData
    .map((query) => (query.actionRequired ? 1 : 0))
    .reduce((n1: number, n2: number) => n1 + n2, 0);
};
