import React from 'react';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import SectionHeader from '../common/SectionHeader';
import StatesTaxFilingInformation from './components/StatesTaxFilingInformation';

const StateTaxes = () => {
  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_SECTION_HEADER_HEIGHT} =
    useLayout();
  return (
    <div>
      <div style={{display: 'flex'}}>
        <div>
          <SectionHeader
            sectionName="States"
            section={TaxProfileSection.StateTaxes}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '75vw',
              overflowY: 'auto',
              height:
                spaceLeftBelowTaxFilingSteps -
                TAX_PROFILE_SECTION_HEADER_HEIGHT,
            }}>
            <StatesTaxFilingInformation />
          </div>
        </div>
        <div style={{overflowY: 'auto', width: '24vw', paddingRight: 10}}>
          <ActionRequired
            dontAllowAdd
            types={[CreateQueryDrawerTab.TAX_PROFILE]}
            taxProfileSection={TaxProfileSection.StateTaxes}
          />
        </div>
      </div>
    </div>
  );
};

export default StateTaxes;
