import {Typography} from '@mui/material';
import React, {useState} from 'react';
import UpdateCCHClientIdOverlay from 'src/CpaCenterList/components/UpdateCCHClientIdCell/UpdateCCHClientIdOverlay';
import {themmeColor} from 'src/constants/constants';

interface UpdateCCHClientIdCellProps {
  currentCCHClientId: string;
  flyUserId: string;
  onSuccess: () => void | Promise<void>;
}

const UpdateCCHClientIdCell = ({
  currentCCHClientId,
  flyUserId,
  onSuccess,
}: UpdateCCHClientIdCellProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <div
        onClick={openModal}
        style={{
          backgroundColor: themmeColor.offWhite,
          borderRadius: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography
          color={themmeColor.black}
          textOverflow="ellipsis"
          fontSize={13}>
          {currentCCHClientId}
        </Typography>
      </div>
      {isOpen && (
        <UpdateCCHClientIdOverlay
          isVisible={isOpen}
          onClose={closeModal}
          currentCCHClientId={currentCCHClientId}
          flyUserId={flyUserId}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default UpdateCCHClientIdCell;
