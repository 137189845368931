import React, {useContext} from 'react';
import MissingExpenseContext from '../MissingExpenses.context';
import SingleBankInfoCard from './SingleBankInfoCard';

const UploadMissingBankStatements = () => {
  const {bankInfo} = useContext(MissingExpenseContext);

  return (
    <div style={{height: '80vh', overflowY: 'auto'}}>
      {bankInfo.map((bank) => (
        <SingleBankInfoCard {...bank} />
      ))}
    </div>
  );
};

export default UploadMissingBankStatements;
