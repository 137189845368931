import {ReduxStateType} from '../store';

export const selectActiveYear = (state: ReduxStateType) =>
  state.globals.activeYear;

export const selectTaxonomy = (state: ReduxStateType) =>
  state.globals.taxonomy;

export const selectCPAMessageTemplates = (state: ReduxStateType) =>
  state.globals.cpaMessageTemplates;
