import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import {themmeColor} from 'src/constants/constants';
import {setMessageAttachmentDrawer} from 'src/store/app/app.actions';

interface MessageAttachmentProps {
  filename: string;
  unsigned_link: string;
}

const MessageAttachment = ({
  filename,
  unsigned_link,
}: MessageAttachmentProps) => {
  const dispatch = useDispatch();

  return (
    <Box
      onClick={() => {
        dispatch(
          setMessageAttachmentDrawer({
            unsigned_link,
            isVisible: true,
            filename,
          }),
        );
      }}
      backgroundColor={themmeColor.focusedBlue}
      borderColor={themmeColor.dashboardBlue}
      style={{
        paddingBlock: 4,
        paddingInline: 10,
        borderRadius: 6,
        margin: 6,
      }}>
      <Typography style={{fontSize: 13}}>{filename}</Typography>
    </Box>
  );
};

export default MessageAttachment;
