import {Typography} from '@mui/material';
import React, {useMemo, useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import ManualSignNowIdPopup from './components/ManualSignNowIdPopup';
import Threads from './components/Threads';
import ReviewReturnPlaceholderPopup from './components/ReviewReturnPlaceholderPopup';
import {useDispatch, useSelector} from 'react-redux';
import {selectThreadsForCurrReturn} from 'src/store/threads/threads.selector';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import AssignUserQueriesPopup from './components/AssignUserQueriesPopup';
import {ReduxStateType} from 'src/store/store';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {
  TaxReturn,
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {getFilteredQueries} from 'src/CpaCenterV2/UserQueries/UserQueries.utils';
import {selectQueriesReducer} from 'src/store/queries/queries.selector';
import {ReduxQueryType} from 'src/store/queries/queries.reducer';
import {bulkUpdateQueries} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchQueries} from 'src/store/queries/queries.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const CpaReview = ({url}: {url: string | null}) => {
  const {currentReturn} = useCurrentTaxReturnId();
  const {userId} = useCurrentUserId();
  const {threads} = useSelector((state: ReduxStateType) =>
    selectThreadsForCurrReturn(state, currentReturn),
  );
  const [loading, setLoading] = useState(false);
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const {notify} = useNotify();
  const {activeYear} = useActiveYear();

  const pendintNotesCount = threads.filter(
    (thread) => thread.status === ThreadStatus.OPEN,
  ).length;

  const [isReviewReturnPopup, setIsReviewReturnPopup] = useState(false);
  const [isManualSingNowPopup, setIsManualSignNowPopup] = useState(false);
  const [isAssignQueriesPopup, setIsAssignQueriesPopup] = useState(false);
  const [makeSpouseAsPrimary, setMakeSpouseAsPrimary] = useState(false);

  const {spaceLeftBelowTaxReturnSection, TAX_RETURN_BOTTOM_BAR} = useLayout();
  const {queries} = useSelector(selectQueriesReducer);
  const dispatch = useDispatch();

  const onCloseReviewReturnPopup = () => {
    setIsReviewReturnPopup(false);
  };

  const openReviewReturnPopup = () => {
    setIsReviewReturnPopup(true);
  };

  const relevantQueries = useMemo(
    () => getFilteredQueries(queries),
    [queries],
  );

  const isNotAssignedToAnyReturn = (query: ReduxQueryType) => {
    return (
      query.assigned_return_type === null ||
      query.assigned_return_type === undefined
    );
  };

  const assignPendingQueriesToReturn = async (
    pendingQueries: ReduxQueryType[],
    taxReturn: TaxReturn,
  ) => {
    let body: Parameters<typeof bulkUpdateQueries>['0']['questions'] = [];
    if (taxReturn.return_type === TaxReturnType.INDIVIDUAL) {
      body = pendingQueries.map((query) => ({
        id: query.queryId,
        assigned_return_type: TaxReturnType.INDIVIDUAL,
      }));
    } else {
      body = pendingQueries.map((query) => ({
        id: query.queryId,
        assigned_return_type: TaxReturnType.BUSINESS,
        biz_return_id: taxReturn.return_id,
      }));
    }
    try {
      setLoading(true);
      await bulkUpdateQueries({questions: body});
      await dispatch(fetchQueries({userId, year: activeYear}));
      notify(
        `Assigned all pending (${pendingQueries.length}) queries to return`,
        NotificationType.success,
      );
    } catch (e) {
      notify(`Failed to assign queries to tax return`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const openAssignQueriesToReturn = async () => {
    const returnSentToUserTaxReturn = taxReturns?.find((taxReturn) => {
      return [
        TaxReturnStatus.REVIEW_ESIGN,
        TaxReturnStatus.EFILE_STATUS,
      ].includes(taxReturn.status);
    });
    const pendingQueries = relevantQueries.filter(isNotAssignedToAnyReturn);
    const pendingQueryCount = pendingQueries.length;
    const taxReturnsCount = taxReturns.length;
    if (returnSentToUserTaxReturn || pendingQueryCount === 0) {
      openReviewReturnPopup();
    } else if (taxReturnsCount === 1 && pendingQueryCount > 0) {
      // assign to tax return
      await assignPendingQueriesToReturn(pendingQueries, taxReturns[0]);
      // open review return popup
      openReviewReturnPopup();
    } else {
      setIsAssignQueriesPopup(true);
    }
  };

  const openManualSignNowPopup = () => {
    setIsManualSignNowPopup(true);
    onCloseReviewReturnPopup();
  };
  const closeManualSingnowPopup = () => {
    setIsManualSignNowPopup(false);
  };
  if (!url) {
    return null;
  }

  return (
    <div>
      <div style={{display: 'flex'}}>
        <iframe
          title="Review-esign-return"
          src={url}
          style={{
            width: '70%',
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
          }}
        />
        <div
          style={{
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
            width: '30%',
          }}>
          <Threads />
        </div>
      </div>
      <div
        style={{
          height: TAX_RETURN_BOTTOM_BAR,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{marginLeft: 4, fontWeight: 600}}>
          You can send the uploaded return to the user since there are no
          pending notes!
        </Typography>
        <DSButton
          type="primary"
          text="Prepare for Review & e-sign"
          style={{paddingInline: '7%', paddingBlock: 12}}
          disabled={pendintNotesCount > 0 || loading}
          onClick={openAssignQueriesToReturn}
        />
      </div>
      <ReviewReturnPlaceholderPopup
        key={`review-return-placeholder-popup-${isReviewReturnPopup}`}
        url={url}
        isOpen={isReviewReturnPopup}
        onClose={onCloseReviewReturnPopup}
        onSendManually={openManualSignNowPopup}
        makeSpouseAsPrimary={makeSpouseAsPrimary}
        setMakeSpouseAsPrimary={setMakeSpouseAsPrimary}
      />
      <ManualSignNowIdPopup
        key={`manual-signnow-id-popup-${isManualSingNowPopup}`}
        isOpen={isManualSingNowPopup}
        onClose={closeManualSingnowPopup}
        url={url}
        makeSpouseAsPrimary={makeSpouseAsPrimary}
      />
      <AssignUserQueriesPopup
        key={`assign-queries-popup-${isAssignQueriesPopup}`}
        isOpen={isAssignQueriesPopup}
        onClose={() => setIsAssignQueriesPopup(false)}
        onConfirm={openReviewReturnPopup}
      />
    </div>
  );
};

export default CpaReview;
