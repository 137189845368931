import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import JoditEditor, {IJoditEditorProps, Jodit} from 'jodit-react';

function removeStylesFromHTML(htmlString: string) {
  const attributesToBeRemoved = ['style', 'class', 'data-start', 'data-end'];
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, 'text/html');

  attributesToBeRemoved.forEach((toRemove) => {
    doc
      .querySelectorAll(`[${toRemove}]`)
      .forEach((item) => item.removeAttribute(toRemove));
  });

  // Return the cleaned HTML string
  return doc.body.innerHTML;
}

interface RichTextEditorProps {
  message: string;
  setMessage: (message: string) => void;
  disabled?: boolean;
  placeholder?: string;
  height?: number | string;
}

// https://xdsoft.net/jodit/start.html refer this link for docs

const EDITOR_TOOLBAR_OPTIONS = [
  'ol',
  'ul',
  'link',
  // 'undo',
  // 'redo',
  // 'bold',
  // 'underline',
  // 'italic',
  // 'font',
  // 'fontsize',
  // 'preview',
  // 'brush',
];

const RichTextEditor = ({
  message,
  setMessage,
  disabled = false,
  placeholder = '',
  height = 200,
}: RichTextEditorProps) => {
  const editorRef = useRef(null);

  const config: IJoditEditorProps['config'] = useMemo(() => {
    return {
      buttons: EDITOR_TOOLBAR_OPTIONS,
      toolbarButtonSize: 'small',
      language: 'en',
      enter: 'br',
      disabled,
      showPlaceholder: true,
      placeholder,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      height,
      minHeight: height,
      askBeforePasteHTML: false,
      spellcheck: true,
    };
  }, [disabled, placeholder, height]);

  return (
    <JoditEditor
      ref={editorRef}
      tabIndex={1}
      value={message}
      onChange={(updated) => {
        setMessage(removeStylesFromHTML(updated));
      }}
      config={config}
    />
  );
};

export default RichTextEditor;
