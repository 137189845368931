import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Skeleton from 'src/icons/Skeleton';
import {selectBoiBusiness, selectBoiData} from 'src/store/boi/boi.selectors';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import EligibilityQuestions from './EligibilityQuestions';
import BoiBusiness from './BoiBusiness';
import BeneficialOwnerDetails from './BeneficialOwnerDetails';
import CompletedRibbon from 'src/CpaCenterV2/common/CompletedRibbon/CompletedRibbon';
import {isBoiStepComplete} from '../utils';
import {BOI_FILING_COLUMNS, BOI_SECTION_NAMES, BOI_STATUS} from '../types';
import {themmeColor} from 'src/constants/constants';
import {selectTaxProfileCpaSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {postTaxProfileReviewStatus} from 'src/appApi';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {NotificationType} from 'src/store/app/app.reducer';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useBoiSteps from '../hooks/useBoiSteps';
import {BoiFilingCenterRoutesParts} from 'src/constants/routeName';

const BoiProfileReview = () => {
  const {userId} = useCurrentUserId();
  const {loaded: boiBusinessLoaded} = useSelector(selectBoiBusiness);
  const {loaded: taxProfileLoaded} = useSelector(selectTaxProfile);
  const {loaded: taxFormsLoaded} = useSelector(selectTaxFormsReducer);
  const {taxProfileCpaSideStatus, loaded: taxProfileStatusLoaded} =
    useSelector(selectTaxProfileCpaSideStatus);
  const {data: boiData, loaded: boiDataLoaded} = useSelector(selectBoiData);

  const [isLoading, setIsLoading] = useState(false);

  const isDataLoading =
    !boiBusinessLoaded ||
    !taxProfileLoaded ||
    !taxFormsLoaded ||
    !boiDataLoaded ||
    !taxProfileStatusLoaded;

  const isSubSectionPending =
    taxProfileCpaSideStatus[BOI_SECTION_NAMES.BOI_ELIGIBILIITY_QUESTIONS] !==
      TaxProfileReviewStatus.COMPLETE ||
    taxProfileCpaSideStatus[BOI_SECTION_NAMES.BOI_BUSINESS_DETAILS] !==
      TaxProfileReviewStatus.COMPLETE ||
    taxProfileCpaSideStatus[BOI_SECTION_NAMES.BOI_BENEFICIAL_OWNER_DETAILS] !==
      TaxProfileReviewStatus.COMPLETE;

  const {
    spaceLeftBelowTaxFilingSteps,
    TAX_PROFILE_SECTION_HEADER_HEIGHT,
    TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
  } = useLayout();
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const {markStepComplete} = useBoiSteps();

  const markAllComplete = async () => {
    try {
      setIsLoading(true);
      await postTaxProfileReviewStatus(userId, boiData.year, {
        [BOI_SECTION_NAMES.BOI_ELIGIBILIITY_QUESTIONS]:
          TaxProfileReviewStatus.COMPLETE,
        [BOI_SECTION_NAMES.BOI_BUSINESS_DETAILS]:
          TaxProfileReviewStatus.COMPLETE,
        [BOI_SECTION_NAMES.BOI_BENEFICIAL_OWNER_DETAILS]:
          TaxProfileReviewStatus.COMPLETE,
      });
      await dispatch(fetchTaxProfileStatusForGivenYear(userId, boiData.year));
      notify(`Boi Profile review complete`, NotificationType.success);
    } catch (e) {
      notify(`Error in marking step complete ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{height: spaceLeftBelowTaxFilingSteps}}>
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBlock: 12,
          paddingRight: 12,
          height: TAX_PROFILE_SECTION_HEADER_HEIGHT,
        }}>
        <Typography style={{marginLeft: 12, fontWeight: 800, fontSize: 20}}>
          {'Boi Profile Review'}
        </Typography>
        {isSubSectionPending && (
          <DSButton
            type="primary"
            onClick={markAllComplete}
            text="Mark all as reviewed"
            disabled={isDataLoading || isLoading}
          />
        )}
      </div>
      <div
        style={{
          width: '75vw',
          height:
            spaceLeftBelowTaxFilingSteps -
            TAX_PROFILE_SECTION_HEADER_HEIGHT -
            TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
          overflowY: 'auto',
        }}>
        {isDataLoading || isLoading ? (
          <Box col style={{padding: 20}}>
            <Skeleton width={'100%'} style={{marginBottom: 20}} height={150} />
            <Skeleton width={'100%'} style={{marginBottom: 20}} height={150} />
            <Skeleton width={'100%'} style={{marginBottom: 20}} height={150} />
            <Skeleton width={'100%'} style={{marginBottom: 20}} height={150} />
          </Box>
        ) : (
          <>
            <EligibilityQuestions />
            <BoiBusiness />
            <BeneficialOwnerDetails />
          </>
        )}
      </div>
      {isBoiStepComplete(
        boiData[BOI_FILING_COLUMNS.STATUS],
        BOI_STATUS.DETAILS_SUBMITTED,
      ) ? (
        <CompletedRibbon
          height={TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT}
          text="Boi profile review is complete"
        />
      ) : (
        <div
          style={{
            backgroundColor: themmeColor.offWhite,
            display: 'flex',
            justifyContent: 'space-between',
            paddingInline: 40,
            alignItems: 'center',
            height: TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
          }}>
          <Typography>
            Once you have reviewed all the sections you will be able to proceed
            to next step
          </Typography>
          <DSButton
            disabled={isDataLoading || isLoading || isSubSectionPending}
            style={{width: 400, height: 40}}
            text="Review complete"
            onClick={() =>
              markStepComplete(BoiFilingCenterRoutesParts.ProfileReview)
            }
            type="primary"
          />
        </div>
      )}
    </div>
  );
};

export default BoiProfileReview;
