import {getFormData} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {
  createBulkMessageAttUploadURLs,
  generateDocUploadURL,
  generatePartnerDocUploadUrls,
  getOcrData,
  getPartnersDocOcrData,
  postDocument,
  postPartnersBulkDocForOcr,
  submitDocForOCR,
} from 'src/appApi';
import useCurrentUserId from './useCurrentUserId';
import {
  TaxReturnDocumentType,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {pollRequest} from 'src/common/utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {BusinessType} from 'src/store/businessDetails/businessDetails.types';
import {
  PartnersDocOcrDataResponse,
  PartnersDocOcrStatus,
} from 'src/appApi.types';
import {AxiosResponse} from 'axios';

const useUploadDocument = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const uploadTaxReturn = async (
    file: any,
    returnId: number,
    returnType: TaxReturnType,
    entityType?: BusinessType,
  ) => {
    let docType = TaxReturnDocumentType.TAX_RETURNS;
    if (returnType === TaxReturnType.BUSINESS) {
      docType = TaxReturnDocumentType.BUSINESS_RETURNS;
    }
    // Generate upload url
    const {data} = await generateDocUploadURL({
      filename: file.name,
      year: activeYear,
    });

    // Upload document
    await postDocument(data.url, getFormData(file, data.fields));

    // Submit document for ocr
    const {data: job_id} = await submitDocForOCR({
      s3_key: data.s3_key,
      doc_type: docType,
    });

    // wait for OCR to complete
    await pollRequest(
      async () => {
        const pollResponse = await getOcrData({
          job_id,
          doc_type: docType,
          end_user_id: userId,
          year: activeYear,
          biz_return_id: returnId,
        });
        return pollResponse;
      },
      (res: any) => {
        return res?.data?.status === 'Done';
      },
      5000,
    );
  };

  const generateBulkMessageAttUploadURLs = async (filenames: string[]) => {
    const response = await createBulkMessageAttUploadURLs(filenames, userId);
    return response.data;
  };

  const uploadMessageAttachmentsToS3 = async (files: any[]) => {
    const urls = await generateBulkMessageAttUploadURLs(
      files.map((file) => file.name),
    );
    const uploadingFilePromises = urls.urls.map((url, index) => {
      return postDocument(url.url, getFormData(files[index], url.fields));
    });
    await Promise.all(uploadingFilePromises);
    const attachments = urls.urls.map((url, index) => {
      return {
        s3_key: url.s3_key,
        filename: files[index].name,
      };
    });
    return attachments;
  };

  const uploadPartnersDocumentOnS3 = async (files: File[]) => {
    const filenames = files.map((file) => file.name);
    const {
      data: {urls},
    } = await generatePartnerDocUploadUrls(filenames);
    const uploadingFilePromises = urls.map((url, index) => {
      return postDocument(url.url, getFormData(files[index], url.fields));
    });
    await Promise.all(uploadingFilePromises);
    const uploadedFilesInfo = urls.map((url, index) => {
      return {
        s3_key: url.s3_key,
        filename: files[index].name,
      };
    });
    return uploadedFilesInfo;
  };

  const postBulkDocumentForOcr = async (
    body: Parameters<typeof postPartnersBulkDocForOcr>['0'],
  ) => {
    const response = await postPartnersBulkDocForOcr(body);
    return response.data;
  };

  const getPartnerOcrDocsData = async (ocr_job_ids: number[]) => {
    // @ts-ignore
    const response: AxiosResponse<PartnersDocOcrDataResponse> =
      await pollRequest(
        async () => {
          const pollResponse = getPartnersDocOcrData(ocr_job_ids);
          return pollResponse;
        },
        (res: AxiosResponse<PartnersDocOcrDataResponse>) => {
          return res.data.status === PartnersDocOcrStatus.success;
        },
        5000,
      );
    return response;
  };

  return {
    uploadTaxReturn,
    generateBulkMessageAttUploadURLs,
    uploadMessageAttachmentsToS3,
    uploadPartnersDocumentOnS3,
    postBulkDocumentForOcr,
    getPartnerOcrDocsData,
  };
};

export default useUploadDocument;
