export enum TRANSFORMERS {
  NONE = 'NONE',
  STRING_TO_NUMBER = 'STRING_TO_NUMBER',
  LIST_OF_STRING_TO_NUMBER = 'LIST_OF_STRING_TO_NUMBER',
  EMPTY_ARRAY_TO_NULL = 'EMPTY_ARRAY_TO_NULL',
}

const noTransform = (value: any) => {
  return value;
};

const stringToNumber = (value: any) => {
  if (typeof value === 'string') {
    const parsed = parseInt(value, 10);
    if (!Number.isNaN(parsed)) {
      return parsed;
    }
  }
  return value;
};

const listStringToNumber = (value: any) => {
  if (!Array.isArray(value)) {
    return value;
  }
  return value.map((v) => {
    return stringToNumber(v);
  });
};

const emptyArrayToNull = (value: any) => {
  if (Array.isArray(value) && value.length === 0) {
    return null;
  }
  return value;
};

const TRANSFORMERS_MAP = {
  [TRANSFORMERS.NONE]: noTransform,
  [TRANSFORMERS.STRING_TO_NUMBER]: stringToNumber,
  [TRANSFORMERS.LIST_OF_STRING_TO_NUMBER]: listStringToNumber,
  [TRANSFORMERS.EMPTY_ARRAY_TO_NULL]: emptyArrayToNull,
};

export const transform = (value: any, type: TRANSFORMERS | TRANSFORMERS[]) => {
  if (Array.isArray(type)) {
    let v = value;
    type.forEach((t) => {
      v = TRANSFORMERS_MAP[t](v);
    });
    return v;
  }
  return TRANSFORMERS_MAP[type](value);
};
