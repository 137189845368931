import {BusinessDetail, BusinessDetailsField} from './businessDetails.types';
import {
  BUSINESS_DETAILS_INPUT_TYPE,
  FinalFieldType,
} from './businessDetails.reducer';
import {BusinessType} from './businessDetails.types';
import {get} from 'lodash';

const getBusinessAddressFields = (business: BusinessDetail) => {
  const bizAddress = get(business, BusinessDetailsField.address);
  return [
    {
      name: 'House number and street',
      value: get(bizAddress, BusinessDetailsField.number_and_street),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: get(bizAddress, BusinessDetailsField.apartment_number),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: get(bizAddress, BusinessDetailsField.city),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: get(bizAddress, BusinessDetailsField.state),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: get(bizAddress, BusinessDetailsField.zip_code),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Same as home address',
      value: get(business, BusinessDetailsField.is_address_same_as_home),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getShareholderAddressFields = (
  shareholder: BusinessDetail['share_holders'][0],
) => {
  const shareHolderAddress = get(shareholder, BusinessDetailsField.address);
  return [
    {
      name: 'House number and street',
      value: get(shareHolderAddress, BusinessDetailsField.number_and_street),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: get(shareHolderAddress, BusinessDetailsField.apartment_number),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: get(shareHolderAddress, BusinessDetailsField.city),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: get(shareHolderAddress, BusinessDetailsField.state),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: get(shareHolderAddress, BusinessDetailsField.zip_code),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getCommonSoleProprietorshipFields = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: get(business, BusinessDetailsField.entity_type),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: get(business, BusinessDetailsField.name),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Owner or proprietor',
      value: get(business, BusinessDetailsField.owner_or_proprietor),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: get(business, BusinessDetailsField.professions),
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Start date',
      value: get(business, BusinessDetailsField.start_date),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINPresent = (business: BusinessDetail) => {
  return [
    {
      name: 'EIN',
      value: get(business, BusinessDetailsField.ein),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: get(business, BusinessDetailsField.is_registered_with_state),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINNotPresent = (
  business: BusinessDetail,
) => {
  return [
    {
      name: 'This work is still continuing?',
      value: get(business, BusinessDetailsField.is_work_continuing),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'When did you stop this work?',
      value: get(business, BusinessDetailsField.work_stop_date),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: ' Are you and your spouse co-owners in this business / freelance activity?',
      value: get(business, BusinessDetailsField.is_spouse_business_partner),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getFieldsForShareHolder = (
  shareholder: BusinessDetail['share_holders'][0],
) => {
  return [
    {
      name: 'First name & middle initial',
      value: get(
        shareholder,
        BusinessDetailsField.first_name_and_middle_initial,
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Last name',
      value: get(shareholder, BusinessDetailsField.last_name),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Social Security Number',
      value: get(shareholder, BusinessDetailsField.ssn),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Ownership %',
      value: get(shareholder, BusinessDetailsField.ownership_pct),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Another individual acts on behalf of this shareholder',
      value: get(
        shareholder,
        BusinessDetailsField.is_another_individual_act_on_behalf,
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Cash/property contributed to the business',
      value: get(
        shareholder,
        BusinessDetailsField.property_contributed_to_business,
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Cash/property taken out from the business',
      value: get(
        shareholder,
        BusinessDetailsField.property_taken_out_of_business,
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholder Address',
      value: getShareholderAddressFields(shareholder),
      type: BUSINESS_DETAILS_INPUT_TYPE.SHAREHOLDER_ADDRESS,
    },
  ];
};

const getShareholdersDetails = (
  shareholders: BusinessDetail['share_holders'][],
) => {
  return (
    shareholders?.map((shareholder, index) => ({
      name: `Shareholder ${index + 1}`,
      value: getFieldsForShareHolder(shareholder),
      type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_SHAREHOLDER,
    })) ?? []
  );
};

const getDocumentsInformation = (business: BusinessDetail) => {
  return business.document_links?.map((docInfo) => ({
    name: get(docInfo, BusinessDetailsField.document_links_filename),
    type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_DOCUMENT,
    value: docInfo.id,
  }));
};

const getFieldsForPartnershipOrCCorp = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: get(business, BusinessDetailsField.entity_type),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: get(business, BusinessDetailsField.name),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: get(business, BusinessDetailsField.professions),
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: get(business, BusinessDetailsField.incorporation_date),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: get(business, BusinessDetailsField.phone_number),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: get(business, BusinessDetailsField.ein),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: get(business, BusinessDetailsField.accounting_method),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: get(business, BusinessDetailsField.is_registered_with_state),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(
        get(business, BusinessDetailsField.share_holders) as any,
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSigningAuthorityShareHolderName = (business: BusinessDetail) => {
  const signingAuthorityShareholder = get(
    business,
    BusinessDetailsField.share_holders,
  )?.find(
    (shareholder) =>
      shareholder?.[BusinessDetailsField.id] ===
      business?.[BusinessDetailsField.signing_authority_id],
  );
  return `${
    signingAuthorityShareholder?.[
      BusinessDetailsField.first_name_and_middle_initial
    ] ?? ''
  } ${signingAuthorityShareholder?.[BusinessDetailsField.last_name] ?? ''}`;
};

const getFieldsForScorp = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: get(business, BusinessDetailsField.entity_type),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: get(business, BusinessDetailsField.name),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: get(business, BusinessDetailsField.professions),
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: get(business, BusinessDetailsField.incorporation_date),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Scorp effective date',
      value: get(business, BusinessDetailsField.s_corp_effective_date),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: get(business, BusinessDetailsField.phone_number),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: get(business, BusinessDetailsField.ein),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: get(business, BusinessDetailsField.accounting_method),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: get(business, BusinessDetailsField.is_registered_with_state),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(
        get(business, BusinessDetailsField.share_holders) ?? [],
      ),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

export const getCorrectFieldsForBusiness: (
  business: BusinessDetail,
) => FinalFieldType[] = (business: BusinessDetail) => {
  if (business.entity_type === BusinessType.SOLE_PROPRIETORSHIP) {
    return [
      ...getCommonSoleProprietorshipFields(business),
      ...(business?.[BusinessDetailsField.have_ein]
        ? getSoleProprietorshipFieldsIfEINPresent(business)
        : getSoleProprietorshipFieldsIfEINNotPresent(business)),
    ];
  }
  if (
    [BusinessType.PARTNERSHIP, BusinessType.C_CORP].includes(
      business.entity_type,
    )
  ) {
    return [...getFieldsForPartnershipOrCCorp(business)];
  }
  if (
    [BusinessType.S_CORP].includes(
      business?.[BusinessDetailsField.entity_type],
    )
  ) {
    return [...getFieldsForScorp(business)];
  }

  return [] as FinalFieldType[];
};
