import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {patchTaxFiling} from 'src/appApi';
import {CpaCenterV2Route, RouteParts} from 'src/constants/routeName';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';
import useCpaCenterV2Navigation from './useCpaCenterV2Navigation';
import useCurrentUserId from './useCurrentUserId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import _ from 'lodash';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useCurrentStep = () => {
  const dispatch = useDispatch();
  const {
    navigateToTaxProfileReview,
    navigateToUserQueries,
    navigateToReviewEsign,
  } = useCpaCenterV2Navigation();
  const {pathname} = useLocation();
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();
  const {activeYear} = useActiveYear();

  const {
    taxFilingId,
    loaded: userLoaded,
    cpaFacingRetStatus,
  } = useSelector(selectUser);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );

  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    {
      label: 'Document review',
      props: {
        url: CpaCenterV2Route.CpaCenterV2DocumentReview,
        section: RouteParts.DocumentReview,
      },
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
      ),
    },
    {
      label: 'Tax profile review',
      props: {
        url: CpaCenterV2Route.CpaCenterV2TaxProfileReview,
        section: RouteParts.TaxProfileReview,
      },
      // disabled: !isCpaCenterV2StepComplete(
      //   cpaFacingRetStatus,
      //   TAX_RETURN_STATUS.DOCS_APPROVED,
      // ),
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
      ),
    },
    {
      label: 'user queries',
      props: {
        url: CpaCenterV2Route.CpaCenterV2UserQueries,
        section: RouteParts.UserQueries,
      },
      // disabled: !isCpaCenterV2StepComplete(
      //   cpaFacingRetStatus,
      //   TAX_RETURN_STATUS.USER_QUERIES_SENT,
      // ),
      disabled: false,
      isCompleted: isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.PREP_STARTED,
      ),
    },
    {
      label: 'Return review & E-filing',
      props: {
        url: CpaCenterV2Route.CpaCenterV2ReviewEsign,
        section: RouteParts.ReviewEsign,
      },
      disabled: false,
      isCompleted: !taxReturns.some(
        (taxReturn) =>
          taxReturn.cpa_status !== TAX_FILING_STATUS.APPROVED_FROM_IRS,
      ),
    },
  ];

  const markStepComplete = async (status: TAX_FILING_STATUS) => {
    try {
      setIsLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {cpa_facing_ret_status: status},
      );
      await dispatch(fetchUserInfo(userId, activeYear));
      if (status === TAX_FILING_STATUS.TAX_DOCS_REVIEWED) {
        navigateToTaxProfileReview();
      } else if (status === TAX_FILING_STATUS.USER_QUERIES_SENT) {
        navigateToUserQueries();
      } else if (status === TAX_FILING_STATUS.PREP_STARTED) {
        navigateToReviewEsign();
      }
      notify('Status updated successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to update status ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const step = steps.find((step) => pathname.includes(step.props.section));

  let currentLabel;

  if (step) {
    currentLabel = step.label;
  }

  return {
    currentLabel,
    steps,
    markStepComplete,
    isLoading: isLoading || !taxReturnsLoaded || !userLoaded,
    cpaFacingRetStatus,
  };
};

export default useCurrentStep;
