import {ReduxStateType} from '../store';

export const selectTaxProfileCpaSideStatus = (state: ReduxStateType) => {
  return {
    taxProfileCpaSideStatus: state.taxProfileStatus.taxProfileCpaSideStatus,
    loaded: state.taxProfileStatus.loaded,
    status: state.taxProfileStatus.status,
  };
};

export const selectTaxProfileUserSideStatus = (state: ReduxStateType) => {
  return {
    taxProfileUserSideStatus: state.taxProfileStatus.taxProfileUserSideStatus,
    loaded: state.taxProfileStatus.loaded,
    status: state.taxProfileStatus.status,
  };
};
