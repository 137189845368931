import {Typography} from '@mui/material';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useIPPinDetails} from 'src/CpaCenterV2/hooks/useIPPinDetails';
import Box from 'src/DesignSystem/Box/Box';
import {themmeColor} from 'src/constants/constants';
import RightChveron from 'src/icons/RightChveron';
import TickIcon from 'src/icons/TickIcon';
import {setIpPinDetailsDrawer} from 'src/store/app/app.actions';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

interface IPPinDetailsProps {
  currentTaxReturn?: TaxReturn;
}

const IPPinDetails = ({currentTaxReturn}: IPPinDetailsProps) => {
  const dispatch = useDispatch();
  const {hasSubmittedIpPins} = useIPPinDetails();

  if (
    !hasSubmittedIpPins ||
    currentTaxReturn?.return_type !== TaxReturnType.INDIVIDUAL
  ) {
    return null;
  }

  const onClick = () => {
    dispatch(setIpPinDetailsDrawer(true));
  };

  return (
    <Box
      borderColor={themmeColor.offWhite}
      hoverEffect
      onClick={onClick}
      hoverColor={themmeColor.focusedBlue}
      style={{
        paddingBlock: 12,
        paddingInline: 16,
        justifyContent: 'space-between',
        marginBottom: 20,
        marginTop: 20,
      }}>
      <Typography style={{fontWeight: 600}}>IP PIN details</Typography>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <TickIcon />
        <Typography
          style={{
            fontSize: 13,
            fontWeight: 600,
            marginRight: 10,
            marginLeft: 4,
          }}>
          Submitted
        </Typography>
        <RightChveron />
      </div>
    </Box>
  );
};

export default IPPinDetails;
