import React, {ReactNode} from 'react';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {themmeColor} from 'src/constants/constants';

interface ReturnContainerCardProps {
  tagName: string;
  switchComponent: ReactNode;
  children: ReactNode | ReactNode[];
}

const ReturnContainerCard = ({
  tagName,
  switchComponent,
  children,
}: ReturnContainerCardProps) => {
  return (
    <div
      style={{
        backgroundColor: themmeColor.cpaCenterV2Bg,
        paddingBlock: 16,
        paddingInline: 12,
        display: 'flex',
        flexFlow: 'column',
        borderRadius: 16,
      }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text
          type={TEXT_TYPES.XS}
          text={tagName}
          containerStyle={{
            backgroundColor: themmeColor.black,
            color: themmeColor.white,
            textTransform: 'uppercase',
            paddingBlock: 4,
            paddingInline: 8,
            borderRadius: 4,
          }}
          fontWeight={FONT_WEIGHTS.SemiBold}
        />
        {switchComponent}
      </div>
      {children}
    </div>
  );
};

export default ReturnContainerCard;
