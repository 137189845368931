import {DependentRelations} from 'src/constants/constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';

export enum EducationalCreditsChoices {
  IS_STUDENT = 'is_student',
  SPENT_HALF_TIME_IN_ACADEMIC = 'spent_half_time_in_academic',
  HAS_NOT_FINISHED_HIGHER_EDUCATION = 'has_not_finished_higher_education',
  IS_NOT_DRUG_CONVICT = 'is_not_drug_convict',
  HAS_NOT_CLAIMED_AOTC = 'has_not_claimed_aotc',
}

export const educationalCreditsHumanReadableCopies = (year: number) => ({
  [EducationalCreditsChoices.IS_STUDENT]: `Was a student in year ${year}.`,
  [EducationalCreditsChoices.SPENT_HALF_TIME_IN_ACADEMIC]:
    'Maintained at least half-time status in at least one academic period',
  [EducationalCreditsChoices.HAS_NOT_FINISHED_HIGHER_EDUCATION]: `Didn’t finish the first four years of college at the start of ${year}`,
  [EducationalCreditsChoices.IS_NOT_DRUG_CONVICT]: `Not a felony drug convict by the end of ${year}`,
  [EducationalCreditsChoices.HAS_NOT_CLAIMED_AOTC]:
    'Have never claimed AOTC (Hope credit) or claimed it for less than 4 years',
});

export const CHILD_RELATIONS = [
  DependentRelations.Son,
  DependentRelations.Daughter,
  DependentRelations.AdoptedChild,
  DependentRelations.FosterChild,
  DependentRelations.Stepchild,
  DependentRelations.Grandchild,
];

export const ADOPTED_CHILD_RELATIONS = [
  DependentRelations.AdoptedChild,
  DependentRelations.FosterChild,
];

export const PARENT_RELATIONS = [
  DependentRelations.Mother,
  DependentRelations.Father,
];

export const RELATIVE_RELATIONS = [
  DependentRelations.Brother,
  DependentRelations.Sister,
  DependentRelations.Uncle,
  DependentRelations.Aunt,
  DependentRelations.Grandfather,
  DependentRelations.Grandmother,
  DependentRelations.Nephew,
  DependentRelations.Niece,
];

export enum DEPENDENT_CREDITS_OPTIONS {
  CHILD_CARE_AND_CHILD_TAX_CREDIT = 'CHILD_CARE_AND_CHILD_TAX_CREDIT',
  ONLY_CHILD_TAX_CREDIT = 'ONLY_CHILD_TAX_CREDIT',
  ONLY_OTHER_DEPENDENT_CREDIT = 'ONLY_OTHER_DEPENDENT_CREDIT',
  NONE = 'NONE',
}

export const DEPENDENT_CREDITS_QUESTION_TYPE = {
  CHILD_LESS_THAN_17: 'CHILD_LESS_THAN_17',
  CHILD_LESS_THAN_19: 'CHILD_LESS_THAN_19',
  CHILD_LESS_THAN_24: 'CHILD_LESS_THAN_24',
  CHILD: 'CHILD',
  PARENT: 'PARENT',
  RELATIVE: 'RELATIVE',
  OTHER_DEPENDENT: 'OTHER_DEPENDENT',
} as const;

export enum DEPENDENT_CREDITS_QUESTIONS {
  LIVED_WITH_YOU_HALF_YEAR = TaxFormFieldIds.LIVED_IN_HOUSECARE_FOR_HALF_YEAR,
  LIVED_WITH_YOU_OR_SCHOOL = TaxFormFieldIds.LIVED_WITH_YOU_OR_SCHOOL_FOR_HALF_YEAR,
  LIVED_WITH_YOU_WHOLE_YEAR = TaxFormFieldIds.LIVED_WITH_TAX_PAYER_FOR_WHOLE_YEAR,
  MORE_THAN_HALF_YEAR_SUPPORT = TaxFormFieldIds.MORE_THAN_HALF_YEAR_SUPPORT,
  DID_EARN_MORE_THAN_4700 = TaxFormFieldIds.DID_EARN_MORE_THAN_4700,
  IS_FILING_JOINT_TAX_RETURN_THIS_YEAR = TaxFormFieldIds.IS_FILING_JOINT_TAX_RETURN_THIS_YEAR,
  IS_FULL_TIME_STUDENT = TaxFormFieldIds.IS_FULL_TIME_STUDENT,
}
