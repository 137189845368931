import React, {ReactNode} from 'react';
import {Typography} from '@mui/material';
import {format} from 'date-fns';
import {themmeColor} from 'src/constants/constants';
import {
  ReduxMessageType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';

interface MessageHeaderProps {
  query: ReduxQueryType;
  message: ReduxMessageType;
}

const MessageHeader = ({query, message}: MessageHeaderProps) => {
  const timeString = format(message.messageCreatedAt, 'dd/MM/yyyy, HH:mm');

  return (
    <>
      <div>
        <Typography style={{fontSize: 11, color: themmeColor.grey}}>
          {timeString}
        </Typography>
      </div>
    </>
  );
};

export default MessageHeader;
