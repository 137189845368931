import React from 'react';
import Box from 'src/DesignSystem/Box/Box';
import {themmeColor} from 'src/constants/constants';

interface NotApplicableViewProps {
  children: any;
}

const NotApplicableView = ({children}: NotApplicableViewProps) => {
  return (
    <Box
      borderColor="#F0F0F0"
      backgroundColor={themmeColor.cpaCenterV2Bg}
      col
      style={{
        padding: '13px 16px',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {children}
    </Box>
  );
};

export default NotApplicableView;
