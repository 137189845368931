import React from 'react';
import {themmeColor} from 'src/constants/constants';
import DownIcon from 'src/icons/DownIcon';

interface CollapseButtonProps {
  setIsCollapsed: any;
  isCollapsed: boolean;
}

const CollapseButton = ({
  setIsCollapsed,
  isCollapsed,
}: CollapseButtonProps) => {
  return (
    <div
      onClick={() => setIsCollapsed((prev: boolean) => !prev)}
      style={{
        backgroundColor: themmeColor.black,
        position: 'absolute',
        left: '70vw',
        height: 36,
        width: 30,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}>
      <div
        style={{
          rotate: isCollapsed ? '0deg' : '180deg',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <DownIcon color={themmeColor.white} />
      </div>
    </div>
  );
};

export default CollapseButton;
