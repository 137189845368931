import {AxiosResponse} from 'axios';
import {createContext} from 'react';
import {
  VALID_DOCUMENT_REASON,
  VALID_DOCUMENT_STATUS,
} from 'src/constants/constants';
import {BackendDocTypeStatus} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';

export interface Document {
  documentId: number;
  status: VALID_DOCUMENT_STATUS;
  filename: string;
  documentURI: Promise<AxiosResponse<any, any>>;
  isImage: boolean;
  reason?: VALID_DOCUMENT_REASON;
  taxProfileFields: Array<{label: string; priority: number; value: string}>;
  documentTypeId: number;
  documentTypeName: string;
}

export interface DocumentType {
  documentTypeId: number;
  status: BackendDocTypeStatus;
  year: number;
  documents: Array<Document>;
  documentTypeName: string;
  pendingForReviewCount: number;
  noUploadReason: string;
}

interface DocumentsContextProps {
  docsData: Array<DocumentType>;
  fetchDocuments: () => Promise<void>;
  isDocumentLoading: boolean;
  setIsDocumentLoading: (newState: boolean) => void;
  requiredDocTypes: Array<string>;
  uri: string;
  setURI: any;
  canvasRef: any;
  canvasPDFRef: any;
  drawImageBBox: (ocrData: any) => void;
  drawPDFBBox: (page: number, ocrData: any) => void;
  imageScale: number;
  setImageScale: any;
}

// @ts-ignore
const DocumentsContext = createContext<DocumentsContextProps>({});

export default DocumentsContext;
