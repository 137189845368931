import {Typography} from '@mui/material';
import _ from 'lodash';
import React, {ComponentProps} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CLASSIFICATION_STATUS} from 'src/constants/constants';
import TransactionListItem from 'src/CpaCenterV2/common/TransactionsList/components/TransactionListItem';
import IRSGroupedTransactionList from 'src/CpaCenterV2/common/TransactionsList/IRSGroupedTransactionList';
import SimpleTransactionsList from 'src/CpaCenterV2/common/TransactionsList/SimpleTransactionsList';
import useHighValueDeduction from 'src/CpaCenterV2/hooks/useHighValueDeduction';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import CrossIcon from 'src/icons/CrossIcon';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectHighValueDeductionsStatusDrawer} from 'src/store/app/app.selector';
import {selectQueriesReducer} from 'src/store/queries/queries.selector';

const HighValueDeductionsStatusDrawer = () => {
  const {isVisible, queryId} = useSelector(
    selectHighValueDeductionsStatusDrawer,
  );

  const {queryMap} = useSelector(selectQueriesReducer);

  const query = queryMap[queryId];

  const dispatch = useDispatch();

  const {getHighValueQueryExpenseStatus} = useHighValueDeduction();
  if (!query) {
    return null;
  }
  const {pendingTxns, actionTakenTxns} = getHighValueQueryExpenseStatus(query);

  const getDiffConfig: () => ComponentProps<
    typeof TransactionListItem
  >['diffConfig'] = () => {
    const originalValues = _.keyBy(
      query.highValueDeductions.map((item) => ({
        irsCategory: item.original_irs_category,
        classificationStatus: CLASSIFICATION_STATUS.DEDUCTION,
        txnId: item.txn_id,
      })),
      'txnId',
    );

    return {
      diffKeys: ['classificationStatus', 'irsCategory'],
      originalValues,
    };
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={400}
      height={'90vh'}
      isOpen={isVisible}
      direction="left">
      <div style={{padding: 12}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBlock: 12,
          }}>
          <Typography style={{fontSize: 20, fontWeight: 600}}>
            Deductions with high value
          </Typography>
          <div
            onClick={() =>
              dispatch(
                setAppState(AppReducerStates.highValueDeductionsStatusDrawer, {
                  isVisible: false,
                  queryId: -1,
                }),
              )
            }>
            <CrossIcon />
          </div>
        </div>
        <SimpleTransactionsList
          txns={actionTakenTxns}
          header={`${actionTakenTxns.length} deductions reviewed`}
          diffMode={true}
          diffConfig={getDiffConfig()}
        />
        <div style={{height: 20}} />
        <IRSGroupedTransactionList
          selectionMode={IRSGroupedTransactionList.SelectionMode.NONE}
          txns={pendingTxns}
          header={`${pendingTxns.length} deductions yet to review`}
        />
      </div>
    </Drawer>
  );
};

export default HighValueDeductionsStatusDrawer;
