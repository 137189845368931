export interface ReduxActionType {
  type: string;
  payload: any;
}

export enum REDUX_STATUS {
  INIT = 'INIT',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}
