import React from 'react';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import CommonDeductionTable from './CommonDeductionTable';

const AllOtherDeductions = () => {
  return (
    <SubSectionBox
      name="All other deductions"
      taxprofileSubsection={TaxProfileSubsection.AllOtherDeductions}>
      <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
        <CommonDeductionTable
          taxProfileSubsection={TaxProfileSubsection.AllOtherDeductions}
        />
      </div>
    </SubSectionBox>
  );
};

export default AllOtherDeductions;
