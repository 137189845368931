import React, {useMemo} from 'react';
import {Edit} from '@mui/icons-material';
import {isArray} from 'lodash';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import NotesIcon from 'src/icons/NotesIcon';
import {
  InformationSource,
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import NetAmountRow from '../../common/NetAmountRow';

interface BusinessIncomeTableProps {
  headerName: string;
  form?: TaxForm<TaxFormType.PROFIT_LOSS_FORM>;
  header:
    | TaxFormFieldIds.INCOME
    | TaxFormFieldIds.COST_OF_GOODS_SOLD
    | TaxFormFieldIds.OTHER_INCOME;
}

const BusinessIncomeTable = ({
  headerName,
  header,
  form,
}: BusinessIncomeTableProps) => {
  const rows = form?.formData[header]?.[TaxFormFieldIds.SUB_CATEGORY];

  const totalAmount = useMemo(() => {
    return form?.formData[header]?.[TaxFormFieldIds.AMOUNT] ?? 0;
  }, [form, header]);

  return (
    <div style={{marginBlock: 12}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 4,
        }}>
        <Text type={TEXT_TYPES.BASE} text={headerName} />
      </div>
      {isArray(rows) ? (
        <KeyValueTable
          data={rows}
          keys={[
            {
              getValue: (row) => row[TaxFormFieldIds.KEY],
            },
            {
              getValue: (row) =>
                CurrencyNumberFormat(row[TaxFormFieldIds.AMOUNT] ?? 0),
              textStyle: {
                fontWeight: 600,
              },
              cellStyle: {
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
            {
              getValue: (row) => (
                <div style={{marginInline: 20}}>
                  {row[TaxFormFieldIds.SOURCE] ===
                  InformationSource.document ? (
                    <NotesIcon />
                  ) : (
                    <Edit />
                  )}
                </div>
              ),
              cellStyle: {maxWidth: 60},
            },
            {
              getValue: (row) => row[TaxFormFieldIds.CATEGORY],
              cellStyle: {
                backgroundColor: themmeColor.white,
                borderRadius: 8,
                paddingInline: 12,
                paddingBlock: 8,
                border: `solid 1px ${themmeColor.offWhite}`,
              },
            },
            {
              getValue: (row) =>
                row[TaxFormFieldIds.IS_APPLICABLE]
                  ? 'Applicable'
                  : 'Not Applicable',
              cellStyle: {
                maxWidth: 150,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
          ]}
        />
      ) : null}
      <NetAmountRow text={`Total ${headerName}`} amount={totalAmount} />
    </div>
  );
};

export default BusinessIncomeTable;
