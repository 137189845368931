import {getDistributeDeductions} from 'src/appApi';
import {SET_DISTRIBUTE_DEDUCTIONS} from '../actionNames';
import {DistributeDeductionsStateType} from './distributeDeductions.reducer';

export const setDistributeDeductions = (
  payload: Omit<DistributeDeductionsStateType, 'status' | 'loaded'>,
) => {
  return {
    type: SET_DISTRIBUTE_DEDUCTIONS,
    payload,
  };
};

export const fetchDistributeDeductions = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const {data}: any = await getDistributeDeductions({
        fly_user_id: userId,
        year,
      });
      dispatch(setDistributeDeductions({distributedDeductions: data}));
    } catch (e) {}
  };
};
