import {Circle} from '@mui/icons-material';
import {Checkbox, CircularProgress, Typography} from '@mui/material';
import {format} from 'date-fns';
import React from 'react';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import TickIcon from 'src/icons/TickIcon';
import WarningIcon from 'src/icons/WarningIcon';
import {
  CCHStatus,
  CCHStatusType,
  CCHSubsectionStatus,
} from 'src/store/cchStatus/cchStatus.reducer';

interface CCHCheckBoxProps {
  isChecked?: boolean;
  onClick?: () => void;
  subsectionStatus?: CCHStatusType;
  value: string;
  isSubsection: boolean;
  isLoading: boolean;
}

const CCHCheckBox = ({
  isChecked = false,
  onClick = () => {},
  subsectionStatus,
  value,
  isSubsection,
  isLoading,
}: CCHCheckBoxProps) => {
  const {status, error_msg, last_synced_at} =
    subsectionStatus ?? ({} as CCHStatusType);

  const isDone = status === CCHStatus.BACMP;
  const inProgress = [CCHStatus.BAINP, CCHStatus.BARTR].includes(status);
  const isError = [CCHStatus.BAEXC, CCHStatus.BASTD, CCHStatus.BATRD].includes(
    status,
  );

  const getMessage = () => {
    if (isDone) {
      if (!last_synced_at) {
        return `Sent on ${getFieldValue(null)}`;
      }
      return `Sent on ${format(last_synced_at, 'do MMM yyyy')} at ${format(
        last_synced_at,
        'hh:mm:ss a',
      )}`;
    }
    if (inProgress) {
      return `In progress`;
    }
    if (isError) {
      return `Error: ${error_msg}`;
    }
    return null;
  };

  const getIcon = () => {
    if (isLoading && isChecked) {
      return (
        <CircularProgress
          style={{
            height: isSubsection ? 12 : 24,
            width: isSubsection ? 12 : 24,
          }}
        />
      );
    }
    if (isDone) {
      return <TickIcon />;
    }
    if (isError) {
      return <WarningIcon />;
    }
    if (inProgress) {
      return <Skeleton width={24} height={24} />;
    }
    return null;
  };

  const message = getMessage();
  return (
    <div
      style={{
        display: 'flex',
        paddingBlock: isSubsection ? 4 : 14,
        paddingInline: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {isSubsection ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Circle style={{height: 8, width: 8}} />
            <Typography style={{marginLeft: 8}}>{value}</Typography>
          </div>
        ) : (
          <>
            <Checkbox
              onChange={onClick}
              checked={isChecked}
              disabled={isLoading}
              style={{marginRight: 12}}
            />
            <div>
              <Typography style={{fontWeight: 600, fontSize: 16}}>
                {value}
              </Typography>
              {message && (
                <Typography style={{fontSize: 13}}>{message}</Typography>
              )}
            </div>
          </>
        )}
      </div>
      {getIcon()}
    </div>
  );
};

export default CCHCheckBox;
