import React from 'react';
import {
  DerivedMessageType,
  ReduxMessageType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import DocAttachedMessage from './DocAttachedMessage';
import DraftMessage from './DraftMessage';
import {HistoryMessage} from './HistoryMessage';
import {NormalMessage} from './NormalMessage';

export interface MessageProps {
  query: ReduxQueryType;
  message: ReduxMessageType;
}

const Message = ({message, query}: MessageProps) => {
  const RenderMessage = () => {
    switch (message.derivedMessageType) {
      case DerivedMessageType.NORMAL: {
        return <NormalMessage query={query} message={message} />;
      }
      case DerivedMessageType.HISTORY: {
        return <HistoryMessage query={query} message={message} />;
      }
      case DerivedMessageType.DOC_ATTACHED: {
        return <DocAttachedMessage query={query} message={message} />;
      }
      case DerivedMessageType.DRAFT_MESSAGE: {
        return <DraftMessage query={query} message={message} />;
      }
    }
    return <NormalMessage query={query} message={message} />;
  };
  return <>{RenderMessage()}</>;
};

export default Message;
