import {BusinessDetail} from './businessDetails.types';
import {
  BUSINESS_DETAILS_INPUT_TYPE,
  FinalFieldType,
} from './businessDetails.reducer';
import {BusinessType} from './businessDetails.types';

const getBusinessAddressFields = (business: BusinessDetail) => {
  return [
    {
      name: 'House number and street',
      value: business.address?.number_and_street,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: business.address?.apartment_number,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: business.address?.city,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: business.address?.state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: business.address?.zip_code,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Same as home address',
      value: business.is_address_same_as_home,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getShareholderAddressFields = (
  shareholder: BusinessDetail['share_holders'][0],
) => {
  return [
    {
      name: 'House number and street',
      value: shareholder.address.numberAndStreet,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: shareholder.address.apartmentNumber,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: shareholder.address.city,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: shareholder.address.state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: shareholder.address.zipCode,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getCommonSoleProprietorshipFields = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: business.entity_type,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Owner or proprietor',
      value: business.owner_or_proprietor,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Start date',
      value: business.start_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINPresent = (business: BusinessDetail) => {
  return [
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.is_registered_with_state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINNotPresent = (
  business: BusinessDetail,
) => {
  return [
    {
      name: 'This work is still continuing?',
      value: business.is_work_continuing,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'When did you stop this work?',
      value: business.work_stop_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: ' Are you and your spouse co-owners in this business / freelance activity?',
      value: business.is_spouse_business_partner,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getFieldsForShareHolder = (
  shareholder: BusinessDetail['share_holders'][0],
) => {
  return [
    {
      name: 'First name & middle initial',
      value: shareholder.first_name_and_middle_initial,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Last name',
      value: shareholder.last_name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Social Security Number',
      value: shareholder.ssn,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Ownership %',
      value: shareholder.ownership_pct,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Another individual acts on behalf of this shareholder',
      value: shareholder.is_another_individual_act_on_behalf,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholder Address',
      value: getShareholderAddressFields(shareholder),
      type: BUSINESS_DETAILS_INPUT_TYPE.SHAREHOLDER_ADDRESS,
    },
  ];
};

const getShareholdersDetails = (
  shareholders: BusinessDetail['share_holders'][],
) => {
  return shareholders.map((shareholder, index) => ({
    name: `Shareholder ${index + 1}`,
    value: getFieldsForShareHolder(shareholder),
    type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_SHAREHOLDER,
  }));
};

const getDocumentsInformation = (business: BusinessDetail) => {
  return business.document_links?.map((docInfo) => ({
    name: docInfo.filename,
    type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_DOCUMENT,
    value: docInfo.id,
  }));
};

const getFieldsForPartnershipOrCCorp = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: business.entity_type,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: business.incorporation_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: business.phone_number,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: business.accounting_method,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.is_registered_with_state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(business.share_holders ?? []),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSigningAuthorityShareHolderName = (business: BusinessDetail) => {
  const signingAuthorityShareholder = business.share_holders?.find(
    (shareholder) => shareholder.id === business.signingAuthorityId,
  );
  return `${
    signingAuthorityShareholder?.first_name_and_middle_initial ?? ''
  } ${signingAuthorityShareholder?.last_name ?? ''}`;
};

const getFieldsForScorp = (business: BusinessDetail) => {
  return [
    {
      name: 'Type of business',
      value: business.entity_type,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: business.incorporation_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Scorp effective date',
      value: business.s_corp_effective_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: business.phone_number,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: business.accounting_method,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.is_registered_with_state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(business.share_holders),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

export const getCorrectFieldsForBusiness: (
  business: BusinessDetail,
) => FinalFieldType[] = (business: BusinessDetail) => {
  if (business.entity_type === BusinessType.SOLE_PROPRIETORSHIP) {
    return [
      ...getCommonSoleProprietorshipFields(business),
      ...(business.have_ein
        ? getSoleProprietorshipFieldsIfEINPresent(business)
        : getSoleProprietorshipFieldsIfEINNotPresent(business)),
    ];
  }
  if (
    [BusinessType.PARTNERSHIP, BusinessType.C_CORP].includes(
      business.entity_type,
    )
  ) {
    return [...getFieldsForPartnershipOrCCorp(business)];
  }
  if ([BusinessType.S_CORP].includes(business.entity_type)) {
    return [...getFieldsForScorp(business)];
  }

  return [] as FinalFieldType[];
};
