import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessDeductions from './SingleBusinessDeductions';
import { BusinessDetailsField } from 'src/store/businessDetails/businessDetails.types';

const BusinessDeductions = () => {
  const {businesses} = useSelector(selectBusinessDetails);
  return (
    <SubSectionBox
      name="Business deductions"
      taxprofileSubsection={TaxProfileSubsection.BusinessDeductions}>
      {businesses.map((biz) => {
        return <SingleBusinessDeductions key={biz[BusinessDetailsField.id]} biz={biz} />;
      })}
    </SubSectionBox>
  );
};

export default BusinessDeductions;
