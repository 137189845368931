import React, {useCallback, useEffect, useMemo} from 'react';
import {useContext, useState} from 'react';
import {
  CategoryDeductionsSplit,
  CategoryGroupWiseDeductionSplit,
  DeductionsDistributedByAuthor,
  DistributeDeductionCategoryGrouping,
  DistributeDeductionsStateType,
  SplitBusinessType,
  VehicleDeductions,
} from 'src/store/distributeDeductions/distributeDeductions.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {selectDistributedDeductions} from 'src/store/distributeDeductions/distributeDeductions.selector';
import _ from 'lodash';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {postDistributeDeductionsSplit} from 'src/appApi';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {fetchDistributeDeductions} from 'src/store/distributeDeductions/distributeDeductions.actions';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {
  SEPERATE_OFFICE_ANSWER,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const DistributeDeductionsContext = React.createContext({
  selectedAuthor: DeductionsDistributedByAuthor.CPA,
  setSelectedAuthor: (newAuthor: DeductionsDistributedByAuthor) => {},
  business_info:
    [] as DistributeDeductionsStateType['distributedDeductions']['business_info'],
  getCellColor: (index: number) => '' as string,
  categoryGroupWiseDeductionsSplit: {} as CategoryGroupWiseDeductionSplit,
  onChangeSplitValue: ({
    groupName,
    rowIndex,
    splitIndex,
    newValue,
    vehicleIndex,
  }: {
    groupName: DistributeDeductionCategoryGrouping;
    rowIndex: number;
    splitIndex: number;
    newValue: number;
    vehicleIndex?: number | undefined;
  }) => {},
  groupOrder: [] as DistributeDeductionCategoryGrouping[],
  categoryGroupHeader: {} as CategoryGroupWiseDeductionSplit,
  round: (n: number, precision?: number) => 0 as number,
  loading: false,
  dataLoaded: true,
  isUpdateButtonDisabled: true,
  isEditDisabled: false,
  postCpaData: () => {},
  hasUserSubmitted: false,
  querySent: false,
  seperateOffice: null as SEPERATE_OFFICE_ANSWER | null,
});

const colors = ['#FFF3F3', '#F3FCFF', '#FFFAF3'];

export const DistributeDeductionsProvider = ({children}: any) => {
  const [selectedAuthor, setSelectedAuthor] = useState(
    DeductionsDistributedByAuthor.END_USER,
  );
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const seperateOffice: SEPERATE_OFFICE_ANSWER = useSelector(
    (state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.SEPARATE_OFFICE,
        activeYear,
      ),
  );

  const {distributedDeductions, loaded: dataLoaded} = useSelector(
    selectDistributedDeductions,
  );
  const hasUserSubmitted = distributedDeductions.has_user_submitted;
  const querySent = distributedDeductions.query_sent;
  const [loading, setLoading] = useState(false);
  const deductionsGroup = useMemo(
    () => _.cloneDeep(distributedDeductions[selectedAuthor]),
    [distributedDeductions, selectedAuthor],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDistributeDeductions(userId, activeYear));
  }, []);

  const {notify} = useNotify();
  const groupOrder = useMemo(
    () =>
      [
        DistributeDeductionCategoryGrouping.HOME_OFFICE_DEDUDCTIONS,
        DistributeDeductionCategoryGrouping.OFFICE_DEDUDCTIONS,
        DistributeDeductionCategoryGrouping.OTHER_RENT_DEDUCTIONS,
        DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION,
        DistributeDeductionCategoryGrouping.OTHER_DEDUCTION,
      ].filter((item) => Object.keys(deductionsGroup).includes(item)),
    [deductionsGroup],
  );

  const round = (n: number, precision = 2) => {
    const BASE = Math.pow(10, precision);
    return Math.round(n * BASE) / BASE;
  };

  const getCategoryTotal = ({
    groupName,
    vehicleIndex = 0,
  }: {
    groupName: DistributeDeductionCategoryGrouping;
    vehicleIndex?: number;
  }) => {
    if (groupName !== DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION) {
      return round(
        deductionsGroup[groupName]
          .map((categoryInfo) => categoryInfo.total_ded_amount)
          .reduce((prev, curr) => prev + curr, 0),
      );
    } else {
      return round(
        deductionsGroup[groupName][vehicleIndex].category_wise_info
          .map((categoryInfo) => categoryInfo.total_ded_amount)
          .reduce((prev, curr) => prev + curr, 0),
      );
    }
  };

  const getGroupsHeader: () => CategoryGroupWiseDeductionSplit = () => {
    const notVehicleCategoriesData = groupOrder
      .filter(
        (group) =>
          group !== DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION,
      )
      .map((groupName, index) => [
        {
          total_ded_amount: getCategoryTotal({groupName}),
          split: distributedDeductions.business_info.map((i) => ({
            ratio: 0,
            business_type: i.business_type,
            business_id: i.business_id,
          })),
          category_name: groupName,
          is_applicable_for_rental_prop: true,
        },
      ]);
    const vehicleCategoriesData = {
      [DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION]: deductionsGroup[
        DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION
      ].map((vehicleInfo, vehicleIndex) => ({
        vehicle_id: vehicleInfo.vehicle_id,
        category_wise_info: [
          {
            total_ded_amount: getCategoryTotal({
              groupName: DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION,
              vehicleIndex,
            }),
            split: distributedDeductions.business_info.map((i) => ({
              ratio: 0,
              business_type: i.business_type,
              business_id: i.business_id,
            })),
            category_name: vehicleInfo.vehicle_name,
            is_applicable_for_rental_prop: true,
          },
        ],
      })),
    } as {
      [DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION]: VehicleDeductions[];
    };
    const headers = {
      // @ts-ignore
      ...(_.keyBy(notVehicleCategoriesData, '[0].category_name') as {
        [key in DistributeDeductionCategoryGrouping]: CategoryDeductionsSplit[];
      }),
      ...vehicleCategoriesData,
    };
    return headers;
  };

  const [categoryGroupHeader, setCategoryGroupHeader] = useState(() =>
    getGroupsHeader(),
  );

  const onChangeSplitValue = ({
    groupName,
    rowIndex,
    splitIndex,
    newValue,
    vehicleIndex = -1,
  }: {
    groupName: DistributeDeductionCategoryGrouping;
    rowIndex: number;
    splitIndex: number;
    newValue: number;
    vehicleIndex?: number;
  }) => {
    const updatedCategoryGroupWiseSplit = {
      ...categoryGroupWiseDeductionsSplit,
    };
    const headersValue = {
      ...categoryGroupHeader,
    };
    if (groupName !== DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION) {
      // Not vehicle case
      if (rowIndex !== -1) {
        // Update one cell
        updatedCategoryGroupWiseSplit[groupName][rowIndex].split[
          splitIndex
        ].ratio = newValue;
      } else {
        // Update whole column
        updatedCategoryGroupWiseSplit[groupName].forEach((row) => {
          if (
            !row.is_applicable_for_rental_prop &&
            row.split[splitIndex].business_type === SplitBusinessType.RENTAL
          ) {
            return;
          }
          row.split[splitIndex].ratio = newValue;
        });
        headersValue[groupName][0].split[splitIndex].ratio = newValue;
      }
    } else {
      // vehicle case
      if (rowIndex !== -1) {
        // Update one cell
        updatedCategoryGroupWiseSplit[groupName][
          vehicleIndex
        ].category_wise_info[rowIndex].split[splitIndex].ratio = newValue;
      } else {
        // Update whole column
        updatedCategoryGroupWiseSplit[groupName][
          vehicleIndex
        ].category_wise_info.forEach((row) => {
          row.split[splitIndex].ratio = newValue;
        });
        headersValue[groupName][vehicleIndex].category_wise_info[0].split[
          splitIndex
        ].ratio = newValue;
      }
    }
    setCategoryGroupWiseDeductionsSplit(updatedCategoryGroupWiseSplit);
    setCategoryGroupHeader(headersValue);
  };

  const getCellColor = (index: number) => {
    return colors[index % colors.length];
  };

  const [
    categoryGroupWiseDeductionsSplit,
    setCategoryGroupWiseDeductionsSplit,
  ] = useState(() => deductionsGroup);

  useEffect(() => {
    setCategoryGroupWiseDeductionsSplit(deductionsGroup);
    setCategoryGroupHeader(getGroupsHeader());
  }, [deductionsGroup]);

  const postCpaData = async () => {
    try {
      setLoading(true);
      await postDistributeDeductionsSplit({
        fly_user_id: userId,
        year: activeYear,
        allocation: categoryGroupWiseDeductionsSplit,
      });
      dispatch(fetchDistributeDeductions(userId, activeYear));
      notify('Updated data successfully', NotificationType.success);
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const isSplitInvalid = (category: CategoryDeductionsSplit) => {
    if (
      category.split.some(
        (item, index) => item.ratio > category.max_split[index],
      )
    ) {
      return true;
    }
    return (
      category.split.map((s) => s.ratio).reduce((p, c) => p + c, 0) !== 100
    );
  };

  const isAnySplitInvalid = useCallback(() => {
    return groupOrder.some((groupName) => {
      if (
        groupName !== DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION
      ) {
        return categoryGroupWiseDeductionsSplit[groupName].some((category) =>
          isSplitInvalid(category),
        );
      }
      return categoryGroupWiseDeductionsSplit[groupName].some((vehicle) =>
        vehicle.category_wise_info.some((category) =>
          isSplitInvalid(category),
        ),
      );
    });
  }, [groupOrder, categoryGroupWiseDeductionsSplit]);

  const isUpdateButtonDisabled = useMemo(() => {
    return (
      selectedAuthor !== DeductionsDistributedByAuthor.CPA ||
      loading ||
      !dataLoaded ||
      isAnySplitInvalid()
    );
  }, [selectedAuthor, isAnySplitInvalid, loading, dataLoaded]);

  const isEditDisabled = selectedAuthor !== DeductionsDistributedByAuthor.CPA;

  return (
    <DistributeDeductionsContext.Provider
      value={{
        selectedAuthor,
        setSelectedAuthor,
        business_info: distributedDeductions.business_info,
        getCellColor,
        categoryGroupWiseDeductionsSplit,
        onChangeSplitValue,
        groupOrder,
        categoryGroupHeader,
        round,
        loading,
        dataLoaded,
        isUpdateButtonDisabled,
        postCpaData,
        isEditDisabled,
        hasUserSubmitted,
        querySent,
        seperateOffice,
      }}>
      {children}
    </DistributeDeductionsContext.Provider>
  );
};

export const useDistributDeductions = () => {
  const value = useContext(DistributeDeductionsContext);
  return value;
};
