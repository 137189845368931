import React, {ComponentProps, useEffect} from 'react';
import {Divider, Typography} from '@mui/material';
import {StatesInfo} from 'src/appApi.types';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import SubSectionBox from '../../common/SubSectionBox';
import {useDispatch, useSelector} from 'react-redux';
import {selectDocuments} from 'src/store/documents/documents.selector';
import Skeleton from 'src/icons/Skeleton';
import StatesWithIncome from './StatesWithIncome';
import StatesTaxFilingCategory from './StatesTaxFilingCategory';
import {selectTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.selector';
import {fetchTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.actions';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import DrivingLicense from './DrivingLicense';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const StatesTaxFilingInformation = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const {loaded, documents} = useSelector(selectDocuments);
  const {
    loaded: statesInfoLoaded,
    statesLivedInfo: statesTaxesInfo,
    businessStateReturnInfo,
    isDrivingLicenseRequired,
  } = useSelector(selectTaxProfileStatesInfo);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const isLoading = !statesInfoLoaded || !loaded;

  useEffect(() => {
    dispatch(fetchTaxProfileStatesInfo(userId, activeYear));
  }, [userId, activeYear]);

  const getState = (row: StatesInfo) => {
    const {is_current_address, state} = row;
    if (is_current_address) {
      return `${state} (Present address)`;
    }
    return state;
  };

  const livedCount = (row: StatesInfo) => {
    if (row.lived_count) {
      return `${row.lived_count} days`;
    }
    return row.lived_count;
  };

  const stateLivedKeys: ComponentProps<typeof KeyValueTable>['keys'] = [
    {
      getValue: getState,
    },
    {
      getValue: livedCount,
      onClick: (rowData) => {
        dispatch(setAppState(AppReducerStates.statesInfoDrawer, true));
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      textStyle: {
        fontWeight: 600,
      },
    },
  ];

  const statesWithIncomeDocs = isLoading
    ? []
    : statesTaxesInfo.filter((state) => state.related_income_forms.length > 0);
  const statesLivedInfo = isLoading
    ? []
    : statesTaxesInfo.filter((state) => state.lived_count != null);

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const drivingLicenseTaxForms = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.DRIVING_LICENSE,
  );

  const selfDLTaxform = drivingLicenseTaxForms.find(
    (tf) => !tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );
  const spouseDLTaxform = drivingLicenseTaxForms.find(
    (tf) => tf.formData[TaxFormFieldIds.IS_SPOUSE],
  );

  return (
    <SubSectionBox
      name="State tax filing information"
      taxprofileSubsection={TaxProfileSubsection.StatesTaxFilingInformation}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <Typography
            style={{
              fontSize: 13,
              color: themmeColor.black60,
              marginBottom: 8,
            }}>
            States lived in {activeYear}
          </Typography>
          {isLoading ? (
            <Skeleton width={'100%'} height={'20vh'} />
          ) : (
            <KeyValueTable keys={stateLivedKeys} data={statesLivedInfo} />
          )}
          <Typography
            style={{
              fontSize: 13,
              color: themmeColor.black60,
              marginTop: 20,
              marginBottom: 8,
            }}>
            States with income in {activeYear}
          </Typography>
          <StatesWithIncome
            statesWithIncomeDocs={statesWithIncomeDocs}
            isLoading={isLoading}
            documents={documents}
          />
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <StatesTaxFilingCategory
            stateReturnsInfo={statesTaxesInfo}
            businessStateReturnInfo={businessStateReturnInfo}
            isLoading={isLoading}
            getState={getState}
          />
        </div>
      </div>
      <Divider style={{marginTop: 20}} />
      <div>
        <Typography
          style={{
            color: themmeColor.black60,
            marginTop: 20,
            marginBottom: 8,
            fontWeight: 600,
          }}>
          Driving License Information
        </Typography>
        {isDrivingLicenseRequired && (
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                  }
            }>
            <div
              style={
                summaryVariant ===
                TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                  ? {}
                  : {flex: 0.48}
              }>
              <DrivingLicense taxForm={selfDLTaxform} isSpouse={false} />
            </div>
            <div
              style={
                summaryVariant ===
                TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                  ? {marginTop: 20}
                  : {flex: 0.48}
              }>
              <DrivingLicense taxForm={spouseDLTaxform} isSpouse />
            </div>
          </div>
        )}
      </div>
    </SubSectionBox>
  );
};

export default StatesTaxFilingInformation;
