import {Tab, Tabs} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
interface TabProps {
  label?: string;
  value: any;
}

interface TabsProps {
  value: any;
  onChange: (newValue: any) => void;
  options: TabProps[];
}

const DSTabs = ({value, onChange, options}: TabsProps) => {
  return (
    <Tabs
      // @ts-ignore
      indicatorColor="transparent"
      value={value}
      onChange={(event, newValue) => onChange(newValue)}>
      {options.map((option) => {
        const isSelected = option.value === value;
        return (
          <Tab
            label={option.label ?? option.value}
            value={option.value}
            style={{
              textTransform: 'none',
              backgroundColor: isSelected
                ? themmeColor.black
                : themmeColor.white,
              color: !isSelected ? themmeColor.black : themmeColor.white,
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default DSTabs;
