import {Typography} from '@mui/material';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import KeyValueTable, {
  TABLE_DATA_TYPE,
} from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import useDeductions from 'src/CpaCenterV2/hooks/useDeductions';
import {DeductionAmountSource} from 'src/store/deductionSummary/deductionSummary.reducer';
import {Description} from '@mui/icons-material';

interface CommonDeductionTableProps {
  taxProfileSubsection: TaxProfileSubsection;
}

const getHeadingKey = (index: number) => {
  return `heading-${index}`;
};

const generateHeadingRow = (headingValues: string[]) => {
  const columnNames = {};
  headingValues.forEach((h, index) => {
    columnNames[getHeadingKey(index)] = h;
  });
  return {
    type: TABLE_DATA_TYPE.HEADING,
    ...headingValues.reduce((obj, val, index) => {
      return {
        ...obj,
        ...columnNames,
      };
    }, {}),
  };
};

const CommonDeductionTable = ({
  taxProfileSubsection,
}: CommonDeductionTableProps) => {
  const {getFields} = useDeductions();

  const data = getFields(taxProfileSubsection);

  const showPreStartupExpensesCol = data.some(
    (rowData) => rowData.value_pre_startup != null,
  );

  const tableData = [
    ...(showPreStartupExpensesCol
      ? [generateHeadingRow([' ', 'post startup', 'pre startup'])]
      : []),
    ...data,
  ];

  const tableKeys = [
    {
      getValue: (row, index) => {
        if (row.type === TABLE_DATA_TYPE.HEADING) {
          return row[getHeadingKey(index)];
        }
        return row.label;
      },
      getIcon: (row, index) => {
        if (row.source === DeductionAmountSource.TAX_FORMS) {
          return <Description />;
        }
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    {
      getValue: (row, index) => {
        if (row.type === TABLE_DATA_TYPE.HEADING) {
          return row[getHeadingKey(index)];
        }
        return row.value;
      },
      cellStyle: {display: 'flex', justifyContent: 'flex-end'},
      textStyle: {fontWeight: 600},
    },
    ...(showPreStartupExpensesCol
      ? [
          {
            getValue: (row, index) => {
              if (row.type === TABLE_DATA_TYPE.HEADING) {
                return row[getHeadingKey(index)];
              }
              return row.value_pre_startup;
            },
            cellStyle: {display: 'flex', justifyContent: 'flex-end'},
            textStyle: {fontWeight: 600},
          },
        ]
      : []),
  ];

  return (
    <>
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.black60,
          marginBottom: 4,
        }}>
        Applicable deductions
      </Typography>
      <KeyValueTable data={tableData} keys={tableKeys} />
    </>
  );
};

export default CommonDeductionTable;
