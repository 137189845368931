import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Pagination as RaPagination} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  spacer: {
    flex: 'none',
  },
}));

export const ExpensePagination = (props) => {
  const classes = useStyles();
  return (
    <RaPagination
      {...props}
      rowsPerPageOptions={[25, 50, 100, 250, 500]}
      classes={classes}
    />
  );
};
