import React, {useEffect} from 'react';
// import { google } from 'googleapis';
import {Document, Page, pdfjs} from 'react-pdf';
import {createHighlights} from '../utils';
import useAnnotations from '../useAnnotations';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Annotations() {
  const {
    jobId,
    isLoading,
    currentActiveRow,
    pdfSignedURl,
    currentPageOcrDataJson,
    currentPageFinalAnnotationSheetData,
    isLastPage,
    totalPages,
    currentPage,
    onDocumentLoadSuccess,
    onClickPrevPage,
    onClickNextPage,
  } = useAnnotations({isViewOnly: true});

  useEffect(() => {
    const highlightContainer = document.getElementById(
      `highlight-container-${currentPage}`,
    );
    createHighlights(highlightContainer, currentPageOcrDataJson);
  }, [currentPage, currentPageOcrDataJson]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{padding: '16px'}}>
      <h1>Annotations</h1>
      <h3>Job: {jobId}</h3>
      <h2>Current Doc Title: {currentActiveRow[1]}</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100vw',
        }}>
        <div
          style={{
            position: 'relative',
            width: '80vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            id={`highlight-container-${currentPage}`}
            style={{position: 'relative'}}>
            {pdfSignedURl && (
              <>
                <Document
                  file={pdfSignedURl}
                  onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={currentPage} width={500} />
                </Document>
                <p>
                  Page: {currentPage}/{totalPages}
                </p>
                {currentPage > 1 && (
                  <button onClick={onClickPrevPage}>Prev</button>
                )}
                {!isLastPage && (
                  <button onClick={onClickNextPage}>Next</button>
                )}
              </>
            )}
          </div>
        </div>
        {/* key value input fields */}
        <div
          id="validation-container"
          style={{
            height: '100vh',
            width: '20%',
            overflow: 'scroll',
            border: '1ps solid black',
          }}>
          {currentPageOcrDataJson.map(
            (item: {key_text: string; value_text: string; id: string}) => (
              <div
                style={{
                  padding: '1rem',
                  margin: '0.5rem',
                  cursor: 'pointer',
                  backgroundColor: '#e5e5e5',
                }}
                key={item.id}>
                {item.key_text}
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    backgroundColor: 'grey',
                    color: 'white',
                  }}>
                  {Object.keys(currentPageFinalAnnotationSheetData).length &&
                    item?.id &&
                    currentPageFinalAnnotationSheetData[item.id] &&
                    currentPageFinalAnnotationSheetData[item.id][
                      currentPageFinalAnnotationSheetData[item.id]?.length - 1
                    ]}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
}
