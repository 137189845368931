import * as React from 'react';
import {Fragment, useState} from 'react';
import {Button, Confirm, useMutation, useNotify} from 'react-admin';

const SingleExpenseNotifyButton = ({txnId, notifSent, formData}) => {
  const {classification_status, expense_category, state} = formData;
  var req_info = {};
  // dynamically set request info based on classification_status
  if (classification_status == 'PS') {
    req_info = {
      type: 'possible_deduction',
      resource: 'notification',
      payload: {txn_id: txnId, expense_category: expense_category},
    };
  } else if (classification_status == 'DE') {
    req_info = {
      type: 'certain_deduction',
      resource: 'notification',
      payload: {txn_id: txnId, expense_category: expense_category},
    };
  } else if (classification_status == 'ND') {
    req_info = {
      type: 'non_deduction',
      resource: 'notification',
      payload: {txn_id: txnId, expense_category: expense_category},
    };
  }
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(
    notifSent ||
      (classification_status != 'PS' &&
        classification_status != 'DE' &&
        classification_status != 'ND'),
  );
  const notify = useNotify();
  const [singleExpenseNotify, {loading}] = useMutation(req_info, {
    onSuccess: () => {
      setDisabled(true);
      notify(`Expense notification sent!`);
    },
    onFailure: (error) => {
      console.log(error);
      notify(
        `Expense notification not sent: ${error.body['display_msg']}`,
        'warning',
      );
    },
  });
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    singleExpenseNotify();
    setOpen(false);
  };

  // TODO in the confirm dialog show details
  return (
    <Fragment>
      <Button label="Notify User" onClick={handleClick} disabled={disabled} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Schedule Ongoing Notification"
        content="Are you sure you want to schedule ongoing notification to the user for this expense?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default SingleExpenseNotifyButton;
