import React, {useMemo} from 'react';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {usePartnershipUserList} from '../usePartnershipUserList';
import {PartnershipFiltersConfig} from '../PartnershipUserList.constants';
import {
  PartnershipFilters,
  PartnshipFilterLocation,
} from '../PartnershipUserList.types';
import {Form} from 'src/DesignSystem/Form/Form';
import {selectPartners} from 'src/store/partners/partners.selector';
import {useSelector} from 'react-redux';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';

interface PartnershipUserListFilterPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const PartnershipUserListFilterPopup = ({
  isOpen,
  onClose,
}: PartnershipUserListFilterPopupProps) => {
  const {filterCopy, updateFilterCopy, applyFiltersFromCopy} =
    usePartnershipUserList();

  const {partners} = useSelector(selectPartners);

  const popupFilters = useMemo(() => {
    const filters = PartnershipFiltersConfig.filter(
      (filter) => filter.location === PartnshipFilterLocation.filter_pop_up,
    );
    const partnerOptions = partners.map((partner) => ({
      label: partner.partner_name,
      value: partner.id,
    }));
    return filters.map((filter) => {
      if (filter.filter_name === PartnershipFilters.partner) {
        return {...filter, options: partnerOptions};
      }
      return {
        ...filter,
        options: filter.options?.map((op) => ({label: op, value: op})),
      };
    });
  }, [partners]);

  return (
    <PopUp
      primaryButtonTitle="Apply"
      primaryButtonOnClick={() => {
        applyFiltersFromCopy();
        onClose();
      }}
      secondaryButtonTitle="Close"
      secondaryButtonOnClick={onClose}
      isOpen={isOpen}
      onClose={onClose}
      style={{width: 600}}>
      <Text
        type={TEXT_TYPES.H6}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text="Search Filters"
        containerStyle={{marginBottom: 12}}
      />
      <div style={{maxHeight: '50vh', overflowY: 'auto'}}>
        {popupFilters.map((filter) => {
          return (
            <div style={{marginBottom: 12}}>
              <Form.CheckField
                options={filter.options ?? []}
                value={filterCopy[filter.filter_name] ?? []}
                onChangeValue={(newValue) =>
                  updateFilterCopy(filter.filter_name, newValue)
                }
                title={filter.filter_name}
                allowEmpty
              />
            </div>
          );
        })}
      </div>
    </PopUp>
  );
};

export default PartnershipUserListFilterPopup;
