import React from 'react';
import {TAX_FILING_STATUS, themmeColor} from 'src/constants/constants';
import {TAX_RETURN_STATUS_LABELS} from './cpaList.utils';

interface Props {
  isVisible: boolean;
  dataArray: {
    business_name: string;
    status: TAX_FILING_STATUS;
  }[];
}

const AllReturnStatusBox = ({isVisible, dataArray}: Props) => {
  return isVisible ? (
    <div
      style={{
        borderRadius: 12,
        borderWidth: 1,
        backgroundColor: themmeColor.backgroundOffWhite,
        borderColor: themmeColor.black70,
        position: 'absolute',
        left: '30%',
        top: 0,
        zIndex: 1000,
        width: 500,
        maxHeight: 500,
        boxShadow: '0 0 12px rgba(0, 0, 0, 0.4)',
        overflow: 'hidden',
      }}>
      {dataArray.map((data) => {
        const currentStatus =
          TAX_RETURN_STATUS_LABELS[
            data.status ?? TAX_FILING_STATUS.NOT_INITIATED
          ];
        return (
          <div
            style={{
              backgroundColor: themmeColor.white,
              padding: 16,
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: themmeColor.offWhite,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                flex: 0.45,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: 13,
                fontWeight: 600,
              }}>
              {data.business_name}
            </div>

            <div
              style={{
                backgroundColor: currentStatus.backColor,
                borderRadius: 24,
                flex: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: currentStatus.textColor,
                fontSize: 13,
                fontWeight: 600,
              }}>
              {currentStatus.label}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default AllReturnStatusBox;
