import React from 'react';

interface QueriesContextProps {
  fetchQueriesList: () => Promise<any>;
}

// @ts-ignore
const QueriesContext = React.createContext<QueriesContextProps>(null);

export default QueriesContext;
