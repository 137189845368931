import React, {ComponentProps} from 'react';
import SimpleLabel from '../CellsInternal/SimpleLabel';
import Cell from './Cell';
import PercentageInput from '../CellsInternal/PercentageInput';
import TotalCell from './TotalCell';
import {Typography} from '@mui/material';

interface DistributionCellProps
  extends Omit<ComponentProps<typeof Cell>, 'children'> {
  labelValue: ComponentProps<typeof SimpleLabel>['value'];
  percentage: ComponentProps<typeof PercentageInput>['value'];
  disabled?: boolean;
  onChangePercent: ComponentProps<typeof PercentageInput>['onChange'];
  getReadValue?: ComponentProps<typeof SimpleLabel>['getReadValue'];
  notApplicable?: boolean;
  commonTextStyle?: React.CSSProperties;
  labelContainerStyle?: React.CSSProperties;
  percentContainerStyle?: React.CSSProperties;
  labelTextStyle?: React.CSSProperties;
  percentageTextStyle?: React.CSSProperties;
  percentageBorderColor?: string;
  subLabel?: string;
  subLabelStyle?: React.CSSProperties;
}

const DistributionCell = ({
  backgroundColor,
  labelValue,
  getReadValue,
  percentage,
  onChangePercent,
  notApplicable,
  disabled = false,
  cellStyle = {},
  commonTextStyle = {},
  labelContainerStyle = {},
  percentContainerStyle = {},
  labelTextStyle = {},
  percentageTextStyle = {},
  percentageBorderColor,
  subLabel,
  subLabelStyle = {},
}: DistributionCellProps) => {
  return (
    <Cell
      cellStyle={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        ...cellStyle,
      }}
      backgroundColor={backgroundColor}>
      <div>
        <SimpleLabel
          textStyle={{...commonTextStyle, ...labelTextStyle}}
          value={notApplicable ? 'NA' : labelValue}
          getReadValue={getReadValue}
          style={labelContainerStyle}
        />
        {subLabel && (
          <Typography style={{fontSize: 13, ...subLabelStyle}}>
            {subLabel}
          </Typography>
        )}
      </div>

      <PercentageInput
        textStyle={{...commonTextStyle, ...percentageTextStyle}}
        value={percentage}
        onChange={onChangePercent}
        style={percentContainerStyle}
        notApplicable={notApplicable}
        disabled={disabled}
        borderColor={percentageBorderColor}
      />
    </Cell>
  );
};

export default DistributionCell;
