import {isNull} from 'lodash';
import React from 'react';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';

interface BusinessBalanceSheetDocsProps {
  startDoc: ReduxDocumentType | null;
  endDoc: ReduxDocumentType | null;
}

const BusinessBalanceSheetDocs = ({
  startDoc,
  endDoc,
}: BusinessBalanceSheetDocsProps) => {
  const {navigateToDocumentReview} = usePartnershipNavigation();
  return !isNull(startDoc) || !isNull(endDoc) ? (
    <div style={{marginBlock: 12}}>
      <Text type={TEXT_TYPES.BASE} text={'Related documents'} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginTop: 4,
        }}>
        {!isNull(startDoc) ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}>
            <Text type={TEXT_TYPES.S} text={'Start'} />
            <DocumentPointer
              doc={startDoc}
              dontShowStatus
              border
              onDocumentClick={() => navigateToDocumentReview(startDoc.docId)}
            />
          </div>
        ) : null}
        {!isNull(endDoc) ? (
          <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
            <Text type={TEXT_TYPES.S} text={'End'} />
            <DocumentPointer
              doc={endDoc}
              dontShowStatus
              border
              onDocumentClick={() => navigateToDocumentReview(endDoc.docId)}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default BusinessBalanceSheetDocs;
