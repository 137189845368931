import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {markNotHighValueDeductions} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import IRSGroupedTransactionList from 'src/CpaCenterV2/common/TransactionsList/IRSGroupedTransactionList';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import BackIcon from 'src/icons/BackIcon';
import CrossIcon from 'src/icons/CrossIcon';
import WarningIcon from 'src/icons/WarningIcon';
import {setHighValueDeductionsDrawer} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';
import {selectAppReducerState} from 'src/store/app/app.selector';
import {fetchHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.actions';
import {selectHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.selector';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface TransactionsViewProps {
  selectedTransactionIds: Set<number>;
  setSelectedTransactionIds: (newSet: Set<number>) => void;
  onAskQuery: () => void;
  onLooksGood: () => void;
  isLoading: boolean;
}

const TransactionsView = ({
  selectedTransactionIds,
  setSelectedTransactionIds,
  onAskQuery,
  onLooksGood,
  isLoading,
}: TransactionsViewProps) => {
  const dispatch = useDispatch();
  const {deductions} = useSelector(selectHighValueDeductions);
  return (
    <div
      style={{
        padding: 16,
        paddingRight: 16,
        paddingBottom: 0,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 12, display: 'flex'}}>
            <WarningIcon />
          </div>
          <Typography style={{fontWeight: 600, fontSize: 20}}>
            Deductions with high value
          </Typography>
        </div>
        <div onClick={() => dispatch(setHighValueDeductionsDrawer(false))}>
          <CrossIcon />
        </div>
      </div>
      <Typography
        style={{fontSize: 14, color: themmeColor.grey, marginTop: 16}}>
        Some of the deduction value seems to be higher than normal. You can
        raise the query with the user to understand whether they have done any
        mistakes!
      </Typography>
      <div style={{height: '58vh', overflowY: 'auto', marginTop: 16}}>
        <IRSGroupedTransactionList
          txns={deductions}
          selectionMode={IRSGroupedTransactionList.SelectionMode.MULTI_SELECT}
          selectedTransactionIds={selectedTransactionIds}
          setSelectedTransactionIds={setSelectedTransactionIds}
          disabled={isLoading}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 16,
          paddingBottom: 0,
          flex: 1,
        }}>
        <DSButton
          type="secondary"
          text="Looks good"
          style={{marginRight: 10, flex: 1, height: 48}}
          onClick={onLooksGood}
          disabled={selectedTransactionIds.size === 0 || isLoading}
        />
        <DSButton
          style={{flex: 1, height: 48}}
          type="primary"
          text="Ask query"
          onClick={onAskQuery}
          disabled={selectedTransactionIds.size === 0 || isLoading}
        />
      </div>
    </div>
  );
};

interface AskQueryViewProps {
  setIsAskQuery: (newState: boolean) => void;
  selectedTransactionIds: Set<number>;
  message: string;
  setMessage: (newMessage: string) => void;
  isLoading: boolean;
  onPostQuery: (isDraft?: boolean) => void;
}

const AskQueryView = ({
  setIsAskQuery,
  selectedTransactionIds,
  message,
  setMessage,
  isLoading = false,
  onPostQuery,
}: AskQueryViewProps) => {
  const dispatch = useDispatch();
  return (
    <div style={{padding: 16}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div
            style={{display: 'flex', marginRight: 12}}
            onClick={() => setIsAskQuery(false)}>
            <BackIcon />
          </div>
          <Typography style={{fontSize: 20, fontWeight: 600}}>
            {selectedTransactionIds.size} deductions selected
          </Typography>
        </div>
        <div onClick={() => dispatch(setHighValueDeductionsDrawer(false))}>
          <CrossIcon />
        </div>
      </div>
      <Typography style={{marginBottom: 16, marginTop: 16}}>
        Ask the situation and reason for claiming this high value deduction to
        confirm whether they have done it right!
      </Typography>

      <div style={{marginBlock: 12}}>
        <MessageTextBox
          message={message}
          setMessage={setMessage}
          isLoading={isLoading}
          placeholder={
            'Describe the issues properly so that users understand what to do!'
          }
          onSend={() => onPostQuery(false)}
          onDraft={() => onPostQuery(true)}
          inputMode={MessageTextBox.MessageTextBoxInputMode.SEND_MESSAGE}
          height={200}
        />
      </div>
    </div>
  );
};

const HighValueDeductionsDrawer = () => {
  const isVisible = useSelector((state) =>
    selectAppReducerState(state, AppReducerStates.highValueDeductionsDrawer),
  );
  const {userId} = useCurrentUserId();

  const [isAskQueryView, setIsAskQuery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {activeYear} = useActiveYear();

  const [message, setMessage] = useState('');

  const [selectedTransactionIds, setSelectedTransactionIds] = useState(
    () => new Set<number>(),
  );

  const {createHighValueDeductionQuery} = useQueryAction();

  const dispatch = useDispatch();
  const {notify} = useNotify();

  const openQueryInputView = () => {
    setIsAskQuery(true);
  };

  const onReset = async () => {
    setMessage('');
    setSelectedTransactionIds(new Set());
    setIsAskQuery(false);
    await dispatch(fetchHighValueDeductions(userId, activeYear));
    await dispatch(fetchQueries({userId, year: activeYear}));
  };

  const blackListTxn = async () => {
    try {
      setIsLoading(true);
      await markNotHighValueDeductions({
        user_id: userId,
        txn_ids: [...selectedTransactionIds],
      });
      notify('Marked as not High Value deduction', NotificationType.success);
      await onReset();
    } catch (e) {
      notify('Failed to mark not high value', NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onPostQuery = async (isDraft = false) => {
    try {
      setIsLoading(true);
      await createHighValueDeductionQuery({
        message,
        txn_ids: [...selectedTransactionIds],
        isDraft,
      });
      await onReset();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={400}
      height={'85vh'}
      isOpen={isVisible}
      direction="left">
      {!isAskQueryView ? (
        <TransactionsView
          selectedTransactionIds={selectedTransactionIds}
          setSelectedTransactionIds={setSelectedTransactionIds}
          isLoading={isLoading}
          onAskQuery={openQueryInputView}
          onLooksGood={blackListTxn}
        />
      ) : (
        <AskQueryView
          setIsAskQuery={setIsAskQuery}
          message={message}
          selectedTransactionIds={selectedTransactionIds}
          setMessage={setMessage}
          onPostQuery={onPostQuery}
          isLoading={isLoading}
        />
      )}
    </Drawer>
  );
};

export default HighValueDeductionsDrawer;
