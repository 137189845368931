import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialButton} from '@material-ui/core/Button';

import {Fragment, useState} from 'react';
import {
  Button,
  useMutation,
  useNotify,
  useUnselectAll,
  useListContext,
  GET_MANY,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  redButton: {
    color: 'white',
    backgroundColor: 'red',
  },
}));

const BulkNotifyFormDialog = ({selectedIds, open, onClose}) => {
  const classes = useStyles();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const {data: storedData, resource} = useListContext();
  const [refreshTxns, {}] = useMutation({
    type: GET_MANY,
    resource: resource,
    payload: {ids: selectedIds},
  });
  const [whenToSend, setWhenToSend] = useState('schedule');
  const [bulkNotifyNow, {loadingNow}] = useMutation(
    {
      type: 'bulk_notify',
      resource: 'notification',
      payload: {txn_ids: selectedIds, realtime: true},
    },
    {
      onSuccess: () => {
        refreshTxns();
        unselectAll(resource);
        notify('Bulk notify now successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Bulk notify now failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );
  const [bulkNotifySchedule, {loadingSchedule}] = useMutation(
    {
      type: 'bulk_notify',
      resource: 'notification',
      payload: {txn_ids: selectedIds, realtime: false},
    },
    {
      onSuccess: () => {
        refreshTxns();
        unselectAll(resource);
        notify('Bulk notify schedule successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Bulk notify schedule failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  const handleConfirm = () => {
    if (whenToSend === 'now') {
      bulkNotifyNow();
    } else {
      bulkNotifySchedule();
    }
    onClose();
  };
  const selectedExpenseNames = selectedIds.map((id, i) => {
    let merchantName =
      id in storedData
        ? storedData[id]['merchant_name'] +
          ' - ' +
          storedData[id]['classification_status']
        : null;
    return '(' + String(i + 1) + ') ' + merchantName;
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Bulk Notify {selectedIds.length} Selected Expenses
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br />
          Selected expenses: {selectedExpenseNames.join(', ')}. <br />
        </DialogContentText>
        <RadioGroup
          aria-label="type"
          name="when"
          value={whenToSend}
          onChange={(event, newValue) => {
            if (newValue != null) {
              setWhenToSend(newValue);
            }
          }}>
          <FormControlLabel value="now" control={<Radio />} label="Now" />
          <FormControlLabel
            value="schedule"
            control={<Radio />}
            label="Schedule"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={onClose}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={handleConfirm}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirm
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const BulkNotifyButton = ({selectedIds, className}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      <Button
        size="medium"
        className={`${className} ${classes.redButton}`}
        label="Notify"
        onClick={handleClick}
        color="primary"
        variant="contained"
      />
      <BulkNotifyFormDialog
        selectedIds={selectedIds}
        open={open}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkNotifyButton;
