import React, {ComponentProps} from 'react';
import PartnersListHeader from './components/PartnersListHeader';
import Table from 'src/DesignSystem/Table/Table';
import {useDispatch, useSelector} from 'react-redux';
import {selectPartners} from 'src/store/partners/partners.selector';
import ChooseCPA from '../PartnershipUserList/components/ChooseCPA';
import {assignPocToPartner} from 'src/appApi';
import {fetchPartners} from 'src/store/partners/partners.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

const PartnersList = () => {
  const {partners} = useSelector(selectPartners);
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const columns: ComponentProps<typeof Table>['columns'] = [
    {field: 'id', headerName: 'Partner ID'},
    {field: 'partner_name', headerName: 'Partner name', width: 150},
    {
      field: 'flyfin_poc_email',
      headerName: 'FlyFin POC Email',
      width: 150,
      editable: true,
      renderEditCell: ChooseCPA,
    },
    {field: 'partner_poc_name', headerName: 'Partner POC name', width: 150},
    {field: 'partner_poc_email', headerName: 'Partner POC Email', width: 200},
    {field: 'tax_return_count', headerName: 'Tax Return count', width: 150},
  ];
  return (
    <>
      <PartnersListHeader />
      <Table
        style={{minHeight: 600}}
        columns={columns}
        rows={partners}
        rowsPerPageOptions={[10, 20, 50, 100]}
        processRowUpdate={async (newRow, oldRow) => {
          try {
            if (newRow['flyfin_poc_email'] !== oldRow['flyfin_poc_email']) {
              await assignPocToPartner(
                newRow['id'],
                newRow['flyfin_poc_email'],
              );
              dispatch(fetchPartners());
              notify('Assigned poc', NotificationType.success);
            }
            return newRow;
          } catch (e) {
            notify(`Something went wrong ${e}`, NotificationType.error);
          }
          return oldRow;
        }}
        experimentalFeatures={{
          newEditingApi: true,
        }}
      />
    </>
  );
};

export default PartnersList;
