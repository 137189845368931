import {Typography} from '@mui/material';
import axios from 'axios';
import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {syncTaxReturnWithCCH} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {downloadDrakeFile} from 'src/CpaCenterV2/CpaCenterV2.utils';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import CrossIcon from 'src/icons/CrossIcon';
import TickIcon from 'src/icons/TickIcon';
import WarningIcon from 'src/icons/WarningIcon';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';
import {selectAppReducerState} from 'src/store/app/app.selector';
import {BankDetail} from 'src/store/bankDetails/bankDetails.reducer';
import {selectBankDetails} from 'src/store/bankDetails/bankDetails.selector';
import {ReduxStateType} from 'src/store/store';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {selectUser} from 'src/store/user/user.selector';

const EfilePaymentDetailsDrawer = () => {
  const dispatch = useDispatch();
  const {isVisible, returnId, returnType} = useSelector(
    (state: ReduxStateType) =>
      selectAppReducerState(state, AppReducerStates.paymentDetailsDrawer),
  );
  const {dateOfDebit} = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const {bankDetails} = useSelector(selectBankDetails);

  const {taxReturns} = useSelector(selectTaxReturnsReducer);

  const currentTaxReturn = taxReturns.find(
    (taxReturn) =>
      taxReturn.return_id === returnId && taxReturn.return_type === returnType,
  );

  const currentReturnBankDetails = bankDetails.filter(
    (bank) => bank.return_id === returnId && bank.return_type === returnType,
  );

  const onClose = () => {
    dispatch(
      setAppState(AppReducerStates.paymentDetailsDrawer, {
        isVisible: false,
        returnId: -1,
        returnType: null,
      }),
    );
  };

  const getFieldsMappingForAccountDetails = (bankDetails: BankDetail) => {
    const accountDetails = [
      {
        name: 'Account number',
        value: bankDetails.account_number,
      },
      {
        name: 'Routing number',
        value: bankDetails.routing_number,
      },
      {
        name: 'Account type',
        value: bankDetails.account_type,
      },
      {
        name: 'Phone number',
        value: bankDetails.phone_number,
      },
      {
        name: 'Synced with CCH',
        value: bankDetails.cch_sync_status,
      },
      {
        name: 'Date of Debit',
        value: dateOfDebit,
      },
    ];
    return accountDetails;
  };

  const isSyncedwithCCH = currentReturnBankDetails.every(
    (bankDetail) => bankDetail.cch_sync_status,
  );

  const hasAtLeastLinkedOneBank = currentReturnBankDetails.length > 0;

  const taxRefundsData = useMemo(() => {
    if (!currentTaxReturn || !currentTaxReturn?.refund_and_due?.refunds)
      return [];
    const logs = [];
    currentTaxReturn.refund_and_due.refunds.log.forEach((refund) => {
      logs.push({
        name: refund.title,
        value: CurrencyNumberFormat(refund.amount),
      });
    });
    return logs;
  }, [currentTaxReturn]);

  const taxDueData = useMemo(() => {
    if (!currentTaxReturn || !currentTaxReturn?.refund_and_due?.dues)
      return [];
    const logs = [];
    currentTaxReturn.refund_and_due.dues.log.forEach((refund) => {
      logs.push({
        name: refund.title,
        value: CurrencyNumberFormat(refund.amount),
      });
    });
    logs.push({
      name: 'Payment method',
      value: currentTaxReturn.refund_and_due.dues.payment_method,
    });
    return logs;
  }, [currentTaxReturn]);

  const onSendToCCH = async () => {
    try {
      setIsLoading(true);
      await syncTaxReturnWithCCH({return_id: returnId});
      await dispatch(fetchTaxReturns(userId, activeYear));
      notify('Synced with CCH', NotificationType.success);
    } catch (e) {
      notify(`Failed to send to CCH ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadDrakeFile = async () => {
    try {
      setIsLoading(true);
      await downloadDrakeFile({
        userId,
        activeYear,
        returnType,
        returnId,
        config: {BANK_DETAILS: ['DD', 'PMT']},
      });
      notify('Downloading Drake file', NotificationType.success);
      await dispatch(fetchTaxReturns(userId, activeYear));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notify('Failed to download Drake file', NotificationType.error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
      }
    }
  };
  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={400}
      height={'85vh'}
      isOpen={isVisible}>
      <div style={{padding: 24, overflowY: 'auto'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 24,
          }}>
          <Typography style={{fontWeight: 600, fontSize: 20}}>
            User tax payment details
          </Typography>
          <div onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
        {currentReturnBankDetails.map((bankDetail, index) => (
          <DSAccordion name={`Account ${index + 1}`}>
            <KeyValueTable
              data={getFieldsMappingForAccountDetails(bankDetail)}
              keys={[
                {getValue: (row) => row.name},
                {
                  getValue: (row) => row.value,
                  cellStyle: {
                    justifyContent: 'flex-end',
                    display: 'flex',
                  },
                  textStyle: {fontWeight: 600},
                },
              ]}
            />
          </DSAccordion>
        ))}
        {hasAtLeastLinkedOneBank && (
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingBlock: 8,
              marginBlock: 12,
            }}
            borderColor={
              isSyncedwithCCH
                ? themmeColor.successGreen
                : themmeColor.flyfinYellow
            }
            backgroundColor={
              isSyncedwithCCH ? themmeColor.lightGreen : '#FFFDF0'
            }>
            <div style={{marginRight: 8, display: 'flex'}}>
              {isSyncedwithCCH ? <TickIcon /> : <WarningIcon />}
            </div>
            <Typography style={{fontSize: 13, fontWeight: 600}}>
              {isSyncedwithCCH ? 'Synced with CCH' : 'Not synced with CCH'}
            </Typography>
          </Box>
        )}
        {hasAtLeastLinkedOneBank && (
          <div>
            <DSButton
              type="primary"
              text="Download Drake file"
              onClick={onDownloadDrakeFile}
              disabled={isLoading}
              style={{width: '100%', paddingBlock: 12, marginBottom: 8}}
            />
            <DSButton
              type="secondary"
              text="Send to CCH"
              onClick={onSendToCCH}
              disabled={isSyncedwithCCH || isLoading}
              style={{width: '100%', paddingBlock: 12, marginBottom: 24}}
            />
          </div>
        )}
        {taxRefundsData.length > 0 && (
          <>
            <Typography style={{fontWeight: 600, marginBottom: 8}}>
              Tax refunds
            </Typography>
            <KeyValueTable
              data={taxRefundsData}
              keys={[
                {getValue: (row) => row.name},
                {
                  getValue: (row) => row.value,
                  cellStyle: {
                    justifyContent: 'flex-end',
                    display: 'flex',
                  },
                  textStyle: {fontWeight: 600},
                },
              ]}
            />
          </>
        )}
        {taxDueData.length > 1 && (
          <>
            <Typography
              style={{fontWeight: 600, marginBottom: 8, marginTop: 24}}>
              Tax dues
            </Typography>
            <KeyValueTable
              data={taxDueData}
              keys={[
                {getValue: (row) => row.name},
                {
                  getValue: (row) => row.value,
                  cellStyle: {
                    justifyContent: 'flex-end',
                    display: 'flex',
                  },
                  textStyle: {fontWeight: 600},
                },
              ]}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default EfilePaymentDetailsDrawer;
