const CURR_YEAR = new Date().getFullYear();
const PREV_YEAR = CURR_YEAR - 1;

const YEARS_ARRAY = [PREV_YEAR, CURR_YEAR];

export const BULK_TAX_PROFILE_POST_DATA = {
  years: YEARS_ARRAY,
  create_if_not_exist: true,
  questions_data: [
    {question_id: 40, answer: ['Filing taxes effortlessly']},
    {question_id: 23, answer: 'No'},
    {question_id: 27, answer: ["I don't"]},
    {question_id: 29, answer: 'Sometimes (2-4/month)'},
    {question_id: 30, answer: ['None of the above']},
    {question_id: 21, answer: ['Business owner']},
    {question_id: 22, answer: ['Bank Professional']},
    {question_id: 58, answer: 'MORE_THAN_75K'},
  ],
};

export enum APP_LEVELS {
  EnterName = 'EnterName',
  CardType = 'CardType',
  LinkMoreAccounts = 'LinkMoreAccounts',
  FetchingExpenses = 'FetchingExpenses',
  ClassificationComplete = 'ClassificationComplete',
  tabBarScreens = 'tabBarScreens',
}

export const TAX_REMINDER_TAX_PROFILE = {
  question_id: 43,
  years: YEARS_ARRAY,
  answer: 'No thanks',
};

export const MEANWHILE_QUESTIONS = [
  {
    question_id: 36,
    years: YEARS_ARRAY,
    answer: 'More than 3 years',
  },
  {
    question_id: 54,
    years: YEARS_ARRAY,
    answer: 'no',
  },
  {
    question_id: 5,
    years: YEARS_ARRAY,
    answer: 'Single',
  },
  {
    question_id: 39,
    years: [PREV_YEAR],
    answer: false,
  },
  {
    question_id: 11,
    years: YEARS_ARRAY,
    answer: ['Freelancing'],
  },
];

export const getTaxProfileJourneyData = (year) => ({
  years: [year],
  questions_data: [
    {
      question_id: 1,
      answer: {first_name_and_middle_initial: 'RDB', last_name: 'Account'},
    },
    {question_id: 2, answer: '213-82-1837'},
    {question_id: 62, answer: '(321) 312-3123'},
    {
      question_id: 3,
      answer: {
        number_and_street: 'ahsdusa',
        apartment_number: 'anksjdasnd',
        city: 'asdlkjnjaksnd',
        state: ['Arizona'],
        zip_code: '12321-3123',
      },
    },
    {question_id: 21, answer: ['Business owner']},
    {question_id: 22, answer: ['Bank Professional']},
    {
      question_id: 20,
      answer: {
        business_name: 'basudbk',
        is_your_business_registered: false,
        EIN: '',
      },
    },
    {question_id: 54, answer: 'no'},
    {question_id: 56, answer: false},
    {
      question_id: 57,
      answer: {
        number_and_street: '',
        apartment_number: '',
        city: '',
        state: [],
        zip_code: '',
      },
    },
    {question_id: 10, answer: ['Arizona']},
    {question_id: 11, answer: ['Freelancing']},
    {question_id: 13, answer: 123123412},
    {question_id: 44, answer: ''},
    {question_id: 46, answer: ''},
    {question_id: 47, answer: ''},
    {question_id: 48, answer: ''},
    {question_id: 45, answer: ''},
    {question_id: 41, answer: ''},
    {
      question_id: 31,
      answer: {
        first_quarter_tax: 0,
        second_quarter_tax: 0,
        third_quarter_tax: 0,
        fourth_quarter_tax: 0,
      },
    },
    {
      question_id: 32,
      answer: {
        Arizona: {
          first_quarter_tax: 0,
          second_quarter_tax: 0,
          third_quarter_tax: 0,
          fourth_quarter_tax: 0,
        },
      },
    },
  ],
});
