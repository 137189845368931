import React from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import TickIcon from 'src/icons/TickIcon';
import WarningIcon from 'src/icons/WarningIcon';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';

enum LogType {
  DONE = 'DONE',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
}

interface Log {
  event: string;
  date: string;
  type: LogType;
}

interface ActivityTableProps {
  logs: Log[];
}

const ActivityLog = ({event, date, type, isLast}: Log & {isLast: boolean}) => {
  const getIcon = () => {
    if (type === LogType.DONE) {
      return <TickIcon />;
    }
    if (type === LogType.WARNING) {
      return <WarningIcon />;
    }
    return <StatusPendingIcon />;
  };
  return (
    <div style={{display: 'flex', marginBottom: isLast ? 0 : 12}}>
      <div
        style={{
          marginTop: 4,
          marginRight: 8,
          alignItems: 'center',
        }}>
        {getIcon()}
        {!isLast && (
          <div
            style={{
              borderLeft: 'dotted 1px black',
              width: 0,
              marginLeft: 12,
              height: 'calc(100% - 20px)',
            }}
          />
        )}
      </div>
      <div>
        <Typography style={{fontWeight: 600}}>{event}</Typography>
        <Typography style={{fontSize: 13, color: themmeColor.black70}}>
          {date}
        </Typography>
      </div>
    </div>
  );
};

const ActivityTable = ({logs}: ActivityTableProps) => {
  return (
    <Box
      col
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{paddingBlock: 25, paddingInline: 20}}>
      {logs.map((log, index) => (
        <div>
          <ActivityLog {...log} isLast={logs.length - 1 === index} />
        </div>
      ))}
    </Box>
  );
};

export default Object.assign(ActivityTable, {TYPE: LogType});
