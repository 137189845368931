import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  Autocomplete,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: themmeColor.white,
    width: '500px',
    borderRadius: 16,
    padding: 24,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 36,
  },
});

export interface SelectedDocTypeProps {
  docType: string;
  label: string;
}

interface AddMoreDocumentsOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  options: SelectedDocTypeProps[];
  title: string;
  subTitle: string;
  showCommentInput: boolean;
  sendButtonText?: string;
  handleSend: (
    comment: string,
    selectedDocType: SelectedDocTypeProps | null,
  ) => void;
}

const AddMoreDocumentsOverlay: React.FC<AddMoreDocumentsOverlayProps> = ({
  isOpen,
  onClose,
  options,
  title,
  subTitle,
  showCommentInput,
  sendButtonText = 'Send',
  handleSend,
}) => {
  const [selectedDocType, setSelectectedDocType] =
    useState<SelectedDocTypeProps | null>(null);
  const [isCustom, setIsCustom] = useState(false);
  const [customDocType, setCustomDocType] = useState('');

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDocTypeUpdate = (value: SelectedDocTypeProps) => {
    setSelectectedDocType(value);
  };

  const setInitvalues = () => {
    setSelectectedDocType(null);
    setComment('');
  };

  const onSend = async () => {
    try {
      if (isCustom) {
        setLoading(true);
        setLoading(false);
        setInitvalues();
        onClose();
      } else {
        setLoading(true);
        await handleSend(comment, selectedDocType);
        setLoading(false);
        setInitvalues();
        onClose();
      }
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  const docSelected =
    (!isCustom && selectedDocType !== null) ||
    (isCustom && customDocType.length !== 0);

  const styles = useStyles({});

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      disableEnforceFocus
      className={styles.modal}>
      <div className={styles.modalContainer}>
        <Typography fontSize={20} fontWeight={'600'}>
          {title}
        </Typography>
        <Typography fontSize={14} color={themmeColor.grey}>
          {subTitle}
        </Typography>
        {isCustom ? (
          <TextField
            style={{width: 500, marginTop: 20}}
            label="Enter custom document type"
            value={customDocType}
            onChange={(e) => setCustomDocType(e.target.value)}
          />
        ) : (
          <Autocomplete
            disabled={loading}
            style={{marginTop: 20, width: 500}}
            onChange={(e, v) => handleDocTypeUpdate(v)}
            value={selectedDocType ? selectedDocType.label : ''}
            id="combo-box-demo"
            options={options}
            sx={{width: 200}}
            renderInput={(params) => (
              <TextField {...params} label="Document type" />
            )}
          />
        )}

        {showCommentInput && docSelected && (
          <RichTextEditor message={comment} setMessage={setComment} />
        )}
        <div className={styles.buttonsContainer}>
          <Button
            onClick={onClose}
            disabled={loading}
            style={{width: 230, height: 48}}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onSend}
            disabled={
              !docSelected ||
              loading ||
              (showCommentInput && comment.length === 0)
            }
            style={{width: 230, height: 48}}
            variant="contained">
            {sendButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddMoreDocumentsOverlay;
