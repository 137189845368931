import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import Box from 'src/DesignSystem/Box/Box';
import FilledCrossIcon from 'src/icons/FilledCrossIcon';
import TickIcon from 'src/icons/TickIcon';
import {
  EducationalCreditsChoices,
  educationalCreditsHumanReadableCopies,
} from '../Credits.constants';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface EducationCreditsCriteriaCardProps {
  name: string;
  relation: string;
  selectedChoices: EducationalCreditsChoices[];
}

const allChoices = Object.values(EducationalCreditsChoices);

const EducationCreditsCriteriaCard = ({
  name,
  relation,
  selectedChoices,
}: EducationCreditsCriteriaCardProps) => {
  const {activeYear} = useActiveYear();
  return (
    <Box
      borderColor={themmeColor.offWhite}
      backgroundColor={themmeColor.cpaCenterV2Bg}
      col
      style={{paddingBlock: 20, paddingInline: 16}}>
      <Typography style={{fontWeight: 600, marginBottom: 12}}>
        {getFieldValue(name)} ({getFieldValue(relation)})
      </Typography>
      <Typography
        style={{fontSize: 13, color: themmeColor.black60, marginBottom: 12}}>
        Select all that applies {getFieldValue(name)}
      </Typography>
      {allChoices.map((choice, index) => {
        const isEligible = selectedChoices.includes(choice);
        const isLast = index === allChoices.length - 1;
        return (
          <div style={{display: 'flex', marginBottom: isLast ? 0 : 8}}>
            <div style={{marginRight: 8}}>
              {isEligible ? (
                <TickIcon color={themmeColor.black} />
              ) : (
                <FilledCrossIcon />
              )}
            </div>
            <Typography style={{fontWeight: 600, color: themmeColor.black70}}>
              {educationalCreditsHumanReadableCopies(activeYear)[choice]}
            </Typography>
          </div>
        );
      })}
    </Box>
  );
};

export default EducationCreditsCriteriaCard;
