import {v4 as uuidv4} from 'uuid';

export function extractDataFromJson(data) {
  const formData = {};

  for (const page in data) {
    const _value = data[page];
    const dataOfSinglePage = [];
    if (_value.length > 0) {
      const _fields = _value[0].fields;

      _fields.forEach((field) => {
        const {
          value,
          keys_geometry,
          values_geometry,
          actual_textract_key_list,
        } = field;

        if (actual_textract_key_list.length < 1) {
          return;
        }

        // Generate a unique ID for the key-value pair
        const pairId = uuidv4();
        dataOfSinglePage.push({
          id: pairId,
          key_text: actual_textract_key_list[0],
          value_text: value[0],
          key_box: keys_geometry[0].BoundingBox,
          value_box: values_geometry[0].BoundingBox,
        });
      });
    }
    formData[page] = dataOfSinglePage;
  }

  return formData;
}

export const prepareFinalAnnotationData = (ocrDataJson) => {
  const finalData = {};

  for (const page in ocrDataJson) {
    const currentPageOcrData = ocrDataJson[page];
    const finalDataOfPage = {};

    currentPageOcrData.forEach((i) => {
      finalDataOfPage[i.id] = [i.key_text, i.value_text, i.value_text];
    });

    finalData[page] = finalDataOfPage;
  }

  return finalData;
};

export const prepareResultData = (data) => {
  const finalData = {};

  for (const page in data) {
    const currentPageData = data[page];
    const finalDataList = [];

    finalDataList.push(
      ...Object.keys(currentPageData).map((i) => [i, ...currentPageData[i]]),
    );

    finalData[page] = finalDataList;
  }

  return finalData;
};

const drawAndAppendHighlight = (highlightContainer, box, color, id) => {
  const highlight = document.createElement('div');

  highlight.style.position = 'absolute';
  highlight.id = `highlight-box-${id}`;
  highlight.style.pointerEvents = 'none';
  highlight.style.cursor = 'pointer';
  highlight.style.border = `1px solid ${color}`;
  highlight.style.zIndex = '99999';
  highlight.style.left = `${box.Left * 100}%`;
  highlight.style.top = `${box.Top * 100}%`;
  highlight.style.width = `${box.Width * 100}%`;
  highlight.style.height = `${box.Height * 100}%`;

  highlightContainer?.appendChild(highlight);
};

export const createHighlights = (highlightContainer, data) => {
  // clear out prev polygons
  // get an element where the attribute [id] begins with (^=) the string "hello-".
  // https://stackoverflow.com/a/58592106/17233639
  const highlightBoxNodeList = document.querySelectorAll(
    '[id^="highlight-box-"]',
  );
  Array.prototype.forEach.call(highlightBoxNodeList, (n) => n && n.remove());

  data.forEach((item) => {
    const keyBox = item.key_box;
    const valueBox = item.value_box;
    drawAndAppendHighlight(highlightContainer, keyBox, 'yellow', item.id);
    drawAndAppendHighlight(highlightContainer, valueBox, 'green', item.id);
  });
};
