import React, {useMemo, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {OCRBox} from './OCR.types';

interface PageDimensions {
  width: number;
  height: number;
}

interface OCROverlayViewerProps {
  pdfUrl: string;
  ocrData: OCRBox[];
  onlyRenderPagesWithOcrFields?: boolean;
  height?: number;
  scale?: number;
  boxesBorderWidth?: number;
}

const PDFWithOcrBoxes: React.FC<OCROverlayViewerProps> = ({
  pdfUrl,
  ocrData,
  onlyRenderPagesWithOcrFields = false,
  height = 400,
  scale = 1.3,
  boxesBorderWidth = 2,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  const pageNumbersWithOcrFields = useMemo(() => {
    const pagesWithOcrFields = new Set();
    ocrData.forEach((field) => {
      pagesWithOcrFields.add(field.pageNumber);
    });
    return pagesWithOcrFields;
  }, [ocrData]);

  // Triggered on successful PDF document load
  const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setNumPages(numPages);
  };

  // Capture page dimensions on render success
  const handlePageRender = (pageNumber: number, page: pdfjs.PDFPageProxy) => {
    if (pageNumbersWithOcrFields.has(pageNumber) || pageNumber === 1) {
      const viewport = page.getViewport({scale});
      setPageDimensions({width: viewport.width, height: viewport.height});
    }
  };

  // Calculate absolute positions for OCR boxes
  const calculateBoxStyle = ({
    Top,
    Left,
    Width,
    Height,
  }: OCRBox): React.CSSProperties => {
    return {
      position: 'absolute',
      top: `${Top * pageDimensions.height}px`,
      left: `${Left * pageDimensions.width}px`,
      width: `${Width * pageDimensions.width}px`,
      height: `${Height * pageDimensions.height}px`,
      border: `${boxesBorderWidth}px solid red`,
      boxSizing: 'border-box',
    };
  };

  return (
    <div
      ref={pdfContainerRef}
      style={{position: 'relative', height, overflowY: 'auto'}}>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({length: numPages || 0}, (_, index) => {
          if (
            onlyRenderPagesWithOcrFields &&
            !pageNumbersWithOcrFields.has(index + 1)
          ) {
            return null;
          }
          return (
            <div key={index} style={{position: 'relative'}}>
              <Page
                key={index}
                pageNumber={index + 1}
                onRenderSuccess={(pdfPage) =>
                  handlePageRender(index + 1, pdfPage)
                }
                scale={scale}
              />
              {ocrData
                .filter((data) => data.pageNumber === index + 1)
                .map((box, boxIndex) => (
                  <div key={boxIndex} style={calculateBoxStyle(box)}></div>
                ))}
            </div>
          );
        })}
      </Document>
    </div>
  );
};

export default React.memo(PDFWithOcrBoxes);
