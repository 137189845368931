import React, {useState} from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {themmeColor} from 'src/constants/constants';
import {Tooltip} from '@mui/material';
import UpdateNoteOverlay from 'src/CpaCenterList/components/UpdateNoteCell/UpdateNoteOverlay';
import {patchTaxFiling} from 'src/appApi';

interface UpdateNoteCellProps {
  value: string;
  isEmpty: boolean;
  timeStamp: 1678350076;
  whom: string;
  taxFilingId: number;
  onSuccess: () => void;
}

const UpdateNoteCell = ({
  value,
  isEmpty,
  timeStamp,
  whom,
  taxFilingId,
  onSuccess,
}: UpdateNoteCellProps) => {
  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };
  const [updateOverlay, setUpdateOverlay] = useState(false);

  const updateNote = async (notes: string) => {
    // TODO: make API call
    try {
      await patchTaxFiling({tax_filing_id: taxFilingId}, {notes});
      await onSuccess();
      setUpdateOverlay(false);
    } catch {
      alert('somthing went wrong!');
    }
  };

  return (
    <div onClick={stopDrilling}>
      <div onClick={() => setUpdateOverlay(true)}>
        <Tooltip title={value}>
          <NoteAddIcon
            style={{color: isEmpty ? themmeColor.offWhite : themmeColor.black}}
          />
        </Tooltip>
      </div>
      {updateOverlay && (
        <UpdateNoteOverlay
          updateNote={updateNote}
          isVisible={updateOverlay}
          value={value}
          handleClose={() => setUpdateOverlay(false)}
          timeStamp={timeStamp}
          whom={whom}
        />
      )}
    </div>
  );
};

export default UpdateNoteCell;
