import {SET_BALANCE_SHEET_DATA} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';
import {BalanceSheetCombined} from './balanceSheet.types';

const initialState = {
  balance_sheet_data: [] as BalanceSheetCombined[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const balanceSheet = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_BALANCE_SHEET_DATA: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type BalanceSheetStateType = typeof initialState;
