import React, {useContext} from 'react';

export enum TaxProfileSummaryVariant {
  DEFAULT = 'DEFAULT',
  USER_QUERIES_VARAINT = 'USER_QUERIES_VARAINT',
}

interface ContextProps {
  summaryVariant: TaxProfileSummaryVariant;
}

const TaxProfileSummaryFilterContext = React.createContext<ContextProps>({
  summaryVariant: TaxProfileSummaryVariant.DEFAULT,
});

export const TaxProfileSummaryFilterProvider = ({children}) => {
  const SUMMARY_VARIANT = TaxProfileSummaryVariant.USER_QUERIES_VARAINT;

  return (
    <TaxProfileSummaryFilterContext.Provider
      value={{summaryVariant: SUMMARY_VARIANT}}>
      {children}
    </TaxProfileSummaryFilterContext.Provider>
  );
};

export const useTaxProfileSummaryFilter = () => {
  const value = useContext(TaxProfileSummaryFilterContext);
  return value;
};
