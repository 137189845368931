import * as React from 'react';
import {Fragment, useState} from 'react';
import {
  Button,
  useMutation,
  useRefresh,
  useNotify,
  useQueryWithStore,
  Loading,
} from 'react-admin';
import {BATCH_NOTIFICATION_THRESHOLD} from '../constants/constants';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialButton} from '@material-ui/core/Button';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ScheduleIcon from '@material-ui/icons/Schedule';

const moment = require('moment-timezone');
const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));

export const getConvertedDate = (inputDate) => {
  // IST is 5.5 hrs ahead of gmt and PST is 7/8 hrs behind gmt
  // diff between IST & PST is 12.5/13.5 hrs
  let timeDeltaPST = moment.tz('America/Los_Angeles').toString();
  timeDeltaPST = timeDeltaPST.split('GMT-')[1].charAt(1) * 3600 * 1000;

  const localTimeZone = moment.tz.guess(true);
  let timeDeltaLocal = moment
    .tz(localTimeZone)
    .toString()
    .split('GMT')[1]
    .substring(1);
  const sign = moment.tz(localTimeZone).toString().split('GMT')[1].charAt(0);
  timeDeltaLocal =
    (timeDeltaLocal.substring(0, 2) * 60 + timeDeltaLocal.substring(2) * 1) *
    60 *
    1000;
  if (sign === '-') timeDeltaLocal = -timeDeltaLocal;
  const new_time =
    new Date(inputDate).getTime() + timeDeltaPST + timeDeltaLocal;
  const new_date = new Date();
  new_date.setTime(new_time);
  return moment.tz(new_date, 'America/Los_Angeles');
};

export function DateAndTimePicker({disable, scheduledTime, setScheduledTime}) {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        type="datetime-local"
        value={scheduledTime}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disable}
        onChange={(event) => setScheduledTime(event.target.value)}
      />
    </form>
  );
}

const BatchNotifyFormDialog = ({
  userId,
  final_account_ids,
  years,
  open,
  onClose,
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const [whenToSend, setWhenToSend] = useState('schedule');
  const [batchNotifyNow, {loadingNow}] = useMutation(
    {
      type: 'batch',
      resource: 'notification',
      payload: {
        user_id: userId,
        account_ids: final_account_ids,
        year: years,
        realtime: true,
      },
    },
    {
      onSuccess: () => {
        refresh();
        notify('Batch notify now successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Batch notify now failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );
  const [scheduledTime, setScheduledTime] = useState('');
  const [batchNotifySchedule, {loadingSchedule}] = useMutation(
    {
      type: 'batch',
      resource: 'notification',
      payload: {
        user_id: userId,
        account_ids: final_account_ids,
        year: years,
        realtime: false,
        scheduled_time: getConvertedDate(scheduledTime),
      },
    },
    {
      onSuccess: () => {
        refresh();
        notify('Batch notify schedule successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Batch notify schedule failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  const handleConfirm = () => {
    if (whenToSend === 'now') {
      batchNotifyNow();
    } else {
      batchNotifySchedule();
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Batch Notify {final_account_ids.length} Selected Accounts
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br /> Are you sure you want to send batch notification for this user
          and card(s)? <br />
        </DialogContentText>
        <RadioGroup
          aria-label="type"
          name="when"
          value={whenToSend}
          onChange={(event, newValue) => {
            if (newValue != null) {
              setWhenToSend(newValue);
            }
          }}>
          <Grid container>
            <Grid item xs={3}>
              <FormControlLabel value="now" control={<Radio />} label="Now" />
            </Grid>
            <Grid item>
              <Chip
                disabled={whenToSend === 'schedule' ? true : false}
                label={moment.tz('America/Los_Angeles').toString()}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <FormControlLabel
                value="schedule"
                control={<Radio />}
                label="Schedule"
              />
            </Grid>
            <Grid item>
              <DateAndTimePicker
                disable={whenToSend === 'now' ? true : false}
                scheduledTime={scheduledTime}
                setScheduledTime={setScheduledTime}
              />
              ( US Pacific Date time )
            </Grid>
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={onClose}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={handleConfirm}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirm
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const BatchNotifyButton = ({userId, accountId, years}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const final_account_ids = accountId == null ? [] : accountId;
  const {
    data: account_data,
    loading: account_loading,
    error: account_error,
  } = useQueryWithStore({
    type: 'getMany',
    resource: 'plaid_account',
    payload: {ids: final_account_ids},
  });

  if (account_loading) return <Loading />;
  // if (account_error) return <Error />;
  if (!account_data) return null;
  const disable_button =
    account_data.length == 0 ||
    account_data.some(
      (x) =>
        x['no_of_times_batch_notification_sent'] >=
        BATCH_NOTIFICATION_THRESHOLD,
    );
  const is_scheduled =
    account_data.length != 0 &&
    account_data.some((x) => x['is_batch_scheduled'] === true);
  return (
    <Fragment>
      {is_scheduled && <ScheduleIcon />}
      <Button
        label="Batch Notify"
        onClick={handleClick}
        disabled={disable_button}
      />
      <BatchNotifyFormDialog
        userId={userId}
        final_account_ids={final_account_ids}
        years={years}
        open={open}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BatchNotifyButton;
