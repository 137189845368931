import React, {useState} from 'react';
import Modal from '@mui/material/Modal';

import {themmeColor} from 'src/constants/constants';
import {Autocomplete, Button, TextField, Typography} from '@mui/material';
import {updateBoiUserData} from 'src/appApi';
import {BOI_STATUS} from '../types';

interface UpdateBoiStatusOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  currentBoiStatus: BOI_STATUS;
  boiId: number;
  onSuccess: () => void | Promise<void>;
}

const UpdateBoiStatusOverlay = ({
  isVisible,
  onClose,
  currentBoiStatus,
  boiId,
  onSuccess,
}: UpdateBoiStatusOverlayProps) => {
  const currentStatus = currentBoiStatus;

  const [activeStatus, setActiveStatus] = useState(currentStatus);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const validBoiStatuses = Object.values(BOI_STATUS);

  const handleOnChange = (e: any, v: any) => {
    setActiveStatus(v);
  };

  const handleStatusUpdate = async () => {
    setLoading(true);
    try {
      await updateBoiUserData(boiId, {status: activeStatus});
      await onSuccess();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={stopDrilling}>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={isVisible}
        onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 540,
            padding: 24,
            borderRadius: 16,
            backgroundColor: themmeColor.white,
          }}>
          <Typography fontSize={20} fontWeight="600">
            Change boi status
          </Typography>
          <Typography
            color={themmeColor.grey}
            style={{display: 'flex', marginTop: 12}}
            fontSize={14}>
            Do you want to change the boi status from "
            <Typography
              color={themmeColor.grey}
              fontSize={14}
              fontWeight={'600'}>
              {currentStatus}
            </Typography>
            "
          </Typography>
          <Autocomplete
            onChange={handleOnChange}
            disablePortal
            id="combo-box-demo"
            options={validBoiStatuses}
            value={activeStatus}
            fullWidth
            style={{marginTop: 16}}
            sx={{width: 300}}
            renderInput={(params) => (
              <TextField {...params} label="Return Status" />
            )}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              marginTop: 24,
            }}>
            <Button
              disabled={loading}
              onClick={handleClose}
              style={{flex: 0.45, height: 48}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleStatusUpdate}
              disabled={
                !activeStatus || currentStatus === activeStatus || loading
              }
              style={{flex: 0.45, height: 48}}
              variant="contained">
              Yes, Change it
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateBoiStatusOverlay;
