import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {createCouponCode, createPromoCodes, listPromoCodes} from 'src/appApi';

import CreatePromoCode from './components/CreatePromoCode';
import Pagination from './components/Pagination';
import PromoSearchBar from './components/PromoSearchBar';
import PromoTable from './components/PromoTable';
import CreateCouponCode from './components/CreateCouponCode';

const WhitelistPromoCode = () => {
  const [searchParams] = useSearchParams();
  const defaultPromoCode = searchParams.get('promoCode');
  const defaultCouponCode = searchParams.get('couponCode');
  const defaultPage = searchParams.get('page');

  const [isCreatePromo, setIsCreatePromo] = useState(false);
  const [isCreateCoupon, setIsCreateCoupon] = useState(false);

  const openCreatePromoCodeModal = () => {
    setIsCreatePromo(true);
  };

  const closeCreatePromoModal = () => {
    setIsCreatePromo(false);
  };

  const openCreateCouponCodeModal = () => {
    setIsCreateCoupon(true);
  };

  const closeCreateCouponModal = () => {
    setIsCreateCoupon(false);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    promo: string | null,
    coupon: string | null,
    page,
  ) => {
    try {
      setLoading(true);
      const response = await listPromoCodes(promo ?? '', coupon ?? '', page);
      setData(response.data);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    fetchData(
      defaultPromoCode,
      defaultCouponCode,
      parseInt(defaultPage ?? '1'),
    );
  };

  useEffect(() => {
    fetchData(
      defaultPromoCode,
      defaultCouponCode,
      parseInt(defaultPage ?? '1'),
    );
  }, [defaultCouponCode, defaultPromoCode, defaultPage]);

  const onCreatePromoCode = async (data: any) => {
    try {
      setLoading(true);
      await createPromoCodes(data);
      closeCreatePromoModal();
    } catch (e) {
      alert(JSON.stringify(e?.response?.data ?? '') ?? 'Error occured');
    } finally {
      setLoading(false);
    }
  };

  const onCreateCoupon = async (data: any) => {
    try {
      setLoading(true);
      await createCouponCode(data);
      closeCreateCouponModal();
    } catch (e) {
      alert(JSON.stringify(e?.response?.data ?? '') ?? 'Error occured');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{padding: 20}}>
      <PromoSearchBar
        loading={loading}
        openCreatePromoCodeModal={openCreatePromoCodeModal}
        openCreateCouponCodeModal={openCreateCouponCodeModal}
        fetchData={refreshData}
      />
      <PromoTable loading={loading} data={data} />
      <CreatePromoCode
        isOpen={isCreatePromo}
        onClose={closeCreatePromoModal}
        loading={loading}
        onCreatePromoCode={onCreatePromoCode}
      />
      <CreateCouponCode
        isOpen={isCreateCoupon}
        onClose={closeCreateCouponModal}
        loading={loading}
        onCreateCoupon={onCreateCoupon}
      />
      <Pagination disableNext={data.length < 10} />
    </div>
  );
};

export default WhitelistPromoCode;
