import React from 'react';

const ExternalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M14.9999 2.99892L21.0085 3.00177M21.0085 3.00177L21.0086 8.99902M21.0085 3.00177L12.0109 11.9967"
        stroke="#0D0F0F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 6H5C3.89543 6 3 6.89543 3 8V19C3 20.1046 3.89543 21 5 21H16C17.1046 21 18 20.1046 18 19V12"
        stroke="#0D0F0F"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};


export default ExternalIcon;
