import React from 'react';
import {useSelector} from 'react-redux';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import SubSectionBox from '../../common/SubSectionBox';
import {
  TAX_PROFILE_FIELDS,
  FIELD_ID,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {Typography} from '@mui/material';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {
  MortgageTypes,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.constants';
import CommonDeductionTable from './CommonDeductionTable';
import {WFH_ANSWER} from '../Deductions.constants';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const HomeOfficeDetails = () => {
  const {activeYear} = useActiveYear();
  const wfh = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.WFH, activeYear),
  );

  const homeOfficeArea = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.HOME_OFFICE_AREA,
      activeYear,
    ),
  );
  const homeArea = homeOfficeArea?.[FIELD_ID.HOME_AREA];
  const officeArea = homeOfficeArea?.[FIELD_ID.OFFICE_AREA];

  const homeOfficeType = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.HOME_OFFICE_EXPENSE_CHOICE,
      activeYear,
    ),
  );

  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const homeOfficeTFs = taxForms.filter(
    (tf) =>
      tf.formType === ALL_FORM_TYPES.FORM_1098_MIS &&
      tf.formData[TaxFormFieldIds.MORTGAGE_TYPE] === MortgageTypes.HOME_OFFICE,
  );

  return (
    <SubSectionBox
      name="Home office details"
      taxprofileSubsection={TaxProfileSubsection.HomeOfficeDetails}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField name="Work from home" value={wfh} />
          {wfh === WFH_ANSWER.YES && (
            <>
              <SimpleKeyValueField
                name="Total area of your home (sq. ft.)"
                value={homeArea}
              />
              <SimpleKeyValueField
                name="Area of your home office space (sq. ft.)"
                value={officeArea}
              />
              <SimpleKeyValueField
                name="Home office type?"
                value={homeOfficeType}
              />
              <Typography
                style={{
                  fontSize: 11,
                  color: themmeColor.black60,
                  marginBottom: 4,
                }}>
                Related document
              </Typography>
              {homeOfficeTFs.map((tf) => {
                return (
                  <DocumentPointer
                    dontShowStatus
                    doc={documents.find((doc) => doc.docId === tf.docId)}
                    taxform={tf}
                    border
                  />
                );
              })}
            </>
          )}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          {wfh === WFH_ANSWER.YES && (
            <CommonDeductionTable
              taxProfileSubsection={TaxProfileSubsection.HomeOfficeDetails}
            />
          )}
        </div>
      </div>
    </SubSectionBox>
  );
};

export default HomeOfficeDetails;
