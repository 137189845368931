import React, {useEffect, useState} from 'react';
import {Button} from '@mui/material';
import {useParams} from 'react-router-dom';
import {getPreviewInfo, postCCHData} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import {
  CCH_ACTION_BUTTONS,
  CCH_SYNC_STATUS,
  IncomeInfo,
} from 'src/CpaCenterUserInfo/CpaCenterCCH/cch.utils';
import SendIncomeDocumentsOverlay from './components/SendIncomeDocumentsOverlay';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const CpaCenterCCH = () => {
  const {activeYear} = useActiveYear();
  const {flyfin_user_id} = useParams();

  const [loading, setLoading] = useState(true);
  const [incomeInfo, setIncomeInfo] = useState<IncomeInfo[]>([]);
  const [showSendIncomeDocumentsOverlay, setShowSendIncomeDocumentsOverlay] =
    useState(false);

  const CCH_ACTION_BUTTON_CONFIG = [
    {
      actionType: CCH_ACTION_BUTTONS.BASIC,
      label: 'Send Basic',
      backgroundColor: themmeColor.darkBlue,
    },
    {
      actionType: CCH_ACTION_BUTTONS.INCOME,
      label: 'Send Income Documents',
      backgroundColor:
        incomeInfo.length === 0 ||
        incomeInfo.filter(
          (incomeData) =>
            incomeData.cch_sync_status !== CCH_SYNC_STATUS.SUCCESS,
        ).length !== 0
          ? themmeColor.darkBlue
          : themmeColor.successGreen,
    },
    {
      actionType: CCH_ACTION_BUTTONS.SCHEDULE_C,
      label: 'Send Schedule C',
      backgroundColor: themmeColor.darkBlue,
    },
  ];

  const getIncomeDocumentsInfo = async () => {
    const res = await getPreviewInfo({
      fly_user_id: flyfin_user_id ?? '',
      year: activeYear,
      sections: CCH_ACTION_BUTTONS.INCOME,
    });
    setIncomeInfo(res.data[CCH_ACTION_BUTTONS.INCOME]);
  };

  useEffect(() => {
    getIncomeDocumentsInfo();
    setLoading(false);
  }, [flyfin_user_id, activeYear]);

  const sendIncomeDocumentsToCCH = async (selectedForms: number[]) => {
    try {
      await postCCHData({
        fly_user_id: flyfin_user_id ?? '',
        year: activeYear,
        config: {
          [CCH_ACTION_BUTTONS.INCOME]: {
            should_populate: true,
            resources: {tax_form_ids: selectedForms},
          },
        },
      });
      await getIncomeDocumentsInfo();
      alert('Success!!!');
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  const actionButtonOnPressHandler = async (
    actionType: CCH_ACTION_BUTTONS,
  ) => {
    setLoading(true);
    try {
      switch (actionType) {
        case CCH_ACTION_BUTTONS.BASIC:
          await postCCHData({
            fly_user_id: flyfin_user_id ?? '',
            year: activeYear,
            config: {[actionType]: {should_populate: true}},
          });
          alert('Success!!!');
          break;
        case CCH_ACTION_BUTTONS.INCOME:
          setShowSendIncomeDocumentsOverlay(true);
          break;
        case CCH_ACTION_BUTTONS.SCHEDULE_C:
          await postCCHData({
            fly_user_id: flyfin_user_id ?? '',
            year: activeYear,
            config: {[actionType]: {should_populate: true}},
          });
          alert('Success!!!');
          break;
        default:
          break;
      }
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <FullScreenLoading open={loading} />}
      {CCH_ACTION_BUTTON_CONFIG.map((buttonProps) => (
        <div style={{margin: 10}}>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => actionButtonOnPressHandler(buttonProps.actionType)}
            style={{background: buttonProps.backgroundColor}}>
            {buttonProps.label}
          </Button>
        </div>
      ))}
      {showSendIncomeDocumentsOverlay && (
        <SendIncomeDocumentsOverlay
          isOpen={showSendIncomeDocumentsOverlay}
          onClose={() => setShowSendIncomeDocumentsOverlay(false)}
          handleSend={sendIncomeDocumentsToCCH}
          data={incomeInfo}
        />
      )}
    </div>
  );
};

export default CpaCenterCCH;
