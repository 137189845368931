import React from 'react';
import {
  CategoryDeductionsSplit,
  DistributeDeductionCategoryGrouping,
} from 'src/store/distributeDeductions/distributeDeductions.reducer';
import TitleRow from '../Rows/TitleRow';
import CategorySplitRow from '../Rows/CategorySplitRow';

interface CategoryGroupTableProps {
  categoryHeaderRowData: CategoryDeductionsSplit[];
  categoryWiseData: CategoryDeductionsSplit[];
  onChangeSplitHeader: (props: {newValue: number; splitIndex: number}) => void;
  onChangeSplit: (props: {
    newValue: number;
    splitIndex: number;
    rowIndex: number;
  }) => void;
}

const CategoryGroupTable = ({
  categoryHeaderRowData,
  categoryWiseData,
  onChangeSplitHeader,
  onChangeSplit,
}: CategoryGroupTableProps) => {
  return (
    <div>
      <TitleRow />
      {categoryHeaderRowData[0].category_name !==
      DistributeDeductionCategoryGrouping.OTHER_DEDUCTION ? (
        <CategorySplitRow
          rowData={categoryHeaderRowData[0]}
          isHeaderRow
          onChangeSplit={onChangeSplitHeader}
        />
      ) : null}
      {categoryWiseData.map((categoryInfo, rowIndex) => {
        return (
          <CategorySplitRow
            key={`${categoryInfo.category_name}-split-row`}
            rowData={categoryInfo}
            onChangeSplit={(props) => onChangeSplit({...props, rowIndex})}
          />
        );
      })}
    </div>
  );
};

export default CategoryGroupTable;
