import {FIELD_ID} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {
  FORM_INPUT_TYPE,
  FormConfigProps,
} from 'src/DesignSystem/Form/Form.types';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {ReturnFields} from './CreateReturn.types';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';
import {
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import _ from 'lodash';
import {PartnershipReturnFields} from 'src/appApi.types';

const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026];

export const TaxReturnBasicFieldsConfig: FormConfigProps[] = [
  {
    title: 'First name & middle initial',
    path: FIELD_ID.FIRST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Last name',
    path: FIELD_ID.LAST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'SSN',
    path: FIELD_ID.SSN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.VALIDATE_SSN, VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Partner',
    path: ReturnFields.partner,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    validators: [VALIDATOR.NON_NULL_VALUE],
    options: [],
  },
  {
    title: 'Tax year',
    path: ReturnFields.years,
    inputType: FORM_INPUT_TYPE.MultiSelect,
    options: years.map((year) => ({label: year, value: year})),
    validators: [VALIDATOR.NON_EMPTY_ARRAY, VALIDATOR.NON_NULL_VALUE],
  },
];

export const IndividualReturnFieldsConfig: FormConfigProps[] = [
  {
    title: 'Filing status',
    path: FIELD_ID.FILING_STATUS,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
    options: Object.values(FILING_STATUS_ANSWER).map((status) => ({
      label: status,
      value: status,
    })),
  },
  {
    title: 'Spouse SSN',
    path: FIELD_ID.SPOUSE_SSN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.VALIDATE_SSN, VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Filing',
    path: ReturnFields.should_file_individual_return,
    inputType: FORM_INPUT_TYPE.Boolean,
  },
];

export const BusinessReturnFieldsConfig: FormConfigProps[] = [
  {
    title: 'Filing',
    path: ReturnFields.should_file_return,
    inputType: FORM_INPUT_TYPE.Boolean,
  },
  {
    title: 'Business name',
    path: BusinessDetailsField.name,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business entity',
    path: BusinessDetailsField.entity_type,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
    options: [
      {value: BusinessType.PARTNERSHIP, label: 'Partnership'},
      {value: BusinessType.S_CORP, label: 'S-corp'},
      {value: BusinessType.C_CORP, label: 'C-crop'},
    ],
  },
  {
    title: 'Business EIN',
    path: BusinessDetailsField.ein,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.VALIDATE_EIN, VALIDATOR.NON_NULL_VALUE],
  },
];

export const ManualReturnFieldsConfig: FormConfigProps[] = [
  ...TaxReturnBasicFieldsConfig,
  ...IndividualReturnFieldsConfig,
  {
    title: 'Businesses',
    path: ReturnFields.businesses,
    inputType: FORM_INPUT_TYPE.Array,
    childProps: BusinessReturnFieldsConfig,
    childTitle: 'Business',
  },
];

export const UsersRelationFieldConfig: FormConfigProps[] = [
  {
    title: 'Users',
    path: ReturnFields.users,
    inputType: FORM_INPUT_TYPE.Array,
    childProps: [
      {
        title: 'First name & middle initial',
        path: FIELD_ID.FIRST_NAME,
        inputType: FORM_INPUT_TYPE.String,
        validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'Last name',
        path: FIELD_ID.LAST_NAME,
        inputType: FORM_INPUT_TYPE.String,
        validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'SSN',
        path: FIELD_ID.SSN,
        inputType: FORM_INPUT_TYPE.String,
        validators: [VALIDATOR.VALIDATE_SSN, VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'This user has spouse & filing MFJ',
        inputType: FORM_INPUT_TYPE.Boolean,
        path: ReturnFields.has_spouse,
      },
      {
        title: 'Select spouse',
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: [],
        path: ReturnFields.spouse_id,
        allowClear: true,
      },
    ],
    childTitle: 'User',
  },
];

export const MultipleUsersFieldConfig: FormConfigProps[] = [
  {
    title: 'Users',
    path: ReturnFields.users,
    inputType: FORM_INPUT_TYPE.Array,
    childProps: _.cloneDeep(ManualReturnFieldsConfig),
    childTitle: 'User',
  },
];

export const SingleDocumentAssignmentConfig: FormConfigProps[] = [
  {
    title: 'Select User',
    path: PartnershipReturnFields.fly_user_id,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Years',
    path: ReturnFields.years,
    inputType: FORM_INPUT_TYPE.MultiSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_ARRAY],
  },
  {
    title: 'This belongs to spouse',
    path: ReturnFields.belongs_to_spouse,
    inputType: FORM_INPUT_TYPE.Boolean,
  },
];

export const MultipleDocumentAssignmentConfig: FormConfigProps[] = [
  {
    title: 'Assign Documents',
    path: ReturnFields.documents,
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Document',
    childProps: SingleDocumentAssignmentConfig,
  },
];
