import React, {Fragment, useState} from 'react';
import {useRedirect, useNotify} from 'react-admin';
import {
  TextField,
  SimpleShowLayout,
  Loading,
  Button,
  useMutation,
  useRefresh,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  TextField as MUITextField,
  makeStyles,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {useGetOne, useGetList} from 'react-admin';
import {CPAQuestionMessageType, CPA_CALL_STATUS} from '../constants/constants';
import {getFormattedDateTime} from '../common/utils';
import {RouteName} from 'src/constants/routeName';

const useStyles = makeStyles((theme) => ({
  userdetails: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    marginRight: '10%',
  },
  openqueries: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    marginRight: '10%',
  },
  conversation: {
    backgroundColor: '#f0eded',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,
    marginRight: '10%',
    marginTop: '2%',
  },
  expensedetails: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 500,
    marginRight: '10%',
    marginTop: '2%',
  },
  title: {
    textAlign: 'center',
  },
}));

const UserProfile = (props) => {
  const classes = useStyles();

  const {
    data: userData,
    isUserLoading,
    userError,
  } = useGetOne('users', props['fly_user_id']);
  const {
    data: ansData,
    isAnsLoading,
    ansError,
  } = useGetList(
    'onb_questions',
    {page: 1, perPage: 50},
    {field: 'question_id', order: 'ASC'},
    {fly_user_id: props['fly_user_id']},
  );

  if (isUserLoading || isAnsLoading) {
    return <Loading />;
  }
  if (userError || ansError) {
    return <p>ERROR</p>;
  }

  const onbAnswers = Object.values(ansData).map((ans) => (
    <div>
      <b>{ans['question']}</b>{' '}
      <p>{JSON.stringify(ans['answer'], null, '  ')}</p>
    </div>
  ));
  return (
    <SimpleShowLayout className={classes.userdetails} record={{...userData}}>
      <h4 className={classes.title}>User Details</h4>
      <TextField source="email" />
      <TextField source="name" />
      <Typography>{onbAnswers}</Typography>
    </SimpleShowLayout>
  );
};

const ExpenseDetails = (props) => {
  const classes = useStyles();

  const {
    data: txnData,
    isUserLoading,
    userError,
  } = useGetOne('expenses', props['txn_ids'][0]);

  if (isUserLoading) {
    return <Loading />;
  }
  if (userError) {
    return <p>ERROR</p>;
  }
  return (
    <SimpleShowLayout className={classes.expensedetails} record={{...txnData}}>
      <h4 className={classes.title}>Expense Details</h4>
      <TextField source="txn_date" />
      <TextField source="merchant_name" />
      <TextField source="txn_category" />
      <TextField source="classification_status" />
      <TextField source="tax_deductible_amount" />
      <TextField source="plaid_category" />
    </SimpleShowLayout>
  );
};

const Message = (props) => {
  const dd = new Date(props.created_at).toLocaleString();
  const msgStyle = {
    padding: '0.2em',
    border: '0.5px solid',
    borderRadius: '10px',
    backgroundColor: 'white',
  };
  if (props.author_type === 'CPA') {
    return (
      <div
        style={{alignSelf: 'right', marginLeft: '5em', marginBottom: '2em'}}>
        <p style={{fontSize: '0.8em'}}>
          {props.author_name} {dd}
        </p>
        <div style={msgStyle}>
          <p>{props.message}</p>
        </div>
      </div>
    );
  }
  return (
    <div style={{alignSelf: 'left', marginRight: '5em', marginBottom: '2em'}}>
      <p style={{fontSize: '0.8em'}}>
        {props.author_name} {dd}
      </p>
      <div style={msgStyle}>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

const Conversation = (props) => {
  const {id, call_status = null, start_time, end_time} = {...props};
  const [convData, setConvData] = useState();
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const {data, loading, error} = useGetList(
    'conversation',
    {page: 1, perPage: 50},
    {field: 'id', order: 'ASC'},
    {cpa_question_id: id},
  );
  const [saveMessageNotify, {loading: isLoading}] = useMutation(
    {
      type: 'save_message',
      resource: 'cpa_question',
      payload: {
        data: {
          message: convData,
          cpa_question_id: id,
          author_type: 'CPA',
          send_notification: true,
          type: 'CHAT',
        },
      },
    },
    {
      onSuccess: () => {
        setConvData('');
        refresh();
      },
      onFailure: (error) => {
        notify(`Not able to send!: ${error.body['display_msg']}`, 'warning');
      },
    },
  );

  const [cancelCall, {loading: isCallLoading}] = useMutation(
    {
      type: 'save_message',
      resource: 'cpa_question',
      payload: {
        data: {
          message:
            'Hope this was helpful. If you feel your query is answered, you can go ahead and cancel the call.',
          cpa_question_id: id,
          author_type: 'CPA',
          send_notification: true,
          type: CPAQuestionMessageType.DEEPLINK,
          sub_type: CPAQuestionMessageType.CALL_CANCELLATION,
        },
      },
    },
    {
      onSuccess: () => {
        setConvData('');
        refresh();
      },
      onFailure: (error) => {
        notify(`Not able to send!: ${error.body['display_msg']}`, 'warning');
      },
    },
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const chat = Object.values(data).map((msg) => <Message {...msg} />);
  const showLoading = isCallLoading || isLoading;

  return (
    <Fragment>
      <SimpleShowLayout className={classes.conversation}>
        <h4 className={classes.title}>Conversation</h4>
        {chat}
      </SimpleShowLayout>
      <p>Call status: {call_status}</p>
      {call_status === CPA_CALL_STATUS.CALL_SCHEDULED && (
        <p>{getFormattedDateTime(start_time, end_time)}</p>
      )}
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={9}>
          <MUITextField
            style={{width: '100%', marginTop: '2%', marginBottom: '2%'}}
            multiline
            maxRows={4}
            id="outlined-basic"
            label="Type here"
            variant="outlined"
            onChange={(e) => setConvData(e.target.value)}
            disabled={showLoading}
          />
        </Grid>
        <Button
          variant="contained"
          label="Send"
          onClick={saveMessageNotify}
          disabled={showLoading}></Button>
        {call_status === CPA_CALL_STATUS.CALL_SCHEDULED && (
          <Button
            variant="outlined"
            label="Cancel call"
            onClick={cancelCall}
            disabled={isCallLoading}></Button>
        )}
      </Grid>
    </Fragment>
  );
};

const OpenCPAQueries = (props) => {
  const {setOpenCPAQueriesCount, fly_user_id, id} = {...props};
  const redirect = useRedirect();
  const classes = useStyles();
  const {
    data: data,
    isLoading,
    error,
  } = useGetList(
    'cpa_question',
    {page: 1, perPage: 50},
    {field: 'id', order: 'ASC'},
    {fly_user_id: fly_user_id, state: 'AWAITING_RESPONSE'},
  );

  const openQueryDetails = (queId) => {
    redirect('edit', '/cpa_question', queId);
  };

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  setOpenCPAQueriesCount(Object.values(data).length);
  return (
    <Fragment>
      <TableContainer component={Paper} className={classes.openqueries}>
        <h4 style={{textAlign: 'center'}}>Other Queries</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Query</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">App Year</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(data)
              .filter((ele) => ele.id !== id)
              .map((row) => (
                <TableRow
                  key={row.name}
                  onClick={() => openQueryDetails(row.id)}>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    {new Date(row.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.query.slice(0, 10)}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.app_year}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const CPAQuestionEdit = (props) => {
  // There is some issue on frontend with api data, data is getting modify to some other api's data.
  // so redirecting user to new UI. instead debugging issue on depricated UI.
  window.location.assign(
    `${RouteName.UserQueries.UserQueries}/${props['id']}`,
  );

  // const [openCPAQueriesCount, setOpenCPAQueriesCount] = useState(0);
  // const {
  //   data: qData,
  //   isQLoading,
  //   qError,
  // } = useGetOne('cpa_question', props['id']);

  // if (isQLoading) {
  //   return <Loading />;
  // }
  // if (qError) {
  //   return <p>ERROR</p>;
  // }
  // return (
  //   <Fragment>
  //     <Grid container>
  //       <h3>Question Id : {qData?.id}</h3>
  //       <Grid container>
  //         <Grid xs={6}>
  //           <UserProfile {...qData} />
  //         </Grid>
  //         <Grid xs={6}>
  //           <OpenCPAQueries
  //             {...qData}
  //             openCPAQueriesCount={openCPAQueriesCount}
  //             setOpenCPAQueriesCount={setOpenCPAQueriesCount}
  //           />
  //         </Grid>
  //       </Grid>
  //       <Grid container>
  //         <Grid xs={6}>
  //           <Conversation
  //             {...qData}
  //             openCPAQueriesCount={openCPAQueriesCount}
  //           />
  //         </Grid>
  //         {qData && qData['type'] === 'TXN_RELATED' && (
  //           <Grid xs={6}>
  //             <ExpenseDetails {...qData} />
  //           </Grid>
  //         )}
  //       </Grid>
  //     </Grid>
  //   </Fragment>
  // );
};

export default CPAQuestionEdit;
