import {themmeColor} from 'src/constants/constants';
import {InlineNotificationType} from './InlineNotification.types';

export const InlineNotificationVariantsProps = {
  [InlineNotificationType.error]: {
    backgroundColor: themmeColor.errorRed,
    textColor: themmeColor.white,
  },
  [InlineNotificationType.warning]: {
    backgroundColor: themmeColor.lightYellow,
    textColor: themmeColor.black,
  },
};
