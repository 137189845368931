import {listPartners} from 'src/appApi';
import {SET_PARTNERS} from '../actionNames';
import {PartnersStateType} from './partners.reducer';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';

const setPartners = (payload: PartnersStateType['partners']) => {
  return {
    type: SET_PARTNERS,
    payload,
  };
};

export const fetchPartners = () => {
  return async (dispatch: any) => {
    try {
      const response = await listPartners();
      dispatch(setPartners(response.data.results));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch partners`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
