import React from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import TickIcon from 'src/icons/TickIcon';
import {useSelector} from 'react-redux';
import {
  selectQueriesReducer,
  selectQuery,
} from 'src/store/queries/queries.selector';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import {ReduxStateType} from 'src/store/store';
import {isQueryResolvedByCpaOrTerminallyResolved} from 'src/store/queries/queries.utils';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {DerivedMessageType} from 'src/store/queries/queries.reducer';
import {stringClipper} from 'src/common/utils';

interface MessageStickyHeaderProps {
  onResolve: () => Promise<any>;
  isLoading: boolean;
}

const MessageListStickyHeader = ({
  onResolve,
  isLoading,
}: MessageStickyHeaderProps) => {
  const {loaded} = useSelector(selectQueriesReducer);
  const {queryId} = useCurrentQueryId();
  const query = useSelector((state: ReduxStateType) =>
    selectQuery(state, queryId),
  );
  const {MESSAGE_STICKY_HEADER_HEIGHT} = useLayout();
  if (!query) {
    return null;
  }
  const isResolved = loaded && isQueryResolvedByCpaOrTerminallyResolved(query);

  const areAllMessagesDraft = query.messages.every(
    (message) =>
      message.derivedMessageType === DerivedMessageType.DRAFT_MESSAGE,
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingInline: 10,
        paddingRight: 20,
        backgroundColor: themmeColor.offGrey,
        height: MESSAGE_STICKY_HEADER_HEIGHT,
      }}>
      <Typography
        style={{
          marginLeft: 4,
          fontSize: 16,
          fontWeight: 600,
        }}>
        {loaded && stringClipper(query.query, 64)}
      </Typography>
      {isResolved ? (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <TickIcon />
          <Typography
            style={{
              color: themmeColor.successGreen,
              marginLeft: 4,
              fontSize: 13,
            }}>
            Resolved
          </Typography>
        </div>
      ) : (
        <DSButton
          disabled={!loaded || isLoading || isResolved || areAllMessagesDraft}
          type="primary"
          text="Resolve"
          onClick={onResolve}
        />
      )}
    </div>
  );
};

export default MessageListStickyHeader;
