import {SET_CCH_STATUS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
export enum CCHStatus {
  BAEXC = 'BAEXC',
  BACMP = 'BACMP',
  BAINP = 'BAINP',
  BARTR = 'BARTR',
  BASTD = 'BASTD',
  BATRD = 'BATRD',
}

export interface CCHStatusType {
  status: CCHStatus;
  error_msg: string | null;
  last_synced_at: Date | null;
}

export interface CCHSubsectionStatus {
  [subsection: string]: CCHStatusType;
}

export interface CCHStatusInfo {
  [section: string]: CCHSubsectionStatus;
}

const initialState = {
  status_info: {} as CCHStatusInfo,
  sections: [] as string[],
  sectionWiseSubsection: {} as {[key: string]: []},
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const cchStatus = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_CCH_STATUS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type CCHStatusStateType = typeof initialState;
