import {ReduxStateType} from '../store';

export const selectBoiData = (state: ReduxStateType) => ({
  data: state.boi.data,
  loaded: state.boi.loaded,
});

export const selectBoiBusiness = (state: ReduxStateType) => ({
  business: state.boi.business,
  loaded: state.boi.loaded,
});
