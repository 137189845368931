import {Button} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import Text from '../Form/common/Text/Text';
import {FONT_WEIGHTS, TEXT_TYPES} from '../Form/common/Text/Text.types';

interface ButtonProps {
  type: 'primary' | 'secondary' | 'tertiary';
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  textColor?: string;
  disabledTextColor?: string;
  bgColor?: string;
  disabledBgColor?: string;
  borderColor?: string;
  disabledBorderColor?: string;
  style?: React.CSSProperties;
  startIcon?: any;
  endIcon?: any;
  textType?: TEXT_TYPES;
  textFontWeight?: FONT_WEIGHTS;
}

const DSButton = ({
  type,
  text,
  onClick,
  disabled,
  textColor,
  disabledTextColor,
  disabledBgColor,
  bgColor,
  borderColor,
  disabledBorderColor,
  style = {},
  startIcon = undefined,
  endIcon = undefined,
  textType = TEXT_TYPES.S,
  textFontWeight = FONT_WEIGHTS.SemiBold,
}: ButtonProps) => {
  const getTextColor = () => {
    if (type === 'primary') {
      if (disabled) {
        return disabledTextColor ?? themmeColor.white;
      }
      return textColor ?? themmeColor.white;
    }
    if (type === 'secondary' || type === 'tertiary') {
      if (disabled) {
        return disabledTextColor ?? themmeColor.silver;
      }
      return textColor ?? themmeColor.black;
    }
  };

  const getBackgroundColor = () => {
    if (type === 'primary') {
      if (disabled) {
        return disabledBgColor ?? themmeColor.silver;
      }
      return bgColor ?? themmeColor.black;
    }
    if (type === 'secondary' || type === 'tertiary') {
      if (disabled) {
        return disabledBgColor ?? 'transparent';
      }
      return bgColor ?? 'transparent';
    }
  };

  const getBorderColor = () => {
    if (type === 'secondary') {
      if (disabled) {
        return disabledBorderColor ?? themmeColor.silver;
      }
      return borderColor ?? themmeColor.black;
    }
  };

  const getVariant = () => {
    if (type === 'primary') {
      return 'contained';
    }
    if (type === 'secondary') {
      return 'outlined';
    }
    return 'text';
  };

  const color = getTextColor();
  const backgroundColor = getBackgroundColor();
  const brColor = getBorderColor();

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      style={{
        color,
        backgroundColor,
        borderColor: brColor,
        textTransform: 'none',
        borderRadius: 8,
        ...style,
      }}
      variant={getVariant()}
      onClick={(e) => onClick(e)}
      disabled={disabled}>
      <Text type={textType} text={text} fontWeight={textFontWeight} />
    </Button>
  );
};

export default DSButton;
