const RightTriangle = ({color}: {color: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="22"
      viewBox="0 0 7 22"
      fill="none">
      <path d="M0 0L7 11L0 22V0Z" fill={color} />
    </svg>
  );
};


export default RightTriangle;
