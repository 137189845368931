import React, {useContext, useState} from 'react';

interface DraftEditModeProps {
  isDraftEditMode: boolean;
  draftMessageId: number;
}

interface ContextProps {
  draftEditMode: DraftEditModeProps;
  setDraftEditMode: (props: DraftEditModeProps) => void;
  message: string;
  setMessage: (newVal: string) => void;
  isLoading: boolean;
  setIsLoading: (newVal: boolean) => void;
  hasClickedOnReopen: boolean;
  setHasClickedOnReopen: (newVal: boolean) => void;
  files: any[];
  setFiles: (newFiles: any[]) => void;
}

const MessageListContext = React.createContext<ContextProps>({
  draftEditMode: {
    isDraftEditMode: false,
    draftMessageId: -1,
  },
  setDraftEditMode: () => {},
  message: '',
  setMessage: () => {},
  isLoading: false,
  setIsLoading: () => {},
  hasClickedOnReopen: false,
  setHasClickedOnReopen: () => {},
  files: [],
  setFiles: () => {},
});

export const MessageListProvider = ({children}) => {
  const [draftEditMode, setDraftEditMode] = useState<DraftEditModeProps>({
    isDraftEditMode: false,
    draftMessageId: -1,
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasClickedOnReopen, setHasClickedOnReopen] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  return (
    <MessageListContext.Provider
      value={{
        draftEditMode,
        setDraftEditMode,
        message,
        setMessage,
        isLoading,
        setIsLoading,
        hasClickedOnReopen,
        setHasClickedOnReopen,
        files,
        setFiles,
      }}>
      {children}
    </MessageListContext.Provider>
  );
};

export const useMessageList = () => {
  const value = useContext(MessageListContext);
  return value;
};
