import React from 'react';

const TaxProfileIcon = ({color = '#555555'}: {color?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <circle
        cx="12.0953"
        cy="7.47814"
        r="4.47619"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M6.28152 15.002H9.58241H14.418H17.7195C19.0919 15.002 20.2783 15.9839 20.5711 17.3612L20.8684 19.885C20.9384 20.4795 20.4739 21.002 19.8753 21.002H4.12486C3.52616 21.002 3.06162 20.4794 3.13175 19.8848L3.42937 17.3612C3.72214 15.9839 4.9092 15.002 6.28152 15.002Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default TaxProfileIcon;
