import {BackendTransaction} from 'src/appApi.types';

export const parseSingleTransaction = ({
  id: txnId,
  bucket,
  classification_status: classificationStatus,
  current_amount: currentAmount,
  expense_sub_category: expenseSubCategory,
  irs_category: irsCategory,
  is_pending: isPending,
  is_reviewed: isReviewed,
  merchant_name: merchantName,
  original_amount: originalAmount,
  tax_deductible_amount: taxDeductibleAmount,
  txn_category: txnCategory,
  txn_date: txnDate,
  updated_classification_status: updatedClassificationStatus,
  updated_merchant_name: updatedMerchantName,
  updated_expense_category: updatedExpenseCategory,
}: BackendTransaction) => {
  const txn = {
    txnId,
    bucket,
    classificationStatus,
    currentAmount,
    expenseSubCategory,
    irsCategory,
    isPending,
    isReviewed,
    merchantName,
    originalAmount,
    taxDeductibleAmount,
    txnCategory,
    txnDate,
    updatedClassificationStatus,
    updatedMerchantName,
    updatedExpenseCategory,
  };
  return txn;
};
