import {TaxProfileSubsection} from 'src/constants/constants';
import {TAX_DEDUCTION_TYPE} from 'src/store/deductionSummary/deductionSummary.reducer';

export const getTaxDeductionSectionSchedule = (
  section: TaxProfileSubsection,
) => {
  if (
    [
      TaxProfileSubsection.HSA,
      TaxProfileSubsection.HSAContributions,
      TaxProfileSubsection.IRAContributions,
    ].includes(section)
  ) {
    return TAX_DEDUCTION_TYPE.SCH_1;
  }
  if ([TaxProfileSubsection.PersonalDeductions].includes(section)) {
    return TAX_DEDUCTION_TYPE.SCH_A;
  }
  return TAX_DEDUCTION_TYPE.SCH_C;
};
