import React, {useMemo, useRef} from 'react';
import JoditEditor from 'jodit-react';

interface RichTextEditorProps {
  message: string;
  setMessage: (message: string) => void;
  disabled?: boolean;
  placeholder?: string;
  height?: number | string;
}

// https://xdsoft.net/jodit/start.html refer this link for docs

const EDITOR_TOOLBAR_OPTIONS = [
  'ol',
  'ul',
  'link',
  // 'undo',
  // 'redo',
  // 'bold',
  // 'underline',
  // 'italic',
  // 'font',
  // 'fontsize',
  // 'preview',
  // 'brush',
];

const RichTextEditor = ({
  message,
  setMessage,
  disabled = false,
  placeholder = '',
  height = 200,
}: RichTextEditorProps) => {
  const editorRef = useRef(null);

  const config = useMemo(() => {
    return {
      buttons: EDITOR_TOOLBAR_OPTIONS,
      toolbarButtonSize: 'small',
      language: 'en',
      enter: 'br',
      disabled,
      showPlaceholder: true,
      placeholder,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      height,
      minHeight: height,
      askBeforePasteHTML: true,
      spellcheck: true,
    };
  }, [disabled, placeholder, height]);

  return (
    <JoditEditor
      ref={editorRef}
      tabIndex={1}
      value={message}
      onChange={(newVal) => {
        setMessage(newVal);
      }}
      config={config}
    />
  );
};

export default RichTextEditor;
