import {TaxReturn} from './taxReturns.reducer';

export const parseSingleReturn: (taxReturn: TaxReturn) => TaxReturn = (
  taxReturn,
) => {
  return {
    ...taxReturn,
    return_expiry: new Date(taxReturn.return_expiry),
  };
};
