import React, {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useUploadDocument from 'src/CpaCenterV2/hooks/useUploadDocument';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DuplicateFilesDrawer from './DuplicateFilesDrawer';
import Dropzone from 'src/DesignSystem/Dropzone/Dropzone';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';

const useUploadPartnerReturnDocumentInternal = () => {
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const [filesUploadingPopup, setFilesUplodingPopup] = useState(false);
  const [ocrIdsInProgress, setOcrIdsInProgress] = useState<number[]>([]);
  const [duplicateFiles, setDuplicateFiles] = useState<File[]>([]);
  const dispatch = useDispatch();
  const {documents, loaded} = useSelector(selectDocuments);
  const {
    uploadPartnersDocumentOnS3,
    postBulkDocumentForOcr,
    getPartnerOcrDocsData,
  } = useUploadDocument();
  const {navigateToDocumentList} = usePartnershipNavigation();

  const {notify} = useNotify();

  const onUploadFiles = async (files: File[], ignoreConflicts = false) => {
    try {
      setFilesUplodingPopup(true);
      const alreadyUploadedFileNames = new Set(
        documents.map((doc) => doc.filename),
      );
      const noConflictFiles: File[] = [],
        filesWithConflictingName: File[] = [];
      files.forEach((file) => {
        if (alreadyUploadedFileNames.has(file.name) && !ignoreConflicts) {
          filesWithConflictingName.push(file);
        } else {
          noConflictFiles.push(file);
        }
      });
      if (noConflictFiles.length > 0) {
        const uploadedFilesInfo = await uploadPartnersDocumentOnS3(
          noConflictFiles,
          {fly_user_id: userId, year: activeYear},
        );
        const {ocr_job_ids} = await postBulkDocumentForOcr({
          filesdata: uploadedFilesInfo,
        });
        setOcrIdsInProgress((prev) => [...prev, ...ocr_job_ids]);
        getPartnerOcrDocsData(ocr_job_ids).then((response) => {
          dispatch(fetchDocumentsForGivenYear(userId, activeYear));
          setOcrIdsInProgress((prevIds) => {
            const ocrCompleteIds = new Set(
              response.data.tax_docs_data.map((doc) => doc.ocr_job_id),
            );
            if (ocrCompleteIds.size > 0) {
              dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
            }
            // if ocr not completed then keep it in array
            return prevIds.filter((id) => !ocrCompleteIds.has(id));
          });
        });
        navigateToDocumentList();
      }
      if (filesWithConflictingName.length > 0) {
        setDuplicateFiles((prev) => [...prev, ...filesWithConflictingName]);
      }
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setFilesUplodingPopup(false);
    }
  };

  const onUploadDuplicateFiles = async () => {
    const duplicateFilesToUpload = [...duplicateFiles];
    setDuplicateFiles([]);
    await onUploadFiles(duplicateFilesToUpload, true);
  };

  return {
    filesUploadingPopup,
    setFilesUplodingPopup,
    duplicateFiles,
    setDuplicateFiles,
    ocrIdsInProgress,
    setOcrIdsInProgress,
    onUploadFiles,
    onUploadDuplicateFiles,
  };
};

const UploadPartnerReturnDocumentContext = React.createContext<
  ReturnType<typeof useUploadPartnerReturnDocumentInternal>
  // @ts-ignore
>(null);

export const UploadPartnerReturnDocumentProvider = ({children}: any) => {
  const value = useUploadPartnerReturnDocumentInternal();
  const {
    filesUploadingPopup,
    duplicateFiles,
    onUploadFiles,
    setDuplicateFiles,
    onUploadDuplicateFiles,
  } = value;

  return (
    <UploadPartnerReturnDocumentContext.Provider value={value}>
      <Dropzone
        disabled={filesUploadingPopup || duplicateFiles.length > 0}
        onDropFiles={onUploadFiles}
        style={{overflow: 'hidden'}}>
        {children}
        <PopUp
          isOpen={filesUploadingPopup}
          onClose={() => {}}
          style={{
            width: 500,
            height: 200,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <Text
            type={TEXT_TYPES.H6}
            fontWeight={FONT_WEIGHTS.SemiBold}
            text={'Uploading files...'}
          />
        </PopUp>
        <DuplicateFilesDrawer
          duplicateFiles={duplicateFiles}
          setDuplicateFiles={setDuplicateFiles}
          onUploadFiles={onUploadDuplicateFiles}
        />
      </Dropzone>
    </UploadPartnerReturnDocumentContext.Provider>
  );
};

export const useUploadPartnerReturnDocument = () => {
  return useContext(UploadPartnerReturnDocumentContext);
};
