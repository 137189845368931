import React, {useState} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import {themmeColor} from 'src/constants/constants';
import {useLocation, useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import {TabActionCountProps} from 'src/appApi';
import {UserInfoRoute} from 'src/constants/routeName';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  tabButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px',
    borderBottom: `solid 1px ${themmeColor.offWhite}`,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: ({selected}) =>
      selected ? themmeColor.darkBlue : themmeColor.offWhite,
    borderBottomWidth: ({selected}) => (selected ? '3px' : '1px'),
    position: 'relative',
  },
  tabButtonActionContainer: {
    position: 'absolute',
    right: 2,
    top: 2,
    backgroundColor: 'red',
    borderRadius: 100,
    height: 16,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});

const TabButton = ({
  label,
  navigationLink,
  selected,
  setCurrentSelection,
  actionCount,
  ...rest
}: {
  label: string;
  image: any;
  selected?: boolean;
  navigationLink: string;
  actionCount?: number;
  setCurrentSelection: Function;
}) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(navigationLink);
    setCurrentSelection(navigationLink);
  };

  const styles = useStyles({selected});

  return (
    <div
      className={styles.tabButtonContainer}
      style={{
        backgroundColor:
          hovered || selected ? themmeColor.focusedBlue : undefined,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleOnClick}>
      {actionCount !== 0 && !!actionCount && (
        <div className={styles.tabButtonActionContainer}>
          <Typography color={themmeColor.white} fontSize={10}>
            {actionCount}
          </Typography>
        </div>
      )}
      <rest.image />
      <Typography fontSize={13} fontWeight="600">
        {label}
      </Typography>
    </div>
  );
};

const TabSwitchComponent = ({
  taxProfileActionCount,
  documentOCRActionCount,
  taxQueriesActionCount,
  reviewEsignActionCount,
}: TabActionCountProps) => {
  const {pathname} = useLocation();
  const tabsData = [
    {
      label: 'Tax Profile',
      image: AccountCircleIcon,
      navigationLink: UserInfoRoute.TaxProfile,
      actionCount: taxProfileActionCount,
    },
    {
      label: 'Documents & OCR',
      image: DocumentScannerIcon,
      navigationLink: UserInfoRoute.DocumentsOCR,
      actionCount: documentOCRActionCount,
    },
    {
      label: 'Tax Queries',
      image: QuestionAnswerIcon,
      navigationLink: UserInfoRoute.TaxQueries,
      actionCount: taxQueriesActionCount,
    },
    {
      label: 'Review & esign',
      image: PreviewIcon,
      navigationLink: UserInfoRoute.UserRES,
      actionCount: reviewEsignActionCount,
    },
    {
      label: 'CCH',
      image: IntegrationInstructionsIcon,
      navigationLink: UserInfoRoute.UserCCH,
      actionCount: 0,
    },
  ];

  const [currentSelection, setCurrentSelection] = useState(pathname);

  return (
    <div>
      {tabsData.map((item) => (
        <TabButton
          selected={currentSelection.includes(item.navigationLink)}
          {...item}
          setCurrentSelection={setCurrentSelection}
        />
      ))}
    </div>
  );
};

export default TabSwitchComponent;
