import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {postTaxProfileReviewStatus} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import TickIcon from 'src/icons/TickIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {Typography} from '@mui/material';
import {selectTaxProfileCpaSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {BOI_SECTION_NAMES} from '../types';
import {ReduxStateType} from 'src/store/store';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {selectTaxProfileAnswerForGivenYear} from 'src/store/taxProfile/taxProfile.selector';
import {selectBoiData} from 'src/store/boi/boi.selectors';
import {isEligibleForBoi} from '../utils';

interface Props {
  name: string;
  boiSubsection: BOI_SECTION_NAMES;
  children?: any;
  width?: any;
  style?: React.CSSProperties;
  year: number;
  showIsEligibleTag?: boolean;
}

const BoiSubSectionBox = ({
  name,
  children,
  width,
  style,
  boiSubsection,
  year,
  showIsEligibleTag = false,
}: Props) => {
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();
  const {taxProfileCpaSideStatus, loaded} = useSelector(
    selectTaxProfileCpaSideStatus,
  );
  const {data} = useSelector(selectBoiData);

  const answers = useSelector((state: ReduxStateType) => {
    return {
      [TAX_PROFILE_FIELDS.IS_BOI_REQUIRED]: selectTaxProfileAnswerForGivenYear(
        state,
        TAX_PROFILE_FIELDS.IS_BOI_REQUIRED,
        data.year,
      ),
      [TAX_PROFILE_FIELDS.BUSINESS_FORMED_OR_REGISTERED_IN_USA]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.BUSINESS_FORMED_OR_REGISTERED_IN_USA,
          data.year,
        ),
      [TAX_PROFILE_FIELDS.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.BUSINESS_CREATED_OR_REGISTERED_BEFORE_JAN_2024,
          data.year,
        ),
      [TAX_PROFILE_FIELDS.BUSINESS_LLC_OR_C_CORP]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.BUSINESS_LLC_OR_C_CORP,
          data.year,
        ),
      [TAX_PROFILE_FIELDS.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.BUSINESS_FORMED_FILING_DOCS_WITH_STATE_SECRETARY,
          data.year,
        ),
      [TAX_PROFILE_FIELDS.SELECT_POINTS_APPLICABLE_FOR_BUSINESS]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.SELECT_POINTS_APPLICABLE_FOR_BUSINESS,
          data.year,
        ),
      [TAX_PROFILE_FIELDS.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES]:
        selectTaxProfileAnswerForGivenYear(
          state,
          TAX_PROFILE_FIELDS.BUSINESS_COMES_UNDER_GIVEN_CATEGORIES,
          data.year,
        ),
    };
  });

  const [isLoading, setIsLoading] = useState(false);

  const isComplete = taxProfileCpaSideStatus[boiSubsection];

  const onClickDone = async () => {
    try {
      setIsLoading(true);
      await postTaxProfileReviewStatus(userId, year, {
        [boiSubsection]: TaxProfileReviewStatus.COMPLETE,
      });
      await dispatch(fetchTaxProfileStatusForGivenYear(userId, year));
      notify(
        `Review for subsection ${boiSubsection} complete`,
        NotificationType.success,
      );
    } catch (e) {
      notify(`Error in marking step complete ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSectionStatus = () => {
    if (isComplete) {
      return <TickIcon />;
    }

    return (
      <DSButton
        disabled={isLoading || !loaded}
        text="Mark as reviewed"
        type="secondary"
        onClick={onClickDone}
      />
    );
  };

  const getEligibility = () => {
    if (showIsEligibleTag) {
      const isEligible = isEligibleForBoi(answers);
      return (
        <div
          style={{
            marginLeft: 12,
            borderRadius: 20,
            padding: '2px 12px',
            backgroundColor: '#EBFFD7',
          }}>
          <Typography style={{fontSize: 11, fontWeight: 600}}>
            {isEligible ? 'STATUS:  ELIGIBLE' : 'STATUS: NOT ELIGIBLE'}
          </Typography>
        </div>
      );
    }

    return <></>;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        borderRadius: 12,
        backgroundColor: themmeColor.white,
        margin: 16,
        width,
        ...style,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
          height: 36,
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography style={{fontSize: 16, fontWeight: 600}}>
            {name}
          </Typography>
          {getEligibility()}
        </div>
        {getSectionStatus()}
      </div>
      {children}
    </div>
  );
};

export default BoiSubSectionBox;
