import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';

interface NormalReadOnlyCellProps {
  value: string | number;
  color?: string;
}

const NormalReadOnlyCell = ({value, color = themmeColor.grey}: NormalReadOnlyCellProps) => {
  return <Typography color={color}>{value}</Typography>;
};

export default NormalReadOnlyCell;
