import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import NotesIcon from 'src/icons/NotesIcon';
import ResetIcon from 'src/icons/ResetIcon';
import Skeleton from 'src/icons/Skeleton';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {ReduxQueryType} from 'src/store/queries/queries.reducer';
import {selectQueriesForCurrReturn} from 'src/store/queries/queries.selector';
import {QueryFilterKeys, QueryFilterType} from '../UserQueries.types';
import {getFilteredQueries, getQueriesFilters} from '../UserQueries.utils';
import MessageList from './Messages/MessageList';
import Queries from './Queries';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {ReduxStateType} from 'src/store/store';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const getOptionLabel = (option: QueryFilterType) => {
  if (QueryFilterKeys.docType in option) {
    return option[QueryFilterKeys.docType];
  } else if (QueryFilterKeys.subsection in option) {
    return option[QueryFilterKeys.subsection];
  } else if (QueryFilterKeys.highValueDeduction in option) {
    return 'Review deductions';
  } else if (QueryFilterKeys.userTaxProfile in option) {
    return 'User Tax Profile';
  } else if (QueryFilterKeys.general in option) {
    return 'General';
  } else if (QueryFilterKeys.reviewEsign in option) {
    return 'Review Esign';
  } else if (QueryFilterKeys.distributeDeductions in option) {
    return 'Distribute Deductions';
  } else if (QueryFilterKeys.taxFilingFeedback in option) {
    return 'Feedback query';
  } else if (QueryFilterKeys.personalReturnApprovedFromIRS in option) {
    return 'Approval query';
  }
};

const getFilterAppliedQueries = (
  queries: ReduxQueryType[],
  selectedFilter: QueryFilterType | null,
) => {
  let filteredQueries = queries;
  if (selectedFilter !== null) {
    if (QueryFilterKeys.docType in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.docData?.docType === selectedFilter[QueryFilterKeys.docType],
      );
    } else if (QueryFilterKeys.subsection in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.taxProfileSubsection ===
          selectedFilter[QueryFilterKeys.subsection],
      );
    } else if (QueryFilterKeys.highValueDeduction in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.highValueDeduction],
      );
    } else if (QueryFilterKeys.userTaxProfile in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.userTaxProfile],
      );
    } else if (QueryFilterKeys.general in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType === selectedFilter[QueryFilterKeys.general],
      );
    } else if (QueryFilterKeys.reviewEsign in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.reviewEsign],
      );
    } else if (QueryFilterKeys.distributeDeductions in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.distributeDeductions],
      );
    } else if (QueryFilterKeys.taxFilingFeedback in selectedFilter) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.taxFilingFeedback],
      );
    } else if (
      QueryFilterKeys.personalReturnApprovedFromIRS in selectedFilter
    ) {
      filteredQueries = filteredQueries.filter(
        (query) =>
          query.derivedQueryType ===
          selectedFilter[QueryFilterKeys.personalReturnApprovedFromIRS],
      );
    }
  }
  return filteredQueries;
};

const UserQueriesSection = ({
  returnId,
  returnType,
}: {
  returnId?: number | null;
  returnType?: TaxReturnType | null;
}) => {
  const {queries, loaded} = useSelector((state: ReduxStateType) =>
    selectQueriesForCurrReturn(
      state,
      returnId == null || returnType == null
        ? null
        : {
            return_id: returnId,
            return_type: returnType,
          },
    ),
  );
  const {navigateToDocumentReview, navigateToMessagesView} =
    useCpaCenterV2Navigation();
  const dispatch = useDispatch();
  const {documents} = useSelector(selectDocuments);
  const {activeYear} = useActiveYear();

  const {userId} = useCurrentUserId();

  const [selectedFilter, setSelectedFilter] = useState<QueryFilterType | null>(
    null,
  );

  // This will get all the filters that can be applied on the queries
  const queriesFilters = getQueriesFilters(queries);

  let filteredQueries = getFilteredQueries(queries);
  filteredQueries = getFilterAppliedQueries(filteredQueries, selectedFilter);

  const onChangeFilter = (value: QueryFilterType) => {
    setSelectedFilter(value);
  };

  const {USER_QUERIES_SECTION_BAR, spaceLeftBelowTaxFilingSteps} = useLayout();

  const pendingForReviewDocCount = documents.filter(
    (doc) => doc.docStatus === VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW,
  ).length;

  return (
    <div
      style={{
        height: spaceLeftBelowTaxFilingSteps,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBlock: 8,
          height: USER_QUERIES_SECTION_BAR,
        }}>
        {/* Header */}
        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
          <Typography style={{fontSize: 20, fontWeight: 800, marginRight: 12}}>
            User queries ({filteredQueries.length})
          </Typography>
          <div style={{width: 350}}>
            {/* Dropdown */}
            <Dropdown
              options={queriesFilters}
              value={selectedFilter}
              onChange={onChangeFilter}
              placeholder={'Select query Filter'}
              getOptionLabel={getOptionLabel}
              maxWidth
            />
          </div>
        </div>
        <div style={{display: 'flex', marginRight: 10}}>
          <Box
            style={{padding: 10, marginRight: 8}}
            backgroundColor={themmeColor.black}
            hoverEffect
            onClick={() => dispatch(fetchQueries({userId, year: activeYear}))}>
            <ResetIcon />
          </Box>
          <Box
            style={{padding: 10, marginRight: 8, position: 'relative'}}
            backgroundColor={themmeColor.black}
            hoverEffect
            onClick={navigateToDocumentReview}>
            <NotesIcon color={themmeColor.white} />
            <div
              style={{
                position: 'absolute',
                top: -4,
                right: -8,
                backgroundColor: 'red',
                borderRadius: 100,
                padding: 4,
              }}>
              <Typography
                style={{
                  fontSize: 11,
                  color: themmeColor.white,
                  fontWeight: 800,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {pendingForReviewDocCount}
              </Typography>
            </div>
          </Box>
          <DSButton
            type={'secondary'}
            onClick={() =>
              dispatch(
                setAppState(AppReducerStates.createQueryDrawerStatus, {
                  isVisible: true,
                  selectedTab: CreateQueryDrawerTab.GENERAL,
                  selectedDropdown: null,
                  currentReturnId: returnId,
                  currentReturnType: returnType,
                }),
              )
            }
            text={'Add'}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          borderRadius: 12,
          overflow: 'hidden',
          backgroundColor: themmeColor.white,
          border: `solid 1px ${themmeColor.offWhite}`,
          height: spaceLeftBelowTaxFilingSteps - USER_QUERIES_SECTION_BAR,
        }}>
        <div
          style={{
            width: '23vw',
            overflowY: 'auto',
          }}>
          {!loaded ? (
            <div>
              <Skeleton width={'95%'} height={120} />
              <Skeleton width={'95%'} height={120} />
              <Skeleton width={'95%'} height={120} />
              <Skeleton width={'95%'} height={120} />
            </div>
          ) : (
            <Queries
              onClick={(qId) =>
                navigateToMessagesView(qId, returnId, returnType)
              }
              queries={filteredQueries}
            />
          )}
        </div>
        <div
          style={{
            height: spaceLeftBelowTaxFilingSteps - USER_QUERIES_SECTION_BAR,
            width: '52vw',
          }}>
          <MessageList
            offset={spaceLeftBelowTaxFilingSteps - USER_QUERIES_SECTION_BAR}
          />
        </div>
      </div>
    </div>
  );
};

export default UserQueriesSection;
