import React from 'react';
import {useSelector} from 'react-redux';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectAppReducerState} from 'src/store/app/app.selector';
import {ReduxStateType} from 'src/store/store';
import TaxProfileSummary from './components/TaxProfileSummary';

const TaxProfileSummaryDrawer = () => {
  const isTaxProfileSummaryDrawer = useSelector<ReduxStateType>((state) =>
    selectAppReducerState(state, AppReducerStates.isTaxProfileSummaryDrawer),
  ) as boolean;

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      height={'90vh'}
      isOpen={isTaxProfileSummaryDrawer}>
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          marginTop: 4,
          position: 'absolute',
          height: '90vh',
        }}>
        <TaxProfileSummary />
      </div>
    </Drawer>
  );
};

export default TaxProfileSummaryDrawer;
