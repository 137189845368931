import {TaxProfileField} from './store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from './store/taxProfile/taxProfile.types';
import {ReturnFields} from './Partnership/PartnershipUserList/components/CreateReturnComponents/CreateReturn.types';
import {TaxFormType} from './store/taxForms/taxForms.types';
import {
  BusinessDetailsField,
  BusinessType,
} from './store/businessDetails/businessDetails.types';
import {
  ESIGN_MODE,
  TF_SEGMENT,
  USER_COMMS_MODE,
} from './store/user/user.reducer';
import {ValueField} from './DesignSystem/OCR/OCR.types';

export interface TaxFilingStatus {
  results: Array<{
    id: number;
    fly_user_id: number;
    year: number;
    registered_state: null;
    status_updated_by: string;
    status_updated_at: string;
    federal_returns: null;
    federal_returns_updated_by: null;
    federal_returns_updated_at: null;
    state_returns: null;
    state_returns_updated_by: null;
    state_returns_updated_at: null;
    fly_user_name: 'a b';
    federal_returns_type: null;
    state_returns_type: null;
    email: string;
    user_filing_status: string;
    notes: string;
    next_action_date: string;
    last_status_change_date: string;
    cpa_facing_ret_status: number;
    tax_extension_status: null;
    cch_client_id: string;
    comms_mode: USER_COMMS_MODE;
    esign_mode: ESIGN_MODE;
    date_of_debit: string;
    tax_filing_plan_name: string;
    tf_segment: TF_SEGMENT;
    partner_name: string;
  }>;
  count: number;
}

interface TaxFilingDoc {
  id: number;
  status: string;
  year: number;
  links: Array<{
    id: number;
    status: string;
    reason: string;
    filename: string;
    doc_link: string;
    extras: {
      [key: string]: any;
    };
    error_msg: null | string;
    linked_business_id: number | null;
  }>;
  doc_type: string;
  no_upload_reason: string | null;
  ocr_required: boolean;
}

export type TaxFilingDocs = {
  forms: TaxFilingDoc[];
  required_docs: Array<string>;
};

export interface BackendTaxForm {
  doc_link: string;
  form_data: any;
  form_type: TaxFormType;
  id: number;
  is_end_user_reviewed: boolean;
  tfd_info_id: number;
  year: number;
  linked_business_id: number;
}

export interface BackendTransaction {
  id: number;
  posted_plaid_txn: any;
  fly_user: number;
  is_pending: boolean;
  merchant_name: string;
  merchant_cluster: any;
  txn_date: string;
  original_amount: number;
  current_amount: number;
  tax_deductible_amount: number;
  plaid_category: string;
  txn_category: string;
  expense_sub_category: string;
  irs_category: any;
  classification_status: string;
  notification_state: string;
  last_modified_by: string;
  state: string;
  created_at: string;
  bucket: string;
  fly_user_name: string;
  fly_user_id: number;
  plaid_removed: boolean;
  is_reviewed: boolean;
  updated_merchant_name: string;
  updated_expense_category: string;
  updated_classification_status: string;
}

enum StateReturnType {
  RESIDENT = 'RESIDENT',
  PART_YEAR_RESIDENT = 'PART_YEAR_RESIDENT',
  NOT_RESIDENT = 'NOT_RESIDENT',
  TAX_FILING_NOT_NEEDED = 'TAX_FILING_NOT_NEEDED',
}

export interface BackendStateReturnsInfo {
  states_info: StatesInfo[];
  business_tax_returns_info: BackendBusinessTaxReturnInfo[];
  is_driving_license_required: boolean;
}

export interface StatesInfo {
  state: string;
  return_type: StateReturnType;
  minimum_days?: number;
  related_income_forms: RelatedIncomeForm[];
  state_img_url: string;
  lived_count?: number;
  is_current_address: boolean;
  is_city_tax_filing_maybe_needed: boolean;
}

export interface BackendBusinessTaxReturnInfo {
  related_businesses: {
    entity_type: BusinessType;
    id: number;
    name: string;
  }[];
  related_shareholders: {
    first_name_and_middle_initial: string;
    last_name: string;
    id: string;
    related_business_id: number;
    business_name: string;
  }[];
  state: string;
  state_img_url: string;
  is_city_tax_filing_maybe_needed: boolean;
}

interface RelatedIncomeForm {
  tax_form_id: number;
  filename: string;
  doc_link: string;
  form_type: string;
  doc_id: number;
}

enum CCH_STATUS {
  BAEXC = 'BAEXC',
  BACMP = 'BACMP',
  BAINP = 'BAINP',
  BARTR = 'BARTR',
  BASTD = 'BASTD',
  BATRD = 'BATRD',
}

export interface ResponseCCHStatus {
  status_info: {
    [key1: string]: {
      [key2: string]: {
        status: CCH_STATUS;
        error_msg: string;
        last_synced_at: string;
      };
    };
  };
}
export interface QtcEndUserData {
  input_data: {
    biz_deductions: number;
    deduction_option: string;
    tax_profile: {[key: number]: any};
  };
  federal_tax_details: {
    federal_annual_tax: number;
    federal_quarterly_tax: number;
    calculation_defs: any;
    income_tax: any;
    taxes_paid: any;
    se_tax: any;
  };
  state_tax_details: {
    [key: string]: {
      state_abbreviation: string;
      state_annual_tax: number;
      state_quarterly_tax: number;
      calculation_defs: any;
      income_tax: any;
      taxes_paid: any;
      zero_tax_state: boolean;
    };
  };
  total_tax_to_pay: number;
  created_at: string;
  quarter: number;
  year: number;
}

export interface QtcAIData {
  calculated_by: string | null;
  calculated_date: string | null;
  federal_tax_details: {
    federal_annual_tax: number;
    federal_quarterly_tax: number;
    federal_payments_this_qtr: number;
    calculation_defs: any;
    taxes_paid: any;
    input_data: {
      agi_last_year: number;
      filing_status_last_year: FILING_STATUS_ANSWER;
      total_tax_last_year: number;
    };
  };
  state_tax_details: {
    [key: string]: {
      state_abbreviation: string;
      state_annual_tax: number;
      state_quarterly_tax: number;
      input_data: {
        agi_last_year: number;
        filing_status_last_year: FILING_STATUS_ANSWER;
        total_tax_last_year: number;
      };
      taxes_paid: any;
      zero_tax_state: boolean;
      state_payments_this_qtr: number;
    };
  };
  input_data: null;
  last_payment_added_date: string | null;
  quarter: number;
  tax_liability: number;
  tax_paid: number;
  tax_paid_this_quarter: number;
  total_tax_to_pay: number;
  year: number;
}
export interface QtcInputData {
  end_user: QtcEndUserData[];
  qt_payments: BackendTaxForm[];
  tax_form: QtcAIData;
}

interface S3UploadUrl {
  url: string;
  fields: {
    'Content-Type': string;
    key: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    policy: string;
    'x-amz-signature': string;
  };
  s3_key: string;
  filename: string;
}

export interface S3UploadUrls {
  urls: S3UploadUrl[];
}

interface PartnersListItem {
  id: number;
  partner_name: string;
  flyfin_poc_email: string | null;
  partner_poc_name: string;
  partner_poc_email: string;
  tax_return_count: number;
}

export interface PartnersListApiResponse {
  count: number;
  next: null | string;
  prev: null | string;
  results: PartnersListItem[];
}

export enum PartnershipReturnFields {
  tax_filing_id = 'tax_filing_id',
  fly_user_id = 'fly_user_id',
  name = 'name',
  return_status = 'return_status',
  tax_extension_status = 'tax_extension_status',
  year = 'year',
  partner = 'partner',
  assigned_cpa = 'assigned_cpa',
  reviewer = 'reviewer',
  activity = 'activity',
  created_at = 'created_at',
  notes = 'notes',
}

export interface PartnershipReturnsListItem {
  [PartnershipReturnFields.tax_filing_id]: number;
  [PartnershipReturnFields.fly_user_id]: number;
  [PartnershipReturnFields.name]: string;
  [PartnershipReturnFields.return_status]: any;
  [PartnershipReturnFields.tax_extension_status]: any;
  [PartnershipReturnFields.year]: number;
  [PartnershipReturnFields.partner]: string;
  [PartnershipReturnFields.assigned_cpa]: null;
  [PartnershipReturnFields.reviewer]: null;
  [PartnershipReturnFields.activity]: string;
  [PartnershipReturnFields.created_at]: string;
  [PartnershipReturnFields.notes]: string;
}

export interface PartnershipReturnCreationItem
  extends PartnershipReturnsListItem {
  [ReturnFields.tax_profile_questions]: {question_id: number; answer: any}[];
}

export interface PartnershipReturnsListApiResponse {
  count: number;
  next: null | string;
  prev: null | string;
  results: PartnershipReturnsListItem[];
}

export enum PartnersDocOcrStatus {
  pending = 'pending',
  success = 'success',
}

interface BusinessTypeObject {
  [ReturnFields.id]: string;
  [BusinessDetailsField.name]: ValueField;
  [BusinessDetailsField.entity_type]: ValueField;
  [BusinessDetailsField.ein]: ValueField;
  [ReturnFields.extra_data]: any;
}

interface PartnerDocOcrData {
  doc_type: string;
  [ReturnFields.ocr_job_id]: number;
  [ReturnFields.filename]: string;
  [ReturnFields.businesses]: BusinessTypeObject[];
  [ReturnFields.sole_prop_businesses]: any[];
  personal_details: {
    [TaxProfileField.FILING_STATUS]: ValueField;
    [TaxProfileField.FIRST_NAME]: ValueField;
    [TaxProfileField.LAST_NAME]: ValueField;
    [TaxProfileField.SSN]: ValueField;
    [TaxProfileField.SPOUSE_SSN]: ValueField;
  };
  read_url: string;
  s3_key: string;
}

export interface PartnersDocOcrDataResponse {
  status: PartnersDocOcrStatus;
  tax_docs_data: PartnerDocOcrData[];
}
