import {stringify} from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import {createHashHistory, createBrowserHistory} from 'history';

/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
const drfProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHttp = (type, resource, params) => {
    let url = '';
    let options = {};

    switch (type) {
      case CREATE:
        url = `${apiUrl}/${resource}/`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case GET_ONE:
        if (params.id === undefined) {
          // ***HACK***
          // to ignore the call for plaid_account/undefined and users/undefined.
          // This is being called ReferenceInput filter in expenselist.
          // Unable to figure out how to disable this at the source.
          // Setting the url to empty will disable the call from happening.
          url = '';
        } else {
          url = `${apiUrl}/${resource}/${params.id}/`;
        }
        break;
      case GET_LIST: {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const {filter} = params;
        const query = {
          page,
          per_page: perPage,
          ordering: `${order === 'ASC' ? '' : '-'}${field}`,
          ...filter,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const {filter, target, id} = params;
        const query = {
          page,
          per_page: perPage,
          ordering: `${order === 'ASC' ? '' : '-'}${field}`,
          ...filter,
          [target]: id,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;

        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        if (resource === 'dashboard/users') {
          url = `${apiUrl}/${resource}/delete_user_data/`;
          options.body = JSON.stringify({user_id: params.id});
        } else {
          url = `${apiUrl}/${resource}/${params.id}/`;
        }
        options.method = 'DELETE';
        break;
      case 'adjacent_txns':
      case 'similar_txns':
      case 'hist_txns': {
        const {txnId, count} = params;
        const query = {
          txn_id: txnId,
          count: count,
        };
        url = `${apiUrl}/${resource}/${type}/?${stringify(query)}`;
        break;
      }
      case 'getMerchantSuggestions': {
        url = `${apiUrl}/${resource}/fetch_merchant_suggestions/?${stringify(
          params,
        )}`;
        break;
      }
      case 'getUniqueMerchants': {
        url = `${apiUrl}/${resource}/unique_merchants/?${stringify(params)}`;
        break;
      }
      case 'getUniqueAlgoMerchants': {
        url = `${apiUrl}/${resource}/unique_algo_merchants/?${stringify(
          params,
        )}`;
        break;
      }
      case 'getUniqueMerchantClusters': {
        url = `${apiUrl}/${resource}/unique_merchant_clusters/?${stringify(
          params,
        )}`;
        break;
      }
      case 'getUniqueExpenseCategories': {
        url = `${apiUrl}/${resource}/unique_expense_categories/?${stringify(
          params,
        )}`;
        break;
      }
      case 'getTaxFilingLogs': {
        url = `${apiUrl}/${resource}/get_tax_filing_logs/?${stringify(
          params,
        )}`;
        break;
      }
      case 'bulk_partial_update': {
        // console.log(JSON.stringify(params))
        url = `${apiUrl}/${resource}/${type}/`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params);
        break;
      }
      case 'mark_reviewed':
      case 'bulk_review': {
        // console.log(JSON.stringify(params))
        url = `${apiUrl}/${resource}/${type}/`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params);
        break;
      }
      case 'batch': {
        // const { userId, year } = params;
        // const query = {
        //     user_id: userId,
        //     year: new Date().getFullYear(),
        // };
        url = `${apiUrl}/${resource}/initial_classification_done/`;
        options.method = 'POST';
        options.body = JSON.stringify(params);
        break;
      }
      case 'bulk_notify':
      case 'custom_notify':
      case 'suggest_merchant_name':
      case 'possible_deduction':
      case 'certain_deduction':
      case 'non_deduction':
      case 'send_monthly_plan_hidden_link': {
        url = `${apiUrl}/${resource}/${type}/`;
        options.method = 'POST';
        options.body = JSON.stringify(params);
        break;
      }
      case 'is_eligible_for_intervention': {
        url = `${apiUrl}/${resource}/${type}/?${stringify(params)}`;
        options.method = 'GET';
        break;
      }
      case 'getUniverse': {
        url = `${apiUrl}/${resource}/category_taxonomy/`;
        break;
      }

      case 'duplicate': {
        const {plaidTxnId} = params;
        url = `${apiUrl}/${resource}/${plaidTxnId}/duplicate/`;
        options.method = 'POST';
        break;
      }

      case 'create_mapping': {
        url = `${apiUrl}/${resource}/create_mapping/`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }
      case 'createMany': {
        url = `${apiUrl}/${resource}/bulk_create_csv/`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }

      case 'save_message': {
        url = `${apiUrl}/${resource}/save_message/`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }

      case 'getTxnSummaryForExportData': {
        url = `${apiUrl}/${resource}/get_export_data_txn_summary/?${stringify(
          params,
        )}`;
        break;
      }

      case 'export_data': {
        url = `${apiUrl}/${resource}/export_data/?${stringify(params)}`;
        break;
      }
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    return {url, options};
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Object} Data response
   */
  const convertHttpResponse = (response, type, resource, params) => {
    const {headers, json} = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if ('count' in json) {
          return {data: json.results, total: json.count};
        } else if (headers.has('content-range')) {
          return {
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
          };
        } else if ('detail' in json && json.detail === 'Invalid page.') {
          return {data: [], total: 0};
        } else {
          throw new Error(
            'The total number of results is unknown. The DRF data provider ' +
              'expects responses for lists of resources to contain this ' +
              "information to build the pagination. If you're not using the " +
              'default PageNumberPagination class, please include this ' +
              'information using the Content-Range header OR a "count" key ' +
              'inside the response.',
          );
        }
      case CREATE:
        return {data: {...params.data, id: json.id}};
      case DELETE:
        return {data: params.previousData};
      default:
        return {data: json};
    }
  };
  const resourceMap = {
    users: 'dashboard/users',
    expenses: 'dashboard/transaction',
    unreviewedexpenses: 'dashboard/transaction',
    categorizer: 'dashboard/categorizer',
    // 'plaid_item': 'dashboard/plaid_item',
    plaid_account: 'dashboard/plaid_account',
    plaid_txn: 'dashboard/plaid_txn',
    onb_questions: 'dashboard/onboarding_question',
    tax_filing_status: 'dashboard/tax_engine/tax_filing_status',
    export_data: 'dashboard/tax_engine/export_data',
    users_new_plaid_account:
      'dashboard/users/get_users_with_new_plaid_accounts',
    insert_txns_using_csv: 'dashboard/transaction/insert_txns_using_csv',
    tax_profile: 'dashboard/tax_engine/tax_profile',
    cpa_question: 'dashboard/cpa_question',
    conversation: 'dashboard/cpa_question_message',
  };

  /**
   * @param {String} type React-admin request type, e.g. 'GET_LIST'
   * @param {string} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if (type === 'adjacent_txns' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'similar_txns' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'hist_txns' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'getUniqueMerchants' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'getUniqueMerchantClusters' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'getUniqueExpenseCategories' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'bulk_classify' && resource !== 'expenses') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'batch' && resource !== 'notification') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'bulk_notify' && resource !== 'notification') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'getUniverse' && resource !== 'categorizer') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    if (type === 'duplicate' && resource !== 'plaid_txn') {
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    /**
     * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
     * since they're not supported by default.
     */
    if (resource in resourceMap) {
      resource = resourceMap[resource];
    }
    switch (type) {
      case GET_MANY:
        return Promise.all(
          params.ids.map((id) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: 'GET',
            }),
          ),
        ).then((responses) => ({
          data: responses.map((response) => response.json),
        }));
      case UPDATE_MANY:
        return Promise.all(
          params.ids.map((id) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: 'PATCH',
              body: JSON.stringify(params.data),
            }),
          ),
        ).then((responses) => ({
          data: responses.map((response) => response.json),
        }));
      case DELETE_MANY:
        return Promise.all(
          params.ids.map((id) =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'DELETE',
            }),
          ),
        ).then((responses) => ({
          data: responses.map((response) => response.json),
        }));
      default:
        break;
    }

    const {url, options} = convertDataRequestToHttp(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHttpResponse(response, type, resource, params),
    );
  };
};

export const authProvider = () => Promise.resolve();
export const history = createHashHistory();

export default drfProvider;
