import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {setEditTaxForm} from 'src/store/app/app.actions';
import {selectEditTaxForm} from 'src/store/app/app.selector';
import {useEditTaxForm} from './useEditTaxForm';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import Periods from '../EditTaxProfile/components/Periods';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';
import ProfitLossHeader from './components/ProfitLossHeader';
import {getPath} from '../utils';
import ProfitLossExpensesHeader from './components/ProfitLossExpensesHeader';
import EditTaxForm from './EditTaxForm';
import {useLocation} from 'react-router-dom';

export const EditTaxFormDrawer = () => {
  const {
    title,
    isVisible,
    taxFormId,
    taxFormType,
    prefilledFormData,
    multi_tax_forms_entity_id,
    filterFields,
    onCommit,
  } = useSelector(selectEditTaxForm);
  const dispatch = useDispatch();

  const {pathname} = useLocation();

  const {
    fields,
    areAllFieldsValid,
    isEditingTaxForm,
    onCreate,
    onDelete,
    onUpdate,
    isLoading,
    linkedBusinessId,
  } = useEditTaxForm({
    taxFormId,
    taxFormType,
    prefilledFormData,
    multi_tax_forms_entity_id,
    filterFields,
    onCommit,
  });

  const onClose = () => {
    dispatch(
      setEditTaxForm({
        isVisible: false,
        taxFormId: null,
        taxFormType: null,
        title: '',
        prefilledFormData: {},
        multi_tax_forms_entity_id: undefined,
      }),
    );
  };

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      title={title}
      height={'90vh'}
      onClose={onClose}
      customHeader={
        isEditingTaxForm ? (
          <DSButton
            type="secondary"
            text="Delete"
            onClick={() => onDelete(onClose)}
            disabled={isLoading}
          />
        ) : null
      }
      isOpen={isVisible}>
      <div
        style={{paddingInline: 12, display: 'flex', flexDirection: 'column'}}>
        <EditTaxForm fields={fields} linkedBusinessId={linkedBusinessId} />
        <DSButton
          text={
            isEditingTaxForm
              ? `Update ${taxFormType}`
              : `Create ${taxFormType}`
          }
          type="primary"
          onClick={
            isEditingTaxForm
              ? () => onUpdate(onClose)
              : () => onCreate(onClose)
          }
          style={{marginBlock: 12, paddingBlock: 12}}
          disabled={!areAllFieldsValid || isLoading}
        />
      </div>
    </Drawer>
  );
};

export default EditTaxFormDrawer;
