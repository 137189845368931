import React, {ReactNode, useState} from 'react';
import {
  DerivedMessageType,
  MessageAuthorType,
  MessageType,
  ReduxMessageType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import MessageHeader from './MessageHeader';
import MessageAttachment from './MessageAttachment';
import DeleteIcon from 'src/icons/DeleteIcon';
import {themmeColor} from 'src/constants/constants';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Typography} from '@mui/material';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {useDispatch} from 'react-redux';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';

interface MessageWrapperProps {
  children: ReactNode | ReactNode[];
  message: ReduxMessageType;
  query: ReduxQueryType;
}

const MessageWrapper = ({children, message, query}: MessageWrapperProps) => {
  const [isHoveringDelete, setHoveringDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const {deleteMessage, isLoading} = useQueryAction();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const [messageDeleteFailed, setMessageDeleteFailed] = useState(false);
  const isFromUser = message.authorType === MessageAuthorType.END_USER;
  const isFromCpa = message.authorType === MessageAuthorType.CPA;
  const readyByUser = query.hasUserRead;
  const isLast =
    query.messages.findIndex((m) => m.messageId === message.messageId) ===
    query.messages.length - 1;
  const isChat = message.messageType === MessageType.CHAT;
  const isNormalMessage =
    message.derivedMessageType === DerivedMessageType.NORMAL;

  let shouldShowDelete = false;
  if (isFromCpa && !readyByUser && isLast && isChat && isNormalMessage) {
    shouldShowDelete = true;
  }

  const _deleteMessage = async () => {
    try {
      await deleteMessage({messageId: message.messageId});
      setDeletePopup(false);
    } catch (e: any) {
      if (
        e?.response?.data?.error_msg ===
        'This message cannot be deleted, user has already viewed it'
      ) {
        setMessageDeleteFailed(true);
      }
    } finally {
      setDeletePopup(false);
      await dispatch(fetchQueries({userId, year: activeYear}));
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
      }}>
      <div
        style={{
          flex: 1,
          alignItems: isFromUser ? 'flex-start' : 'flex-end',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <MessageHeader query={query} message={message} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {shouldShowDelete && (
            <div
              onClick={() => setDeletePopup(true)}
              onMouseEnter={() => setHoveringDelete(true)}
              onMouseLeave={() => setHoveringDelete(false)}
              style={{marginRight: 12, display: 'flex'}}>
              <DeleteIcon
                color={
                  isHoveringDelete ? themmeColor.errorRed : themmeColor.black
                }
              />
            </div>
          )}
          {children}
        </div>

        {message?.attachments?.length > 0 && (
          <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '35vw'}}>
            {message.attachments.map((attachment) => (
              <MessageAttachment {...attachment} />
            ))}
          </div>
        )}
      </div>
      {shouldShowDelete && (
        <PopUp
          isOpen={deletePopup}
          onClose={() => setDeletePopup(false)}
          primaryButtonTitle="Delete"
          primaryButtonOnClick={_deleteMessage}
          primaryButtonDisabled={isLoading}
          secondaryButtonTitle="Cancel"
          secondaryButtonOnClick={() => setDeletePopup(false)}
          secondaryButtonDisabled={isLoading}>
          <Typography style={{fontWeight: 600}}>
            Are you sure? This action can't be undone.
          </Typography>
        </PopUp>
      )}
      <PopUp
        isOpen={messageDeleteFailed}
        onClose={() => setMessageDeleteFailed(false)}
        primaryButtonTitle="Close"
        primaryButtonOnClick={() => setMessageDeleteFailed(false)}>
        <Text
          type={TEXT_TYPES.BASE}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text="This message cannot be deleted, user has already viewed it."
        />
      </PopUp>
    </div>
  );
};

export default MessageWrapper;
