import {Button, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import ReuploadDocOverlay from './ReuploadDocOverlay';
import ReviewAndEsignContext, {
  ReviewEsignDocumentType,
} from '../ReviewAndEsignContext';
import {getFormData, ReviewDocStateKeys} from '../ReviewAndEsign.utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  generateDocUploadURL,
  getOcrData,
  postDocument,
  reuploadDoc,
  sendDocForReviewEsign,
  submitDocForOCR,
} from 'src/appApi';
import EsignReUpdateInfo from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/DocumentAndQueryViewer/EsignReUpdateInfo';
import {pollRequest} from 'src/common/utils';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {makeStyles} from '@mui/styles';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';

const useStyles = makeStyles({
  documentQueryViewerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  documentNameBubble: {display: 'flex', overflowX: 'auto'},
  documentNameBubbleContainer: {
    fontWeight: 600,
    backgroundColor: ({selected}) =>
      selected ? themmeColor.darkBlue : themmeColor.offWhite,
    padding: '2px 10px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginRight: 4,
    position: 'relative',
  },
  showActionDot: {
    position: 'absolute',
    alignSelf: 'center',
    top: 0,
    right: 0,
    backgroundColor: themmeColor.errorRed,
    height: 10,
    width: 10,
    borderRadius: 50,
  },
  documentContent: {
    height: 'calc(100% - 20px)',
    width: '100%',
    display: 'flex',
  },
  reviewDoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: themmeColor.successGreen,
    height: '32px',
    alignItems: 'center',
  },
  documentViewer: {
    width: '100%',
    height: ({selectedDocumentObject}) =>
      `calc(100% - 60px - ${
        selectedDocumentObject?.reviewDone ? '32px' : '0px'
      })`,
  },
  updateDocument: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
});

interface DocumentNameBubbleProps {
  selected: boolean;
  filename: string;
  documentId: number;
  showActionDot: boolean;
}

const DocumentNameBubble: React.FC<DocumentNameBubbleProps> = ({
  selected,
  filename,
  documentId,
  showActionDot,
}) => {
  const {reviewEsignNavigate} = useContext(ReviewAndEsignContext);
  const styles = useStyles({selected});

  return (
    <div
      className={styles.documentNameBubbleContainer}
      onClick={() => reviewEsignNavigate(documentId.toString())}>
      <Typography
        textOverflow={'ellipsis'}
        noWrap
        width={selected ? '200px' : '50px'}
        color={selected ? themmeColor.white : themmeColor.black}>
        {filename}
      </Typography>
      {showActionDot && <div className={styles.showActionDot} />}
    </div>
  );
};

const DocumentAndQueryViewer = () => {
  const {activeYear} = useActiveYear();
  const {documents, fetchDocsData} = useContext(ReviewAndEsignContext);
  const {flyfin_user_id, docId} = useParams();
  const selectedDocumentId = docId ? parseInt(docId, 10) : undefined;

  const [loading, setLoading] = useState(false);

  const initDocInfo = {
    [ReviewDocStateKeys.FILE]: undefined,
    [ReviewDocStateKeys.S3_KEY]: undefined,
    [ReviewDocStateKeys.FEDERAL]: {},
    [ReviewDocStateKeys.STATE]: {},
    [ReviewDocStateKeys.ESIGN_FIELDS]: [],
  };

  const [newDocInfo, setNewDocInfo] = useState(initDocInfo);

  const updateDocField = (key: ReviewDocStateKeys, value: any) => {
    setNewDocInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFileChange = async (file: any) => {
    if (!file) {
      setNewDocInfo(initDocInfo);
      return;
    }

    try {
      setLoading(true);

      // create bucket in s3
      const urlResponse = await generateDocUploadURL({
        filename: file?.name ?? 'File',
        year: activeYear,
      });

      // upload file
      await postDocument(
        urlResponse.data.url,
        getFormData(file, urlResponse.data.fields),
      );

      // submit doc for ocr
      const submitDocRes = await submitDocForOCR({
        s3_key: urlResponse.data.s3_key,
        doc_type: ReviewEsignDocumentType.TAX_RETURNS,
      });

      // get ocr values
      const ocrResponse: any = await pollRequest(
        async () => {
          const pollResponse = await getOcrData({
            job_id: submitDocRes.data,
            doc_type: ReviewEsignDocumentType.TAX_RETURNS,
            end_user_id: flyfin_user_id,
            year: activeYear,
          });
          return pollResponse;
        },
        (res: any) => {
          return res?.data?.status === 'Done';
        },
        5000,
      );

      const ocrDataRes = ocrResponse.data ? ocrResponse.data.ocr_data : {};
      setNewDocInfo((prevState) => ({
        ...prevState,
        [ReviewDocStateKeys.FILE]: file,
        [ReviewDocStateKeys.S3_KEY]: urlResponse.data.s3_key,
        [ReviewDocStateKeys.FEDERAL]: ocrDataRes.summary?.federal ?? {},
        [ReviewDocStateKeys.STATE]: ocrDataRes.summary?.state ?? {},
        [ReviewDocStateKeys.ESIGN_FIELDS]: ocrDataRes?.esign_fields ?? [],
      }));
      setLoading(false);
    } catch (e) {
      alert(`Something went wrong!!!, ${e}`);
      setLoading(false);
      console.log(e);
    }
  };

  const [reuploadOpen, setReuploadOpen] = useState(false);

  const [updateEsignOpen, setUpdateEsignOpen] = useState(false);

  const selectedDocumentObject = documents?.filter?.(
    (document) => document.documentId === selectedDocumentId,
  )?.[0];

  const handleReUpload = async () => {
    setLoading(true);
    try {
      await reuploadDoc({
        filename: newDocInfo.file?.name,
        s3_key: newDocInfo.s3_key,
        extras: selectedDocumentObject.extras,
        year: activeYear,
        doc_type: selectedDocumentObject.documentType,
        tfd_info_id: selectedDocumentObject.documentId,
        fly_user_id: flyfin_user_id ?? '',
        federal_return_summary: newDocInfo.federal,
        states_return_summary: newDocInfo.state,
        esign_fields: newDocInfo.esign_fields,
      });
      await fetchDocsData();
      setReuploadOpen(false);
    } catch (e) {
      alert(`Something went wrong!!!, ${e?.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleReUpdateSignNowDoc = async (
    filename: string,
    signNowdocId: string,
    inviteLink?: string,
  ) => {
    setLoading(true);
    try {
      const body = {
        user: flyfin_user_id ?? '',
        year: activeYear,
        filename: filename,
        jurisdiction: selectedDocumentObject.extras.jurisdiction,
        sign_now_document_id: signNowdocId,
        s3_url: selectedDocumentObject.documentURI,
      };
      await sendDocForReviewEsign(body);
      setUpdateEsignOpen(false);
      await fetchDocsData();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupOpenerClick = () => {
    selectedDocumentObject.documentType === ReviewEsignDocumentType.ESIGN_FORM
      ? setUpdateEsignOpen(true)
      : setReuploadOpen(true);
  };

  const styles = useStyles({selectedDocumentObject});

  return (
    <div className={styles.documentQueryViewerContainer}>
      <div style={{height: '20px', padding: '10px'}}>
        <div className={styles.documentNameBubble}>
          {documents.map((document) => (
            <DocumentNameBubble
              selected={selectedDocumentId === document.documentId}
              filename={document.filename}
              documentId={document.documentId}
              showActionDot={
                document?.queries?.some((query) => !query.resolved) ?? false
              }
            />
          ))}
        </div>
      </div>
      <div className={styles.documentContent}>
        <div style={{width: '70%', height: '100%'}}>
          {selectedDocumentObject?.reviewDone && (
            <div className={styles.reviewDoneContainer}>
              <CheckCircleIcon
                style={{marginRight: 10, height: 18, width: 18}}
              />
              <Typography fontWeight={'600'}>
                Review completed by the user
              </Typography>
            </div>
          )}
          {selectedDocumentObject?.documentURI && (
            <iframe
              className={styles.documentViewer}
              src={selectedDocumentObject.documentURI}
              title="Review & Esign Doc"
            />
          )}
          {selectedDocumentObject && (
            <div className={styles.updateDocument}>
              <Button
                style={{textTransform: 'none'}}
                disabled={selectedDocumentObject?.reviewDone}
                variant="contained"
                onClick={handlePopupOpenerClick}>
                Update Document
              </Button>
            </div>
          )}
        </div>
        <div style={{height: '100%', width: '30%'}}>
          <Outlet />
        </div>
        {reuploadOpen && (
          <ReuploadDocOverlay
            onClose={() => setReuploadOpen(false)}
            isOpen={reuploadOpen}
            handleFileChange={handleFileChange}
            handleReUpload={handleReUpload}
            loading={loading}
            updateDocField={updateDocField}
            newDocInfo={newDocInfo}
          />
        )}
        {updateEsignOpen && (
          <EsignReUpdateInfo
            isOpen={updateEsignOpen}
            onClose={() => setUpdateEsignOpen(false)}
            loading={loading}
            {...selectedDocumentObject.extras}
            handleReupdate={handleReUpdateSignNowDoc}
            filename={selectedDocumentObject.filename}
          />
        )}
        {loading && <FullScreenLoading open={loading} />}
      </div>
    </div>
  );
};

export default DocumentAndQueryViewer;
