import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateTaxFilingExtensionStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {TAX_EXTENSION_STATUS, themmeColor} from 'src/constants/constants';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import Box from 'src/DesignSystem/Box/Box';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {
  TaxReturnField,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {fetchUserInfo} from 'src/store/user/user.actions';
import LabelField from './LabelField';
import UpdateExtensionStatusPopup from './UpdateExtensionStatusPopup';
import BusinessExtensionStatusPopup from './BusinessExtensionStatusPopup';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {stringClipper} from 'src/common/utils';

interface ExtensionStatusUpdateProps {
  loading: boolean;
  setLoading: (newState: boolean) => void;
}

const ExtensionStatusUpdate = ({
  loading,
  setLoading,
}: ExtensionStatusUpdateProps) => {
  const [businessExtensionStatusPopup, setBusinessExtensionStatusPopup] =
    useState(false);
  const [selectedReturn, setSelectedReturn] = useState<{
    returnId: number;
    returnType: TaxReturnType;
  } | null>(null);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const {businesses} = useSelector(selectBusinessDetails);
  const [taxExtensionStatusPopup, setTaxExtensionStatusPopup] =
    useState(false);
  const dispatch = useDispatch();

  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const taxReturnDetails = useMemo(() => {
    const businessReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS,
    );
    const indReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
    );
    const indReturnData = indReturn
      ? {
          return_type: indReturn.return_type,
          return_id: indReturn.return_id,
          name: 'Individual',
          extension_status: indReturn[TaxReturnField.tax_extension_status],
        }
      : {};
    const bizReturnData = taxReturns
      .filter((taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS)
      .map((taxReturn) => {
        {
          const business = businesses.find(
            (data) => data[BusinessDetailsField.id] === taxReturn.business_id,
          );
          return {
            return_id: taxReturn.return_id,
            return_type: taxReturn.return_type,
            name: taxReturn.business_name ?? '',
            business_type: business?.[BusinessDetailsField.entity_type],
            extension_status: taxReturn[TaxReturnField.tax_extension_status],
          };
        }
      });
    const multiReturnStatusUpdateData = [indReturnData, ...bizReturnData];
    return {
      businessReturn: businessReturn,
      indReturn: indReturn,
      multiReturnStatusUpdateData: multiReturnStatusUpdateData,
    };
  }, [taxReturns, businesses]);

  const getSelectedExtensionStatus = () => {
    return taxReturnDetails.multiReturnStatusUpdateData.filter((data) => {
      return (
        data.return_id === selectedReturn?.returnId &&
        data.return_type === selectedReturn?.returnType
      );
    })[0]?.extension_status;
  };

  const getSelectedExtensionTitle = () => {
    if (selectedReturn?.returnType === TaxReturnType.INDIVIDUAL) {
      return 'Individual return';
    }

    const data = taxReturnDetails.multiReturnStatusUpdateData.filter(
      (data) => {
        return (
          data.return_id === selectedReturn?.returnId &&
          data.return_type === selectedReturn?.returnType
        );
      },
    )[0];

    return `${stringClipper(data.name ?? 'business', 12)} (${
      data.business_type
    })`;
  };

  const refreshInfo = async () => {
    await dispatch(fetchUserInfo(userId, activeYear));
    await dispatch(fetchTaxReturns(userId, activeYear));
  };

  const onUpdateTaxExtensionStatus = async (
    newExtensionStatus: TAX_EXTENSION_STATUS,
  ) => {
    try {
      setLoading(true);
      if (selectedReturn?.returnType === TaxReturnType.INDIVIDUAL) {
        await updateTaxFilingExtensionStatus({
          fly_user_id: userId,
          year: activeYear,
          tax_extension_status: newExtensionStatus,
          return_type: TaxReturnType.INDIVIDUAL,
          return_id: selectedReturn.returnId,
        });
      } else {
        await updateTaxFilingExtensionStatus({
          fly_user_id: userId,
          year: activeYear,
          tax_extension_status: newExtensionStatus,
          return_type: TaxReturnType.BUSINESS,
          return_id: selectedReturn?.returnId,
        });
      }
    } catch (e) {
      alert('Failed to update extension status');
    } finally {
      await refreshInfo();
      setLoading(false);
      setTaxExtensionStatusPopup(false);
    }
  };

  return (
    <div>
      <Box
        backgroundColor="#0D0F0F0D"
        style={{
          borderColor: themmeColor.silver,
          gap: '8px',
          padding: 12,
          alignItems: 'center',
        }}>
        {taxReturnDetails.indReturn && (
          <LabelField
            label="INDIVIDUAL EXT"
            value={
              taxReturnDetails.indReturn[
                TaxReturnField.tax_extension_status
              ] ?? 'NA'
            }
            onClick={() => {
              setTaxExtensionStatusPopup(true);
              setSelectedReturn({
                returnId: taxReturnDetails.indReturn?.return_id,
                returnType: TaxReturnType.INDIVIDUAL,
              });
            }}
          />
        )}
        {taxReturnDetails.businessReturn && (
          <LabelField
            label={'BUSINESS EXT'}
            value={'Extension status'}
            onClick={() => {
              setBusinessExtensionStatusPopup(true);
            }}
          />
        )}
      </Box>
      {selectedReturn && (
        <UpdateExtensionStatusPopup
          key={`extensionStatus-${selectedReturn.returnId}-${selectedReturn.returnType}`}
          isOpen={taxExtensionStatusPopup}
          onClose={() => setTaxExtensionStatusPopup(false)}
          currentExtensionStatus={getSelectedExtensionStatus()}
          returnTitle={getSelectedExtensionTitle()}
          loading={loading}
          onUpdateStatus={onUpdateTaxExtensionStatus}
        />
      )}
      {taxReturnsLoaded && (
        <BusinessExtensionStatusPopup
          isOpen={businessExtensionStatusPopup}
          onClose={() => setBusinessExtensionStatusPopup(false)}
          dataArray={taxReturnDetails.multiReturnStatusUpdateData.filter(
            (data) => data.return_type === TaxReturnType.BUSINESS,
          )}
          setTaxExtensionStatusPopup={setTaxExtensionStatusPopup}
          setSelectedReturn={setSelectedReturn}
        />
      )}
    </div>
  );
};

export default ExtensionStatusUpdate;
