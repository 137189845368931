import {fromUnixTime} from 'date-fns';
import {
  BackendTransformedProps,
  MissingExpenseStatus,
} from '../MissingExpenses.context';

interface MissingExpensesBackendBankStatements {
  upload_ts: number;
  name: string;
}

interface MissingExpensesBackendBankInfo {
  insti_id: number;
  bank_name: string;
  start_month: string;
  end_month: string;
  bank_statements: Array<MissingExpensesBackendBankStatements>;
}

interface MissingExpenseBackendResponse {
  user_id: number;
  username: string;
  email_addresss: string;
  status: MissingExpenseStatus;
  bank_info: Array<MissingExpensesBackendBankInfo>;
}

export const missingExpensesBackendResponseMapper: (
  response: any,
) => BackendTransformedProps = (response: MissingExpenseBackendResponse) => {
  const {
    user_id: userId,
    username,
    email_addresss: emailAddress,
    status,
    bank_info,
  } = response;

  const bankInfo = bank_info.map((bank) => {
    const {
      insti_id: bankId,
      bank_name: bankName,
      start_month: startMonth,
      end_month: endMonth,
      bank_statements,
    } = bank;

    const bankStatements = bank_statements.map((statement) => ({
      uploadTimeStamp: fromUnixTime(statement.upload_ts).toString(),
      name: statement.name,
    }));

    return {bankId, bankName, startMonth, endMonth, bankStatements};
  });

  return {
    userId,
    username,
    emailAddress,
    status,
    bankInfo,
  };
};
