import _ from 'lodash';
import {SET_DISTRIBUTE_DEDUCTIONS} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

export enum DeductionsDistributedByAuthor {
  CPA = 'CPA',
  END_USER = 'END_USER',
}

export enum DistributeDeductionCategoryGrouping {
  HOME_OFFICE_DEDUDCTIONS = 'home_office_deductions',
  OFFICE_DEDUDCTIONS = 'office_deductions',
  OTHER_RENT_DEDUCTIONS = 'other_rent_deductions',
  OTHER_DEDUCTION = 'other_deductions',
  VEHICLE_DEDUCTION = 'vehicle_deductions',
}

export enum SplitBusinessType {
  ACTUAL_BUSINESS = 'ACTUAL_BUSINESS',
  RENTAL = 'RENTAL',
}

interface DedudctionSplit {
  business_id: number;
  business_type: SplitBusinessType;
  ratio: number;
}

export interface CategoryDeductionsSplit {
  category_name: string;
  split: DedudctionSplit[];
  max_split: number[];
  is_applicable_for_rental_prop: boolean;
  total_ded_amount: number;
}

export interface VehicleDeductions {
  vehicle_id: number;
  category_wise_info: CategoryDeductionsSplit[];
  vehicle_name: string;
}

export interface CategoryGroupWiseDeductionSplit {
  [DistributeDeductionCategoryGrouping.HOME_OFFICE_DEDUDCTIONS]: CategoryDeductionsSplit[];
  [DistributeDeductionCategoryGrouping.OFFICE_DEDUDCTIONS]: CategoryDeductionsSplit[];
  [DistributeDeductionCategoryGrouping.OTHER_RENT_DEDUCTIONS]: CategoryDeductionsSplit[];
  [DistributeDeductionCategoryGrouping.OTHER_DEDUCTION]: CategoryDeductionsSplit[];
  [DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION]: VehicleDeductions[];
}

const EmptyDistributeDeductionsCategoryGroupSplit = {
  [DistributeDeductionCategoryGrouping.HOME_OFFICE_DEDUDCTIONS]: [],
  [DistributeDeductionCategoryGrouping.OFFICE_DEDUDCTIONS]: [],
  [DistributeDeductionCategoryGrouping.OTHER_RENT_DEDUCTIONS]: [],
  [DistributeDeductionCategoryGrouping.OTHER_DEDUCTION]: [],
  [DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION]: [],
} as CategoryGroupWiseDeductionSplit;

export interface BusinessInfo {
  business_id: number;
  business_type: SplitBusinessType;
  business_name: string;
  business_sub_type: string;
}

const initialState = {
  distributedDeductions: {
    [DeductionsDistributedByAuthor.CPA]: _.cloneDeep(
      EmptyDistributeDeductionsCategoryGroupSplit,
    ),
    [DeductionsDistributedByAuthor.END_USER]: _.cloneDeep(
      EmptyDistributeDeductionsCategoryGroupSplit,
    ),
    business_info: [] as BusinessInfo[],
    has_user_submitted: false as boolean,
    query_sent: false as boolean,
  },
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const distributeDeductions = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_DISTRIBUTE_DEDUCTIONS: {
      return {
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
        ...action.payload,
      };
    }
  }
  return state;
};

export type DistributeDeductionsStateType = typeof initialState;
