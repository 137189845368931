import {isNumber} from 'lodash';
import React, {ComponentProps, useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {populateBusinessDetailsFromDoc} from 'src/appApi';
import {DOCUMENT_TYPES} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import ViewDocumentContext from '../ViewDocument.context';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';

const BUSINESS_ASSIGNABLE_DOC_TYPES = [
  DOCUMENT_TYPES.FORM_1065,
  DOCUMENT_TYPES.FORM_1120,
  DOCUMENT_TYPES.FORM_1120_S,
  DOCUMENT_TYPES.BUSINESS_REGISTRATION_DOC,
  DOCUMENT_TYPES.PROFIT_LOSS_STATEMENT,
  DOCUMENT_TYPES.BALANCE_SHEET,
];

interface AssignBusinessToDocProps {
  selectedBusiness: number | null;
  setSelectedBusiness: (newId: number) => void;
  docType: DOCUMENT_TYPES;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignBusinessToDoc = ({
  docType,
  selectedBusiness,
  setSelectedBusiness,
  setLoading,
}: AssignBusinessToDocProps) => {
  const {
    userId: flyfinUserId,
    document,
    refreshDocuments: fetchDocuments,
  } = useContext(ViewDocumentContext);
  const {businesses} = useSelector(selectBusinessDetails);
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {notify} = useNotify();

  const onAssignBusinessToDoc = async () => {
    try {
      setLoading(true);
      if (isNumber(selectedBusiness)) {
        await populateBusinessDetailsFromDoc({
          tfd_doc_id: document.docId,
          business_id: selectedBusiness,
        });
      }
      await fetchDocuments();
      await dispatch(fetchTaxFormsForGivenYear(flyfinUserId, activeYear));
    } catch (e) {
      notify(`Failed to assign business ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const options: ComponentProps<typeof Form.RadioField>['options'] =
    useMemo(() => {
      return businesses.map((biz) => {
        return {
          label: `${biz[BusinessDetailsField.name]} (${
            biz[BusinessDetailsField.entity_type]
          })`,
          value: biz[BusinessDetailsField.id],
        };
      });
    }, [businesses]);

  if (!BUSINESS_ASSIGNABLE_DOC_TYPES.includes(docType)) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 8,
        marginBottom: 8,
      }}>
      <Form.RadioField
        title="Select business"
        options={options}
        value={selectedBusiness}
        onChangeValue={setSelectedBusiness}
      />

      <DSButton
        type="secondary"
        onClick={onAssignBusinessToDoc}
        text="Assign business"
        style={{marginTop: 12}}
        disabled={!isNumber(selectedBusiness)}
      />
    </div>
  );
};

export default AssignBusinessToDoc;
