import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import FilledCrossIcon from 'src/icons/FilledCrossIcon';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectTaxFormDrawer} from 'src/store/app/app.selector';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';

const TaxFormDrawer = () => {
  const {taxformId, isVisible} = useSelector(selectTaxFormDrawer);
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const dispatch = useDispatch();

  const {
    formData = {},
    taxFormId,
    formType,
  } = taxForms.find((tf) => tf.taxFormId === taxformId) ?? ({} as TaxForm);

  const fieldNames = Object.keys(formData).sort((a, b) => a.localeCompare(b));

  const onClose = () => {
    dispatch(
      setAppState(AppReducerStates.taxformDrawer, {
        isVisible: false,
        taxformId,
      }),
    );
  };

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      height={'90vh'}
      isOpen={isVisible}>
      <div
        style={{
          padding: 20,
          width: '100%',
          backgroundColor: 'white',
          marginTop: 4,
          position: 'absolute',
          height: '90vh',
        }}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography
            style={{fontSize: 20, fontWeight: 600, marginBottom: 20}}>
            {formType} (id: {taxFormId})
          </Typography>
          <div onClick={onClose}>
            <FilledCrossIcon />
          </div>
        </div>
        <MultiKeyValueField
          fields={fieldNames.map((field) => ({
            name: field.split('_').join(' '),
            path: field,
            style: {
              backgroundColor: themmeColor.cpaCenterV2Bg,
              borderRadius: 12,
              padding: 12,
            },
          }))}
          data={formData}
        />
      </div>
    </Drawer>
  );
};

export default TaxFormDrawer;
