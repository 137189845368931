import UploadFile from './components/UploadFile';
import OcrAndFileView from './components/OcrAndFileView';
import {ReviewAndEsignDocument} from '../../ReviewAndEsignContext';

enum FILE_STATES {
  INIT = 'INIT',
  ALREADY_UPLOADED = 'ALREADY_UPLOADED',
}

interface SingleFileUploadSectionProps {
  reviewDoc: ReviewAndEsignDocument | null;
  setReviewDoc: any;
  handleDocUpload: (file: any) => void;
  handleDocReUpload: (file: any) => void;
  loading?: boolean;
}

const SingleFileUploadSection = ({
  reviewDoc,
  setReviewDoc,
  handleDocUpload,
  handleDocReUpload,
  loading,
}: SingleFileUploadSectionProps) => {
  const fileType = ['pdf'];

  return (
    <div style={{height: '100%', width: '100%', overflow: 'hidden'}}>
      {reviewDoc ? (
        <OcrAndFileView
          fileType={fileType}
          reviewDoc={reviewDoc}
          setReviewDoc={setReviewDoc}
          handleDocReUpload={handleDocReUpload}
          loading={loading}
        />
      ) : (
        <UploadFile fileType={fileType} handleDocUpload={handleDocUpload} />
      )}
    </div>
  );
};

export default SingleFileUploadSection;
