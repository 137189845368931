import React, {useContext, useState} from 'react';
import {Button, Typography} from '@mui/material';
import TaxQueriesContext, {
  Query,
} from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/TaxQueriesContext';
import {
  CPA_CALL_STATUS,
  RESOLVED_OPACITY,
  themmeColor,
} from 'src/constants/constants';
import {getDateDifference} from 'src/common/utils';
import {useNavigate, useParams} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const QueryBox: React.FC<Query> = ({
  query_type,
  messages,
  query_status,
  actionRequired,
  resolved,
  query_id,
  call_status = CPA_CALL_STATUS.NA,
}) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const messageToShow = messages?.[messages?.length - 1];

  const {queryId: currentQueryId} = useParams();

  const parsedQueryId = parseInt(currentQueryId ?? '', 10);

  const isSelected = query_id === parsedQueryId;

  const handleOnClick = () => {
    // TODO navigate to this specific query
    navigate(query_id.toString());
  };
  return (
    <div
      onClick={handleOnClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        border: 'solid',
        borderWidth: 0.5,
        borderColor: themmeColor.offWhite,
        padding: '10px 0px 10px 13px',
        position: 'relative',
        backgroundColor:
          isHovering || isSelected
            ? themmeColor.focusedBlue
            : themmeColor.white,
      }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div
          style={{
            padding: '4px 12px',
            backgroundColor: themmeColor.errorRed,
            borderRadius: 12,
            alignItems: 'center',
            display: 'inline-flex',
            marginLeft: -4,
            height: 'max-content',
            opacity: resolved ? RESOLVED_OPACITY : 1,
          }}>
          <Typography
            style={{opacity: resolved ? RESOLVED_OPACITY : 1}}
            fontSize={10}
            fontWeight={600}>
            {query_type}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}>
          {call_status !== CPA_CALL_STATUS.NA && (
            <div
              style={{
                padding: '4px 12px',
                backgroundColor: themmeColor.lightGrey,
                alignItems: 'center',
              }}>
              <Typography fontSize={12}>{call_status}</Typography>
            </div>
          )}
          {actionRequired && (
            <div
              style={{
                backgroundColor: themmeColor.errorRed,
                borderRadius: 20,
                height: 24,
                width: 24,
                marginTop: 4,
              }}
            />
          )}
        </div>
      </div>
      <div style={{marginTop: 6}}>
        <Typography style={{opacity: resolved ? RESOLVED_OPACITY : 1}}>
          {messageToShow.fromUs ? 'You' : 'User'}:{' '}
          {ReactHtmlParser(messageToShow.text)}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: 12,
          marginTop: 9,
        }}>
        <Typography
          textTransform={'uppercase'}
          fontWeight={600}
          fontSize={10}
          style={{opacity: resolved ? RESOLVED_OPACITY : 1}}
          color={themmeColor.flyfinYellow}>
          {query_status}
        </Typography>
        <Typography
          style={{opacity: resolved ? RESOLVED_OPACITY : 1}}
          color={themmeColor.silver}
          fontSize={13}
          fontWeight={400}>
          {getDateDifference(messageToShow.timeStamp)}
        </Typography>
      </div>
    </div>
  );
};

const SectionHeader = ({
  label,
  showButton = false,
  onClickHandler = () => {},
}: {
  label: string;
  showButton?: boolean;
  onClickHandler: () => void;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px 5px 4px 13px',
        alignItems: 'center',
        borderBottom: 'solid',
        borderBottomWidth: 1,
        borderColor: themmeColor.offWhite,
      }}>
      <Typography fontWeight={600} textTransform={'uppercase'} fontSize={10}>
        {label}
      </Typography>
      {showButton && (
        <Button
          onClick={onClickHandler}
          variant="contained"
          style={{fontSize: 10, borderRadius: 8, padding: '6px 8px'}}>
          Ask a new query
        </Button>
      )}
    </div>
  );
};

const QuerySelector = () => {
  const {queriesData} = useContext(TaxQueriesContext);
  const navigate = useNavigate();

  const sortedQueries = queriesData?.sort((q1, q2) => {
    const m1 = q1?.messages[q1.messages.length - 1];
    const m2 = q2?.messages[q2.messages.length - 1];
    return m2.timeStamp - m1.timeStamp;
  });

  const resolvedQueries = sortedQueries.filter((query) => query.resolved);
  const unResolvedQueries = sortedQueries.filter((query) => !query.resolved);

  const handleNewQueryOnClick = () => {
    // TODO navigate to /new
    navigate('new');
  };

  return (
    <div
      style={{
        width: '25%',
        height: '100%',
        overflowY: 'auto',
      }}>
      {/* All Queries Section Tag */}
      <SectionHeader
        label="All Queries"
        showButton
        onClickHandler={handleNewQueryOnClick}
      />
      <div>
        {unResolvedQueries.map((query) => (
          <QueryBox {...query} />
        ))}
      </div>
      <SectionHeader
        label="Resolved queries"
        onClickHandler={handleNewQueryOnClick}
      />
      <div>
        {resolvedQueries.map((query) => (
          <QueryBox {...query} />
        ))}
      </div>
    </div>
  );
};

export default QuerySelector;
