import axios from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  getGoogleSheetData,
  getS3ReadUrl,
  updateGoogleSheetData,
} from 'src/appApi';
import {
  extractDataFromJson,
  prepareFinalAnnotationData,
  prepareResultData,
} from './utils';
import {SPREAD_SHEET_ID} from './constant';

const useAnnotations = ({isViewOnly}: {isViewOnly: boolean}) => {
  const {job_id, task_id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [annotationSheetData, setAnnotationSheetData] = useState<any[][]>([]);
  const [currentActiveRow, setCurrentActiveRow] = useState<any[]>([]);
  const [currentActiveRowId, setCurrentActiveRowId] = useState<number>();
  const [pdfSignedURl, setPdfSignedURl] = useState<string>('');
  const [ocrDataJson, setOcrDataJson] = useState<any>({});
  const [finalAnnotationSheetData, setFinalAnnotationSheetData] =
    useState<any>({});
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const isLastPage = currentPage === totalPages;

  const changeActiveSheet = useCallback(() => {
    setIsLoading(true);
    if (job_id) {
      // gets data from annotation sheet
      getGoogleSheetData({
        sheet_id: SPREAD_SHEET_ID,
        sheet_name: job_id,
      })
        .then((res) => {
          const responseData = res.data;
          if (responseData) {
            // remove first row to avoid headings of sheet
            responseData.splice(0, 1);
            setAnnotationSheetData(responseData);
            for (let index = 0; index < responseData.length; index++) {
              const row = responseData[index];
              const result = row[4];

              if (isViewOnly) {
                if (task_id === row[0]) {
                  setCurrentActiveRow(row);
                  break;
                }
                continue;
              }
              if (!result) {
                setCurrentActiveRowId(index);
                setCurrentActiveRow(row);
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log({err});
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isViewOnly, job_id, task_id]);

  useEffect(() => {
    changeActiveSheet();
  }, [changeActiveSheet, isViewOnly, job_id, task_id]);

  useEffect(() => {
    (async () => {
      try {
        if (currentActiveRow.length) {
          // get the pdf file
          const s3ResponsePdf = await getS3ReadUrl({
            bucketName: 'flyfin-taxdocs',
            fileName: `annotation_docs/${currentActiveRow[2]}`,
          });
          // get the ocr data provided by IR team
          const s3Response = await getS3ReadUrl({
            bucketName: 'flyfin-taxdocs',
            fileName: `annotation_docs/${currentActiveRow[3]}`,
          });
          setPdfSignedURl(s3ResponsePdf.data as string);
          const res = await axios.get(s3Response.data as string);
          setOcrDataJson(extractDataFromJson(res.data));
        }
      } catch (error) {
        console.log({error});
      }
    })();
  }, [currentActiveRow]);

  useEffect(() => {
    setFinalAnnotationSheetData(prepareFinalAnnotationData(ocrDataJson));
  }, [ocrDataJson]);

  const onDocumentLoadSuccess = ({numPages}) => {
    setTotalPages(numPages);
  };

  const onInputChange = ({pageNumber, id, newValue}) => {
    setFinalAnnotationSheetData((prevFinalData: any) => {
      const currentPagePrevFinalData = prevFinalData[pageNumber];

      const updatedArray = [...(currentPagePrevFinalData[id] || [])];
      updatedArray[updatedArray.length - 1] = newValue;

      return {
        ...prevFinalData,
        [pageNumber]: {
          ...currentPagePrevFinalData,
          [id]: updatedArray,
        },
      };
    });
  };

  const onClickPrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const onClickNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const onClickFinalSave = () => {
    setSubmitting(true);
    const copyData = [
      ['ID', 'TITLE', 'PDF', 'JSON', 'RESULT'],
      ...annotationSheetData,
    ];
    const finalDataList = prepareResultData(finalAnnotationSheetData);

    copyData[(currentActiveRowId as unknown as number) + 1] = [
      ...currentActiveRow,
      JSON.stringify(finalDataList),
    ];

    updateGoogleSheetData({
      sheet_id: SPREAD_SHEET_ID,
      sheet_name: job_id,
      rows: copyData,
    })
      .then((res) => {
        changeActiveSheet();
        setCurrentPage(1);
        console.log(res);
      })
      .catch((err) => {
        console.log({err});
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return {
    jobId: job_id,
    isLoading,
    currentActiveRow,
    pdfSignedURl,
    currentPageOcrDataJson: ocrDataJson?.[currentPage] ?? [],
    currentPageFinalAnnotationSheetData:
      finalAnnotationSheetData?.[currentPage] ?? {},
    isLastPage,
    totalPages,
    currentPage,
    submitting,
    onDocumentLoadSuccess,
    onInputChange,
    onClickPrevPage,
    onClickNextPage,
    onClickFinalSave,
  };
};

export default useAnnotations;
