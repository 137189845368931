import {useSelector} from 'react-redux';
import {CLASSIFICATION_STATUS} from 'src/constants/constants';
import {QuerySubType, ReduxQueryType} from 'src/store/queries/queries.reducer';
import {selectTransactionsReducer} from 'src/store/transactions/transaction.selector';
import {Transaction} from 'src/store/transactions/transactions.reducer';

const useHighValueDeduction = () => {
  const {transactionIdMap} = useSelector(selectTransactionsReducer);

  const getHighValueQueryExpenseStatus = (query: ReduxQueryType) => {
    let totalCount = 0;
    let pendingCount = 0;
    let actionTakenCount = 0;

    let pendingAmount = 0;
    let actionTakenAmount = 0;
    let totalAmount = 0;

    const pendingTxns: Transaction[] = [];
    const actionTakenTxns: Transaction[] = [];
    const allTxns: Transaction[] = [];
    query.highValueDeductions.forEach(({original_irs_category, txn_id}) => {
      const txn = transactionIdMap[txn_id];
      if (!txn) {
        return;
      }
      totalCount++;
      allTxns.push(txn);
      totalAmount += txn.taxDeductibleAmount;
      if (
        txn.classificationStatus === CLASSIFICATION_STATUS.DEDUCTION &&
        txn.irsCategory === original_irs_category
      ) {
        pendingTxns.push(txn);
        pendingCount++;
        pendingAmount += txn.taxDeductibleAmount;
      } else {
        actionTakenTxns.push(txn);
        actionTakenCount++;
        actionTakenAmount += txn.taxDeductibleAmount;
      }
    });
    return {
      totalCount,
      pendingCount,
      actionTakenCount,
      totalAmount,
      pendingAmount,
      actionTakenAmount,
      pendingTxns,
      actionTakenTxns,
      allTxns,
    };
  };
  return {
    getHighValueQueryExpenseStatus,
  };
};

export default useHighValueDeduction;
