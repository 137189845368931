import {Autocomplete, Button, Modal, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import {ALL_DOCS_TYPE, themmeColor} from 'src/constants/constants';
import {RE_ACTIONS} from '../../../../ReviewAndEsign.utils';

interface REActionOverlayProps {
  actionRequired: RE_ACTIONS;
  message: string;
  updateMessage: (newText: string) => void;
  onSend: () => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
  selectedDocType: {label: string; docType: string} | null;
  setSelectectedDocType: (newValue: any) => void;
}

const REActionOverlay: React.FC<REActionOverlayProps> = ({
  isOpen,
  onClose,
  actionRequired,
  message,
  updateMessage,
  onSend,
  selectedDocType,
  setSelectectedDocType,
}) => {
  const [loading, setLoading] = useState(false);

  const options = ALL_DOCS_TYPE.map((docType) => ({
    docType,
    label: docType,
  }));

  const handleDocTypeUpdate = (value: any) => {
    setSelectectedDocType(value);
  };

  const handleSend = async () => {
    setLoading(true);
    await onSend();
    onClose();
    setLoading(false);
  };
  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          width: '500px',
          borderRadius: 16,
          padding: 24,
        }}>
        <Typography fontSize={20} fontWeight={'600'}>
          Action: {actionRequired}
        </Typography>
        <Typography fontSize={14} color={themmeColor.grey}>
          Some text here some text here some text here some text here some text
          here
        </Typography>

        <Autocomplete
          disabled={loading}
          style={{marginTop: 20, width: 500}}
          onChange={(e, v) => handleDocTypeUpdate(v)}
          value={selectedDocType?.label}
          id="combo-box-demo"
          options={options}
          sx={{width: 200}}
          renderInput={(params) => (
            <TextField {...params} label="Document type" />
          )}
        />

        {selectedDocType !== null && (
          <div style={{marginTop: 12}}>
            <RichTextEditor message={message} setMessage={updateMessage} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 36,
          }}>
          <Button
            onClick={onClose}
            disabled={loading}
            style={{width: 230, height: 48}}
            variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSend}
            disabled={
              selectedDocType === null || loading || message.length === 0
            }
            style={{width: 230, height: 48}}
            variant="contained">
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default REActionOverlay;
