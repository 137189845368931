import {createContext} from 'react';
import {QUERY_STATUS} from 'src/constants/constants';
import {
  EsignFields,
  ReviewDocStateKeys,
  ReviewOcrValues,
} from './ReviewAndEsign.utils';
import {NavigateFunction} from 'react-router-dom';

export interface ReviewAndEsignMessage {
  timestamp: number;
  fromUs: boolean;
  text: string;
  documentType?: string;
}

export interface ReviewAndEsignQuery {
  queryId: number;
  status: QUERY_STATUS;
  timestamp: number;
  title: string;
  messages: Array<ReviewAndEsignMessage>;
  resolved: boolean;
  fromUser: boolean;
}

export enum ReviewEsignDocumentType {
  TAX_RETURNS = 'TAX_RETURNS',
  ESIGN_FORM = 'ESIGN_FORM',
}

export interface ReviewAndEsignDocument {
  documentURI: string;
  documentId: number;
  filename: string;
  documentActionCount?: number;
  queries?: Array<ReviewAndEsignQuery>;
  reviewDone: boolean;
  documentType: ReviewEsignDocumentType;
  extras: {
    [ReviewDocStateKeys.JURISDICTION]: string;
    [ReviewDocStateKeys.SIGNNOW_DOC_ID]?: string;
  };
  esignFields: EsignFields[];
  federal: ReviewOcrValues | {};
  state: {[state: string]: ReviewOcrValues};
}

export interface ReviewAndEsignContextProps {
  flyfin_user_id: string | undefined;
  documents: Array<ReviewAndEsignDocument>;
  setDocuments: any;
  handleDocUpload: (file: any) => void;
  handleDocReUpload: (file: any, docId: number) => void;
  fetchDocsData: () => Promise<any>;
  fetchUserDetails: () => void;
  docsLoading: boolean;
  reviewEsignNavigate: NavigateFunction;
}

// @ts-ignore
const ReviewAndEsignContext = createContext<ReviewAndEsignContextProps>({});

export default ReviewAndEsignContext;
