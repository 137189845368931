import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import React, {useState} from 'react';
import useCreateDummyAccount from './components/useCreateDummyAccount';

const CreateDummyAccount = () => {
  const {
    getButtonCopy,
    email,
    otp,
    onClickHandler,
    buttonDisabled,
    otpDisabled,
    emailDisabled,
    setEmail,
    setOtp,
    toggleSkipPayment,
    skipPayment,
    skipTaxProfile,
    toggleSkipTaxProfile,
  } = useCreateDummyAccount();

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
      }}>
      <TextField
        type={'email'}
        placeholder="Enter flyfin Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        disabled={emailDisabled}
      />
      <br />
      <br />
      <TextField
        disabled={otpDisabled}
        type={'number'}
        placeholder="Enter Otp"
        onChange={(e) => setOtp(e.target.value)}
        value={otp}
      />
      <br />
      <br />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => toggleSkipPayment(e.target.checked)}
              checked={skipPayment}
              disabled={otpDisabled}
            />
          }
          label="Skip payment"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => toggleSkipTaxProfile(e.target.checked)}
              checked={skipTaxProfile}
              disabled={otpDisabled}
            />
          }
          label="Fill Tax Profile"
        />
      </FormGroup>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={onClickHandler}
        disabled={buttonDisabled}>
        {getButtonCopy()}
      </Button>
    </div>
  );
};

export default CreateDummyAccount;
