import React from 'react';
import ReadOnlyCell from '../Cells/ReadOnlyCell';
import {
  CategoryDeductionsSplit,
  SplitBusinessType,
} from 'src/store/distributeDeductions/distributeDeductions.reducer';
import {themmeColor} from 'src/constants/constants';
import {CurrencyNumberFormat} from 'src/common/utils';
import DistributionCell from '../Cells/DistributionCell';
import {useDistributDeductions} from '../../useDistributeDeductions';
import TotalCell from '../Cells/TotalCell';

interface CategorySplitRowProps {
  rowData: CategoryDeductionsSplit;
  onChangeSplit: (props: {newValue: number; splitIndex: number}) => void;
  isHeaderRow?: boolean;
}

const CategorySplitRow = ({
  rowData,
  onChangeSplit,
  isHeaderRow,
}: CategorySplitRowProps) => {
  const {getCellColor, round, isEditDisabled} = useDistributDeductions();

  const getCategoryName = () => {
    if (!isHeaderRow) {
      return rowData.category_name;
    }
    return rowData.category_name.split('_').join(' ');
  };

  const total = rowData.split.map((i) => i.ratio).reduce((p, c) => p + c, 0);

  const isValid = total === 100 || isHeaderRow;

  const getSubvalue = () => {
    if (isHeaderRow) return undefined;
    if (total < 100) return `Remaining ${100 - total}%`;
    if (total > 100) return `Over by ${total - 100}%`;
    return undefined;
  };

  const warningColor = total < 100 ? '#D37506' : themmeColor.errorRed;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: isHeaderRow ? 80 : 56,
      }}>
      <ReadOnlyCell
        value={getCategoryName()}
        backgroundColor={themmeColor.cpaCenterV2Bg}
        textStyle={{
          fontWeight: isHeaderRow ? 600 : 400,
          marginLeft: isHeaderRow ? 0 : 8,
          textTransform: isHeaderRow ? 'uppercase' : 'none',
        }}
      />
      <TotalCell
        value={CurrencyNumberFormat(rowData.total_ded_amount)}
        textStyle={{
          fontWeight: isHeaderRow || !isValid ? 600 : 400,
          textAlign: 'right',
        }}
        subvalue={getSubvalue()}
        subValueStyle={{
          color: warningColor,
          textAlign: 'right',
        }}
      />
      {rowData.split.map((cellData, index) => {
        const getCellLabel = () => {
          const splitAmount =
            (cellData.ratio / 100) * rowData.total_ded_amount;
          const roundedSplitAount = round(splitAmount);
          return CurrencyNumberFormat(roundedSplitAount);
        };

        const notApplicable =
          cellData.business_type === SplitBusinessType.RENTAL &&
          !rowData.is_applicable_for_rental_prop;

        const disabled = isEditDisabled;

        const getSubLabel = () => {
          if (cellData.ratio > rowData?.max_split?.[index]) {
            return `Max: ${rowData?.max_split?.[index]}%`;
          }
          return undefined;
        };

        const getPctBorderColor = () => {
          if (disabled || notApplicable || isHeaderRow) {
            return undefined;
          }
          if (total !== 100) {
            return warningColor;
          }
          if (getSubLabel() !== undefined) {
            return themmeColor.errorRed;
          }
          return themmeColor.silver;
        };

        return (
          <DistributionCell
            percentage={cellData.ratio}
            onChangePercent={(newValue) =>
              onChangeSplit({newValue, splitIndex: index})
            }
            backgroundColor={getCellColor(index)}
            labelValue={getCellLabel()}
            labelTextStyle={{
              fontWeight: isHeaderRow ? 600 : 400,
              width: 50,
            }}
            percentageTextStyle={{
              fontWeight: isHeaderRow ? 600 : 400,
            }}
            notApplicable={notApplicable}
            disabled={disabled}
            percentageBorderColor={getPctBorderColor()}
            subLabel={getSubLabel()}
            subLabelStyle={{color: themmeColor.errorRed}}
          />
        );
      })}
    </div>
  );
};

export default CategorySplitRow;
