import {Typography} from '@mui/material';
import React, {ComponentProps} from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import TransactionListItem, {
  SelectionMode,
} from './components/TransactionListItem';

interface SimpleTransactionsListProps
  extends Pick<
    ComponentProps<typeof TransactionListItem>,
    'diffMode' | 'diffConfig'
  > {
  txns: Transaction[];
  header: string;
}

const SimpleTransactionsList = ({
  txns,
  diffConfig,
  diffMode,
  header,
}: SimpleTransactionsListProps) => {
  const getAmount = () => {
    return CurrencyNumberFormat(
      txns.map((t) => t.taxDeductibleAmount).reduce((t1, t2) => t1 + t2, 0),
    );
  };
  return (
    <div
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: themmeColor.offWhite,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBlock: 10,
          paddingInline: 16,
          backgroundColor: '#E6E8ED',
        }}>
        {/* Select all Header */}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography>{header}</Typography>
        </div>
        <Typography>{getAmount()}</Typography>
      </div>
      {txns.map((txn) => {
        return (
          <TransactionListItem
            txn={txn}
            selectionMode={SelectionMode.NONE}
            style={{
              borderBottom: `solid 1px ${themmeColor.offWhite}`,
            }}
            diffConfig={diffConfig}
            diffMode={diffMode}
          />
        );
      })}
    </div>
  );
};

export default SimpleTransactionsList;
