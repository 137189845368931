import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
} from 'react-admin';
import {Filter} from 'react-admin';
import {SelectInput, TextInput} from 'react-admin';
import {
  CPAQuestionStatusChoices,
  CPAQuestionTypeChoices,
} from '../constants/constants';

const CPAQuestionFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Email" source="fly_user_email" alwaysOn />
      <TextInput label="Name" source="fly_user_name" alwaysOn />
      <SelectInput
        source="status"
        choices={CPAQuestionStatusChoices}
        optionText="name"
        optionValue="id"
        allowEmpty
        alwaysOn
      />
      <SelectInput
        source="type"
        choices={CPAQuestionTypeChoices}
        optionText="name"
        optionValue="id"
        allowEmpty
        alwaysOn
      />
    </Filter>
  );
};

const CPAQuestionList = (props) => {
  return (
    <List {...props} perPage={25} filters={<CPAQuestionFilter />}>
      <Datagrid rowClick="edit">
        <TextField label="Question Id" source="id" />
        <TextField label="Name" source="fly_user_name" />
        <TextField label="Email" source="fly_user_email" />
        <FunctionField
          label="Query"
          render={(record) => `${record.query.slice(0, 20)}...`}
        />
        <TextField source="app_year" />
        <DateField showTime source="created_at" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="replied_by" />
      </Datagrid>
    </List>
  );
};

export default CPAQuestionList;
