import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Form} from 'src/DesignSystem/Form/Form';

import Text from 'src/DesignSystem/Form/common/Text/Text';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import _ from 'lodash';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import {patchBusinessDetails} from 'src/appApi';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {
  BusinessDetail,
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBusinessDetails} from 'src/store/businessDetails/businessDetails.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {getBusinessDetailFormConfig} from './EditBusinesDetails.utils';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {BUSINESS_ENTITY_OPTIONS} from './EditBusinessDetails.constants';
import {getPath} from 'src/common/utils';
import {selectTaxProfileAnswerForGivenYear} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';

interface EditBusinessDetailsProps {
  isOpen: boolean;
  business: ReturnType<typeof selectBusinessDetails>['businesses'][0];
  onClose: () => void;
}

const EditBusinessDetails = ({
  isOpen,
  business,
  onClose,
}: EditBusinessDetailsProps) => {
  const [businessData, setBusinessData] = useState(() =>
    _.cloneDeep(business),
  );
  const {activeYear} = useActiveYear();
  const filingStatus = useSelector((state) =>
    selectTaxProfileAnswerForGivenYear(
      state,
      TAX_PROFILE_FIELDS.FILING_STATUS,
      activeYear,
    ),
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {notify} = useNotify();

  const {userId} = useCurrentUserId();

  useEffect(() => {
    if (isOpen) {
      setBusinessData(_.cloneDeep(business));
    }
  }, [business, isOpen]);

  const businessDetailsFormConfig = useMemo(() => {
    return getBusinessDetailFormConfig(
      businessData[BusinessDetailsField.have_ein],
      businessData[BusinessDetailsField.entity_type],
    );
  }, [
    businessData[BusinessDetailsField.have_ein],
    businessData[BusinessDetailsField.entity_type],
  ]);

  const overWriteFields: OverWriteFormFieldsType = useCallback(
    ({fields, data, setData}) => {
      const fieldPathToFieldMap = _.keyBy(fields, 'path');
      const haveEinField = fieldPathToFieldMap[BusinessDetailsField.have_ein];
      const businessTypeField =
        fieldPathToFieldMap[BusinessDetailsField.entity_type];
      if (haveEinField && businessTypeField) {
        if (
          haveEinField.value === false &&
          businessTypeField.inputType === FORM_INPUT_TYPE.SingleSelect
        ) {
          businessTypeField.options = BUSINESS_ENTITY_OPTIONS.filter(
            (option) => option.value === BusinessType.SOLE_PROPRIETORSHIP,
          );
        }
        haveEinField.onChangeValue = (newValue: boolean) => {
          setData((prev: any) => {
            let newState = {..._.set(prev, haveEinField.path, newValue)};
            if (newValue === false) {
              newState = _.set(
                newState,
                businessTypeField.path,
                BusinessType.SOLE_PROPRIETORSHIP,
              );
            }
            return newState;
          });
        };
      }

      const shareholders = (data as BusinessDetail)[
        BusinessDetailsField.share_holders
      ];
      const signAuthorityField =
        fieldPathToFieldMap[BusinessDetailsField.signing_authority_id];
      if (
        Array.isArray(shareholders) &&
        signAuthorityField &&
        signAuthorityField.inputType === FORM_INPUT_TYPE.SingleSelect
      ) {
        const options: typeof signAuthorityField.options = shareholders.map(
          (s) => ({
            label: s[BusinessDetailsField.first_name_and_middle_initial],
            value: s[BusinessDetailsField.id],
          }),
        );
        signAuthorityField.options = options;
      }

      const sameHomeAddress =
        fieldPathToFieldMap[BusinessDetailsField.is_address_same_as_home];

      const fieldsToRemove: string[] = [];

      if (sameHomeAddress && sameHomeAddress.value === true) {
        const homeAddressFieldsPath = [
          BusinessDetailsField.number_and_street,
          BusinessDetailsField.apartment_number,
          BusinessDetailsField.city,
          BusinessDetailsField.state,
          BusinessDetailsField.zip_code,
        ].map((path) => getPath(BusinessDetailsField.address, path));
        fieldsToRemove.push(...homeAddressFieldsPath);
      }

      if (filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) {
        fieldsToRemove.push(BusinessDetailsField.is_spouse_business_partner);
      }
      const isWorkContinuing =
        fieldPathToFieldMap[BusinessDetailsField.is_work_continuing];

      if (isWorkContinuing && isWorkContinuing.value === true) {
        fieldsToRemove.push(BusinessDetailsField.work_stop_date);
      }

      const filteredFields = fields.filter(
        (field) => !fieldsToRemove.includes(field.path),
      );

      return filteredFields;
    },

    [filingStatus],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: businessDetailsFormConfig,
    data: businessData,
    setData: setBusinessData,
    overWriteFields,
  });

  const updateBusinessDetails = async () => {
    try {
      setLoading(true);
      await patchBusinessDetails({
        businessData,
        fly_user_id: userId,
        businessId: businessData[BusinessDetailsField.id],
      });
      await dispatch(fetchBusinessDetails(userId, activeYear));
      onClose();
      notify(
        'Business details updated successfully',
        NotificationType.success,
      );
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUp
      style={{
        minWidth: 500,
        maxWidth: '70vw',
      }}
      isOpen={isOpen}
      primaryButtonTitle="Update"
      primaryButtonDisabled={!areAllFieldsValid || loading}
      primaryButtonOnClick={updateBusinessDetails}
      secondaryButtonTitle="Close"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      onClose={onClose}>
      <Text
        type={Text.TEXT_TYPES.L}
        text="Edit business details"
        fontWeight={Text.FONT_WEIGHTS.SemiBold}
        containerStyle={{marginBottom: 12}}
      />
      <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
        {fields.map((field) => {
          return (
            <div style={{marginBottom: 12}}>
              <Form {...field} />
            </div>
          );
        })}
      </div>
    </PopUp>
  );
};

export default EditBusinessDetails;
