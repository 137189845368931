import React from 'react';

const NotesIcon = ({color = '#555555'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <mask
        id="mask0_282_3550"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect x="0.222656" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_282_3550)">
        <path
          d="M8.47266 17.75H15.9726V16.25H8.47266V17.75ZM8.47266 13.75H15.9726V12.25H8.47266V13.75ZM6.53036 21.5C6.02522 21.5 5.59766 21.325 5.24766 20.975C4.89766 20.625 4.72266 20.1974 4.72266 19.6922V4.3077C4.72266 3.80257 4.89766 3.375 5.24766 3.025C5.59766 2.675 6.02522 2.5 6.53036 2.5H14.4727L19.7226 7.74995V19.6922C19.7226 20.1974 19.5476 20.625 19.1976 20.975C18.8476 21.325 18.42 21.5 17.9149 21.5H6.53036ZM13.7227 8.49995V3.99997H6.53036C6.45342 3.99997 6.3829 4.03203 6.31878 4.09613C6.25468 4.16024 6.22263 4.23077 6.22263 4.3077V19.6922C6.22263 19.7692 6.25468 19.8397 6.31878 19.9038C6.3829 19.9679 6.45342 20 6.53036 20H17.9149C17.9918 20 18.0624 19.9679 18.1265 19.9038C18.1906 19.8397 18.2226 19.7692 18.2226 19.6922V8.49995H13.7227Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default NotesIcon;
