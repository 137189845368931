import React, {ComponentProps, useCallback} from 'react';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import TaxReturnFieldGridWrapper from './TaxReturnFieldGridWrapper';
import {Form} from 'src/DesignSystem/Form/Form';
import {ReturnFields} from '../CreateReturn.types';
import _ from 'lodash';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';

interface UserRelationFieldsProps extends ArrayChildProps {
  alreadySpouseUserIds: number[];
  selectedUserIds: number[];
  allOptions: ComponentProps<typeof Form.CheckField>['options'];
}

const UserRelationFields = ({
  array,
  onChangeValue,
  childProps,
  arrayObjectIndex,
  alreadySpouseUserIds,
  selectedUserIds,
  allOptions,
}: UserRelationFieldsProps) => {
  const {updateObject, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );

  const overWriteFields: OverWriteFormFieldsType = useCallback(
    ({fields, data, setData}) => {
      const fieldsToRemove: string[] = [];
      if (data[ReturnFields.has_spouse] === false) {
        fieldsToRemove.push(ReturnFields.spouse_id);
      }
      return fields
        .filter((field) => !fieldsToRemove.includes(field.path))
        .map((field) => {
          if (
            field.inputType === FORM_INPUT_TYPE.SingleSelect &&
            field.path === ReturnFields.spouse_id
          ) {
            field.options = allOptions.filter((option) => {
              if (option.value === field.value) {
                // my spouse
                return true;
              }
              if (
                alreadySpouseUserIds.includes(option.value) ||
                selectedUserIds.includes(option.value)
              ) {
                // either primary user or someone else spouse
                return false;
              }
              return true;
            });
          }
          if (
            field.inputType === FORM_INPUT_TYPE.Boolean &&
            field.path === ReturnFields.has_spouse
          ) {
            field.onChangeValue = (newValue) => {
              setData((prev: any) => {
                let updated = {..._.set(prev, field.path, newValue)};
                if (newValue === false) {
                  updated = _.set(updated, ReturnFields.spouse_id, null);
                }
                return updated;
              });
            };
          }
          return field;
        });
    },
    [allOptions, alreadySpouseUserIds, selectedUserIds],
  );

  const {fields} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
    overWriteFields,
  });

  return (
    <div>
      <TaxReturnFieldGridWrapper
        style={{
          marginTop: 16,
          border: 'solid 1px grey',
          padding: 12,
          borderRadius: 12,
        }}>
        {fields.map((field) => {
          return <Form {...field} />;
        })}
      </TaxReturnFieldGridWrapper>
    </div>
  );
};

export default UserRelationFields;
