import React from 'react';
import {
  TaxProfileSection,
  TaxProfileSectionSequence,
  themmeColor,
} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import useTaxProfileReview from 'src/CpaCenterV2/hooks/useTaxProfileReview';
import TaxProfileStep from './TaxProfileStep';

const TaxProfileSteps = () => {
  const {navigateToTaxProfileSection} = useCpaCenterV2Navigation();
  const onClickStep = (section: TaxProfileSection) => {
    navigateToTaxProfileSection(section);
  };

  const {isSectionReviewPending} = useTaxProfileReview();

  const getStatus = (section: TaxProfileSection) => {
    if (!isSectionReviewPending(section)) {
      return TaxProfileStep.Step.COMPLETED;
    }

    return TaxProfileStep.Step.NOT_STARTED;
  };

  const getTaxProfileSteps = () => {
    const taxProfileStatus = TaxProfileSectionSequence.map((step) => ({
      ...step,
      status: getStatus(step.section),
    }));
    for (var i = 0; i < taxProfileStatus.length; i++) {
      if (taxProfileStatus[i].status !== TaxProfileStep.Step.COMPLETED) {
        taxProfileStatus[i].status = TaxProfileStep.Step.IN_PROGRESS;
        break;
      }
    }
    return taxProfileStatus;
  };

  const taxProfileSteps = getTaxProfileSteps();
  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT} =
    useLayout();

  console.log({taxProfileSteps});

  return (
    <div
      style={{
        overflowY: 'auto',
        height:
          spaceLeftBelowTaxFilingSteps - TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
      }}>
      {taxProfileSteps.map(({stepName, status, section}, index) => (
        <div>
          <TaxProfileStep
            stepName={stepName}
            status={status}
            onClick={() => onClickStep(section)}
          />
          {index !== taxProfileSteps.length - 1 && (
            <div
              style={{
                marginLeft: 32,
                height: 15,
                borderRightWidth: 2,
                borderRightStyle: 'dashed',
                borderRightColor: themmeColor.silver,
                width: 1,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default TaxProfileSteps;
