export enum BusinessType {
  SOLE_PROPRIETORSHIP = 'sole_proprietorship',
  PARTNERSHIP = 'partnership',
  S_CORP = 's_corp',
  C_CORP = 'c_corp',
}

export enum BusinessDetailsField {
  id = 'id',
  year = 'year',
  is_end_user_reviewed = 'is_end_user_reviewed',
  ein = 'ein',
  start_date = 'start_date',
  name = 'name',

  // Address fields
  address = 'address',
  city = 'city',
  state = 'state',
  zip_code = 'zip_code',
  apartment_number = 'apartment_number',
  number_and_street = 'number_and_street',

  have_ein = 'have_ein',
  entity_type = 'entity_type',
  professions = 'professions',
  phone_number = 'phone_number',

  // Shareholders fields
  share_holders = 'share_holders',
  ssn = 'ssn',
  last_name = 'last_name',
  ownership_pct = 'ownership_pct',
  first_name_and_middle_initial = 'first_name_and_middle_initial',
  is_another_individual_act_on_behalf = 'is_another_individual_act_on_behalf',

  // Document Links fields
  document_links = 'document_links',
  document_links_doc_link = 'doc_link',
  document_links_filename = 'filename',
  document_links_form_type = 'form_type',
  document_links_id = 'id',

  accounting_method = 'accounting_method',
  incorporation_date = 'incorporation_date',
  owner_or_proprietor = 'owner_or_proprietor',
  manual_flow_complete = 'manual_flow_complete',
  signing_authority_id = 'signing_authority_id',
  s_corp_effective_date = 's_corp_effective_date',
  is_address_same_as_home = 'is_address_same_as_home',
  is_registered_with_state = 'is_registered_with_state',
  started_in_tax_filing_year = 'started_in_tax_filing_year',
  is_spouse_business_partner = 'is_spouse_business_partner',
  is_work_continuing = 'is_work_continuing',
  work_stop_date = 'work_stop_date',
}

export interface BusinessDetail {
  [BusinessDetailsField.id]: number;
  [BusinessDetailsField.year]: number;
  [BusinessDetailsField.is_end_user_reviewed]: boolean;
  [BusinessDetailsField.ein]: string;
  [BusinessDetailsField.start_date]: string;
  [BusinessDetailsField.name]: string;

  [BusinessDetailsField.address]: {
    [BusinessDetailsField.city]: string;
    [BusinessDetailsField.state]: string;
    [BusinessDetailsField.zip_code]: string;
    [BusinessDetailsField.apartment_number]: string;
    [BusinessDetailsField.number_and_street]: string;
  };

  [BusinessDetailsField.have_ein]: boolean;
  [BusinessDetailsField.entity_type]: BusinessType;
  [BusinessDetailsField.professions]: string[];
  [BusinessDetailsField.phone_number]: string;

  [BusinessDetailsField.share_holders]: {
    [BusinessDetailsField.id]: string;
    [BusinessDetailsField.ssn]: string;
    [BusinessDetailsField.address]: any;
    [BusinessDetailsField.last_name]: string;
    [BusinessDetailsField.ownership_pct]: number;
    [BusinessDetailsField.first_name_and_middle_initial]: string;
    [BusinessDetailsField.is_another_individual_act_on_behalf]: any;
  }[];

  [BusinessDetailsField.document_links]: {
    [BusinessDetailsField.document_links_doc_link]: string;
    [BusinessDetailsField.document_links_filename]: string;
    [BusinessDetailsField.document_links_form_type]: string;
    [BusinessDetailsField.document_links_id]: number;
  }[];

  [BusinessDetailsField.accounting_method]: AccountingMethods | null;
  [BusinessDetailsField.incorporation_date]: string;
  [BusinessDetailsField.owner_or_proprietor]: BusinessOwner | null;
  [BusinessDetailsField.manual_flow_complete]: boolean;
  [BusinessDetailsField.signing_authority_id]: any;
  [BusinessDetailsField.s_corp_effective_date]: any;
  [BusinessDetailsField.is_address_same_as_home]: boolean;
  [BusinessDetailsField.is_registered_with_state]: boolean;
  [BusinessDetailsField.started_in_tax_filing_year]: boolean;
  [BusinessDetailsField.is_spouse_business_partner]: boolean | null;
  [BusinessDetailsField.is_work_continuing]: boolean | null;
  [BusinessDetailsField.work_stop_date]: string | null;
}

export enum BusinessOwner {
  SELF = 'self',
  SPOUSE = 'spouse',
}

export enum AccountingMethods {
  CASH = 'Cash',
  ACCRUAL = 'Accrual',
  OTHER = 'Other',
}
