import React from "react";
import { useParams } from "react-router-dom";

const useCurrentDocumentId = () => {

    const {docId: docIdString} = useParams();

    const docId =  parseInt(docIdString);

    return {
        docId,
    }
};

export default useCurrentDocumentId;
