import React from 'react';

interface BackIconProps {
  onClick?: () => void;
  color?: string;
}

const BackIcon = ({onClick, color = '#121212'}: BackIconProps) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M15.9993 3L7 12L16 21"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BackIcon;
