import {isObject, isString, isUndefined} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {assignTypeToBalanceSheet} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import {Form} from 'src/DesignSystem/Form/Form';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  BalanceSheetType,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';

interface AssignYearToBalanceSheetProps {
  taxFormIds: number[];
  setLoading: (newVal: boolean) => void;
}

const AssignYearToBalanceSheet = ({
  taxFormIds,
  setLoading,
}: AssignYearToBalanceSheetProps) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const [selected, setSelected] = useState(null);
  const {notify} = useNotify();
  const dispatch = useDispatch();

  const balanceSheetTaxForms = useMemo(() => {
    return taxForms.filter(
      (tf) =>
        taxFormIds.includes(tf.taxFormId) &&
        tf.formType === TaxFormType.BALANCE_SHEET_FORM,
    );
  }, [taxForms, taxFormIds]);
  const firstTf = balanceSheetTaxForms?.[0];

  useEffect(() => {
    if (isObject(firstTf)) {
      setSelected(firstTf.formData?.[TaxFormFieldIds.BALANCE_SHEET_TYPE]);
    } else {
      setSelected(null);
    }
  }, [firstTf]);

  const onAssignYear = async () => {
    try {
      setLoading(true);
      await assignTypeToBalanceSheet(firstTf.taxFormId, selected);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`${e?.response?.data}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  if (balanceSheetTaxForms.length === 0 || !isObject(firstTf)) {
    return null;
  }

  return (
    <div
      style={{
        paddingLeft: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Form.RadioField
        title="Select balance sheet year"
        options={Object.values(BalanceSheetType).map((val) => ({
          label: val,
          value: val,
        }))}
        onChangeValue={setSelected}
        value={selected}
      />
      <DSButton
        type="primary"
        text="Assign year"
        onClick={onAssignYear}
        disabled={!isString(selected)}
        style={{marginTop: 12}}
      />
    </div>
  );
};

export default AssignYearToBalanceSheet;
