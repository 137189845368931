import {TaxProfileSection} from 'src/constants/constants';
import PartnerBasicDetails from './PartnerBasicDetails/PartnerBasicDetails';
import PartnerIncome from './PartnerIncome/PartnerIncome';
import PartnerCredits from './PartnerCredits/PartnerCredits';
import PartnerTaxPayments from './PartnerTaxPayments/PartnerTaxPayments';
import PartnerStateTaxes from './PartnerStateTaxes/PartnerStateTaxes';
import PartnerDeductions from './PartnerDeductions/PartnerDeductions';
import PartnerBalanceSheet from './PartnerBalanceSheet/PartnerBalanceSheet';

export const PartnerTaxProfileSectionsConfig = [
  {
    section: TaxProfileSection.BasicDetails,
    Component: PartnerBasicDetails,
  },
  {
    section: TaxProfileSection.Income,
    Component: PartnerIncome,
  },
  {
    section: TaxProfileSection.Deductions,
    Component: PartnerDeductions,
  },
  {
    section: TaxProfileSection.BalanceSheet,
    Component: PartnerBalanceSheet,
  },
  {
    section: TaxProfileSection.Credits,
    Component: PartnerCredits,
  },
  {
    section: TaxProfileSection.TaxPayments,
    Component: PartnerTaxPayments,
  },
  {
    section: TaxProfileSection.StateTaxes,
    Component: PartnerStateTaxes,
  },
];
