import {Button, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CpaAssignConfirmOverlay from 'src/CpaCenterList/components/CPAAssignCell/CpaAssignConfirmOverlay';
import {
  assignCPAToUser,
  assignReviewerToUser,
  patchTaxFiling,
  updateBoiUserData,
} from 'src/appApi';
import {VALID_CPA_EMAILS} from 'src/constants/constants';
import {CPA_LIST_COLUMNS} from '../cpaList.utils';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {BOI_FILING_COLUMNS} from 'src/BoiFilingCenter/types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface CPAAssignCellProps {
  fieldId: string;
  userId?: number;
  value: VALID_CPA_EMAILS | null;
  isAssigned: boolean;
  fetchList: () => Promise<void>;
  taxFilingId?: number;
  boiId?: number;
}

const CPAAssignCell = ({
  fieldId,
  value,
  isAssigned,
  userId,
  taxFilingId,
  boiId,
  fetchList,
}: CPAAssignCellProps) => {
  const {activeYear} = useActiveYear();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [currentCpa, setCurrentCpa] = useState(value);
  const [loading, setLoading] = useState(false);

  const {notify} = useNotify();

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const handleClose = () => setIsOpen(false);

  const updateCpa = async (cpa: VALID_CPA_EMAILS) => {
    setLoading(true);
    try {
      switch (fieldId) {
        case CPA_LIST_COLUMNS.CPA:
          await assignCPAToUser(cpa, userId);
          break;
        case CPA_LIST_COLUMNS.REVIEWER:
          await assignReviewerToUser(cpa, userId, activeYear);
          break;
        case CPA_LIST_COLUMNS.COLLABORATOR_EMAIL:
          if (taxFilingId) {
            await patchTaxFiling(
              {tax_filing_id: taxFilingId},
              {collaborator_email: cpa},
            );
          } else {
            notify('Please provide tax filing id', NotificationType.error);
          }
          break;
        case BOI_FILING_COLUMNS.ASSIGNED_REVIEWER:
          await updateBoiUserData(boiId, {
            assigned_reviewer: cpa,
          });
          break;
      }

      await fetchList();
      setIsOpen(false);
      setConfirmOverlay(false);
    } catch (e) {
      alert(`Something went wrong ${e.response.data.detail}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (cpa: VALID_CPA_EMAILS) => {
    setCurrentCpa(cpa);
    handleClose();
    if (isAssigned) {
      setConfirmOverlay(true);
      return;
    }
    updateCpa(cpa);
  };

  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const CPAs = Object.values(VALID_CPA_EMAILS);

  return (
    <div onClick={stopDrilling}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handleMenuOpen}
        id={`cpa-assign-div-${userId}`}>
        {isAssigned ? (
          <div style={{display: 'flex'}}>
            <Typography>{value}</Typography>
            <KeyboardArrowDownIcon style={{marginLeft: 8}} />
          </div>
        ) : (
          <div>
            <Button>Assign</Button>
          </div>
        )}
      </div>
      <Menu
        id="basic-menu"
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': `cpa-assign-div-${userId}`,
        }}>
        {CPAs.map((cpa) => (
          <MenuItem value={cpa} onClick={() => handleUpdate(cpa)}>
            {cpa}
          </MenuItem>
        ))}
      </Menu>
      <CpaAssignConfirmOverlay
        isVisible={confirmOverlay}
        handleClose={() => setConfirmOverlay(false)}
        onContinue={() => updateCpa(currentCpa)}
        loading={loading}
      />
    </div>
  );
};

export default CPAAssignCell;
