import React, {useContext} from 'react';
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import DocumentsContext, {
  Document,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import {Typography} from '@mui/material';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-rotate.css';
import {Block, Preview} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  documentSwitcherContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  documentBubbleContainer: {
    display: 'flex',
    height: '50px',
    width: '100%',
    marginTop: 18,
    overflowX: 'auto',
  },
  outletContainer: {
    position: 'relative',
    height: 'calc(100% - 50px)',
    width: '100%',
    overflow: 'hidden',
  },
  documentBubble: {
    fontWeight: 600,
    backgroundColor: ({documentId, selectedId}) =>
      documentId === selectedId ? themmeColor.darkBlue : themmeColor.offWhite,
    padding: '2px 10px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginRight: 4,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
  },
  docBubbleIcon: {
    borderRadius: 100,
    height: 18,
    width: 18,
    alignSelf: 'center',
    marginRight: 4,
  },
});

interface DocumentBubbleProps extends Document {
  selectedId: number;
  navigate: NavigateFunction;
}

const DocumentBubble: React.FC<DocumentBubbleProps> = ({
  selectedId,
  documentId,
  filename,
  status,
  navigate,
}) => {
  const handleOnClick = () => {
    navigate(documentId.toString());
  };

  const styles = useStyles({documentId, selectedId});

  const renderIcon = () => {
    switch (status) {
      case VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW:
        return (
          <Preview
            className={styles.docBubbleIcon}
            style={{
              color: themmeColor.black80,
            }}
          />
        );
      case VALID_DOCUMENT_STATUS.ACCEPTED:
        return (
          <Preview
            className={styles.docBubbleIcon}
            style={{
              color: themmeColor.black80,
            }}
          />
        );
      case VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA:
        return (
          <CheckCircleIcon
            className={styles.docBubbleIcon}
            style={{
              color: themmeColor.successGreen,
            }}
          />
        );
      case VALID_DOCUMENT_STATUS.ACTION_REQUIRED:
        return (
          <ErrorIcon
            className={styles.docBubbleIcon}
            style={{
              color: themmeColor.errorRed,
            }}
          />
        );
      case VALID_DOCUMENT_STATUS.IGNORED:
        return (
          <Block
            className={styles.docBubbleIcon}
            style={{
              color: themmeColor.black80,
            }}
          />
        );
    }
  };

  return (
    <div className={styles.documentBubble} onClick={handleOnClick}>
      {renderIcon()}
      <Typography
        textOverflow={'ellipsis'}
        noWrap
        width={documentId === selectedId ? '200px' : '50px'}
        color={
          documentId === selectedId ? themmeColor.white : themmeColor.black
        }>
        {filename}
      </Typography>
    </div>
  );
};

const DocumentSwitcher = () => {
  const {docTypeId, docId} = useParams();
  const {docsData} = useContext(DocumentsContext);
  const navigate = useNavigate();

  const parsedDocTypeId = docTypeId ? parseInt(docTypeId, 10) : undefined;
  const parsedDocumentId = docId ? parseInt(docId, 10) : undefined;

  const documentList = docsData?.filter(
    (doc_data) => doc_data.documentTypeId === parsedDocTypeId,
  )?.[0]?.documents;

  const document = documentList?.filter(
    (document) => document.documentId === parsedDocumentId,
  )?.[0];

  const styles = useStyles({});

  if (!documentList) return null;

  return (
    <div className={styles.documentSwitcherContainer}>
      <div className={styles.documentBubbleContainer}>
        {documentList.map((doc) => (
          <DocumentBubble
            key={`${doc.documentId}`}
            selectedId={document?.documentId}
            navigate={navigate}
            {...doc}
          />
        ))}
      </div>
      <div className={styles.outletContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default DocumentSwitcher;
