import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import ReviewAndEsignContext from '../../../ReviewAndEsignContext';
import RESingleQueryView from './components/RESingleQueryView';

const ReviewAndEsignQueries = () => {
  const {docId} = useParams();
  const {documents} = useContext(ReviewAndEsignContext);

  const selectedDocumentId = docId
    ? parseInt(docId, 10)
    : undefined;

  const selectedDocumentObject = documents?.filter?.(
    (document) => document.documentId === selectedDocumentId,
  )?.[0];

  if (!selectedDocumentId || !selectedDocumentObject) return <></>;
  if (
    !selectedDocumentObject.queries ||
    selectedDocumentObject.queries.length === 0
  )
    return <></>;

  return (
    <RESingleQueryView />
  );
};

export default ReviewAndEsignQueries;
