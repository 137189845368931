import {TextField} from '@mui/material';
import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import SendIcon from 'src/icons/SendIcon';

interface WriteCommentProps {
  style?: React.CSSProperties;
  onSend: (text: string) => any;
  disabled?: boolean;
  autoFocus?: boolean;
}

const WriteComment = ({
  style = {},
  onSend,
  disabled = false,
  autoFocus = false,
}: WriteCommentProps) => {
  const [text, setText] = useState('');
  const _onSend = async () => {
    if (disabled) {
      return;
    }
    await onSend(text);
    setText('');
  };
  return (
    <div style={{...style, position: 'relative'}}>
      <TextField
        placeholder="Write your response..."
        onChange={(e) => setText(e.target.value)}
        value={text}
        rows={3}
        multiline
        fullWidth
        disabled={disabled}
        autoFocus={autoFocus}
        InputProps={{
          sx: {
            borderRadius: '12px',
          },
        }}
      />
      <Box
        onClick={_onSend}
        hoverEffect
        backgroundColor={disabled ? themmeColor.grey : themmeColor.black}
        style={{
          position: 'absolute',
          bottom: 8,
          right: 8,
          padding: 10,
        }}>
        <SendIcon />
      </Box>
    </div>
  );
};

export default WriteComment;
