import {SET_EFILE_LOGS} from '../actionNames';
import {TaxReturnType} from '../taxReturns/taxReturns.reducer';
import {REDUX_STATUS, ReduxActionType} from '../types';

export interface EfileLog {
  title: string;
  date: string;
  warning: boolean;
}

const initialState = {
  efileLogs: [] as {
    return_id: number;
    return_type: TaxReturnType;
    logs: EfileLog[];
  }[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const efileLogs = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_EFILE_LOGS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type EfileLogsStateType = typeof initialState;
