const ExtraCountCell = ({value, onClick}: {value: number, onClick?: () => void;}) => {
    return (
      <div style={{
        width: '25px',
        height: '26px',
        borderRadius: '6px',
        padding: '2px',
        gap: '10px',
        backgroundColor: '#1F7FD8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }} onClick={onClick}>
        <p style={{
          color: 'white',
          fontSize: 'Inter',
          margin: 0,
        }}>+{value}</p>
      </div>
    );
  }
  
export default ExtraCountCell;
