import React from 'react';
import {Chip, Grid, IconButton, Typography} from '@mui/material';
import {CPAQuestionTypeName, themmeColor} from 'src/constants/constants';
import {makeStyles} from '@mui/styles';
import {ArrowBack} from '@mui/icons-material';
import {BackendQuery} from 'src/types/query.types';
import {QueryStatusText, StatusColor} from 'src/UserQueries/Queries.constants';

type Props = {
  goBack: () => void;
  query: BackendQuery;
};
const useStyles = makeStyles({
  backButton: {
    fontSize: 16,
    marginRight: 16,
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
    paddingTop: 12,
  },
  headerTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: themmeColor.black60,
  },
});

const UserInfoItem = ({title, value}) => {
  const styles = useStyles();
  return (
    <div className={styles.userInfo}>
      <Typography className={styles.headerTitle}>{title}</Typography>
      {typeof value === 'function' ? (
        value()
      ) : (
        <Typography
          fontSize={16}
          fontWeight={'400'}
          color={themmeColor.black60}
          marginTop={1}>
          {value}
        </Typography>
      )}
    </div>
  );
};

const QueryPageHeader = ({goBack, query}: Props) => {
  const styles = useStyles();
  const data = [
    {
      title: 'User ID',
      value: query.fly_user_id,
    },
    {
      title: 'Name',
      value: query.name,
    },
    {
      title: 'Email address',
      value: query.email,
    },
    {
      title: 'Created at',
      value: query.created_date,
    },
    {
      title: 'Last updated',
      value: query.updated_at ?? '-',
    },
    {
      title: 'Status',
      value: () => (
        <Chip
          label={QueryStatusText[query.status]}
          sx={{
            backgroundColor: StatusColor[query.status],
            color: themmeColor.white,
          }}
        />
      ),
    },
    {
      title: 'Query type',
      value: CPAQuestionTypeName[query.type],
    },
    {
      title: 'year',
      value: query.app_year,
    },
    {
      title: 'Assignee',
      value: query.assignee_email ?? '-',
    },
  ];
  return (
    <Grid container gridRow={1} direction={'row'} px={1.6}>
      <Grid
        item
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}>
        <IconButton className={styles.backButton} onClick={goBack}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid flex={1} item display={'flex'} justifyContent={'space-around'}>
        {data.map((item) => (
          <UserInfoItem {...item} />
        ))}
      </Grid>
    </Grid>
  );
};

export default QueryPageHeader;
