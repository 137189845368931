import {isObject} from 'lodash';
import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectTaxonomy} from 'src/store/globals/globals.selector';

export const useTaxonomy = () => {
  const {deduction_categories} = useSelector(selectTaxonomy);
  const irsCategories = useMemo(() => {
    return Object.keys(deduction_categories).sort((a, b) =>
      a.localeCompare(b),
    );
  }, [deduction_categories]);

  const getL2s = useCallback(
    (irsCategory: string) => {
      if (isObject(deduction_categories[irsCategory])) {
        return deduction_categories[irsCategory].l2;
      }
      return [];
    },
    [deduction_categories],
  );

  return {
    irsCategories,
    getL2s,
  };
};
