import {BoiBusinessForm, BoiFilingData} from 'src/BoiFilingCenter/types';
import {UPDATE_BOI_USER_DATA, SET_BOI_DATA} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

const initialState = {
  data: {} as BoiFilingData,
  business: {} as BoiBusinessForm,
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const boi = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_BOI_DATA:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_BOI_USER_DATA:
      return {
        ...state,
        data: {...action.payload},
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type BoiDataStateType = typeof initialState;
