import React from 'react';
const QuestionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none">
      <path
        d="M1.40091 3.06706C1.35963 2.46693 1.67181 1.09784 3.27678 1.10501C5.31255 1.1141 5.79466 2.97874 4.68769 4.01429L3.81283 4.77294C3.47581 5.10526 2.96008 5.51304 2.96008 6.80835"
        stroke="#0D0F0F"
        stroke-width="1.75238"
        stroke-linecap="round"
      />
      <circle cx="2.95583" cy="9.37624" r="0.997827" fill="#0D0F0F" />
    </svg>
  );
};

export default QuestionIcon;
