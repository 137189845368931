import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {themmeColor} from 'src/constants/constants';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';

interface DependentCreditsDocsProps {
  dependentTF: TaxForm;
}

const DependentCreditsDocs = ({dependentTF}: DependentCreditsDocsProps) => {
  const haveCCE =
    dependentTF.formData[TaxFormFieldIds.HAVE_CHILD_CARE_EXPENSES];
  let cceDocIds = dependentTF.formData[TaxFormFieldIds.LINKED_CHILD_CARE_DOCS];

  if (!Array.isArray(cceDocIds)) {
    cceDocIds = [];
  }

  const {documents} = useSelector(selectDocuments);

  const cceDocs = documents.filter((doc) => cceDocIds.includes(doc.docId));

  if (!haveCCE || cceDocs.length === 0) {
    return null;
  }

  return (
    <div style={{marginTop: 20}}>
      <Typography style={{marginBottom: 4, fontSize: 13}}>
        Documents uploaded
      </Typography>
      {cceDocs.map((doc) => (
        <>
          <DocumentPointer
            backgroundColor={themmeColor.cpaCenterV2Bg}
            doc={doc}
            dontShowStatus
            taxform={dependentTF}
            style={{marginBottom: 8}}
          />
        </>
      ))}
    </div>
  );
};

export default DependentCreditsDocs;
