import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {resetRedux} from 'src/store/common.actions';

const useCurrentUserId = () => {
  const {userId: userIdString} = useParams();
  const dispatch = useDispatch();


  let userId = -1;
  if (userIdString) {
    userId = parseInt(userIdString, 10);
  }
  useEffect(() => {
    if (!userIdString) {
      dispatch(resetRedux());
    }
  }, [userIdString, dispatch]);


  return {
    userId,
  };
};

export default useCurrentUserId;
