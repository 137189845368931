import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';
import {setDistributeDeductionsQueryDrawer} from 'src/store/app/app.actions';
import {selectDistributeDeductionsQueryDrawer} from 'src/store/app/app.selector';
import {fetchDistributeDeductions} from 'src/store/distributeDeductions/distributeDeductions.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {selectUser} from 'src/store/user/user.selector';

const DistributeDeductionsQueryDrawer = () => {
  const isVisible = useSelector(selectDistributeDeductionsQueryDrawer);
  const dispatch = useDispatch();
  const {userName, loaded: profileLoaded} = useSelector(selectUser);
  const {createDistributeDeductionsQuery, isLoading} = useQueryAction();
  const [text, setText] = useState('');
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  useEffect(() => {
    if (profileLoaded) {
      const defaultMessage = `Hello ${userName},<br />Since you have multiple businesses/gigs, allocate deductions approximately based on the purchases made for each. Thanks.`;
      setText(defaultMessage);
    }
  }, [profileLoaded]);

  const onClose = () => {
    dispatch(setDistributeDeductionsQueryDrawer(false));
  };

  const onSend = async () => {
    await createDistributeDeductionsQuery({message: text});
    await dispatch(fetchDistributeDeductions(userId, activeYear));
    onClose();
    await dispatch(fetchQueries({userId, year: activeYear}));
  };

  return (
    <Drawer
      title="Allocate Expenses to your Businesses"
      isOpen={isVisible}
      onClose={onClose}
      width={400}
      height={'90vh'}>
      <div style={{paddingInline: 12}}>
        <Typography style={{color: themmeColor.grey, fontSize: 14}}>
          Ask the user to distribute deduction based on multiple businesses
          they have.
        </Typography>
        <MessageTextBox
          message={text}
          setMessage={setText}
          inputMode={
            MessageTextBox.MessageTextBoxInputMode.SEND_MESSAGE_WITH_NO_DRAFT
          }
          isLoading={isLoading}
          height={200}
          placeholder="Describe the issues properly so that users understand what to do!"
          onSend={onSend}
        />
      </div>
    </Drawer>
  );
};

export default DistributeDeductionsQueryDrawer;
