import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialButton} from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getAllCategories} from '../common/ExpenseCategoriesUtils';
import {BlankChoice} from '../constants/constants';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import {debounce} from 'lodash';
import Grid from '@material-ui/core/Grid';
import {Fragment, useState, useCallback} from 'react';
import {
  Button,
  Confirm,
  useMutation,
  useNotify,
  Error,
  useListContext,
  GET_MANY,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  textFieldStyle: {
    marginTop: '2em',
    display: 'grid',
  },
  suggestionList: {
    padding: '1em',
    marginTop: '2em',
    marginLeft: '1em',
    backgroundColor: '#fcfcfc',
  },
}));

const MerchantSuggestionList = ({
  merchantName,
  link,
  setMerchantName,
  setCategory,
  setLink,
  setSuggestionData,
}) => {
  const notify = useNotify();
  const [mutate, {data, total, error, loading, loaded}] = useMutation();
  const fetchSuggestions = (merchantName, link) =>
    mutate(
      {
        type: 'getMerchantSuggestions',
        resource: 'categorizer',
        payload: {
          merchant_name: merchantName,
          link: isValidHttpUrl(link) ? link : '',
        },
      },
      {
        onSuccess: () => {},
        onFailure: (error) => {
          notify(
            `Problem in fetching suggestions!: ${error.body['display_msg']}`,
            'warning',
          );
        },
      },
    );

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 2000),
    [],
  );

  React.useEffect(
    () => debouncedFetchSuggestions(merchantName, link),
    [merchantName, link],
  );
  React.useEffect(() => setSuggestionData(data), [data]);

  const classes = useStyles();
  const autoFillMerchant = ({index, data}) => {
    setMerchantName(data['suggestions'][index][0]);
    setCategory({
      id: data['suggestions'][index][1],
      name: data['suggestions'][index][1],
    });
    setLink(data['suggestions'][index][3]);
  };
  const renderMerchant = ({index}) => {
    return (
      <ListItem
        button
        onClick={() => autoFillMerchant({index, data})}
        myIndex={index}
        key={index}>
        <ListItemText primary={`${data['suggestions'][index][0]}`} />
      </ListItem>
    );
  };
  const renderLink = ({index}) => {
    return (
      <ListItem
        button
        onClick={() => setLink(data['suggestions'][index][3])}
        myIndex={index}
        key={index}>
        <ListItemText primary={`${data['suggestions'][index][3]}`} />
      </ListItem>
    );
  };

  if (error) return <Error />;
  if (!data) return null;
  return (
    <div>
      <Grid container>
        <Grid item className={classes.suggestionList}>
          <h3>Merchant Names</h3>
          <FixedSizeList
            height={300}
            itemSize={46}
            itemCount={data['suggestions'].length}>
            {renderMerchant}
          </FixedSizeList>
        </Grid>
        <Grid item className={classes.suggestionList}>
          <h3>Links</h3>
          <FixedSizeList
            height={300}
            itemSize={46}
            itemCount={data['suggestions'].length}>
            {renderLink}
          </FixedSizeList>
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmPopup = ({
  open,
  setOpen,
  setConfirmed,
  suggestMerchant,
  clearState,
}) => {
  const confirmPopup = () => {
    setConfirmed(true);
    setOpen(false);
    suggestMerchant();
    clearState();
  };
  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Confirm
      isOpen={open}
      title="Confirm New Merchant Name Suggestion"
      content="Are you sure you want to add new merchant name suggestion ?"
      onConfirm={confirmPopup}
      onClose={closePopup}
    />
  );
};

const MerchantSuggestionFormDialog = ({
  merchantNameForRef,
  txnId,
  categoryMap,
  open,
  onClose,
}) => {
  // insert blank key into categoryMap to enable not patching in the request when field is set to blank
  const catgoryChoices = getAllCategories(categoryMap).concat(BlankChoice);
  const [category, setCategory] = useState(BlankChoice);
  const [merchName, setMerchName] = useState('');
  const [link, setLink] = useState('');
  const [popup, setPopup] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const [suggestionData, setSuggestionData] = useState();
  const {data: storedData, resource} = useListContext();
  const [refreshTxns, {}] = useMutation({
    type: GET_MANY,
    resource: resource,
    payload: {ids: [txnId]},
  });
  const [suggestMerchant, {data, total, error, loading, loaded}] = useMutation(
    {
      type: 'suggest_merchant_name',
      resource: 'expenses',
      payload: {
        txn_id: txnId,
        merchant_name: merchName,
        link: link,
        category: category['id'],
      },
    },
    {
      onSuccess: () => {
        notify('Merchant name suggestion successful!');
        refreshTxns();
        clearState();
        onClose();
      },
      onFailure: (error) => {
        notify(
          `Merchant name suggestion failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );
  const clearState = () => {
    setCategory(BlankChoice);
    setMerchName('');
    setLink('');
  };

  const isNewMerchant = () => {
    const merchantNames = new Set(
      suggestionData['suggestions'].map((item) => item[0]),
    );
    const links = new Set(
      suggestionData['suggestions'].map((item) => item[3]),
    );
    if (!merchantNames.has(merchName) || !links.has(link)) return true;
    return false;
  };

  const handleConfirm = () => {
    if (isNewMerchant() && !confirmed) setPopup(true);
    else {
      suggestMerchant();
      clearState();
    }
  };
  const onCancel = () => {
    clearState();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      aria-labelledby="form-dialog-title"
      scroll="body"
      height={200}>
      <DialogContent>
        <DialogContentText>{merchantNameForRef}</DialogContentText>
        <TextField
          className={classes.textFieldStyle}
          label="Decorated Merchant Name"
          variant="outlined"
          value={merchName}
          onChange={(event) => {
            setMerchName(event.target.value);
          }}
        />
        <TextField
          className={classes.textFieldStyle}
          label="Link"
          variant="outlined"
          value={link}
          onChange={(event) => {
            setLink(event.target.value);
          }}
        />
        <Autocomplete
          className={classes.dialogFieldStyle}
          options={catgoryChoices}
          value={category}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            if (newValue != null) {
              setCategory(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Expense Category"
              variant="outlined"
            />
          )}
        />
        {(merchName || link) && (
          <MerchantSuggestionList
            merchantName={merchName}
            setSuggestionData={setSuggestionData}
            link={link}
            setMerchantName={setMerchName}
            setCategory={setCategory}
            setLink={setLink}
          />
        )}
        <ConfirmPopup
          open={popup}
          setOpen={setPopup}
          setConfirmed={setConfirmed}
          suggestMerchant={suggestMerchant}
          clearState={clearState}
        />
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={onCancel}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={handleConfirm}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirm
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const MerchantSuggestionButton = ({...props}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Button label="Suggest" onClick={handleClick} />
      <MerchantSuggestionFormDialog
        merchantNameForRef={props.record.merchant_name}
        txnId={props.record.id}
        categoryMap={props.categoryMap}
        open={open}
        onClose={handleDialogClose}
        goBack={() => navigate(-1)}
      />
    </Fragment>
  );
};

export default MerchantSuggestionButton;
