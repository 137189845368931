import React, {ComponentProps} from 'react';
import Cell from './Cell';
import SimpleLabel from '../CellsInternal/SimpleLabel';

interface ReadOnlyCellProps
  extends ComponentProps<typeof SimpleLabel>,
    Omit<ComponentProps<typeof Cell>, 'children'> {}

const ReadOnlyCell = ({
  value,
  getReadValue = (value: any) => value,
  cellStyle = {},
  backgroundColor,
  style = {},
  textStyle = {},
}: ReadOnlyCellProps) => {
  return (
    <Cell backgroundColor={backgroundColor} cellStyle={cellStyle}>
      <SimpleLabel
        value={getReadValue(value)}
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...style,
        }}
        textStyle={textStyle}
      />
    </Cell>
  );
};

export default ReadOnlyCell;
