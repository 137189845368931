import {TextField} from '@mui/material';
import React, {useState} from 'react';
import JSONPretty from 'react-json-pretty';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import DSButton from 'src/DesignSystem/Button/Button';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {retrieveUserDetails, updateUserSubscriptionId} from 'src/appApi';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const ResponseConfig = [
  {
    keyName: 'user_data',
    decription: 'User Details',
  },
  {
    keyName: 'products_info',
    decription: 'Products Api Response',
  },
  {
    keyName: 'subs_info',
    decription: 'Subscription Info',
  },
  {
    keyName: 'refund_details',
    decription: 'Refund Details',
  },
];

const GetUserDetails = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const [newSubsId, setNewSubsId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [isDetailsPopup, setIsDetailsPopup] = useState(false);

  const refreshUserDetails = async () => {
    const {data} = (await retrieveUserDetails({fly_user_id: userId})) as any;
    setUserDetails(data);
  };

  const onGetUserDetails = async () => {
    try {
      setIsLoading(true);
      await refreshUserDetails();
      setIsDetailsPopup(true);
    } catch (e) {
      alert(JSON.stringify(e?.response?.data ?? '') ?? 'Error occured');
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateUserSubsId = async () => {
    try {
      setIsLoading(true);
      await updateUserSubscriptionId({
        fly_user_id: userId,
        new_subs_id: newSubsId,
      });
      await refreshUserDetails();
      setNewSubsId('');
    } catch (e) {
      alert(JSON.stringify(e?.response?.data ?? '') ?? 'Error occured');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '90vh',
        flexDirection: 'column',
      }}>
      <TextField
        value={userId}
        placeholder={'Enter fly user id'}
        type="number"
        disabled={isLoading}
        onChange={(e) => setUserId(e.target.value)}
      />
      <DSButton
        text="Get details"
        type="primary"
        onClick={onGetUserDetails}
        disabled={isLoading}
        style={{margin: 20, paddingInline: 32, paddingBlock: 8}}
      />
      <PopUp
        style={{height: 600, width: 800}}
        primaryButtonTitle="Close"
        primaryButtonDisabled={isLoading}
        isOpen={isDetailsPopup}
        onClose={() => setIsDetailsPopup(false)}>
        <div style={{height: 550, overflowY: 'auto'}}>
          {ResponseConfig.map((item) => {
            return (
              <div>
                <DSAccordion name={item.decription}>
                  <JSONPretty
                    id={item.decription}
                    data={userDetails[item.keyName]}
                    theme={JSONPrettyMon}
                    style={{fontSize: 20}}
                  />
                </DSAccordion>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <TextField
            value={newSubsId}
            placeholder={'Enter new subs id'}
            disabled={isLoading}
            onChange={(e) => setNewSubsId(e.target.value)}
          />

          <DSButton
            type="primary"
            onClick={onUpdateUserSubsId}
            text={`Update subs id for user: ${userDetails?.['user_data']?.['email']}`}
            disabled={!newSubsId || isLoading}
            style={{padding: 12}}
          />
        </div>
      </PopUp>
    </div>
  );
};

export default GetUserDetails;
