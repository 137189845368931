import React from 'react';

interface StatusPendingIconProps {
  color?: string;
}

const StatusPendingIcon = ({color = '#FFC94A'}: StatusPendingIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="3.5 3.5"
      />
    </svg>
  );
};

export default StatusPendingIcon;
