import {useDispatch} from 'react-redux';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';

const useNotify = () => {
  const dispatch = useDispatch();
  const notify = (message: string, type: NotificationType) => {
    dispatch(
      setAppState(AppReducerStates.notifcationStatus, {
        isVisible: true,
        message,
        type,
      }),
    );
  };
  return {
    notify,
  };
};

export default useNotify;
