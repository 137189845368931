import React from 'react';
import {themmeColor} from 'src/constants/constants';

const DownIcon = ({color = themmeColor.black}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={color}>
      <path
        d="M18.667 9L12.667 15L6.66699 9"
        stroke="#121212"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DownIcon;
