import React, {useCallback} from 'react';
import {InputWrapperBaseType} from '../components/InputWrapper/InputWrapper';
import Text from '../Text/Text';
import {TEXT_TYPES} from '../Text/Text.types';
import {Switch, SwitchProps, styled} from '@mui/material';
import {themmeColor} from 'src/constants/constants';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(
  ({
    theme,
    width = 42,
    height = 26,
    thumbSize = 22,
    trackOpacity = 1,
    checkedColor = themmeColor.black,
    uncheckedColor = themmeColor.lightGrey,
  }: any) => ({
    width,
    height,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: (height - thumbSize) / 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${width - height}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: checkedColor,
          opacity: trackOpacity,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: thumbSize,
      height: thumbSize,
    },
    '& .MuiSwitch-track': {
      borderRadius: height / 2,
      backgroundColor: uncheckedColor,
      opacity: trackOpacity,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }),
);

interface SwitchFieldProps extends InputWrapperBaseType {
  onChangeValue: (newValue: any) => void;
  value: boolean | undefined;
  style?: React.CSSProperties;
}

const SwitchField = ({
  onChangeValue,
  value,
  title,
  ...rest
}: SwitchFieldProps) => {
  const _onChangeValue: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        onChangeValue(e.target.checked);
      },
      [onChangeValue],
    );

  const titleJsx = title ? (
    <Text
      type={TEXT_TYPES.BASE}
      containerStyle={{marginRight: 8}}
      text={title}
    />
  ) : null;
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {titleJsx}
      <IOSSwitch onChange={_onChangeValue} checked={value} />
    </div>
  );
};

export default SwitchField;
