import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form} from 'src/DesignSystem/Form/Form';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {createPartner} from 'src/appApi';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchPartners} from 'src/store/partners/partners.actions';

interface CreatePartnersPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const Fields = [
  {
    question: 'Partner name',
    field_name: 'partner_name',
    value: '',
  },
  {
    question: 'Partner poc name',
    field_name: 'partner_poc_name',
    value: '',
  },
  {
    question: 'Partner poc email',
    field_name: 'partner_poc_email',
    value: '',
  },
];

const getInitObject = () => {
  const obj: any = {};
  Fields.forEach((field) => {
    obj[field.field_name] = field.value;
  });
  return obj;
};

const CreatePartnersPopup = ({isOpen, onClose}: CreatePartnersPopupProps) => {
  const [partnerDetails, setPartnerDetails] = useState(() => getInitObject());

  const [isLoading, setIsLoading] = useState(false);

  const {notify} = useNotify();
  const dispatch = useDispatch();

  const updateField = (key: any, value: any) => {
    setPartnerDetails((prev: any) => ({...prev, [key]: value}));
  };

  const isInvalidInput = () => {
    return Fields.some(
      (field) =>
        typeof partnerDetails[field.field_name] !== 'string' ||
        partnerDetails[field.field_name]?.length === 0,
    );
  };

  const onCreatePartner = async () => {
    try {
      setIsLoading(true);
      await createPartner(partnerDetails);
      await dispatch(fetchPartners());
      setPartnerDetails(getInitObject());
      onClose();
      notify('Created Partner successfully', NotificationType.success);
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopUp
      style={{width: 600}}
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonTitle="Create"
      primaryButtonDisabled={isInvalidInput() || isLoading}
      primaryButtonOnClick={onCreatePartner}>
      <div>
        <Text
          text={'Create partner'}
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
          containerStyle={{marginBottom: 16}}
        />
        {Fields.map((field) => {
          return (
            <Form.TextField
              title={field.question}
              value={partnerDetails[field.field_name]}
              onChangeValue={(newVal) => updateField(field.field_name, newVal)}
              style={{marginBottom: 12}}
              disabled={isLoading}
            />
          );
        })}
      </div>
    </PopUp>
  );
};

export default CreatePartnersPopup;
