import {
  SET_TAXONOMY,
  SET_CPA_MESSAGE_TEMPLATE,
  SWITCH_ACTIVE_YEAR,
} from '../actionNames';
import {ReduxActionType} from '../types';

export const SUPPORTED_YEARS = [2022, 2023, 2024] as const;

const defaultYear = SUPPORTED_YEARS[SUPPORTED_YEARS.length - 1];

const initialState = {
  activeYear: defaultYear,
  taxonomy: {
    expense_txn_categories: {} as any,
    learning_card_categories: {} as any,
    deduction_categories: {} as {
      [key: string]: {
        h2: string;
        tax_tip: string;
        image: string;
        primary_l0: string;
        name: string;
        l2: string[];
        synonyms: string[];
        included: string[];
      };
    },
    l0_info: {} as any,
    income_txn_categories: {} as any,
    income_irs_categories: {} as any,
  },
  cpaMessageTemplates: [] as {title: string; body: string}[],
};

export const globals = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SWITCH_ACTIVE_YEAR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TAXONOMY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_CPA_MESSAGE_TEMPLATE:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};

export type GlobalsStateType = typeof initialState;
