import {InputAdornment, TextField} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import SimpleLabel from './SimpleLabel';

interface PercentageInputProps {
  value: number;
  onChange: (newValue: number) => void;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  notApplicable?: boolean;
  disabled?: boolean;
  borderColor?: string;
}

const BASE = 1;

const PercentageInput = ({
  value,
  onChange,
  notApplicable = false,
  disabled = false,
  textStyle = {},
  style = {},
  borderColor,
}: PercentageInputProps) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberString = e.target.value;
    const newValue = Number(numberString) / BASE;
    if (!Number.isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      onChange(newValue);
    }
  };

  if (disabled) {
    return <SimpleLabel value={notApplicable ? 'NA' : `${value}%`} />;
  }

  return (
    <div style={style}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={notApplicable ? 'NA' : `${Math.round(value * BASE)}`}
        onChange={_onChange}
        disabled={notApplicable || disabled}
        onFocus={(e) => e.currentTarget.select()}
        InputProps={{
          sx: {
            backgroundColor: '#F0F0F0',
            borderColor: borderColor ? borderColor : themmeColor.offWhite,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: '8px',
            width: 80,
            height: 48,
            ':hover': {
              borderColor,
            },
            input: {
              textAlign: 'center',
            },
            ...textStyle,
          },
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </div>
  );
};

export default PercentageInput;
