import React from 'react';
import {useEditController, Title} from 'react-admin';
import {
  useQueryWithStore,
  Loading,
  Error,
  useMutation,
  useNotify,
} from 'react-admin';
import {
  choices,
  SelectInput,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  TabbedForm,
  FormTab,
  useRedirect,
  AutocompleteInput,
  FormDataConsumer,
  required,
  ListContextProvider,
  NullableBooleanInput,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import keyBy from 'lodash/keyBy';
import Button from '@material-ui/core/Button';
import {useForm} from 'react-final-form';
import {JsonField} from 'react-admin-json-view';
import Grid from '@material-ui/core/Grid';

import SingleExpenseNotifyButton from '../notifications/SingleExpenseNotifyButton';
import CreateDuplicateButton from './CreateDuplicate';
import {
  BlankChoice,
  DeductionClassChoices,
  NotSureCategoryReasonChoices,
} from '../constants/constants';
import {
  getAllCategories,
  getSubCategoriesFor,
} from '../common/ExpenseCategoriesUtils';
import {getMealsCategoriesAll} from '../common/ExpenseCategoriesUtils';
import {TxnColumns} from './ExpenseList';
import {Fragment} from 'react';

const useEditStyles = makeStyles({
  actions: {
    float: 'right',
  },
  card: {
    marginTop: '1em',
    // maxWidth: '30em',
  },
});

const validateDeductionClass = [
  required(),
  choices(
    DeductionClassChoices.map((x) => x['id']),
    'Invalid deduction class',
  ),
];

const ExpenseUserProfile = ({userId}) => {
  const {data, loading, error} = useQueryWithStore({
    type: 'getOne',
    resource: 'users',
    payload: {id: userId},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <ul>
      <li>Id: {data.id}</li>
      <li>Name: {data.name}</li>
      <li>Email: {data.email}</li>
      <li>Profession: {data.profession}</li>
    </ul>
  );
};

// const ExpenseEditActions = ({basePath, data, resource}) => {
//     <TopToolbar>
//         {/* <ShowButton basePath={basePath} record={data} /> */}
//         {/* Add your custom actions */}
//         <Button color="primary" onClick={customAction}>Custom Action</Button>
//     </TopToolbar>
// }

const ExpenseEditToolbar = (props) => {
  const form = useForm();
  var formdata = form.getState().values;

  const {resource, record} = props;
  const redirect = useRedirect();
  // TODO : currently sending repeated query for adj txns.
  // Look into how to reuse the response
  const {data, loading, error} = useQueryWithStore({
    type: 'adjacent_txns',
    resource: 'expenses',
    payload: {txnId: record.id, count: 1},
  });

  if (loading) return <Loading />;
  if (error) {
    return <Error />;
  }
  if (!data) return null;

  return (
    <Toolbar {...props}>
      <SaveButton />
      {/* <Button color="primary" href={"/expenses/"+data["newer"]} disabled={data["newer"] != "0"}>Newer</Button>
        <Button color="primary" href={"/expenses/"+data["older"]} disabled={data["newer"] != "0"}>Older</Button> */}
      <Button
        color="primary"
        onClick={() => redirect('edit', '/expenses', data['newer'])}
        disabled={data['newer'] == '0'}>
        Newer
      </Button>
      <Button
        color="primary"
        onClick={() => redirect('edit', '/expenses', data['older'])}
        disabled={data['older'] == '0'}>
        Older
      </Button>
      <SingleExpenseNotifyButton
        txnId={record.id}
        notifSent={record.notification_sent}
        formData={formdata}
      />
      {(() => {
        if (process.env.REACT_APP_ENVIRONMENT != 'production') {
          return <CreateDuplicateButton plaidTxnId={record.plaid_txn.id} />;
        }
      })()}
    </Toolbar>
  );
};

const postRowClick = (id, basePath, record) => {
  window.open(`#/expenses/${id}`);
};

const RelatedTxnsView = ({txnId, type}) => {
  const {data, loading, error} = useQueryWithStore({
    type: type,
    resource: 'expenses',
    payload: {txnId: txnId, count: 10},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;
  // See https://github.com/marmelab/react-admin/issues/5020
  // for why we used ListContextProvider
  return (
    <ListContextProvider
      value={{
        resource: 'expenses',
        basePath: '/expenses',
        data: keyBy(data, 'id'),
        ids: data.map(({id}) => id),
        currentSort: {field: 'txn_date', order: 'DESC'},
        selectedIds: [],
      }}>
      <TxnColumns
        rowClick={postRowClick}
        defaultColumns={[
          'id',
          'merchant_name',
          'current_amount',
          'txn_date',
          'plaid_category',
          'expense_category',
          'expense_sub_category',
          'classification_status',
          'last_modified_by',
          'notification_state',
        ]}
      />
    </ListContextProvider>
  );
};

const ClassificationHelperView = ({txnId}) => {
  return (
    <TabbedForm submitOnEnter={false} redirect={false} toolbar={false}>
      <FormTab label="Historical expenses">
        <RelatedTxnsView txnId={txnId} type="hist_txns" />
      </FormTab>
      <FormTab label="Similar expenses">
        <RelatedTxnsView txnId={txnId} type="similar_txns" />
      </FormTab>
    </TabbedForm>
  );
};

const MyExpenseEdit = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = useEditController(props);

  const classes = useEditStyles();
  const notify = useNotify();
  const [mutate] = useMutation();
  const {
    data: taxonomy,
    loading: catLoading,
    error: catError,
  } = useQueryWithStore({
    type: 'getUniverse',
    resource: 'categorizer',
  });
  if (catLoading) return <Loading />;
  if (catError) return <Error />;
  if (!taxonomy) return null;
  const categoryMap = taxonomy['hierarchy'];
  const mealsCategories = getMealsCategoriesAll(
    taxonomy['expense_categories'],
    taxonomy['expense_categories_depr'],
  );

  const editTxn = (
    id,
    payloadData,
    unknown_merchant,
    merchant_error_severity,
    not_sure_category,
    not_sure_cat_reason,
    is_plaid_cat_correct,
  ) =>
    mutate(
      {
        type: 'bulk_partial_update',
        resource: 'expenses',
        payload: {
          ids: [id],
          patch_data: payloadData,
          unknown_merchant,
          merchant_error_severity,
          not_sure_category,
          not_sure_cat_reason,
          is_plaid_cat_correct,
        },
      },
      {
        onSuccess: () => {
          notify('Expense Edit successful!');
        },
        onFailure: (error) => {
          notify(
            `Expense Edit failed!: ${error.body['display_msg']}`,
            'warning',
          );
        },
      },
    );

  const customSave = (props) => {
    var payloadData = {};
    if (!props.classifier_data.unknown_merchant) {
      payloadData.merchant_name = props.updated_merchant_name;
      payloadData.classification_status = props.updated_classification_status;
      payloadData.expense_category = props.updated_expense_category;
      payloadData.expense_sub_category = getSubCategoriesFor(
        props.updated_expense_category,
        categoryMap,
      )[0].id;
    }
    editTxn(
      props.id,
      payloadData,
      props.classifier_data.unknown_merchant ? true : false,
      props.classifier_data.merchant_error_severity,
      props.classifier_data.unknown_merchant
        ? true
        : props.classifier_data.not_sure_category,
      props.classifier_data.unknown_merchant
        ? NotSureCategoryReasonChoices[0].id
        : props.classifier_data.not_sure_cat_reason ?? undefined,
      props.classifier_data.is_plaid_cat_correct ?? undefined,
    );
  };

  return (
    <div className="edit-page">
      <Title defaultTitle={`Expense #${record ? record.id : ''}`} />
      <Card className={classes.card}>
        {record && (
          <ExpenseUserProfile userId={record.fly_user} />
          // <SimpleForm
          //     basePath={basePath}
          //     redirect={false}
          //     resource={resource}
          //     record={record}
          //     save={false}
          //     toolbar={false}
          // >
          //     <TextField source="plaid_txn" label="Plaid Transaction ID" />
          //     <TextField source="expense_category" label="Expense category" />
          // </SimpleForm>
        )}
      </Card>
      <Card className={classes.card}>
        {record && (
          <TabbedForm
            basePath={basePath}
            redirect={false}
            resource={resource}
            record={record}
            save={customSave}
            version={version}
            toolbar={<ExpenseEditToolbar />}>
            <FormTab label="Txn">
              <Grid container style={{width: '100%'}}>
                <Grid xs={4} style={{marginRight: '10%'}}>
                  <FormDataConsumer>
                    {({formData, ...rest}) => {
                      let currentChoices = getAllCategories(categoryMap);
                      if (!(formData.expense_category in categoryMap)) {
                        currentChoices.push({
                          id: formData.expense_category,
                          name: formData.expense_category,
                        });
                      }
                      return (
                        <Fragment>
                          <TextInput
                            disabled={
                              formData.classifier_data.unknown_merchant
                            }
                            source="updated_merchant_name"
                          />
                          <Grid container direction="row" alignItems="center">
                            <BooleanInput
                              label="Unknown"
                              source="classifier_data.unknown_merchant"
                            />
                          </Grid>
                          <AutocompleteInput
                            disabled={
                              formData.classifier_data.unknown_merchant
                            }
                            source="updated_expense_category"
                            label="Updated category"
                            choices={currentChoices}
                            validate={required()}
                          />
                          <Grid container direction="row" alignItems="center">
                            <BooleanInput
                              label="Not Sure"
                              source="classifier_data.not_sure_category"
                            />
                            <SelectInput
                              disabled={
                                !formData.classifier_data.not_sure_category
                              }
                              label="Reason"
                              source="classifier_data.not_sure_cat_reason"
                              choices={NotSureCategoryReasonChoices.concat(
                                BlankChoice,
                              )}
                            />
                          </Grid>
                          <NullableBooleanInput
                            disabled={
                              formData.classifier_data.unknown_merchant
                            }
                            style={{width: 230}}
                            label="Is Plaid Category Correct?"
                            source="classifier_data.is_plaid_cat_correct"
                          />
                          <SelectInput
                            disabled={
                              formData.classifier_data.unknown_merchant
                            }
                            label="Updated Deduction?"
                            source="updated_classification_status"
                            choices={DeductionClassChoices}
                            validate={validateDeductionClass}
                          />
                        </Fragment>
                      );
                    }}
                  </FormDataConsumer>
                </Grid>
                <Grid xs={2} style={{marginRight: '10%'}}>
                  <TextInput disabled source="id" />
                  <TextInput disabled source="original_amount" />
                  <TextInput disabled source="current_amount" />
                  <TextInput disabled source="txn_date" />
                </Grid>

                <Grid xs={2}>
                  <TextInput
                    disabled
                    source="plaid_category"
                    label="Plaid Category"
                  />
                  <TextInput disabled source="is_pending" />
                  <TextInput disabled source="tax_deductible_amount" />
                  <TextInput disabled source="state" />
                </Grid>
              </Grid>
            </FormTab>
            <FormTab label="Plaid Txn">
              <JsonField
                source="plaid_txn"
                addLabel={true}
                reactJsonOptions={{
                  name: null,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
              <JsonField
                source="posted_plaid_txn"
                addLabel={true}
                reactJsonOptions={{
                  name: null,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
            </FormTab>
            <FormTab label="Classifier">
              <JsonField
                source="classifier_data"
                addLabel={true}
                reactJsonOptions={{
                  name: null,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
            </FormTab>
          </TabbedForm>
        )}
      </Card>
      <Card className={classes.card}>
        {record && <ClassificationHelperView txnId={record.id} />}
      </Card>
      {/* <h1>{defaultTitle}</h1> */}
      {/* {cloneElement(props.children, {
                basePath,
                record,
                redirect,
                resource,
                save,
                saving,
                version,
            })} */}
    </div>
  );
};

export default MyExpenseEdit;
