import React, {useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {Form} from 'src/DesignSystem/Form/Form';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import DSButton from 'src/DesignSystem/Button/Button';
import FilterIcon from 'src/icons/FilterIcon';
import MoreIcon from 'src/icons/MoreIcon';
import {themmeColor} from 'src/constants/constants';
import {usePartnershipUserList} from '../usePartnershipUserList';
import {
  PartnershipFilters,
  PartnershipMenuOptionTypes,
} from '../PartnershipUserList.types';
import PartnershipUserListFilterPopup from './PartnershipUserListFilterPopup';
import Menu from 'src/DesignSystem/Menu/Menu';
import {PartnershipMenuOptions} from '../PartnershipUserList.constants';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';

const PartnershipUserListHeader = () => {
  const {openDrawer} = useOutletContext<any>();
  const {filterCopy, updateFilterCopy, applyFiltersFromCopy, clearAllFilters} =
    usePartnershipUserList();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const {navigateToPartnersList} = usePartnershipNavigation();

  const onClickMenuOption = (id: any) => {
    if (id === PartnershipMenuOptionTypes.ManagePartners) {
      navigateToPartnersList();
    } else {
      alert(`Implement me brother: ${id}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        padding: 24,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawer}>
          <MenuIcon />
        </IconButton>
        <Form.Text
          text="Tax filing(P)"
          type={TEXT_TYPES.L}
          fontWeight={Form.Text.FONT_WEIGHTS.ExtraBold}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Form.NumberField
          placeholder="User Id"
          value={filterCopy[PartnershipFilters.fly_user_id] ?? ''}
          onChangeValue={(newVal) =>
            updateFilterCopy(PartnershipFilters.fly_user_id, newVal)
          }
          style={{marginRight: 16}}
        />
        <Form.TextField
          placeholder="Email"
          value={filterCopy[PartnershipFilters.email] ?? ''}
          onChangeValue={(newVal) =>
            updateFilterCopy(PartnershipFilters.email, newVal)
          }
          style={{marginRight: 16}}
        />
        <Form.TextField
          placeholder="Name"
          value={filterCopy[PartnershipFilters.name] ?? ''}
          onChangeValue={(newVal) =>
            updateFilterCopy(PartnershipFilters.name, newVal)
          }
          style={{marginRight: 16}}
        />
        <DSButton
          type="primary"
          text="Search"
          onClick={applyFiltersFromCopy}
          style={{height: 48, marginRight: 16}}
        />
        <DSButton
          type="secondary"
          text="Clear all"
          onClick={clearAllFilters}
          style={{height: 48, marginRight: 16}}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <DSButton
          type="secondary"
          text="Filters"
          onClick={() => setIsFilterOpen(true)}
          startIcon={<FilterIcon />}
          style={{height: 48, marginRight: 16}}
        />
        <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
          <MoreIcon color={themmeColor.black} />
        </IconButton>
      </div>
      <PartnershipUserListFilterPopup
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
      />
      <Menu
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        onClick={onClickMenuOption}
        options={PartnershipMenuOptions.map((option) => {
          return {
            id: option.id,
            Component: <Text text={option.title} />,
          };
        })}
      />
    </div>
  );
};

export default PartnershipUserListHeader;
