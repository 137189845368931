import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  TAX_FILING_STATUS,
  themmeColor,
  VALID_CPA_EMAILS,
} from 'src/constants/constants';
import {
  ACTION_TYPES,
  BACKEND_RESPONSE_KEYS,
  CPA_LIST_COLUMNS,
  EXTENSION_STATUS,
  getValuesForDateFilter,
  PAYMENT_PLAN,
  TAX_RETURN_STATUS_LABELS,
  USER_ACTIVITY_TYPE,
} from 'src/CpaCenterList/components/cpaList.utils';
import {Button, Divider, Modal, Typography} from '@mui/material';
import {parse} from 'querystring';
import {TF_SEGMENT, USER_COMMS_MODE} from 'src/store/user/user.reducer';
import {filterFields} from '../CpaCenterList.constants';

interface CpaListFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  tableData: any;
}

const CpaListFilterModal = ({
  isOpen,
  onClose,
  tableData,
}: CpaListFilterModalProps) => {
  const columnItems = tableData.filter((column) =>
    [
      CPA_LIST_COLUMNS.USER_ID,
      CPA_LIST_COLUMNS.RETURN_STATUS,
      CPA_LIST_COLUMNS.CPA,
    ].includes(column.label),
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultReverse = searchParams.get(filterFields.REVERSE);
  const defaultSortingKey = searchParams.get(filterFields.SORTING_KEYS);
  const defaultPlansKey = searchParams.getAll(filterFields.PAYMENT_PLANS);
  const defaultSelectedReturnStatus = searchParams.getAll(
    filterFields.RETURN_STATUS,
  );
  const defaultSelectedCpaActions = searchParams.getAll(
    filterFields.CPA_ACTION_REQUIRED,
  );
  const defaultSelectedReviewerActions = searchParams.getAll(
    filterFields.REVIEWER_ACTION_REQUIRED,
  );
  const defaultSelectedCPAs = searchParams.getAll(filterFields.CPA);
  const defaultSelectedReviewers = searchParams.getAll(filterFields.REVIEWER);
  const defaultSelectedDates = searchParams.getAll(
    filterFields.NEXT_ACTION_DATE,
  );
  const defaultSelectedTaxExtension = searchParams.getAll(
    filterFields.TAX_EXTENSION_STATUS,
  );
  const defaultSelectedTaxQueryStatus = searchParams.get(
    filterFields.TAX_QUERY_OPEN,
  );
  const defaultSelectedActivity = searchParams.get(
    filterFields.USER_ACTIVITY_STATUS,
  );
  const defaultSelectedCommsMode = searchParams.get(filterFields.COMMS_MODE);
  const defaultSelectedExpressExtensionEligible = searchParams.get(
    filterFields.EXPRESS_EXTENSION_ELIGIBLE,
  );
  const defaultSelectedCollaborator = searchParams.getAll(
    filterFields.COLLABORATOR_EMAIL,
  );
  const defaultSelectedTFSegment = searchParams.getAll(
    filterFields.TF_SEGMENT,
  );
  const defaultSelectedFiledElsewhere = searchParams.get(
    filterFields.FILED_ELSEWHERE,
  );

  const [filterStates, setFilterStates] = useState({
    [filterFields.REVERSE]: defaultReverse ? defaultReverse === 'true' : true,
    [filterFields.SORTING_KEYS]:
      defaultSortingKey ?? BACKEND_RESPONSE_KEYS.STATUS,
    [filterFields.PAYMENT_PLANS]: defaultPlansKey ?? [],
    [filterFields.RETURN_STATUS]: defaultSelectedReturnStatus ?? [],
    [filterFields.CPA_ACTION_REQUIRED]: defaultSelectedCpaActions ?? [],
    [filterFields.REVIEWER_ACTION_REQUIRED]:
      defaultSelectedReviewerActions ?? [],
    [filterFields.TAX_EXTENSION_STATUS]: defaultSelectedTaxExtension ?? [],
    [filterFields.REVIEWER]: defaultSelectedReviewers ?? [],
    [filterFields.CPA]: defaultSelectedCPAs ?? [],
    [filterFields.USER_ACTIVITY_STATUS]: defaultSelectedActivity,
    [filterFields.TAX_QUERY_OPEN]: defaultSelectedTaxQueryStatus,
    [filterFields.NEXT_ACTION_DATE]: defaultSelectedDates ?? [],
    [filterFields.COMMS_MODE]: defaultSelectedCommsMode,
    [filterFields.EXPRESS_EXTENSION_ELIGIBLE]:
      defaultSelectedExpressExtensionEligible,
    [filterFields.COLLABORATOR_EMAIL]: defaultSelectedCollaborator ?? [],
    [filterFields.TF_SEGMENT]: defaultSelectedTFSegment ?? [],
    [filterFields.FILED_ELSEWHERE]: defaultSelectedFiledElsewhere,
  });

  const handleSingleSelector = (key: filterFields, value: any) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMultiSelector = (key: filterFields, value: any) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: filterStates[key].includes(value)
        ? filterStates[key].filter((option) => option !== value)
        : [...filterStates[key], value],
    }));
  };

  const handleApply = () => {
    // @ts-ignore
    setSearchParams((prevParams) => ({
      ...parse(prevParams.toString()),
      [filterFields.REVERSE]: filterStates[filterFields.REVERSE],
      [filterFields.SORTING_KEYS]: filterStates[filterFields.SORTING_KEYS],
      [filterFields.PAYMENT_PLANS]: filterStates[filterFields.PAYMENT_PLANS],
      [filterFields.RETURN_STATUS]: filterStates[filterFields.RETURN_STATUS],
      [filterFields.CPA_ACTION_REQUIRED]:
        filterStates[filterFields.CPA_ACTION_REQUIRED],
      [filterFields.REVIEWER_ACTION_REQUIRED]:
        filterStates[filterFields.REVIEWER_ACTION_REQUIRED],
      [filterFields.TAX_EXTENSION_STATUS]:
        filterStates[filterFields.TAX_EXTENSION_STATUS],
      [filterFields.REVIEWER]: filterStates[filterFields.REVIEWER],
      [filterFields.CPA]: filterStates[filterFields.CPA],
      [filterFields.USER_ACTIVITY_STATUS]:
        filterStates[filterFields.USER_ACTIVITY_STATUS],
      [filterFields.TAX_QUERY_OPEN]: filterStates[filterFields.TAX_QUERY_OPEN],
      [filterFields.NEXT_ACTION_DATE]:
        filterStates[filterFields.NEXT_ACTION_DATE],
      [filterFields.COMMS_MODE]: filterStates[filterFields.COMMS_MODE],
      [filterFields.EXPRESS_EXTENSION_ELIGIBLE]:
        filterStates[filterFields.EXPRESS_EXTENSION_ELIGIBLE],
      [filterFields.COLLABORATOR_EMAIL]:
        filterStates[filterFields.COLLABORATOR_EMAIL],
      [filterFields.TF_SEGMENT]: filterStates[filterFields.TF_SEGMENT],
      [filterFields.FILED_ELSEWHERE]:
        filterStates[filterFields.FILED_ELSEWHERE],
    }));
    onClose();
  };

  // @ts-ignore
  const planNamesKeys: Array<keyof typeof PAYMENT_PLAN> =
    Object.keys(PAYMENT_PLAN);

  const returnStatusKeys: Array<TAX_FILING_STATUS> = Object.values(
    TAX_RETURN_STATUS_LABELS,
  ).map((value) => value.id);

  const dates = getValuesForDateFilter();

  const taxExtensionKeys = Object.values(EXTENSION_STATUS).filter(
    (extStatus) => extStatus !== EXTENSION_STATUS.NULL,
  ) as Array<EXTENSION_STATUS>;

  const commsMode = Object.values(USER_COMMS_MODE);
  const tfSegmentOptions = Object.values(TF_SEGMENT);

  const BubbleButton = ({
    label,
    onClick,
    selected = false,
    spaceAround,
  }: {
    label: string;
    onClick: () => void;
    selected: boolean;
    spaceAround?: boolean;
  }) => {
    return (
      <Button
        variant={'outlined'}
        style={{
          borderRadius: 8,
          margin: spaceAround ? 5 : 0,
          textTransform: 'none',
          borderColor: selected ? themmeColor.darkBlue : themmeColor.grey,
          color: selected ? themmeColor.darkBlue : themmeColor.grey,
          borderWidth: selected ? 2 : 1,
          fontWeight: selected ? 'bold' : 'normal',
        }}
        onClick={onClick}>
        {label}
      </Button>
    );
  };

  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={isOpen}
      onClose={onClose}>
      <div
        style={{
          padding: '20px',
          backgroundColor: themmeColor.white,
          width: '40%',
          overflow: 'scroll',
          maxHeight: '90vh',
        }}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography fontSize={'20px'} fontWeight={'bold'} padding={'10px'}>
            Filters
          </Typography>
        </div>
        <Divider style={{marginTop: '10px'}} />
        {/* Sorting Container */}
        <div style={{marginTop: '10px'}}>
          <div>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Order
            </Typography>
          </div>
          {/* Sorting order button */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 10,
              marginTop: '10px',
            }}>
            <BubbleButton
              label={'Increasing'}
              onClick={() => handleSingleSelector(filterFields.REVERSE, false)}
              selected={!filterStates[filterFields.REVERSE]}
            />
            <BubbleButton
              label={'Decreasing'}
              onClick={() => handleSingleSelector(filterFields.REVERSE, true)}
              selected={filterStates[filterFields.REVERSE]}
            />
          </div>
          <Divider style={{marginTop: '10px'}} />
          {/* Sorting keys */}
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Sorting Keys
            </Typography>
          </div>
          <div
            style={{
              overflowWrap: 'break-word',
              marginTop: '10px',
            }}>
            {columnItems.map((item) => (
              <BubbleButton
                label={item.label}
                onClick={() =>
                  handleSingleSelector(
                    filterFields.SORTING_KEYS,
                    item.backendResponseKey,
                  )
                }
                selected={
                  filterStates[filterFields.SORTING_KEYS] ===
                  item.backendResponseKey
                }
                spaceAround
              />
            ))}
          </div>
        </div>
        <Divider style={{marginTop: '10px'}} />
        {/* Filters */}
        <div>
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Payment-Plans
            </Typography>
            <div style={{marginTop: '10px'}}>
              {planNamesKeys.map((planKey) => (
                <BubbleButton
                  label={PAYMENT_PLAN[planKey]}
                  onClick={() =>
                    handleMultiSelector(
                      filterFields.PAYMENT_PLANS,
                      PAYMENT_PLAN[planKey],
                    )
                  }
                  selected={filterStates[filterFields.PAYMENT_PLANS].includes(
                    PAYMENT_PLAN[planKey],
                  )}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Return-Status
            </Typography>
            <div style={{marginTop: '10px', gap: '10px'}}>
              {returnStatusKeys.map((returnId) => (
                <BubbleButton
                  label={TAX_RETURN_STATUS_LABELS[returnId].label}
                  onClick={() =>
                    handleMultiSelector(filterFields.RETURN_STATUS, returnId)
                  }
                  selected={filterStates[filterFields.RETURN_STATUS].includes(
                    returnId,
                  )}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Cpa Action Required
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(ACTION_TYPES).map((action) => (
                <BubbleButton
                  label={action}
                  onClick={() =>
                    handleMultiSelector(
                      filterFields.CPA_ACTION_REQUIRED,
                      action,
                    )
                  }
                  selected={filterStates[
                    filterFields.CPA_ACTION_REQUIRED
                  ].includes(action)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Reviewer Action Required
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(ACTION_TYPES).map((action) => (
                <BubbleButton
                  label={action}
                  onClick={() =>
                    handleMultiSelector(
                      filterFields.REVIEWER_ACTION_REQUIRED,
                      action,
                    )
                  }
                  selected={filterStates[
                    filterFields.REVIEWER_ACTION_REQUIRED
                  ].includes(action)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Tax Extension Status
            </Typography>
            <div style={{marginTop: '10px'}}>
              {taxExtensionKeys.map((action) => (
                <BubbleButton
                  label={action}
                  onClick={() =>
                    handleMultiSelector(
                      filterFields.TAX_EXTENSION_STATUS,
                      action,
                    )
                  }
                  selected={filterStates[
                    filterFields.TAX_EXTENSION_STATUS
                  ].includes(action)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Preparer
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(VALID_CPA_EMAILS).map((cpa) => (
                <BubbleButton
                  label={cpa}
                  onClick={() => handleMultiSelector(filterFields.CPA, cpa)}
                  selected={filterStates[filterFields.CPA].includes(cpa)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Reviewer
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(VALID_CPA_EMAILS).map((cpa) => (
                <BubbleButton
                  label={cpa}
                  onClick={() =>
                    handleMultiSelector(filterFields.REVIEWER, cpa)
                  }
                  selected={filterStates[filterFields.REVIEWER].includes(cpa)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Collaborator
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(VALID_CPA_EMAILS).map((cpa) => (
                <BubbleButton
                  label={cpa}
                  onClick={() =>
                    handleMultiSelector(filterFields.COLLABORATOR_EMAIL, cpa)
                  }
                  selected={filterStates[
                    filterFields.COLLABORATOR_EMAIL
                  ].includes(cpa)}
                  spaceAround
                />
              ))}
            </div>
          </div>
          {/* <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Next Action Date
            </Typography>
            <div style={{marginTop: '10px'}}>
              {dates.map((date) => (
                <BubbleButton
                  label={date.label}
                  onClick={() =>
                    handleMultiSelector(filterFields.NEXT_ACTION_DATE, date)
                  }
                  selected={filterStates[
                    filterFields.NEXT_ACTION_DATE
                  ].includes(date.timestamp)}
                  spaceAround
                />
              ))}
            </div>
          </div> */}
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              User activity status
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(USER_ACTIVITY_TYPE).map(
                (activity: USER_ACTIVITY_TYPE) => (
                  <BubbleButton
                    label={activity}
                    onClick={() =>
                      handleSingleSelector(
                        filterFields.USER_ACTIVITY_STATUS,
                        activity,
                      )
                    }
                    selected={
                      activity ===
                      filterStates[filterFields.USER_ACTIVITY_STATUS]
                    }
                    spaceAround
                  />
                ),
              )}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Tax query open
            </Typography>
            <div style={{marginTop: '10px'}}>
              {['true', 'false'].map((status) => (
                <BubbleButton
                  label={status}
                  onClick={() =>
                    handleSingleSelector(filterFields.TAX_QUERY_OPEN, status)
                  }
                  selected={
                    status === filterStates[filterFields.TAX_QUERY_OPEN]
                  }
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography style={{fontSize: '16px', fontWeight: 'normal'}}>
              Express extension eligible
            </Typography>
            <div style={{marginTop: '10px'}}>
              {['true', 'false'].map((status) => (
                <BubbleButton
                  label={status}
                  onClick={() =>
                    handleSingleSelector(
                      filterFields.EXPRESS_EXTENSION_ELIGIBLE,
                      status,
                    )
                  }
                  selected={
                    status ===
                    filterStates[filterFields.EXPRESS_EXTENSION_ELIGIBLE]
                  }
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography style={{fontSize: '16px', fontWeight: 'normal'}}>
              Tax Filing Segment
            </Typography>
            <div style={{marginTop: '10px'}}>
              {tfSegmentOptions.map((option) => (
                <BubbleButton
                  label={option}
                  onClick={() =>
                    handleMultiSelector(filterFields.TF_SEGMENT, option)
                  }
                  selected={filterStates[filterFields.TF_SEGMENT].includes(
                    option,
                  )}
                  spaceAround
                />
              ))}
            </div>
          </div>
        </div>
        <Divider style={{marginTop: '10px'}} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}>
          <Button
            onClick={onClose}
            style={{color: themmeColor.errorRed, marginRight: 5}}
            variant="text">
            Cancel
          </Button>
          <Button
            style={{color: themmeColor.black}}
            onClick={handleApply}
            variant="text">
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CpaListFilterModal;
