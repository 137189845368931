import {MenuItem, Radio, Select, Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';

interface Props {
  value: any;
  handleChange: (newValue: any) => void;
  options: {
    label: string;
    value: any;
  }[];
  style?: React.CSSProperties;
}

const RadioDropdown = ({value, handleChange, options, style = {}}: Props) => {
  return (
    <Select
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      inputProps={{'aria-label': 'Without label'}}
      size="small"
      style={{
        borderRadius: 20,
        background: themmeColor.offWhite,
        ...style,
      }}
      renderValue={(value) => (
        <Typography
          style={{
            fontSize: 13,
            fontWeight: 600,
            marginTop: 3,
          }}>
          {options.find((option) => option.value === value)?.label}
        </Typography>
      )}
      MenuProps={{PaperProps: {style: {borderRadius: 12}}}}>
      {options.map((option) => {
        return (
          <MenuItem value={option.value}>
            <Radio checked={option.value === value} />
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 600,
              }}>
              {option.label}
            </Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default RadioDropdown;
