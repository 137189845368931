import {Typography} from '@mui/material';
import React from 'react';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import Skeleton from 'src/icons/Skeleton';

interface SimpleKeyValueFieldProps {
  name: string;
  value: any;
  style?: React.CSSProperties;
  loading?: boolean;
}

const SimpleKeyValueField = ({
  name,
  value,
  style = {},
  loading = false,
}: SimpleKeyValueFieldProps) => {
  return (
    <div style={{marginBottom: 12, ...style}}>
      <Typography style={{fontSize: 13}}>{name}</Typography>
      {loading ? (
        <Skeleton height={20} width={50} radius={4}/>
      ) : (
        <Typography style={{fontSize: 16, fontWeight: 600}}>
          {getFieldValue(value)}
        </Typography>
      )}
    </div>
  );
};

export default SimpleKeyValueField;
