import React from 'react';
import shortid from 'shortid';

const Skeleton = ({
  width,
  height,
  style,
  radius = 12,
  ...rest
}: {
  width: any;
  height: any;
  radius?: number,
  style?: React.CSSProperties;
}) => {
  const id = shortid.generate();
  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none">
        <rect width={width} height={height} rx={radius} fill={`url(#${id})`} />
        <defs>
          <linearGradient
            id={id}
            x1={0}
            y1={height / 2}
            x2={width}
            y2={height / 2}
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#333333" />
            <stop offset="1" stop-color="#333333" stop-opacity="0.24" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Skeleton;
