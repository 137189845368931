import React, {ComponentProps, useEffect, useState} from 'react';
import {InputWrapperBaseType} from '../../common/components/InputWrapper/InputWrapper';
import CheckField from '../../common/CheckField/CheckField';
import RadioField from '../../common/RadioField/RadioField';
import {getProfessions} from 'src/appApi';
import {CircularProgress} from '@mui/material';

interface SingleProfessionProps {
  isSingle: true;
  value: string;
}

interface MultiProfessionProps {
  isSingle: false;
  value: string[];
}

type ConditionalProps = SingleProfessionProps | MultiProfessionProps;

type ProfessionProps = ConditionalProps &
  InputWrapperBaseType & {
    onChangeValue: (newValue: any) => void;
  };

const ProfessionField = ({title, onChangeValue, ...rest}: ProfessionProps) => {
  const [options, setOptions] = useState<
    ComponentProps<typeof CheckField>['options']
  >([]);

  const fetchProfesstions = async () => {
    const response = await getProfessions();
    const professions: typeof options = response.data.professions.map(
      (profession) => ({label: profession.title, value: profession.title}),
    );
    setOptions(professions);
  };

  useEffect(() => {
    fetchProfesstions();
  }, []);

  if (options.length === 0) {
    return <CircularProgress />;
  }

  return (
    <div>
      {!rest.isSingle ? (
        <CheckField
          title={title}
          onChangeValue={onChangeValue}
          value={rest.value}
          options={options}
        />
      ) : (
        <RadioField
          title={title}
          onChangeValue={onChangeValue}
          value={rest.value}
          options={options}
        />
      )}
    </div>
  );
};

export default ProfessionField;
