import {
  FormConfigProps,
  FORM_INPUT_TYPE,
} from 'src/DesignSystem/Form/Form.types';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {BOOLEAN_OPTIONS} from 'src/constants/constants';
import {
  TaxFormFieldIds,
  DependentRelations,
  TaxFormType,
  QuarterlyPaymentType,
  IncomeEarnerType,
  TaxForm,
  InformationSource,
  ProfitLossIncomeCategory,
  ProfitLostCogsCategory,
  VehicleExpenseTypes,
  ProfitLossScorpExpenseCategory,
  ProfitLossPartnershipExpenseCategory,
  ProfitLossCcorpExpenseCatgory,
  ProfitLosssTypeOfExpense,
} from './taxForms.types';
import {TRANSFORMERS} from 'src/DesignSystem/Form/Forms.transformers';
import {cloneDeep, isNumber} from 'lodash';
import {getPath} from 'src/common/utils';
import {BusinessType} from '../businessDetails/businessDetails.types';
import {IRSCategory} from '../transactions/transactions.types';

export const BusinessTypeWiseExpenseCategory = {
  [BusinessType.S_CORP]: Object.values(ProfitLossScorpExpenseCategory),
  [BusinessType.PARTNERSHIP]: Object.values(
    ProfitLossPartnershipExpenseCategory,
  ),
  [BusinessType.C_CORP]: Object.values(ProfitLossCcorpExpenseCatgory),
  [BusinessType.SOLE_PROPRIETORSHIP]: Object.values(IRSCategory),
};

const EditDepedendentTaxFormConfig: FormConfigProps[] = [
  {
    title: 'First name & middle initial',
    path: TaxFormFieldIds.DEPENDENT_FIRST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Last name',
    path: TaxFormFieldIds.DEPENDENT_LAST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Relationship to you',
    path: TaxFormFieldIds.DEPENDENT_RELATION,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(DependentRelations).map((val) => ({
      label: val,
      value: val,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Date of birth',
    path: TaxFormFieldIds.DEPENDENT_BIRTHDATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'SSN or ITIN or ATIN',
    path: TaxFormFieldIds.DEPENDENT_SSN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [
      VALIDATOR.NON_NULL_VALUE,
      VALIDATOR.NON_EMPTY_STRING,
      VALIDATOR.SSN_ITIN_ATIN,
    ],
    transformers: TRANSFORMERS.SSN,
  },
  {
    title: 'Are they either a US resident or US citizen?',
    path: TaxFormFieldIds.DEPENDENT_US_CITIZEN,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for dependent credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_DEPENDENT_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for education credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_EDUCATION_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Eligible for adoption credits?',
    path: TaxFormFieldIds.IS_ELIGIBLE_FOR_ADOPTION_CREDIT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
];

const EditPaymentsTaxFormConfig: FormConfigProps[] = [
  {
    title: 'Payment type',
    path: TaxFormFieldIds.PAYMENT_TYPE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(QuarterlyPaymentType).map((k) => ({
      label: k,
      value: k,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Amount paid',
    path: TaxFormFieldIds.AMOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Date of payment',
    path: TaxFormFieldIds.PAYMENT_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
];

const EditStateLivedTaxFormConfig: FormConfigProps[] = [
  {
    title: 'State',
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
    path: TaxFormFieldIds.STATE,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Who lived in this state',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: TaxFormFieldIds.INCOME_EARNER,
    options: Object.values(IncomeEarnerType)
      .filter((v) => v !== IncomeEarnerType.BUSINESS)
      .map((v) => ({
        label: v,
        value: v,
      })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'House number and street',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.NUMBER_AND_STREET,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Apartment/Unit number (if applicable)',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.APARTMENT_NUMBER,
  },
  {
    title: 'City',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.CITY,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Zip code',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.ZIP_CODE,
    validators: [VALIDATOR.VALIDATE_ZIP_CODE],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    title: 'County',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: TaxFormFieldIds.COUNTY,
    validators: [VALIDATOR.NON_NULL_VALUE],
    options: [],
  },
  {
    title: 'School district code',
    inputType: FORM_INPUT_TYPE.String,
    path: TaxFormFieldIds.SCHOOL_DISTRICT_CODE,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Period of stay',
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Period',
    childProps: [
      {
        title: 'From',
        path: TaxFormFieldIds.FROM,
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'To',
        path: TaxFormFieldIds.TO,
        inputType: FORM_INPUT_TYPE.Date,
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
    ],
    path: TaxFormFieldIds.PERIODS,
  },
];

const EditDLTaxFormConfig: FormConfigProps[] = [
  {
    title: 'Who does this is belong to?',
    path: TaxFormFieldIds.IS_SPOUSE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [
      {label: 'Self', value: false},
      {label: 'Spouse', value: true},
    ],
  },
  {
    title: 'Driving license number',
    path: TaxFormFieldIds.DL_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Expiry date',
    path: TaxFormFieldIds.EXPIRY_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Issue date',
    path: TaxFormFieldIds.ISSUE_DATE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Issuing state.',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    useAbbrevation: true,
    isSingle: true,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Doc Number',
    path: TaxFormFieldIds.DOC_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
];

const EditW2TaxFormConfig: FormConfigProps[] = [
  {
    title: 'Income earner',
    path: TaxFormFieldIds.INCOME_EARNER,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(IncomeEarnerType).map((earner) => ({
      label: earner,
      value: earner,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business Id',
    path: TaxFormFieldIds.BUSINESS_ID,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Employer’s name',
    path: TaxFormFieldIds.EMPLOYER_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Employer’s City',
    path: TaxFormFieldIds.EMPLOYER_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Wages, tips, other compensation',
    path: TaxFormFieldIds.WAGES_TIPS_ETC,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Employee’s social security number',
    path: TaxFormFieldIds.EMPLOYEE_SSN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Employer identification number (EIN)',
    path: TaxFormFieldIds.EMPLOYER_EIN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
    transformers: TRANSFORMERS.EIN,
  },
  {
    title: 'Employer’s address',
    path: TaxFormFieldIds.EMPLOYER_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Employer’s State',
    path: TaxFormFieldIds.EMPLOYER_STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: 'Employer’s ZIP',
    path: TaxFormFieldIds.EMPLOYER_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    title: 'Control number',
    path: TaxFormFieldIds.CONTROL_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Employee's first name & middle initial",
    path: TaxFormFieldIds.EMPLOYEE_FIRST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Employee's last name",
    path: TaxFormFieldIds.EMPLOYEE_LAST_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Employee's address",
    path: TaxFormFieldIds.EMPLOYEE_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Employee's City",
    path: TaxFormFieldIds.EMPLOYEE_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Employee's State",
    path: TaxFormFieldIds.EMPLOYEE_STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: "Employee's ZIP",
    path: TaxFormFieldIds.EMPLOYEE_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    title: 'Federal income tax withheld',
    path: TaxFormFieldIds.FEDERAL_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Social security wages',
    path: TaxFormFieldIds.SS_WAGES,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Social security tax withheld',
    path: TaxFormFieldIds.SS_TAX_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Medicare wages and tips',
    path: TaxFormFieldIds.MEDICARE_WAGES_N_TIPS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Medicare tax withheld',
    path: TaxFormFieldIds.MEDICARE_TAX_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Social security tips',
    path: TaxFormFieldIds.SS_TIPS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Allocated tips',
    path: TaxFormFieldIds.ALLOCATED_TIPS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Dependent care benefits',
    path: TaxFormFieldIds.DEPENDENT_CARE_BENEFITS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Nonqualified plans',
    path: TaxFormFieldIds.NON_QUALIFIED_PLANS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Statutory employee',
    path: TaxFormFieldIds.STATUTORY_EMPLOYEE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'Retirement plan',
    path: TaxFormFieldIds.RETIREMENT_PLAN,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'Third-party sick pay',
    path: TaxFormFieldIds.THIRD_PARTY_SICK_PAY,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: 'Employer’s state ID number',
    path: TaxFormFieldIds.EMPLOYER_STATE_ID_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'State wages, tips, etc.',
    path: TaxFormFieldIds.STATE_WAGES_TIPS_ETC,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'State income tax',
    path: TaxFormFieldIds.STATE_INCOME_TAX,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Local wages, tips, etc.',
    path: TaxFormFieldIds.LOCAL_WAGES_TIPS_ETC,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Local income tax',
    path: TaxFormFieldIds.LOCAL_INCOME_TAX,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Locality name',
    path: TaxFormFieldIds.LOCALITY_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Other',
    path: TaxFormFieldIds.OTHER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Extracted Form year',
    path: TaxFormFieldIds.EXTRACTED_FORM_YEAR,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
];

const EditManualFreelanceIncomeTaxForm: FormConfigProps[] = [
  {
    title: 'Income earner',
    path: TaxFormFieldIds.INCOME_EARNER,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(IncomeEarnerType).map((earner) => ({
      label: earner,
      value: earner,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business Id',
    path: TaxFormFieldIds.BUSINESS_ID,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: "Payer's name",
    path: TaxFormFieldIds.PAYER_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Amount',
    path: TaxFormFieldIds.AMOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
];

const EditForm1099IntConfig: FormConfigProps[] = [
  {
    title: 'Income earner',
    path: TaxFormFieldIds.INCOME_EARNER,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(IncomeEarnerType).map((earner) => ({
      label: earner,
      value: earner,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business Id',
    path: TaxFormFieldIds.BUSINESS_ID,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: "Payer's name",
    path: TaxFormFieldIds.PAYER_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Interest income',
    path: TaxFormFieldIds.INTEREST_INCOME,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Interest on U.S. savings bonds and Treasury obligations',
    path: TaxFormFieldIds.US_BOND_INTEREST,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: "Payer's street address",
    path: TaxFormFieldIds.PAYER_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's city/town",
    path: TaxFormFieldIds.PAYER_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's state",
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: "Payer's ZIP code",
    path: TaxFormFieldIds.PAYER_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's TIN",
    path: TaxFormFieldIds.PAYER_TIN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's TIN",
    path: TaxFormFieldIds.RECIPIENT_TIN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's name",
    path: TaxFormFieldIds.RECIPIENT_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's Street address (including apt. no)",
    path: TaxFormFieldIds.RECIPIENT_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's City or town",
    path: TaxFormFieldIds.RECIPIENT_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's State",
    path: TaxFormFieldIds.RECIPIENT_STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: "Recipient's ZIP or Postal Code",
    path: TaxFormFieldIds.RECIPIENT_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'FATCA filing requirement',
    path: TaxFormFieldIds.FATCA_FILING_REQUIREMENT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'Account number (see instructions)',
    path: TaxFormFieldIds.ACCOUNT_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: '2nd TIN not',
    path: TaxFormFieldIds.SECOND_TIN_NOT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'Early withdrawal penalty',
    path: TaxFormFieldIds.EARLY_WITHDRAWAL_PENALTY,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Federal income tax withheld',
    path: TaxFormFieldIds.FEDERAL_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Investment expenses',
    path: TaxFormFieldIds.INVESTMENT_EXPENSES,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Foreign tax paid',
    path: TaxFormFieldIds.PAID_FOREIGN_TAX,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Foreign country or U.S. possession',
    path: TaxFormFieldIds.POSSESSION,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Tax-exempt interest',
    path: TaxFormFieldIds.TAX_EXEMPT_INTEREST,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Specified private activity bond interest',
    path: TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Market discount',
    path: TaxFormFieldIds.MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Bond premium',
    path: TaxFormFieldIds.BOND_PREMIUM,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Bond premium on Treasury obligation',
    path: TaxFormFieldIds.TREASURY_OBLIGATION_BOND_PREMIUM,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Bond premium on tax-exempt bond',
    path: TaxFormFieldIds.TAX_EXEMPT_BOND_PREMIUM,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Tax-exempt and tax credit bond CUSIP no',
    path: TaxFormFieldIds.CUSIP_NO,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: 'State identification no.',
    path: TaxFormFieldIds.STATE_ID_NO,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'State tax withheld',
    path: TaxFormFieldIds.STATE_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Extracted form year',
    path: TaxFormFieldIds.EXTRACTED_FORM_YEAR,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
];

const EditForm1099DivConfig: FormConfigProps[] = [
  {
    title: 'Income earner',
    path: TaxFormFieldIds.INCOME_EARNER,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(IncomeEarnerType).map((earner) => ({
      label: earner,
      value: earner,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business Id',
    path: TaxFormFieldIds.BUSINESS_ID,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: "Payer's name",
    path: TaxFormFieldIds.PAYER_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Total ordinary dividends',
    path: TaxFormFieldIds.TOTAL_ORDINARY_DIVIDENDS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Total capital gain distr.',
    path: TaxFormFieldIds.TOTAL_CAPITAL_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Unrecap. Sec. 1250 gain',
    path: TaxFormFieldIds.SEC_1250_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Section 1202 gain',
    path: TaxFormFieldIds.SECTION_1202_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Collectibles (28%) gain',
    path: TaxFormFieldIds.COLLECTIBLES_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Section 897 ordinary dividends',
    path: TaxFormFieldIds.SECTION_897_DIVIDENDS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Section 897 capital gain',
    path: TaxFormFieldIds.SECTION_897_CAPITAL_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Nondividend distributions',
    path: TaxFormFieldIds.NON_DIVIDEND_DISTRIBUTIONS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Section 199A dividends',
    path: TaxFormFieldIds.SECTION_199A_DIVIDENDS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Cash liquidation distributions',
    path: TaxFormFieldIds.CASH_LIQUIDATION_DISTRIBUTIONS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Noncash liquidation distributions',
    path: TaxFormFieldIds.NON_CASH_LIQUIDATION_DISTRIBUTIONS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Exempt-interest dividends',
    path: TaxFormFieldIds.EXEMPT_INTEREST_DIVIDENDS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Specified private activity bond interest dividends',
    path: TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: "Payer's street address",
    path: TaxFormFieldIds.PAYER_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's city/town",
    path: TaxFormFieldIds.PAYER_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's state",
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: "Payer's ZIP code",
    path: TaxFormFieldIds.PAYER_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Payer's TIN",
    path: TaxFormFieldIds.PAYER_TIN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's TIN",
    path: TaxFormFieldIds.RECIPIENT_TIN,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's name",
    path: TaxFormFieldIds.RECIPIENT_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's Street address (including apt. no)",
    path: TaxFormFieldIds.RECIPIENT_ADDRESS,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's City",
    path: TaxFormFieldIds.RECIPIENT_CITY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: "Recipient's State",
    path: TaxFormFieldIds.RECIPIENT_STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: "Recipient's ZIP or Postal Code",
    path: TaxFormFieldIds.RECIPIENT_ZIP_CODE,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Qualified dividends',
    path: TaxFormFieldIds.QUALIFIED_DIVIDENDS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Federal income tax withheld',
    path: TaxFormFieldIds.FEDERAL_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Investment expenses',
    path: TaxFormFieldIds.INVESTMENT_EXPENSES,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Foreign tax paid',
    path: TaxFormFieldIds.PAID_FOREIGN_TAX,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Foreign country or U.S. possession',
    path: TaxFormFieldIds.POSSESSION,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'FATCA filing requirement',
    path: TaxFormFieldIds.FATCA_FILING_REQUIREMENT,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: BOOLEAN_OPTIONS,
    validators: [],
  },
  {
    title: 'State',
    path: TaxFormFieldIds.STATE,
    inputType: FORM_INPUT_TYPE.State,
    isSingle: true,
    useAbbrevation: true,
  },
  {
    title: 'State identification no.',
    path: TaxFormFieldIds.STATE_ID_NO,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'State tax withheld',
    path: TaxFormFieldIds.STATE_WITHHELD,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
  {
    title: 'Account number',
    path: TaxFormFieldIds.ACCOUNT_NUMBER,
    inputType: FORM_INPUT_TYPE.String,
    validators: [],
  },
  {
    title: 'Extracted form year',
    path: TaxFormFieldIds.EXTRACTED_FORM_YEAR,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [],
  },
];

const EditForm1099BConfig: FormConfigProps[] = [
  {
    title: 'Income earner',
    path: TaxFormFieldIds.INCOME_EARNER,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(IncomeEarnerType).map((earner) => ({
      label: earner,
      value: earner,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business Id',
    path: TaxFormFieldIds.BUSINESS_ID,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Short term net gain',
    path: TaxFormFieldIds.SHORT_TERM_NET_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Short term net loss',
    path: TaxFormFieldIds.SHORT_TERM_NET_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Undetermined net gain',
    path: TaxFormFieldIds.UNDETERMINED_NET_GAIN,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Undetermined net loss',
    path: TaxFormFieldIds.UNDETERMINED_NET_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'short term reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_REPORTED_TO_IRS_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_REPORTED_TO_IRS_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_REPORTED_TO_IRS_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_REPORTED_TO_IRS_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_REPORTED_TO_IRS_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_NOT_REPORTED_TO_IRS_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_NOT_REPORTED_TO_IRS_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_NOT_REPORTED_TO_IRS_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_NOT_REPORTED_TO_IRS_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not reported to irs',
    path: TaxFormFieldIds.SHORT_TERM_NOT_REPORTED_TO_IRS_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not received',
    path: TaxFormFieldIds.SHORT_TERM_NOT_RECEIVED_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not received',
    path: TaxFormFieldIds.SHORT_TERM_NOT_RECEIVED_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not received',
    path: TaxFormFieldIds.SHORT_TERM_NOT_RECEIVED_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not received',
    path: TaxFormFieldIds.SHORT_TERM_NOT_RECEIVED_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'short term not received',
    path: TaxFormFieldIds.SHORT_TERM_NOT_RECEIVED_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'long term reported to irs',
    path: TaxFormFieldIds.LONG_TERM_REPORTED_TO_IRS_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'long term reported to irs',
    path: TaxFormFieldIds.LONG_TERM_REPORTED_TO_IRS_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'long term reported to irs',
    path: TaxFormFieldIds.LONG_TERM_REPORTED_TO_IRS_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'long term reported to irs',
    path: TaxFormFieldIds.LONG_TERM_REPORTED_TO_IRS_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'long term reported to irs',
    path: TaxFormFieldIds.LONG_TERM_REPORTED_TO_IRS_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not reported to irs',
    path: TaxFormFieldIds.UNDETERMINED_NOT_REPORTED_TO_IRS_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not reported to irs',
    path: TaxFormFieldIds.UNDETERMINED_NOT_REPORTED_TO_IRS_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not reported to irs',
    path: TaxFormFieldIds.UNDETERMINED_NOT_REPORTED_TO_IRS_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not reported to irs',
    path: TaxFormFieldIds.UNDETERMINED_NOT_REPORTED_TO_IRS_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not reported to irs',
    path: TaxFormFieldIds.UNDETERMINED_NOT_REPORTED_TO_IRS_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not received',
    path: TaxFormFieldIds.UNDETERMINED_NOT_RECEIVED_PROCEEDS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not received',
    path: TaxFormFieldIds.UNDETERMINED_NOT_RECEIVED_COST_BASIS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not received',
    path: TaxFormFieldIds.UNDETERMINED_NOT_RECEIVED_MARKET_DISCOUNT,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not received',
    path: TaxFormFieldIds.UNDETERMINED_NOT_RECEIVED_WASH_SALE_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: 'undetermined not received',
    path: TaxFormFieldIds.UNDETERMINED_NOT_RECEIVED_NET_GAIN_LOSS,
    inputType: FORM_INPUT_TYPE.Number,
  },
  {
    title: "Recipient's TIN",
    path: TaxFormFieldIds.RECIPIENT_TIN,
    inputType: FORM_INPUT_TYPE.String,
  },
  {
    title: 'Extracted form year',
    path: TaxFormFieldIds.EXTRACTED_FORM_YEAR,
    inputType: FORM_INPUT_TYPE.Number,
  },
];

const ProfitLossCommonHeaderFields: FormConfigProps[] = [
  {
    title: 'Name',
    path: TaxFormFieldIds.KEY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Amount',
    path: TaxFormFieldIds.AMOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Source',
    path: TaxFormFieldIds.SOURCE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(InformationSource).map((i) => ({
      label: i,
      value: i,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    path: TaxFormFieldIds.IS_APPLICABLE,
    inputType: FORM_INPUT_TYPE.Boolean,
  },
];

export const EditProfitAndLossForm: FormConfigProps[] = [
  {
    title: 'Income',
    path: getPath(TaxFormFieldIds.INCOME, TaxFormFieldIds.SUB_CATEGORY),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Income',
    childProps: [
      ...ProfitLossCommonHeaderFields,
      {
        title: 'Category',
        path: TaxFormFieldIds.CATEGORY,
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: Object.values(ProfitLossIncomeCategory).map((i) => ({
          label: i,
          value: i,
        })),
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
    ],
  },
  {
    title: 'Cost of Goods Sold',
    path: getPath(
      TaxFormFieldIds.COST_OF_GOODS_SOLD,
      TaxFormFieldIds.SUB_CATEGORY,
    ),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'COGS',
    childProps: [
      ...ProfitLossCommonHeaderFields,
      {
        title: 'Category',
        path: TaxFormFieldIds.CATEGORY,
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: Object.values(ProfitLostCogsCategory).map((i) => ({
          label: i,
          value: i,
        })),
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
    ],
  },
  {
    title: 'Other Income',
    path: getPath(TaxFormFieldIds.OTHER_INCOME, TaxFormFieldIds.SUB_CATEGORY),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Other income',
    childProps: ProfitLossCommonHeaderFields,
  },
  {
    title: 'Expenses',
    path: getPath(TaxFormFieldIds.EXPENSES, TaxFormFieldIds.SUB_CATEGORY),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Expense',
    childProps: [
      ...ProfitLossCommonHeaderFields,
      {
        title: 'Category',
        path: TaxFormFieldIds.CATEGORY,
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: [],
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'L2',
        path: TaxFormFieldIds.L2,
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: [],
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
      {
        title: 'Type of expense',
        path: TaxFormFieldIds.TYPE_OF_EXPENSE,
        inputType: FORM_INPUT_TYPE.SingleSelect,
        options: Object.values(ProfitLosssTypeOfExpense).map((k) => ({
          label: k,
          value: k,
        })),
        validators: [VALIDATOR.NON_NULL_VALUE],
      },
    ],
  },
];

export const EditFormBusinessVehicle: FormConfigProps[] = [
  {
    title: 'Expense type of a vehicle',
    path: TaxFormFieldIds.VEHICLE_EXPENSE_TYPE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(VehicleExpenseTypes).map((cat) => ({
      label: cat,
      value: cat,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Vehicle Model Name',
    path: TaxFormFieldIds.ITEM_NAME,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Total miles driven',
    path: TaxFormFieldIds.TOTAL_MILES,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business miles driven',
    path: TaxFormFieldIds.BUSINESS_MILES,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Vehicle purchase date',
    path: TaxFormFieldIds.DATE_OF_PURCHASE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Business use start date',
    path: TaxFormFieldIds.DATE_PUT_IN_SERVICE,
    inputType: FORM_INPUT_TYPE.Date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Total vehicle cost',
    path: TaxFormFieldIds.ITEM_VALUE,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Total loan interest paid',
    path: TaxFormFieldIds.INTEREST_PAID,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Vehicle model year',
    path: TaxFormFieldIds.MAKE_YEAR,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
];

const BalanceSheetLineItemConfig: FormConfigProps[] = [
  {
    title: 'Name',
    path: TaxFormFieldIds.KEY,
    inputType: FORM_INPUT_TYPE.String,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Amount',
    path: TaxFormFieldIds.AMOUNT,
    inputType: FORM_INPUT_TYPE.Number,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Source',
    path: TaxFormFieldIds.SOURCE,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: Object.values(InformationSource).map((i) => ({
      label: i,
      value: i,
    })),
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Category',
    path: TaxFormFieldIds.CATEGORY,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'L2',
    path: TaxFormFieldIds.L2,
    inputType: FORM_INPUT_TYPE.SingleSelect,
    options: [],
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    path: TaxFormFieldIds.IS_APPLICABLE,
    inputType: FORM_INPUT_TYPE.Boolean,
  },
];

export const EditBalanceSheetConfig: FormConfigProps[] = [
  {
    title: 'Assets',
    path: getPath(TaxFormFieldIds.ASSETS, TaxFormFieldIds.LINE_ITEMS),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Asset',
    childProps: cloneDeep(BalanceSheetLineItemConfig),
  },
  {
    title: 'Liabilities',
    path: getPath(TaxFormFieldIds.LIABILITIES, TaxFormFieldIds.LINE_ITEMS),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Liability',
    childProps: cloneDeep(BalanceSheetLineItemConfig),
  },
  {
    title: 'Equities',
    path: getPath(TaxFormFieldIds.EQUITY, TaxFormFieldIds.LINE_ITEMS),
    inputType: FORM_INPUT_TYPE.Array,
    childTitle: 'Equity',
    childProps: cloneDeep(BalanceSheetLineItemConfig),
  },
];

export enum BalanceSheetLineItemCategories {
  CurrentAssets = 'Current assets',
  OtherCurrentAssets = 'Other current assets',
  Investments = 'Investments',
  OtherAssets = 'Other Assets',
  CurrentLiabilities = 'Current liabilities',
  OtherLiabilities = 'Other liabilities',
  Equity = 'Equity',
}

enum BalanceSheetLineItemsSubcategories {
  Cash = 'Cash',
  TradeNotes = 'Trade notes and accounts receivable',
  LessAllowanceForBadDebts = 'Less allowance for bad debts',
  Inventories = 'Inventories',
  USGovernmentObligations = 'U.S government obligations',
  TaxExemptSecurities = 'Tax-exempt securities',

  OtherCurrentAssets = 'Other current assets',
  OtherCurrentLiabilities = 'Other current liabilities',

  Other = 'Other',

  LoanShaareholders = 'Loan to shaareholders',
  MortgageAndRealEstateLoans = 'Mortgage and real estate loans',

  Buildings = 'Buildings and other depreciable assets',
  BuildingsLessAccumulatedDepreciation = 'Buildings and other depreciable assets -  Less accumulated depreciation',
  DepleableAssets = 'Depleable assets',
  DepleableAssetsLessAccumulatedDepreciation = 'Depleable assets -  Less accumulated depreciation',
  Land = 'Land',
  IntangibleAssets = 'Intangible assets',
  IntangibleAssetsLessAccumulatedDepreciation = 'Intangible assets -  Less accumulated depreciation',

  AccountsPayable = 'Accounts payable',
  MortgageNotes = 'Mortgage, notes, bonds payable in less than 1 year',

  // AccuredFederal = 'Accured - federal income tax',
  // AccuredState = 'Accured - state income tax',
  // AccuredCity = 'Accured - city income tax',

  LoansFromShareholdersOverride = 'Loans from shareholders - override',
  MortgageNotesMore1Year = 'Mortgage, notes, bonds payable in 1 year or more',

  CapitalStock = 'Capital stock',
  AdditionalPaidInCapital = 'Additional paid-in capital',
  RetainedEarningsAppropriated = 'Retained earnings - appropriated',
  RetainedEarningsUnappropriatedEndOfTheYearEntryIsAnOverride = 'Retained earnings - unappropriated - end of the year entry is an override',
  TotalRetainedEarningsEndOfYearOverrideSCC = 'Total retained earnings - end of year - overide - SCC',
  AdjustmentsToShareholdersEquity = "Adjustments to shareholder's equity",
  LessCostOfTreasuryStock = 'Less cost of treasury stock',

  LoanToPartners = 'Loan to partners',

  LoanFromPartners = 'Loans from partners',

  PartnerCapital = 'Partner Capital',
}

const CurrentAssetsSubCategories = [
  BalanceSheetLineItemsSubcategories.Cash,
  BalanceSheetLineItemsSubcategories.TradeNotes,
  BalanceSheetLineItemsSubcategories.LessAllowanceForBadDebts,
  BalanceSheetLineItemsSubcategories.Inventories,
  BalanceSheetLineItemsSubcategories.USGovernmentObligations,
  BalanceSheetLineItemsSubcategories.TaxExemptSecurities,
];

const OtherCurrentAssetsSubCategories =
  [] as BalanceSheetLineItemsSubcategories[];

const InvestmentsSubCategories = [
  BalanceSheetLineItemsSubcategories.LoanShaareholders,
  BalanceSheetLineItemsSubcategories.MortgageAndRealEstateLoans,
  BalanceSheetLineItemsSubcategories.Other,
];

const OtherAssetsSubCategories = [
  BalanceSheetLineItemsSubcategories.Buildings,
  BalanceSheetLineItemsSubcategories.BuildingsLessAccumulatedDepreciation,
  BalanceSheetLineItemsSubcategories.DepleableAssets,
  BalanceSheetLineItemsSubcategories.DepleableAssetsLessAccumulatedDepreciation,
  BalanceSheetLineItemsSubcategories.Land,
  BalanceSheetLineItemsSubcategories.IntangibleAssets,
  BalanceSheetLineItemsSubcategories.IntangibleAssetsLessAccumulatedDepreciation,
  BalanceSheetLineItemsSubcategories.Other,
];

const CurrentLiabilitiesSubCategories = [
  BalanceSheetLineItemsSubcategories.AccountsPayable,
  BalanceSheetLineItemsSubcategories.MortgageNotes,
  BalanceSheetLineItemsSubcategories.Other,
];

const OtherLiabilitiesSubCategories = [
  BalanceSheetLineItemsSubcategories.LoansFromShareholdersOverride,
  BalanceSheetLineItemsSubcategories.MortgageNotesMore1Year,
  BalanceSheetLineItemsSubcategories.Other,
];

const EquitySubCategories = [
  BalanceSheetLineItemsSubcategories.CapitalStock,
  BalanceSheetLineItemsSubcategories.AdditionalPaidInCapital,
  BalanceSheetLineItemsSubcategories.RetainedEarningsAppropriated,
  BalanceSheetLineItemsSubcategories.RetainedEarningsUnappropriatedEndOfTheYearEntryIsAnOverride,
  BalanceSheetLineItemsSubcategories.TotalRetainedEarningsEndOfYearOverrideSCC,
  BalanceSheetLineItemsSubcategories.AdjustmentsToShareholdersEquity,
  BalanceSheetLineItemsSubcategories.LessCostOfTreasuryStock,
];

const SCorpAndCCorpBalanceSheetHeaderToCategoryToSub = {
  [TaxFormFieldIds.ASSETS]: {
    [BalanceSheetLineItemCategories.CurrentAssets]: CurrentAssetsSubCategories,
    [BalanceSheetLineItemCategories.OtherCurrentAssets]:
      OtherCurrentAssetsSubCategories,
    [BalanceSheetLineItemCategories.Investments]: InvestmentsSubCategories,
    [BalanceSheetLineItemCategories.OtherAssets]: OtherAssetsSubCategories,
  },
  [TaxFormFieldIds.LIABILITIES]: {
    [BalanceSheetLineItemCategories.CurrentLiabilities]:
      CurrentLiabilitiesSubCategories,
    [BalanceSheetLineItemCategories.OtherLiabilities]:
      OtherLiabilitiesSubCategories,
  },
  [TaxFormFieldIds.EQUITY]: {
    [BalanceSheetLineItemCategories.Equity]: EquitySubCategories,
  },
};

export const BusinessEntityToCategoryToL2Map = {
  [BusinessType.S_CORP]: SCorpAndCCorpBalanceSheetHeaderToCategoryToSub,
  [BusinessType.C_CORP]: SCorpAndCCorpBalanceSheetHeaderToCategoryToSub,
  [BusinessType.PARTNERSHIP]: {
    [TaxFormFieldIds.ASSETS]: {
      [BalanceSheetLineItemCategories.CurrentAssets]:
        CurrentAssetsSubCategories,
      [BalanceSheetLineItemCategories.OtherCurrentAssets]:
        OtherCurrentAssetsSubCategories,
      [BalanceSheetLineItemCategories.Investments]: [
        BalanceSheetLineItemsSubcategories.LoanToPartners,
        BalanceSheetLineItemsSubcategories.MortgageAndRealEstateLoans,
        BalanceSheetLineItemsSubcategories.Other,
      ],
      [BalanceSheetLineItemCategories.OtherAssets]: OtherAssetsSubCategories,
    },
    [TaxFormFieldIds.LIABILITIES]: {
      [BalanceSheetLineItemCategories.CurrentLiabilities]:
        CurrentLiabilitiesSubCategories,
      [BalanceSheetLineItemCategories.OtherLiabilities]: [
        BalanceSheetLineItemsSubcategories.LoanFromPartners,
        BalanceSheetLineItemsSubcategories.MortgageNotesMore1Year,
        BalanceSheetLineItemsSubcategories.Other,
      ],
    },
    [TaxFormFieldIds.EQUITY]: {
      [BalanceSheetLineItemCategories.Equity]: [
        BalanceSheetLineItemsSubcategories.PartnerCapital,
      ],
    },
  },
};

export const EditTaxFormConfigMap: {
  [k in TaxFormType]?: FormConfigProps[];
} = {
  [TaxFormType.DEPENDENT]: EditDepedendentTaxFormConfig,
  [TaxFormType.QUARTERLY_TAX_PAYMENTS]: EditPaymentsTaxFormConfig,
  [TaxFormType.STATES_LIVED]: EditStateLivedTaxFormConfig,
  [TaxFormType.DRIVING_LICENSE]: EditDLTaxFormConfig,
  [TaxFormType.W2_FORM]: EditW2TaxFormConfig,
  [TaxFormType.MANUAL_FREELANCE_INCOME_FORM]: EditManualFreelanceIncomeTaxForm,
  [TaxFormType.FORM_1099_INT]: EditForm1099IntConfig,
  [TaxFormType.FORM_1099_DIV]: EditForm1099DivConfig,
  [TaxFormType.FORM_1099_B]: EditForm1099BConfig,
  [TaxFormType.PROFIT_LOSS_FORM]: EditProfitAndLossForm,
  [TaxFormType.BUSINESS_VEHICLE]: EditFormBusinessVehicle,
  [TaxFormType.BALANCE_SHEET_FORM]: EditBalanceSheetConfig,
};

export const IncomeTaxFormConfig = {
  [TaxFormType.W2_FORM]: {
    incomeName: 'W2',
    getAmount: (form: TaxForm<TaxFormType.W2_FORM>) => {
      return form.formData[TaxFormFieldIds.WAGES_TIPS_ETC];
    },
    incomeSourceKey: TaxFormFieldIds.EMPLOYER_NAME,
  },
  [TaxFormType.MANUAL_FREELANCE_INCOME_FORM]: {
    incomeName: 'Freelance Income',
    getAmount: (form: TaxForm<TaxFormType.MANUAL_FREELANCE_INCOME_FORM>) => {
      return form.formData[TaxFormFieldIds.AMOUNT];
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_NEC]: {
    incomeName: '1099 NEC',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_NEC>) => {
      return form.formData[TaxFormFieldIds.NON_EMPLOYEE_COMPENSATION];
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_MISC]: {
    incomeName: '1099 Misc',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_MISC>) => {
      let amount = 0;
      const rent = form.formData[TaxFormFieldIds.RENTS];
      const royality = form.formData[TaxFormFieldIds.ROYALTIES];
      const oi = form.formData[TaxFormFieldIds.OTHER_INCOME];
      if (isNumber(rent)) {
        amount += rent;
      }
      if (isNumber(royality)) {
        amount += royality;
      }
      if (isNumber(oi)) {
        amount += oi;
      }
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_K]: {
    incomeName: '1099 K',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_K>) => {
      return form.formData[TaxFormFieldIds.GROSS_AMOUNT_PAYMENT_CARD];
    },
    incomeSourceKey: TaxFormFieldIds.PSE_NAME,
  },
  [TaxFormType.FORM_1099_INT]: {
    incomeName: '1099 Int',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_INT>) => {
      let amount = 0;
      const interest = form.formData[TaxFormFieldIds.INTEREST_INCOME];
      const bond = form.formData[TaxFormFieldIds.US_BOND_INTEREST];
      if (isNumber(bond)) {
        amount += bond;
      }
      if (isNumber(interest)) {
        amount += interest;
      }
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_DIV]: {
    incomeName: '1099 DIV',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_DIV>) => {
      let amount = 0;
      const fields = [
        TaxFormFieldIds.TOTAL_ORDINARY_DIVIDENDS,
        TaxFormFieldIds.TOTAL_CAPITAL_GAIN,
        TaxFormFieldIds.SECTION_1202_GAIN,
        TaxFormFieldIds.SECTION_897_DIVIDENDS,
        TaxFormFieldIds.SEC_1250_GAIN,
        TaxFormFieldIds.COLLECTIBLES_GAIN,
        TaxFormFieldIds.SECTION_897_CAPITAL_GAIN,
        TaxFormFieldIds.NON_DIVIDEND_DISTRIBUTIONS,
        TaxFormFieldIds.SECTION_199A_DIVIDENDS,
        TaxFormFieldIds.CASH_LIQUIDATION_DISTRIBUTIONS,
        TaxFormFieldIds.NON_CASH_LIQUIDATION_DISTRIBUTIONS,
        TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST,
      ] as const;
      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_B]: {
    incomeName: '1099 B',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_B>) => {
      let amount = 0;
      const positive = [
        TaxFormFieldIds.SHORT_TERM_NET_GAIN,
        TaxFormFieldIds.LONG_TERM_NET_GAIN,
        TaxFormFieldIds.UNDETERMINED_NET_GAIN,
      ] as const;
      const negative = [
        TaxFormFieldIds.SHORT_TERM_NET_LOSS,
        TaxFormFieldIds.LONG_TERM_NET_LOSS,
        TaxFormFieldIds.UNDETERMINED_NET_LOSS,
      ] as const;
      positive.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      negative.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount -= form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.INCOME_EARNER,
  },
  [TaxFormType.W2_G_FORM]: {
    incomeName: 'W2 G',
    getAmount: (form: TaxForm<TaxFormType.W2_G_FORM>) => {
      let amount = 0;
      const fields = [
        TaxFormFieldIds.WINNING_REPORTABLE,
        TaxFormFieldIds.WINNINGS_FROM_IDENTICAL_WAGERS,
      ] as const;
      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_G]: {
    incomeName: '1099 G',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_G>) => {
      let amount = 0;
      const fields = [
        TaxFormFieldIds.UNEMPLOYMENT_COMPENSATION,
        TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET,
        TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET,
        TaxFormFieldIds.RTAA_PAYMENTS,
        TaxFormFieldIds.TAXABLE_GRANTS,
        TaxFormFieldIds.AGRICULTURE_PAYMENTS,
        TaxFormFieldIds.MARKET_GAIN,
      ] as const;
      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_1099_R]: {
    incomeName: '1099 R',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_R>) => {
      let amount = 0;
      const fields = [TaxFormFieldIds.GROSS_DISTRIBUTION] as const;
      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PAYER_NAME,
  },
  [TaxFormType.FORM_K1_1120S]: {
    incomeName: 'K1 1120S',
    getAmount: (form: TaxForm<TaxFormType.FORM_K1_1120S>) => {
      let amount = 0;
      const positive = [
        TaxFormFieldIds.ORDINARY_BUSINESS_INCOME,
        TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME,
        TaxFormFieldIds.INTEREST_INCOME,
        TaxFormFieldIds.QUALIFIED_DIVIDENDS,
        TaxFormFieldIds.ORDINARY_DIVIDENDS,
        TaxFormFieldIds.SHORT_TERM_NET_GAIN,
        TaxFormFieldIds.LONG_TERM_NET_GAIN,
      ] as const;
      const negative = [
        TaxFormFieldIds.ORDINARY_BUSINESS_LOSS,
        TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS,
        TaxFormFieldIds.SHORT_TERM_NET_LOSS,
        TaxFormFieldIds.LONG_TERM_NET_LOSS,
      ] as const;
      positive.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      negative.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount -= form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.CORPORATION_NAME,
  },
  [TaxFormType.RENTAL_INCOME]: {
    incomeName: 'Rental Income',
    getAmount: (form: TaxForm<TaxFormType.RENTAL_INCOME>) => {
      let amount = 0;
      const fields = [TaxFormFieldIds.INCOME_EARNED] as const;

      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.RENTAL_PROPERTY_NAME,
  },
  [TaxFormType.FORM_K1_1065]: {
    incomeName: 'K1 1065',
    getAmount: (form: TaxForm<TaxFormType.FORM_K1_1065>) => {
      let amount = 0;
      const positive = [
        TaxFormFieldIds.ORDINARY_BUSINESS_INCOME,
        TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME,
        TaxFormFieldIds.INTEREST_INCOME,
        TaxFormFieldIds.QUALIFIED_DIVIDENDS,
        TaxFormFieldIds.ORDINARY_DIVIDENDS,
        TaxFormFieldIds.SHORT_TERM_NET_GAIN,
        TaxFormFieldIds.LONG_TERM_NET_GAIN,
      ] as const;
      const negative = [
        TaxFormFieldIds.ORDINARY_BUSINESS_LOSS,
        TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS,
        TaxFormFieldIds.SHORT_TERM_NET_LOSS,
        TaxFormFieldIds.LONG_TERM_NET_LOSS,
      ] as const;
      positive.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      negative.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount -= form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.PARTNERSHIP_NAME,
  },
  [TaxFormType.FORM_1099_SA]: {
    incomeName: '1099 SA',
    getAmount: (form: TaxForm<TaxFormType.FORM_1099_SA>) => {
      let amount = 0;
      const fields = [TaxFormFieldIds.GROSS_DISTRIBUTION] as const;
      fields.forEach((field) => {
        if (isNumber(form.formData[field])) {
          amount += form.formData[field];
        }
      });
      return amount;
    },
    incomeSourceKey: TaxFormFieldIds.TRUSTEE_NAME,
  },
} as const;

export const ManuallyAddableIncomeTaxForms = [
  TaxFormType.W2_FORM,
  TaxFormType.MANUAL_FREELANCE_INCOME_FORM,
  TaxFormType.FORM_1099_INT,
  TaxFormType.FORM_1099_DIV,
  TaxFormType.FORM_1099_B,
] as const;

export const FormTypesRenderOcrDataFromTaxForm = [
  TaxFormType.PROFIT_LOSS_FORM,
  TaxFormType.BALANCE_SHEET_FORM,
];
