import React, {ComponentProps} from 'react';
import UploadDocument from './UploadDocument';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface UploadDocumentPopupProps
  extends ComponentProps<typeof UploadDocument> {
  isVisible: boolean;
  onClose: () => void;
}

const UploadDocumentPopup = ({
  isVisible,
  onClose,
  ...props
}: UploadDocumentPopupProps) => {
  return (
    <PopUp
      style={{width: 800, height: 500}}
      isOpen={isVisible}
      onClose={onClose}>
      <UploadDocument {...props} />
    </PopUp>
  );
};

export default UploadDocumentPopup;
