import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';
import TickIcon from 'src/icons/TickIcon';

enum TaxProfileStepStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

interface TaxProfileStepProps {
  stepName: string;
  onClick: () => void;
  status: TaxProfileStepStatus;
}

const TaxProfileStep = ({status, stepName, onClick}: TaxProfileStepProps) => {
  const getIcon = () => {
    switch (status) {
      case TaxProfileStepStatus.COMPLETED:
        return <TickIcon />;
      case TaxProfileStepStatus.IN_PROGRESS:
        return <StatusPendingIcon />;
      case TaxProfileStepStatus.NOT_STARTED:
        return <StatusPendingIcon color={themmeColor.silver} />;
    }
  };
  return (
    <Box
      style={{
        paddingInline: 20,
        paddingBlock: 14,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {getIcon()}
        <Typography style={{fontSize: 16, fontWeight: 600, marginLeft: 10}}>
          {stepName}
        </Typography>
      </div>
      <DSButton
        text={status === TaxProfileStepStatus.COMPLETED ? 'View' : 'Start'}
        type={
          status === TaxProfileStepStatus.COMPLETED ? 'secondary' : 'primary'
        }
        onClick={onClick}
        // disabled={status === TaxProfileStepStatus.NOT_STARTED}
      />
    </Box>
  );
};

export default Object.assign(TaxProfileStep, {Step: TaxProfileStepStatus});
