import {isObject} from 'lodash';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {DOCUMENT_TYPES} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {
  BusinessDetail,
  BusinessDetailsField,
} from 'src/store/businessDetails/businessDetails.types';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import BusinessIncomeTable from './BusinessIncomeTable';
import {
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import NetAmountRow from '../../common/NetAmountRow';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {filterProfitAndLossIncomeField} from 'src/store/taxForms/taxForms.utils';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';

interface SingleBusinessIncomeProps {
  business: BusinessDetail;
}

const SingleBusinessIncome = ({business}: SingleBusinessIncomeProps) => {
  const {documents} = useSelector(selectDocuments);
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {onAddTaxForm, onEditTaxForm} = usePartnerTaxProfileReview();
  const {navigateToDocumentReview} = usePartnershipNavigation();

  const profitLossDoc = useMemo(() => {
    return documents.filter(
      (doc) =>
        doc.docType === DOCUMENT_TYPES.PROFIT_LOSS_STATEMENT &&
        doc.linkedBusinessId === business[BusinessDetailsField.id],
    )?.[0];
  }, [documents, business]);

  const taxForm = useMemo(() => {
    return taxForms.filter(
      (tf) =>
        tf.linked_business_id === business[BusinessDetailsField.id] &&
        tf.formType === TaxFormType.PROFIT_LOSS_FORM,
    )?.[0] as undefined | TaxForm<TaxFormType.PROFIT_LOSS_FORM>;
  }, [taxForms, business]);

  const onEditProfitAndLossForm = () => {
    if (isObject(taxForm)) {
      onEditTaxForm({
        taxFormId: taxForm.taxFormId,
        taxFormType: taxForm.formType,
        title: 'Edit P&L',
        filterFields: filterProfitAndLossIncomeField,
      });
    } else {
      onAddTaxForm({
        taxFormType: TaxFormType.PROFIT_LOSS_FORM,
        title: 'Add P&L',
        multi_tax_forms_entity_id: business[BusinessDetailsField.id],
        filterFields: filterProfitAndLossIncomeField,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={`${business[BusinessDetailsField.name]} (${
            business[BusinessDetailsField.entity_type]
          })`}
        />
        <DSButton
          type="primary"
          text={isObject(taxForm) ? 'Edit' : 'Add'}
          onClick={onEditProfitAndLossForm}
        />
      </div>
      {isObject(profitLossDoc) ? (
        <>
          <Text
            type={TEXT_TYPES.BASE}
            text={'Related documents'}
            containerStyle={{marginTop: 12}}
          />
          <DocumentPointer
            doc={profitLossDoc}
            dontShowStatus
            border
            onDocumentClick={() =>
              navigateToDocumentReview(profitLossDoc.docId)
            }
          />
        </>
      ) : null}
      <BusinessIncomeTable
        headerName="Income"
        form={taxForm}
        header={TaxFormFieldIds.INCOME}
      />
      <BusinessIncomeTable
        headerName="Cost of Goods Sold"
        form={taxForm}
        header={TaxFormFieldIds.COST_OF_GOODS_SOLD}
      />
      <NetAmountRow
        text="Gross Profit"
        amount={
          taxForm?.formData[TaxFormFieldIds.GROSS_PROFIT]?.[
            TaxFormFieldIds.AMOUNT
          ] ?? 0
        }
      />
      <BusinessIncomeTable
        headerName="Other income"
        form={taxForm}
        header={TaxFormFieldIds.OTHER_INCOME}
      />
      <NetAmountRow
        text="Net other Income"
        amount={
          taxForm?.formData[TaxFormFieldIds.NET_OTHER_INCOME]?.[
            TaxFormFieldIds.AMOUNT
          ] ?? 0
        }
      />
    </div>
  );
};

export default SingleBusinessIncome;
