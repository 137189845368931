import {Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  CurrencyNumberFormat,
  getAge,
  getDateFromYYYYMMDD,
} from 'src/common/utils';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import Skeleton from 'src/icons/Skeleton';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {IRSCategory} from 'src/store/transactions/transactions.constants';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import SubSectionBox from '../../common/SubSectionBox';
import {selectDeductionSummary} from 'src/store/deductionSummary/deductionSummary.selector';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {TaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {selectTaxProfileUserSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const AdoptionCredits = () => {
  const {activeYear} = useActiveYear();
  const {taxForms, loaded: taxFormsLoaded} = useSelector(
    selectTaxFormsReducer,
  );
  const deductionSummary = useSelector(selectDeductionSummary);
  const {taxProfileUserSideStatus, loaded: taxProfileStatusLoaded} =
    useSelector(selectTaxProfileUserSideStatus);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const isLoading = !taxFormsLoaded || !taxProfileStatusLoaded;
  const showAdoptionCredits =
    taxProfileStatusLoaded &&
    ![
      TaxProfileUserSideStatus.NOT_APPLICABLE,
      TaxProfileUserSideStatus.NOT_INITIATED,
    ].includes(
      taxProfileUserSideStatus[TaxProfileSubsection.AdoptionCredits]?.status ??
        TaxProfileUserSideStatus.NOT_APPLICABLE,
    );

  const dependents = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.DEPENDENT,
  );

  const getAdoptedChildrenDisplayString = (dep: TaxForm) => {
    return `${dep.formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME]} ${
      getAge(
        getDateFromYYYYMMDD(dep.formData[TaxFormFieldIds.DEPENDENT_BIRTHDATE]),
      ) >= 18
        ? '(Above 18)'
        : ''
    }`;
  };

  const adoptedChildren = dependents
    .filter((dep) => dep.formData[TaxFormFieldIds.IS_ADOPTED])
    .map((dep) => getAdoptedChildrenDisplayString(dep));

  const incapableChildren = dependents
    .filter((dep) => dep.formData[TaxFormFieldIds.IS_INCAPABLE])
    .map((dep) => dep.formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME]);

  const adoptionExpenses = useMemo(() => {
    return deductionSummary.sch_3_category_wise_data
      .filter((deduction) => {
        return deduction.category_display_name === IRSCategory.AdoptionCredits;
      })
      .reduce((prev, deduction) => {
        return prev + (deduction?.amount ?? 0);
      }, 0);
  }, [deductionSummary]);

  return (
    <SubSectionBox
      name="Adoption credit"
      taxprofileSubsection={TaxProfileSubsection.AdoptionCredits}>
      {isLoading ? (
        <Skeleton width={'100%'} height={200} />
      ) : (
        showAdoptionCredits && (
          <>
            <div
              style={
                summaryVariant ===
                TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                  ? {}
                  : {
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'space-between',
                    }
              }>
              <div
                style={
                  summaryVariant ===
                  TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                    ? {}
                    : {flex: 0.48}
                }>
                <SimpleKeyValueField
                  name={`Select the child(ren) you adopted in year ${activeYear}?`}
                  value={adoptedChildren.join(', ')}
                />
                <SimpleKeyValueField
                  name={`Are any of them physically or mentally incapable of self care?`}
                  value={incapableChildren.join(', ')}
                />
              </div>
              <div
                style={
                  summaryVariant ===
                  TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                    ? {marginTop: 20}
                    : {flex: 0.48}
                }>
                <Typography
                  style={{
                    fontSize: 13,
                    color: themmeColor.black60,
                    marginBottom: 8,
                  }}>
                  Applicable deductions
                </Typography>
                <KeyValueTable
                  data={[{amount: adoptionExpenses}]}
                  keys={[
                    {
                      getValue: () => 'Adoption expenses',
                    },
                    {
                      getValue: ({amount}) =>
                        amount ? CurrencyNumberFormat(amount) : amount,
                      cellStyle: {
                        justifyContent: 'flex-end',
                        display: 'flex',
                      },
                      textStyle: {fontWeight: 600},
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )
      )}
    </SubSectionBox>
  );
};

export default AdoptionCredits;
