import {TextField} from '@mui/material';
import React, {useMemo, useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import CrossIcon from 'src/icons/CrossIcon';
import CPAContentTile from './CPAContentTile';
import {LocalStorageKey} from 'src/constants/constants';
import {useSelector} from 'react-redux';
import {selectCPAMessageTemplates} from 'src/store/globals/globals.selector';

interface Props {
  setMessage: (newString: string) => void;
  cpaQuestionTemplatePopup: boolean;
  setCpaQuestionTemplatePopup: (state: boolean) => void;
}

const CPAMessageTemplates = ({
  setMessage,
  cpaQuestionTemplatePopup,
  setCpaQuestionTemplatePopup,
}: Props) => {
  const [showContent, setShowContent] = useState(
    localStorage.getItem(LocalStorageKey.CpaTemplateShowContent)
      ? localStorage.getItem(LocalStorageKey.CpaTemplateShowContent) === 'true'
      : true,
  );
  const [templateSearchText, setTemplateSearchText] = useState('');
  const templateContent = useSelector(selectCPAMessageTemplates);

  const toggleContent = () => {
    setShowContent((prev) => {
      localStorage.setItem(
        LocalStorageKey.CpaTemplateShowContent,
        (!prev).toString(),
      );
      return !prev;
    });
  };

  const cpaTemplateContentToShow = useMemo(() => {
    const lowerCaseSearchText = templateSearchText.toLowerCase();
    const newTemplateContent = templateContent.filter((data) => {
      const lowerCaseTitle: string = data.title.toLowerCase();
      return lowerCaseTitle.includes(lowerCaseSearchText);
    });
    return newTemplateContent;
  }, [templateContent, templateSearchText]);

  return (
    <PopUp
      style={{
        width: 700,
        overflow: 'hidden',
      }}
      isOpen={cpaQuestionTemplatePopup}
      onClose={() => {
        setCpaQuestionTemplatePopup(false);
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text type={TEXT_TYPES.L} text="Message templates" />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DSButton
            text={showContent ? 'Hide content' : 'Show content'}
            onClick={toggleContent}
            type="secondary"
            style={{marginRight: 16}}
          />
          <div
            onClick={() => {
              setCpaQuestionTemplatePopup(false);
            }}>
            <CrossIcon color="black" />
          </div>
        </div>
      </div>
      <TextField
        autoFocus
        label="Enter template title"
        value={templateSearchText}
        type="text"
        fullWidth={true}
        onChange={(e) => {
          setTemplateSearchText(e.target.value);
        }}
        style={{marginBlock: 16}}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          height: '60vh',
        }}>
        {cpaTemplateContentToShow.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}>
            No results
            <DSButton
              text="Show all topics"
              onClick={() => {
                setTemplateSearchText('');
              }}
              type="secondary"
            />
          </div>
        ) : (
          cpaTemplateContentToShow.map((data) => {
            return (
              <CPAContentTile
                title={data.title}
                body={data.body}
                masterShowContent={showContent}
                setMessage={setMessage}
                setCpaQuestionTemplatePopup={setCpaQuestionTemplatePopup}
              />
            );
          })
        )}
      </div>
    </PopUp>
  );
};

export default CPAMessageTemplates;
