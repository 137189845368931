import _ from 'lodash';
import {useCallback, useMemo} from 'react';
import {
  FORM_INPUT_TYPE,
  FormConfigProps,
  FormInputProps,
} from 'src/DesignSystem/Form/Form.types';
import {parseFormConfigToFormField} from 'src/DesignSystem/Form/Form.utils';
import {getValidatorFunctions} from 'src/DesignSystem/Form/Form.validators';

export type OverWriteFormFieldsType = (props: {
  fields: ReturnType<typeof parseFormConfigToFormField>[];
  data: any;
  setData: any;
}) => ReturnType<typeof parseFormConfigToFormField>[];

export const useFormData = ({
  config,
  data,
  setData,
  overWriteFields,
}: {
  config: FormConfigProps[];
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  overWriteFields?: OverWriteFormFieldsType;
}) => {
  const fields = useMemo(() => {
    let parsedFields = config.map((configItem) => {
      return parseFormConfigToFormField({
        config: configItem,
        data,
        setData,
      });
    });
    if (overWriteFields) {
      parsedFields = overWriteFields({fields: parsedFields, data, setData});
    }
    return parsedFields;
  }, [data, setData, overWriteFields]);

  const checkAllFieldsAreValid = useCallback(
    (fields: FormInputProps[]): boolean => {
      return fields.every((field) => {
        if (field.inputType === FORM_INPUT_TYPE.Array) {
          const arrayObjectProps = field.value.map((data) =>
            field.childProps.map((childProp) =>
              parseFormConfigToFormField({
                config: childProp,
                data,
                setData,
              }),
            ),
          );
          return arrayObjectProps.every(checkAllFieldsAreValid);
        }
        const validators = field.validators;
        if (validators) {
          const validatorFunctions = getValidatorFunctions(validators);
          return validatorFunctions.every(
            (isValid) => isValid(field.value).isValid,
          );
        }
        return true;
      });
    },
    [data, setData],
  );

  const areAllFieldsValid = useMemo(() => {
    return checkAllFieldsAreValid(fields);
  }, [checkAllFieldsAreValid, fields]);

  return {
    fields,
    areAllFieldsValid,
  };
};
