import {Add, Restore} from '@mui/icons-material';
import {Button, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

const GAP = 12;

const PromoSearchBar = ({
  loading,
  openCreatePromoCodeModal,
  openCreateCouponCodeModal,
  fetchData,
}: {
  loading: boolean;
  openCreatePromoCodeModal: () => void;
  openCreateCouponCodeModal: () => void;
  fetchData: () => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultPromoCode = searchParams.get('promoCode');
  const defaultCouponCode = searchParams.get('couponCode');

  const [promo, setPromo] = useState(defaultPromoCode ?? '');
  const [coupon, setCoupon] = useState(defaultCouponCode ?? '');

  const trimText = (value: string) => (value.trim().length === 0 ? '' : value);

  const onClickSearch = () => {
    setSearchParams({promoCode: promo, couponCode: coupon});
  };

  useEffect(() => {
    setCoupon(defaultCouponCode ?? '');
    setPromo(defaultPromoCode ?? '');
  }, [defaultPromoCode, defaultCouponCode]);

  return (
    <div style={{display: 'flex'}}>
      <TextField
        type={'search'}
        placeholder="Enter coupon code"
        onChange={(e) => setCoupon(trimText(e.target.value))}
        value={coupon}
        disabled={loading}
      />
      <div style={{width: GAP}} />
      <TextField
        type={'search'}
        placeholder="Enter promo code"
        onChange={(e) => setPromo(trimText(e.target.value))}
        value={promo}
        disabled={loading}
      />
      <div style={{width: GAP}} />
      <Button disabled={loading} onClick={onClickSearch} variant="outlined">
        Search
      </Button>
      <div style={{width: GAP}} />
      <Button disabled={loading} onClick={fetchData} variant="outlined">
        Refresh
      </Button>
      <div style={{width: GAP}} />
      <Button
        startIcon={<Add />}
        disabled={loading}
        variant="contained"
        onClick={openCreateCouponCodeModal}>
        Create Coupon
      </Button>
      <div style={{width: GAP}} />
      <Button
        startIcon={<Add />}
        disabled={loading}
        variant="contained"
        onClick={openCreatePromoCodeModal}>
        Create Promo-Code
      </Button>
      <div style={{width: GAP}} />
      <Button
        style={{backgroundColor: 'orange'}}
        startIcon={<Restore />}
        disabled={loading}
        variant="contained"
        onClick={() => setSearchParams({})}>
        Reset
      </Button>
    </div>
  );
};
export default PromoSearchBar;
