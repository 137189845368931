import React, {useContext, useEffect, useState} from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  bulkCreateReviewEsignDocs,
  generateDocUploadURL,
  getOcrData,
  getReviewEsignDocuments,
  postDocument,
  reuploadDoc,
  submitDocForOCR,
} from 'src/appApi';
import ReviewAndEsignContext, {
  ReviewAndEsignDocument,
  ReviewEsignDocumentType,
} from './ReviewAndEsignContext';
import {
  BackendReviewAndEsignResponse,
  DOC_UPLOAD_ACTION,
  MANDATORY_JURISDICTION,
  aggregateReviewEsignActionCount,
  backendReviewAndEsignResponseMapper,
  getFormData,
} from './ReviewAndEsign.utils';
import {
  BACKEND_RESPONSE_KEYS,
  isTaxFilingStatusComplete,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import UserInfoContext from '../UserInfoContext';
import {handleAsyncRequest, pollRequest} from 'src/common/utils';
import {makeStyles} from '@mui/styles';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {ReviewEsignRoute} from 'src/constants/routeName';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});

const CpaCenterReviewAndEsign = () => {
  const {activeYear} = useActiveYear();
  const {flyfin_user_id} = useParams();
  const [documentsData, setDocumentsData] = useState<
    Array<ReviewAndEsignDocument>
  >([]);
  const [docsLoading, setDocsLoading] = useState(false);
  const {setActionCounts} = useOutletContext();
  const {userInfo, fetchUserDetails} = useContext(UserInfoContext);
  const navigate = useNavigate();

  const fetchDocsData = async () => {
    try {
      setDocsLoading(true);
      if (!flyfin_user_id) return;

      const response = await getReviewEsignDocuments({
        user: flyfin_user_id,
        year: activeYear,
      });

      const documents = backendReviewAndEsignResponseMapper(
        response.data as BackendReviewAndEsignResponse,
      );
      const reviewEsignActionCount =
        aggregateReviewEsignActionCount(documents);

      setActionCounts((prev) => ({...prev, reviewEsignActionCount}));
      setDocumentsData(documents);
      setDocsLoading(false);
      return documents;
    } catch (e) {
      alert(`Something Went Wrong ${e}`);
    }
  };

  const handleSendDocToS3 = async (
    file: any,
    action: DOC_UPLOAD_ACTION,
    docId?: number,
  ) => {
    try {
      // create bucket in s3
      let [urlResponse, urlError] = await handleAsyncRequest(
        generateDocUploadURL({
          filename: file?.name ?? 'File',
          year: activeYear,
        }),
      );

      if (urlError) throw new Error('error: generateDocUploadURL');

      // upload file
      let [postDocumentRes, postDocumentError] = await handleAsyncRequest(
        postDocument(
          urlResponse.data.url,
          getFormData(file, urlResponse.data.fields),
        ),
      );

      if (postDocumentError) throw new Error('error: postDocument');

      // submit doc for ocr
      let [submitDocRes, submitDocError] = await handleAsyncRequest(
        submitDocForOCR({
          s3_key: urlResponse.data.s3_key,
          doc_type: ReviewEsignDocumentType.TAX_RETURNS,
        }),
      );

      if (submitDocError) throw new Error('error: submitDocForOCR');

      // get ocr values
      const ocrResponse: any = await pollRequest(
        async () => {
          const pollResponse = await getOcrData({
            job_id: submitDocRes.data,
            doc_type: ReviewEsignDocumentType.TAX_RETURNS,
            end_user_id: flyfin_user_id,
            year: activeYear,
          });
          return pollResponse;
        },
        (res: any) => {
          return res.data?.status === 'Done';
        },
        5000,
      );

      const ocrDataRes = ocrResponse.data ? ocrResponse.data.ocr_data : {};

      if (action === DOC_UPLOAD_ACTION.UPLOAD) {
        // save review doc to backend
        await bulkCreateReviewEsignDocs({
          user: flyfin_user_id ?? '',
          year: activeYear,
          s3_key: urlResponse.data.s3_key,
          filename: file.name,
          jurisdiction: MANDATORY_JURISDICTION,
          states_return_summary: ocrDataRes.summary?.state ?? {},
          federal_return_summary: ocrDataRes.summary?.federal ?? {},
          esign_fields: ocrDataRes?.esign_fields ?? [],
        });
      } else {
        // reupload reivew doc to backend
        await reuploadDoc({
          filename: file.name,
          s3_key: urlResponse.data.s3_key,
          extras: {jurisdiction: MANDATORY_JURISDICTION},
          year: activeYear,
          doc_type: ReviewEsignDocumentType.TAX_RETURNS,
          tfd_info_id: docId ?? -1,
          fly_user_id: flyfin_user_id ?? '',
          states_return_summary: ocrDataRes.summary?.state ?? {},
          federal_return_summary: ocrDataRes.summary?.federal ?? {},
          esign_fields: ocrDataRes?.esign_fields ?? [],
        });
      }

      await fetchDocsData();
      await fetchUserDetails();
    } catch (e) {
      alert(`${e}. Please contact tech team`);
    }
  };

  const handleDocUpload = async (file: any) => {
    await handleSendDocToS3(file, DOC_UPLOAD_ACTION.UPLOAD);
  };

  const handleDocReUpload = async (file: any, docId: number) => {
    await handleSendDocToS3(file, DOC_UPLOAD_ACTION.RE_UPLOAD, docId);
  };

  useEffect(() => {
    fetchDocsData().then((documents) => {
      if (
        !isTaxFilingStatusComplete(
          userInfo[BACKEND_RESPONSE_KEYS.STATUS],
          TAX_FILING_STATUS.PREP_STARTED,
        )
      ) {
        navigate(ReviewEsignRoute.UploadReturn);
        return;
      } else if (
        !isTaxFilingStatusComplete(
          userInfo[BACKEND_RESPONSE_KEYS.STATUS],
          TAX_FILING_STATUS.SUMMARY_REVIEWED,
        )
      ) {
        navigate(ReviewEsignRoute.CpaReview);
        return;
      } else {
        if (
          userInfo[BACKEND_RESPONSE_KEYS.STATUS] ===
          TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED
        ) {
          navigate(ReviewEsignRoute.BankDetails);
          return;
        } else if (documents && documents.length !== 0) {
          navigate(
            `${
              documents.filter(
                (document) =>
                  document.documentType ===
                  ReviewEsignDocumentType.TAX_RETURNS,
              )[0].documentId
            }`,
          );
          return;
        }
        navigate('');
        return;
      }
    });
  }, [userInfo.cpa_facing_ret_status, activeYear]);

  const styles = useStyles({});

  if (docsLoading) <FullScreenLoading open={docsLoading} />;

  return (
    <ReviewAndEsignContext.Provider
      value={{
        flyfin_user_id,
        documents: documentsData,
        setDocuments: setDocumentsData,
        handleDocUpload,
        handleDocReUpload,
        fetchDocsData,
        fetchUserDetails,
        docsLoading,
        reviewEsignNavigate: navigate,
      }}>
      <div className={styles.container}>
        <Outlet />
      </div>
    </ReviewAndEsignContext.Provider>
  );
};

export default CpaCenterReviewAndEsign;
