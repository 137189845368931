import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {default as MaterialButton} from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {BlankChoice, LandingScreenChoices} from '../constants/constants';
import Checkbox from '@material-ui/core/Checkbox';
import {
  DateAndTimePicker,
  getConvertedDate,
} from '../notifications/BatchNotifyButton';
import {Fragment, useState} from 'react';
import {
  Button,
  useMutation,
  useNotify,
  useUnselectAll,
  useListContext,
} from 'react-admin';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
const moment = require('moment-timezone');
const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  textFieldStyle: {
    marginTop: '2em',
    display: 'grid',
  },
}));
const CustomNotificationFormDialog = ({selectedIds, open, onClose}) => {
  // insert blank key into categoryMap to enable not patching in the request when field is set to blank
  const classes = useStyles();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const {resource} = useListContext();
  const [iosMessage, setIosMessage] = useState('');
  const [previewIosMessage, setPreviewIosMessage] = useState('');
  const [iosLandingScreen, setIosLandingScreen] = useState(BlankChoice);
  const [androidMessageTitle, setAndroidMessageTitle] = useState('');
  const [previewAndroidMessageTitle, setPreviewAndroidMessageTitle] =
    useState('');
  const [androidMessageBody, setAndroidMessageBody] = useState('');
  const [previewAndroidMessageBody, setPreviewAndroidMessageBody] =
    useState('');
  const [androidLandingScreen, setAndroidLandingScreen] =
    useState(BlankChoice);
  const [userIds, setUserIds] = useState([]);
  const [androidcheck, setAndroidcheck] = useState(false);
  const [ioscheck, setIoscheck] = useState(false);
  const [whenToSend, setWhenToSend] = useState('schedule');
  const [scheduledTime, setScheduledTime] = useState('');
  const [
    customBulkNotifyNow,
    {dataNow, totalNow, errorNow, loadingNow, loadedNow},
  ] = useMutation(
    {
      type: 'custom_notify',
      resource: 'notification',
      payload: {
        user_ids: userIds,
        ios_message: iosMessage,
        ios_landing_screen: iosLandingScreen['id'],
        android_message_title: androidMessageTitle,
        android_message_body: androidMessageBody,
        android_landing_screen: androidLandingScreen['id'],
        should_send_to_android: androidcheck,
        should_send_to_ios: ioscheck,
        realtime: true,
      },
    },
    {
      onSuccess: () => {
        unselectAll(resource);
        notify('Custom bulk notify now successful!');
        clearState();
        onClose();
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Custom bulk notify now failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  const [
    customBulkNotifySchedule,
    {
      dataSchedule,
      totalSchedule,
      errorSchedule,
      loadingSchedule,
      loadedSchedule,
    },
  ] = useMutation(
    {
      type: 'custom_notify',
      resource: 'notification',
      payload: {
        user_ids: userIds,
        ios_message: iosMessage,
        ios_landing_screen: iosLandingScreen['id'],
        android_message_title: androidMessageTitle,
        android_message_body: androidMessageBody,
        android_landing_screen: androidLandingScreen['id'],
        should_send_to_android: androidcheck,
        should_send_to_ios: ioscheck,
        realtime: false,
        scheduled_time: getConvertedDate(scheduledTime),
      },
    },
    {
      onSuccess: () => {
        unselectAll(resource);
        notify('Custom bulk notify schedule successful!');
        clearState();
        onClose();
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Custom bulk notify schedule failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  const clearState = () => {
    setIosMessage('');
    setIosLandingScreen(BlankChoice);
    setAndroidMessageTitle('');
    setAndroidMessageBody('');
    setAndroidLandingScreen(BlankChoice);
    setAndroidcheck(false);
    setIoscheck(false);
    setUserIds([]);
  };

  const handleConfirm = () => {
    if (whenToSend === 'now') customBulkNotifyNow();
    else customBulkNotifySchedule();
  };

  const onCancel = () => {
    clearState();
    onClose();
  };

  const convertMessage = (message) => {
    let new_message = message.replace('{{ first_name }}', 'John');
    new_message = new_message.replace('{{ last_name }}', 'Turner');
    new_message = new_message.replace('{{ email }}', 'john123@gmail.com');
    new_message = new_message.replace(
      '{{ profession }}',
      'Software Developer',
    );
    return new_message;
  };

  React.useEffect(() => {
    setUserIds(selectedIds);
  }, [selectedIds]);

  React.useEffect(() => {
    setPreviewIosMessage(convertMessage(iosMessage));
    setPreviewAndroidMessageTitle(convertMessage(androidMessageTitle));
    setPreviewAndroidMessageBody(convertMessage(androidMessageBody));
  }, [iosMessage, androidMessageBody, androidMessageTitle]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      scroll="paper">
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <h1>iOS</h1>
            <TextField
              className={classes.textFieldStyle}
              label="Type Notification message  (required)"
              multiline
              value={iosMessage}
              rows={4}
              rowsMax={Infinity}
              variant="outlined"
              onChange={(event) => {
                setIosMessage(event.target.value);
              }}
            />
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={LandingScreenChoices.concat(BlankChoice)}
              value={iosLandingScreen}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setIosLandingScreen(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Landing Screen (required)"
                  variant="outlined"
                />
              )}
            />
            <h1>Android</h1>
            <TextField
              className={classes.textFieldStyle}
              label="Type Notification Title  (required)"
              value={androidMessageTitle}
              variant="outlined"
              onChange={(event) => {
                setAndroidMessageTitle(event.target.value);
              }}
            />
            <TextField
              className={classes.textFieldStyle}
              label="Type Notification Body (required)"
              multiline
              value={androidMessageBody}
              rows={4}
              rowsMax={Infinity}
              variant="outlined"
              onChange={(event) => {
                setAndroidMessageBody(event.target.value);
              }}
            />
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={LandingScreenChoices.concat(BlankChoice)}
              value={androidLandingScreen}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setAndroidLandingScreen(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Landing Screen (required)"
                  variant="outlined"
                />
              )}
            />

            <br />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <MaterialButton variant="contained" color="primary">
                Preview
              </MaterialButton>
              <h3>
                {userIds[userIds.length - 1] !== ''
                  ? userIds.length
                  : userIds.length - 1}{' '}
                user selected
              </h3>
              <TextField
                className={classes.textFieldStyle}
                label="User id comma separated"
                multiline
                value={userIds}
                rows={3}
                rowsMax={Infinity}
                variant="outlined"
                onChange={(event) => {
                  setUserIds(event.target.value.split(','));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h3>iOS message preview</h3>
              {previewIosMessage}
            </Grid>
            <Grid item xs={12}>
              <h3>Android message preview</h3>
              <h5>Title</h5>
              {previewAndroidMessageTitle}
              <h5>Body</h5>

              {previewAndroidMessageBody}
            </Grid>
            <br />
            <br />
            Android
            <Checkbox
              checked={androidcheck}
              onChange={() => setAndroidcheck(!androidcheck)}
              name="androidcheck"
              color="primary"
            />
            iOS
            <Checkbox
              checked={ioscheck}
              onChange={() => setIoscheck(!ioscheck)}
              name="ioscheck"
              color="primary"
            />
            <br />
            <br />
            <RadioGroup
              aria-label="type"
              name="when"
              value={whenToSend}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setWhenToSend(newValue);
                }
              }}>
              <Grid container>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Now"
                  />
                </Grid>
                <Grid item>
                  <Chip
                    disabled={whenToSend === 'schedule' ? true : false}
                    label={moment.tz('America/Los_Angeles').toString()}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="schedule"
                    control={<Radio />}
                    label="Schedule"
                  />
                </Grid>
                <Grid item>
                  <DateAndTimePicker
                    disable={whenToSend === 'now' ? true : false}
                    scheduledTime={scheduledTime}
                    setScheduledTime={setScheduledTime}
                  />
                  ( US Pacific Date time )
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={onCancel}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={handleConfirm}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirm
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const CustomNotificationButton = ({selectedIds, history}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const navigate = useNavigate();
  return (
    <Fragment>
      <Button label="Custom Notification" onClick={handleClick} />
      <CustomNotificationFormDialog
        selectedIds={selectedIds}
        open={open}
        onClose={handleDialogClose}
        goBack={() => navigate(-1)}
      />
    </Fragment>
  );
};

export default CustomNotificationButton;
