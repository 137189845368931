import React, {useContext, useState} from 'react';
import {Button, Modal, Typography} from '@mui/material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
  AuthorType,
  CPA_CALL_STATUS,
  CPAQuestionMessageType,
  CPAQuestionType,
  RESOLVED_OPACITY,
  themmeColor,
  VALID_DOCUMENT_REASON,
} from 'src/constants/constants';
import TaxQueriesContext, {
  Message,
} from 'src/CpaCenterUserInfo/CpaCenterTaxQueries/components/TaxQueriesContext';
import DoneIcon from '@mui/icons-material/Done';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {
  getEnvBasedConfig,
  getFormattedDateTime,
  getRelativeDate,
} from 'src/common/utils';
import {Send} from '@mui/icons-material';
import {postMessage, postTaxQuery, resolveQuery} from 'src/appApi';
import ReactHtmlParser from 'react-html-parser';
import {v4 as uuidv4} from 'uuid';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import {appendSpreadsheet} from 'src/common/postGoogleSheets';
import {getDocumentOcrPath} from 'src/utils/navigation.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

enum QUERY_STATES {
  NEW = 'NEW',
  SELECTED = 'SELECTED',
  NOT_SELECTED = 'NOT_SELECTED',
}

const RenderMessage: React.FC<Message> = ({
  timeStamp,
  fromUs,
  text,
  tagged_documents,
}) => {
  const {flyfin_user_id} = useParams();
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 25,
        justifyContent: fromUs ? 'end' : 'start',
      }}>
      {/* content container */}
      <div
        style={{display: 'flex', flexDirection: 'column', maxWidth: '45vw'}}>
        <Typography
          style={{
            padding: '2px',
            alignSelf: fromUs ? 'flex-start' : 'flex-end',
          }}
          fontWeight={400}
          fontSize={13}>
          {getRelativeDate(timeStamp)}
        </Typography>
        <div
          style={{
            padding: 12,
            backgroundColor: fromUs ? '#D7E9FF' : themmeColor.offWhite,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: fromUs ? '#8CC0FF' : themmeColor.silver,
            border: 'solid',
          }}>
          {/* <Typography>{text}</Typography> */}
          <div>{ReactHtmlParser(text)}</div>
          {flyfin_user_id &&
            (tagged_documents.document_exists ||
              tagged_documents.document_type_exists) && (
              <Link
                onClick={(e) =>
                  !tagged_documents.document_exists && e.preventDefault()
                }
                to={getDocumentOcrPath({
                  docTypeId: tagged_documents?.document_type_id,
                  docId: tagged_documents?.document_id,
                  userId: flyfin_user_id,
                })}
                style={{textDecoration: 'inherit', color: 'inherit'}}>
                <div
                  style={{
                    display: 'inline-flex',
                    backgroundColor: themmeColor.white,
                    borderRadius: 8,
                    padding: '8px 12px',
                    marginTop: 12,
                  }}>
                  <FileOpenIcon style={{marginRight: 10}} />
                  <Typography>
                    {tagged_documents.document_exists
                      ? tagged_documents.name
                      : tagged_documents.doc_type}
                  </Typography>
                </div>
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};

const QueryViewer = () => {
  const {activeYear} = useActiveYear();
  const {queryId, flyfin_user_id} = useParams();
  const {queriesData, fetchTaxQueries, postingQuery, setPostingQuery} =
    useContext(TaxQueriesContext);
  const parsedQueriyId = parseInt(queryId ?? '', 10);
  const [textEditorId, setTextEditorId] = useState('');
  const [callConfirmation, setCallConfirmation] = useState(false);
  const query = queriesData.filter(
    (query) => query.query_id === parsedQueriyId,
  )?.[0];

  const navigate = useNavigate();
  const cpaId = localStorage.getItem('id');

  const [message, setMessage] = useState('');
  const [rephrasedMessage, setRephrasedMessage] = useState('');

  const currentState = query
    ? QUERY_STATES.SELECTED
    : queryId === 'new'
    ? QUERY_STATES.NEW
    : QUERY_STATES.NOT_SELECTED;

  if (currentState === QUERY_STATES.NOT_SELECTED) return null;

  const isCallScheduled =
    query?.call_status === CPA_CALL_STATUS.CALL_SCHEDULED;
  const callScheduledConfig = isCallScheduled
    ? {
        buttonTitle: 'Cancel call',
        templateMessage:
          'Hope this was helpful. If you feel your query is answered, you can go ahead and cancel the call.',
        type: CPAQuestionMessageType.CALL_CANCELLATION,
      }
    : {
        buttonTitle: 'Schedule call',
        templateMessage:
          'Please schedule a call by clicking on the link below.',
        type: CPAQuestionMessageType.CALL_INVITE,
      };

  const handleResolveQuery = async () => {
    // TODO post resolve query
    if (!queryId) return;
    try {
      await resolveQuery({query_id: queryId});
    } catch (e) {
      alert(
        'Something went wrong with last action, refresh page or contact tech-team',
      );
    } finally {
      fetchTaxQueries();
    }
  };

  const navigateToQuery = (id: number) => {
    navigate(`../${id}`);
  };

  const logEventToSheets = (params = {}) => {
    const newRow = {
      userId: flyfin_user_id,
      cpaId,
      randomId: textEditorId,
      originalMessage: message,
      rephrasedMessage: rephrasedMessage,
      ...params,
    };
    appendSpreadsheet(newRow);
  };

  const handleSend = async () => {
    if (!flyfin_user_id) return;
    // Todo send message
    logEventToSheets({eventName: 'send_message'});
    try {
      setPostingQuery(true);
      switch (currentState) {
        case QUERY_STATES.NEW: {
          // Create new query
          const response = await postTaxQuery({
            fly_user_id: flyfin_user_id,
            query: message,
            app_year: activeYear,
            type: CPAQuestionType.TAX_FILING,
            sub_type: VALID_DOCUMENT_REASON.GENERAL,
          });
          await fetchTaxQueries();
          navigateToQuery(response.data);
          break;
        }
        case QUERY_STATES.SELECTED: {
          // post message in existing query
          if (!queryId) return;
          await postMessage({
            message,
            cpa_question_id: queryId,
            author_type: AuthorType.CPA,
            type: CPAQuestionMessageType.CHAT,
          });
          break;
        }
        default: {
        }
      }
      setMessage('');
    } catch (e) {
      alert(
        'Something went wrong with last action, refresh page or contact tech-team',
      );
    } finally {
      setTextEditorId('');
      fetchTaxQueries();
      setPostingQuery(false);
    }
  };

  const handleRephraseText = async () => {
    setPostingQuery(true);
    const env = getEnvBasedConfig();
    const GPT_URL = `${env.REACT_APP_DATA_PROVIDER_URL}/website/tax-gpt/`;
    fetch(GPT_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        is_self_employed: false,
        is_working_from_home: false,
        is_user_query_tax_related: false,
        og_prompt: `We are tax CPA. Please don't mention tax cpa or repharsing in the response. Please help me repharse - ${message}`,
        should_contain_base_prompt: false,
        conversation_thread: [],
        use_get_prompt_func: false,
        temperature: 0.5,
        max_tokens: false,
      }),
    }).then(async (stream) => {
      const data = stream.body;
      if (!data) {
        return;
      }

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      let lastMessage = '';

      while (!done) {
        const {value, done: doneReading} = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        lastMessage += chunkValue;
      }
      setPostingQuery(false);
      const randomId = uuidv4();
      setTextEditorId(randomId);

      logEventToSheets({
        randomId,
        rephrasedMessage: lastMessage,
        eventName: 'rephrase_tax_query',
      });
      setRephrasedMessage(lastMessage);
    });
  };

  const handleCpaCall = async () => {
    setPostingQuery(true);
    closeCallConfirmation();
    try {
      setPostingQuery(true);
      if (!queryId) return;
      await postMessage({
        message: callScheduledConfig.templateMessage,
        cpa_question_id: queryId,
        author_type: AuthorType.CPA,
        type: CPAQuestionMessageType.DEEPLINK,
        sub_type: callScheduledConfig.type,
      });
    } catch (e) {
      alert(
        'Something went wrong with last action, refresh page or contact tech-team',
      );
    } finally {
      fetchTaxQueries();
      setPostingQuery(false);
    }
  };

  const closeCallConfirmation = () => {
    setCallConfirmation(false);
  };

  const handleClearEditor = () => {
    setMessage('');
    setTextEditorId('');
    setRephrasedMessage('');
  };

  const handlePasteRephrasedText = () => {
    setMessage(rephrasedMessage);
    logEventToSheets({
      eventName: 'paste_rephrased_to_editor',
      rephrasedMessage: '',
      originalMessage: rephrasedMessage,
    });
    setRephrasedMessage('');
  };

  return (
    <div style={{width: '75%', height: '100%', overflowY: 'scroll'}}>
      {
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '6px 0px',
              border: 'solid',
              borderWidth: 1,
              borderColor: themmeColor.offWhite,
            }}>
            {query && (
              <div
                style={{
                  padding: '4px 12px',
                  backgroundColor: themmeColor.errorRed,
                  borderRadius: 12,
                  alignItems: 'center',
                  display: 'inline-flex',
                  marginLeft: -4,
                  opacity: query?.resolved ? RESOLVED_OPACITY : 1,
                }}>
                <Typography
                  style={{opacity: query?.resolved ? RESOLVED_OPACITY : 1}}
                  fontSize={10}
                  fontWeight={600}>
                  {query?.query_type}
                </Typography>
              </div>
            )}
          </div>
          {!query?.resolved && currentState !== QUERY_STATES.NEW && (
            <div
              onClick={handleResolveQuery}
              style={{
                borderRadius: 8,
                padding: '8px 16px',
                display: 'inline-flex',
                position: 'fixed',
                alignSelf: 'end',
                backgroundColor: themmeColor.offWhite,
                alignItems: 'center',
                right: 10,
              }}>
              <Typography>Mark as resolved</Typography>
              <DoneIcon style={{marginLeft: 10, height: 16, width: 16}} />
            </div>
          )}
          <div
            style={{
              padding: 20,
              overflowY: 'auto',
              height: '50vh',
            }}>
            {query?.messages?.map((message) => (
              <RenderMessage {...message} />
            ))}
            {query?.resolved && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: themmeColor.lightGreen,
                  height: 36,
                  marginTop: 20,
                  alignItems: 'center',
                }}>
                <Typography>Resolved</Typography>
              </div>
            )}
          </div>
        </div>
      }
      {/* TextField Here */}
      {!query?.resolved && (
        <div style={{padding: '10px', bottom: 0}}>
          {/* https://codesandbox.io/s/linko-template-editor-r3nwy */}
          <RichTextEditor message={message} setMessage={setMessage} />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {rephrasedMessage.length > 0 && (
              <p style={{fontWeight: 'bold'}}>
                Chat GPT repharsed response:{' '}
                <span style={{fontWeight: 'normal'}}>{rephrasedMessage}</span>
              </p>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 4,
            }}>
            <div style={{display: 'flex'}}>
              <Button
                onClick={handleClearEditor}
                disabled={postingQuery || message.length === 0}
                variant="outlined">
                Clear editor
              </Button>
              <Button
                onClick={() => setCallConfirmation(true)}
                disabled={postingQuery || currentState === QUERY_STATES.NEW}
                variant="outlined">
                {callScheduledConfig.buttonTitle}
              </Button>
              {isCallScheduled && (
                <p style={{marginRight: 4, marginLeft: 4}}>
                  {getFormattedDateTime(query.start_time, query.end_time)}
                </p>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 4,
              }}>
              <Button
                onClick={handleRephraseText}
                variant="outlined"
                disabled={postingQuery || message.length === 0}
                style={{marginRight: 12}}>
                Rephrase
              </Button>
              <Button
                onClick={handlePasteRephrasedText}
                variant="outlined"
                disabled={postingQuery || rephrasedMessage.length === 0}
                style={{marginRight: 12}}>
                Paste rephrased to editor
              </Button>
              <Button
                onClick={handleSend}
                disabled={postingQuery || message.length === 0}
                startIcon={<Send />}
                variant="contained">
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={callConfirmation}>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 12,
            padding: 16,
            width: '40%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <p>Are you sure?</p>
          <div style={{flex: 'row'}}>
            <Button
              onClick={handleCpaCall}
              variant="contained"
              style={{marginRight: 12}}>
              yes
            </Button>
            <Button onClick={closeCallConfirmation}>No</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QueryViewer;
