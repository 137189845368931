import {ReduxStateType} from '../store';
import {TaxReturn, TaxReturnType} from '../taxReturns/taxReturns.reducer';
import {ReduxQueryType} from './queries.reducer';

export const selectQueriesReducer = (state: ReduxStateType) => {
  return state.queries;
};

export const selectQueries = (state: ReduxStateType) => {
  return state.queries.queries;
};

export const selectLastMessage = (query: ReduxQueryType) => {
  return query.messages[query.messages.length - 1];
};

export const selectQuery = (state: ReduxStateType, queryId: number) => {
  return state.queries.queryMap[queryId];
};

export const selectQueriesForCurrReturn = (
  state: ReduxStateType,
  currentReturn: Pick<TaxReturn, 'return_id' | 'return_type'> | null,
) => {
  return {
    queries:
      currentReturn == null
        ? state.queries.queries
        : state.queries.queries.filter((query) =>
            currentReturn.return_type === TaxReturnType.INDIVIDUAL
              ? query.assigned_return_type === currentReturn.return_type
              : query.assigned_return_type === currentReturn.return_type &&
                query.biz_return_id === currentReturn.return_id,
          ),
    loaded: state.queries.loaded,
  };
};
