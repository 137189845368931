import React from 'react';
import {Typography} from '@mui/material';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import Box from 'src/DesignSystem/Box/Box';
import Skeleton from 'src/icons/Skeleton';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import SubSectionBox from '../../common/SubSectionBox';
import {useDependentCredits} from './useDependentCredits';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import ChildCareExpenseCard from './ChildCareExpenseCard';
import DependentCreditsDocs from './DependentCreditsDocs';

const DependentsCredits = () => {
  const {
    isLoading,
    dependentsTaxForm,
    getDependentCreditsInfo,
    getDependentCreditQuestionsToShow,
    dependentToChildCareExpenseForms,
  } = useDependentCredits();
  const {summaryVariant} = useTaxProfileSummaryFilter();

  return (
    <SubSectionBox
      name="Dependents credits"
      taxprofileSubsection={TaxProfileSubsection.DependentCredits}>
      {isLoading ? (
        <Skeleton width={'100%'} height={300} />
      ) : (
        <>
          {dependentsTaxForm.map((dependent) => {
            const name =
              dependent.formData[TaxFormFieldIds.DEPENDENT_FIRST_NAME];
            const relation =
              dependent.formData[TaxFormFieldIds.DEPENDENT_RELATION];
            const questions = getDependentCreditQuestionsToShow(dependent);
            const cceForms =
              dependentToChildCareExpenseForms[dependent.taxFormId];
            const shouldShowCCE =
              dependent.formData[TaxFormFieldIds.HAVE_CHILD_CARE_EXPENSES];

            return (
              <div
                key={dependent.taxFormId}
                style={
                  summaryVariant ===
                  TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                    ? {}
                    : {
                        display: 'flex',
                        marginBlock: 20,
                        flex: 1,
                        justifyContent: 'space-between',
                      }
                }>
                <Box
                  backgroundColor={themmeColor.cpaCenterV2Bg}
                  col
                  borderColor={themmeColor.offWhite}
                  style={
                    summaryVariant ===
                    TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                      ? {paddingBlock: 20, paddingInline: 16}
                      : {paddingBlock: 20, paddingInline: 16, flex: 0.48}
                  }>
                  <Typography style={{fontWeight: 600, marginBottom: 12}}>
                    {`${getFieldValue(name)} (${getFieldValue(relation)})`}
                  </Typography>
                  {questions.map((question) => {
                    return (
                      <SimpleKeyValueField
                        name={question.title}
                        value={dependent.formData[question.questionId]}
                      />
                    );
                  })}
                </Box>
                <div
                  style={
                    summaryVariant ===
                    TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                      ? {marginTop: 20}
                      : {flex: 0.48}
                  }>
                  <Typography
                    style={{
                      fontSize: 13,
                      color: themmeColor.black60,
                      marginBottom: 8,
                    }}>
                    Applicable deductions
                  </Typography>
                  <KeyValueTable
                    data={getDependentCreditsInfo(dependent)}
                    keys={[
                      {getValue: (rowData) => rowData.label},
                      {
                        getValue: (rowData) => rowData.value,
                        cellStyle: {
                          justifyContent: 'flex-end',
                          display: 'flex',
                        },
                        textStyle: {fontWeight: 600},
                      },
                    ]}
                  />
                  {shouldShowCCE &&
                    cceForms.map((form, index) => (
                      <div style={{marginTop: 12}}>
                        <ChildCareExpenseCard index={index} cceForm={form} />
                      </div>
                    ))}
                  <DependentCreditsDocs dependentTF={dependent} />
                </div>
              </div>
            );
          })}
        </>
      )}
    </SubSectionBox>
  );
};

export default DependentsCredits;
