import {Typography} from '@mui/material';
import React from 'react';

interface SimpleLabelProps {
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  value: any;
  getReadValue?: (value: any) => any;
}

const SimpleLabel = ({
  style = {},
  textStyle = {},
  value,
  getReadValue = (value: any) => value,
}: SimpleLabelProps) => {
  return (
    <div style={style}>
      <Typography style={textStyle}>{getReadValue(value)}</Typography>
    </div>
  );
};

export default SimpleLabel;
