import React from 'react';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {useCreateReturn} from './useCreateReturn';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {PartnershipReturnFields} from 'src/appApi.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {themmeColor} from 'src/constants/constants';
import {CREATE_RETURN_STATUS} from './CreateReturn.types';

const CreatedReturnsPopup = () => {
  const {createdReturns, setCreatedReturns, status, setStatus} =
    useCreateReturn();

  const onClose = () => {
    setStatus(CREATE_RETURN_STATUS.INIT);
    setCreatedReturns([]);
  };

  const {navigateToReturnDetails} = usePartnershipNavigation();

  return (
    <PopUp
      isOpen={status === CREATE_RETURN_STATUS.RETURNS_CREATED}
      style={{width: 600}}
      onClose={onClose}>
      <Text
        type={TEXT_TYPES.L}
        text={'Returns have been created successfully'}
        fontWeight={FONT_WEIGHTS.SemiBold}
      />
      <Text
        text={'Following returns have been created'}
        type={TEXT_TYPES.S}
        containerStyle={{marginBottom: 12, marginTop: 16}}
      />
      {createdReturns.map((taxReturn) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
            }}>
            <Text
              type={TEXT_TYPES.BASE}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={`Name: ${taxReturn[PartnershipReturnFields.name]}`}
              containerStyle={{
                backgroundColor: themmeColor.cpaCenterV2Bg,
                borderRadius: 8,
                paddingBlock: 16,
                paddingInline: 12,
                flex: 1,
                marginRight: 12,
              }}
            />
            <Text
              type={TEXT_TYPES.BASE}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={`Year: ${taxReturn[PartnershipReturnFields.year]}`}
              containerStyle={{
                backgroundColor: themmeColor.cpaCenterV2Bg,
                borderRadius: 8,
                paddingBlock: 16,
                paddingInline: 12,
                marginRight: 12,
              }}
            />
            <DSButton
              text="Open"
              onClick={() =>
                navigateToReturnDetails(
                  taxReturn[PartnershipReturnFields.fly_user_id],
                  taxReturn[PartnershipReturnFields.year],
                )
              }
              type="secondary"
              style={{paddingBlock: 12}}
            />
          </div>
        );
      })}
    </PopUp>
  );
};

export default CreatedReturnsPopup;
