import {WarningRounded} from '@mui/icons-material';
import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';

const SectionPendingWarning = () => {
  return (
    <div
      style={{
        background: '#FFFAED',
        borderRadius: 4,
        padding: '3px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
      }}>
      <WarningRounded style={{color: themmeColor.flyfinYellow}} />
      <Typography
        style={{fontSize: '13', fontWeight: 'normal', marginLeft: 4}}>
        User hasn’t completed this section and is okay not claiming incomplete
        parts
      </Typography>
    </div>
  );
};

export default SectionPendingWarning;
