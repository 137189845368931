import {Edit} from '@mui/icons-material';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Tag from 'src/DesignSystem/Tag/Tag';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {
  GenericTaxForm,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';

interface BusinessVehicleDetailsProps {
  index: number;
  vehicleTaxForm: GenericTaxForm;
}

const BusinessVehicleDetails = ({
  index,
  vehicleTaxForm,
}: BusinessVehicleDetailsProps) => {
  const {onEditTaxForm} = usePartnerTaxProfileReview();

  const onEditVehicle = () => {
    onEditTaxForm({
      taxFormId: vehicleTaxForm.taxFormId,
      taxFormType: vehicleTaxForm.formType,
      title: 'Edit vehicle',
    });
  };

  return (
    <Box
      style={{padding: 12, marginBottom: 12}}
      col
      backgroundColor={themmeColor.cpaCenterV2Bg}
      borderColor={themmeColor.silver}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Tag
          text={`Vehicle ${index + 1}`}
          textColor={themmeColor.white}
          backgroundColor={themmeColor.black}
        />
        <DSButton
          type="primary"
          text="Edit"
          startIcon={<Edit />}
          onClick={onEditVehicle}
        />
      </div>
      <div
        style={{
          gridTemplateColumns: 'repeat(auto-fit,minmax(250px,1fr))',
          display: 'grid',
          marginTop: 12,
        }}>
        <MultiKeyValueField
          fields={[
            {name: 'Vehicle make & model', path: TaxFormFieldIds.ITEM_NAME},
            {name: 'Model year', path: TaxFormFieldIds.MAKE_YEAR},
            {name: 'Total miles driven', path: TaxFormFieldIds.TOTAL_MILES},
            {
              name: 'Business miles driven',
              path: TaxFormFieldIds.BUSINESS_MILES,
            },
            {
              name: 'Type',
              path: TaxFormFieldIds.VEHICLE_EXPENSE_TYPE,
            },
            {
              name: 'Vehicle purchase date',
              path: TaxFormFieldIds.DATE_OF_PURCHASE,
            },
            {
              name: 'Business use start date',
              path: TaxFormFieldIds.DATE_PUT_IN_SERVICE,
            },
            {
              name: 'Total vehicle cost',
              path: TaxFormFieldIds.ITEM_VALUE,
              parseReadValue: (val) => CurrencyNumberFormat(val),
            },
          ]}
          data={vehicleTaxForm.formData}
        />
      </div>
    </Box>
  );
};

export default BusinessVehicleDetails;
