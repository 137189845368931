import {Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import RightChveron from 'src/icons/RightChveron';
import {setChildCareExpenseDrawer} from 'src/store/app/app.actions';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTransactionIdMap} from 'src/store/transactions/transaction.selector';

interface ChildCareExpenseCardProps {
  cceForm: TaxForm;
  index: number;
}

const ChildCareExpenseCard = ({index, cceForm}: ChildCareExpenseCardProps) => {
  const txnMap = useSelector(selectTransactionIdMap);
  const title = `Child care expense ${index + 1}`;

  const dispatch = useDispatch();

  const onClickExpense = () => {
    dispatch(setChildCareExpenseDrawer(true, cceForm.taxFormId, title));
  };
  const amount = useMemo(() => {
    const txnIds = cceForm.formData[TaxFormFieldIds.TXN_IDS];
    let _amount = 0;
    if (Array.isArray(txnIds)) {
      txnIds.forEach((txnId) => {
        const txn = txnMap[txnId];
        if (txn) {
          _amount += txn.currentAmount;
        }
      });
    }
    return _amount;
  }, [txnMap, cceForm]);

  return (
    <Box
      hoverEffect
      hoverColor={themmeColor.focusedBlue}
      onClick={onClickExpense}
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{padding: 16, justifyContent: 'space-between', marginBottom: 8}}
      borderColor={themmeColor.offWhite}>
      {title}
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography style={{fontWeight: 600}}>
          {CurrencyNumberFormat(amount)}
        </Typography>
        <RightChveron />
      </div>
    </Box>
  );
};

export default ChildCareExpenseCard;
