import {
  BOI_FILING_COLUMNS,
  SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER,
} from './types';

export const BOI_LIST_COLUMN_NAMES = {
  [BOI_FILING_COLUMNS.ASSIGNED_REVIEWER]: 'CPA',
  [BOI_FILING_COLUMNS.FLY_USER_ID]: 'User ID',
  [BOI_FILING_COLUMNS.FLY_USER_NAME]: 'Name',
  [BOI_FILING_COLUMNS.PAYMENT_STATUS]: 'Payment status',
  [BOI_FILING_COLUMNS.STATUS]: 'Status',
  [BOI_FILING_COLUMNS.SUBMIT_TYPE]: 'BOI mode',
  [BOI_FILING_COLUMNS.USER_SUBMITTED_AT]: 'User submitted date',
};

export const SELECT_POINTS_APPLICABLE_FOR_BUSINESS_COPIES = {
  [SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER.MORE_THAN_20_USA_FT_EMPLOYEES]:
    'Has more than 20 full time employees in USA',
  [SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER.OPERATING_PHYSICAL_OFFICE_IN_USA]:
    'An operating presence at a physical office within the United States',
  [SELECT_POINTS_APPLICABLE_FOR_BUSINESS_VALID_ANSWER.MORE_THAN_5_MILLION_USA_TAX_PREV_YEAR]:
    "Over $5 million revenue reported on last year's U.S. tax return.",
};

export enum BOI_REQUIRED_ANSWERS {
  YES = 'Yes',
  UNSURE = 'Unsure',
  NO = 'No',
}
