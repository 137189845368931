import {TextField, Typography} from '@mui/material';
import React from 'react';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Tag from 'src/DesignSystem/Tag/Tag';
import {
  RETURN_SUMMARY_CATEGORY,
  ReturnSummaryKeys,
  themmeColor,
} from 'src/constants/constants';
import DeleteIcon from 'src/icons/DeleteIcon';
import {
  ReturnSummaryInputFieldProps,
  ReturnSummaryInputType,
} from '../ReviewSummary.types';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import './styles.css';

interface OcrDataSectionProps {
  sectionName: string;
  data: ReturnSummaryInputFieldProps[];
  onChangeKey: (toUpdateIndex: number, newKey: any) => void;
  onChangeValue: (toUpdateIndex: number, newValue: any) => void;
  onDelete?: () => void;
}

const OcrDataSection = ({
  sectionName,
  data,
  onChangeKey,
  onChangeValue,
  onDelete,
}: OcrDataSectionProps) => {
  return (
    <DSAccordion
      defaultExpanded
      summaryStyle={{backgroundColor: themmeColor.lightYellow}}
      Summary={() => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
            }}>
            <Tag
              text={sectionName}
              textColor={themmeColor.white}
              backgroundColor={themmeColor.grey}
            />
            {onDelete && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}>
                <DeleteIcon />
              </div>
            )}
          </div>
        );
      }}>
      <div>
        {data.map((field, index) => {
          if (field.type === ReturnSummaryInputType.TEXT_INPUT) {
            return (
              <div
                style={{
                  marginBottom: 20,
                }}>
                <TextField
                  className="no-spinner"
                  style={{width: '100%'}}
                  type={'number'}
                  onChange={(e) => onChangeValue(index, e.target.value)}
                  onWheel={(event) => event.target.blur()}
                  value={field.value}
                  label={RETURN_SUMMARY_CATEGORY[field.key]}
                  error={field.valueMandatory && field.value === null}
                />
              </div>
            );
          }
          return (
            <div
              style={{
                marginBottom: 20,
                display: 'flex',
                alignItems: 'center',
              }}>
              <Dropdown
                options={field.options}
                value={field.key}
                placeholder={field.options.join('/')}
                getOptionLabel={(option: ReturnSummaryKeys) =>
                  RETURN_SUMMARY_CATEGORY[option]
                }
                onChange={(newKey) => onChangeKey(index, newKey)}
                maxWidth
                error={field.key === null}
              />
              <TextField
                className="no-spinner"
                style={{marginLeft: 12, width: '100%'}}
                type={'number'}
                onChange={(e) => onChangeValue(index, e.target.value)}
                onWheel={(event) => event.target.blur()}
                value={field.value}
                disabled={!field.key}
                label={field.key}
                error={field.valueMandatory && field.value === null}
              />
            </div>
          );
        })}
      </div>
    </DSAccordion>
  );
};

export default OcrDataSection;
