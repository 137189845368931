import {get, isArray, isNull, isObject, isUndefined, keyBy} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getPath} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {Form} from 'src/DesignSystem/Form/Form';
import {
  FORM_INPUT_TYPE,
  FormInputProps,
} from 'src/DesignSystem/Form/Form.types';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import DeleteIcon from 'src/icons/DeleteIcon';
import DocumentIcon from 'src/icons/DocumentIcon';
import ManualIcon from 'src/icons/ManualIcon';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {BalanceSheetLineItemCategories} from 'src/store/taxForms/taxForms.constants';
import {
  InformationSource,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import {overWriteBalanceSheetLineItem} from 'src/store/taxForms/taxForms.utils';

interface LineItemProps extends ArrayChildProps {
  header:
    | TaxFormFieldIds.ASSETS
    | TaxFormFieldIds.LIABILITIES
    | TaxFormFieldIds.EQUITY;
  entityType: BusinessType | null;
}

const LineItem = ({
  header,
  arrayObjectIndex,
  childProps,
  childTitle,
  onChangeValue,
  entityType,
  array,
}: LineItemProps) => {
  const {updateObject, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    (props) => {
      return overWriteBalanceSheetLineItem({...props, entityType, header});
    },
    [entityType, header],
  );

  const {fields} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
    overWriteFields,
  });

  const isApplicableSwitchField = fields.find(
    (field) => field.path === TaxFormFieldIds.IS_APPLICABLE,
  );

  const onDelete = () => {
    const newValue = array.filter((_, index) => index !== arrayObjectIndex);
    onChangeValue(newValue);
  };

  return (
    <Box
      borderColor={themmeColor.silver}
      col
      style={{
        padding: 16,
        marginBottom: 12,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{display: 'flex', gap: 4, alignItems: 'center'}}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: themmeColor.grey,
              border: `1px solid ${themmeColor.offWhite}`,
              padding: '2px 8px',
            }}>
            <Text
              color={themmeColor.white}
              type={TEXT_TYPES.S}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={arrayObjectIndex + 1}
            />
          </div>
          {arrayObject[TaxFormFieldIds.SOURCE] ===
          InformationSource.document ? (
            <DocumentIcon />
          ) : (
            <ManualIcon />
          )}
        </div>
        <div style={{display: 'flex', gap: 8}}>
          {arrayObject[TaxFormFieldIds.SOURCE] !==
          InformationSource.document ? (
            <DSButton
              text="Delete"
              startIcon={<DeleteIcon />}
              type="secondary"
              onClick={onDelete}
            />
          ) : null}
          {isApplicableSwitchField ? (
            <Form {...isApplicableSwitchField} />
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '0px 12px',
          marginTop: 12,
        }}>
        {fields.map((field) => {
          if (
            [TaxFormFieldIds.SOURCE, TaxFormFieldIds.IS_APPLICABLE].includes(
              field.path as TaxFormFieldIds,
            )
          ) {
            return null;
          }
          return (
            <div style={{marginBottom: 12}}>
              <Form {...field} />
            </div>
          );
        })}
      </div>
    </Box>
  );
};

type BalanceSheetLineItemsProps = FormInputProps & {
  path: string;
  bizId: number;
};

const BalanceSheetLineItems = (props: BalanceSheetLineItemsProps) => {
  const {businesses} = useSelector(selectBusinessDetails);

  const entityType = useMemo(() => {
    const biz = businesses.find(
      (biz) => biz[BusinessDetailsField.id] === props.bizId,
    );
    if (isUndefined(biz)) {
      return null;
    }
    return biz[BusinessDetailsField.entity_type];
  }, [businesses, props.bizId]);

  if (props.inputType !== FORM_INPUT_TYPE.Array) {
    return null;
  }

  const header = props.path.split('.')[0] as
    | TaxFormFieldIds.ASSETS
    | TaxFormFieldIds.LIABILITIES
    | TaxFormFieldIds.EQUITY;

  const onAddLineItem = () => {
    const emptyLineItem = createEmptyFormDataFromFormConfig(
      props.childProps,
      0,
    );
    emptyLineItem[TaxFormFieldIds.SOURCE] = InformationSource.manual;
    emptyLineItem[TaxFormFieldIds.IS_APPLICABLE] = true;
    if (header === TaxFormFieldIds.EQUITY) {
      emptyLineItem[TaxFormFieldIds.CATEGORY] =
        BalanceSheetLineItemCategories.Equity;
    }
    props.onChangeValue([emptyLineItem, ...(props.value ?? [])]);
  };

  return (
    <div style={{marginTop: 12, marginBottom: 12}}>
      <DSAccordion defaultExpanded name={props.title}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}>
        <DSButton
          type="primary"
          onClick={onAddLineItem}
          text={`Add ${props.title}`}
          style={{marginLeft: 'auto'}}
        />
      </div>
      {Array.isArray(props.value)
        ? props.value.map((_, i) => (
            <div>
              <LineItem
                header={header}
                arrayObjectIndex={i}
                array={props.value}
                entityType={entityType}
                {...props}
              />
            </div>
          ))
        : null}
    </DSAccordion>
    </div>
  );
};

export default BalanceSheetLineItems;
