import {useSelector} from 'react-redux';
import {selectActiveYear} from 'src/store/globals/globals.selector';
import {useParams} from 'react-router-dom';

export const useActiveYear = () => {
  let activeYear: number = useSelector(selectActiveYear);
  const {year} = useParams();

  if (year) {
    const parsedYear = parseInt(year, 10);
    if (!Number.isNaN(parsedYear)) {
      activeYear = parsedYear;
    }
  }

  return {activeYear};
};
