import {GridRenderEditCellParams, useGridApiContext} from '@mui/x-data-grid';
import React from 'react';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import {VALID_CPA_EMAILS} from 'src/constants/constants';

const ChooseCPA = (params: GridRenderEditCellParams<any, any, any>) => {
  const context = useGridApiContext();
  return (
    <Dropdown
      options={Object.values(VALID_CPA_EMAILS)}
      value={params.value}
      onChange={(newVal) => {
        context.current.setEditCellValue({
          id: params.id,
          field: params.field,
          value: newVal,
        });
      }}
      maxWidth
      placeholder="Choose CPA"
    />
  );
};

export default ChooseCPA;
