import {Button, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {AddOutlined, ChatBubbleRounded, Refresh} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {themmeColor} from 'src/constants/constants';
import {
  addThread,
  getAllDocThreads,
  updateThreadUnreadMessages,
} from 'src/appApi';
import {
  CPA_REVIEW_THREAD_STATUS,
  CpaReviewThread,
} from '../../ReviewAndEsign.utils';
import Thread from './components/Thread';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {ReviewAndEsignDocument} from '../../ReviewAndEsignContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid black',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    borderBottom: 'solid',
    background: themmeColor.lightGreen,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
  noNotes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
});

interface ReviewAndEsignNotesProps {
  setIsAllResolved: any;
  setReUploadDocOverlay: any;
  doc: ReviewAndEsignDocument;
}

const ReviewAndEsignNotes: React.FC<ReviewAndEsignNotesProps> = ({
  setIsAllResolved,
  setReUploadDocOverlay,
  doc,
}) => {
  const [threads, setThreads] = useState<{[key: number]: CpaReviewThread}>({});
  const [threadsSequence, setThreadsSequence] = useState<number[]>([]);
  const [openThreadId, setOpenThreadId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditThread, setShowEditThread] = useState(false);

  const fetchThreads = async () => {
    const params = {
      doc_id: doc.documentId,
    };
    try {
      const res = await getAllDocThreads(params);
      const threadsData = res.data.reduce((result, thread) => {
        return {...result, [thread.id]: thread};
      }, {});
      setThreadsSequence(res.data.map((thread) => thread.id));
      setThreads(threadsData);
    } catch (e) {
      alert(`Error occured ${e}`);
    }
  };

  const refreshThreads = async () => {
    setIsLoading(true);
    await fetchThreads();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchThreads();
    const tabSwitchEventListener = (event) => {
      if (document.visibilityState === 'visible') {
        fetchThreads();
      }
    };
    document.addEventListener('visibilitychange', tabSwitchEventListener);
    setIsLoading(false);
    return () => {
      document.removeEventListener('visibilitychange', tabSwitchEventListener);
    };
  }, []);

  useEffect(() => {
    let isAllResolved = true;
    Object.keys(threads).forEach((threadId) => {
      if (threads[Number(threadId)].status === CPA_REVIEW_THREAD_STATUS.OPEN) {
        isAllResolved = false;
        return;
      }
    });
    if (isAllResolved && Object.keys(threads).length !== 0)
      setReUploadDocOverlay(true);
    setIsAllResolved(isAllResolved);
  }, [threads]);

  const sendNewThread = async (comment: string) => {
    setIsLoading(true);
    const requestBody = {
      doc_id: doc.documentId,
      comment: comment,
    };
    addThread(requestBody)
      .then((res) => {
        const newThread: CpaReviewThread = res.data;
        setThreads((prevState) => {
          return {[newThread.id]: newThread, ...prevState};
        });
        setThreadsSequence((prevState) => {
          return [newThread.id, ...prevState];
        });
        setOpenThreadId(newThread.id);
        setShowEditThread(false);
      })
      .catch((e) => {
        alert(`Error Occured ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
        setShowEditThread(false);
      });
  };

  const createThread = () => {
    if (!showEditThread) {
      setShowEditThread(true);
    }
  };

  const onThreadClick = (id: number) => {
    const unreadMessages = threads[id].unread_messages;
    if (unreadMessages.length !== 0) {
      updateThreadUnreadMessages(
        {
          unread_messages: unreadMessages,
        },
        {
          thread_id: id,
        },
      )
        .then(() => {
          fetchThreads();
        })
        .catch((e) => {
          alert(`Something went wrong ${e}`);
        });
    }
    setShowEditThread(false);
    setOpenThreadId(id);
  };

  const styles = useStyles({});

  return (
    <>
      {isLoading && <FullScreenLoading open={isLoading} />}
      <div className={styles.container} onClick={() => setOpenThreadId(null)}>
        <div className={styles.header}>
          <Typography fontWeight={'600'} fontSize={'16px'} fontStyle={'Inter'}>
            Notes
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div onClick={refreshThreads} style={{marginRight: '10px'}}>
              <Refresh />
            </div>
            {Object.keys(threads).length !== 0 && (
              <Button
                variant="contained"
                size="small"
                onClick={createThread}
                startIcon={<AddOutlined />}
                style={{
                  background: themmeColor.black,
                  borderRadius: '64px',
                  textTransform: 'none',
                }}>
                <Typography
                  fontWeight={'500'}
                  fontSize={'13px'}
                  fontStyle={'Inter'}>
                  Add a note
                </Typography>
              </Button>
            )}
          </div>
        </div>
        <div className={styles.content}>
          {Object.keys(threads).length === 0 && !showEditThread ? (
            <div className={styles.noNotes}>
              <div style={{textAlign: 'center'}}>
                <Typography
                  fontWeight={'600'}
                  fontSize={'16px'}
                  fontStyle={'Inter'}
                  style={{margin: '20px 0px'}}>
                  You can add your notes <br />
                  here
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={createThread}
                  startIcon={<ChatBubbleRounded />}
                  style={{
                    borderRadius: '8px',
                    padding: '5px 40px',
                    textTransform: 'none',
                  }}>
                  Add a note
                </Button>
              </div>
            </div>
          ) : (
            <>
              {showEditThread && (
                <Thread
                  key={-1}
                  isEditing={true}
                  sendNewThread={sendNewThread}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setThreads={setThreads}
                />
              )}
              {threadsSequence.map((threadId) => {
                return (
                  <Thread
                    key={`${threadId}`}
                    threadData={threads[threadId]}
                    isEditing={openThreadId === threadId}
                    onThreadClick={onThreadClick}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setThreads={setThreads}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewAndEsignNotes;
