import React from 'react';
import {
  useDistributDeductions,
  DistributeDeductionsProvider,
} from './useDistributeDeductions';
import BackIcon from 'src/icons/BackIcon';
import {themmeColor} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {Typography} from '@mui/material';
import QuestionIcon from 'src/icons/QuestionIcon';
import {useDispatch} from 'react-redux';
import {setDistributeDeductionsQueryDrawer} from 'src/store/app/app.actions';
import DSTabs from 'src/DesignSystem/Tabs/DSTabs';
import {DeductionsDistributedByAuthor} from 'src/store/distributeDeductions/distributeDeductions.reducer';
import DSButton from 'src/DesignSystem/Button/Button';
import Skeleton from 'src/icons/Skeleton';
import AllDistributeDeductionsTable from './components/AllDistributeDeductionsTable';
import UserNotSubmittedView from './components/UserNotSubmittedView';

const DistributeDeductions = () => {
  const {
    selectedAuthor,
    setSelectedAuthor,
    isUpdateButtonDisabled,
    dataLoaded,
    postCpaData,
    hasUserSubmitted,
    querySent,
  } = useDistributDeductions();
  const {navigateToBrowserBack} = useCpaCenterV2Navigation();

  const dispatch = useDispatch();
  return (
    <div>
      <div
        style={{
          marginBlock: 12,
          paddingInline: 12,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div
            onClick={navigateToBrowserBack}
            style={{
              backgroundColor: themmeColor.black,
              width: 36,
              height: 36,
              borderRadius: 100,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
            <BackIcon color={themmeColor.white} />
          </div>
          <Typography style={{marginLeft: 12, fontWeight: 800, fontSize: 20}}>
            Distribute deductions
          </Typography>
        </div>

        <div
          onClick={() => dispatch(setDistributeDeductionsQueryDrawer(true))}
          style={{
            display: 'flex',
            padding: 20,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: themmeColor.white,
            marginRight: 4,
          }}>
          {querySent ? (
            <Typography style={{marginRight: 8}}>Ask again</Typography>
          ) : null}
          <QuestionIcon />
        </div>
      </div>
      <div
        style={{
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'space-between',
          paddingInline: 12,
        }}>
        <DSTabs
          value={selectedAuthor}
          onChange={(newAuthor) => setSelectedAuthor(newAuthor)}
          options={[
            {
              label: 'User Submitted',
              value: DeductionsDistributedByAuthor.END_USER,
            },
            {
              label: 'CPA Edit',
              value: DeductionsDistributedByAuthor.CPA,
            },
          ]}
        />
        <DSButton
          type="primary"
          onClick={postCpaData}
          text="Save"
          disabled={isUpdateButtonDisabled}
        />
      </div>
      {!dataLoaded ? (
        <Skeleton width={'100%'} height={500} />
      ) : selectedAuthor === DeductionsDistributedByAuthor.END_USER &&
        !hasUserSubmitted ? (
        <UserNotSubmittedView />
      ) : (
        <AllDistributeDeductionsTable />
      )}
    </div>
  );
};

const DistributeDeductionsWithContext = () => {
  return (
    <DistributeDeductionsProvider>
      <DistributeDeductions />
    </DistributeDeductionsProvider>
  );
};

export default DistributeDeductionsWithContext;
