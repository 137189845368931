import {useContext} from 'react';
import {themmeColor} from 'src/constants/constants';
import {useNavigate} from 'react-router-dom';
import {
  BACKEND_RESPONSE_KEYS,
  CPA_LIST_COLUMNS,
  getDateForNextAction,
  getDateTimeFromMonthDayString,
  getMonthDateFromDate,
} from 'src/CpaCenterList/components/cpaList.utils';
import {IconButton, Typography} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import NormalReadOnlyCell from 'src/CpaCenterList/components/NormalReadOnlyCell/NormalReadOnlyCell';
import UpdateReturnStatusCell from 'src/CpaCenterList/components/UpdateReturnStatusCell/UpdateReturnStatusCell';
import NextActionDatePickerCell from 'src/CpaCenterV2/common/UserSummaryBar/components/NextActionDatePickerCell';
import UpdateNoteCell from 'src/CpaCenterList/components/UpdateNoteCell/UpdateNoteCell';
import ExtensionStatus from 'src/CpaCenterUserInfo/components/ExtensionStatusPicker';
import ExportData from 'src/CpaCenterUserInfo/components/ExportData';
import UpdateCCHClientIdCell from 'src/CpaCenterList/components/UpdateCCHClientIdCell/UpdateCCHClientIdCell';
import {RouteName} from 'src/constants/routeName';
import UserInfoContext from '../UserInfoContext';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const TopUserInfoBar = () => {
  const {activeYear} = useActiveYear();
  const navigate = useNavigate();
  const {fetchUserDetails, userInfo} = useContext(UserInfoContext);

  const goBack = () => navigate(RouteName.TaxFiling);

  const dataItems = [
    {label: CPA_LIST_COLUMNS.USER_ID, value: userInfo.fly_user_id},
    {label: CPA_LIST_COLUMNS.NAME, value: userInfo.fly_user_name},
    {label: CPA_LIST_COLUMNS.EMAIL_ADDRESS, value: userInfo.email},
  ];

  const RenderDataItem = ({label, value}: {label: string; value?: string}) => {
    return (
      <div style={{marginLeft: 20, marginBottom: 16}}>
        <Typography color={themmeColor.black60} fontWeight="600" fontSize={13}>
          {label}
        </Typography>
        <Typography color={themmeColor.black60}>{value}</Typography>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
      }}>
      <IconButton
        onClick={goBack}
        style={{
          borderRadius: 8,
          marginLeft: 20,
          borderWidth: 1,
          border: `solid ${themmeColor.offWhite}`,
        }}>
        <ArrowCircleLeftIcon style={{marginRight: 12}} />
        <Typography>Back to list</Typography>
      </IconButton>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          overflowX: 'auto',
        }}>
        {dataItems.map((item) => (
          <RenderDataItem {...item} />
        ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
            marginBottom: 16,
          }}>
          <Typography
            fontSize={13}
            color={themmeColor.black60}
            fontWeight={600}>
            {CPA_LIST_COLUMNS.CCH_CLIENT_ID}
          </Typography>
          <div style={{marginTop: 2, height: 20, width: 120}}>
            <UpdateCCHClientIdCell
              currentCCHClientId={
                userInfo[BACKEND_RESPONSE_KEYS.CCH_CLIENT_ID]
              }
              flyUserId={userInfo.fly_user_id}
              onSuccess={fetchUserDetails}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
            marginBottom: 16,
          }}>
          <Typography
            fontSize={13}
            color={themmeColor.black60}
            fontWeight={600}>
            {CPA_LIST_COLUMNS.LAST_STATUS_CHANGE}
          </Typography>
          <NormalReadOnlyCell
            value={getMonthDateFromDate(
              getDateTimeFromMonthDayString(userInfo.last_status_change_date),
            )}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
            marginBottom: 16,
          }}>
          <Typography
            fontSize={13}
            color={themmeColor.black60}
            fontWeight={600}>
            {CPA_LIST_COLUMNS.RETURN_STATUS}
          </Typography>
          <div style={{marginTop: 2, height: 20, width: 200}}>
            <UpdateReturnStatusCell
              currentReturnStatusId={userInfo[BACKEND_RESPONSE_KEYS.STATUS]}
              taxFilingId={userInfo.id}
              onSuccess={fetchUserDetails}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
            marginBottom: 16,
          }}>
          <Typography
            fontSize={13}
            color={themmeColor.black60}
            fontWeight={600}>
            {CPA_LIST_COLUMNS.NEXT_ACTION_DATE}
          </Typography>
          <NextActionDatePickerCell
            value={
              userInfo.next_action_date ?? getDateForNextAction(new Date())
            }
            onSuccess={fetchUserDetails}
            taxFilingId={userInfo.id}
            isValid={!!userInfo.next_action_date}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
            marginBottom: 16,
          }}>
          <Typography
            fontSize={13}
            color={themmeColor.black60}
            fontWeight={600}>
            {CPA_LIST_COLUMNS.NOTE}
          </Typography>
          <UpdateNoteCell
            value={userInfo.notes}
            whom={'Varsha'}
            timeStamp={1678350076}
            isEmpty={!userInfo.notes}
            taxFilingId={userInfo.id}
            onSuccess={fetchUserDetails}
          />
        </div>
        <ExtensionStatus
          currentExtensionStatus={
            userInfo[BACKEND_RESPONSE_KEYS.EXTENSION_STATUS] ?? ''
          }
          taxFilingId={userInfo.id}
          onSuccess={fetchUserDetails}
        />

        <div style={{marginLeft: 20, marginBottom: 16}}>
          <ExportData fly_user_id={userInfo.fly_user_id} year={activeYear} />
        </div>
      </div>
    </div>
  );
};

export default TopUserInfoBar;
