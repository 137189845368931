import {useContext, useState} from 'react';
import DocumentsContext from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import {VALID_DOCUMENT_STATUS, themmeColor} from 'src/constants/constants';
import {Button, Typography} from '@mui/material';
import ActionRequiredOverlay from './ActionRequiredOverlay';
import {useParams} from 'react-router-dom';
import IgnoreDocumentOverlay from './IgnoreDocumentOverlay';
import ReviewOcrData from './ReviewOcrData/ReviewOcrData';
import {postDocumentStatus} from 'src/appApi';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    background: themmeColor.offWhite,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
});

const CpaActionPane = () => {
  const {docsData, fetchDocuments} = useContext(DocumentsContext);
  const {flyfin_user_id, docTypeId, docId} = useParams();

  const parsedDocTypeId = docTypeId ? parseInt(docTypeId, 10) : undefined;
  const parsedDocumentId = docId ? parseInt(docId, 10) : undefined;
  const documents = docsData?.filter(
    (doc_data) => doc_data.documentTypeId === parsedDocTypeId,
  )?.[0]?.documents;
  const document = documents?.filter(
    (document) => document.documentId === parsedDocumentId,
  )?.[0];

  const [actionRequiredOverlay, setActionRequiredOverlay] = useState(false);
  const [ignoreDocumentOverlay, setIgnoreDocumentOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAcceptDocument = async () => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: document.documentId ?? -1,
        status: VALID_DOCUMENT_STATUS.ACCEPTED,
      });
      await fetchDocuments();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const styles = useStyles({});

  if (loading) return <FullScreenLoading open={loading} />;
  return document ? (
    <div>
      {document.status === VALID_DOCUMENT_STATUS.ACCEPTED ||
      document.status === VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA ? (
        <ReviewOcrData
          flyfinUserId={flyfin_user_id ?? ''}
          document={document}
          fetchDocuments={fetchDocuments}
        />
      ) : (
        <div>
          <div className={styles.header}>
            <Typography fontSize={'16px'} fontWeight="600">
              Step 1: Verify Document
            </Typography>
          </div>
          <div className={styles.content}>
            <Button
              onClick={() => onAcceptDocument()}
              style={{
                backgroundColor: themmeColor.successGreen,
                color: themmeColor.white,
                padding: '10px 20px',
                borderRadius: 8,
                margin: 8,
              }}
              variant={'contained'}>
              Accept
            </Button>
            {document.status !== VALID_DOCUMENT_STATUS.ACTION_REQUIRED && (
              <Button
                onClick={() => setActionRequiredOverlay(true)}
                style={{
                  backgroundColor: themmeColor.errorRed,
                  color: themmeColor.white,
                  padding: '10px 20px',
                  borderRadius: 8,
                  margin: 8,
                }}>
                Take Action
              </Button>
            )}
            {document.status !== VALID_DOCUMENT_STATUS.IGNORED && (
              <Button
                onClick={() => setIgnoreDocumentOverlay(true)}
                style={{
                  backgroundColor: themmeColor.white,
                  color: themmeColor.darkBlue,
                  padding: '10px 20px',
                  border: `1px solid ${themmeColor.darkBlue}`,
                  borderRadius: 8,
                  margin: 8,
                }}>
                Ignore
              </Button>
            )}
          </div>
        </div>
      )}
      {actionRequiredOverlay && (
        <ActionRequiredOverlay
          document={document}
          handleClose={() => setActionRequiredOverlay(false)}
          isVisible={actionRequiredOverlay}
        />
      )}
      {ignoreDocumentOverlay && (
        <IgnoreDocumentOverlay
          document={document}
          handleClose={() => setIgnoreDocumentOverlay(false)}
          isVisible={ignoreDocumentOverlay}
        />
      )}
    </div>
  ) : null;
};

export default CpaActionPane;
