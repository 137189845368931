import React, {useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {Add} from '@mui/icons-material';
import CreatePartnersPopup from './CreatePartnersPopup';

const PartnersListHeader = () => {
  const {navigateToTaxReturnsList} = usePartnershipNavigation();

  const [createPartnersPopup, setCreatePartnersPopup] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <DSButton
        text="View Tax Returns"
        startIcon={<ChevronLeft />}
        onClick={navigateToTaxReturnsList}
        type="secondary"
        style={{paddingBlock: 12}}
      />
      <DSButton
        text="Add new"
        startIcon={<Add />}
        onClick={() => setCreatePartnersPopup(true)}
        type="primary"
        style={{paddingBlock: 12}}
      />
      <CreatePartnersPopup
        isOpen={createPartnersPopup}
        onClose={() => setCreatePartnersPopup(false)}
      />
    </div>
  );
};

export default PartnersListHeader;
