import {getEfileLogs} from 'src/appApi';
import {SET_EFILE_LOGS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {EfileLogsStateType} from './efileLogs.reducer';
const setEfileLogs = (payload: Pick<EfileLogsStateType, 'efileLogs'>) => {
  return {
    type: SET_EFILE_LOGS,
    payload,
  };
};

export const fetchEfileLogs = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = (await getEfileLogs({
        fly_user_id: userId,
        year,
      })) as any;
      dispatch(setEfileLogs({efileLogs: data}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch efile logs (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
