import React from 'react';

interface TaxReturnFieldGridWrapperProps {
  children: any;
  style?: React.CSSProperties;
}

const TaxReturnFieldGridWrapper = ({
  children,
  style = {},
}: TaxReturnFieldGridWrapperProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: 12,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default TaxReturnFieldGridWrapper;
