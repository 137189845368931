import {
  FORM_INPUT_TYPE,
  FormConfigProps,
} from 'src/DesignSystem/Form/Form.types';
import {
  BusinessEntityToCategoryToL2Map,
  BusinessTypeWiseExpenseCategory,
  EditTaxFormConfigMap,
  IncomeTaxFormConfig,
} from './taxForms.constants';
import {
  BalanceSheetHeaderType,
  BalanceSheetLineItemType,
  GenericTaxForm,
  IncomeEarnerType,
  OverWriteFormDataExtraParams,
  QuarterlyPaymentType,
  TaxFormFieldIds,
  TaxFormType,
} from './taxForms.types';
import {
  OverWriteFormFieldsFunctionArgsType,
  OverWriteFormFieldsFunctionType,
} from 'src/DesignSystem/Form/useFormData';
import _, {
  cloneDeep,
  get,
  isArray,
  isFunction,
  isNull,
  isObject,
  isString,
  isUndefined,
  keyBy,
  noop,
  union,
} from 'lodash';
import {
  isSchoolDistrictCodeRequired,
  stateAbbreviationNameMap,
  stateNameToAbbreviationMap,
} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {
  getCountyNameAndCodeMapForState,
  getPath,
  isCountyRequired,
} from 'src/common/utils';
import {
  FILING_STATUS_ANSWER,
  TaxProfileField,
  TaxProfileQuestion,
} from '../taxProfile/taxProfile.types';
import {INCOME_EARNER_VALUES} from 'src/CpaCenterV2/TaxProfileSections/Income/Income.constants';
import {
  BusinessDetail,
  BusinessDetailsField,
  BusinessType,
} from '../businessDetails/businessDetails.types';
import {IRSCatgoriesRequireTypeOfExpense} from '../transactions/transactions.constants';
import {
  checkFormDataValidity,
  getParsedFields,
} from 'src/DesignSystem/Form/Form.utils';

const overwriteNoop: OverWriteFormFieldsFunctionType = ({fields}) => {
  return fields;
};

const overwriteTaxPayment: OverWriteFormFieldsFunctionType = ({
  fields,
  data,
}) => {
  const fieldsToRemove: string[] = [];
  if (data[TaxFormFieldIds.PAYMENT_TYPE] !== QuarterlyPaymentType.STATE) {
    fieldsToRemove.push(TaxFormFieldIds.STATE);
  }
  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

const overwriteStateLivedTaxForm = (
  {fields, data, setData}: OverWriteFormFieldsFunctionArgsType,
  extraData: OverWriteFormDataExtraParams,
) => {
  const fieldsToRemove: string[] = [];
  const stateAbbrevation = data[TaxFormFieldIds.STATE];
  const stateName = stateAbbreviationNameMap[stateAbbrevation];
  const {taxprofileMap, taxforms} = extraData;

  const filingStatus = taxprofileMap[TaxProfileQuestion.FILING_STATUS];
  const isSpousePeriodsAreSame =
    taxprofileMap[TaxProfileQuestion.SPOUSE_PERIODS_ARE_SAME] !== false;

  const fieldByPath = _.keyBy(fields, 'path');

  const earner = data[TaxFormFieldIds.INCOME_EARNER];
  const isSelfEarner = earner === INCOME_EARNER_VALUES.MYSELF;
  const selectedState = data[TaxFormFieldIds.STATE];

  if (
    [INCOME_EARNER_VALUES.MYSELF, INCOME_EARNER_VALUES.MY_SPOUSE].includes(
      earner,
    )
  ) {
    const tpState = get(
      taxprofileMap,
      getPath(TaxProfileQuestion.HOME_ADDRESS, TaxProfileField.STATE, '[0]'),
      null,
    );
    let tpStateAbb: null | string = null;
    if (typeof tpState === 'string') {
      tpStateAbb = stateNameToAbbreviationMap[tpState] ?? null;
    }

    const earnerTaxFormStates = taxforms
      .filter((tf) => {
        if (tf.formType !== TaxFormType.STATES_LIVED) {
          return false;
        }
        if (
          isSelfEarner &&
          tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
            INCOME_EARNER_VALUES.MYSELF
        ) {
          return true;
        }
        if (
          !isSelfEarner &&
          tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
            INCOME_EARNER_VALUES.MY_SPOUSE
        ) {
          return true;
        }
        return false;
      })
      .map((tf) => tf.formData[TaxFormFieldIds.STATE]);

    const statesToExclude: string[] = [];
    if (tpStateAbb !== null && selectedState !== tpStateAbb) {
      // if state is same as tax profile and not currently selected, remove from options
      statesToExclude.push(tpStateAbb);
    }
    earnerTaxFormStates.forEach((tfState) => {
      if (typeof tfState === 'string' && tfState !== selectedState) {
        statesToExclude.push(tfState);
      }
    });
    const stateField = fieldByPath[TaxFormFieldIds.STATE];
    if (stateField && stateField.inputType === FORM_INPUT_TYPE.State) {
      stateField.excludeStates = statesToExclude;
    }
  }

  const stateField = fieldByPath[TaxFormFieldIds.STATE];
  if (stateField && stateField.inputType === FORM_INPUT_TYPE.State) {
    stateField.onChangeValue = (newValue) => {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.STATE]: newValue,
          [TaxFormFieldIds.COUNTY]: null,
        };
      });
    };
  }

  const incomeEarnerField = fieldByPath[TaxFormFieldIds.INCOME_EARNER];
  if (
    incomeEarnerField &&
    incomeEarnerField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    incomeEarnerField.onChangeValue = (newVal) => {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.STATE]: null,
          [TaxFormFieldIds.COUNTY]: null,
          [TaxFormFieldIds.INCOME_EARNER]: newVal,
        };
      });
    };
  }

  if (
    filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY ||
    isSpousePeriodsAreSame
  ) {
    if (
      incomeEarnerField &&
      incomeEarnerField.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      incomeEarnerField.options = [
        {
          label: INCOME_EARNER_VALUES.MYSELF,
          value: INCOME_EARNER_VALUES.MYSELF,
        },
      ];
    }
    if (data[TaxFormFieldIds.INCOME_EARNER] !== INCOME_EARNER_VALUES.MYSELF) {
      setData((prev: any) => {
        return {
          ...prev,
          [TaxFormFieldIds.INCOME_EARNER]: INCOME_EARNER_VALUES.MYSELF,
        };
      });
    }
  }

  if (typeof stateName !== 'string' || !isCountyRequired(stateName)) {
    fieldsToRemove.push(TaxFormFieldIds.COUNTY);
  } else {
    const countyField = fieldByPath[TaxFormFieldIds.COUNTY];
    if (
      countyField &&
      countyField.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      countyField.options = Object.entries(
        getCountyNameAndCodeMapForState(stateName),
      ).map(([countyName, countyCode]) => {
        return {
          label: countyName,
          value: countyCode,
        };
      });
    }
  }

  if (
    typeof stateName !== 'string' ||
    !isSchoolDistrictCodeRequired(stateName)
  ) {
    fieldsToRemove.push(TaxFormFieldIds.SCHOOL_DISTRICT_CODE);
  }

  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

const overWriteIncomeTaxForms = (
  taxFormType: TaxFormType,
  {fields, data, setData}: OverWriteFormFieldsFunctionArgsType,
  extraData: OverWriteFormDataExtraParams,
) => {
  const fieldsByPath = keyBy(fields, 'path');
  const fieldsToRemove: string[] = [];
  if (
    [
      TaxFormType.W2_FORM,
      TaxFormType.MANUAL_FREELANCE_INCOME_FORM,
      TaxFormType.FORM_1099_INT,
      TaxFormType.FORM_1099_DIV,
      TaxFormType.FORM_1099_B,
    ].includes(taxFormType)
  ) {
    const bizField = fieldsByPath[TaxFormFieldIds.BUSINESS_ID];
    if (bizField && bizField.inputType === FORM_INPUT_TYPE.SingleSelect) {
      bizField.options = extraData.businesses.map((biz) => ({
        label: biz[BusinessDetailsField.name],
        value: biz[BusinessDetailsField.id],
      }));
    }
    if (data[TaxFormFieldIds.INCOME_EARNER] !== IncomeEarnerType.BUSINESS) {
      fieldsToRemove.push(TaxFormFieldIds.BUSINESS_ID);
    }
  }
  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

export const overWriteProfitLossHeader = ({
  fields,
  data,
}: OverWriteFormFieldsFunctionArgsType) => {
  const fieldsByPath = keyBy(fields, 'path');
  const fieldsToRemove: string[] = [];
  // @ts-ignore
  fieldsByPath[TaxFormFieldIds.SOURCE]['disabled'] = true;

  if (!data[TaxFormFieldIds.IS_APPLICABLE]) {
    fieldsToRemove.push(TaxFormFieldIds.CATEGORY);
    fieldsToRemove.push(TaxFormFieldIds.L2);
  }

  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

export const overWriteProfitLossExpenseHeader = ({
  fields,
  data,
  entityType,
  getL2s,
  irsCategories,
}: OverWriteFormFieldsFunctionArgsType & {
  entityType: BusinessType;
  getL2s: (category: string) => string[];
  irsCategories: string[];
}) => {
  const fieldsByPath = keyBy(fields, 'path');
  const fieldsToRemove: string[] = [];
  // @ts-ignore
  fieldsByPath[TaxFormFieldIds.SOURCE]['disabled'] = true;

  if (
    fieldsByPath[TaxFormFieldIds.CATEGORY].inputType ===
    FORM_INPUT_TYPE.SingleSelect
  ) {
    let options: {label: string; value: string}[] = [];
    if (entityType !== BusinessType.SOLE_PROPRIETORSHIP) {
      options = BusinessTypeWiseExpenseCategory[entityType].map((k) => ({
        label: k,
        value: k,
      }));
    } else {
      options = irsCategories.map((k) => ({label: k, value: k}));
    }
    fieldsByPath[TaxFormFieldIds.CATEGORY].options = options;
  }
  const l2s = getL2s(data[TaxFormFieldIds.CATEGORY]);
  if (
    isString(data[TaxFormFieldIds.CATEGORY]) &&
    isArray(l2s) &&
    l2s.length > 0 &&
    fieldsByPath[TaxFormFieldIds.L2].inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    fieldsByPath[TaxFormFieldIds.L2].options = l2s.map((l2) => ({
      label: l2,
      value: l2,
    }));
  } else {
    fieldsToRemove.push(TaxFormFieldIds.L2);
  }
  if (
    isString(data[TaxFormFieldIds.L2]) &&
    !l2s.includes(data[TaxFormFieldIds.L2])
  ) {
    fieldsByPath[TaxFormFieldIds.L2].onChangeValue(null);
  }

  if (
    !(
      entityType === BusinessType.SOLE_PROPRIETORSHIP &&
      IRSCatgoriesRequireTypeOfExpense.includes(data[TaxFormFieldIds.CATEGORY])
    )
  ) {
    fieldsToRemove.push(TaxFormFieldIds.TYPE_OF_EXPENSE);
    if (isString(data[TaxFormFieldIds.TYPE_OF_EXPENSE])) {
      fieldsByPath[TaxFormFieldIds.TYPE_OF_EXPENSE].onChangeValue(null);
    }
  }

  if (!data[TaxFormFieldIds.IS_APPLICABLE]) {
    fieldsToRemove.push(TaxFormFieldIds.CATEGORY);
    fieldsToRemove.push(TaxFormFieldIds.L2);
  }

  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

export const getEditTaxFormConfig = (
  taxFormType: TaxFormType,
  filterFields?: (field: FormConfigProps) => boolean,
): FormConfigProps[] => {
  let config: FormConfigProps[] = [];
  if (EditTaxFormConfigMap[taxFormType] !== undefined) {
    config = EditTaxFormConfigMap[taxFormType] ?? [];
  }
  if (isFunction(filterFields)) {
    config = config.filter(filterFields);
  }
  return config;
};

export const getOverwriteTaxForm = (
  taxFormType: TaxFormType | null,
  extraData: OverWriteFormDataExtraParams,
): OverWriteFormFieldsFunctionType => {
  if (taxFormType === TaxFormType.QUARTERLY_TAX_PAYMENTS) {
    return overwriteTaxPayment;
  }
  if (taxFormType === TaxFormType.STATES_LIVED) {
    return (props) => overwriteStateLivedTaxForm(props, extraData);
  }
  const incomeFormsType = Object.keys(
    IncomeTaxFormConfig,
  ) as (keyof typeof IncomeTaxFormConfig)[];
  if (incomeFormsType.includes(taxFormType)) {
    return (props) => overWriteIncomeTaxForms(taxFormType, props, extraData);
  }
  return overwriteNoop;
};

export const cloneTaxFormForBackend = (
  taxform: GenericTaxForm,
  overwriteFormData: any = {},
): {
  id: number;
  form_type: TaxFormType;
  form_data: any;
  year: number;
} => {
  const clone = cloneDeep(taxform);

  return {
    id: clone.taxFormId,
    form_type: clone.formType,
    form_data: {...clone.formData, ...overwriteFormData},
    year: clone.year,
  };
};

export const validateProfitLossHeader = ({
  config,
  taxFormData,
  setTaxFormData,
  overWriteFields,
}: {
  config: FormConfigProps[];
  taxFormData: any;
  setTaxFormData: any;
  overWriteFields: OverWriteFormFieldsFunctionType;
}) => {
  const fields = getParsedFields(
    config,
    taxFormData,
    setTaxFormData,
    overWriteFields,
  );
  return fields.every((field) => {
    if (
      field.path ===
        getPath(TaxFormFieldIds.EXPENSES, TaxFormFieldIds.SUB_CATEGORY) &&
      field.inputType === FORM_INPUT_TYPE.Array
    ) {
      if (!isArray(field.value)) {
        return true;
      }
      return field.value.every((expense, index) => {
        const childFields = getParsedFields(
          field.childProps,
          expense,
          noop,
          (props) =>
            overWriteProfitLossHeader({
              ...props,
            }),
        );
        return checkFormDataValidity(childFields);
      });
    }
    return checkFormDataValidity([field]);
  });
};

export const validateProfitLoss = ({
  config,
  taxFormData,
  setTaxFormData,
  overWriteFields,
  businesses,
  linkedBusinessId,
  getL2s,
  irsCategories,
}: {
  config: FormConfigProps[];
  taxFormData: any;
  setTaxFormData: any;
  overWriteFields: OverWriteFormFieldsFunctionType;
  businesses: BusinessDetail[];
  linkedBusinessId?: number;
  getL2s: (category: string) => string[];
  irsCategories: string[];
}) => {
  const fields = getParsedFields(
    config,
    taxFormData,
    setTaxFormData,
    overWriteFields,
  );
  const linkedBusiness = businesses.find(
    (biz) => biz[BusinessDetailsField.id] === linkedBusinessId,
  );
  const entityType =
    linkedBusiness?.[BusinessDetailsField.entity_type] ?? BusinessType.S_CORP;
  return fields.every((field) => {
    if (field.inputType === FORM_INPUT_TYPE.Array) {
      if (!isArray(field.value)) {
        return true;
      }
      const overwriteFn =
        field.path ===
        getPath(TaxFormFieldIds.EXPENSES, TaxFormFieldIds.SUB_CATEGORY)
          ? overWriteProfitLossExpenseHeader
          : overWriteProfitLossHeader;
      return field.value.every((expense, index) => {
        const childFields = getParsedFields(
          field.childProps,
          expense,
          noop,
          (props) =>
            overwriteFn({
              ...props,
              entityType,
              getL2s,
              irsCategories,
            }),
        );
        return checkFormDataValidity(childFields);
      });
    }
    return checkFormDataValidity([field]);
  });
};

export const filterProfitAndLossIncomeField = (field: FormConfigProps) => {
  return !field.path.startsWith(TaxFormFieldIds.EXPENSES);
};

export const filterProfitAndLossExpenseField = (field: FormConfigProps) => {
  return !filterProfitAndLossIncomeField(field);
};

export const overWriteBalanceSheetLineItem = ({
  fields,
  data,
  entityType,
  header,
}: OverWriteFormFieldsFunctionArgsType & {
  entityType: BusinessType | null;
  header:
    | TaxFormFieldIds.ASSETS
    | TaxFormFieldIds.LIABILITIES
    | TaxFormFieldIds.EQUITY;
}) => {
  const fieldsByPath = keyBy(fields, 'path');
  const fieldsToRemove: string[] = [];

  let categories: string[] = [];
  const categoryToL2Map = get(
    BusinessEntityToCategoryToL2Map,
    getPath(entityType, header),
  );
  if (isObject(categoryToL2Map)) {
    categories = Object.keys(categoryToL2Map);
  }
  const catField = fieldsByPath[TaxFormFieldIds.CATEGORY];
  if (
    !isUndefined(catField) &&
    catField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    catField.options = categories.map((cat) => ({label: cat, value: cat}));
  }

  if (isNull(data[TaxFormFieldIds.CATEGORY])) {
    fieldsToRemove.push(TaxFormFieldIds.L2);
  } else {
    const category = data[TaxFormFieldIds.CATEGORY];
    const l2s = categoryToL2Map?.[category];
    const l2Field = fieldsByPath[TaxFormFieldIds.L2];
    if (
      isArray(l2s) &&
      l2s.length > 0 &&
      !isUndefined(l2Field) &&
      l2Field.inputType === FORM_INPUT_TYPE.SingleSelect
    ) {
      l2Field.options = l2s.map((l2) => ({label: l2, value: l2}));
      if (!isNull(l2Field.value) && !l2s.includes(l2Field.value)) {
        l2Field.onChangeValue(null);
      }
    } else {
      fieldsToRemove.push(TaxFormFieldIds.L2);
    }
  }

  const sourceField = fieldsByPath[TaxFormFieldIds.SOURCE];
  if (
    !isUndefined(sourceField) &&
    sourceField.inputType === FORM_INPUT_TYPE.SingleSelect
  ) {
    sourceField.disabled = true;
  }

  if (!data[TaxFormFieldIds.IS_APPLICABLE]) {
    fieldsToRemove.push(TaxFormFieldIds.CATEGORY);
    fieldsToRemove.push(TaxFormFieldIds.L2);
  }

  return fields.filter((field) => !fieldsToRemove.includes(field.path));
};

export const validateBalanceSheet = ({
  config,
  taxFormData,
  setTaxFormData,
  overWriteFields,
  businesses,
  linkedBusinessId,
}: {
  config: FormConfigProps[];
  taxFormData: any;
  setTaxFormData: any;
  overWriteFields: OverWriteFormFieldsFunctionType;
  businesses: BusinessDetail[];
  linkedBusinessId?: number;
}) => {
  const fields = getParsedFields(
    config,
    taxFormData,
    setTaxFormData,
    overWriteFields,
  );
  const linkedBusiness = businesses.find(
    (biz) => biz[BusinessDetailsField.id] === linkedBusinessId,
  );
  const entityType =
    linkedBusiness?.[BusinessDetailsField.entity_type] ?? BusinessType.S_CORP;
  return fields.every((field) => {
    if (
      [
        getPath(TaxFormFieldIds.ASSETS, TaxFormFieldIds.LINE_ITEMS),
        getPath(TaxFormFieldIds.LIABILITIES, TaxFormFieldIds.LINE_ITEMS),
        getPath(TaxFormFieldIds.EQUITY, TaxFormFieldIds.LINE_ITEMS),
      ].includes(field.path) &&
      field.inputType === FORM_INPUT_TYPE.Array
    ) {
      const header = field.path.split('.')[0] as
        | TaxFormFieldIds.ASSETS
        | TaxFormFieldIds.LIABILITIES
        | TaxFormFieldIds.EQUITY;
      if (!isArray(field.value)) {
        return true;
      }
      return field.value.every((expense, index) => {
        const childFields = getParsedFields(
          field.childProps,
          expense,
          noop,
          (props) =>
            overWriteBalanceSheetLineItem({...props, entityType, header}),
        );
        return checkFormDataValidity(childFields);
      });
    }
    return checkFormDataValidity([field]);
  });
};
