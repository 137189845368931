import React from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import {MessageAuthorType} from 'src/store/queries/queries.reducer';
import ReactHtmlParser from 'react-html-parser';
import {MessageProps} from './Message';
import {useSelector} from 'react-redux';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import MessageWrapper from './MessageWrapper';

const DocAttachedMessage = ({message, query}: MessageProps) => {
  const isFromUser = message.authorType === MessageAuthorType.END_USER;
  const {documents} = useSelector(selectDocuments);
  const linkedDocument = documents.find((doc) => doc.docId === message.docId);
  return (
    <MessageWrapper query={query} message={message}>
      <Box
        style={{
          paddingBlock: 12,
          paddingInline: 16,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: !isFromUser ? themmeColor.textPurple : '#F4F1F9',
          ...(isFromUser
            ? {borderBottomLeftRadius: 0}
            : {borderBottomRightRadius: 0}),
        }}>
        <Typography
          style={{
            color: isFromUser ? themmeColor.textPurple : themmeColor.white,
            overflow: 'auto',
          }}>
          {ReactHtmlParser(message.message)}
        </Typography>
        <DocumentPointer doc={linkedDocument} dontShowStatus border />
      </Box>
    </MessageWrapper>
  );
};

export default DocAttachedMessage;
