import React from 'react';
import {BackendMessage} from 'src/types/query.types';
import {AuthorType, themmeColor} from 'src/constants/constants';
import {DefaultTheme, makeStyles} from '@mui/styles';
import {Button, Grid, Typography} from '@mui/material';
import {UserProfile} from 'src/icons/iconLinks';
import {getRelativeDate} from 'src/common/utils';
import {getUnixTime} from 'date-fns';
import {Delete, Edit, Send} from '@mui/icons-material';
import ReactHtmlParser from 'react-html-parser';

type Props = {
  message: BackendMessage,
  onEdit?: (messageId: number) => void;
  onDelete?: (messageId: number) => void;
  onSendDraft?: (draftId: number) => void;
  editing?: boolean;
};

enum MessageViewType {
  EndUser = 'EndUser',
  Draft = 'Draft',
  Cpa = 'Cpa',
}

const getMessageViewType = (message: BackendMessage) => {
  if (message.author_type === AuthorType.END_USER)
    return MessageViewType.EndUser;
  if (message.is_draft)
    return MessageViewType.Draft;
  return MessageViewType.Cpa;
};

const getMessageStyleProps = ({message, highlight}: Props) => {
  const messageViewType = getMessageViewType(message);
  const props = {
    isFromEndUser: messageViewType === MessageViewType.EndUser,
    bgColor: themmeColor.lightSkyBlue,
    borderColor: themmeColor.skyBlue,
    highlight,
  };
  if (messageViewType === MessageViewType.EndUser) {
    return {
      ...props,
      bgColor: themmeColor.offWhite,
      borderColor: themmeColor.silver,
    };
  }
  if (messageViewType === MessageViewType.Draft) {
    return {
      ...props,
      bgColor: themmeColor.antiqueWhite,
      borderColor: themmeColor.hitPink,
    };
  }
  return props;
};

const useStyles = makeStyles<DefaultTheme, ReturnType<typeof getMessageStyleProps>>({
  container: {
    display: 'flex',
    flexDirection: ({isFromEndUser}) => isFromEndUser ? 'row' : 'row-reverse',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '80%',
    alignSelf: ({isFromEndUser}) => isFromEndUser ? 'flex-star' : 'flex-end',
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: ({isFromEndUser}) => isFromEndUser ? 'row' : 'row-reverse',
    justifyContent: 'space-between',
  },
  authorName: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  createTime: {
    marginLeft: ({isFromEndUser}) => isFromEndUser ? 20 : 0,
    marginRight: ({isFromEndUser}) => isFromEndUser ? 0 : 20,
    fontSize: 13,
  },
  messageText: {
    fontSize: 16,
  },
  msgContainer: {
    padding: 12,
    borderRadius: 8,
    border: ({borderColor}) => `1px solid ${borderColor}`,
    backgroundColor: ({bgColor}) => bgColor,
  },
  userIcon: {
    backgroundColor: themmeColor.offWhite,
    borderRadius: 32,
    padding: '6px 8px 2px 8px',
    marginRight: 8,
    alignSelf: 'flex-start',
  },
  editButton: {
    backgroundColor: themmeColor.antiqueWhite,
    color: themmeColor.black80,
    borderColor: themmeColor.hitPink,
    fontSize: 13,
    borderRadius: 26,
    '&:hover': {
      backgroundColor: themmeColor.hitPink,
      borderColor: themmeColor.hitPink,
    },
  },
  deleteButton: {
    backgroundColor: themmeColor.linen,
    color: themmeColor.black80,
    borderColor: themmeColor.errorRed,
    fontSize: 13,
    borderRadius: 26,
    '&:hover': {
      backgroundColor: themmeColor.errorRed,
      borderColor: themmeColor.errorRed,
    },
    marginLeft: 12,
  },
  sendButton: {
    backgroundColor: themmeColor.lightGreen,
    borderColor: themmeColor.successGreen,
    color: themmeColor.black80,
    fontSize: 13,
    borderRadius: 26,
    '&:hover': {
      backgroundColor: themmeColor.successGreen,
      borderColor: themmeColor.successGreen,
    },
  },
  editMessage: {
    backgroundColor: themmeColor.antiqueWhite,
    padding: 12,
    borderRadius: 12,
  },
});


const MessageView = ({message, onEdit, onDelete, onSendDraft, editing}: Props) => {
  const styles = useStyles(getMessageStyleProps({message}));
  const messageViewType = getMessageViewType(message);

  const handleClickEdit = () => {
    if (!onEdit) return;
    onEdit(message.id);
  };

  const handleClickDelete = () => {
    if (!onDelete) return;
    onDelete(message.id);
  };

  const handleSendDraft = () => {
    if (!onSendDraft) return;
    onSendDraft(message.id);
  };
  return (
    <div className={editing ? styles.editMessage : undefined}>
      {editing && <Typography>Editing this draft</Typography>}
      <div className={styles.container}>
        {messageViewType === MessageViewType.EndUser && (
          <div className={styles.userIcon}>
            <UserProfile />
          </div>
        )}
        <div className={styles.innerContainer}>
          <div className={styles.authorContainer}>
            <Typography className={styles.authorName}>
              {message.author_name}
            </Typography>
            <Typography className={styles.createTime}>
              {getRelativeDate(getUnixTime(new Date(message.created_at)))}
            </Typography>
          </div>
          <div className={styles.msgContainer}>
            <Typography className={styles.messageText}>
              {ReactHtmlParser(message.message)}
            </Typography>
          </div>
          {messageViewType === MessageViewType.Draft && (
            <Grid container marginTop={1} gridRow={1} columnGap={2} justifyContent={'space-between'}>
              <Grid item>
                <Button
                  disabled={editing}
                  onClick={handleClickEdit}
                  variant={'outlined'}
                  className={styles.editButton}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
                <Button
                  disabled={editing}
                  onClick={handleClickDelete}
                  variant={'outlined'}
                  className={styles.deleteButton}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={editing}
                  onClick={handleSendDraft}
                  variant={'outlined'}
                  className={styles.sendButton}
                  endIcon={<Send />}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageView;
