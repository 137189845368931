import React from 'react';
import {Button, Modal, Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {PictureAsPdf} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

interface ConfirmDocumentUploadProps {
  isOpen: boolean;
  onClose: () => void;
  filesNamesList: Array<any>;
  onSend: () => void;
  loading: boolean;
  confirmationText: string;
  sendButtonText: string;
}

const useStyles = makeStyles({
  container: {
    borderRadius: 16,
    backgroundColor: themmeColor.white,
    padding: 24,
    width: 550,
  },
  filesContainer: {
    borderRadius: 8,
    padding: '9px 7px',
    backgroundColor: themmeColor.offWhite,
  },
  fileDisplay: {
    display: 'inline-flex',
    flexDirection: 'row',
    backgroundColor: themmeColor.white,
    marginBottom: 8,
    height: 36,
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 8,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'space-between',
  },
});

const ConfirmDocumentUpload: React.FC<ConfirmDocumentUploadProps> = ({
  isOpen,
  onClose,
  filesNamesList,
  onSend,
  confirmationText,
  sendButtonText,
  loading,
}) => {
  const styles = useStyles({});

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isOpen}
      onClose={onClose}>
      <div className={styles.container}>
        <Typography fontSize={20} fontWeight={'600'}>
          {confirmationText}
        </Typography>
        <Typography
          fontSize={14}
          color={themmeColor.grey}
          style={{marginTop: 4, marginBottom: 20}}>
          Make sure all the uploaded documents are correct.
        </Typography>
        <div className={styles.filesContainer}>
          {filesNamesList.map((filename) => (
            <div>
              <div className={styles.fileDisplay}>
                <PictureAsPdf
                  style={{marginRight: 10, color: themmeColor.darkBlue}}
                />
                <Typography>{filename}</Typography>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={loading}
            onClick={onClose}
            style={{width: 230}}
            variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={onSend}
            style={{width: 230}}
            variant="contained">
            {sendButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDocumentUpload;
