import React from 'react';
import {themmeColor} from 'src/constants/constants';

export const DashedLine = ({isLast}: {isLast?: boolean}) => {
  return (
    <div style={{marginTop: 8, marginBottom: -20}}>
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          border: `1px solid ${themmeColor.black}`,
          marginRight: 14,
          backgroundColor: themmeColor.black,
        }}
      />
      {!isLast && (
        <div
          style={{
            borderLeft: `1px dashed ${themmeColor.black}`,
            marginLeft: 4,
            height: '100%',
          }}
        />
      )}
    </div>
  );
};
