import React from 'react';
import {useSelector} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import CCHCheckBox from './CCHCheckBox';

interface CCHSectionsProps {
  selectedSections: {[key: string]: string[]};
  setSelectedSections: (newValue: {[key: string]: string[]}) => void;
  isLoading: boolean;
}

const CCHSections = ({
  selectedSections,
  setSelectedSections,
  isLoading,
}: CCHSectionsProps) => {
  const {sections, sectionWiseSubsection, status_info} =
    useSelector(selectCCHStatus);

  const onClickSection = (section: string, toAdd: boolean) => {
    if (toAdd) {
      const targetList = [...sectionWiseSubsection[section]];
      const targetState = {...selectedSections, [section]: targetList};
      setSelectedSections(targetState);
    } else {
      const targetState = {...selectedSections, [section]: []};
      setSelectedSections(targetState);
    }
  };

  return (
    <div>
      <div>
        {sections.map((section) => {
          const isSectionSelected = sectionWiseSubsection[section].every(
            (item) => selectedSections[section]?.includes(item),
          );
          return (
            <Box
              key={section}
              col
              style={{
                marginBottom: 12,
                overflow: 'hidden',
                marginRight: 8,
                paddingBottom: 8,
              }}>
              <CCHCheckBox
                value={section}
                isChecked={isSectionSelected}
                isSubsection={false}
                onClick={() => onClickSection(section, !isSectionSelected)}
                isLoading={isLoading}
              />
              <div style={{paddingLeft: 80, backgroundColor: 'white'}}>
                {sectionWiseSubsection[section].map((subsection) => {
                  return (
                    <CCHCheckBox
                      value={subsection}
                      isSubsection={true}
                      isLoading={isLoading}
                      subsectionStatus={status_info[section][subsection]}
                    />
                  );
                })}
              </div>
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default CCHSections;
