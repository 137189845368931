import React from 'react';
import {APP_FLOWS, RouteName} from 'src/constants/routeName';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import CreateDummyAccount from '../CreateDummyAccount/CreateDummyAccount';
import {Tab, Tabs} from '@mui/material';
import WhitelistPromoCode from '../WhitelistPromoCode/WhitelistPromoCode';
import GetUserDetails from '../GetUserDetails/GetUserDetails';

const TAB_CONFIG = {
  [RouteName.InternalUtilities.CreateProfile]: {
    stgOnly: true,
    Component: CreateDummyAccount,
    title: 'Create Profile',
  },
  [RouteName.InternalUtilities.WhiteListPromoCode]: {
    stgOnly: false,
    Component: WhitelistPromoCode,
    title: 'Whitelist PromoCodes',
  },
  [RouteName.InternalUtilities.GetUserDetails]: {
    stgOnly: false,
    Component: GetUserDetails,
    title: 'Get User Details',
  },
};

const BASE = APP_FLOWS.InternalUtilities;

const UtilityProvider = () => {
  const routes = Object.keys(TAB_CONFIG).filter(
    (route) =>
      process.env.REACT_APP_ENVIRONMENT !== 'production' ||
      !TAB_CONFIG[route].stgOnly,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const routeSplits = location.pathname.split('/');

  const currentTab = routeSplits[routeSplits.length - 1];

  const onClickTab = (route: string) => {
    navigate(`${BASE}/${route}`);
  };

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={(e, v) => onClickTab(v)}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example">
        {routes.map((route) => (
          <Tab value={route} label={TAB_CONFIG[route].title} />
        ))}
      </Tabs>
      <Routes>
        {routes.map((route) => {
          const {Component} = TAB_CONFIG[route];
          return <Route path={route} element={<Component />} />;
        })}
      </Routes>
    </div>
  );
};

export default UtilityProvider;
