import {Autocomplete, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {TAX_EXTENSION_STATUS} from 'src/constants/constants';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentExtensionStatus: TAX_EXTENSION_STATUS | undefined;
  loading: boolean;
  returnTitle: string;
  onUpdateStatus: (extensionStatus: TAX_EXTENSION_STATUS) => void;
}

const UpdateExtensionStatusPopup = ({
  isOpen,
  onClose,
  currentExtensionStatus,
  loading,
  returnTitle,
  onUpdateStatus,
}: Props) => {
  const [selectedStatus, setSelectedStatus] = useState(currentExtensionStatus);

  const handleOnChange = (e: any, v: any) => {
    setSelectedStatus(v);
  };

  return (
    <PopUp
      primaryButtonTitle="Yes, Change it"
      primaryButtonOnClick={() => onUpdateStatus(selectedStatus)}
      primaryButtonDisabled={!selectedStatus || loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 540}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography fontSize={20} fontWeight="600">
        {`Change extension status for ${returnTitle}`}
      </Typography>
      <Autocomplete
        onChange={handleOnChange}
        disablePortal
        id="combo-box-demo"
        options={[TAX_EXTENSION_STATUS.FILED, TAX_EXTENSION_STATUS.REQUEST, TAX_EXTENSION_STATUS.REJECTED]}
        value={selectedStatus}
        fullWidth
        style={{marginTop: 16}}
        sx={{width: 300}}
        renderInput={(params) => (
          <TextField {...params} label="Extension status" />
        )}
      />
    </PopUp>
  );
};

export default UpdateExtensionStatusPopup;
