import React from 'react';
import {Typography} from '@material-ui/core';
import {useGetOne, useGetList, Loading} from 'react-admin';

const ExpenseAside = ({
  defaultTitle,
  data,
  ids,
  filterValues,
  userIdSource,
  setOnbData,
}) => {
  const {
    data: user_data,
    loading: user_loading,
    error: user_error,
  } = useGetOne('users', filterValues[userIdSource]);
  const {
    data: onb_data,
    loading: onb_loading,
    error: onb_error,
  } = useGetList(
    'onb_questions',
    {page: 1, perPage: 50},
    {field: 'question_id', order: 'ASC'},
    {fly_user_id: filterValues[userIdSource]},
  );
  if (user_loading || onb_loading) {
    return <Loading />;
  }
  if (user_error || onb_error) {
    return <p>Unknown user</p>;
  }
  var answerElems = [];
  setOnbData(onb_data);
  for (var key in onb_data) {
    answerElems.push(
      <li>
        <b>{onb_data[key]['question']}</b>{' '}
        <p><b>{JSON.stringify(onb_data[key]['question_label'], null, '  ')}, {JSON.stringify(onb_data[key]['year'], null, '  ')}: </b> {JSON.stringify(onb_data[key]['answer'], null, '  ')}</p>
      </li>,
    );
  }
  let experiment_buckets = <span>No Bucket Info</span>;
  if (user_data.experiment_buckets != null) {
    experiment_buckets = Object.entries(user_data.experiment_buckets).map(
      (ele) => (
        <li>
          {ele[0]}: {ele[1]}
        </li>
      ),
    );
  }
  return (
    <div style={{width: 200, margin: '1em'}}>
      <Typography variant="h6">User info</Typography>
      <Typography variant="body2">User id: {user_data['id']}</Typography>
      <Typography variant="body2">Name: {user_data['name']}</Typography>
      <Typography variant="body2">Email: {user_data['email']}</Typography>
      <Typography variant="body2" width="10px">
        Experiment Buckets:
        <ul>{experiment_buckets}</ul>
      </Typography>
      <Typography variant="body2">
        <ul>{answerElems}</ul>
      </Typography>
    </div>
  );
};

export default ExpenseAside;
