import React, {useMemo} from 'react';
import TaxReturnFieldGridWrapper from './TaxReturnFieldGridWrapper';
import BasicTaxReturnFields from './BasicTaxReturnFields';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import ReturnContainerCard from './ReturnContainerCard';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {ReturnFields, UserRelationType} from '../CreateReturn.types';
import IndividualTaxReturnFields from './IndividualTaxReturnFields';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import BusinessTaxReturnField from './BusinessTaxReturnField';
import {useFormData} from 'src/CpaCenterV2/hooks/useFormData';
import _ from 'lodash';
import {Form} from 'src/DesignSystem/Form/Form';
import DSButton from 'src/DesignSystem/Button/Button';
import {Add} from '@mui/icons-material';

const UserCard = ({
  fields,
  onAddBusiness,
  user,
}: {
  fields: ReturnType<typeof useFormData>['fields'];
  onAddBusiness: () => void;
  user: UserRelationType | null;
}) => {
  const fieldsByPath = useMemo(() => _.keyBy(fields, 'path'), [fields]);

  const bizsField = fieldsByPath[ReturnFields.businesses];

  return (
    <>
      <TaxReturnFieldGridWrapper>
        <BasicTaxReturnFields fields={fields} />
      </TaxReturnFieldGridWrapper>
      <Text
        type={TEXT_TYPES.BASE}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={'Returns'}
        containerStyle={{marginTop: 20, marginBottom: 8}}
      />
      <ReturnContainerCard
        tagName="Individual Return"
        switchComponent={
          <Form
            {...fieldsByPath[ReturnFields.should_file_individual_return]}
          />
        }>
        <IndividualTaxReturnFields
          fieldsDictionary={fieldsByPath}
          user={user}
        />
      </ReturnContainerCard>

      {bizsField.inputType === FORM_INPUT_TYPE.Array ? (
        <div>
          {bizsField.value.map((_, arrayObjectIndex) => {
            return (
              <div style={{marginTop: 20}}>
                <BusinessTaxReturnField
                  key={
                    bizsField.value[arrayObjectIndex][ReturnFields.unique_key]
                  }
                  childProps={bizsField.childProps}
                  arrayObjectIndex={arrayObjectIndex}
                  onChangeValue={bizsField.onChangeValue}
                  array={bizsField.value}
                  user={user}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      <div>
        <DSButton
          type="secondary"
          text="Add business return"
          onClick={onAddBusiness}
          startIcon={<Add />}
          style={{marginTop: 12}}
        />
      </div>
    </>
  );
};

export default UserCard;
