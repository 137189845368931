import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import MessageList from 'src/CpaCenterV2/UserQueries/components/Messages/MessageList';
import Queries from 'src/CpaCenterV2/UserQueries/components/Queries';
import DSButton from 'src/DesignSystem/Button/Button';
import ExternalLinkIcon from 'src/icons/ExternalLinkIcon';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';
import {QuerySubType} from 'src/store/queries/queries.reducer';
import {selectQueriesForCurrReturn} from 'src/store/queries/queries.selector';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {ReduxStateType} from 'src/store/store';

const ReviewAndEsign = ({url}: {url: string | null}) => {
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const {queryId} = useCurrentQueryId();
  const {navigateToReviewEsignQuery, navigateToTaxReturnDetailedView} =
    useCpaCenterV2Navigation();
  const dispatch = useDispatch();
  const {
    spaceLeftBelowTaxReturnSection,
    windowSize: {width: windowWidth},
  } = useLayout();
  const totalWidth = windowWidth - 40;
  const {queries} = useSelector((state: ReduxStateType) =>
    selectQueriesForCurrReturn(state, currentReturn),
  );

  const [messageViewWidth, setMessageViewWidth] = useState(
    queryId ? 0.25 * totalWidth : 0,
  );

  const queriesWidth = 0.25 * totalWidth;

  const updateMessageViewWidth = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.pageX === 0) return;
    const newWidth = totalWidth - e.pageX;
    const maxWidth = 0.5 * totalWidth;
    setMessageViewWidth(Math.min(newWidth, maxWidth));
  };

  const finalSpace = spaceLeftBelowTaxReturnSection - 10;
  const reviewEsignQueries = queries.filter(
    (q) => q.querySubtype === QuerySubType.REVIEW_ESIGN,
  );

  useEffect(() => {
    if (messageViewWidth === 0 && typeof queryId === 'number') {
      setMessageViewWidth(0.25 * totalWidth);
    }
  }, [!queryId, totalWidth, messageViewWidth]);

  const openAddQueriesDrawer = () => {
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab: CreateQueryDrawerTab.TAX_PROFILE,
        selectedDropdown: null,
        currentReturnId: returnId,
        currentReturnType: currentReturn?.return_type,
      }),
    );
  };

  if (!url) {
    return null;
  }

  return (
    <div>
      <div style={{display: 'flex', height: finalSpace}}>
        <div
          style={{
            width:
              totalWidth - queriesWidth - (queryId ? messageViewWidth : 0),
            position: 'relative',
          }}>
          <iframe
            allowTransparency
            key={'review-esign'}
            title="Review-esign-return"
            src={url}
            style={{width: '100%', height: '100%'}}
          />
          <div
            style={{
              padding: 8,
              position: 'absolute',
              right: 0,
              top: 0,
              backgroundColor: themmeColor.grey,
              alignItems: 'center',
              display: 'flex',
            }}>
            <Typography
              style={{
                color: 'white',
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: 13,
              }}>
              Document expiry: {currentReturn.return_expiry.toDateString()}
            </Typography>
          </div>
          <div
            onClick={() =>
              navigateToTaxReturnDetailedView(
                returnId,
                currentReturn?.return_type,
                TaxReturnStatus.REVIEW_ESIGN,
              )
            }
            style={{
              padding: 10,
              position: 'absolute',
              right: 16,
              top: 48,
              backgroundColor: themmeColor.black,
              borderRadius: 50,
              alignItems: 'center',
              display: 'flex',
            }}>
            <ExternalLinkIcon />
          </div>
        </div>
        <div style={{width: queriesWidth, overflowY: 'auto'}}>
          <div
            style={{
              display: 'flex',
              paddingInline: 20,
              paddingBlock: 12,
              justifyContent: 'space-between',
              backgroundColor: themmeColor.white,
              borderBottom: 'solid 1px black',
            }}>
            <Typography style={{fontSize: 20, fontWeight: 800}}>
              User queries
            </Typography>
            <DSButton
              type="secondary"
              text="Add"
              onClick={openAddQueriesDrawer}
            />
          </div>
          <div style={{height: finalSpace}}>
            <Queries
              onClick={(qId) =>
                navigateToReviewEsignQuery(
                  returnId,
                  currentReturn?.return_type,
                  qId,
                )
              }
              queries={reviewEsignQueries}
            />
          </div>
        </div>
        <div
          draggable
          onDrag={updateMessageViewWidth}
          style={{
            width: 10,
            height: finalSpace,
            backgroundColor: themmeColor.black,
            cursor: 'col-resize',
          }}
        />
        <div
          style={{
            width: queryId ? messageViewWidth : 0,
            height: finalSpace,
          }}>
          <MessageList offset={finalSpace} />
        </div>
      </div>
    </div>
  );
};

export default ReviewAndEsign;
