import {copyToClipboard} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import CopyIcon from 'src/icons/CopyIcon';
import {NotificationType} from 'src/store/app/app.reducer';

interface CopyTextProps {
  label: string;
  value: string;
}

const CopyText = ({label, value}: CopyTextProps) => {
  const {notify} = useNotify();

  const onPressCopy = () => {
    copyToClipboard(value, () => notify('Copied', NotificationType.success));
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: themmeColor.offWhite,
        marginTop: 12,
        paddingLeft: 16,
        paddingTop: 10,
        paddingBottom: 10,
      }}>
      <Text
        type={Text.TEXT_TYPES.BASE}
        text={`${label}: `}
        fontWeight={Text.FONT_WEIGHTS.Regular}
      />
      <Text
        type={Text.TEXT_TYPES.BASE}
        text={value}
        fontWeight={Text.FONT_WEIGHTS.SemiBold}
        containerStyle={{marginLeft: 4}}
      />
      <div
        onClick={onPressCopy}
        style={{cursor: 'pointer', marginLeft: 6, paddingTop: 4}}>
        <CopyIcon />
      </div>
    </div>
  );
};

export default CopyText;
