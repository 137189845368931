import React from 'react';

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none">
      <path
        d="M6.2359 12C6.2359 12.4165 6.19539 12.833 6.11437 13.2434L4.51075 21.3665C4.42107 21.8208 4.91079 22.1637 5.29855 21.9182L20.2549 12.448C20.5817 12.2411 20.5817 11.7589 20.2549 11.552L5.29855 2.08183C4.91079 1.8363 4.42107 2.17923 4.51075 2.63349L6.11437 10.7566C6.19539 11.167 6.2359 11.5835 6.2359 12ZM6.2359 12L10.5 12"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default SendIcon;
