import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  BoiFilingCenterRoutesParts,
  BoiFilingCenterRoutes,
} from 'src/constants/routeName';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {NotificationType} from 'src/store/app/app.reducer';
import {selectTaxProfileCpaSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {selectBoiData} from 'src/store/boi/boi.selectors';
import {BOI_FILING_COLUMNS, BOI_SECTION_NAMES, BOI_STATUS} from '../types';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {postTaxProfileReviewStatus, updateBoiUserData} from 'src/appApi';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {isBoiStepComplete} from '../utils';
import {fetchBoiUserData} from 'src/store/boi/boi.actions';

const useBoiSteps = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();

  const {taxProfileCpaSideStatus, loaded: taxProfileStatusLoaded} =
    useSelector(selectTaxProfileCpaSideStatus);
  const {data: boiData, loaded: boiDataLoaded} = useSelector(selectBoiData);

  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    {
      label: 'Document review',
      props: {
        url: BoiFilingCenterRoutes.DocumentReview,
        section: BoiFilingCenterRoutesParts.DocumentReview,
      },
      disabled: false,
      isCompleted:
        taxProfileCpaSideStatus[BOI_SECTION_NAMES.BOI_DOCUMENT_REVIEW] ===
        TaxProfileReviewStatus.COMPLETE,
    },
    {
      label: 'Boi profile review',
      props: {
        url: BoiFilingCenterRoutes.BoiProfileReview,
        section: BoiFilingCenterRoutesParts.ProfileReview,
      },
      disabled: false,
      isCompleted: isBoiStepComplete(
        boiData[BOI_FILING_COLUMNS.STATUS],
        BOI_STATUS.DETAILS_SUBMITTED,
      ),
    },
  ];

  const markStepComplete = async (section: string) => {
    try {
      setIsLoading(true);
      switch (section) {
        case BoiFilingCenterRoutesParts.DocumentReview:
          await postTaxProfileReviewStatus(userId, boiData.year, {
            [BOI_SECTION_NAMES.BOI_DOCUMENT_REVIEW]:
              TaxProfileReviewStatus.COMPLETE,
          });
          await dispatch(
            fetchTaxProfileStatusForGivenYear(userId, boiData.year),
          );
          break;
        case BoiFilingCenterRoutesParts.ProfileReview:
          await updateBoiUserData(boiData.id, {
            status: BOI_STATUS.PREP_STARTED,
          });
          await dispatch(fetchBoiUserData(userId));
          break;
      }
      notify('Status updated successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to update status ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const step = steps.find((step) => pathname.includes(step.props.section));

  let currentLabel;

  if (step) {
    currentLabel = step.label;
  }

  return {
    currentLabel,
    steps,
    markStepComplete,
    isLoading: isLoading || !boiDataLoaded || !taxProfileStatusLoaded,
  };
};

export default useBoiSteps;
