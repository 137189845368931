import React, {useState} from 'react';
import {Button, Input, Modal, TextField, Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {PictureAsPdf} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

interface EnterSignNowIdProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: (signowId: string) => void;
  loading: boolean;
}

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderRadius: 16,
    backgroundColor: themmeColor.white,
    padding: 24,
    width: 550,
  },
  filesContainer: {
    borderRadius: 8,
    padding: '9px 0px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 36,
    justifyContent: 'space-between',
  },
});

const EnterSignNowIdOverlay: React.FC<EnterSignNowIdProps> = ({
  isOpen,
  onClose,
  onSend,
  loading,
}) => {
  const [signowId, setSignowId] = useState('');
  const styles = useStyles({});

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <Typography fontSize={20} fontWeight={'600'}>
          Enter sign now doc id
        </Typography>
        <div className={styles.filesContainer}>
          <TextField
            variant="outlined"
            placeholder="Paste Link Here"
            value={signowId}
            type="text"
            multiline={false}
            onChange={(event) => setSignowId(event.target.value)}
            style={{width: '90%'}}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={loading}
            onClick={onClose}
            style={{width: 230}}
            variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => onSend(signowId)}
            style={{width: 230}}
            variant="contained">
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnterSignNowIdOverlay;
