import React, {useCallback, useMemo, useState} from 'react';
import {TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
  additionalDocumentsList,
  DOCUMENT_TYPES,
  PartnerTaxProfileSectionToSubsectionMapping,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import ContentToggleSwitch from 'src/DesignSystem/ContentToggleSwitch/ContentToggleSwitch';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';
import {selectCreateQueryDrawerStatus} from 'src/store/app/app.selector';
import {fetchQueries} from 'src/store/queries/queries.actions';
import MessageTextBox from '../MessageTextBox/MessageTextBox';
import SimpleArrayField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleArrayField';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {isPartnership} from '../CpaCenterV2.utils';
import {flatten} from 'lodash';

const tabsOptions = [
  CreateQueryDrawerTab.TAX_PROFILE,
  CreateQueryDrawerTab.GENERAL,
  CreateQueryDrawerTab.DOCUMENT,
];

const OTHER_DOCUMENTS = 'Other document';
const OTHER = 'Other';

const TITLE_SPLIT_CHARACTER = ' ';

const getDropdownOptions = (selectedTab: CreateQueryDrawerTab) => {
  const documentDropdownOptions = [
    ...additionalDocumentsList,
    OTHER_DOCUMENTS,
  ];
  const partnerSections = flatten(
    Object.values(
      isPartnership()
        ? PartnerTaxProfileSectionToSubsectionMapping
        : TaxProfileSectionToSubsectionMapping,
    ),
  );
  const taxProfileDropdownOptions = partnerSections;
  const generalDropdownOptions = [...taxProfileDropdownOptions, OTHER];
  switch (selectedTab) {
    case CreateQueryDrawerTab.DOCUMENT:
      return documentDropdownOptions;
    case CreateQueryDrawerTab.TAX_PROFILE:
      return taxProfileDropdownOptions;
    case CreateQueryDrawerTab.GENERAL:
      return generalDropdownOptions;
  }
  return [];
};

const getDropdownPlaceholder = (selectedTab: CreateQueryDrawerTab) => {
  switch (selectedTab) {
    case CreateQueryDrawerTab.DOCUMENT:
      return 'Select Document Type';
    case CreateQueryDrawerTab.TAX_PROFILE:
      return 'Select Tax Profile Section';
    case CreateQueryDrawerTab.GENERAL:
      return 'Select Section';
  }
  return '';
};

const getIsTitleInputVisible = (
  selectedTab: CreateQueryDrawerTab,
  selectedDropdown: string | null,
) => {
  if (
    selectedTab === CreateQueryDrawerTab.DOCUMENT &&
    selectedDropdown === OTHER_DOCUMENTS
  ) {
    return true;
  }
  if (
    selectedTab === CreateQueryDrawerTab.GENERAL &&
    selectedDropdown === OTHER
  ) {
    return true;
  }
  return false;
};

const getTitlePlaceholder = (selectedTab: CreateQueryDrawerTab) => {
  if (selectedTab === CreateQueryDrawerTab.DOCUMENT) {
    return 'e.g. Depreciation schedule (Maximum 3 words)';
  }
  if (selectedTab === CreateQueryDrawerTab.GENERAL) {
    return 'e.g. Business outside USA (Maximum 5 words)';
  }
};

const getIsValidTitle = (
  selectedTab: CreateQueryDrawerTab,
  selectedDropdown: string | null,
  sentence: string,
) => {
  if (!getIsTitleInputVisible(selectedTab, selectedDropdown)) {
    return true;
  }
  if (sentence.trim().length === 0) {
    return false;
  }
  if (selectedTab === CreateQueryDrawerTab.GENERAL) {
    const words = sentence.split(TITLE_SPLIT_CHARACTER);
    return words.length <= 5;
  }
  if (selectedTab === CreateQueryDrawerTab.DOCUMENT) {
    const words = sentence.split(TITLE_SPLIT_CHARACTER);
    return words.length <= 3;
  }
  return true;
};

const CreateQueryDrawer = () => {
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const {notify} = useNotify();
  const {
    isVisible,
    selectedTab,
    selectedDropdown,
    currentReturnType,
    currentReturnId,
  } = useSelector(selectCreateQueryDrawerStatus);
  const {activeYear} = useActiveYear();

  const {taxReturns} = useSelector(selectTaxReturnsReducer);

  const {businesses} = useSelector(selectBusinessDetails);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');

  const updateSelectedTab = (tab: any) => {
    const options = getDropdownOptions(tab);
    let targetDropdown = selectedDropdown;
    if (!options.includes(targetDropdown)) {
      targetDropdown = null;
    }
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab: tab,
        selectedDropdown: targetDropdown,
        currentReturnId,
        currentReturnType,
      }),
    );
  };

  const updateDropDown = (newDropdown: any) => {
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab,
        selectedDropdown: newDropdown,
        currentReturnId,
        currentReturnType,
      }),
    );
  };

  const updateTitle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setTitle(e.target.value);
  };

  const onClose = () => {
    setMessage('');
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: false,
        selectedTab,
        selectedDropdown: null,
        currentReturnId: null,
        currentReturnType: null,
      }),
    );
  };

  const serializeReturn = useCallback((returnType: any, returnId: any) => {
    if (typeof returnType === 'string' && typeof returnId === 'number') {
      return `${returnType}|${returnId}`;
    }
    return null;
  }, []);

  const deserializerReturn = useCallback((identifier: any) => {
    if (typeof identifier === 'string') {
      const [returnType, returnId] = identifier.split('|');
      if (returnType && Number.isInteger(parseInt(returnId))) {
        return {
          returnType,
          returnId: parseInt(returnId),
        };
      }
    }
    return {returnType: null, returnId: null};
  }, []);

  const updateSelectedReturn = (newVal: any) => {
    const {returnType, returnId} = deserializerReturn(newVal);
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab,
        selectedDropdown,
        currentReturnType: returnType,
        currentReturnId: returnId,
      }),
    );
  };

  const selectedReturn = serializeReturn(currentReturnType, currentReturnId);

  const returnsOptions = useMemo(() => {
    return taxReturns.map((taxReturn) =>
      serializeReturn(taxReturn.return_type, taxReturn.return_id),
    );
  }, [taxReturns, serializeReturn]);

  const getHumanReadableValueForReturn = useCallback(
    (option: any) => {
      const {returnType, returnId} = deserializerReturn(option);
      const taxReturn = taxReturns.find(
        (taxReturn) =>
          taxReturn.return_id === returnId &&
          taxReturn.return_type === returnType,
      );
      if (taxReturn) {
        if (returnType === TaxReturnType.INDIVIDUAL) {
          return 'Individual Return';
        } else {
          const business = businesses.find(
            (biz) => biz[BusinessDetailsField.id] === taxReturn.business_id,
          );
          return `Business Return - ${
            business?.[BusinessDetailsField.name]
          } (${business?.[BusinessDetailsField.entity_type]})`;
        }
      }
      return null;
    },
    [deserializerReturn, taxReturns, businesses],
  );

  const getDescription = () => {
    if (selectedTab === CreateQueryDrawerTab.TAX_PROFILE) {
      return 'Please select the field in which you want the user to navigate and resolve the issues.';
    }
    if (selectedTab === CreateQueryDrawerTab.DOCUMENT) {
      return 'Please select the document type you want to request';
    }
    if (selectedTab === CreateQueryDrawerTab.GENERAL) {
      return '<Please Fill me>';
    }
  };

  const {
    createUploadDocumentQuery,
    createTaxProfileQuery,
    createTaxFilingGeneralQuery,
  } = useQueryAction();

  const onCreateQuery = async (isDraft = false) => {
    try {
      setLoading(true);
      if (selectedTab === CreateQueryDrawerTab.DOCUMENT && selectedDropdown) {
        await createUploadDocumentQuery({
          message,
          doc_type:
            selectedDropdown === OTHER_DOCUMENTS
              ? DOCUMENT_TYPES.OTHER
              : selectedDropdown,
          title: selectedDropdown === OTHER_DOCUMENTS ? title : undefined,
          isDraft,
        });
      } else if (
        selectedTab === CreateQueryDrawerTab.TAX_PROFILE &&
        selectedDropdown
      ) {
        await createTaxProfileQuery({
          message,
          taxFilingSection: selectedDropdown,
          isDraft,
        });
      } else if (
        selectedTab === CreateQueryDrawerTab.GENERAL &&
        selectedDropdown
      ) {
        await createTaxFilingGeneralQuery({
          message,
          taxFilingSection:
            selectedDropdown !== OTHER ? selectedDropdown : undefined,
          title: selectedDropdown === OTHER ? title : undefined,
          isDraft,
        });
      }
      onClose();
      await dispatch(fetchQueries({userId, year: activeYear}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={400}
      height={'90vh'}
      isOpen={isVisible}
      direction="left">
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 4,
          padding: 24,
        }}>
        <Typography style={{fontSize: 20, fontWeight: 600}}>
          Action required
        </Typography>
        <div style={{marginBlock: 12}}>
          <ContentToggleSwitch
            value={selectedTab}
            onClick={(newValue: any) => updateSelectedTab(newValue)}
            options={tabsOptions}
            disabled={loading}
          />
        </div>
        {/* Return selection */}
        <div style={{marginBlock: 12}}>
          <Dropdown
            maxWidth
            options={returnsOptions}
            placeholder={'Select return (optional)'}
            onChange={updateSelectedReturn}
            getOptionLabel={getHumanReadableValueForReturn}
            value={selectedReturn}
            disabled={loading}
          />
        </div>
        <div>
          <Typography
            style={{fontSize: 14, color: themmeColor.grey, marginBottom: 8}}>
            {getDescription()}
          </Typography>
          {/* Add Dropdown */}
          <div style={{marginBottom: 12}}>
            <Dropdown
              key={selectedTab}
              maxWidth
              options={getDropdownOptions(selectedTab)}
              placeholder={getDropdownPlaceholder(selectedTab)}
              onChange={(newVal) => updateDropDown(newVal)}
              value={selectedDropdown}
              disabled={loading}
            />
          </div>
          {getIsTitleInputVisible(selectedTab, selectedDropdown) && (
            <TextField
              fullWidth
              variant="outlined"
              onChange={updateTitle}
              label={getTitlePlaceholder(selectedTab)}
              error={!getIsValidTitle(selectedTab, selectedDropdown, title)}
              value={title}
            />
          )}
          <div style={{marginBlock: 12}}>
            <MessageTextBox
              message={message}
              setMessage={setMessage}
              placeholder={
                'Describe the issues properly so that users understand what to do!'
              }
              isLoading={loading}
              height={200}
              onSend={() => onCreateQuery(false)}
              onDraft={() => onCreateQuery(true)}
              inputMode={MessageTextBox.MessageTextBoxInputMode.SEND_MESSAGE}
              sendDisabled={
                !message.length ||
                !selectedDropdown ||
                loading ||
                !getIsValidTitle(selectedTab, selectedDropdown, title)
              }
              draftDisabled={
                !message.length ||
                !selectedDropdown ||
                loading ||
                !getIsValidTitle(selectedTab, selectedDropdown, title)
              }
            />
          </div>
        </div>
        <div
          style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
          <DSButton
            style={{flex: 1}}
            type="secondary"
            text="Close"
            disabled={loading}
            onClick={onClose}
          />
        </div>
        {!getIsValidTitle(selectedTab, selectedDropdown, title) && (
          <SimpleArrayField
            name="Number of words in title"
            value={title.split(TITLE_SPLIT_CHARACTER)}
            style={{color: 'red', marginTop: 12}}
          />
        )}
      </div>
    </Drawer>
  );
};

export default CreateQueryDrawer;
