import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import {RouteParts} from 'src/constants/routeName';
import RightTriangle from 'src/icons/RightTriangle';
import Skeleton from 'src/icons/Skeleton';
import WarningIcon from 'src/icons/WarningIcon';
import {selectDeductionsChangelog} from 'src/store/deductionsChangelog/deductionsChangelog.selector';

interface StepsDataType {
  label: string;
  props?: any;
  disabled?: boolean;
  isCompleted?: boolean;
}

interface StepsProps {
  data: StepsDataType[];
  onClick: (step: StepsDataType) => void;
  isLoading?: boolean;
  currentLabel?: string;
}

const Triangle = ({backgroundColor}: {backgroundColor: string}) => {
  return (
    <div style={{marginRight: -7, display: 'flex', alignItems: 'center'}}>
      <RightTriangle color={backgroundColor} />
    </div>
  );
};

const Steps = ({
  data,
  onClick,
  currentLabel,
  isLoading = false,
}: StepsProps) => {
  const getBackgroundColor = (isCompleted?: boolean, disabled?: boolean) => {
    if (!disabled && !isCompleted) {
      return themmeColor.black;
    }
    if (isCompleted) {
      return themmeColor.darkGreen;
    }
    return themmeColor.offGrey;
  };
  const {changelog} = useSelector(selectDeductionsChangelog);

  if (isLoading) {
    return (
      <div style={{display: 'flex'}}>
        {data.map((step) => (
          <Skeleton
            height={20}
            width={100}
            style={{marginRight: 8}}
            radius={8}
          />
        ))}
      </div>
    );
  }

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', rowGap: 8, height: 24}}>
      {data.map((item, index) => {
        const isFirst = index === 0;
        const isTp = item.props.section === RouteParts.TaxProfileReview;
        let icon = null;
        if (isTp && changelog.length > 0) {
          icon = <WarningIcon height={15} width={15} />;
        }

        const isSelectedStep = currentLabel === item.label;

        return (
          <div
            onClick={() => !item.disabled && onClick(item)}
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: `solid 4px ${
                isSelectedStep ? themmeColor.errorRed : 'transparent'
              }`,
            }}>
            <div
              style={{
                backgroundColor: getBackgroundColor(
                  item.isCompleted,
                  item.disabled,
                ),
                display: 'flex',
                alignItems: 'center',
                marginRight: 14,
              }}>
              {!isFirst && (
                <Triangle backgroundColor={themmeColor.cpaCenterV2Bg} />
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginInline: 16,
                }}>
                <Typography
                  style={{
                    fontSize: 11,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    letterSpacing: 0.7,
                    color:
                      item.isCompleted || !item.disabled
                        ? themmeColor.white
                        : themmeColor.black,
                  }}>
                  {item.label}
                </Typography>
                {icon}
              </div>
              <Triangle
                backgroundColor={getBackgroundColor(
                  item.isCompleted,
                  item.disabled,
                )}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
