import {Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import {BACKEND_RESPONSE_KEYS, USER_ACTIVITY_TYPE} from 'src/CpaCenterList/components/cpaList.utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {patchTaxFiling} from 'src/appApi';

interface ActivityCellProps {
  userId: number;
  activityStatus: USER_ACTIVITY_TYPE;
  taxFilingId: number;
  fetchUserList: () => Promise<any>;
}

const ActivityCell = ({
  activityStatus,
  taxFilingId,
  fetchUserList,
  userId,
}: ActivityCellProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const handleClose = () => setIsOpen(false);
  const handleMenuOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const handleUpdateUserActivityStatus = async (
    newActivity: USER_ACTIVITY_TYPE,
  ) => {
    try {
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.ACTIVITY]: newActivity},
      );
      await fetchUserList();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
    }
  };

  return (
    <div onClick={stopDrilling}>
      <div
        id={`action-assign-div-${userId}`}
        onClick={handleMenuOpen}
        style={{
          display: 'flex',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography>{activityStatus}</Typography>
        <KeyboardArrowDownIcon style={{marginLeft: 8}} />
      </div>
      <Menu
        id="basic-menu"
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': `action-assign-div-${userId}`,
        }}>
        {Object.values(USER_ACTIVITY_TYPE).map((activity) => (
          <MenuItem
            value={activity}
            onClick={() => handleUpdateUserActivityStatus(activity)}>
            {activity}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActivityCell;
