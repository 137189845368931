export enum TEXT_TYPES {
  /**
   * fontSize: themefonts.font72,
   * lineHeight: 88,
   */
  H1 = 'H1',
  /**
   * fontSize: themefonts.font60,
   * lineHeight: 70,
   */
  H2 = 'H2',
  /**
   * fontSize: themefonts.font48,
   * lineHeight: 60,
   */
  H3 = 'H3',
  /**
   * fontSize: themefonts.font36,
   * lineHeight: 48,
   */
  H4 = 'H4',
  /**
   * fontSize: themefonts.font30,
   * lineHeight: 40,
   */
  H5 = 'H5',
  /**
   * fontSize: themefonts.font24,
   * lineHeight: 32,
   */
  H6 = 'H6',
  /**
   * fontSize: themefonts.font20,
   * lineHeight: 28,
   */
  L = 'L',
  /**
   * fontSize: themefonts.font16,
   * lineHeight: 24,
   */
  BASE = 'BASE',
  /**
   * fontSize: themefonts.font13,
   * lineHeight: 20,
   */
  S = 'S',
  /**
   * fontSize: themefonts.font11,
   * lineHeight: 16,
   * fontweight: semibold
   * letter spacing: 0.7
   */
  XS = 'XS',
}

export enum FONT_WEIGHTS {
  Regular = 400,
  SemiBold = 600,
  ExtraBold = 800,
}
