import {useSelector} from 'react-redux';
import {
  TaxProfileSection,
  TaxProfileSectionSequence,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {
  TaxProfileReviewStatus,
  TaxProfileUserSideStatus,
} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import {
  selectTaxProfileCpaSideStatus,
  selectTaxProfileUserSideStatus,
} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {getTaxProfileSectionFromSubsection} from '../common/CpaCenterV2.utils';

const useTaxProfileReview = () => {
  const {taxProfileCpaSideStatus, loaded} = useSelector(
    selectTaxProfileCpaSideStatus,
  );
  const {taxProfileUserSideStatus} = useSelector(
    selectTaxProfileUserSideStatus,
  );

  const isSubSectionVisible = (subsection: TaxProfileSubsection) => {
    const section = getTaxProfileSectionFromSubsection(subsection);

    // Code has been commented since CPA
    // were facing issue with this piece of change

    // if (section === TaxProfileSection.Deductions) {
    //   return (
    //     loaded &&
    //     (subsection === TaxProfileSubsection.AllOtherDeductions ||
    //       ![
    //         TaxProfileUserSideStatus.NOT_APPLICABLE,
    //         TaxProfileUserSideStatus.NOT_INITIATED,
    //       ].includes(
    //         taxProfileUserSideStatus[subsection]?.status ??
    //           TaxProfileUserSideStatus.NOT_APPLICABLE,
    //       ))
    //   );
    // }
    if (section === TaxProfileSection.Income) {
      return (
        loaded &&
        ![
          TaxProfileUserSideStatus.NOT_APPLICABLE,
          TaxProfileUserSideStatus.NOT_INITIATED,
        ].includes(
          taxProfileUserSideStatus[subsection]?.status ??
            TaxProfileUserSideStatus.NOT_APPLICABLE,
        )
      );
    }

    return true;
  };

  const isSubSectionPendingFromUser = (subsection: TaxProfileSubsection) => {
    return (
      loaded &&
      [
        TaxProfileUserSideStatus.PENDING,
        TaxProfileUserSideStatus.REVIEW_PENDING,
      ].includes(
        taxProfileUserSideStatus[subsection]?.status ??
          TaxProfileUserSideStatus.NOT_APPLICABLE,
      )
    );
  };

  const isSubsectionReviewComplete = (subsection: TaxProfileSubsection) => {
    return (
      taxProfileCpaSideStatus[subsection] ===
        TaxProfileReviewStatus.COMPLETE || !isSubSectionVisible(subsection)
    );
  };

  const isSectionReviewPending = (section: TaxProfileSection) => {
    const allSubsections = TaxProfileSectionToSubsectionMapping[section];
    const isPending = allSubsections.some(
      (subsection) => !isSubsectionReviewComplete(subsection),
    );
    return isPending;
  };

  const isTaxProfileReviewComplete = () => {
    const allSections = Object.values(TaxProfileSection);
    if (allSections.some(isSectionReviewPending)) {
      return false;
    }
    return true;
  };

  const getNextSection = (section: TaxProfileSection) => {
    const sectionIndex = TaxProfileSectionSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === TaxProfileSectionSequence.length - 1) {
      return null;
    }
    return TaxProfileSectionSequence[sectionIndex + 1];
  };

  const getPreviousSection = (section: TaxProfileSection) => {
    const sectionIndex = TaxProfileSectionSequence.findIndex(
      (item) => item.section === section,
    );
    if (sectionIndex === 0) {
      return null;
    }
    return TaxProfileSectionSequence[sectionIndex - 1];
  };

  return {
    loaded,
    isSectionReviewPending,
    isSubsectionReviewComplete,
    isTaxProfileReviewComplete,
    getNextSection,
    getPreviousSection,
    isSubSectionVisible,
    isSubSectionPendingFromUser,
  };
};

export default useTaxProfileReview;
