import React from 'react';
import {Chip, Grid, Typography} from '@mui/material';
import {
  CPAQuestionType,
  CPAQuestionTypeName,
  QUERY_STATUS,
  themmeColor,
} from 'src/constants/constants';
import {makeStyles} from '@material-ui/core/styles';
import {BackendQuery} from 'src/types/query.types';
import _ from 'lodash';
import {CurrencyNumberFormat} from 'src/common/utils';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import {useExpenseDetails} from '../hooks/useExpenseDetails';
import DSButton from 'src/DesignSystem/Button/Button';
import {useCpaQueryViewer} from 'src/UserQueries/CpaQueryViewerContext';

type Props = {
  queryData: BackendQuery;
  showStatus?: boolean;
  txnData: Record<number, Transaction>;
};

const getContainerBackgroundColor = (status) => {
  if (status === QUERY_STATUS.OPEN) return themmeColor.lightFlyfinOrange;
  if (status === QUERY_STATUS.RESOLVED) return themmeColor.lightSuccessGreen;
  return themmeColor.lightYellow;
};

const useStyle = makeStyles({
  headerContainer: {
    backgroundColor: ({status}) => getContainerBackgroundColor(status),
    padding: '12px 16px',
  },
  typeText: {
    fontWeight: 'bold',
  },
  dataTitle: {fontSize: 13},
});

const getStatusChipProps = (status) => {
  if (status === QUERY_STATUS.DRAFT) {
    return {color: themmeColor.white, backgroundColor: themmeColor.darkYellow};
  }
  if (status === QUERY_STATUS.OPEN) {
    return {
      color: themmeColor.white,
      backgroundColor: themmeColor.dashboardRed,
    };
  }
  if (status === QUERY_STATUS.RESOLVED) {
    return {
      color: themmeColor.white,
      backgroundColor: themmeColor.resolvedGreen,
    };
  }
  return {color: themmeColor.white, backgroundColor: themmeColor.sentGrey};
};

const QueryChatHeader = ({queryData, showStatus, txnData}: Props) => {
  const {setIsExpenseListDrawer} = useCpaQueryViewer();
  const styles = useStyle({status: queryData.status});
  const queryStatus = queryData.status.replace('_', ' ');
  const {getExpenseMerchantGroupDetails} = useExpenseDetails();

  const getQueryHeaderData = (queryData: BackendQuery) => {
    if (queryData.type === CPAQuestionType.TXN_RELATED) {
      const {merchantName, dateString, totalSum, expensesCount, isNoExpense} =
        getExpenseMerchantGroupDetails(queryData.txn_ids, txnData);
      if (isNoExpense) {
        return [
          {
            title: 'Expense not found',
            value:
              "Linked expense not found, user might've deleted or resetted expenses",
          },
        ];
      }
      return [
        {title: 'Merchant name', value: merchantName},
        {title: 'Date', value: dateString},
        {
          title: 'Transaction type',
          value: `${queryData.txn_category}${queryData.plaid_category}`,
        },
        {
          title: 'Total expense',
          value: `${CurrencyNumberFormat(totalSum)} (${expensesCount})`,
        },
      ];
    }
    return [
      {
        value: '',
      },
    ];
  };
  return (
    <div className={styles.headerContainer}>
      {showStatus && (
        <Chip
          label={queryStatus}
          sx={{...getStatusChipProps(queryData.status), mb: 0.8}}
        />
      )}
      <Typography className={styles.typeText}>
        {queryData.type === CPAQuestionType.TAX_FILING
          ? queryData.query
          : `${CPAQuestionTypeName[queryData.type]} query`}
      </Typography>
      <Grid
        container
        direction="row"
        columnGap={2}
        rowGap={1}
        justifyContent={'space-between'}
        marginTop={0.8}>
        {getQueryHeaderData(queryData).map(({title, value}) => {
          return (
            <Grid item>
              {!!title && (
                <Typography className={styles.dataTitle}>{title}</Typography>
              )}
              {!!value && (
                <Typography style={{fontWeight: 600}}>{value}</Typography>
              )}
            </Grid>
          );
        })}
        {queryData.type === CPAQuestionType.TXN_RELATED && (
          <div>
            <DSButton
              type="secondary"
              text="View all"
              onClick={() => {
                setIsExpenseListDrawer(true);
              }}
              disabled={
                getExpenseMerchantGroupDetails(queryData.txn_ids, txnData)
                  .isNoExpense
              }
            />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default QueryChatHeader;
