import React, {ReactNode} from 'react';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {themmeColor} from 'src/constants/constants';

interface CreateReturnDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: any;
}

const CreateReturnDrawer = ({
  isOpen,
  onClose,
  children,
  title,
}: CreateReturnDrawerProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      direction="up"
      width={'100vw'}
      height={'100vh'}
      style={{display: 'flex', flexDirection: 'column'}}
      title={title}
      onClose={onClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          backgroundColor: themmeColor.cpaCenterV2Bg,
        }}>
        {children}
      </div>
    </Drawer>
  );
};
export default CreateReturnDrawer;
