import React, {useEffect, useState} from 'react';
import {COMMON_COMPONENTS_ID} from 'src/constants/constants';

const useLayout = () => {
  const userSummaryBar = document.getElementById(
    COMMON_COMPONENTS_ID.CPA_CENTER_V2_USER_SUMMARY_BAR,
  );
  const taxFilingSteps = document.getElementById(
    COMMON_COMPONENTS_ID.CPA_CENTER_V2_TAX_FILING_STEPS,
  );
  const messageListStickyFooter = document.getElementById(
    COMMON_COMPONENTS_ID.CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER,
  );
  const taxReturnSectionHeader = document.getElementById(
    COMMON_COMPONENTS_ID.CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER,
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [userSummaryBarYAxisEnd, setUserSummaryBarYAxisEnd] = useState(
    userSummaryBar?.getBoundingClientRect().bottom,
  );

  const [messageListFooterHeight, setMessageListFooterHeight] = useState(250);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!userSummaryBar) return;
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      setUserSummaryBarYAxisEnd(
        userSummaryBar?.getBoundingClientRect().bottom,
      );
    });
    resizeObserver.observe(userSummaryBar);
    return () => resizeObserver.disconnect(); // clean up
  }, [userSummaryBar]);

  useEffect(() => {
    if (!messageListStickyFooter) return;
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      setMessageListFooterHeight(
        messageListStickyFooter?.getBoundingClientRect().height,
      );
    });
    resizeObserver.observe(messageListStickyFooter);
    return () => resizeObserver.disconnect(); // clean up
  }, [messageListStickyFooter]);

  const spaceLeftBelowUserSummaryBar =
    windowSize.height - (userSummaryBarYAxisEnd ?? 0) - window.scrollY;

  const taxFilingStepsBottom = taxFilingSteps?.getBoundingClientRect().bottom;

  const spaceLeftBelowTaxFilingSteps =
    windowSize.height - (taxFilingStepsBottom ?? 0) - window.scrollY;

  const spaceLeftBelowTaxReturnSection =
    windowSize.height -
    (taxReturnSectionHeader?.getBoundingClientRect().bottom ??
      taxFilingStepsBottom ??
      0) -
    window.scrollY;

  const DOCUMENT_BOTTOM_BAR_HEIGHT = 56;
  const DOCUMENTS_ACTION_BAR_HEIGHT = 44;
  const TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT = 56;
  const TAX_PROFILE_SECTION_HEADER_HEIGHT = 64;
  const USER_QUERIES_SECTION_BAR = 72;
  const MESSAGE_STICKY_HEADER_HEIGHT = 72;
  const TAX_RETURN_BOTTOM_BAR = 56;
  const NOTES_SECTION_HEADER_HEIGHT = 48;

  return {
    windowSize,
    spaceLeftBelowUserSummaryBar,
    spaceLeftBelowTaxFilingSteps,
    messageListFooterHeight,
    spaceLeftBelowTaxReturnSection,
    DOCUMENT_BOTTOM_BAR_HEIGHT,
    DOCUMENTS_ACTION_BAR_HEIGHT,
    TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
    TAX_PROFILE_SECTION_HEADER_HEIGHT,
    USER_QUERIES_SECTION_BAR,
    MESSAGE_STICKY_HEADER_HEIGHT,
    TAX_RETURN_BOTTOM_BAR,
    NOTES_SECTION_HEADER_HEIGHT,
  };
};

export default useLayout;
