import {Button} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  LocalStorageKey,
  PROD_ALLOW_USER_DELETION_EMAILS,
  themmeColor,
} from 'src/constants/constants';
import ConfirmDeleteOverlay from './ConfirmDeleteOverlay';
import {useState} from 'react';

const DeleteUserButton = (props: any) => {
  const email = localStorage.getItem(LocalStorageKey.UserEmail);

  const [isOpen, setIsOpen] = useState(false);

  const isDeleteButtonEnabled = () => {
    return (
      process.env.REACT_APP_ENVIRONMENT !== 'production' ||
      PROD_ALLOW_USER_DELETION_EMAILS.includes(email)
    );
  };

  const isDisabled = !isDeleteButtonEnabled();

  return (
    <div>
      <Button
        disabled={isDisabled}
        startIcon={<DeleteIcon />}
        onClick={() => setIsOpen(true)}
        variant={'text'}
        style={{color: isDisabled ? undefined : themmeColor.errorRed}}>
        Delete
      </Button>
      <ConfirmDeleteOverlay key={`${props.record?.id}-${isOpen}`} {...props} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default DeleteUserButton;
