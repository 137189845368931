import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import Box from 'src/DesignSystem/Box/Box';
import Tag from 'src/DesignSystem/Tag/Tag';
import {
  BUSINESS_DETAILS_INPUT_TYPE,
  MultipleShareHolderFieldType,
} from 'src/store/businessDetails/businessDetails.reducer';

const ShareHoldersDetails = ({
  type,
  value,
  name,
}: MultipleShareHolderFieldType) => {
  return (
    <div>
      {value?.map((singleShareHolderField, index) => {
        return (
          <Accordion>
            <AccordionSummary
              style={{backgroundColor: themmeColor.cpaCenterV2Bg}}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Tag
                text={`Share Holder ${index + 1}`}
                textColor={themmeColor.white}
                backgroundColor={themmeColor.grey}
              />
            </AccordionSummary>
            <AccordionDetails>
              {singleShareHolderField.value?.map((field) => {
                if (field.type === BUSINESS_DETAILS_INPUT_TYPE.DEFAULT) {
                  return (
                    <SimpleKeyValueField
                      name={field.name}
                      value={field.value}
                    />
                  );
                }
                if (
                  field.type ===
                  BUSINESS_DETAILS_INPUT_TYPE.SHAREHOLDER_ADDRESS
                ) {
                  return (
                    <Box
                      borderColor={themmeColor.silver}
                      backgroundColor={themmeColor.cpaCenterV2Bg}
                      style={{padding: 12, paddingBottom: 0}}
                      col>
                      <Typography style={{fontWeight: 600, marginBottom: 12}}>
                        {field.name}
                      </Typography>
                      {field.value?.map((addressField) => (
                        <SimpleKeyValueField
                          name={addressField.name}
                          value={addressField.value}
                        />
                      ))}
                    </Box>
                  );
                }
                return null;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default ShareHoldersDetails;
