import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {
  TaxProfileSection,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';

import {TaxProfileSummaryFilterProvider} from './useTaxProfileSummaryFilter';
import RadioDropdown from 'src/DesignSystem/Dropdown/RadioDropdown';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import {selectQuery} from 'src/store/queries/queries.selector';
import {useSelector} from 'react-redux';
import {
  DerivedQueryType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {getTaxProfileSectionFromSubsection} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import {
  GENERAL_SECTION,
  TaxProfileSectionToDefaultSubsectionMap,
  TaxProfileSectionToSubsectionDropdownChoices,
  TaxProfileSectionsDropdownChoices,
  TaxProfileSubsectionToComponentMap,
} from './TaxProfileSingleSection.constants';

const TaxProfileSingleSection = () => {
  const {queryId} = useCurrentQueryId();
  const query = useSelector((state: ReduxQueryType) =>
    selectQuery(state, queryId),
  );
  const [filters, setFilters] = useState<{
    section: TaxProfileSection | typeof GENERAL_SECTION;
    subsection: TaxProfileSubsection | typeof GENERAL_SECTION;
  }>({
    section: GENERAL_SECTION,
    subsection: GENERAL_SECTION,
  });

  const handleChangeSectionAndSubsection = (
    section: TaxProfileSection | typeof GENERAL_SECTION,
    subsection?: TaxProfileSubsection | typeof GENERAL_SECTION,
  ) => {
    let finalSection = [
      ...Object.values(TaxProfileSection),
      GENERAL_SECTION,
    ].includes(section)
      ? section
      : GENERAL_SECTION;
    let finalSubSection = TaxProfileSectionToSubsectionDropdownChoices[
      finalSection
    ].find((option) => option.value === subsection)
      ? subsection
      : TaxProfileSectionToDefaultSubsectionMap[finalSection];

    setFilters({
      section: finalSection,
      subsection: finalSubSection,
    });
  };

  const handleChangeSubsection = (
    subsection: TaxProfileSubsection | typeof GENERAL_SECTION,
  ) => {
    setFilters((prev) => ({
      ...prev,
      subsection: subsection,
    }));
  };

  useEffect(() => {
    if (query) {
      switch (query.derivedQueryType) {
        case DerivedQueryType.GENERAL:
        case DerivedQueryType.REUPLOAD_DOCUMENT:
        case DerivedQueryType.REVIEW_ESIGN:
        case DerivedQueryType.UPLOAD_NEW_DOCUMENT:
          handleChangeSectionAndSubsection(GENERAL_SECTION, GENERAL_SECTION);
          break;
        case DerivedQueryType.TAX_PROFILE_CPA:
        case DerivedQueryType.TAX_PROFILE:
          handleChangeSectionAndSubsection(
            getTaxProfileSectionFromSubsection(query.taxProfileSubsection),
            query.taxProfileSubsection,
          );
          break;
        default:
          handleChangeSectionAndSubsection(GENERAL_SECTION, GENERAL_SECTION);
          break;
      }
    }
  }, [query]);

  return (
    <TaxProfileSummaryFilterProvider>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}>
        <div style={{marginBottom: 10, width: '100%'}}>
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 600,
              color: themmeColor.grey,
              textAlign: 'center',
              marginTop: 16,
            }}>
            USER INFORMATION
          </Typography>
          <div style={{marginTop: 16, flexDirection: 'row'}}>
            <RadioDropdown
              value={filters.section}
              handleChange={handleChangeSectionAndSubsection}
              options={TaxProfileSectionsDropdownChoices}
              style={{marginLeft: 10}}
            />

            {(
              TaxProfileSectionToSubsectionDropdownChoices[
                filters.section ?? ''
              ] ?? []
            ).length > 1 && (
              <RadioDropdown
                value={filters.subsection}
                handleChange={handleChangeSubsection}
                options={
                  TaxProfileSectionToSubsectionDropdownChoices[
                    filters.section ?? ''
                  ] ?? []
                }
                style={{marginLeft: 10}}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: '100%',
            overflowY: 'scroll',
          }}>
          {TaxProfileSubsectionToComponentMap[filters.subsection ?? ''] ?? (
            <></>
          )}
        </div>
      </div>
    </TaxProfileSummaryFilterProvider>
  );
};

export default TaxProfileSingleSection;
