import {Button, Modal, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {ReviewDocStateKeys} from '../ReviewAndEsign.utils';

interface EsignReUpdateInfoProps {
  onClose: () => void;
  isOpen: boolean;
  handleReupdate: (filename: string, docId: string) => void;
  loading: boolean;
  [ReviewDocStateKeys.FILENAME]?: string;
  [ReviewDocStateKeys.SIGNNOW_DOC_ID]?: string;
}

const EsignReUpdateInfo = ({
  onClose,
  isOpen,
  loading,
  handleReupdate,
  ...rest
}: EsignReUpdateInfoProps) => {
  const [filename, setFilename] = useState(rest[ReviewDocStateKeys.FILENAME]);
  const [signNowId, setSignNowId] = useState(
    rest[ReviewDocStateKeys.SIGNNOW_DOC_ID],
  );
  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      onClose={onClose}
      open={isOpen}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          borderRadius: 16,
          padding: 24,
          width: 600,
        }}>
        <div>
          <Typography fontSize={20} fontWeight="600">
            Update E-sign document Info
          </Typography>
          <Typography
            style={{marginBottom: 8}}
            color={themmeColor.grey}
            fontSize={14}>
            Make sure all information is correct
          </Typography>
        </div>
        <div style={{marginTop: 12}}>
          <TextField
            style={{marginBottom: 12}}
            fullWidth
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            placeholder={'Filename'}
            disabled={loading}
          />
          <TextField
            style={{marginBottom: 12}}
            fullWidth
            value={signNowId}
            placeholder={'Signnow Document Id'}
            onChange={(e) => setSignNowId(e.target.value)}
            disabled={loading}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Button
            style={{width: 260, height: 48}}
            variant="outlined"
            disabled={loading}
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            style={{width: 260, height: 48}}
            variant="contained"
            onClick={() => handleReupdate(filename, signNowId)}
            disabled={loading || !filename || !signNowId}>
            Confirm and send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EsignReUpdateInfo;
