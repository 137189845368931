import React from 'react';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {useSelector} from 'react-redux';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {ExpandMore} from '@mui/icons-material';
import Tag from 'src/DesignSystem/Tag/Tag';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {BUSINESS_VEHICLE_USAGE_CHOICES} from '../Deductions.constants';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const BusinessVehicleDetails = () => {
  const {activeYear} = useActiveYear();
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const businessVehicles = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.BUSINESS_VEHICLE,
  );

  const getFields = ({formData}: TaxForm) => {
    const commonFields = [
      {
        name: `Did you use a vehicle for business in ${activeYear}?`,
        value: formData[TaxFormFieldIds.VEHICLE_EXPENSE_TYPE],
      },
      {
        name: `Vehicle make & model`,
        value: formData[TaxFormFieldIds.ITEM_NAME],
      },
      {
        name: `Model year`,
        value: formData[TaxFormFieldIds.MAKE_YEAR],
      },
      {
        name: `Total miles driven in ${activeYear}`,
        value: formData[TaxFormFieldIds.TOTAL_MILES],
      },
      {
        name: `Business miles driven in ${activeYear}`,
        value: formData[TaxFormFieldIds.BUSINESS_MILES],
      },
    ];

    if (
      formData[TaxFormFieldIds.VEHICLE_EXPENSE_TYPE] ===
      BUSINESS_VEHICLE_USAGE_CHOICES.OWN_VEHICLE
    ) {
      return [
        ...commonFields,
        ...[
          {
            name: 'Vehicle purchase date',
            value: formData[TaxFormFieldIds.DATE_OF_PURCHASE],
          },
          {
            name: 'Business-use start date',
            value: formData[TaxFormFieldIds.DATE_PUT_IN_SERVICE],
          },
          {
            name: 'Total vehicle cost',
            value: formData[TaxFormFieldIds.ITEM_VALUE],
          },
        ],
      ];
    }

    if (
      formData[TaxFormFieldIds.VEHICLE_EXPENSE_TYPE] ===
      BUSINESS_VEHICLE_USAGE_CHOICES.LOAN_INTEREST
    ) {
      return [
        ...commonFields,
        ...[
          {
            name: 'Vehicle purchase date',
            value: formData[TaxFormFieldIds.DATE_OF_PURCHASE],
          },
          {
            name: 'Business-use start date',
            value: formData[TaxFormFieldIds.DATE_PUT_IN_SERVICE],
          },
          {
            name: 'Total vehicle cost',
            value: formData[TaxFormFieldIds.ITEM_VALUE],
          },
          {
            name: `Total loan interest paid in ${activeYear}`,
            value: formData[TaxFormFieldIds.INTEREST_PAID],
          },
        ],
      ];
    }
    return commonFields;
  };

  return (
    <SubSectionBox
      name="Business vehicle details"
      taxprofileSubsection={TaxProfileSubsection.BusinessVehicleDetails}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          {businessVehicles.map((tf, index) => {
            const doc = documents.find(({docId}) => tf.docId === docId);
            return (
              <>
                <Accordion>
                  <AccordionSummary
                    style={{backgroundColor: themmeColor.cpaCenterV2Bg}}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Tag
                      text={`Vehicle ${index + 1}`}
                      textColor={themmeColor.white}
                      backgroundColor={themmeColor.grey}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{marginBottom: 12}}>
                      {getFields(tf).map((field) => (
                        <SimpleKeyValueField {...field} />
                      ))}
                      <Typography
                        style={{
                          fontSize: 11,
                          color: themmeColor.black60,
                          marginBottom: 4,
                        }}>
                        Related document
                      </Typography>
                      {doc && (
                        <DocumentPointer doc={doc} dontShowStatus border />
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.BusinessVehicleDetails}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default BusinessVehicleDetails;
