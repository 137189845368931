import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from '../../constants/constants';
import {BankStatementsProps} from '../MissingExpenses.context';

const UploadedStatementCard = ({
  uploadTimeStamp,
  name,
}: BankStatementsProps) => {
  return (
    <div
      style={{
        flex: 1,
        borderRadius: 12,
        backgroundColor: themmeColor.focusedBlue,
        padding: 16,
        marginBottom: 10,
        borderWidth: 2,
        borderColor: themmeColor.darkBlue,
        borderStyle: 'solid',
      }}>
      <Typography color={themmeColor.black60}>
        Uploaded on {uploadTimeStamp}
      </Typography>
      <div
        style={{
          backgroundColor: themmeColor.white,
          padding: '6px 12px',
          borderRadius: 8,
          display: 'inline-flex',
          marginTop: 8,
          borderWidth: 1,
          borderColor: themmeColor.darkBlue,
          borderStyle: 'solid',
        }}>
        <Typography color={themmeColor.black80} fontSize={13}>
          {name}
        </Typography>
      </div>
    </div>
  );
};

export default UploadedStatementCard;
