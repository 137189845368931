import React from 'react';
import {TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

type Props = {
  tableData: Array<any>;
  onClickSortDirection?: (cellId: any) => void;
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
};

const ListTableHeader = ({
  tableData,
  onClickSortDirection,
  orderBy,
  sortDirection,
}: Props) => {
  return (
    <TableHead>
      <TableRow>
        {tableData.map(({id, label, enableSort = false}) => (
          <TableCell
            key={id ?? label}
            sortDirection={id === orderBy ? sortDirection : false}
            style={{
              fontWeight: 'bold',
            }}>
            {enableSort ? (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? sortDirection : undefined}
                onClick={() => onClickSortDirection?.(id)}>
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ListTableHeader;
