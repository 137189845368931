import {Backdrop, CircularProgress} from '@mui/material';

const FullScreenLoading = ({open}: {open: boolean}) => {
  return (
    <Backdrop
      sx={{color: '#000', zIndex: (theme) => theme.zIndex.drawer + 2000}}
      open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullScreenLoading;
