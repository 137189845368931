import React, {useCallback, useEffect} from 'react';
import {ArrayChildProps} from 'src/DesignSystem/Form/common/ArrayField/ArrayField';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import UserCard from './UserCard';
import {selectPartners} from 'src/store/partners/partners.selector';
import {useSelector} from 'react-redux';
import {overWriteCreateReturnFields} from '../CreateReturn.utils';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {BusinessReturnFieldsConfig} from '../CreateReturn.constants';
import {ReturnFields, UserRelationType} from '../CreateReturn.types';
import {uniqueId} from 'lodash';

interface ListEditableUserCardsProps extends ArrayChildProps {
  users: UserRelationType[];
  setValidUsers: React.Dispatch<
    React.SetStateAction<{
      [key: number]: boolean;
    }>
  >;
}

const ListUserCards = ({
  childProps,
  array,
  arrayObjectIndex,
  onChangeValue,
  users,
  setValidUsers,
}: ListEditableUserCardsProps) => {
  const {updateObject, onChangeArrayItem, arrayObject} = useArrayField(
    array,
    onChangeValue,
    arrayObjectIndex,
  );

  const {partners} = useSelector(selectPartners);

  const overWriteFields: OverWriteFormFieldsType = useCallback(
    (props) => {
      return overWriteCreateReturnFields({...props, partners});
    },
    [partners],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: childProps,
    data: arrayObject,
    setData: updateObject,
    overWriteFields,
  });
  const onAddBusiness = () => {
    const updatedBizs = arrayObject[ReturnFields.businesses];
    const emptyBiz = createEmptyFormDataFromFormConfig(
      BusinessReturnFieldsConfig,
      0,
    );
    emptyBiz[ReturnFields.should_file_return] = true;
    emptyBiz[ReturnFields.unique_key] = uniqueId('automatic-biz');
    updatedBizs.push(emptyBiz);
    onChangeArrayItem(
      {...arrayObject, [ReturnFields.businesses]: updatedBizs},
      arrayObjectIndex,
    );
  };

  useEffect(() => {
    setValidUsers((prev) => ({
      ...prev,
      [arrayObjectIndex]: areAllFieldsValid,
    }));
  }, [areAllFieldsValid, arrayObjectIndex]);

  return (
    <UserCard
      fields={fields}
      onAddBusiness={onAddBusiness}
      user={users[arrayObjectIndex]}
    />
  );
};

export default ListUserCards;
