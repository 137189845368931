import React from 'react';
import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Tag from 'src/DesignSystem/Tag/Tag';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {
  BoiFilingCenterRoutesParts,
  BoiFilingCenterRoutes,
  RouteName,
} from 'src/constants/routeName';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useNavigate} from 'react-router-dom';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {BeneficialOwnersForm} from '../types';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';

interface Props {
  index: number;
  data: BeneficialOwnersForm;
  document?: ReduxDocumentType;
}

const BeneficialOwnerCard = ({index, data, document}: Props) => {
  const {userId} = useCurrentUserId();
  const navigate = useNavigate();

  const onDocumentClick = (doc: ReduxDocumentType) => {
    const to =
      `${RouteName.CpaCenterV2}${BoiFilingCenterRoutes.DocumentReview}/${doc.docId}`.replace(
        BoiFilingCenterRoutesParts.UserId,
        `${userId}`,
      );
    navigate(to);
  };

  return (
    <Accordion
      style={{
        backgroundColor: themmeColor.cpaCenterV2Bg,
        margin: 0,
        marginBottom: 20,
      }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Tag
          textColor={themmeColor.white}
          backgroundColor={themmeColor.grey}
          text={`Beneficial Owner ${index + 1}`}
        />
      </AccordionSummary>
      <AccordionDetails>
        <>
          <div>
            <SimpleKeyValueField
              name="Is FinCEN ID present?"
              style={{marginBottom: 12}}
              value={data[TaxFormFieldIds.IS_FINCEN_ID_PRESENT]}
            />
            <SimpleKeyValueField
              name="Individual's last name"
              style={{marginBottom: 12}}
              value={data[TaxFormFieldIds.INDIVIDUAL_LAST_NAME_OR_ENTITY_NAME]}
            />
            {data[TaxFormFieldIds.IS_FINCEN_ID_PRESENT] ? (
              <SimpleKeyValueField
                style={{marginBottom: 12}}
                name="FinCEN ID"
                value={data[TaxFormFieldIds.FINCEN_ID]}
              />
            ) : (
              <>
                <SimpleKeyValueField
                  name="First name"
                  style={{marginBottom: 12}}
                  value={data[TaxFormFieldIds.DEPENDENT_FIRST_NAME]}
                />
                <SimpleKeyValueField
                  name="Date of birth"
                  style={{marginBottom: 12}}
                  value={data[TaxFormFieldIds.DOB]}
                />
                <Divider />
                <div>
                  <Typography
                    style={{
                      color: '#212121',
                      fontSize: 16,
                      marginTop: 12,
                      marginBottom: 12,
                      fontWeight: 600,
                    }}>
                    Current U.S. address
                  </Typography>
                  {data[TaxFormFieldIds.ADDRESS_OUTSIDE_USA] == null ? (
                    <>
                      <SimpleKeyValueField
                        name="Address (number, street, & apt. or suite no.)"
                        style={{marginBottom: 12}}
                        value={
                          data[TaxFormFieldIds.ADDRESS]?.[
                            TaxFormFieldIds.NUMBER_AND_STREET
                          ]
                        }
                      />
                      <SimpleKeyValueField
                        name="City"
                        style={{marginBottom: 12}}
                        value={
                          data[TaxFormFieldIds.ADDRESS]?.[TaxFormFieldIds.CITY]
                        }
                      />
                      <SimpleKeyValueField
                        name="State"
                        style={{marginBottom: 12}}
                        value={
                          data[TaxFormFieldIds.ADDRESS]?.[
                            TaxFormFieldIds.STATE
                          ]
                        }
                      />
                      <SimpleKeyValueField
                        name="Zip code"
                        style={{marginBottom: 12}}
                        value={
                          data[TaxFormFieldIds.ADDRESS]?.[
                            TaxFormFieldIds.ZIP_CODE
                          ]
                        }
                      />
                    </>
                  ) : (
                    <SimpleKeyValueField
                      name="Mailing address outside USA"
                      style={{marginBottom: 12}}
                      value={data[TaxFormFieldIds.ADDRESS_OUTSIDE_USA]}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {document && (
            <div style={{display: 'flex', marginTop: 12}}>
              <DocumentPointer
                style={{flex: 1}}
                doc={document}
                dontShowStatus
                border
                onDocumentClick={() => onDocumentClick(document)}
              />
            </div>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default BeneficialOwnerCard;
