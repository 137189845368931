import {SET_DEDUCTION_SUMMARY} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export enum DeductionAmountSource {
  TXNS = 'TXNS',
  TAX_FORMS = 'TAX_FORMS',
}

interface L2WiseData {
  l2_name: null | string;
  amount: number;
  txn_ids: number[];
  avg_home_pct: number;
  avg_vehicle_pct: number;
  avg_interest_pct: number;
  avg_phone_pct: number;
  avg_internet_pct: number;
  formula: string;
  source: DeductionAmountSource;
  startup_expenses: number;
  total_expenses: number;
}

export interface SchCCategoryWiseData {
  amount: number;
  txn_ids: number[];
  formula: string;
  avg_home_pct: number;
  avg_vehicle_pct: number;
  avg_interest_pct: number;
  avg_phone_pct: number;
  avg_internet_pct: number;
  l2_wise: L2WiseData[];
  category_display_name: string;
  image_url: string;
  source: DeductionAmountSource;
  startup_expenses: number;
  total_expenses: number;
}

interface SchACategoryWiseData {
  category_display_name: string;
  amount: number;
  total_expenses: number;
  txn_ids: number[];
  info: string;
  image_url: string;
  should_show: boolean;
  source: DeductionAmountSource;
}

export interface DeductionSummaryData {
  total_deductions: number;
  business_deductions: number;
  sch_c_category_wise_data: SchCCategoryWiseData[];
  sch_c_category_wise_data_monthly: SchCCategoryWiseData[][];
  sch_c_category_wise_data_map: Record<string, SchCCategoryWiseData>;
  filing_status: string;
  income: number;
  sch_a_deductions: number;
  standard_deductions: number;
  sch_a_category_wise_data: SchACategoryWiseData[];
  agi_75_per: number;
  mortgage_info: string;
  tax_expenses: number;
  tax_deductions: number;
  charity_cap: number;
  sch_1_category_wise_data: SchCCategoryWiseData[];
  sch_3_category_wise_data: SchCCategoryWiseData[];
  biz_start_date_used: string | null;
}

export enum DEDUCTION_TYPE {
  PERSONAL_DEDUCTION = 'sch_a_category_wise_data',
  BUSINESS_DEDUCTION = 'sch_c_category_wise_data',
  BUSINESS_DEDUCTION_MONTHLY = 'sch_c_category_wise_data_monthly',
  SCHEDULE_1_DEDUCTION = 'sch_1_category_wise_data',
  SCHEDULE_1_DEDUCTION_MONTHLY = 'sch_1_category_wise_data_monthly',
  SCHEDULE_3_DEDUCTION = 'sch_3_category_wise_data',
  SCHEDULE_3_DEDUCTION_MONTHLY = 'sch_3_category_wise_data_monthly',
}

export const TAX_DEDUCTION_TYPE = {
  SCH_A: DEDUCTION_TYPE.PERSONAL_DEDUCTION,
  SCH_C: DEDUCTION_TYPE.BUSINESS_DEDUCTION,
  SCH_1: DEDUCTION_TYPE.SCHEDULE_1_DEDUCTION,
  SCH_3: DEDUCTION_TYPE.SCHEDULE_3_DEDUCTION,
} as const;

const initialState: DeductionSummaryData & {
  loaded: boolean;
  status: REDUX_STATUS;
} = {
  total_deductions: -1,
  business_deductions: -1,
  sch_c_category_wise_data: [],
  sch_c_category_wise_data_map: {},
  sch_c_category_wise_data_monthly: [],
  sch_1_category_wise_data: [],
  filing_status: '',
  income: -1,
  sch_a_deductions: -1,
  standard_deductions: -1,
  sch_a_category_wise_data: [],
  sch_3_category_wise_data: [],
  agi_75_per: -1,
  mortgage_info: '',
  tax_expenses: -1,
  tax_deductions: -1,
  charity_cap: -1,
  biz_start_date_used: null,
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const deductionSummary = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_DEDUCTION_SUMMARY:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type DeductionSummaryStateType = typeof initialState;
