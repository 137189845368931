import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {BoiFilingCenterRoutes} from 'src/constants/routeName';
import BoiFilingList from './BoiFilingList';
import BoiFilingUserInfo from './BoiFilingUserInfo';

const BoiFilingCenterRouter = () => {
  return (
    <Routes>
      <Route path={'/'} element={<BoiFilingList />} />
      <Route
        path={`/${BoiFilingCenterRoutes.UserId}/*`}
        element={<BoiFilingUserInfo />}
      />
    </Routes>
  );
};

export default BoiFilingCenterRouter;
