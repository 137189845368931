import React, {ChangeEventHandler, useCallback} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import {isString} from 'lodash';

interface TextFieldProps extends InputWrapperBaseType {
  placeholder?: string;
  onChangeValue: (newText: string) => void;
  value: string | readonly string[] | number | undefined;
  style?: React.CSSProperties;
}

const TextField = ({onChangeValue, ...rest}: TextFieldProps) => {
  const _onChange = useCallback(
    (e: Parameters<ChangeEventHandler<HTMLInputElement>>[0]) => {
      if (onChangeValue) {
        const newText = e.target.value;
        onChangeValue(
          // @ts-ignore
          isString(newText) && newText.length === 0 ? null : newText,
        );
      }
    },
    [onChangeValue],
  );

  return (
    <InputWrapper
      {...rest}
      value={rest.value === null ? '' : rest.value}
      actualValue={rest.value}
      type="text"
      onChange={_onChange}
    />
  );
};

export default TextField;
