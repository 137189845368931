import React, {useState} from 'react';
import UploadDocumentPopup from 'src/CpaCenterV2/common/UploadDocument/UploadDocumentPopup';
import DSButton from 'src/DesignSystem/Button/Button';
import UploadDocumentIcon from 'src/icons/UploadDocumentIcon';

const K1Upload = () => {
  const [uploadPopup, setUploadPopup] = useState(false);
  const handleFileUpload = async () => {};
  return (
    <>
      <DSButton
        style={{marginRight: 8}}
        text="Upload K1"
        type="secondary"
        startIcon={<UploadDocumentIcon />}
        onClick={() => setUploadPopup(true)}
      />
      <UploadDocumentPopup
        isVisible={uploadPopup}
        onClose={() => setUploadPopup(false)}
        handleUploadFile={handleFileUpload}
      />
    </>
  );
};

export default K1Upload;
