export enum CCH_ACTION_BUTTONS {
  BASIC = 'BASIC',
  SCHEDULE_C = 'SCHEDULE_C',
  INCOME = 'INCOME',
  BANK_DETAILS = 'BANK_DETAILS',
}

export enum CCH_SYNC_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export interface IncomeInfo {
  id: number;
  form_type: string;
  payer: string;
  income: number;
  filename: string;
  doc_id: number;
  doc_type_id: number;
  cch_sync_status: CCH_SYNC_STATUS;
}
