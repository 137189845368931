const FractionCell = ({is_actionable = false, num, den}: {is_actionable?: boolean, num: number, den: number}) => {
    return (
      <div style={{
        width: '32px',
        height: '19px',
        borderRadius: '20px',
        backgroundColor: `${is_actionable ? 'red': 'grey'}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <p style={{
          color: 'white',
          fontSize: '8px',  // TODO: 13px is going out for double digit numbers
          margin: 0,
        }}>{num}/{den}</p>
      </div>
    );
  }
  
  export default FractionCell;