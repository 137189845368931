import _ from 'lodash';
import {useCallback} from 'react';

export const useArrayField = (
  valueArray: any,
  onChangeValue: any,
  arrayObjectIndex: number,
) => {
  const onChangeArrayItem = useCallback(
    (updatedArrayObject: any, index: number) => {
      const updatedValue = [...valueArray];
      updatedValue[index] = _.cloneDeep(updatedArrayObject);
      onChangeValue(updatedValue);
    },
    [valueArray, onChangeValue],
  );

  const updateObject = useCallback(
    (getState: any) => {
      const arrayObject = valueArray[arrayObjectIndex];
      // pass old state to get new updated State
      const newState = getState(_.cloneDeep(arrayObject));
      onChangeArrayItem(newState, arrayObjectIndex);
    },
    [valueArray, arrayObjectIndex, onChangeArrayItem],
  );

  const arrayObject = valueArray[arrayObjectIndex];

  return {
    updateObject,
    onChangeArrayItem,
    arrayObject,
  };
};
