import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';

interface BoxProps {
  children?: any;
  style?: React.CSSProperties;
  noBR?: boolean;
  col?: boolean;
  onClick?: () => void;
  backgroundColor?: string;
  hoverEffect?: boolean;
  hoverColor?: string;
  borderColor?: string;
}

const Box = ({
  children,
  noBR,
  style = {},
  col,
  onClick,
  backgroundColor,
  hoverEffect,
  hoverColor,
  borderColor,
}: BoxProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const border: React.CSSProperties = borderColor
    ? {borderStyle: 'solid', borderColor, borderWidth: 1}
    : {};

  const getBgColor = () => {
    if (hoverEffect && isHovering) {
      return hoverColor ?? themmeColor.silver;
    }
    return backgroundColor ?? themmeColor.white;
  };
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        display: 'flex',
        flexDirection: col ? 'column' : 'row',
        backgroundColor: getBgColor(),
        borderRadius: noBR ? 0 : 12,
        ...border,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default Box;
