import {Button, IconButton, Modal, TextField, Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {FileUploader} from 'react-drag-drop-files';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ClearIcon from '@mui/icons-material/Clear';
import {ReviewDocStateKeys} from '../ReviewAndEsign.utils';

interface ReuploadDocOverlayProps {
  onClose: () => void;
  isOpen: boolean;
  handleFileChange: (file: any) => void;
  handleReUpload: () => void;
  loading: boolean;
  updateDocField: (key: ReviewDocStateKeys, value: any) => void;
  newDocInfo: any;
}

const ReuploadDocOverlay = ({
  onClose,
  isOpen,
  handleFileChange,
  handleReUpload,
  loading,
  newDocInfo,
  updateDocField,
}: ReuploadDocOverlayProps) => {
  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      onClose={onClose}
      open={isOpen}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          borderRadius: 16,
          padding: 24,
          width: '80%',
          overflow: 'scroll',
        }}>
        <div>
          <Typography fontSize={20} fontWeight="600">
            Re-upload document
          </Typography>
          <Typography
            style={{marginBottom: 8}}
            color={themmeColor.grey}
            fontSize={14}>
            Make sure the uploaded document is correct.
          </Typography>
        </div>
        {newDocInfo.file ? (
          <div>
            <div
              style={{
                backgroundColor: themmeColor.focusedBlue,
                borderRadius: 8,
                alignItems: 'center',
                display: 'flex',
                borderWidth: 1,
                borderStyle: 'solid',
                justifyContent: 'space-between',
                flex: 1,
                padding: 16,
                borderColor: themmeColor.darkBlue,
              }}>
              <div
                style={{
                  backgroundColor: themmeColor.white,
                  height: 36,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 8,
                }}>
                <PictureAsPdfIcon
                  style={{marginRight: 10, color: themmeColor.darkBlue}}
                />
                <Typography>{newDocInfo.file?.name}</Typography>
                <IconButton
                  onClick={(e) => {
                    handleFileChange(undefined);
                  }}>
                  <ClearIcon style={{color: themmeColor.darkBlue}} />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              {Object.keys(newDocInfo[ReviewDocStateKeys.FEDERAL]).length >
                0 && (
                <div>
                  <Typography>Federal</Typography>
                  {Object.entries(newDocInfo[ReviewDocStateKeys.FEDERAL]).map(
                    ([key, value]) => (
                      <div style={{width: '20vw'}}>
                        <Typography>{key}</Typography>
                        <TextField
                          style={{width: '100%'}}
                          type={'number'}
                          onChange={(e) => {
                            const cloneFederalValues = {
                              ...newDocInfo[ReviewDocStateKeys.FEDERAL],
                            };
                            cloneFederalValues[key] = Number(e.target.value);
                            updateDocField(
                              ReviewDocStateKeys.FEDERAL,
                              cloneFederalValues,
                            );
                          }}
                          value={value}
                        />
                      </div>
                    ),
                  )}
                </div>
              )}
              {Object.entries(newDocInfo[ReviewDocStateKeys.STATE])?.map(
                ([state, stateDetails]: any) => (
                  <div style={{width: '20vw'}}>
                    <Typography>{state} state</Typography>
                    {Object.entries(stateDetails).map(([key, value]) => (
                      <div style={{width: '20vw'}}>
                        <Typography>{key}</Typography>
                        <TextField
                          type={'number'}
                          style={{width: '100%'}}
                          onChange={(e) => {
                            const cloneStateValues = {
                              ...newDocInfo[ReviewDocStateKeys.STATE],
                              [state]: {
                                ...stateDetails,
                                [key]: Number(e.target.value),
                              },
                            };
                            updateDocField(
                              ReviewDocStateKeys.STATE,
                              cloneStateValues,
                            );
                          }}
                          value={value}
                        />
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          </div>
        ) : (
          <div>
            <FileUploader
              multiple={false}
              dropMessageStyle={{backgroundColor: '#00000000'}}
              hoverTitle={
                <h2 color={themmeColor.white}>Drop to upload the file</h2>
              }
              types={['pdf']}
              handleChange={handleFileChange}>
              <div
                style={{
                  backgroundColor: themmeColor.focusedBlue,
                  borderRadius: 8,
                  height: 68,
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'center',
                  display: 'flex',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: themmeColor.darkBlue,
                }}>
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <CloudUploadIcon style={{marginRight: 11}} />
                    <Typography>Drag and Drop</Typography>
                  </div>
                  <div
                    style={{
                      marginLeft: 30,
                      marginRight: 30,
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                    }}>
                    <Typography fontWeight={'500'}>Or</Typography>
                  </div>
                  <div
                    style={{
                      backgroundColor: themmeColor.darkBlue,
                      padding: '9px 12px',
                      display: 'flex',
                      flexDirection: 'row',
                      borderRadius: 8,
                    }}>
                    <AttachFileIcon
                      style={{marginRight: 11, color: themmeColor.white}}
                    />
                    <Typography color={themmeColor.white}>
                      Upload from PC
                    </Typography>
                  </div>
                </div>
              </div>
            </FileUploader>
          </div>
        )}
        <div
          style={{
            marginTop: 36,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Button
            style={{width: 260, height: 48}}
            variant="outlined"
            disabled={loading}
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            style={{width: 260, height: 48}}
            variant="contained"
            onClick={handleReUpload}
            disabled={!newDocInfo.file || loading}>
            Confirm and send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReuploadDocOverlay;
