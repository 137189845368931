import {TextField, Typography} from '@mui/material';
import {RETURN_SUMMARY_CATEGORY, themmeColor} from 'src/constants/constants';
import {ReviewDocStateKeys, ReviewOcrValues} from '../../../ReviewAndEsign.utils';
import {PictureAsPdfOutlined, RefreshOutlined} from '@mui/icons-material';
import {FileUploader} from 'react-drag-drop-files';
import {makeStyles} from '@mui/styles';
import {ReviewAndEsignDocument} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsignContext';

interface OcrAndFileViewProps {
  fileType: string[];
  reviewDoc: ReviewAndEsignDocument;
  setReviewDoc: any;
  handleDocReUpload: (file: any) => void;
  loading?: boolean;
}

const useStyles = makeStyles({
  pdfViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    height: '100%',
    overflow: 'hidden',
  },
  pdfViewHeader: {
    backgroundColor: themmeColor.white,
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    padding: '10px',
  },
  fileName: {
    backgroundColor: themmeColor.white,
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 25px',
    borderRadius: 8,
    border: `1px solid ${themmeColor.offWhite}`,
    marginRight: '20px',
  },
  reUpload: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 8,
    padding: '10px',
    backgroundColor: themmeColor.white,
    border: `1px solid ${themmeColor.darkBlue}`,
  },
  pdfViewContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ocrView: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '30%',
    border: `1px solid ${themmeColor.backgroundOffWhite}`,
  },
  ocrViewHeader: {
    background: themmeColor.offWhite,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    marginBottom: '10px',
  },
  ocrText: {
    borderRadius: '20px',
    background: themmeColor.successGreen,
    padding: '0px 10px',
  },
  ocrViewContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
  },
  ocrItemContainer: {
    background: themmeColor.backgroundOffWhite,
    padding: '10px',
    marginBottom: '10px',
  },
});

const OcrAndFileView = ({
  fileType,
  reviewDoc,
  setReviewDoc,
  handleDocReUpload,
  loading,
}: OcrAndFileViewProps) => {
  const renderOcrItem = (
    title: string,
    reviewDocKey: ReviewDocStateKeys.FEDERAL | ReviewDocStateKeys.STATE,
    ocrValues: ReviewOcrValues | {},
  ) => {
    const onOcrValueChange = (key: string, value: string) => {
      if (reviewDocKey === ReviewDocStateKeys.FEDERAL) {
        const cloneFederalValues: any = {
          ...reviewDoc[reviewDocKey],
          [key]: Number(value),
        };
        setReviewDoc((prevState) => ({
          ...prevState,
          [reviewDocKey]: cloneFederalValues,
        }));
      } else {
        const cloneStateValues: any = {
          ...reviewDoc[reviewDocKey][title],
          [key]: Number(value),
        };
        setReviewDoc((prevState) => ({
          ...prevState,
          [reviewDocKey]: {
            ...reviewDoc[reviewDocKey],
            [title]: cloneStateValues,
          },
        }));
      }
    };

    return (
      <div className={styles.ocrItemContainer}>
        <div style={{marginBottom: '10px'}}>
          <Typography fontWeight={'600'} fontSize={'16px'} fontStyle={'Inter'}>
            {title}
          </Typography>
        </div>
        <div>
          {Object.entries(ocrValues).map(([key, value]) => (
            <div style={{marginBottom: '10px'}}>
              <Typography
                fontWeight={'400'}
                fontSize={'14px'}
                fontStyle={'Inter'}
                marginBottom="5px">
                {RETURN_SUMMARY_CATEGORY.hasOwnProperty(key)
                  ? RETURN_SUMMARY_CATEGORY[key]
                  : key}
              </Typography>
              <TextField
                style={{width: '100%'}}
                type={'number'}
                onChange={(e) => onOcrValueChange(key, e.target.value)}
                onWheel={(e) => e.target.blur()}
                value={value}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const styles = useStyles({});

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
      <div className={styles.pdfViewContainer}>
        <div className={styles.pdfViewHeader}>
          <div className={styles.fileName}>
            <PictureAsPdfOutlined
              style={{color: themmeColor.darkBlue, marginRight: '10px'}}
            />
            <Typography
              fontWeight={'400'}
              fontSize={'15px'}
              fontStyle={'Inter'}>
              {reviewDoc.filename}
            </Typography>
          </div>
          <div>
            <FileUploader
              multiple={false}
              dropMessageStyle={{backgroundColor: '#00000000'}}
              hoverTitle={
                <h2 color={themmeColor.white}>Drop to upload the file</h2>
              }
              types={fileType}
              handleChange={handleDocReUpload}>
              <div className={styles.reUpload}>
                <RefreshOutlined
                  style={{
                    color: themmeColor.darkBlue,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  fontWeight={'400'}
                  fontSize={'15px'}
                  fontStyle={'Inter'}
                  color={themmeColor.darkBlue}>
                  Re-upload
                </Typography>
              </div>
            </FileUploader>
          </div>
        </div>
        <div className={styles.pdfViewContent}>
          <iframe
            width="95%"
            height="95%"
            src={reviewDoc.documentURI}
            title="Review & Esign Doc"
          />
        </div>
      </div>
      <div className={styles.ocrView}>
        <div className={styles.ocrViewHeader}>
          <Typography fontWeight={'600'} fontSize={'17px'} fontStyle={'Inter'}>
            Review Information
          </Typography>
          <div className={styles.ocrText}>
            <Typography
              fontWeight={'600'}
              fontSize={'17px'}
              fontStyle={'Inter'}>
              OCR
            </Typography>
          </div>
        </div>
        <div className={styles.ocrViewContainer}>
          {Object.keys(reviewDoc.federal).length > 0
            ? renderOcrItem(
                'Federal',
                ReviewDocStateKeys.FEDERAL,
                reviewDoc.federal,
              )
            : !loading && <div>No federal return data found!</div>}
          {Object.keys(reviewDoc.state).length > 0 ? (
            <div>
              {Object.entries(reviewDoc.state).map(
                ([state, stateDetails]: any) =>
                  renderOcrItem(state, ReviewDocStateKeys.STATE, stateDetails),
              )}
            </div>
          ) : (
            !loading && <div>No state return data found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OcrAndFileView;
