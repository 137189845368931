import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {ACTION_TYPES} from 'src/CpaCenterList/components/cpaList.utils';

interface ActionCellProps {
  value: ACTION_TYPES;
}

const ACTION_TYPE_TO_LABEL = {
  [ACTION_TYPES.NO]: 'No',
  [ACTION_TYPES.YES]: 'Yes',
};

const ActionCell = ({value}: ActionCellProps) => {
  return (
    <div>
      <Typography
        color={
          value === ACTION_TYPES.YES
            ? themmeColor.errorRed
            : themmeColor.silver
        }
        fontSize={13}
        fontWeight={600}>
        {ACTION_TYPE_TO_LABEL[value]}
      </Typography>
    </div>
  );
};

export default ActionCell;
