import React from 'react';
import {LocalStorageKey, themmeColor} from '../constants/constants';
import {Admin, AppBar, fetchUtils, Layout, Resource} from 'react-admin';
import drfProvider, {history} from '../drfDataProvider';
import {Link} from 'react-router-dom';
import googleAuthProvider from '../authProvider';
import LoginPage from '../LoginPage';
import {UserList} from '../users/UserList';
import ExpenseList from '../expenses/ExpenseList';
import MyExpenseEdit from '../expenses/MyExpenseEdit';
import UnreviewedExpenseList from '../expenses/unreviewed/UnreviewedExpenseList';
import {NewPlaidAccountList} from '../users/NewPlaidAccount';
import TaxFilingStatusEdit from '../tax_engine/TaxFilingStatusEdit';
import TaxProfileEdit from '../tax_engine/TaxProfileEdit';
import CPAQuestionList from '../cpa_questions/CPAQuestionList';
import CPAQuestionEdit from '../cpa_questions/CPAQuestionEdit';
import {APP_FLOWS, RouteName} from '../constants/routeName';
import YearSwitchDropdown from 'src/common/YearSwitchDropdown/YearSwitchDropdown';

const authP = googleAuthProvider(process.env.REACT_APP_DATA_PROVIDER_URL);
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem(LocalStorageKey.Token);
  options.headers.set('Authorization', `Token ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = drfProvider(
  process.env.REACT_APP_DATA_PROVIDER_URL,
  httpClient,
);
const MyAppBar = (props) => {
  return (
    <Layout
      {...props}
      appBar={() => (
        <AppBar
          sx={{
            '& .RaAppBar-title': {
              flex: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          }}
          {...props}>
          <div style={{flexDirection: 'row', flexGrow: 1}} />
          <div
            style={{
              marginRight: 20,
              width: 200,
              backgroundColor: themmeColor.white,
            }}>
            <YearSwitchDropdown />
          </div>
          <Link
            style={{textDecoration: 'none'}}
            to={`${APP_FLOWS.InternalUtilities}/${RouteName.InternalUtilities.CreateProfile}`}>
            <p
              style={{
                marginRight: 10,
                color: 'white',
              }}>
              Utils
            </p>
          </Link>
          <Link style={{textDecoration: 'none'}} to={RouteName.TaxFiling}>
            <p
              style={{
                marginRight: 10,
                color: 'grey',
              }}>
              Cpa-Center
            </p>
          </Link>
          <Link
            style={{textDecoration: 'none'}}
            to={`${RouteName.CpaCenterV2}${RouteName.TaxFiling}`}>
            <p
              style={{
                marginRight: 10,
                color: 'white',
                fontWeight: '600',
              }}>
              Cpa-Center-2
            </p>
          </Link>
        </AppBar>
      )}
    />
  );
};
const ReactAdmin = () => {
  return (
    <Admin
      history={history}
      layout={MyAppBar}
      loginPage={(props) => <LoginPage {...props} isReactAdmin />}
      authProvider={authP}
      dataProvider={dataProvider}>
      <Resource name="users" list={UserList} />
      <Resource name="expenses" list={ExpenseList} edit={MyExpenseEdit} />
      <Resource
        name="unreviewedexpenses"
        list={UnreviewedExpenseList}
        options={{label: 'Unreviewed'}}
      />
      <Resource
        name="users_new_plaid_account"
        list={NewPlaidAccountList}
        options={{label: 'New PlaidAccounts'}}
      />
      <Resource name="plaid_account" />
      <Resource name="notification" />
      {/* <Resource name="plaid_item" /> */}
      <Resource name="plaid_txn" />
      <Resource name="categorizer" />
      <Resource name="onb_questions" />
      <Resource name="conversation" />
      <Resource name="app_mirroring" />
      <Resource name="export_data" />
      <Resource name="tax_filing_status" edit={TaxFilingStatusEdit} />
      <Resource name="tax_profile" edit={TaxProfileEdit} />
      <Resource
        name="cpa_question"
        list={CPAQuestionList}
        edit={CPAQuestionEdit}
        options={{label: 'CPA Questions'}}
      />
    </Admin>
  );
};

export default ReactAdmin;
