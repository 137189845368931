import {Typography} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import {useExpenseDetails} from 'src/common/hooks/useExpenseDetails';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';

interface MerchantSectionHeaderProps {
  expenses: Transaction[];
}

const MerchantSectionHeader = ({expenses}: MerchantSectionHeaderProps) => {
  const {getExpenseMerchantGroupDetails} = useExpenseDetails();
  const txnData = _.keyBy(expenses, 'txnId');
  const txn_ids = expenses.map((e) => e.txnId);
  const {merchantName, expensesCount, dateString, totalSum} =
    getExpenseMerchantGroupDetails(txn_ids, txnData);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBlock: 12,
        paddingInline: 16,
        backgroundColor: '#F1F1F1',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderColor: themmeColor.offWhite,
        borderWidth: 1,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{fontWeight: 600}}>{merchantName}</Typography>
        <Typography style={{fontWeight: 600}}>
          {CurrencyNumberFormat(totalSum)}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          style={{fontSize: 12}}>{`${expensesCount} expenses`}</Typography>
        <Typography style={{fontSize: 12}}>{dateString}</Typography>
      </div>
    </div>
  );
};

export default MerchantSectionHeader;
