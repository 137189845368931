import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessIncome from './SingleBusinessIncome';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import { BusinessDetailsField } from 'src/store/businessDetails/businessDetails.types';

const PartnerBusinessIncome = () => {
  const {businesses} = useSelector(selectBusinessDetails);
  return (
    <SubSectionBox
      name="Business"
      taxprofileSubsection={TaxProfileSubsection.BusinessIncome}>
      {businesses.map((biz, index) => (
        <DSAccordion key={biz[BusinessDetailsField.id]} defaultExpanded name={`Business ${index + 1}`}>
          <SingleBusinessIncome business={biz} />
        </DSAccordion>
      ))}
    </SubSectionBox>
  );
};

export default PartnerBusinessIncome;
