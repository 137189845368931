import {SET_APP_STATE} from '../actionNames';
import {TaxFormType} from '../taxForms/taxForms.types';
import {
  AppReducerStates,
  AppReducerType,
  NotificationType,
} from './app.reducer';

export const setAppState = (path: AppReducerStates, value: any) => {
  // Uncomment below for stronger error
  // if (
  //   path === AppReducerStates.notifcationStatus &&
  //   value?.type === NotificationType.error &&
  //   value?.isVisible
  // ) {
  //   alert(value?.message);
  // }
  return {
    type: SET_APP_STATE,
    payload: {
      path,
      value,
    },
  };
};

export const setHighValueDeductionsDrawer = (isVisible: boolean) => {
  return (dispatch: any) => {
    dispatch(
      setAppState(AppReducerStates.highValueDeductionsDrawer, isVisible),
    );
  };
};

export const setReuploadDocumentQueryDrawer = (
  payload: AppReducerType[AppReducerStates.reuploadDocumentQueryDrawer],
) => {
  return setAppState(AppReducerStates.reuploadDocumentQueryDrawer, payload);
};

export const setDistributeDeductionsQueryDrawer = (
  payload: AppReducerType[AppReducerStates.distributeDeductionsQueryDrawer],
) => {
  return setAppState(
    AppReducerStates.distributeDeductionsQueryDrawer,
    payload,
  );
};
export const setFileExtensionDetailsDrawer = (isVisible: boolean) => {
  return setAppState(AppReducerStates.fileExtensionDetailsDrawer, isVisible);
};

export const setIpPinDetailsDrawer = (isVisible: boolean) => {
  return setAppState(AppReducerStates.ipPinDetailsDrawer, isVisible);
};
export const setChildCareExpenseDrawer = (
  isVisible: boolean,
  formId = -1,
  title = '',
) => {
  return setAppState(AppReducerStates.childCareExpenseDrawer, {
    isVisible,
    formId,
    title,
  });
};

export const setTaxFormDrawer = ({
  isVisible,
  taxformId,
}: {
  isVisible: boolean;
  taxformId: number;
}) => {
  return setAppState(AppReducerStates.taxformDrawer, {
    isVisible,
    taxformId,
  });
};

export const setMessageAttachmentDrawer = ({
  isVisible,
  unsigned_link,
  filename,
}: {
  isVisible: boolean;
  unsigned_link: string | null;
  filename: string;
}) => {
  return setAppState(AppReducerStates.messageAttachmentDrawer, {
    isVisible,
    unsigned_link,
    filename,
  });
};

export const setEditTaxProfileQuestions = ({
  pathPrefixes,
  title,
}: {
  pathPrefixes: string[];
  title: string;
}) => {
  return setAppState(AppReducerStates.editTaxProfileQuestions, {
    title,
    pathPrefixes,
  });
};

export const setEditTaxForm = (props: {
  isVisible: boolean;
  taxFormId: number | null;
  taxFormType: TaxFormType | null;
  title: string;
  prefilledFormData?: any;
  multi_tax_forms_entity_id?: number;
  filterFields?: (field: any) => boolean;
  onCommit?: () => void;
}) => {
  return setAppState(AppReducerStates.editTaxForm, {
    ...props,
    prefilledFormData: props.prefilledFormData ?? {},
  });
};

export const setStatesInfoDrawer = (props: {
  isVisible: boolean;
  taxFormId: null | number;
  isDefault: boolean;
  isSpouse: boolean;
}) => {
  return setAppState(AppReducerStates.statesInfoDrawer, props);
};
