import {SET_DEDUCTIONS_CHANGELOG} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

export interface DeductionChangelog {
  name: string;
  amount_difference: number;
}

const initState = {
  changelog: [] as DeductionChangelog[],
  last_sync_at: new Date(),
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const deductionsChangelog = (
  state = initState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_DEDUCTIONS_CHANGELOG: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }

  return state;
};

export type DeductionsChangelogStateType = typeof initState;
