import {getUserDocuments} from 'src/appApi';
import {SET_DOCUMENTS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {ReduxDocumentType} from './documents.reducer';

const setDocuments = (documents: ReduxDocumentType[]) => {
  return {
    type: SET_DOCUMENTS,
    payload: {documents},
  };
};

export const fetchDocumentsForGivenYear = (userId: number, year: number) => {
  return async (dispatch) => {
    try {
      const {data: documentTypes} = await getUserDocuments({
        user: userId,
        year: year,
      });
      const documents: ReduxDocumentType[] = [];

      documentTypes.forms.forEach((documentType) => {
        const {
          doc_type: docType,
          id: docTypeId,
          status: docTypeStatus,
          links,
          year,
          ocr_required: ocrRequired,
        } = documentType;
        links.forEach((doc) => {
          const {
            id: docId,
            filename,
            status: docStatus,
            doc_link: docLink,
            extras,
          } = doc;
          const document: ReduxDocumentType = {
            docType,
            docTypeId,
            docTypeStatus,
            year,
            filename,
            docStatus,
            docLink,
            extras,
            docId,
            ocrRequired,
          };
          documents.push(document);
        });
      });
      dispatch(setDocuments(documents));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch documents (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
