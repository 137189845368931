import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ViewDocumentContext from 'src/CpaCenterV2/ViewDocument/ViewDocument.context';
import RenderDocument from 'src/CpaCenterV2/ViewDocument/components/RenderDocument';
import RenderOcrData from 'src/CpaCenterV2/ViewDocument/components/RenderOcrData';
import useViewDocument from 'src/CpaCenterV2/ViewDocument/useViewDocument';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import Tag from 'src/DesignSystem/Tag/Tag';
import {themmeColor} from 'src/constants/constants';
import BackIcon from 'src/icons/BackIcon';
import Skeleton from 'src/icons/Skeleton';
import {selectBoiData} from 'src/store/boi/boi.selectors';

const ViewDocumentBoi = () => {
  const navigate = useNavigate();

  const {data: boiData} = useSelector(selectBoiData);
  const value = useViewDocument({
    year: boiData.year,
  });
  const {document, documentsLoading} = value;

  const {spaceLeftBelowTaxFilingSteps, DOCUMENTS_ACTION_BAR_HEIGHT} =
    useLayout();

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <ViewDocumentContext.Provider value={value}>
      <div>
        {documentsLoading ? (
          <div style={{display: 'flex', marginTop: 8, alignItems: 'center'}}>
            <Skeleton style={{marginRight: 20}} width={36} height={36} />
            <Skeleton style={{marginRight: 20}} width={100} height={20} />
            <Skeleton width={100} height={20} />
          </div>
        ) : (
          <div
            style={{
              height: DOCUMENTS_ACTION_BAR_HEIGHT,
              display: 'flex',
              marginTop: 8,
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingInline: 12,
            }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div
                onClick={onClickBack}
                style={{
                  backgroundColor: themmeColor.black,
                  width: 36,
                  height: 36,
                  borderRadius: 100,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <BackIcon color={themmeColor.white} />
              </div>
              <div style={{display: 'flex', marginLeft: 12}}>
                <Tag fontSize={20} text={document.docType} />
              </div>
              <Typography
                style={{fontSize: 20, fontWeight: 800, marginLeft: 8}}>
                {document.filename}
              </Typography>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            height: spaceLeftBelowTaxFilingSteps - DOCUMENTS_ACTION_BAR_HEIGHT,
          }}>
          <div style={{width: '70vw'}}>
            <RenderDocument />
          </div>
          <div style={{width: '29vw', overflowY: 'auto'}}>
            <RenderOcrData />
          </div>
        </div>
      </div>
    </ViewDocumentContext.Provider>
  );
};

export default ViewDocumentBoi;
