import React from 'react';

const IgnoredIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <circle cx="10" cy="10" r="10" fill="#E1E1E1" />
      <path
        d="M14.2857 5.80087C13.1967 4.68954 11.6789 4 10 4C6.68629 4 4 6.68629 4 10C4 11.6789 4.68954 13.1967 5.80087 14.2857M14.2857 5.80087C15.3462 6.88305 16 8.36517 16 10C16 13.3137 13.3137 16 10 16C8.36517 16 6.88305 15.3462 5.80087 14.2857M14.2857 5.80087L5.80087 14.2857"
        stroke="#121212"
        stroke-width="2"
      />
    </svg>
  );
};

export default IgnoredIcon;
