import {getDeductionsChangelog} from 'src/appApi';
import {SET_DEDUCTIONS_CHANGELOG} from '../actionNames';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {setAppState} from '../app/app.actions';

const setDeductionsChangelog = (payload: any) => {
  return {
    type: SET_DEDUCTIONS_CHANGELOG,
    payload,
  };
};

export const fetchDeductionsChangelog = (
  fly_user_id: number,
  year: number,
) => {
  return async (dispatch: any) => {
    try {
      const response = await getDeductionsChangelog(fly_user_id, year);
      await dispatch(setDeductionsChangelog(response.data));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch deduction changelog (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
