import {Typography} from '@mui/material';
import React, {ReactNode, useState} from 'react';
import {useDispatch} from 'react-redux';
import {postTaxProfileReviewStatus} from 'src/appApi';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useTaxProfileReview from 'src/CpaCenterV2/hooks/useTaxProfileReview';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import QuestionIcon from 'src/icons/QuestionIcon';
import TickIcon from 'src/icons/TickIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';
import useCreateTaxProfileQuery from './useCreateTaxProfileQuery';
import SectionPendingWarning from 'src/CpaCenterV2/common/SectionPendingWarning/SectionPendingWarning';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from 'src/CpaCenterV2/TaxProfileSections/common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {useSearchParams} from 'react-router-dom';
import {USER_QUERIES_URL_SEARCH_PARAMS} from 'src/CpaCenterV2/UserQueries/UserQueries.types';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';

interface SubSectionBoxProps {
  name: string;
  querieNotAllowed?: boolean;
  taxprofileSubsection: TaxProfileSubsection;
  children?: any;
  width?: any;
  style?: React.CSSProperties;
  customSectionActions?: ReactNode | ReactNode[];
}

const SubSectionBox = ({
  name,
  querieNotAllowed = false,
  children,
  width,
  style,
  taxprofileSubsection,
  customSectionActions = null,
}: SubSectionBoxProps) => {
  const {activeYear} = useActiveYear();
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {notify} = useNotify();
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const [searchParams] = useSearchParams();

  const currentReturnId = searchParams.get(
    USER_QUERIES_URL_SEARCH_PARAMS.RETURN_ID,
  )
    ? Number(searchParams.get(USER_QUERIES_URL_SEARCH_PARAMS.RETURN_ID))
    : null;
  const currentReturnType = searchParams.get(
    USER_QUERIES_URL_SEARCH_PARAMS.RETURN_TYPE,
  ) as TaxReturnType;

  const {openCreateQueryDrawer} = useCreateTaxProfileQuery({
    currentReturnId,
    currentReturnType,
  });

  const {
    loaded,
    isSubsectionReviewComplete,
    isSubSectionPendingFromUser,
    isSubSectionVisible,
  } = useTaxProfileReview();

  const [isLoading, setIsLoading] = useState(false);

  const isComplete = isSubsectionReviewComplete(taxprofileSubsection);

  const onClickDone = async () => {
    try {
      setIsLoading(true);
      await postTaxProfileReviewStatus(userId, activeYear, {
        [taxprofileSubsection]: TaxProfileReviewStatus.COMPLETE,
      });
      await dispatch(fetchTaxProfileStatusForGivenYear(userId, activeYear));
      notify(
        `Review for subsection ${taxprofileSubsection} complete`,
        NotificationType.success,
      );
    } catch (e) {
      notify(`Error in marking step complete ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSectionStatus = () => {
    if (isComplete) {
      return <TickIcon />;
    }

    return (
      <DSButton
        disabled={!loaded || isLoading}
        text="Done"
        type="secondary"
        onClick={onClickDone}
      />
    );
  };

  const showRaiseQueriesIcon =
    !querieNotAllowed &&
    summaryVariant === TaxProfileSummaryVariant.DEFAULT &&
    isHovering;

  if (!isSubSectionVisible(taxprofileSubsection)) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        borderRadius: 12,
        backgroundColor: themmeColor.white,
        margin: 16,
        width,
        ...style,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
          height: 36,
        }}>
        <Typography style={{fontSize: 16, fontWeight: 600}}>{name}</Typography>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {customSectionActions}
          {showRaiseQueriesIcon && (
            <div
              onClick={() => openCreateQueryDrawer(taxprofileSubsection)}
              style={{
                display: 'flex',
                height: 36,
                width: 64,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 40,
                backgroundColor: themmeColor.cpaCenterV2Bg,
                marginRight: 4,
              }}>
              <QuestionIcon />
            </div>
          )}
          {summaryVariant === TaxProfileSummaryVariant.DEFAULT &&
            getSectionStatus()}
        </div>
      </div>
      {isSubSectionPendingFromUser(taxprofileSubsection) && (
        <SectionPendingWarning />
      )}
      {children}
    </div>
  );
};

export default SubSectionBox;
