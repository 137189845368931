import {Typography} from '@mui/material';
import {format, parseISO} from 'date-fns';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {
  CLASSIFICATION_STATUS,
  DATE_FORMATS,
  themmeColor,
} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';
import FilledCrossIcon from 'src/icons/FilledCrossIcon';
import QuestionIcon from 'src/icons/QuestionIcon';
import TickIcon from 'src/icons/TickIcon';
import {Transaction} from 'src/store/transactions/transactions.reducer';

interface MerchantClassifiedTransactionItemProps {
  expense: Transaction;
}

const MerchantClassifiedTransactionItem = ({
  expense,
}: MerchantClassifiedTransactionItemProps) => {
  const txnDate = parseISO(expense.txnDate);
  const dateString = format(txnDate, DATE_FORMATS.DISPLAY_FORMAT);

  const getIcon = () => {
    if (expense.classificationStatus === CLASSIFICATION_STATUS.DEDUCTION) {
      return <TickIcon />;
    }
    if (expense.classificationStatus === CLASSIFICATION_STATUS.NON_DEDUCTION) {
      return <FilledCrossIcon />;
    }
    return <QuestionIcon />;
  };
  return (
    <div
      style={{
        paddingBlock: 12,
        paddingInline: 16,
        paddingLeft: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderStyle: 'solid',
        borderColor: themmeColor.offWhite,
        borderWidth: 1,
        borderTopWidth: 0,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div style={{marginRight: 4}}>{getIcon()}</div>
        <Typography>{dateString}</Typography>
      </div>
      <Typography>{CurrencyNumberFormat(expense.currentAmount)}</Typography>
    </div>
  );
};

export default MerchantClassifiedTransactionItem;
