import {Thread, ThreadMessage} from './threads.reducer';

export const parseSingleMessage: (message: ThreadMessage) => ThreadMessage = (
  message,
) => {
  return {
    ...message,
    created_at: new Date(message.created_at),
  };
};

export const parseSingleThread: (thread: Thread) => Thread = (thread) => {
  return {
    ...thread,
    created_at: new Date(thread.created_at),
    messages: thread.messages.map(parseSingleMessage),
  };
};
