import {TaxProfileSubsection} from 'src/constants/constants';

import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.types';

export const INCOME_SOURCES = {
  Freelance: 'Freelancing',
  W2: 'W2 income',
  Interest: 'Income from interest',
  Dividend: 'Income from dividends',
  Investment: 'Capital gains',
  Gambling: 'Gambling',
  Goverment: 'Government benefits',
  Retirement: 'Retirement',
  HSA: 'HSA',
  RENTAL_INCOME: 'Rental Income',
  DIGITAL_ASSETS_INCOME: 'Digital assets income',
  K1_Income: 'k1_income',
};

export const TaxFormTypeToFieldsMap: {
  [key in TaxFormType]?: {field: TaxFormFieldIds; operation: 1 | -1}[];
} = {
  [TaxFormType.W2_FORM]: [
    {field: TaxFormFieldIds.WAGES_TIPS_ETC, operation: 1},
  ],
  [TaxFormType.FORM_1099_NEC]: [
    {field: TaxFormFieldIds.NON_EMPLOYEE_COMPENSATION, operation: 1},
  ],
  [TaxFormType.FORM_1099_MISC]: [
    {field: TaxFormFieldIds.RENTS, operation: 1},
    {field: TaxFormFieldIds.ROYALTIES, operation: 1},
    {field: TaxFormFieldIds.OTHER_INCOME, operation: 1},
  ],
  [TaxFormType.FORM_1099_K]: [
    {field: TaxFormFieldIds.GROSS_AMOUNT_PAYMENT_CARD, operation: 1},
  ],
  [TaxFormType.FORM_1099_INT]: [
    {field: TaxFormFieldIds.INTEREST_INCOME, operation: 1},
    {field: TaxFormFieldIds.US_BOND_INTEREST, operation: 1},
  ],
  [TaxFormType.FORM_1099_G]: [
    {field: TaxFormFieldIds.UNEMPLOYMENT_COMPENSATION, operation: 1},
    {field: TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET, operation: 1},
    {field: TaxFormFieldIds.STATE_TAX_REFUND_CREDIT_OFFSET, operation: 1},
    {field: TaxFormFieldIds.RTAA_PAYMENTS, operation: 1},
    {field: TaxFormFieldIds.TAXABLE_GRANTS, operation: 1},
    {field: TaxFormFieldIds.AGRICULTURE_PAYMENTS, operation: 1},
    {field: TaxFormFieldIds.MARKET_GAIN, operation: 1},
  ],
  [TaxFormType.FORM_1099_R]: [
    {field: TaxFormFieldIds.GROSS_DISTRIBUTION, operation: 1},
  ],
  [TaxFormType.FORM_1099_DIV]: [
    {field: TaxFormFieldIds.TOTAL_ORDINARY_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.TOTAL_CAPITAL_GAIN, operation: 1},
    {field: TaxFormFieldIds.SECTION_1202_GAIN, operation: 1},
    {field: TaxFormFieldIds.SECTION_897_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.SEC_1250_GAIN, operation: 1},
    {field: TaxFormFieldIds.COLLECTIBLES_GAIN, operation: 1},
    {field: TaxFormFieldIds.SECTION_897_CAPITAL_GAIN, operation: 1},
    {field: TaxFormFieldIds.NON_DIVIDEND_DISTRIBUTIONS, operation: 1},
    {field: TaxFormFieldIds.SECTION_199A_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.CASH_LIQUIDATION_DISTRIBUTIONS, operation: 1},
    {field: TaxFormFieldIds.NON_CASH_LIQUIDATION_DISTRIBUTIONS, operation: 1},
    {field: TaxFormFieldIds.PRIVATE_ACTIVITY_BOND_INTEREST, operation: 1},
  ],
  [TaxFormType.FORM_1099_B]: [
    {field: TaxFormFieldIds.SHORT_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.SHORT_TERM_NET_LOSS, operation: -1},
    {field: TaxFormFieldIds.LONG_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.LONG_TERM_NET_LOSS, operation: -1},
    {field: TaxFormFieldIds.UNDETERMINED_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.UNDETERMINED_NET_LOSS, operation: -1},
  ],
  [TaxFormType.FORM_1099_SA]: [
    {field: TaxFormFieldIds.GROSS_DISTRIBUTION, operation: 1},
  ],
  [TaxFormType.W2_G_FORM]: [
    {field: TaxFormFieldIds.WINNING_REPORTABLE, operation: 1},
    {field: TaxFormFieldIds.WINNINGS_FROM_IDENTICAL_WAGERS, operation: 1},
  ],
  [TaxFormType.MANUAL_FREELANCE_INCOME_FORM]: [
    {field: TaxFormFieldIds.AMOUNT, operation: 1},
  ],
  [TaxFormType.RENTAL_INCOME]: [
    {field: TaxFormFieldIds.INCOME_EARNED, operation: 1},
  ],
  [TaxFormType.FORM_K1_1065]: [
    {field: TaxFormFieldIds.ORDINARY_BUSINESS_INCOME, operation: 1},
    {field: TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME, operation: 1},
    {field: TaxFormFieldIds.ORDINARY_BUSINESS_LOSS, operation: -1},
    {field: TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS, operation: -1},
    {field: TaxFormFieldIds.INTEREST_INCOME, operation: 1},
    {field: TaxFormFieldIds.QUALIFIED_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.ORDINARY_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.SHORT_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.LONG_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.SHORT_TERM_NET_LOSS, operation: -1},
    {field: TaxFormFieldIds.LONG_TERM_NET_LOSS, operation: -1},
  ],
  [TaxFormType.FORM_K1_1120S]: [
    {field: TaxFormFieldIds.ORDINARY_BUSINESS_INCOME, operation: 1},
    {field: TaxFormFieldIds.RENTAL_REAL_ESTATE_INCOME, operation: 1},
    {field: TaxFormFieldIds.ORDINARY_BUSINESS_LOSS, operation: -1},
    {field: TaxFormFieldIds.RENTAL_REAL_ESTATE_LOSS, operation: -1},
    {field: TaxFormFieldIds.INTEREST_INCOME, operation: 1},
    {field: TaxFormFieldIds.QUALIFIED_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.ORDINARY_DIVIDENDS, operation: 1},
    {field: TaxFormFieldIds.SHORT_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.LONG_TERM_NET_GAIN, operation: 1},
    {field: TaxFormFieldIds.SHORT_TERM_NET_LOSS, operation: -1},
    {field: TaxFormFieldIds.LONG_TERM_NET_LOSS, operation: -1},
  ],
};

export const TaxFormTypeToPayerNameMap = {
  [TaxFormType.FORM_1099_MISC]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_NEC]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_K]: TaxFormFieldIds.FILER_NAME,
  [TaxFormType.W2_FORM]: TaxFormFieldIds.EMPLOYER_NAME,
  [TaxFormType.FORM_1099_INT]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_DIV]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_B]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.W2_G_FORM]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_G]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_R]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_1099_SA]: TaxFormFieldIds.TRUSTEE_NAME,
  [TaxFormType.MANUAL_FREELANCE_INCOME_FORM]: TaxFormFieldIds.PAYER_NAME,
  [TaxFormType.FORM_K1_1065]: TaxFormFieldIds.PARTNERSHIP_NAME,
  [TaxFormType.FORM_K1_1120S]: TaxFormFieldIds.CORPORATION_NAME,
};

export const IncomeSubsectionToTaxFormTypes = {
  [TaxProfileSubsection.Freelance]: [
    TaxFormType.FORM_1099_NEC,
    TaxFormType.FORM_1099_MISC,
    TaxFormType.FORM_1099_K,
    TaxFormType.MANUAL_FREELANCE_INCOME_FORM,
  ],
  [TaxProfileSubsection.W2Income]: [TaxFormType.W2_FORM],
  [TaxProfileSubsection.Interest]: [TaxFormType.FORM_1099_INT],
  [TaxProfileSubsection.Dividend]: [TaxFormType.FORM_1099_DIV],
  [TaxProfileSubsection.Investment]: [TaxFormType.FORM_1099_B],
  [TaxProfileSubsection.Gambling]: [TaxFormType.W2_G_FORM],
  [TaxProfileSubsection.Government]: [TaxFormType.FORM_1099_G],
  [TaxProfileSubsection.Retirement]: [TaxFormType.FORM_1099_R],
  [TaxProfileSubsection.HSA]: [TaxFormType.FORM_1099_SA],
  [TaxProfileSubsection.RentalIncome]: [TaxFormType.RENTAL_INCOME],
  [TaxProfileSubsection.K1_INCOME]: [
    TaxFormType.FORM_K1_1065,
    TaxFormType.FORM_K1_1120S,
  ],
} as const;

export const mapTaxProfileSectionToIncomeSource = {
  [TaxProfileSubsection.Freelance]: INCOME_SOURCES.Freelance,
  [TaxProfileSubsection.W2Income]: INCOME_SOURCES.W2,
  [TaxProfileSubsection.Interest]: INCOME_SOURCES.Interest,
  [TaxProfileSubsection.Dividend]: INCOME_SOURCES.Dividend,
  [TaxProfileSubsection.Investment]: INCOME_SOURCES.Investment,
  [TaxProfileSubsection.Gambling]: INCOME_SOURCES.Gambling,
  [TaxProfileSubsection.Government]: INCOME_SOURCES.Goverment,
  [TaxProfileSubsection.Retirement]: INCOME_SOURCES.Retirement,
  [TaxProfileSubsection.HSA]: INCOME_SOURCES.HSA,
  [TaxProfileSubsection.RentalIncome]: INCOME_SOURCES.RENTAL_INCOME,
  [TaxProfileSubsection.DigitalAssetIncome]:
    INCOME_SOURCES.DIGITAL_ASSETS_INCOME,
  [TaxProfileSubsection.K1_INCOME]: INCOME_SOURCES.K1_Income,
};

let map = {};
const sections = Object.keys(IncomeSubsectionToTaxFormTypes);
sections.forEach((key) => {
  const types = IncomeSubsectionToTaxFormTypes[key];
  types.forEach((value) => {
    map[value] = key;
  });
});

export const FormTypeToTaxProfileSubsection = map;

export const FORM_1099_MISC_TYPES = {
  FREELANCE: 'FREELANCE',
  RENTAL: 'RENTAL',
} as const;

export enum INCOME_EARNER_VALUES {
  MYSELF = 'MYSELF',
  MY_SPOUSE = 'MY_SPOUSE',
  BUSINESS = 'BUSINESS',
}
