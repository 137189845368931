import {BackendBusinessTaxReturnInfo} from 'src/appApi.types';
import {SET_TAX_PROFILE_STATES_INFO} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export enum StateReturnType {
  RESIDENT = 'RESIDENT',
  PART_YEAR_RESIDENT = 'PART_YEAR_RESIDENT',
  NOT_RESIDENT = 'NOT_RESIDENT',
  TAX_FILING_NOT_NEEDED = 'TAX_FILING_NOT_NEEDED',
  PARTNERSHIP_RETURN = 'PARTNERSHIP_RETURN',
  C_CORP_RETURN = 'C_CORP_RETURN',
  S_CORP_RETURN = 'S_CORP_RETURN',
  RESIDENT_PARTNER = 'RESIDENT_PARTNER',
}

export interface StatesInfo {
  state: string;
  return_type: StateReturnType;
  minimum_days?: number;
  related_income_forms: RelatedIncomeForm[];
  state_img_url: string;
  lived_count?: number;
  is_current_address: boolean;
  is_city_tax_filing_maybe_needed: boolean;
}

export interface RelatedIncomeForm {
  tax_form_id: number;
  filename: string;
  doc_link: string;
  form_type: string;
  doc_id: number;
}

const initialState = {
  statesLivedInfo: [] as StatesInfo[],
  businessStateReturnInfo: [] as BackendBusinessTaxReturnInfo[],
  isDrivingLicenseRequired: false,
  status: REDUX_STATUS.INIT,
  loaded: false,
};

export const taxProfileStatesInfo = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_TAX_PROFILE_STATES_INFO: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type TaxProfileStateInfoStateType = typeof initialState;
