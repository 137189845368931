import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import TaxProfileSteps from './components/TaxProfileSteps';
import ActionRequired from '../common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import DSButton from 'src/DesignSystem/Button/Button';
import SendDraftQueriesFinalPopup from './components/SendDraftQueriesFinalPopup';
import useCurrentStep from '../hooks/useCurrentStep';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {
  EXTENSION_DEADLINE,
  FILED_TAX_EXTENSION_ANSWER,
  TAX_EXTENSION_STATUS,
  TAX_FILING_STATUS,
} from 'src/constants/constants';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import useCurrentUserId from '../hooks/useCurrentUserId';
import {useDispatch, useSelector} from 'react-redux';
import useTaxProfileReview from '../hooks/useTaxProfileReview';
import CompletedRibbon from '../common/CompletedRibbon/CompletedRibbon';
import useLayout from '../hooks/useLayout';
import Box from 'src/DesignSystem/Box/Box';
import {selectUser} from 'src/store/user/user.selector';
import {ArrowForwardIos} from '@mui/icons-material';
import {setFileExtensionDetailsDrawer} from 'src/store/app/app.actions';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {ReduxStateType} from 'src/store/store';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const TaxProfileReview = () => {
  const {activeYear} = useActiveYear();
  const {cpaFacingRetStatus, isLoading, markStepComplete} = useCurrentStep();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const [isDraftQueryPopup, setIsDraftQueryPopup] = useState(false);
  const {isTaxProfileReviewComplete} = useTaxProfileReview();
  const {spaceLeftBelowTaxFilingSteps, TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT} =
    useLayout();
  const isReviewCompleteEnabled =
    isTaxProfileReviewComplete() &&
    !isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.USER_QUERIES_SENT,
    ) &&
    !isLoading;
  const {tax_extension_status: taxExtensionStatus} = useSelector(selectUser);
  const hasFiledExtension = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.FILED_TAX_EXTENSION,
      activeYear,
    ),
  );

  useEffect(() => {
    dispatch(fetchTaxProfileStatusForGivenYear(userId, activeYear));
  }, [activeYear]);

  const openSendDraftQueryPopup = () => {
    setIsDraftQueryPopup(true);
  };

  const closePopup = () => {
    setIsDraftQueryPopup(false);
  };

  const onConfirm = async () => {
    await markStepComplete(TAX_FILING_STATUS.USER_QUERIES_SENT);
    closePopup();
  };

  const isPostExtensionDeadline = new Date() > EXTENSION_DEADLINE;

  const getExtensionStatusText = () => {
    if (isPostExtensionDeadline) {
      if (taxExtensionStatus === TAX_EXTENSION_STATUS.FILED) {
        return 'Extension status: Filed';
      }

      if (hasFiledExtension === FILED_TAX_EXTENSION_ANSWER.YES) {
        return 'Extension Filed: Yes';
      }

      if (hasFiledExtension === FILED_TAX_EXTENSION_ANSWER.NO) {
        return 'Extension Filed: No';
      }

      return 'Extension Filed: NA';
    } else {
      return 'Extension Details - Submitted';
    }
  };

  return (
    <div style={{paddingLeft: 4}}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height:
            spaceLeftBelowTaxFilingSteps -
            TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
        }}>
        <div style={{width: '55vw', paddingRight: 4}}>
          <Typography style={{fontSize: 20, fontWeight: 800, marginBlock: 12}}>
            Tax Profile Review
          </Typography>
          {(isPostExtensionDeadline ||
            [
              TAX_EXTENSION_STATUS.FILED,
              TAX_EXTENSION_STATUS.REQUEST,
            ].includes(taxExtensionStatus)) && (
            <Box
              style={{
                marginBottom: 28,
                padding: '14px 20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={
                isPostExtensionDeadline
                  ? undefined
                  : () => dispatch(setFileExtensionDetailsDrawer(true))
              }
              borderColor="#F0F0F0">
              <Typography style={{fontSize: 16, fontWeight: 600}}>
                {getExtensionStatusText()}
              </Typography>
              {!isPostExtensionDeadline && <ArrowForwardIos />}
            </Box>
          )}
          <TaxProfileSteps />
        </div>
        <div style={{width: '44vw', paddingInline: 12}}>
          <ActionRequired
            types={[
              CreateQueryDrawerTab.TAX_PROFILE,
              CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
              CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
            ]}
          />
        </div>
      </div>
      {isCpaCenterV2StepComplete(
        cpaFacingRetStatus,
        TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
      ) ? (
        <CompletedRibbon
          height={TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT}
          text="Tax profile review is complete"
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingInline: 40,
            alignItems: 'center',
            height: TAX_PROFILE_REVIEW_BOTTOM_BAR_HEIGHT,
          }}>
          <Typography>
            Once you have reviewed all the sections you will be able to proceed
            to next section
          </Typography>
          <DSButton
            disabled={!isReviewCompleteEnabled}
            style={{width: 400, height: 40}}
            text="Review complete"
            onClick={openSendDraftQueryPopup}
            type="primary"
          />
        </div>
      )}
      <SendDraftQueriesFinalPopup
        isOpen={isDraftQueryPopup}
        onClose={closePopup}
        onConfirm={onConfirm}
        disabled={isLoading}
      />
    </div>
  );
};

export default TaxProfileReview;
