import React, {useState, useMemo, useEffect} from 'react';
import {useCreateReturn} from './useCreateReturn';
import CreateReturnDrawer from './common/CreateReturnDrawer';
import {
  CREATE_RETURN_STATUS,
  ReturnFields,
  UserRelationType,
} from './CreateReturn.types';
import DrawerWhiteContainer from './common/DrawerWhiteContainer';
import _ from 'lodash';
import UserRelationContent from './UserRelationContent';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {MultipleUsersFieldConfig} from './CreateReturn.constants';

import CreateReturnFromDocContent from './common/CreateReturnFromDocContent';
import AssignDocument from './common/AssignDocument';

const CreateReturnFromDocDrawer = () => {
  const {status, setStatus} = useCreateReturn();
  const isOpen = [
    CREATE_RETURN_STATUS.CONFIRM_USER_RELATION,
    CREATE_RETURN_STATUS.CREATE_RETURNS_FROM_DOC,
    CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS,
    CREATE_RETURN_STATUS.ASSIGN_DOCUMENTS,
    CREATE_RETURN_STATUS.ASSIGNING_DOCUMENTS,
  ].includes(status);

  const isCreateReturns = [
    CREATE_RETURN_STATUS.CREATE_RETURNS_FROM_DOC,
    CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS,
  ].includes(status);

  const isAssignDocuments = [
    CREATE_RETURN_STATUS.ASSIGN_DOCUMENTS,
    CREATE_RETURN_STATUS.ASSIGNING_DOCUMENTS,
  ].includes(status);

  const [userDetailsForm, setUserDetailsForm] = useState<{
    [ReturnFields.users]: UserRelationType[];
  }>(() => createEmptyFormDataFromFormConfig(MultipleUsersFieldConfig, 0));

  return (
    <CreateReturnDrawer
      isOpen={isOpen}
      onClose={() => setStatus(CREATE_RETURN_STATUS.INIT)}
      title={
        status === CREATE_RETURN_STATUS.CONFIRM_USER_RELATION
          ? 'Confirm user relations'
          : isCreateReturns
          ? 'Create Returns'
          : isAssignDocuments
          ? 'Assign documents'
          : 'Unknown'
      }>
      <DrawerWhiteContainer
        style={{
          width: isAssignDocuments ? '90%' : '50%',
        }}>
        {status === CREATE_RETURN_STATUS.CONFIRM_USER_RELATION ? (
          <UserRelationContent setUserDetailsForm={setUserDetailsForm} />
        ) : null}
        {isCreateReturns ? (
          <CreateReturnFromDocContent
            userDetailsForm={userDetailsForm}
            setUserDetailsForm={setUserDetailsForm}
          />
        ) : null}
        {isAssignDocuments ? <AssignDocument /> : null}
      </DrawerWhiteContainer>
    </CreateReturnDrawer>
  );
};

export default CreateReturnFromDocDrawer;
