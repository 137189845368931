import {Button, Modal, Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';

interface CpaAssignConfirmOverlayProps {
  isVisible: boolean;
  handleClose: () => void;
  onContinue: () => void;
  loading: boolean;
}

const CpaAssignConfirmOverlay = ({
  isVisible,
  handleClose,
  onContinue,
  loading,
}: CpaAssignConfirmOverlayProps) => {
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isVisible}
      onClose={handleClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 450,
          padding: 24,
          borderRadius: 16,
          backgroundColor: themmeColor.white,
        }}>
        <Typography fontWeight={600} fontSize={20}>
          Do you want to change the CPA assigned?
        </Typography>
        <Typography style={{marginTop: 4, marginBottom: 24}}>
          A CPA has been already assigned ot the user, do you want to change
          the assignment?
        </Typography>
        <div
          style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleClose}
            style={{flex: 0.45}}>
            No, Go back
          </Button>
          <Button
            variant="contained"
            onClick={onContinue}
            disabled={loading}
            style={{flex: 0.45}}>
            Yes, Change CPA
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CpaAssignConfirmOverlay;
