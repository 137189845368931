import React, {useState} from 'react';
import {
  Autocomplete,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import {ALL_DOCS_TYPE, themmeColor} from 'src/constants/constants';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Document} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';
import {updateDocType} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface DocumentTypeSwitchOverlayProps {
  isVisible: boolean;
  handleClose: () => void;
  document: Document;
  onSuccess: () => void | Promise<any>;
  flyfin_user_id: string | number | undefined;
}

const DocumentTypeSwitchOverlay = ({
  isVisible,
  handleClose,
  document,
  onSuccess,
  flyfin_user_id,
}: DocumentTypeSwitchOverlayProps) => {
  const {activeYear} = useActiveYear();
  const [selectedDocType, setSelectectedDocType] = useState({
    label: document?.documentTypeName,
    docType: document?.documentTypeName,
  });

  const [loading, setLoading] = useState(false);

  const handleDocTypeUpdate = (value: any) => {
    setSelectectedDocType(value);
  };

  const handleDocUpdate = async () => {
    // TODO: make api call here
    setLoading(true);
    try {
      await updateDocType({
        tfd_info_id: document.documentId,
        fly_user_id: flyfin_user_id ?? '',
        year: activeYear,
        doc_type: selectedDocType.docType,
      });
      handleClose();
      await onSuccess();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isVisible}
      onClose={handleClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 540,
          padding: 24,
          borderRadius: 16,
          backgroundColor: themmeColor.white,
        }}>
        <Typography fontWeight={600} fontSize={20}>
          Change document type
        </Typography>
        <Typography
          color={themmeColor.grey}
          fontSize={14}
          style={{marginTop: 12, marginBottom: 16}}>
          Please select the destination where you want to move the following
          document.
        </Typography>
        <div
          style={{
            display: 'flex',
            borderColor: themmeColor.offWhite,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            alignItems: 'center',
            padding: '9px 12px',
          }}>
          <PictureAsPdfIcon />
          <Typography style={{marginLeft: 4}}>{document.filename}</Typography>
        </div>
        <Autocomplete
          style={{marginTop: 20, width: 530}}
          onChange={(e, v) => handleDocTypeUpdate(v)}
          // @ts-ignore
          value={selectedDocType?.label}
          id=""
          options={ALL_DOCS_TYPE.map((docType) => ({
            docType,
            label: docType,
          }))}
          sx={{width: 200}}
          renderInput={(params: any) => (
            <TextField {...params} label="Document type" />
          )}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            marginTop: 24,
          }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={loading}
            style={{flex: 0.45}}>
            No
          </Button>
          <Button
            disabled={
              selectedDocType.docType === document.documentTypeName || loading
            }
            onClick={handleDocUpdate}
            variant="contained"
            style={{flex: 0.45}}>
            Yes, Move
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentTypeSwitchOverlay;
