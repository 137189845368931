import React from 'react';

const ErrorIcon = ({color = '#FF4B4B'}: {color: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 6.29687C12.5523 6.29687 13 6.74459 13 7.29687V12.7029C13 13.2552 12.5523 13.7029 12 13.7029C11.4477 13.7029 11 13.2552 11 12.7029V7.29687C11 6.74459 11.4477 6.29687 12 6.29687ZM12 15.4531C11.3096 15.4531 10.75 16.0128 10.75 16.7031C10.75 17.3935 11.3096 17.9531 12 17.9531H12.0122C12.7026 17.9531 13.2622 17.3935 13.2622 16.7031C13.2622 16.0128 12.7026 15.4531 12.0122 15.4531H12Z"
        fill={color}
      />
    </svg>
  );
};

export default ErrorIcon;
