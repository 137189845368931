import {Add} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
import _ from 'lodash';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import DSButton from 'src/DesignSystem/Button/Button';
import Table from 'src/DesignSystem/Table/Table';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {CurrencyNumberFormat, getPath} from 'src/common/utils';
import {TaxProfileSubsection} from 'src/constants/constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  TaxForm,
  TaxFormFieldIds,
  TaxFormType,
} from 'src/store/taxForms/taxForms.types';
import EditExistingPayment from './EditExistingPayment';

const PaymentsTaxFormColumns: GridColDef[] = [
  {
    field: TaxFormFieldIds.PAYMENT_TYPE,
    headerName: 'Payment type',
    valueGetter: (params) => _.get(params.row, `formData.${params.field}`),
    width: 300,
  },
  {
    field: TaxFormFieldIds.PAYMENT_DATE,
    headerName: 'Payment date',
    valueGetter: (params) => _.get(params.row, `formData.${params.field}`),
    width: 150,
  },
  {
    field: TaxFormFieldIds.STATE,
    headerName: 'State',
    valueGetter: (params) => _.get(params.row, `formData.${params.field}`),
    width: 150,
  },
  {
    field: TaxFormFieldIds.AMOUNT,
    headerName: 'Amount',
    valueGetter: (params) => _.get(params.row, `formData.${params.field}`),
    valueFormatter: (params) => CurrencyNumberFormat(params.value),
    width: 150,
  },
  {
    field: '_',
    headerName: 'Edit',
    renderCell: EditExistingPayment,
  },
];

const PartnerTaxPaymentsTable = () => {
  const {onAddTaxForm} = usePartnerTaxProfileReview();

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const paymentForms = useMemo(() => {
    return taxForms.filter(
      (tf) => tf.formType === TaxFormType.QUARTERLY_TAX_PAYMENTS,
    ) as TaxForm<TaxFormType.QUARTERLY_TAX_PAYMENTS>[];
  }, [taxForms]);

  return (
    <SubSectionBox
      name="Quarterly Tax payments"
      taxprofileSubsection={TaxProfileSubsection.Payments}
      customSectionActions={
        <DSButton
          type="secondary"
          onClick={() => {
            onAddTaxForm({
              taxFormType: TaxFormType.QUARTERLY_TAX_PAYMENTS,
              title: 'Add quarterly payment',
            });
          }}
          text="Add"
          endIcon={<Add />}
          style={{marginRight: 8}}
        />
      }>
      <Table
        getRowId={(row) => row.taxFormId}
        columns={PaymentsTaxFormColumns}
        rows={paymentForms}
        autoHeight={true}
      />
    </SubSectionBox>
  );
};

export default PartnerTaxPaymentsTable;
