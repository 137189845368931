import React from 'react';
import useLayout from '../hooks/useLayout';
import DocumentActions from './components/DocumentActions';
import RenderDocument from './components/RenderDocument';
import RenderOcrData from './components/RenderOcrData/RenderOcrData';
import useViewDocument from './useViewDocument';
import ViewDocumentContext from './ViewDocument.context';

const ViewDocument = () => {
  const value = useViewDocument({});

  const {spaceLeftBelowTaxFilingSteps, DOCUMENTS_ACTION_BAR_HEIGHT} =
    useLayout();

  return (
    <ViewDocumentContext.Provider value={value}>
      <DocumentActions />
      <div
        style={{
          display: 'flex',
          height: spaceLeftBelowTaxFilingSteps - DOCUMENTS_ACTION_BAR_HEIGHT,
        }}>
        <div style={{width: '70vw'}}>
          <RenderDocument />
        </div>
        <div style={{width: '29vw', overflowY: 'auto'}}>
          <RenderOcrData />
        </div>
      </div>
    </ViewDocumentContext.Provider>
  );
};

export default ViewDocument;
