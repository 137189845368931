import React from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import Box from 'src/DesignSystem/Box/Box';
import {themmeColor} from 'src/constants/constants';
import RightChveron from 'src/icons/RightChveron';
import {selectDistributedDeductions} from 'src/store/distributeDeductions/distributeDeductions.selector';

const DistributeDeductionsEntrypointNudge = () => {
  const {navigateToDistributeDeductions} = useCpaCenterV2Navigation();
  const {distributedDeductions} = useSelector(selectDistributedDeductions);

  if (distributedDeductions.business_info.length <= 1) {
    return null;
  }
  return (
    <Box
      hoverEffect
      hoverColor={themmeColor.focusedBlue}
      onClick={navigateToDistributeDeductions}
      style={{
        justifyContent: 'space-between',
        padding: 20,
        marginInline: 16,
        marginTop: 12,
      }}>
      <Typography style={{fontWeight: 600}}>
        Distribution of deductions
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingInline: 20,
        }}>
        {distributedDeductions.has_user_submitted ? (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 11,
              textTransform: 'uppercase',
            }}>
            Submitted by the user
          </Typography>
        ) : null}
        <RightChveron />
      </div>
    </Box>
  );
};
export default DistributeDeductionsEntrypointNudge;
