import React, {useContext, useState} from 'react';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import {BackendQuery} from 'src/types/query.types';
const CpaQueryViewerContext = React.createContext<{
  isExpenseListDrawer: boolean;
  setIsExpenseListDrawer: (newState: boolean) => void;
  queryData: BackendQuery;
  setQueryData: (queryData: BackendQuery) => void;
  txnData: Record<number, Transaction>;
  setTxnData: (txnData: Record<number, Transaction>) => void;
  pageLoading: boolean;
  setPageLoading: (pageLoading: boolean) => void;
}>({} as any);

export const CpaQueryViewerProvider = ({children}) => {
  const [isExpenseListDrawer, setIsExpenseListDrawer] = useState(false);
  const [queryData, setQueryData] = useState<BackendQuery>({} as any);
  const [txnData, setTxnData] = useState<Record<number, Transaction>>({});
  const [pageLoading, setPageLoading] = useState(true);
  return (
    <CpaQueryViewerContext.Provider
      value={{
        isExpenseListDrawer,
        setIsExpenseListDrawer,
        queryData,
        setQueryData,
        txnData,
        setTxnData,
        pageLoading,
        setPageLoading,
      }}>
      {children}
    </CpaQueryViewerContext.Provider>
  );
};

export const useCpaQueryViewer = () => {
  const value = useContext(CpaQueryViewerContext);
  return value;
};
