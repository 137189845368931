import React, {useState, useMemo} from 'react';

import {DatagridBody} from 'react-admin';
import {useQueryWithStore, Loading, Error} from 'react-admin';
import {BlankChoice} from '../constants/constants';
import {getAllCategories} from '../common/ExpenseCategoriesUtils';
import {GroupedRow, NormalRow} from './CustomDatagridRows';

const MyDatagridRow = (props) => {
  // This is the Custom Datagrid row, which will return grouped or normal row
  // depending on the props.

  // children : list of child nodes
  // categoryMap : Object containing mapping from category to sub category
  // record : single txn record that this row will show

  const {children, categoryMap, record} = {...props};

  const nbCols = React.Children.toArray(children).length;
  const slicedChildren = React.Children.toArray(children).slice(0, -1);
  const expenseCatChoices = getAllCategories(categoryMap).concat(BlankChoice);
  const derivedProps = {
    nbCols: nbCols,
    slicedChildren: slicedChildren,
    expenseCatChoices: expenseCatChoices,
  };
  return record.is_grouped ? (
    <GroupedRow {...props} {...derivedProps} />
  ) : (
    <NormalRow {...props} {...derivedProps} />
  );
};

const MyDatagridBody = (props) => {
  // Custom component for the Datagridbody, Which will do the grouping
  // of the response coming from the backend.

  const getNextGroupId = (agg_ob) => {
    // This function returns the next group id.

    //agg_ob : object containing the groups having (key, value) pair as (id, group)

    const vals = Object.values(agg_ob);
    if (vals.length === 0) {
      return 1;
    }
    const ids = vals.map((item) => item['g_id']);
    return Math.max(...ids) + 1;
  };

  const round = (value, decimals) => {
    return Number(Number(value).toFixed(decimals));
  };

  const getGroups = (expenses) => {
    // This function will generate possible groups from the
    // given list of expenses

    let agg_ob = {};
    for (const exp in expenses) {
      const k = `${expenses[exp]['updated_merchant_name']}_${expenses[exp]['updated_expense_category']}_${expenses[exp]['updated_classification_status']}_${expenses[exp]['is_reviewed']}`;
      if (agg_ob[k] === undefined) {
        agg_ob[k] = {
          g_id: getNextGroupId(agg_ob),
          txns: [expenses[exp]['id']],
        };
      } else {
        agg_ob[k]['txns'] = [...agg_ob[k]['txns'], expenses[exp]['id']];
      }
    }
    return Object.values(agg_ob).filter((item) => item['txns'].length > 1);
  };

  const getGroupedExpenses = (groups, expenses) => {
    // This function will merge the grouped expenses with the normal expenses
    // and returns the object having all the expenses.

    // groups : object containing the groups formed from the given list of expenses.
    // expenses : list of all expenses got from the backend.

    let allGroupedExpense = {};
    for (const key in groups) {
      const group = groups[key];
      let currentGroupedExpense = null;
      group['txns'].forEach((txn_id) => {
        if (currentGroupedExpense === null) {
          currentGroupedExpense = Object.assign({}, expenses[txn_id]);
          currentGroupedExpense.is_grouped = true;
          currentGroupedExpense.start_txn_date = new Date(
            expenses[txn_id]['txn_date'],
          );
          currentGroupedExpense.end_txn_date = new Date(
            expenses[txn_id]['txn_date'],
          );
          currentGroupedExpense.subExpenses = [
            Object.assign({}, expenses[txn_id]),
          ];
          currentGroupedExpense.subExpenseIds = [expenses[txn_id]['id']];
          currentGroupedExpense.no_of_txns = 1;
        } else {
          const date = new Date(expenses[txn_id]['txn_date']);
          currentGroupedExpense.no_of_txns += 1;
          currentGroupedExpense['current_amount'] = round(
            currentGroupedExpense['current_amount'] +
              expenses[txn_id]['current_amount'],
            2,
          );
          currentGroupedExpense['original_amount'] = round(
            currentGroupedExpense['original_amount'] +
              expenses[txn_id]['original_amount'],
            2,
          );
          currentGroupedExpense.start_txn_date =
            currentGroupedExpense.start_txn_date < date
              ? currentGroupedExpense.start_txn_date
              : date;
          currentGroupedExpense.end_txn_date =
            currentGroupedExpense.end_txn_date > date
              ? currentGroupedExpense.end_txn_date
              : date;
          currentGroupedExpense.subExpenses.push(
            Object.assign({}, expenses[txn_id]),
          );
          currentGroupedExpense.subExpenseIds.push(expenses[txn_id]['id']);
        }
      });
      const k = currentGroupedExpense['id'];
      allGroupedExpense[`${k}`] = Object.assign({}, currentGroupedExpense);
    }
    return allGroupedExpense;
  };

  const customSorting = (currentSort, expenses, originalIds) => {
    // This function will modify the order of expenses base on given sorting field.
    // modify the order if the sorting field is one of the followings : txn_date, original_amount, current_amount

    // currentSort : object containing info for current sorting.
    // expenses : list of all expenses got from backend
    // originalIds : list of ids got from the backend.

    const validFields = new Set([
      'txn_date',
      'original_amount',
      'current_amount',
    ]);
    if (currentSort === undefined || !validFields.has(currentSort.field)) {
      const idsAfterGrouping = expenses.map((item) => item['id']);
      return originalIds.filter((id) => idsAfterGrouping.includes(id));
    }

    if (currentSort.field === 'txn_date') {
      expenses.sort((exp1, exp2) => {
        if (currentSort.order === 'ASC') {
          return (
            (exp1.is_grouped ? exp1.start_txn_date : new Date(exp1.txn_date)) -
            (exp2.is_grouped ? exp2.start_txn_date : new Date(exp2.txn_date))
          );
        } else {
          return (
            (exp2.is_grouped ? exp2.end_txn_date : new Date(exp2.txn_date)) -
            (exp1.is_grouped ? exp1.end_txn_date : new Date(exp1.txn_date))
          );
        }
      });
    } else if (
      currentSort.field === 'original_amount' ||
      currentSort.field === 'current_amount'
    ) {
      expenses.sort((exp1, exp2) => {
        if (currentSort.order === 'ASC') {
          return exp1[currentSort.field] - exp2[currentSort.field];
        } else {
          return exp2[currentSort.field] - exp1[currentSort.field];
        }
      });
    }
    return expenses.map((item) => item['id']);
  };

  const getGroupedResponse = (expenses) => {
    // This function will add the is_grouped field in the returned data
    // and form the grouped response.

    let groupedTxnIds = new Set();
    const groups = getGroups(expenses);
    groups.forEach((item) => {
      item['txns'].forEach((id) => groupedTxnIds.add(id));
    });

    let allGroupedExpense = getGroupedExpenses(groups, expenses);

    for (const id in expenses) {
      if (!groupedTxnIds.has(parseInt(id))) {
        allGroupedExpense[`${id}`] = Object.assign({}, expenses[id]);
        allGroupedExpense[`${id}`].is_grouped = false;
      }
    }
    return allGroupedExpense;
  };

  const groupedResponse = useMemo(
    () => getGroupedResponse(Object.assign({}, props.data)),
    [props.data],
  );
  const [currentRow, setCurrentRow] = useState(-1);
  const {
    data: taxonomy,
    loading: catLoading,
    error: catError,
  } = useQueryWithStore({
    type: 'getUniverse',
    resource: 'categorizer',
  });
  if (catLoading) return <Loading />;
  if (catError) return <Error />;
  if (!taxonomy) return null;
  const categoryMap = taxonomy['hierarchy'];
  const ids = customSorting(
    props.currentSort,
    Object.values(groupedResponse),
    props.ids,
  );
  return (
    <DatagridBody
      onMouseLeave={() => setCurrentRow(-1)}
      {...props}
      data={groupedResponse}
      ids={ids}
      row={
        <MyDatagridRow
          {...props}
          categoryMap={categoryMap}
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
        />
      }
    />
  );
};

export default MyDatagridBody;
