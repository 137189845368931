import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {selectEditTaxProfileQuestions} from 'src/store/app/app.selector';
import {useEditTaxProfile} from './useEditTaxProfile';
import EditTaxProfile from './EditTaxProfile';
import {setEditTaxProfileQuestions} from 'src/store/app/app.actions';
import DSButton from 'src/DesignSystem/Button/Button';
import {parseFormConfigToFormData} from 'src/DesignSystem/Form/Form.utils';
import {useLocation} from 'react-router-dom';

const EditTaxProfileDrawer = () => {
  const {pathPrefixes, title} = useSelector(selectEditTaxProfileQuestions);

  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const {fields, onSaveChanges, isLoading, areAllFieldsValid} =
    useEditTaxProfile({
      pathPrefixes,
    });

  const onClose = () => {
    dispatch(setEditTaxProfileQuestions({pathPrefixes: [], title: ''}));
  };

  useEffect(() => {
    onClose();
  }, [pathname]);

  const onClick = async () => {
    await onSaveChanges();
    onClose();
  };

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      title={title}
      height={'90vh'}
      onClose={onClose}
      isOpen={pathPrefixes.length > 0}>
      <div
        style={{
          paddingInline: 12,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <EditTaxProfile fields={fields} />
        <DSButton
          type="primary"
          text="Save changes"
          onClick={onClick}
          style={{paddingBlock: 12, marginBlock: 20}}
          disabled={!areAllFieldsValid || isLoading}
        />
      </div>
    </Drawer>
  );
};
export default EditTaxProfileDrawer;
