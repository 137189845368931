import {Autocomplete, TextField} from '@mui/material';
import React from 'react';

interface DropdownProps {
  options: Array<any>;
  value: any;
  onChange: (newVal: any) => void;
  placeholder: string;
  maxWidth?: boolean;
  disabled?: boolean;
  getOptionLabel?: (option: any) => any;
  error?: boolean;
}

const Dropdown = ({
  options,
  value,
  onChange,
  placeholder,
  maxWidth,
  disabled,
  getOptionLabel = (option: any) => option,
  error = false,
}: DropdownProps) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      fullWidth={maxWidth}
      getOptionLabel={getOptionLabel}
      value={value}
      disabled={disabled}
      onChange={(e, v) => onChange(v)}
      componentsProps={{popper: {style: {width: 'fit-content'}}}}
      renderInput={(params) => (
        <TextField {...params} label={placeholder} error={error} />
      )}
    />
  );
};

export default Dropdown;
