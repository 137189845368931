import {Typography} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import DSCheckBox from '../DSCheckBox/DSCheckBox';
import TransactionListItem, {
  SelectionMode,
} from './components/TransactionListItem';

interface IRSGroupedTransactionListCommonProps {
  txns: Transaction[];
}
interface IRSGroupedTransactionListMultiSelectMode {
  disabled?: boolean;
  selectedTransactionIds: Set<number>;
  selectionMode: SelectionMode.MULTI_SELECT;
  setSelectedTransactionIds: (newSet: Set<number>) => void;
}
interface IRSGroupedTransactionListNoSelectMode {
  selectionMode: SelectionMode.NONE;
  header: string;
}

type IRSGroupedTransactionListProps = IRSGroupedTransactionListCommonProps &
  (
    | IRSGroupedTransactionListNoSelectMode
    | IRSGroupedTransactionListMultiSelectMode
  );

const IRSGroupedTransactionList = ({
  txns,
  selectedTransactionIds = new Set(),
  setSelectedTransactionIds = () => {},
  disabled = false,
  selectionMode,
  header = '',
}: IRSGroupedTransactionListProps) => {
  const onSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedTransactionIds(new Set(txns.map((txn) => txn.txnId)));
    } else {
      setSelectedTransactionIds(new Set());
    }
  };

  const onSelectTransaction = (isChecked: boolean, txn: Transaction) => {
    if (isChecked) {
      selectedTransactionIds.add(txn.txnId);
      const newSet = new Set(selectedTransactionIds);
      setSelectedTransactionIds(newSet);
    } else {
      selectedTransactionIds.delete(txn.txnId);
      const newSet = new Set(selectedTransactionIds);
      setSelectedTransactionIds(newSet);
    }
  };

  const allSelected = txns.every((txn) =>
    selectedTransactionIds.has(txn.txnId),
  );

  const selectedTxns = txns.filter((txn) =>
    selectedTransactionIds.has(txn.txnId),
  );

  const totalAmount = selectedTxns
    .map((t) => t.taxDeductibleAmount)
    .reduce((t1, t2) => t1 + t2, 0);
  const groupedTxns = _.groupBy(txns, 'irsCategory');

  const getHeader = () => {
    if (selectionMode === SelectionMode.MULTI_SELECT) {
      return `${selectedTransactionIds.size} deductions`;
    }
    return header;
  };

  const getAmount = () => {
    if (selectionMode === SelectionMode.MULTI_SELECT) {
      return CurrencyNumberFormat(totalAmount);
    }
    return CurrencyNumberFormat(
      txns.map((t) => t.taxDeductibleAmount).reduce((t1, t2) => t1 + t2, 0),
    );
  };

  const irsCategories = Object.keys(groupedTxns).sort((a, b) =>
    a.localeCompare(b),
  );
  return (
    <div
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: themmeColor.offWhite,
      }}>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBlock: 10,
            paddingInline: 16,
            backgroundColor: '#E6E8ED',
          }}>
          {/* Select all Header */}
          <div style={{display: 'flex', alignItems: 'center'}}>
            {selectionMode === SelectionMode.MULTI_SELECT && (
              <DSCheckBox
                style={{marginRight: 8}}
                isChecked={allSelected}
                onChange={onSelectAll}
                disabled={disabled}
              />
            )}
            <Typography>{getHeader()}</Typography>
          </div>
          <Typography>{getAmount()}</Typography>
        </div>
      }
      {irsCategories.map((category) => {
        return (
          <div>
            <div
              style={{
                backgroundColor: themmeColor.black,
                paddingBlock: 8,
                paddingInline: 16,
                textAlign: 'center',
              }}>
              <Typography
                style={{
                  color: themmeColor.white,
                  fontWeight: 600,
                  fontSize: 11,
                  textTransform: 'uppercase',
                }}>
                {category}
              </Typography>
            </div>
            {groupedTxns[category].map((txn) => (
              <TransactionListItem
                txn={txn}
                onChange={onSelectTransaction}
                isChecked={selectedTransactionIds.has(txn.txnId)}
                disabled={disabled}
                selectionMode={selectionMode}
                style={{
                  borderBottom: `solid 1px ${themmeColor.offWhite}`,
                }}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Object.assign(IRSGroupedTransactionList, {SelectionMode});
