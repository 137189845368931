import {Typography} from '@mui/material';
import {format, parseISO} from 'date-fns';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {DATE_FORMATS, themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';

interface MerchantTransactionItemProps {
  expense: Transaction;
}

const MerchantTransactionItem = ({expense}: MerchantTransactionItemProps) => {
  const txnDate = parseISO(expense.txnDate);
  const dateString = format(txnDate, DATE_FORMATS.DISPLAY_FORMAT);
  return (
    <div
      style={{
        paddingBlock: 12,
        paddingInline: 16,
        paddingLeft: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderStyle: 'solid',
        borderColor: themmeColor.offWhite,
        borderWidth: 1,
        borderTopWidth: 0,
      }}>
      <Typography>{dateString}</Typography>
      <Typography>{CurrencyNumberFormat(expense.currentAmount)}</Typography>
    </div>
  );
};

export default MerchantTransactionItem;
