import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {getDrakeFile} from 'src/appApi';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {CpaCenterV2Route, RouteParts} from 'src/constants/routeName';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const getCpaCenterV2Steps = ({
  cpaFacingRetStatus,
  taxReturns,
}: {
  cpaFacingRetStatus: TAX_FILING_STATUS;
  taxReturns: TaxReturn[];
}) => [
  {
    label: 'Document review',
    props: {
      url: CpaCenterV2Route.CpaCenterV2DocumentReview,
      section: RouteParts.DocumentReview,
    },
    disabled: false,
    isCompleted: isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
    ),
  },
  {
    label: 'Tax profile review',
    props: {
      url: CpaCenterV2Route.CpaCenterV2TaxProfileReview,
      section: RouteParts.TaxProfileReview,
    },
    disabled: false,
    isCompleted: isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
    ),
  },
  {
    label: 'user queries',
    props: {
      url: CpaCenterV2Route.CpaCenterV2UserQueries,
      section: RouteParts.UserQueries,
    },
    disabled: false,
    isCompleted: isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.PREP_STARTED,
    ),
  },
  {
    label: 'Return review & E-filing',
    props: {
      url: CpaCenterV2Route.CpaCenterV2ReviewEsign,
      section: RouteParts.ReviewEsign,
    },
    disabled: false,
    isCompleted: !taxReturns.some(
      (taxReturn) =>
        taxReturn.cpa_status !== TAX_FILING_STATUS.APPROVED_FROM_IRS,
    ),
  },
];


export const downloadDrakeFile = async ({
  userId,
  activeYear,
  returnType,
  returnId,
  config,
}: {
  userId: number;
  activeYear: number;
  returnType: TaxReturnType;
  returnId: number;
  config: Record<string, string[]>;
}) => {
  const response = await getDrakeFile(
    userId,
    activeYear,
    returnType === TaxReturnType.BUSINESS ? returnId : undefined,
    config,
  );

  // Get the filename from Content-Disposition header
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'download.csv'; // Default filename
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
    );
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1].replace(/['"]/g, '');
    }
  }

  // Create blob URL
  const blob = new Blob([response.data], {type: 'text/csv'});
  const url = window.URL.createObjectURL(blob);

  // Create temporary link and trigger download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Cleanup
  window.URL.revokeObjectURL(url);
};
