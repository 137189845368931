import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';
import {fetchUserInfo} from 'src/store/user/user.actions';
import ReturnUserInfoHeader from './components/ReturnUserInfoHeader';
import TaxProfileSummaryDrawer from 'src/CpaCenterV2/common/TaxProfileSummary/TaxProfileSummaryDrawer';
import {fetchTaxProfileForGivenYear} from 'src/store/taxProfile/taxProfile.actions';
import {fetchBusinessDetails} from 'src/store/businessDetails/businessDetails.actions';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {fetchTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.actions';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {useParntershipSteps} from './hooks/usePartnershipSteps';
import Steps from 'src/DesignSystem/Steps/Steps';
import {useNavigate, Route, Routes} from 'react-router-dom';
import {RouteParts} from 'src/constants/routeName';
import PartnerReturnDocumentList from './PartnerReturnDocumentList/PartnerReturnDocumentList';
import PartnerTaxProfileReview from './PartnerTaxProfileReview/PartnerTaxProfileReview';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';
import CreateQueryDrawer from 'src/CpaCenterV2/common/CreateQueryDrawer/CreateQueryDrawer';
import PartnerReturnDocumentReview from './PartnerReturnDocumentReview/PartnerReturnDocumentReview';
import ReuploadDocumentQueryDrawer from 'src/CpaCenterV2/ViewDocument/components/ReuploadDocumentQueryDrawer';
import {UploadPartnerReturnDocumentProvider} from './components/UploadPartnerReturnDocumentProvider';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {PartnerTaxProfileSectionsConfig} from './PartnerTaxProfileReview/PartnerTaxProfileSections/PartnerTaxProfileSections.constants';
import EditTaxProfileDrawer from 'src/common/EditTaxProfile/EditTaxProfileDrawer';
import EditTaxFormDrawer from 'src/common/EditTaxForm/EditTaxFormDrawer';
import {usePartnerTaxProfileReview} from './hooks/usePartnerTaxProfileReview';
import {TaxProfileSubsectionContext} from 'src/CpaCenterV2/TaxProfileSections/common/TaxProfileSubsectionContext';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import TaxReturns from 'src/CpaCenterV2/TaxReturns/TaxReturns';
import TaxReturnDetailedView from 'src/CpaCenterV2/TaxReturnDetailedView/TaxReturnDetailedView';

const Partnership = () => {
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {steps, currentLabel, isLoading} = useParntershipSteps();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo(userId, activeYear));
    dispatch(fetchTaxProfileForGivenYear(userId, activeYear));
    dispatch(fetchBusinessDetails(userId, activeYear));
    dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    dispatch(fetchTaxProfileStatesInfo(userId, activeYear));
    dispatch(fetchTaxReturns(userId, activeYear));
    dispatch(fetchDocumentsForGivenYear(userId, activeYear));
    dispatch(fetchQueries({userId, year: activeYear}));
    dispatch(fetchTaxProfileStatusForGivenYear(userId, activeYear));
    dispatch(fetchCCHStatus(userId, activeYear));
  }, [userId, activeYear]);

  const {
    loaded,
    isSubsectionReviewComplete,
    isSubSectionPendingFromUser,
    isSubSectionVisible,
    isSubsectionComplete,
  } = usePartnerTaxProfileReview();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: themmeColor.cpaCenterV2Bg,
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
        }}>
        <ReturnUserInfoHeader />
        <div style={{zIndex: 100}}>
          {/* all drawer here */}
          <TaxProfileSummaryDrawer />
          <CreateQueryDrawer />
          <ReuploadDocumentQueryDrawer />
          <EditTaxProfileDrawer />
          <EditTaxFormDrawer />
        </div>
        <Steps
          data={steps}
          onClick={(step) => {
            navigate(step.props.section);
          }}
          currentLabel={currentLabel}
          isLoading={isLoading}
        />
        <UploadPartnerReturnDocumentProvider>
          <TaxProfileSubsectionContext.Provider
            value={{
              loaded,
              isSubsectionReviewComplete,
              isSubSectionPendingFromUser,
              isSubSectionVisible,
              isSubsectionComplete,
            }}>
            <Routes>
              <Route
                path={`/${RouteParts.DocumentReview}/${RouteParts.DocumentId}`}
                element={<PartnerReturnDocumentReview />}
              />
              <Route
                path={`/${RouteParts.DocumentReview}`}
                element={<PartnerReturnDocumentList />}
              />
              {PartnerTaxProfileSectionsConfig.map(({section, Component}) => {
                return (
                  <Route
                    path={`/${RouteParts.TaxProfileReview}/${section}`}
                    element={<Component />}
                  />
                );
              })}
              <Route
                path={`/${RouteParts.TaxProfileReview}`}
                element={<PartnerTaxProfileReview />}
              />
              <Route
                path={`/${RouteParts.ReviewEsign}`}
                element={<TaxReturns />}
              />
              <Route
                path={`/${RouteParts.ReviewEsign}/${RouteParts.ReturnId}/${RouteParts.TaxReturnSection}`}
                element={<TaxReturnDetailedView />}
              />
              <Route path={`/*`} element={<h1>Route not found</h1>} />
            </Routes>
          </TaxProfileSubsectionContext.Provider>
        </UploadPartnerReturnDocumentProvider>
      </div>
    </>
  );
};

export default Partnership;
