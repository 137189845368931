import {ALL_FORM_TYPES} from 'src/constants/constants';
import {SET_TAX_FORMS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {TaxFormFieldIds} from './taxForms.constants';

export interface TaxForm {
  taxFormId: number;
  formType: ALL_FORM_TYPES;
  formData: {[key in TaxFormFieldIds]?: any};
  isEndUserReviewed: boolean;
  docId: number;
  docLink: string;
}

const initialState = {
  taxForms: [] as TaxForm[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const taxForms = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_FORMS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxFormsStateType = typeof initialState;
