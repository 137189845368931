import React, {useCallback} from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import Text from '../../Text/Text';
import {themmeColor} from 'src/constants/constants';

interface OptionProps {
  label: any;
  value: any;
  isSingle: boolean;
  checked?: boolean;
  onSelect: (selectedValue: any) => void;
  onDeselect: (toRemoveValue: any) => void;
}

const containerStyle: React.CSSProperties = {
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: themmeColor.cpaCenterV2Bg,
  borderColor: themmeColor.offWhite,
  borderRadius: 8,
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 12,
};

const selectedContainerStyle: React.CSSProperties = {
  ...containerStyle,
  borderColor: themmeColor.black,
  accentColor: themmeColor.black,
};

const Option = ({
  label,
  value,
  isSingle,
  checked,
  onSelect,
  onDeselect,
}: OptionProps) => {
  const onClick = useCallback(() => {
    if (!checked) {
      onSelect(value);
    } else {
      onDeselect(value);
    }
  }, [checked]);
  return (
    <div
      style={checked ? selectedContainerStyle : containerStyle}
      onClick={onClick}>
      <input
        style={{marginRight: 8, color: themmeColor.black}}
        type={isSingle ? 'radio' : 'checkbox'}
        checked={checked}
        onChange={() => {}}
      />
      <Text
        containerStyle={{flex: 1}}
        type={Text.TEXT_TYPES.BASE}
        text={label}
      />
    </div>
  );
};

export default Option;
