import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  Button,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CCH_SYNC_STATUS, IncomeInfo} from '../cch.utils';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: themmeColor.white,
    width: '950px',
    height: '600px',
    borderRadius: 16,
    padding: 24,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 36,
  },
});

export interface SelectedDocTypeProps {
  docType: string;
  label: string;
}

interface SendIncomeDocumentsOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  handleSend: (selectedForms: number[]) => void;
  data: IncomeInfo[];
}

const SendIncomeDocumentsOverlay: React.FC<
  SendIncomeDocumentsOverlayProps
> = ({isOpen, onClose, handleSend, data}) => {
  const {flyfin_user_id} = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedForms, setSelectedForms] = useState<number[]>(
    data
      .filter(
        ({cch_sync_status}) =>
          cch_sync_status === CCH_SYNC_STATUS.FAILED ||
          cch_sync_status === CCH_SYNC_STATUS.NOT_STARTED,
      )
      .map(({id}) => id),
  );

  const getStatusBackgroundColor = (cch_sync_status: CCH_SYNC_STATUS) => {
    switch (cch_sync_status) {
      case CCH_SYNC_STATUS.FAILED:
        return themmeColor.errorRed;
      case CCH_SYNC_STATUS.SUCCESS:
        return themmeColor.successGreen;
      case CCH_SYNC_STATUS.IN_PROGRESS:
        return themmeColor.flyfinYellow;
      default:
        return themmeColor.offWhite;
    }
  };

  const onSend = async () => {
    try {
      setLoading(true);
      await handleSend(selectedForms);
      setLoading(false);
      onClose();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number,
    cch_sync_status: CCH_SYNC_STATUS,
  ) => {
    if (
      cch_sync_status === CCH_SYNC_STATUS.IN_PROGRESS ||
      cch_sync_status === CCH_SYNC_STATUS.SUCCESS
    ) {
      return;
    }
    if (selectedForms.includes(id)) {
      setSelectedForms((prev) => {
        return prev.filter((formId) => formId !== id);
      });
    } else {
      setSelectedForms((prev) => {
        return [...prev, id];
      });
    }
  };

  const styles = useStyles({});

  return (
    <>
      {loading && <FullScreenLoading open={loading} />}
      <Modal
        onClose={onClose}
        open={isOpen}
        disableEnforceFocus
        className={styles.modal}>
        <div className={styles.modalContainer}>
          <Typography fontSize={20} fontWeight={'600'}>
            Select the documents you want to send to CCH
          </Typography>
          <TableContainer style={{height: '500px', overflowY: 'scroll'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Form Type</TableCell>
                  <TableCell align="center">Payer</TableCell>
                  <TableCell align="center">Income</TableCell>
                  <TableCell align="center">File Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(
                  (
                    {
                      form_type,
                      payer,
                      income,
                      filename,
                      cch_sync_status,
                      id,
                      doc_id,
                      doc_type_id,
                    },
                    index,
                  ) => {
                    const isItemSelected = selectedForms.includes(id)
                      ? true
                      : false;

                    return (
                      <TableRow
                        key={id}
                        hover
                        onClick={(event) =>
                          handleClick(event, id, cch_sync_status)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        sx={{cursor: 'pointer'}}>
                        <TableCell component="th" scope="row">
                          {cch_sync_status === CCH_SYNC_STATUS.IN_PROGRESS ||
                          cch_sync_status ===
                            CCH_SYNC_STATUS.SUCCESS ? null : (
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': `table-checkbox-${index}`,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{form_type}</TableCell>
                        <TableCell>{payer}</TableCell>
                        <TableCell>{income}</TableCell>
                        <TableCell>
                          <a
                            onClick={(e) => e.stopPropagation()}
                            href={`/tax-filing/${flyfin_user_id}/documents-ocr/${doc_type_id}/${doc_id}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {filename}
                          </a>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '0px 5px',
                              borderRadius: 8,
                              border: '1px solid black',
                              background:
                                getStatusBackgroundColor(cch_sync_status),
                            }}>
                            {cch_sync_status}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.buttonsContainer}>
            <Button
              onClick={onClose}
              disabled={loading}
              style={{width: 230, height: 48}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onSend}
              disabled={loading || selectedForms.length === 0}
              style={{width: 230, height: 48}}
              variant="contained">
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SendIncomeDocumentsOverlay;
