import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {getDocument} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';
import {setMessageAttachmentDrawer} from 'src/store/app/app.actions';
import {selectMessageAttachmentDrawer} from 'src/store/app/app.selector';

const MessageAttachmentDrawer = () => {
  const {isVisible, unsigned_link, filename} = useSelector(
    selectMessageAttachmentDrawer,
  );
  const dispatch = useDispatch();

  const [readLink, setReadLink] = useState(null);

  useEffect(() => {
    let ignore = false;
    const fetchDoc = async () => {
      if (unsigned_link) {
        const response = await getDocument(unsigned_link);
        if (!ignore) {
          setReadLink(response.data);
        }
      }
    };
    fetchDoc();
    return () => {
      ignore = true;
    };
  }, [unsigned_link]);

  return (
    <Drawer
      style={{
        padding: 20,
        borderColor: themmeColor.black,
        borderWidth: 4,
        borderStyle: 'solid',
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        zIndex: 1000,
      }}
      direction="up"
      width={'99vw'}
      height={'80%'}
      isOpen={isVisible}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingInline: 20,
          paddingBlock: 10,
        }}>
        <Typography style={{fontSize: 20, fontWeight: 600}}>
          {filename}
        </Typography>
        <div
          style={{
            padding: 8,
            backgroundColor: themmeColor.black,
            borderRadius: 50,
            display: 'flex',
          }}
          onClick={() => {
            dispatch(
              setMessageAttachmentDrawer({
                isVisible: false,
                unsigned_link: null,
                filename: '',
              }),
            );
            setReadLink(null);
          }}>
          <CrossIcon color={themmeColor.white} />
        </div>
      </div>
      {readLink && (
        <iframe src={readLink} style={{height: '90%', width: '100%'}} />
      )}
    </Drawer>
  );
};

export default MessageAttachmentDrawer;
