import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CpaReviewMessage} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {themmeColor} from 'src/constants/constants';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    paddingBottom: '0px',
  },
  authorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    padding: '0px 10px',
  },
  dot: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    border: `1px solid ${themmeColor.darkBlue}`,
    marginRight: '10px',
  },
  dashedLine: {
    borderLeft: `1px dashed ${themmeColor.darkBlue}`,
    marginLeft: '2px',
    marginRight: '14px',
  },
});

interface MessageProps {
  messageData?: CpaReviewMessage;
  isCollapsed: boolean;
}

const Message: React.FC<MessageProps> = ({messageData, isCollapsed}) => {
  const styles = useStyles({});

  const getDisplayDateTime = (created_at: string) => {
    const timeStamp = new Date(created_at);
    const date = timeStamp.toLocaleDateString();
    const hours = timeStamp.getHours();
    const minutes = `${timeStamp.getMinutes()}`;

    return `${date} ${hours}:${
      minutes.length === 1 ? `0${minutes}` : minutes
    }`;
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.authorContainer}>
          {!isCollapsed && messageData ? <div className={styles.dot} /> : null}
          <Typography fontWeight={'400'} fontSize={'16px'} fontStyle={'Inter'}>
            {messageData?.author_name}
          </Typography>
        </div>
        <Typography fontWeight={'300'} fontSize={'13px'} fontStyle={'Inter'}>
          {messageData ? getDisplayDateTime(messageData?.created_at) : ''}
        </Typography>
      </div>
      <div className={styles.content}>
        {!isCollapsed && messageData ? (
          <div className={styles.dashedLine} />
        ) : null}
        {messageData ? (
          <Typography
            noWrap={isCollapsed}
            sx={{wordBreak: 'break-word'}}
            fontWeight={'300'}
            fontSize={'15px'}
            fontStyle={'Inter'}>
            {messageData?.comment}
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

export default Message;
