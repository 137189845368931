import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as QueriesIcon} from 'src/icons/queriesIcon.svg';
import {ReactComponent as TaxFilingIcon} from 'src/icons/taxFilingIcon.svg';
import {RouteName} from 'src/constants/routeName';
import useIsCpaCenterV2 from 'src/CpaCenterV2/hooks/useIsCpaCenterV2';

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CpaCenterDrawer = () => {
  const theme = useTheme();
  const location = useLocation();

  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const {getCpaCenterV2Route} = useIsCpaCenterV2();

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const sideBarListItems = [
    {
      title: 'Tax Filing',
      icon: <TaxFilingIcon />,
      route: getCpaCenterV2Route(RouteName.TaxFiling),
    },
    {
      title: 'Queries',
      icon: <QueriesIcon />,
      route: getCpaCenterV2Route(RouteName.UserQueries.UserQueries),
    },
    {
      title: 'Boi Filing',
      icon: <TaxFilingIcon />,
      route: getCpaCenterV2Route(`${RouteName.BoiFilingCenter}`),
    },
    {
      title: 'Partnership',
      icon: <TaxFilingIcon />,
      route: getCpaCenterV2Route(`${RouteName.Partnership}`),
    },
  ];

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <Drawer open={isDrawerOpen} anchor={'left'} onClose={closeDrawer}>
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sideBarListItems.map((option) => (
            <ListItem
              key={option.title}
              onClick={() => {
                closeDrawer();
                navigate(option.route);
              }}
              disablePadding>
              <ListItemButton
                sx={{px: 4}}
                selected={location.pathname === option.route}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2,
                    justifyContent: 'center',
                  }}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        onClick={(e) => {
          if (isDrawerOpen) {
            e.stopPropagation();
            e.preventDefault();
            closeDrawer();
          }
        }}
        sx={{
          flex: 1,
        }}>
        <Outlet context={{openDrawer, closeDrawer}} />
      </Box>
    </Box>
  );
};

export default CpaCenterDrawer;
