import {Info} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import React, {useMemo} from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import EditIcon from 'src/icons/EditIcon';
import NotesIcon from 'src/icons/NotesIcon';
import {BalanceSheetCombined} from 'src/store/balanceSheet/balanceSheet.types';
import {InformationSource} from 'src/store/taxForms/taxForms.types';

interface BalanceSheetHeaderProps {
  categoryData: BalanceSheetCombined['grouped_category_data'][0];
}

const BalanceSheetHeader = ({categoryData}: BalanceSheetHeaderProps) => {
  type RowType = (typeof categoryData.line_items)[0];
  return (
    <div style={{marginBottom: 12}}>
      <Text
        type={TEXT_TYPES.L}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={categoryData.form_key}
        containerStyle={{marginBottom: 4}}
      />
      <KeyValueTable
        header={['L2 - Category', 'Start of the year', 'End of the year']}
        data={categoryData.line_items}
        keys={[
          {getValue: (row: RowType) => `${row.l2} - ${row.category}`},
          {
            cellStyle: {display: 'flex', flexDirection: 'row'},
            getValue: (row: RowType) =>
              CurrencyNumberFormat(row.start_year_data.total_amount),
            getIcon: (row: RowType) => (
              <div style={{marginLeft: 8}}>
                <Tooltip
                  title={
                    <div>
                      {row.start_year_data.items.map((item) => (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            {item.source === InformationSource.document ? (
                              <NotesIcon color={themmeColor.white} />
                            ) : (
                              <EditIcon color={themmeColor.white} />
                            )}
                            <Text text={item.name} type={TEXT_TYPES.BASE} />
                          </div>
                          <Text
                            text={CurrencyNumberFormat(item.amount)}
                            type={TEXT_TYPES.BASE}
                            containerStyle={{marginLeft: 12}}
                          />
                        </div>
                      ))}
                    </div>
                  }>
                  <Info />
                </Tooltip>
              </div>
            ),
          },
          {
            cellStyle: {display: 'flex', flexDirection: 'row'},
            getValue: (row: RowType) =>
              CurrencyNumberFormat(row.end_year_data.total_amount),
            getIcon: (row: RowType) => (
              <div style={{marginLeft: 8}}>
                <Tooltip
                  title={
                    <div>
                      {row.end_year_data.items.map((item) => (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            {item.source === InformationSource.document ? (
                              <NotesIcon color={themmeColor.white} />
                            ) : (
                              <EditIcon color={themmeColor.white} />
                            )}
                            <Text text={item.name} type={TEXT_TYPES.BASE} />
                          </div>
                          <Text
                            text={CurrencyNumberFormat(item.amount)}
                            type={TEXT_TYPES.BASE}
                            containerStyle={{marginLeft: 12}}
                          />
                        </div>
                      ))}
                    </div>
                  }>
                  <Info />
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default BalanceSheetHeader;
