import React from 'react';
import {useDispatch} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';

const useCreateTaxProfileQuery = ({
  currentReturnId,
  currentReturnType,
}: {
  currentReturnId?: number | null;
  currentReturnType?: TaxReturnType | null;
}) => {
  const dispatch = useDispatch();

  const openCreateQueryDrawer = (section: TaxProfileSubsection) => {
    console.log({section});
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab: CreateQueryDrawerTab.TAX_PROFILE,
        selectedDropdown: section,
        currentReturnId,
        currentReturnType,
      }),
    );
  };

  return {
    openCreateQueryDrawer,
  };
};

export default useCreateTaxProfileQuery;
