import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import {Form} from 'src/DesignSystem/Form/Form';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import UserRelationFields from './common/UserRelationFields';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  CREATE_RETURN_STATUS,
  ReturnFields,
  UserRelationType,
} from './CreateReturn.types';
import {UsersRelationFieldConfig} from './CreateReturn.constants';
import {FIELD_ID} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {
  tranformOcrDataToUserRelationsData,
  tranformRelationsDataToUserDetailsForm,
} from './CreateReturn.utils';
import _ from 'lodash';
import {useFormData} from 'src/CpaCenterV2/hooks/useFormData';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {useCreateReturn} from './useCreateReturn';

interface UserRelationContentProps {
  setUserDetailsForm: React.Dispatch<
    React.SetStateAction<{
      users: UserRelationType[];
    }>
  >;
}

const UserRelationContent = ({
  setUserDetailsForm,
}: UserRelationContentProps) => {
  const {setStatus, backendTransformedUserData} = useCreateReturn();

  const [userRelationDetails, setUserRelationDetails] = useState<{
    [ReturnFields.users]: ReturnType<
      typeof tranformOcrDataToUserRelationsData
    >;
  }>({[ReturnFields.users]: []});

  useEffect(() => {
    setUserRelationDetails({[ReturnFields.users]: []});
  }, [backendTransformedUserData]);

  const selectedUserIds = useMemo(() => {
    return userRelationDetails[ReturnFields.users].map(
      (u) => u[ReturnFields.id],
    );
  }, [userRelationDetails]);

  const alreadySpouseUserIds: number[] = useMemo(() => {
    return userRelationDetails[ReturnFields.users]
      .filter(
        (u) =>
          u[ReturnFields.has_spouse] &&
          typeof u[ReturnFields.spouse_id] === 'number',
      )
      .map((u) => u[ReturnFields.spouse_id]);
  }, [userRelationDetails]);

  const allOptions: ComponentProps<typeof Form.CheckField>['options'] =
    useMemo(() => {
      return backendTransformedUserData.map((user) => ({
        label: `${user[FIELD_ID.FIRST_NAME]} ${user[FIELD_ID.LAST_NAME]}`,
        value: user[ReturnFields.id],
      }));
    }, [backendTransformedUserData]);
  const onChangeSelectedUsers = useCallback(
    (newIds: number[]) => {
      setUserRelationDetails((prevUsers) => {
        const newUsers: (typeof prevUsers)[ReturnFields.users] = [];
        const prevUsersById = _.keyBy(
          prevUsers[ReturnFields.users],
          ReturnFields.id,
        );
        const idToObject = _.keyBy(
          backendTransformedUserData,
          ReturnFields.id,
        );
        newIds.forEach((id) => {
          if (prevUsersById[id] !== undefined) {
            newUsers.push(prevUsersById[id]);
          } else {
            newUsers.push(idToObject[id]);
          }
        });
        return {[ReturnFields.users]: newUsers};
      });
    },
    [backendTransformedUserData],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: UsersRelationFieldConfig,
    data: userRelationDetails,
    setData: setUserRelationDetails,
  });

  const isPrimaryDisabled = useMemo(() => {
    if (!areAllFieldsValid) {
      return true;
    }
    if (userRelationDetails[ReturnFields.users].length === 0) {
      return true; // no user to creare
    }
    const hasSpouseButNotAssigned = userRelationDetails[
      ReturnFields.users
    ].some(
      (u) =>
        u[ReturnFields.has_spouse] === true &&
        typeof u[ReturnFields.spouse_id] !== 'number',
    );
    if (hasSpouseButNotAssigned) {
      return true;
    }
    return false;
  }, [userRelationDetails, areAllFieldsValid]);

  const onClickContinue = useCallback(() => {
    const formData = tranformRelationsDataToUserDetailsForm(
      userRelationDetails[ReturnFields.users],
      backendTransformedUserData,
    );
    setUserDetailsForm(formData);
    setStatus(CREATE_RETURN_STATUS.CREATE_RETURNS_FROM_DOC);
  }, [userRelationDetails, backendTransformedUserData]);
  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}>
        <Text
          type={TEXT_TYPES.L}
          fontWeight={FONT_WEIGHTS.SemiBold}
          text={
            'Select all the users for whom you want to create a new account.'
          }
          containerStyle={{marginBottom: 4}}
        />
        <div>
          <Form.CheckField
            title="Select only the primary users. Do not select the Spouse filing MFJ from this drop down."
            value={selectedUserIds}
            onChangeValue={onChangeSelectedUsers}
            options={allOptions.filter(
              (option) => !alreadySpouseUserIds.includes(option.value),
            )}
            allowEmpty
          />
        </div>

        {fields.map((field) => {
          if (field.inputType == FORM_INPUT_TYPE.Array) {
            return field.value.map((_, index) => {
              return (
                <UserRelationFields
                  selectedUserIds={selectedUserIds}
                  alreadySpouseUserIds={alreadySpouseUserIds}
                  allOptions={allOptions}
                  childProps={field.childProps}
                  arrayObjectIndex={index}
                  onChangeValue={field.onChangeValue}
                  array={field.value}
                />
              );
            });
          }
          return null;
        })}
      </div>
      <DSButton
        type="primary"
        text="Continue"
        disabled={isPrimaryDisabled}
        onClick={onClickContinue}
        style={{paddingBlock: 12}}
        textType={TEXT_TYPES.BASE}
      />
    </>
  );
};

export default UserRelationContent;
