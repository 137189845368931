import React, {useMemo} from 'react';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import {useCpaQueryViewer} from '../CpaQueryViewerContext';
import {Typography} from '@mui/material';
import CrossIcon from 'src/icons/CrossIcon';
import TransactionList from 'src/common/TransactionList/TransactionList';
import {useExpenseDetails} from 'src/common/hooks/useExpenseDetails';
import MerchantSectionHeader from 'src/common/TransactionList/components/MerchantGroup/MerchantSectionHeader';
import MerchantTransactionItem from 'src/common/TransactionList/components/MerchantGroup/MerchantTransactionItem';
import MerchantClassifiedTransactionItem from 'src/common/TransactionList/components/MerchantGroup/MerchantClassifiedTransactionItem';

const ExpenseListDrawer = () => {
  const {isExpenseListDrawer, setIsExpenseListDrawer, queryData, txnData} =
    useCpaQueryViewer();
  const {getExpenseMerchantGroupDetails, getPendingCpaExpenses} =
    useExpenseDetails();
  const {expenses} = useMemo(() => {
    return getExpenseMerchantGroupDetails(queryData.txn_ids, txnData);
  }, [queryData, txnData]);

  const {pendingExpenses, classifiedExpenses} = useMemo(() => {
    return getPendingCpaExpenses(expenses);
  }, [expenses]);

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      height={'100vh'}
      isOpen={isExpenseListDrawer}>
      <div style={{padding: 20}}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
          }}>
          <Typography style={{fontWeight: 600, fontSize: 20}}>
            All transactions
          </Typography>
          <div
            onClick={() => {
              setIsExpenseListDrawer(false);
            }}>
            <CrossIcon />
          </div>
        </div>
        <div style={{overflowY: 'auto', height: '85vh'}}>
          <TransactionList
            title={'Pending to classify'}
            groupingKey={'merchantName'}
            expenses={pendingExpenses}
            getSectionHeaderData={({expenses}) => ({
              expenses,
            })}
            renderSectionHeaderData={({data}) => (
              <MerchantSectionHeader {...data} />
            )}
            renderExpense={({txn}) => (
              <MerchantTransactionItem expense={txn} />
            )}
          />
          <div style={{marginTop: 12}}>
            <TransactionList
              title={'already classified'}
              groupingKey={'merchantName'}
              expenses={classifiedExpenses}
              getSectionHeaderData={({expenses}) => ({
                expenses,
              })}
              renderSectionHeaderData={({data}) => (
                <MerchantSectionHeader {...data} />
              )}
              renderExpense={({txn}) => (
                <MerchantClassifiedTransactionItem expense={txn} />
              )}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ExpenseListDrawer;
