import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FIELD_ID} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import useStateInfo from 'src/CpaCenterV2/hooks/useStateInfo';
import DSAccordion from 'src/DesignSystem/Accordion/DSAccordion';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import CrossIcon from 'src/icons/CrossIcon';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectStateInfoDrawer} from 'src/store/app/app.selector';
import MultiKeyValueField from '../../common/MultiKeyValueField';

const StatesInfoDrawer = () => {
  const {statesInfo, fields} = useStateInfo();

  const isVisible = useSelector(selectStateInfoDrawer);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setAppState(AppReducerStates.statesInfoDrawer, false));
  };

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={450}
      height={'85vh'}
      isOpen={isVisible}>
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          marginTop: 4,
          position: 'absolute',
          height: '90vh',
          padding: 20,
        }}>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
          <Typography style={{fontWeight: 600, fontSize: 20}}>States Info</Typography>
          <div style={{display: 'flex'}} onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
        {statesInfo.map((stateInfo) => {
          return (
            <DSAccordion
              name={`${stateInfo[FIELD_ID.STATE]} ${
                stateInfo.current_address ? '(Current address)' : ''
              }`}>
              <MultiKeyValueField data={stateInfo} fields={fields} />
            </DSAccordion>
          );
        })}
      </div>
    </Drawer>
  );
};

export default StatesInfoDrawer;
