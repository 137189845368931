import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import Tag from 'src/DesignSystem/Tag/Tag';
import NotesIcon from 'src/icons/NotesIcon';
import {
  DerivedMessageType,
  DerivedQueryType,
  MessageAuthorType,
  QueryStatus,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import ReactHtmlParser from 'react-html-parser';
import {formatDistance} from 'date-fns';
import {selectLastMessage} from 'src/store/queries/queries.selector';
import useCurrentStep from 'src/CpaCenterV2/hooks/useCurrentStep';
import {isCpaCenterV2StepComplete} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {getFieldValue} from '../CpaCenterV2.utils';
import EditIcon from 'src/icons/EditIcon';
import DeleteIcon from 'src/icons/DeleteIcon';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import {useDispatch} from 'react-redux';
import {fetchQueries} from 'src/store/queries/queries.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import MessageTextBox from '../MessageTextBox/MessageTextBox';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
interface QueryPreviewProps {
  query: ReduxQueryType;
  backgroundColor?: string;
  rootStyle?: React.CSSProperties;
  noBR?: boolean;
  bypassStatusCheck?: boolean;
  onClick: (queryId: number) => void;
  isDraftEditDeleteAllowed: boolean;
}

const QueryPreview = ({
  query,
  backgroundColor = themmeColor.cpaCenterV2QueryBox,
  rootStyle = {},
  noBR = false,
  bypassStatusCheck = false,
  onClick = (queryId: number) => {},
  isDraftEditDeleteAllowed = false,
}: QueryPreviewProps) => {
  const latestMessage = selectLastMessage(query);
  const {cpaFacingRetStatus} = useCurrentStep();
  const [isQueryDeletePopupVisible, setIsQueryDeletePopupVisible] =
    useState(false);
  const [isDraftEditPopupVisible, setIsDraftEditPopupVisible] =
    useState(false);
  const [draftMessageInput, setDraftMessageInput] = useState('');
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const {deleteMessage, isLoading, patchMessage} = useQueryAction();
  let messageSentAt = getFieldValue(null);
  if (latestMessage) {
    messageSentAt = formatDistance(latestMessage.messageCreatedAt, new Date());
  }
  const getQueryStatusColor = () => {
    if (query.queryStatus === QueryStatus.SENT) {
      return themmeColor.lightBlue;
    }
    if (query.queryStatus === QueryStatus.DRAFT) {
      return themmeColor.silver;
    }
    if (
      query.queryStatus === QueryStatus.OPEN ||
      query.queryStatus === QueryStatus.USER_RESOLVED
    ) {
      return themmeColor.flyfinOrange;
    }
    if (
      query.queryStatus === QueryStatus.RESOLVED ||
      query.queryStatus === QueryStatus.CPA_RESOLVED
    ) {
      return themmeColor.successGreen;
    }
  };

  const mapQueryStatuses = (queryStatus: QueryStatus) => {
    if (queryStatus === QueryStatus.USER_RESOLVED) {
      return QueryStatus.OPEN;
    }
    if (queryStatus === QueryStatus.CPA_RESOLVED) {
      return QueryStatus.RESOLVED;
    }
    return queryStatus;
  };

  const authorName =
    latestMessage?.authorType === MessageAuthorType.END_USER
      ? latestMessage?.authorName
      : 'You';

  const isAllowedToNavigateToMessageView =
    isCpaCenterV2StepComplete(
      cpaFacingRetStatus,
      TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
    ) || bypassStatusCheck;

  const getQueryTagName = () => {
    if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE_CPA) {
      return query.taxProfileSubsection;
    }
    if (
      [
        DerivedQueryType.REUPLOAD_DOCUMENT,
        DerivedQueryType.UPLOAD_NEW_DOCUMENT,
      ].includes(query.derivedQueryType)
    ) {
      return query.docData?.docType;
    }
    if (query.derivedQueryType === DerivedQueryType.HIGH_VALUE_DEDUCTION) {
      return 'Review Deductions';
    }
    if (query.derivedQueryType === DerivedQueryType.DISTRIBUTE_DEDUCTIONS) {
      return 'Distribute Deductions';
    }
    if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE) {
      return 'User Tax Profile';
    }
    if (query.derivedQueryType === DerivedQueryType.GENERAL) {
      return 'General';
    }
    if (query.derivedQueryType === DerivedQueryType.REVIEW_ESIGN) {
      return 'Review Esign';
    }
    if (
      query.derivedQueryType ===
      DerivedQueryType.PERSONAL_RETURN_ACCEPTED_BY_IRS
    ) {
      return 'Return Approved';
    }
    if (query.derivedQueryType === DerivedQueryType.TAX_FILING_FEEDBACK) {
      return 'Tax Filing Feedback';
    }
    return '<not implemented>';
  };

  const firstDraftMessage = query.messages.find(
    (m) => m.derivedMessageType === DerivedMessageType.DRAFT_MESSAGE,
  );

  const onClickDraftEdit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (firstDraftMessage) {
      setDraftMessageInput(firstDraftMessage.message);
      setIsDraftEditPopupVisible(true);
    }
  };

  const onDeleteDraft = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsQueryDeletePopupVisible(true);
  };

  const deleteDraftQuery = async () => {
    if (firstDraftMessage) {
      await deleteMessage({messageId: firstDraftMessage.messageId});
      dispatch(fetchQueries({userId, year: activeYear}));
      setIsQueryDeletePopupVisible(false);
    }
  };

  const udpateDraftMessage = async () => {
    if (firstDraftMessage) {
      await patchMessage({
        messageId: firstDraftMessage.messageId,
        message: draftMessageInput,
      });
      dispatch(fetchQueries({userId, year: activeYear}));
      setIsDraftEditPopupVisible(false);
    }
  };

  return (
    <>
      <Box
        col
        backgroundColor={backgroundColor}
        hoverEffect={isAllowedToNavigateToMessageView}
        hoverColor={themmeColor.focusedBlue}
        onClick={() =>
          isAllowedToNavigateToMessageView && onClick(query.queryId)
        }
        noBR={noBR}
        style={{padding: 12, ...rootStyle}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {[
              DerivedQueryType.REUPLOAD_DOCUMENT,
              DerivedQueryType.UPLOAD_NEW_DOCUMENT,
            ].includes(query.derivedQueryType) && <NotesIcon />}
            <div style={{marginLeft: 4, display: 'flex'}}>
              <Tag
                text={getQueryTagName()}
                backgroundColor={themmeColor.white}
                borderColor={themmeColor.offWhite}
              />
            </div>
          </div>
          {isDraftEditDeleteAllowed &&
            query.queryStatus === QueryStatus.DRAFT &&
            firstDraftMessage && (
              <div style={{display: 'flex'}}>
                <div onClick={onClickDraftEdit} style={{marginRight: 4}}>
                  <EditIcon />
                </div>
                <div onClick={onDeleteDraft}>
                  <DeleteIcon />
                </div>
              </div>
            )}
        </div>
        <Typography style={{fontSize: 16, fontWeight: 600, marginTop: 8}}>
          {query.query}
        </Typography>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 400,
            marginTop: 4,
            display: 'flex',
          }}>
          <Typography style={{fontWeight: 600, marginRight: 4}}>
            {authorName}:{' '}
          </Typography>
          <div style={{height: 48, overflow: 'hidden'}}>
            {ReactHtmlParser(latestMessage.message)}
          </div>
        </Typography>
        <div
          style={{
            display: 'flex',
            marginTop: 8,
            justifyContent: 'space-between',
          }}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Tag
              text={mapQueryStatuses(query.queryStatus)}
              backgroundColor={getQueryStatusColor()}
            />
            {query.queryStatus === QueryStatus.SENT && (
              <Tag
                text={query.hasUserRead ? 'Read' : 'Unread'}
                backgroundColor={
                  query.hasUserRead
                    ? themmeColor.successGreen
                    : themmeColor.offWhite
                }
              />
            )}
          </div>
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.grey,
              // textTransform: 'uppercase',
            }}>
            {messageSentAt}
          </Typography>
        </div>
      </Box>
      <PopUp
        isOpen={isQueryDeletePopupVisible}
        onClose={() => setIsQueryDeletePopupVisible(false)}
        primaryButtonTitle={'Confirm'}
        primaryButtonOnClick={deleteDraftQuery}
        primaryButtonDisabled={isLoading}
        secondaryButtonTitle={'Cancel'}
        style={{width: 600}}
        secondaryButtonOnClick={() => setIsQueryDeletePopupVisible(false)}
        secondaryButtonDisabled={isLoading}>
        <div>
          <Typography style={{fontSize: 20, fontWeight: 800}}>
            Are you sure?
          </Typography>
          <Typography
            style={{fontSize: 13, color: themmeColor.grey, marginTop: 12}}>
            Are you sure you want to delete the question added?
          </Typography>
        </div>
      </PopUp>
      <PopUp
        isOpen={isDraftEditPopupVisible}
        style={{width: 600}}
        onClose={() => setIsDraftEditPopupVisible(false)}>
        <Typography style={{fontSize: 20, fontWeight: 800, marginBottom: 12}}>
          Enter updated draft message
        </Typography>
        <MessageTextBox
          inputMode={MessageTextBox.MessageTextBoxInputMode.EDIT_DRAFT}
          message={draftMessageInput}
          setMessage={setDraftMessageInput}
          onDoneEditing={udpateDraftMessage}
          placeholder="Enter updated message"
          isLoading={isLoading}
          height={200}
        />
      </PopUp>
    </>
  );
};

export default QueryPreview;
