import React, {useState} from 'react';
import {Button, Modal, TextField, Tooltip} from '@mui/material';

import {themmeColor} from 'src/constants/constants';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Info} from '@mui/icons-material';

interface CreateCouponProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateCoupon: (data: any) => void;
  loading: boolean;
}

enum FIELD_ID {
  CODE = 'code',
  USE_CASE = 'use_case',
}

const CreateCouponCode = ({
  isOpen,
  onClose,
  loading,
  onCreateCoupon,
}: CreateCouponProps) => {
  const [couponInfo, setCouponInfo] = useState({
    [FIELD_ID.CODE]: '',
    [FIELD_ID.USE_CASE]: '',
  });

  const onChange = (key: FIELD_ID, value: any) => {
    setCouponInfo((prev) => ({...prev, [key]: value.target.value}));
  };

  const config = [
    {
      placeholder: 'Enter coupon code',
      tooltipText:
        'coupon-code is case sentitive, it should not already exists in database',
      key: FIELD_ID.CODE,
      Component: TextField,
      extraParams: {
        fullWidth: true,
      },
    },
    {
      placeholder: 'Enter use case',
      tooltipText: 'Use case for coupon GENERAL | AFFILIATE',
      key: FIELD_ID.USE_CASE,
      Component: TextField,
      extraParams: {
        fullWidth: true,
      },
    },
  ];

  const _createCoupon = () => {
    const {code, use_case} = couponInfo;

    onCreateCoupon({
      code,
      use_case,
    });
  };

  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={isOpen}
      disableAutoFocus={true}
      onClose={onClose}>
      <div
        style={{
          backgroundColor: themmeColor.white,
          padding: 24,
          borderRadius: 12,
          flexDirection: 'column',
          display: 'flex',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {config.map((Field) => (
            <>
              <div
                style={{
                  width: 280,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Field.Component
                  value={couponInfo[Field.key]}
                  placeholder={Field.placeholder}
                  onChange={(e) => onChange(Field.key, e)}
                  {...Field.extraParams}
                />
                <Tooltip key={Field.key} title={Field.tooltipText}>
                  <Info style={{marginLeft: 16}} />
                </Tooltip>
              </div>
              <br />
            </>
          ))}
        </LocalizationProvider>
        <Button disabled={loading} variant="contained" onClick={_createCoupon}>
          Create promo-code
        </Button>
      </div>
    </Modal>
  );
};

export default CreateCouponCode;
