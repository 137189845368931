import {Autocomplete, Button, IconButton, TextField, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ReviewAndEsignContext from '../../../../ReviewAndEsignContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {AuthorType, CPAQuestionMessageType, themmeColor} from 'src/constants/constants';
import {RE_ACTIONS} from '../../../../ReviewAndEsign.utils';
import {getRelativeDate} from 'src/common/utils';
import REAdditionDoc from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/DocumentAndQueryViewer/REUserQueries/ReviewAndEsignQueries/components/REActionOverlay';
import {postMessage, resolveQuery} from 'src/appApi';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';

import ReactHtmlParser from 'react-html-parser';

const RESingleQueryView = () => {
  const {flyfin_user_id, docId, queryId} = useParams();
  const {documents, fetchDocsData} = useContext(ReviewAndEsignContext);
  const [isAdditionalOverlay, setIsAdditionalOverlay] = useState(false);
  const [selectedDocType, setSelectectedDocType] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const selectedDocumentId = docId
    ? parseInt(docId, 10)
    : undefined;

  const selectedQueryId = queryId ? parseInt(queryId, 10) : undefined;
  const selectedDocumentObject = documents?.filter?.(
    (document) => document.documentId === selectedDocumentId,
  )?.[0];

  const selectedQueryObject = selectedDocumentObject.queries?.filter?.(
    (query) => query.queryId === selectedQueryId,
  )?.[0];

  const getInitLocalState = () => ({
    actionRequired: RE_ACTIONS.INIT,
    message: '',
  });

  const [currentLocalState, setCurrentLocalState] = useState(
    getInitLocalState(),
  );

  const closeOverlay = () => {
    setIsAdditionalOverlay(false);
    setCurrentLocalState({
      actionRequired: RE_ACTIONS.INIT,
      message: '',
    });
  };

  const handleUpdateAction = (newValue: RE_ACTIONS) => {
    switch (newValue) {
      case RE_ACTIONS.RESOLVE: {
        // RESOLVE query
        break;
      }
      case RE_ACTIONS.PROOF_OR_EXPENSE_OR_ACCOUNT:
      case RE_ACTIONS.PROOF_OR_INFO_UPDATE:
      case RE_ACTIONS.DOCUMENT_PROOF: {
        // select document overlay
        // set local state
        setIsAdditionalOverlay(true);
        break;
      }
    }
    setCurrentLocalState((prev) => ({...prev, actionRequired: newValue}));
  };

  useEffect(() => {
    if (!selectedDocumentId || !selectedQueryId) {
      setCurrentLocalState(getInitLocalState());
      return;
    }
  }, [selectedDocumentId, selectedQueryId]);

  if (!selectedQueryObject || !selectedQueryObject.messages) return <></>;
  const navigateToAllQueryView = () => {
    navigate(-1);
  };

  const actionOptions = [
    RE_ACTIONS.DOCUMENT_PROOF,
    RE_ACTIONS.PROOF_OR_EXPENSE_OR_ACCOUNT,
    RE_ACTIONS.PROOF_OR_INFO_UPDATE,
    RE_ACTIONS.RESOLVE,
  ];

  const updateText = (message: string) => {
    setCurrentLocalState((prev) => ({...prev, message}));
  };

  const handleOnSend = async () => {
    setLoading(true);
    try {
      switch (currentLocalState.actionRequired) {
        case RE_ACTIONS.INIT: {
          await postMessage({
            cpa_question_id: selectedQueryId ?? 0,
            message: currentLocalState.message,
            author_type: AuthorType.CPA,
            type: CPAQuestionMessageType.CHAT,
          });
          break;
        }
        case RE_ACTIONS.DOCUMENT_PROOF: {
          await postMessage({
            cpa_question_id: selectedQueryId ?? 0,
            message: currentLocalState.message,
            author_type: AuthorType.CPA,
            type: CPAQuestionMessageType.DOCUMENT_PROOF,
            doc_type: selectedDocType?.docType ?? '',
          });
          break;
        }
        case RE_ACTIONS.PROOF_OR_EXPENSE_OR_ACCOUNT: {
          await postMessage({
            cpa_question_id: selectedQueryId ?? 0,
            message: currentLocalState.message,
            author_type: AuthorType.CPA,
            type: CPAQuestionMessageType.TAX_RETURN_MISSING_INFO,
            doc_type: selectedDocType?.docType ?? '',
          });
          break;
        }
        case RE_ACTIONS.PROOF_OR_INFO_UPDATE: {
          await postMessage({
            cpa_question_id: selectedQueryId ?? 0,
            message: currentLocalState.message,
            author_type: AuthorType.CPA,
            type: CPAQuestionMessageType.TAX_RETURN_WRONG_INFO,
            doc_type: selectedDocType?.docType ?? '',
          });
          break;
        }
        case RE_ACTIONS.RESOLVE: {
          if (currentLocalState.message) {
            await postMessage({
              cpa_question_id: selectedQueryId ?? 0,
              message: currentLocalState.message,
              author_type: AuthorType.CPA,
              type: CPAQuestionMessageType.CHAT,
            });
          }
          await resolveQuery({query_id: selectedQueryId ?? 0});
          break;
        }
        default:
          break;
      }
      await fetchDocsData();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 22,
          alignItems: 'center',
          height: 36,
          backgroundColor: themmeColor.offWhite,
        }}>
        <IconButton onClick={navigateToAllQueryView}>
          <ArrowBackIosIcon style={{height: 12}} />
          <Typography
            fontWeight={'600'}
            fontSize={12}
            textTransform={'uppercase'}>
            Back to all queries
          </Typography>
        </IconButton>
      </div>
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          overflowY: 'auto',
          height: '45vh',
        }}>
        {selectedQueryObject.messages.map(
          ({fromUs, text, timestamp, documentType}) => (
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: fromUs ? 'flex-end' : 'flex-start',
                marginTop: 20,
              }}>
              <div style={{maxWidth: '20vw'}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: fromUs ? 'flex-start' : 'flex-end',
                  }}>
                  <Typography>{getRelativeDate(timestamp)}</Typography>
                </div>
                <div
                  style={{
                    backgroundColor: fromUs
                      ? themmeColor.focusedBlue
                      : themmeColor.offWhite,
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: fromUs
                      ? themmeColor.darkBlue
                      : themmeColor.grey,
                    padding: 12,
                    borderRadius: 8,
                  }}>
                  <div>{ReactHtmlParser(text)}</div>
                  {documentType && (
                    <div
                      style={{
                        padding: 8,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: themmeColor.black,
                        borderRadius: 8,
                        display: 'inline-flex',
                        marginTop: 4,
                      }}>
                      <Typography>{documentType}</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
      {selectedQueryObject.resolved ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            backgroundColor: themmeColor.lightGreen,
          }}>
          <Typography fontWeight={'600'}>RESOLVED</Typography>
        </div>
      ) : (
        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography
              fontWeight="600"
              style={{width: '8vw', marginLeft: 10}}>
              Add Action:
            </Typography>
            <Autocomplete
              // disabled={loading}
              onChange={(e, v) => handleUpdateAction(v)}
              fullWidth
              value={
                currentLocalState.actionRequired === RE_ACTIONS.INIT
                  ? ''
                  : currentLocalState.actionRequired
              }
              id="combo-box-demo"
              options={actionOptions}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Return Status" />
              )}
            />
          </div>
          <div
            style={{
              alignItems: 'center',
              padding: 12,
            }}>
            <RichTextEditor
              message={currentLocalState.message}
              setMessage={updateText}
            />
            <div
              style={{
                display: 'flex',
                marginTop: 4,
                justifyContent: 'flex-end',
              }}>
              <Button
                disabled={
                  (currentLocalState.message.length === 0 || loading) &&
                  currentLocalState.actionRequired !== RE_ACTIONS.RESOLVE
                }
                variant="contained"
                onClick={handleOnSend}>
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
      <REAdditionDoc
        actionRequired={currentLocalState.actionRequired}
        message={currentLocalState.message}
        updateMessage={updateText}
        onSend={handleOnSend}
        isOpen={isAdditionalOverlay}
        onClose={closeOverlay}
        selectedDocType={selectedDocType}
        setSelectectedDocType={setSelectectedDocType}
      />
    </div>
  );
};

export default RESingleQueryView;
