import React, {useState} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import {
  CPA_LIST_COLUMNS,
  getDateForNextAction,
  getDateTimeFromMonthDayString,
  getMonthDateFromDate,
} from 'src/CpaCenterList/components/cpaList.utils';
import {patchTaxFiling} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Typography} from '@mui/material';

interface NextActionDatePickerCellProps {
  taxFilingId: number;
  value: string;
  onSuccess: () => void;
  isValid: boolean;
}

const NextActionDatePickerCell = ({
  value,
  taxFilingId,
  onSuccess,
  isValid,
}: NextActionDatePickerCellProps) => {
  const parsedDate = getDateTimeFromMonthDayString(value) as Date;
  const [isOpen, setIsOpen] = useState(false);
  const [timeStamp, setTimeStamp] = useState(dayjs(parsedDate));
  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const onChange = (v: any) => {
    setTimeStamp(dayjs(v));
  };

  const handleDateUpdate = async () => {
    try {
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {next_action_date: getDateForNextAction(timeStamp.toDate())},
      );
      await onSuccess();
    } catch {
      alert('Something went wrong!');
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const RenderDateTime = () => {
    return (
      <div onClick={stopDrilling}>
        <div
          style={{
            display: 'flex',
            padding: '8px 12px',
            backgroundColor: themmeColor.offWhite,
            borderRadius: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsOpen(true)}>
          <Typography fontSize={13}>
            {isValid
              ? (getMonthDateFromDate(timeStamp.toDate()) as string)
              : 'Unassigned'}
          </Typography>
          <KeyboardArrowDownIcon />
        </div>
      </div>
    );
  };

  if (!isOpen) return <RenderDateTime />;

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div onClick={stopDrilling} style={{width: 130}}>
        {isOpen && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              disablePast={true}
              onChange={onChange}
              value={isValid ? timeStamp : undefined}
              onAccept={handleDateUpdate}
              format={'DD/MM/YYYY'}
              label={CPA_LIST_COLUMNS.NEXT_ACTION_DATE}
              onClose={onClose}
              open={isOpen}
              slots={{
                textField: (params) => {
                  return <RenderDateTime />;
                },
              }}
            />
          </LocalizationProvider>
        )}
      </div>
    </div>
  );
};

export default NextActionDatePickerCell;
