import React from 'react';
import {Typography} from '@mui/material';
import Skeleton from 'src/icons/Skeleton';

interface SimpleArrayFieldProps {
  name: string;
  value: any[];
  getItem?: (item: any) => string;
  style?: React.CSSProperties;
  loading?: boolean;
}

const SimpleArrayField = ({
  style,
  name,
  value,
  getItem = (item: any) => item,
  loading = false,
}: SimpleArrayFieldProps) => {
  return (
    <div style={style}>
      <Typography style={{fontSize: 13, marginBottom: 4}}>{name}</Typography>
      {loading ? (
        <Skeleton height={20} width={50} radius={4} />
      ) : (
        value.map((item: any) => (
          <div style={{display: 'flex'}}>
            <Typography style={{marginRight: 8}}>•</Typography>
            <Typography style={{fontSize: 16, fontWeight: 600}}>
              {getItem(item)}
            </Typography>
          </div>
        ))
      )}
    </div>
  );
};

export default SimpleArrayField;
