import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {GenericTaxForm, TaxFormType} from 'src/store/taxForms/taxForms.types';
import {
  getEditTaxFormConfig,
  getOverwriteTaxForm,
  validateBalanceSheet,
  validateProfitLoss,
} from 'src/store/taxForms/taxForms.utils';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import {useDispatch, useSelector} from 'react-redux';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import _, {isFunction, isObject, noop} from 'lodash';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {deleteTaxForm, patchTaxForm, postTaxForm} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from '../hooks/useActiveYear';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {FormConfigProps} from 'src/DesignSystem/Form/Form.types';
import {useTaxonomy} from '../hooks/useTaxonomy';

export const useEditTaxForm = ({
  taxFormId,
  taxFormType,
  prefilledFormData,
  multi_tax_forms_entity_id,
  filterFields,
  onCommit,
}: {
  taxFormId: number | null;
  taxFormType: TaxFormType | null;
  prefilledFormData?: any;
  multi_tax_forms_entity_id?: number;
  filterFields?: (field: FormConfigProps[]) => boolean;
  onCommit?: () => void;
}) => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {businesses} = useSelector(selectBusinessDetails);
  const {irsCategories, getL2s} = useTaxonomy();
  const taxFormById = useMemo(() => {
    return _.keyBy(taxForms, 'taxFormId');
  }, [taxForms]);
  const currentReduxTaxForm = useMemo(() => {
    if (
      typeof taxFormId === 'number' &&
      taxFormById[taxFormId] !== undefined
    ) {
      return taxFormById[taxFormId];
    }
    return null;
  }, [taxFormById, taxFormId]);

  const {notify} = useNotify();
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();
  const {taxProfileMap} = useTaxProfile();

  const isEditingTaxForm = currentReduxTaxForm !== null;

  const [taxFormData, setTaxFormData] = useState<GenericTaxForm['formData']>(
    {},
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const linkedBusinessId =
    currentReduxTaxForm?.linked_business_id ?? multi_tax_forms_entity_id;

  const config = useMemo(() => {
    if (taxFormType === null) {
      return [];
    }
    // @ts-ignore
    return getEditTaxFormConfig(taxFormType, filterFields);
  }, [taxFormType, filterFields]);

  useEffect(() => {
    if (currentReduxTaxForm) {
      const formData = {
        ..._.cloneDeep(currentReduxTaxForm['formData']),
        ...(isObject(prefilledFormData) ? prefilledFormData : {}),
      };
      setTaxFormData(formData);
      return;
    }
    const emptyTaxForm = {
      ...createEmptyFormDataFromFormConfig(config, 0),
      ...(isObject(prefilledFormData) ? prefilledFormData : {}),
    };
    setTaxFormData(emptyTaxForm);
  }, [currentReduxTaxForm, taxFormType, config, prefilledFormData]);

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    (props) => {
      const overwrite = getOverwriteTaxForm(taxFormType, {
        taxforms: taxForms,
        taxprofileMap: taxProfileMap,
        businesses,
      });
      return overwrite(props);
    },
    [taxFormType, taxForms, taxProfileMap, businesses],
  );

  const {fields, areAllFieldsValid: areAllFieldsValidByDefaultConfig} =
    useFormData({
      config,
      data: taxFormData,
      setData: setTaxFormData,
      overWriteFields,
    });

  const areAllFieldsValid = useMemo(() => {
    if (taxFormType === TaxFormType.PROFIT_LOSS_FORM) {
      return validateProfitLoss({
        config,
        taxFormData,
        setTaxFormData,
        overWriteFields,
        businesses,
        linkedBusinessId,
        getL2s,
        irsCategories,
      });
    }
    if (taxFormType === TaxFormType.BALANCE_SHEET_FORM) {
      return validateBalanceSheet({
        config,
        taxFormData,
        setTaxFormData,
        overWriteFields,
        linkedBusinessId,
        businesses,
      });
    }
    return areAllFieldsValidByDefaultConfig;
  }, [
    areAllFieldsValidByDefaultConfig,
    taxFormData,
    taxFormType,
    config,
    overWriteFields,
    setTaxFormData,
    linkedBusinessId,
    businesses,
    getL2s,
    irsCategories,
  ]);

  const onCreate = async (callback = noop) => {
    if (taxFormType === null) {
      return;
    }
    try {
      setIsLoading(true);
      await postTaxForm({
        fly_user_id: userId,
        year: activeYear,
        form_type: taxFormType,
        form_data: taxFormData,
        multi_tax_forms_entity_id,
      });
      callback();
      if (isFunction(onCommit)) {
        onCommit();
      }
      notify('Create Tax form successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to create tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (callback = noop) => {
    if (typeof taxFormId !== 'number') {
      return;
    }
    try {
      setIsLoading(true);
      // add update api here
      await patchTaxForm(taxFormId, {form_data: taxFormData});
      await callback();
      if (isFunction(onCommit)) {
        onCommit();
      }
      notify('Updated Tax form successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to update tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (callback = noop) => {
    if (typeof taxFormId !== 'number') {
      return;
    }
    try {
      setIsLoading(true);
      await deleteTaxForm({tax_form_id: taxFormId});
      callback();
      notify('Tax form deleted successfully', NotificationType.success);
      await dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    } catch (e) {
      notify(`Failed to delete tax form ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fields,
    areAllFieldsValid,
    isEditingTaxForm,
    onCreate,
    onUpdate,
    onDelete,
    isLoading,
    linkedBusinessId,
  };
};
