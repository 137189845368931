import {format, parseISO} from 'date-fns';
import _ from 'lodash';
import {useCallback} from 'react';
import {
  BUCKET,
  CLASSIFICATION_STATUS,
  DATE_FORMATS,
} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';

export const useExpenseDetails = () => {
  const getExpenseMerchantGroupDetails = useCallback(
    (txn_ids: number[], txnMap: Record<number, Transaction>) => {
      const expenses: Transaction[] = [];
      const expensesIds: number[] = [];
      const txnDates: Date[] = [];
      let totalSum = 0;

      const merchantNamesSet = new Set<string>();

      txn_ids?.forEach((txnId: number) => {
        const expense = txnMap?.[txnId];
        if (expense) {
          merchantNamesSet.add(expense.merchantName);
          expenses.push(expense);
          expensesIds.push(expense.txnId);
          totalSum += expense.currentAmount;
          txnDates.push(parseISO(expense.txnDate));
        }
      });

      const isNoExpense = expenses.length === 0;
      const isSingleExpense = expenses.length === 1;
      const isMultiExpense = expenses.length > 1;
      const isMultiMerchant = merchantNamesSet.size > 1;
      const isSingleMerchant = merchantNamesSet.size === 1;

      let merchantName = '';
      if (isSingleMerchant) {
        merchantName = expenses[0].merchantName;
      }
      if (isMultiMerchant) {
        merchantName = `${merchantNamesSet.size} Merchants`;
      }

      let dateString = '';
      const minDate = _.min(txnDates);
      const maxDate = _.max(txnDates);

      if (isSingleExpense && minDate) {
        dateString = format(minDate, DATE_FORMATS.DISPLAY_FORMAT);
      }
      if (isMultiExpense && minDate && maxDate) {
        dateString = format(minDate, DATE_FORMATS.RANGE_FORMAT);
        dateString += ' - ';
        dateString += format(maxDate, DATE_FORMATS.RANGE_FORMAT);
      }

      return {
        merchantName,
        expenses,
        expensesCount: expenses.length,
        isNoExpense,
        isSingleExpense,
        isMultiExpense,
        isSingleMerchant,
        isMultiMerchant,
        totalSum,
        dateString,
      };
    },
    [],
  );
  const getPendingCpaExpenses = useCallback((expenses: Transaction[]) => {
    const pendingExpenses: Transaction[] = [];
    const classifiedExpenses: Transaction[] = [];
    expenses.forEach((exp) => {
      if (exp.bucket === BUCKET.CPA) {
        pendingExpenses.push(exp);
      } else {
        classifiedExpenses.push(exp);
      }
    });
    return {
      pendingExpenses,
      classifiedExpenses,
    };
  }, []);

  return {
    getExpenseMerchantGroupDetails,
    getPendingCpaExpenses,
  };
};
