import {getStateReturnsInfo} from 'src/appApi';
import {SET_TAX_PROFILE_STATES_INFO} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';

const setTaxProfileStatesInfo = (payload) => {
  return {
    type: SET_TAX_PROFILE_STATES_INFO,
    payload,
  };
};

export const fetchTaxProfileStatesInfo = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getStateReturnsInfo({
        fly_user_id: userId,
        year,
      });
      const payload = {
        statesLivedInfo: data.states_info,
        businessStateReturnInfo: data.business_tax_returns_info,
        isDrivingLicenseRequired: data.is_driving_license_required,
      };
      dispatch(setTaxProfileStatesInfo(payload));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to tax-profile states info (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
