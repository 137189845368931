import React from 'react';
import {themmeColor} from 'src/constants/constants';

const EditIcon = ({color = themmeColor.black}: {color?: string}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5149 7.78085L17.151 6.13299C17.6313 5.64918 17.6537 4.89265 17.2011 4.44322L15.5618 2.81571C15.1091 2.36628 14.3528 2.39416 13.8725 2.87797L12.228 4.54084M15.5149 7.78085L7.58388 15.7691L7.25167 16.1037C7.18416 16.1717 7.10276 16.2241 7.01392 16.2567L3.17527 17.6641C2.70373 17.837 2.27268 17.409 2.44217 16.9362L3.82194 13.0875C3.85387 12.9985 3.90564 12.9167 3.97315 12.8487L4.30536 12.5141L12.228 4.54084M15.5149 7.78085L12.228 4.54084"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
