import React from 'react';

const FilterIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 18.5H14.5V16.5H10.5V18.5ZM3.5 6.5V8.5H21.5V6.5H3.5ZM6.5 13.5H18.5V11.5H6.5V13.5Z"
        fill="black"
      />
    </svg>
  );
};

export default FilterIcon;
