import React, {useMemo, useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {
  CREATE_RETURN_STATUS,
  ReturnFields,
  UserRelationType,
} from '../CreateReturn.types';
import {useFormData} from 'src/CpaCenterV2/hooks/useFormData';
import {MultipleUsersFieldConfig} from '../CreateReturn.constants';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import ListUserCards from './ListUserCards';
import {themmeColor} from 'src/constants/constants';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {useCreateReturn} from '../useCreateReturn';
import {transformReturnStateForBackend} from '../CreateReturn.utils';
import {createBulkPartnerReturns} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

interface CreateReturnFromDocContentProps {
  userDetailsForm: {
    users: UserRelationType[];
  };
  setUserDetailsForm: React.Dispatch<
    React.SetStateAction<{
      users: UserRelationType[];
    }>
  >;
}

const CreateReturnFromDocContent = ({
  userDetailsForm,
  setUserDetailsForm,
}: CreateReturnFromDocContentProps) => {
  const {setStatus, setCreatedReturns, pendingDocs, status} =
    useCreateReturn();
  const [validUsers, setValidUsers] = useState<{[key: number]: boolean}>({});
  const {fields} = useFormData({
    data: userDetailsForm,
    setData: setUserDetailsForm,
    config: MultipleUsersFieldConfig,
  });
  const {notify} = useNotify();

  const isLoading =
    status === CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS;

  const areAllUsersValid = useMemo(() => {
    return Object.entries(validUsers).every(([_, value]) => value);
  }, [validUsers]);

  const onPressCreateReturns = async () => {
    try {
      setStatus(CREATE_RETURN_STATUS.RETURN_CREATION_IN_PROGRESS);
      const usersRequestBody = userDetailsForm[ReturnFields.users].map(
        transformReturnStateForBackend,
      );
      if (usersRequestBody.length === 1) {
        // only single user, assign all pending docs to him
        usersRequestBody[0][ReturnFields.ocr_job_ids].push(
          ...pendingDocs.map((doc) => doc[ReturnFields.ocr_job_id]),
        );
      }
      const response = await createBulkPartnerReturns({
        tax_returns: usersRequestBody,
      });
      setCreatedReturns(response.data);
      if (pendingDocs.length === 0 || usersRequestBody.length === 1) {
        // document assignment not needed (no pending docs or single user)
        setStatus(CREATE_RETURN_STATUS.RETURNS_CREATED);
      } else {
        // document assignment needed
        setStatus(CREATE_RETURN_STATUS.ASSIGN_DOCUMENTS);
      }
      notify('Created returns successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to create return: ${e}`, NotificationType.error);
    } finally {
    }
  };

  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}>
        <DSButton
          text="Go back"
          onClick={() => setStatus(CREATE_RETURN_STATUS.CONFIRM_USER_RELATION)}
          type="primary"
        />
        {fields.map((field) => {
          if (
            field.inputType === FORM_INPUT_TYPE.Array &&
            field.path === ReturnFields.users
          ) {
            return field.value.map((obj, index) => {
              return (
                <div
                  style={{
                    padding: 12,
                    borderRadius: 12,
                    border: 'solid 1px grey',
                    marginBottom: 16,
                  }}>
                  <Text
                    type={TEXT_TYPES.L}
                    text={`User ${index + 1}`}
                    fontWeight={FONT_WEIGHTS.SemiBold}
                    containerStyle={{marginBottom: 12}}
                  />
                  <ListUserCards
                    array={field.value}
                    onChangeValue={field.onChangeValue}
                    arrayObjectIndex={index}
                    childProps={field.childProps}
                    users={userDetailsForm[ReturnFields.users]}
                    setValidUsers={setValidUsers}
                  />
                </div>
              );
            });
          }
          return null;
        })}
      </div>

      <DSButton
        type="primary"
        text="Create Returns"
        onClick={onPressCreateReturns}
        disabled={!areAllUsersValid || isLoading}
        style={{paddingBlock: 12}}
        textType={TEXT_TYPES.BASE}
      />
    </>
  );
};

export default CreateReturnFromDocContent;
