import {ReduxStateType} from '../store';
import {TaxReturn} from '../taxReturns/taxReturns.reducer';
import {Thread} from './threads.reducer';

export const selectThreadsForCurrReturn = (
  state: ReduxStateType,
  currentReturn: Pick<TaxReturn, 'return_id' | 'return_type'>,
) => {
  return {
    threads:
      state.threads.threads.find(
        (threadData) =>
          threadData.return_id === currentReturn.return_id &&
          threadData.return_type === currentReturn.return_type,
      )?.threads ?? ([] as Thread[]),
    loaded: state.threads.loaded,
  };
};
