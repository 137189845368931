import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Form} from 'src/DesignSystem/Form/Form';

import Text from 'src/DesignSystem/Form/common/Text/Text';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import _ from 'lodash';
import {
  OverWriteFormFieldsFunctionType,
  useFormData,
} from 'src/DesignSystem/Form/useFormData';
import {
  deleteBusinessDetails,
  patchBusinessDetails,
  postBusinessDetails,
} from 'src/appApi';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {
  BusinessDetail,
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBusinessDetails} from 'src/store/businessDetails/businessDetails.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {
  getBusinessDetailFormConfig,
  getShareholderKey,
  overWriteBusinessDetailsFields,
} from './EditBusinesDetails.utils';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {BUSINESS_ENTITY_OPTIONS} from './EditBusinessDetails.constants';
import {getPath} from 'src/common/utils';
import {selectTaxProfileAnswerForGivenYear} from 'src/store/taxProfile/taxProfile.selector';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {BUSINESS_DETAILS_FIELD_CONFIG} from './EditBusinessDetails.config';
import {ReduxStateType} from 'src/store/store';
import DSButton from 'src/DesignSystem/Button/Button';
import DeleteIcon from 'src/icons/DeleteIcon';
import EditShareholders from './EditShareholders';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {Add} from '@mui/icons-material';

interface EditBusinessDetailsProps {
  isOpen: boolean;
  business?: ReturnType<typeof selectBusinessDetails>['businesses'][0];
  onClose: () => void;
  showDelete?: boolean;
}

const EditBusinessDetails = ({
  isOpen,
  business,
  onClose,
  showDelete = false,
}: EditBusinessDetailsProps) => {
  const [businessData, setBusinessData] = useState<BusinessDetail>({});
  const {activeYear} = useActiveYear();
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswerForGivenYear(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {notify} = useNotify();

  const {userId} = useCurrentUserId();

  const businessDetailsFormConfig = useMemo(() => {
    return getBusinessDetailFormConfig(
      businessData[BusinessDetailsField.have_ein],
      businessData[BusinessDetailsField.entity_type],
    );
  }, [
    businessData[BusinessDetailsField.have_ein],
    businessData[BusinessDetailsField.entity_type],
  ]);

  useEffect(() => {
    if (isOpen) {
      if (business) {
        setBusinessData(_.cloneDeep(business));
      } else {
        setBusinessData(
          createEmptyFormDataFromFormConfig(BUSINESS_DETAILS_FIELD_CONFIG, 0),
        );
      }
    }
  }, [business, isOpen]);

  const overWriteFields: OverWriteFormFieldsFunctionType = useCallback(
    (props) => overWriteBusinessDetailsFields({...props, filingStatus}),
    [filingStatus],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: businessDetailsFormConfig,
    data: businessData,
    setData: setBusinessData,
    overWriteFields,
  });

  const updateBusinessDetails = async () => {
    try {
      setLoading(true);
      const shareholders = businessData[
        BusinessDetailsField.share_holders
      ].map((s) => ({...s, [BusinessDetailsField.id]: getShareholderKey(s)}));
      const backendBizData = {
        ...businessData,
        [BusinessDetailsField.share_holders]: shareholders,
      };
      // @ts-ignore
      delete backendBizData[BusinessDetailsField.document_links];
      await patchBusinessDetails({
        businessData: backendBizData,
        fly_user_id: userId,
        businessId: businessData[BusinessDetailsField.id],
      });
      await dispatch(fetchBusinessDetails(userId, activeYear));
      onClose();
      notify(
        'Business details updated successfully',
        NotificationType.success,
      );
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const createBusiness = async () => {
    try {
      setLoading(true);
      const shareholders = businessData[
        BusinessDetailsField.share_holders
      ].map((s) => ({...s, [BusinessDetailsField.id]: getShareholderKey(s)}));
      const backendBizData = {
        ...businessData,
        [BusinessDetailsField.is_end_user_reviewed]: true,
        [BusinessDetailsField.document_links]: [],
        [BusinessDetailsField.share_holders]: shareholders,
      };
      await postBusinessDetails(userId, activeYear, backendBizData);
      await dispatch(fetchBusinessDetails(userId, activeYear));
      onClose();
      notify(
        'Business details updated successfully',
        NotificationType.success,
      );
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onPressPrimary = async () => {
    if (business) {
      await updateBusinessDetails();
    } else {
      await createBusiness();
    }
  };

  const onDelete = async () => {
    if (!business) {
      return;
    }
    try {
      setLoading(true);
      await deleteBusinessDetails(business[BusinessDetailsField.id]);
      onClose();
      notify(
        'Business details updated successfully',
        NotificationType.success,
      );
      await dispatch(fetchBusinessDetails(userId, activeYear));
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUp
      style={{
        minWidth: 500,
        maxWidth: '70vw',
      }}
      isOpen={isOpen}
      primaryButtonTitle={business ? 'Update' : 'Create'}
      primaryButtonDisabled={!areAllFieldsValid || loading}
      primaryButtonOnClick={onPressPrimary}
      secondaryButtonTitle="Close"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text
          type={Text.TEXT_TYPES.L}
          text="Edit business details"
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          containerStyle={{marginBottom: 12}}
        />
        {showDelete && business ? (
          <DSButton
            type="secondary"
            startIcon={<DeleteIcon />}
            onClick={onDelete}
            text="Delete Business"
          />
        ) : null}
      </div>
      <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
        {fields.map((field) => {
          return (
            <div style={{marginBottom: 12}}>
              {field.path === BusinessDetailsField.share_holders &&
              field.inputType === FORM_INPUT_TYPE.Array ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 24,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 8,
                    }}>
                    <Text
                      type={TEXT_TYPES.H6}
                      fontWeight={FONT_WEIGHTS.SemiBold}
                      text={'Shareholders'}
                    />
                    <DSButton
                      type="secondary"
                      startIcon={<Add />}
                      onClick={() => {
                        const updatedArray = [
                          ...(field.value ?? []),
                          createEmptyFormDataFromFormConfig(field.childProps),
                        ];
                        field.onChangeValue(updatedArray);
                      }}
                      text="Add Shareholder"
                    />
                  </div>
                  {field.value?.map((_, arrayObjectIndex) => (
                    <EditShareholders
                      arrayObjectIndex={arrayObjectIndex}
                      array={field.value}
                      onChangeValue={field.onChangeValue}
                      childProps={field.childProps}
                    />
                  ))}
                </div>
              ) : (
                <Form {...field} />
              )}
            </div>
          );
        })}
      </div>
    </PopUp>
  );
};

export default EditBusinessDetails;
