import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DSButton from 'src/DesignSystem/Button/Button';
import Skeleton from 'src/icons/Skeleton';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {Integrator} from './CCHAndDrake.types';
import {Autocomplete, TextField, Typography} from '@mui/material';
import FullScreenLoading from 'src/common/FullScreenLoading';
import axios from 'axios';
import {getDrakeConfig, postCCHStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import CCHSections from './CCHSections';
import SendToCCHErrorOverlay from './SendToCCHErrorOverlay';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {
  BusinessDetail,
  BusinessDetailsField,
} from 'src/store/businessDetails/businessDetails.types';
import CopyText from 'src/CpaCenterV2/common/CopyText/CopyText';
import {downloadDrakeFile} from 'src/CpaCenterV2/CpaCenterV2.utils';

interface CCHAndDrakeProps {
  integrator: Integrator;
  setIntegrator: React.Dispatch<React.SetStateAction<Integrator>>;
}

const CCHAndDrake = ({integrator, setIntegrator}: CCHAndDrakeProps) => {
  const {loaded: cchStatusLoaded, sectionWiseSubsection} =
    useSelector(selectCCHStatus);
  const {loaded: taxReturnsLoaded} = useSelector(selectTaxReturnsReducer);
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const {returnId, returnType, currentReturn} = useCurrentTaxReturnId();
  const {taxProfileMap} = useTaxProfile();
  const {businesses} = useSelector(selectBusinessDetails);
  const [drakeSelectedSections, setDrakeSelectedSections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSections, setSelectedSections] = useState({});
  const [drakeNameSections, setDrakeNameSections] = useState<{} | null>(null);
  const [drakeSections, setDrakeSections] = useState<{} | null>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [errorOverlay, setErrorOverlay] = useState<{
    error: string;
    isVisible: boolean;
  }>({error: '', isVisible: false});
  const {notify} = useNotify();
  const dispatch = useDispatch();

  const isLoaded = taxReturnsLoaded && cchStatusLoaded;
  const handleOnChange = (e: any, v: any) => {
    setIntegrator(v);
  };

  const ein = useMemo(() => {
    if (returnType === TaxReturnType.BUSINESS) {
      const curBusiness = businesses.find((business) => {
        return business[BusinessDetailsField.id] === currentReturn.business_id;
      }) as BusinessDetail;
      return curBusiness?.[BusinessDetailsField.ein];
    }
    return undefined;
  }, [currentReturn, businesses]);

  const fetchDrakeSections = async () => {
    try {
      setIsLoading(true);
      const resp =
        returnType === TaxReturnType.BUSINESS
          ? await getDrakeConfig(userId, activeYear, returnId)
          : await getDrakeConfig(userId, activeYear);
      const drakeSectionConfig = resp.data?.config ?? {};
      const drakeNameSectionConfig = Object.assign({}, drakeSectionConfig);
      Object.keys(drakeSectionConfig).forEach((key) => {
        const sectionNameList = drakeSectionConfig[key].map((section) => {
          return `${section.screen_nick} - ${section.screen_desc}`;
        });
        drakeNameSectionConfig[key] = sectionNameList;
      });
      setDrakeSections(drakeSectionConfig);
      setDrakeNameSections(drakeNameSectionConfig);
    } catch (e) {
      notify(`Failed to fetch drake sections ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDrakeSections();
  }, []);

  const onDownloadDrakeFile = async () => {
    try {
      setShowLoading(true);
      const drakeSelectedSectionsConfigList = Object.keys(
        drakeSelectedSections,
      ).map((section) => {
        const subSectionNickList = drakeSelectedSections[section].map(
          (subSection) => {
            return subSection.screen_nick;
          },
        );
        return [section, subSectionNickList];
      });
      const drakeSelectedSectionsConfig = Object.fromEntries(
        drakeSelectedSectionsConfigList,
      );

      await downloadDrakeFile({
        userId,
        activeYear,
        returnType,
        returnId,
        config: drakeSelectedSectionsConfig,
      });
      notify('Downloading Drake file', NotificationType.success);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      notify('Failed to download Drake file', NotificationType.error);
      console.error('Download failed:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
      }
    }
  };

  const sendToCCH = async () => {
    try {
      setIsLoading(true);
      await postCCHStatus({
        fly_user_id: userId,
        year: activeYear,
        config: selectedSections,
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        setErrorOverlay({
          error: e?.response?.data?.error_msg ?? '',
          isVisible: true,
        });
      }
      notify(`Failed to send to cch ${e}`, NotificationType.error);
    } finally {
      dispatch(fetchCCHStatus(userId, activeYear));
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isLoaded ? (
        <>
          <Skeleton style={{marginBottom: 24}} height={40} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
          <Skeleton style={{marginBottom: 12}} height={80} width={'100%'} />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography style={{fontWeight: 800, fontSize: 18}}>
              Send to CCH/Drake
            </Typography>
            <div style={{display: 'flex'}}>
              <DSButton
                type="secondary"
                disabled={isLoading}
                onClick={() =>
                  integrator === Integrator.DRAKE
                    ? setDrakeSelectedSections({...drakeSections})
                    : setSelectedSections({...sectionWiseSubsection})
                }
                text="Select all"
                style={{marginRight: 8}}
              />
              <DSButton
                type="secondary"
                disabled={isLoading}
                onClick={() =>
                  integrator === Integrator.DRAKE
                    ? setDrakeSelectedSections({})
                    : setSelectedSections({})
                }
                text="Clear all"
                style={{marginRight: 8}}
              />
              <Autocomplete
                onChange={handleOnChange}
                disablePortal
                id="combo-box-demo"
                options={Object.values(Integrator)}
                value={integrator}
                fullWidth
                sx={{width: 136}}
                renderInput={(params) => <TextField {...params} />}
              />
              <DSButton
                type="primary"
                disabled={isLoading}
                onClick={
                  integrator === Integrator.DRAKE
                    ? () => onDownloadDrakeFile()
                    : sendToCCH
                }
                text={
                  integrator === Integrator.DRAKE
                    ? 'Download Drake file'
                    : 'Sync to CCH'
                }
                style={{width: '166px', marginLeft: '8px'}}
              />
              <FullScreenLoading open={showLoading} />
            </div>
          </div>
          <CopyText
            label={returnType === TaxReturnType.BUSINESS ? 'EIN' : 'SSN'}
            value={
              returnType === TaxReturnType.BUSINESS
                ? ein
                : taxProfileMap[TaxProfileQuestion.SSN]
            }
          />
          <div
            style={{
              overflowY: 'auto',
              height: 'calc(100% - 50px)',
              marginTop: 20,
            }}>
            {drakeNameSections && (
              <CCHSections
                drakeSections={drakeSections}
                drakeNameSections={drakeNameSections}
                drakeSelectedSections={drakeSelectedSections}
                integrator={integrator}
                setDrakeSelectedSections={setDrakeSelectedSections}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
                isLoading={isLoading}
              />
            )}
          </div>
        </>
      )}
      <SendToCCHErrorOverlay
        isOpen={errorOverlay.isVisible}
        error={errorOverlay.error}
        onClose={() => setErrorOverlay({error: '', isVisible: false})}
      />
    </>
  );
};

export default CCHAndDrake;
