import React from 'react';
import {List, Datagrid, TextField, EmailField} from 'react-admin';
import Button from '@material-ui/core/Button';

import {rowClickToRelatedExpenses} from './UserList';
export const NewPlaidAccountList = (props) => {
  return (
    <List {...props} perPage={50} bulkActionButtons={<Button />}>
      <Datagrid rowClick={rowClickToRelatedExpenses}>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="name" />
        <TextField source="due_hours" />
        <TextField source="card_wise_data" />
        <TextField source="total_txns" />
        <TextField source="reviewer_name" label="Reviewer" />
      </Datagrid>
    </List>
  );
};
