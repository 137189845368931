import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {selectNotificatioStatus} from 'src/store/app/app.selector';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notify = () => {
  const dispatch = useDispatch();
  const {isVisible, message, type} = useSelector(selectNotificatioStatus);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      setAppState(AppReducerStates.notifcationStatus, {
        isVisible: false,
        message,
        type,
      }),
    );
  };

  return (
    <Snackbar open={isVisible} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{width: '100%'}}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default Notify;
