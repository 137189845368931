import React, {useState} from 'react';
import {TextField, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {sendDocForReviewEsign} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';
import {MANDATORY_JURISDICTION} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

interface ManualSignNowIdPopupProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const ManualSignNowIdPopup = ({
  url,
  isOpen,
  onClose,
}: ManualSignNowIdPopupProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const [signNowId, setSignNowId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();
  const dispatch = useDispatch();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  const onSend = async () => {
    try {
      setIsLoading(true);
      await sendDocForReviewEsign({
        user: userId,
        year: activeYear,
        filename: currentReturn.document_name,
        jurisdiction: MANDATORY_JURISDICTION,
        sign_now_document_id: signNowId,
        return_id: returnId,
        return_type: currentReturn.return_type,
      });
      await dispatch(fetchTaxReturns(userId, activeYear));
      onClose();
      navigateToTaxReturnDetailedView(
        returnId,
        currentReturn?.return_type,
        TaxReturnStatus.REVIEW_ESIGN,
      );
      notify('Document sent for review esign', NotificationType.success);
    } catch (e) {
      notify(`Failed to send ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PopUp
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonTitle="Send"
      primaryButtonDisabled={!signNowId || isLoading}
      primaryButtonOnClick={onSend}
      secondaryButtonTitle="Cancel"
      secondaryButtonDisabled={isLoading}
      secondaryButtonOnClick={onClose}>
      <Typography style={{fontSize: 20, fontWeight: 800}}>
        Enter sign now doc id
      </Typography>
      <Typography
        style={{
          fontSize: 13,
          marginTop: 4,
          color: themmeColor.grey,
          marginBottom: 12,
        }}>
        You need to enter sign now document id to enable e-signing for the
        users. Make sure
        <br />
        you are copying the id properly from sign now.
      </Typography>
      <TextField
        fullWidth
        label="Sign now document id"
        type={'text'}
        style={{marginBottom: 16}}
        variant="outlined"
        value={signNowId}
        onChange={(e) => setSignNowId(e.target.value)}
      />
    </PopUp>
  );
};

export default ManualSignNowIdPopup;
