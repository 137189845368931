import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {SET_TAX_PROFILE, UPDATE_TAX_PROFILE} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {SUPPORTED_YEARS} from '../globals/globals.reducer';

export interface TaxProfileQuestionType {
  question_id: TAX_PROFILE_FIELDS;
  answer: any;
}

let defaultState: {[year: number]: TaxProfileQuestionType[]} = {};
SUPPORTED_YEARS.forEach(
  (year) => (defaultState[year] = [] as TaxProfileQuestionType[]),
);

const otherDefaultStates = {
  status: REDUX_STATUS.INIT,
  loaded: false,
};

const initialState = {
  ...otherDefaultStates,
  ...defaultState,
} as typeof defaultState & typeof otherDefaultStates;

export const taxProfile = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_TAX_PROFILE:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_TAX_PROFILE:
      const year = action.payload.year;
      const {questions} = action.payload;
      return {
        ...state,
        [year]: state[year].map((question) => {
          const updatedQuestion = questions.find(
            (payloadQuestion) =>
              payloadQuestion.question_id === question.question_id,
          );
          if (updatedQuestion) {
            return {
              ...question,
              answer: updatedQuestion.answer,
            };
          }
          return question;
        }),
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxProfileStateType = typeof initialState;
