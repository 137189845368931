import React, {useCallback} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';

interface DateFieldProps extends InputWrapperBaseType {
  value: string | null;
  onChangeValue: (newValue: string | null) => void;
}

const DateField = ({
  title,
  value,
  onChangeValue,
  validators,
}: DateFieldProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const updatedValue = e.target.value;
      if (typeof updatedValue === 'string' && updatedValue.length > 0) {
        onChangeValue(updatedValue);
      } else {
        onChangeValue(null);
      }
    },
    [onChangeValue],
  );
  return (
    <div>
      <InputWrapper
        title={title}
        actualValue={value}
        type="date"
        onChange={onChange}
        value={value === null || value === undefined ? '' : value}
        onClick={(e) => e.currentTarget.showPicker()}
        validators={validators}
      />
    </div>
  );
};

export default DateField;
