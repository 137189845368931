import {useNavigate} from 'react-router-dom';
import {getCpaCenterV2Step} from 'src/CpaCenterList/components/cpaList.utils';
import {TaxProfileSection} from 'src/constants/constants';
import {PartnershipRoutes, RouteParts} from 'src/constants/routeName';
import useCurrentUserId from './useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const usePartnershipNavigation = () => {
  const navigate = useNavigate();

  const {userId: uid} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const navigateToPartnersList = () => {
    navigate(PartnershipRoutes.PartnersList);
  };

  const navigateToTaxReturnsList = () => {
    navigate(PartnershipRoutes.UsersList);
  };

  const _getUserAndYearParsedUrl = (
    url: string,
    userId: number,
    year: number,
  ) => {
    return url
      .replace(RouteParts.UserId, `${userId}`)
      .replace(RouteParts.Year, `${year}`);
  };

  const navigateToReturnDetails = ({
    userId = uid,
    year = activeYear,
    status,
    newTab = false,
  }: {
    userId: number;
    year: number;
    status: any;
    newTab?: boolean;
  }) => {
    const step = getCpaCenterV2Step(status);
    const suffixUrl =
      _getUserAndYearParsedUrl(PartnershipRoutes.User, userId, year) +
      `/${step}`;
    if (newTab) {
      const url = `${window.location.origin}${suffixUrl}`;
      window.open(url);
      return;
    }
    navigate(suffixUrl);
  };

  const navigateToDocumentList = () => {
    const url = _getUserAndYearParsedUrl(
      PartnershipRoutes.UserDocuments,
      uid,
      activeYear,
    );
    navigate(url);
  };

  const navigateToDocumentReview = (docId: number) => {
    const url = _getUserAndYearParsedUrl(
      PartnershipRoutes.UserDocumentReview,
      uid,
      activeYear,
    ).replace(RouteParts.DocumentId, `${docId}`);
    navigate(url);
  };

  const navigateToTaxProfileReview = () => {
    const url = _getUserAndYearParsedUrl(
      PartnershipRoutes.TaxProfileReview,
      uid,
      activeYear,
    );
    navigate(url);
  };

  const navigateToTaxProfileSection = (section: TaxProfileSection) => {
    const url =
      _getUserAndYearParsedUrl(
        PartnershipRoutes.TaxProfileReview,
        uid,
        activeYear,
      ) + `/${section}`;
    navigate(url);
  };

  const navigateToPartnershipReviewEsign = () => {
    const url = _getUserAndYearParsedUrl(
      PartnershipRoutes.ReviewEsign,
      uid,
      activeYear,
    );
    navigate(url);
  };

  const navigateToPartnershipTaxReturnDetailedView = (
    returnId: number,
    returnType: TaxReturnType,
    step: TaxReturnStatus,
  ) => {
    const url = _getUserAndYearParsedUrl(
      PartnershipRoutes.ReviewEsignReturnStep,
      uid,
      activeYear,
    )
      .replace(RouteParts.ReturnId, `${returnId}-${returnType}`)
      // hardcoding the status as other sections are not
      // needed for now in the partnership section
      .replace(RouteParts.TaxReturnSection, TaxReturnStatus.SEND_TO_CCH);
    navigate(url);
  };

  return {
    navigateToPartnersList,
    navigateToTaxReturnsList,
    navigateToReturnDetails,
    navigateToDocumentReview,
    navigateToTaxProfileReview,
    navigateToDocumentList,
    navigateToTaxProfileSection,
    navigateToPartnershipReviewEsign,
    navigateToPartnershipTaxReturnDetailedView,
  };
};
