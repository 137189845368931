import React, {useState} from 'react';
import {TableBody, TableCell, TableRow} from '@mui/material';
import {Link} from 'react-router-dom';

import {TAX_FILING_STATUS, themmeColor} from 'src/constants/constants';
import {
  BACKEND_RESPONSE_KEYS,
  CPA_LIST_COLUMNS,
  getCpaCenterV2Step,
  Record,
} from 'src/CpaCenterList/components/cpaList.utils';
import useIsCpaCenterV2 from 'src/CpaCenterV2/hooks/useIsCpaCenterV2';
import AllReturnStatusBox from 'src/CpaCenterList/components/AllReturnStatusBox';

const CpaListTableRow = ({
  record,
  tableData,
  getRedirectRoute,
}: {
  record: Record;
  tableData: {
    label: CPA_LIST_COLUMNS;
    render: (record: Record) => JSX.Element | null;
    backendResponseKey: BACKEND_RESPONSE_KEYS;
  }[];
  getRedirectRoute: () => string;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);

  return (
    <TableRow
      component={Link}
      to={getRedirectRoute()}
      style={{
        backgroundColor: isFocused ? themmeColor.focusedBlue : undefined,
        textDecoration: 'none',
        position: 'relative',
      }}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}>
      {tableData.map((cell) => (
        <TableCell
          align={'left'}
          onMouseEnter={() => {
            if (cell.backendResponseKey === BACKEND_RESPONSE_KEYS.STATUS) {
              setShowStatusModal(true);
            }
          }}
          onMouseLeave={() => {
            if (cell.backendResponseKey === BACKEND_RESPONSE_KEYS.STATUS) {
              setShowStatusModal(false);
            }
          }}>
          <div>{cell.render(record)}</div>
        </TableCell>
      ))}
      {showStatusModal && (
        <AllReturnStatusBox
          key={`return-status-modal-${showStatusModal}`}
          isVisible={showStatusModal}
          dataArray={[
            {
              business_name: 'Personal',
              status: record[BACKEND_RESPONSE_KEYS.STATUS],
            },
            ...record[BACKEND_RESPONSE_KEYS.BUSINESS_RETURN_DATA],
          ]}
        />
      )}
    </TableRow>
  );
};

const ListTableBody = ({
  recordsArray,
  tableData,
  getRedirectRoute,
  flyUserIdFieldName,
  cpaReturnStatusFieldName,
  isTaxFilingTable = false,
}: {
  recordsArray: Array<any>;
  tableData: {
    label: CPA_LIST_COLUMNS;
    render: (record: Record) => JSX.Element | null;
    backendResponseKey: BACKEND_RESPONSE_KEYS;
  }[];
  getRedirectRoute: (params: {
    flyfin_user_id: number;
    query_id?: number;
  }) => string;
  flyUserIdFieldName: string;
  cpaReturnStatusFieldName?: string;
  isTaxFilingTable?: boolean;
}) => {
  const {isCpaCenterV2} = useIsCpaCenterV2();

  return (
    <>
      <TableBody>
        {recordsArray.map((record) => (
          <CpaListTableRow
            record={record}
            tableData={tableData}
            getRedirectRoute={() => {
              const flyUserId = record[flyUserIdFieldName];

              const params = {
                flyfin_user_id: flyUserId,
                query_id: record?.id,
              };

              let route = getRedirectRoute(params);
              if (
                isCpaCenterV2 &&
                isTaxFilingTable &&
                cpaReturnStatusFieldName
              ) {
                const cpaReturnStatus = record[
                  cpaReturnStatusFieldName
                ] as TAX_FILING_STATUS;
                route += `/${getCpaCenterV2Step(cpaReturnStatus)}`;
              }
              return route;
            }}
          />
        ))}
      </TableBody>
    </>
  );
};

export default ListTableBody;
