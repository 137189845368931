import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';

interface CompletedRibbonProps {
  text: string;
  height?: any;
  style?: React.CSSProperties
}

const CompletedRibbon = ({text, height = '10vh', style = {}}: CompletedRibbonProps) => {
  return (
    <Typography
      style={{
        fontSize: 20,
        fontWeight: 600,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flex: 1,
        backgroundColor: themmeColor.successGreen,
        height,
        marginLeft: -12,
        ...style,
      }}>
      {text}
    </Typography>
  );
};

export default CompletedRibbon;
