import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ALL_FORM_TYPES, IP_PIN_LENGTH} from 'src/constants/constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectTaxForms} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfile} from 'src/store/taxProfile/taxProfile.selector';
import {FILING_STATUS_ANSWER} from '../TaxProfileSections/TaxProfileSections.constants';
import {IP_PIN_TYPES} from '../TaxReturns/TaxReturns.types';

const isValidIpPin = (pin: any) => {
  return typeof pin === 'string' && pin.length === IP_PIN_LENGTH;
};

export const useIPPinDetails = () => {
  const {activeYear} = useActiveYear();
  const {[activeYear]: taxProfile} = useSelector(selectTaxProfile);

  const tp = useMemo(() => {
    const questions = [
      TAX_PROFILE_FIELDS.HAS_IP_PIN,
      TAX_PROFILE_FIELDS.SELF_IP_PIN,
      TAX_PROFILE_FIELDS.SPOUSE_IP_PIN,
      TAX_PROFILE_FIELDS.FILING_STATUS,
      TAX_PROFILE_FIELDS.LEGAL_NAME,
      TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME,
    ];
    const _info: any = {};

    questions.forEach((question) => {
      _info[question] = taxProfile.find(
        (q) => q.question_id === question,
      )?.answer;
    });

    return _info;
  }, [taxProfile]);

  const taxforms = useSelector(selectTaxForms);

  const dependents = useMemo(
    () => taxforms.filter((tf) => tf.formType === ALL_FORM_TYPES.DEPENDENT),
    [taxforms],
  );

  const getName = useCallback(
    (key: string | number) => {
      if (key === IP_PIN_TYPES.SELF) {
        const name = tp[TAX_PROFILE_FIELDS.LEGAL_NAME];
        return `${name?.[FIELD_ID.FIRST_NAME]} ${name?.[FIELD_ID.LAST_NAME]}`;
      } else if (key === IP_PIN_TYPES.SPOUSE) {
        const name = tp[TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME];
        return `${name?.[FIELD_ID.FIRST_NAME]} ${
          name?.[FIELD_ID.LAST_NAME]
        } (Spouse)`;
      } else {
        const formData = dependents.find(
          (dep) => dep.taxFormId === key,
        )?.formData;
        return `${formData?.[TaxFormFieldIds.DEPENDENT_FIRST_NAME]} ${
          formData?.[TaxFormFieldIds.DEPENDENT_LAST_NAME]
        } (Dependant)`;
      }
    },
    [tp, dependents],
  );

  const {ipPins, hasSubmittedIpPins} = useMemo(() => {
    let _ipPins: {
      id: string | number;
      name: string;
      pin: string;
    }[] = [];

    const self = tp[TAX_PROFILE_FIELDS.SELF_IP_PIN];
    const spouse = tp[TAX_PROFILE_FIELDS.SPOUSE_IP_PIN];

    const depIds = dependents.map((dep) => dep.taxFormId).sort();

    const pinIds = [IP_PIN_TYPES.SELF, IP_PIN_TYPES.SPOUSE, ...depIds];

    pinIds.forEach((key) => {
      let pin = '';

      if (key === IP_PIN_TYPES.SELF) {
        pin = self;
      } else if (
        key === IP_PIN_TYPES.SPOUSE &&
        tp[FIELD_ID.FILING_STATUS] ===
          FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY
      ) {
        pin = spouse;
      } else {
        pin = dependents.find((dep) => dep.taxFormId === key)?.formData?.[
          TaxFormFieldIds.IP_PIN
        ];
      }

      if (isValidIpPin(pin)) {
        _ipPins.push({
          id: key,
          name: getName(key),
          pin,
        });
      }
    });

    let _hasSubmittedIpPins = false;
    const hasIpPin = tp[TAX_PROFILE_FIELDS.HAS_IP_PIN];
    if (typeof hasIpPin === 'boolean' && !hasIpPin) {
      _hasSubmittedIpPins = true;
    }
    if (typeof hasIpPin === 'boolean' && hasIpPin && _ipPins.length > 0) {
      _hasSubmittedIpPins = true;
    }

    if (_hasSubmittedIpPins && !hasIpPin) {
      _ipPins = [];
    }

    return {hasSubmittedIpPins: _hasSubmittedIpPins, ipPins: _ipPins};
  }, [tp, dependents]);

  return {
    ipPins,
    hasSubmittedIpPins,
  };
};
