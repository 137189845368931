export enum PartnershipFilters {
  fly_user_id = 'fly_user_id',
  name = 'name',
  email = 'email',
  return_status = 'return_status',
  tax_extension_status = 'tax_extension_status',
  year = 'year',
  assigned_cpa = 'assigned_cpa',
  reviewer = 'reviewer',
  activity = 'activity',
  partner = 'partner',
  page = 'page',
  page_size = 'page_size',
}

export enum PartnershipFilterType {
  single = 'single',
  multi = 'multi',
}

export enum PartnshipFilterLocation {
  home = 'home',
  filter_pop_up = 'filter_pop_up',
  footer = 'footer',
}

export enum PartnershipMenuOptionTypes {
  ManagePartners = 'ManagePartners',
  ExportColumns = 'ExportColumns',
  ExportExtensionData = 'ExportExtensionData',
}
