import {useState} from 'react';
import {Typography} from '@mui/material';
import {FileUploader} from 'react-drag-drop-files';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import {NotificationType} from 'src/store/app/app.reducer';

const DEFAULT_SUPPORTED_FILE_TYPES = ['pdf', 'jpg', 'png', 'jpeg'];

interface UploadDocumentProps {
  handleUploadFile: (file: any) => Promise<any>;
  supportedFileTypes?: string[];
}

const UploadDocument = ({
  handleUploadFile,
  supportedFileTypes = DEFAULT_SUPPORTED_FILE_TYPES,
}: UploadDocumentProps) => {
  const [isDropping, setIsDropping] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const selectFile = (file: any) => {
    setFile(file);
  };
  const {notify} = useNotify();

  const onClickUpload = async () => {
    try {
      setIsUploading(true);
      await handleUploadFile(file);
    } catch (e) {
      alert(`Somethin went wrong when uploading document ${e}`);
    } finally {
      setIsUploading(false);
    }
  };

  const notifyFileTypeError = (err: string) => {
    notify(
      `${err}, Supported type ${supportedFileTypes.join(', ')}`,
      NotificationType.error,
    );
  };

  return (
    <>
      <FullScreenLoading open={isUploading} />
      {file !== null && (
        <Box
          style={{
            height: '100%',
            backgroundColor: themmeColor.cpaCenterV2Bg,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography
            style={{fontSize: 20, fontWeight: 800, marginBottom: 20}}>
            "<span style={{color: themmeColor.errorRed}}>{file.name}</span>" is
            ready to upload
          </Typography>
          <div style={{display: 'flex'}}>
            <DSButton
              type="secondary"
              onClick={() => selectFile(null)}
              text={'Clear'}
              style={{marginRight: 20}}
              disabled={isUploading}
            />
            <DSButton
              type={'primary'}
              onClick={onClickUpload}
              text={'Upload'}
              disabled={isUploading}
            />
          </div>
        </Box>
      )}
      {file === null && (
        <FileUploader
          multiple={false}
          dropMessageStyle={{backgroundColor: '#00000000'}}
          hoverTitle={<h2 color={themmeColor.black}>Drop the file</h2>}
          onDraggingStateChange={setIsDropping}
          types={supportedFileTypes}
          fileOrFiles={file}
          onTypeError={notifyFileTypeError}
          handleChange={selectFile}>
          <Box
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: themmeColor.cpaCenterV2Bg,
              height: '100%',
              opacity: isDropping ? 0.1 : 1,
            }}>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 12}}>
                <UploadCloudIcon />
              </div>
              <Typography style={{fontWeight: 600}}>
                Drag & drop the file
              </Typography>
            </div>
            <Typography style={{marginBottom: 12}}>OR</Typography>
            <DSButton
              type="primary"
              onClick={() => {}}
              text="Select file"
              style={{width: '30%'}}
            />
          </Box>
        </FileUploader>
      )}
    </>
  );
};

export default UploadDocument;
