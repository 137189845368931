import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {COMMON_COMPONENTS_ID, themmeColor} from 'src/constants/constants';
import {resetRedux} from 'src/store/common.actions';
import useBoiSteps from './hooks/useBoiSteps';
import Steps from 'src/DesignSystem/Steps/Steps';
import {BoiFilingCenterRoutesParts} from 'src/constants/routeName';
import {fetchBoiFilingData} from 'src/store/boi/boi.actions';
import BoiSummaryBar from './BoiSummaryBar';
import ViewDocumentBoi from './components/ViewDocumentBoi';
import DocumentsReviewBoi from './components/DocumentsReviewBoi';
import BoiProfileReview from './components/BoiProfileReview';
import {fetchTaxProfileForGivenYear} from 'src/store/taxProfile/taxProfile.actions';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';

const BoiFilingUserInfo = () => {
  const {userId} = useCurrentUserId();
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    Promise.all([dispatch(fetchBoiFilingData(userId))]).then(
      ([userBoiData]) => {
        dispatch(fetchTaxProfileForGivenYear(userId, userBoiData.year));
        dispatch(fetchDocumentsForGivenYear(userId, userBoiData.year));
        dispatch(fetchTaxFormsForGivenYear(userId, userBoiData.year));
        dispatch(fetchTaxProfileStatusForGivenYear(userId, userBoiData.year));
      },
    );
    return () => {
      dispatch(resetRedux());
    };
  }, [userId]);

  const {spaceLeftBelowUserSummaryBar} = useLayout();

  const {steps, isLoading} = useBoiSteps();

  return (
    <div>
      <BoiSummaryBar />
      <div
        style={{
          display: 'flex',
          backgroundColor: themmeColor.cpaCenterV2Bg,
          width: '100%',
          height: spaceLeftBelowUserSummaryBar,
          paddingLeft: 8,
          flexDirection: 'column',
          paddingTop: 20,
        }}>
        <div id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_TAX_FILING_STEPS}>
          <Steps
            data={steps}
            onClick={(step) => {
              navigate(step.props.section);
            }}
            isLoading={isLoading}
          />
        </div>
        <Routes>
          <Route
            path={`/${BoiFilingCenterRoutesParts.DocumentReview}/${BoiFilingCenterRoutesParts.DocumentId}`}
            element={<ViewDocumentBoi />}
          />
          <Route
            path={`/${BoiFilingCenterRoutesParts.DocumentReview}`}
            element={<DocumentsReviewBoi />}
          />
          <Route
            path={BoiFilingCenterRoutesParts.ProfileReview}
            element={<BoiProfileReview />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default BoiFilingUserInfo;
