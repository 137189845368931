import {themmeColor} from 'src/constants/constants';

const DocumentIcon = ({height = 24, width = 24, color = themmeColor.grey}) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1791 1.81208C12.9486 1.71671 12.7026 1.6665 12.4519 1.6665H5.00059C4.08012 1.6665 3.33325 2.49561 3.33325 3.51836V16.4813C3.33325 17.5041 4.07944 18.3332 4.99992 18.3332H14.9999C15.9204 18.3332 16.6666 17.5041 16.6666 16.4813V6.37187C16.6666 6.11701 16.6288 5.86646 16.5565 5.6295M13.1791 1.81208C13.4598 1.92822 13.7174 2.11132 13.9322 2.35229L16.0634 4.74284C16.2884 4.99524 16.4559 5.29917 16.5565 5.6295M13.1791 1.81208L12.9457 4.02152C12.8328 5.09032 13.746 5.98499 14.8123 5.85013L16.5565 5.6295M7.08325 9.1665H12.9166M7.08325 12.4998H12.9166"
      stroke={color}
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DocumentIcon;