import React, {useEffect, useRef, useState, cloneElement} from 'react';
import {
  List,
  TextField,
  DateField,
  BooleanField,
  useMutation,
  useNotify,
  ExportButton,
} from 'react-admin';
import {
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  SelectField,
  SelectInput,
  DateInput,
} from 'react-admin';
import {
  TopToolbar,
  Button,
  sanitizeListRestProps,
  useQueryWithStore,
  Loading,
  Error,
  SelectArrayInput,
  ReferenceArrayInput,
  FunctionField,
} from 'react-admin';
import {Filter} from 'react-admin';
import CustomizableDatagrid from '../common/CustomizableDatagrid';
import BulkReviewButton from './unreviewed/BulkReviewButton';
import MerchantSuggestionButton from './MerchantSuggestion';
import ExpenseAside from './ExpenseAside';
import BulkClassifyButton from './BulkClassifyButton';
import ExportData from '../tax_engine/ExportData';
import {
  DeductionClassChoices,
  LastModifierChoices,
  TxnStateChoices,
  TxnBucketChoices,
  TxnReviewedChoices,
  YearChoices,
  NotificationStateChoices,
  CurrentAmountChoices,
} from '../constants/constants';
import BulkNotifyButton from './BulkNotifyButton';
import {getMealsCategoriesAll} from '../common/ExpenseCategoriesUtils';
import {ExpensePagination} from './ExpensePagination';
import {makeStyles} from '@material-ui/core/styles';
import {throttle} from 'lodash';
import MyDatagridBody from './CustomDataGridBody';
import {ImportButton} from 'react-admin-import-csv';
const moment = require('moment-timezone');
export const MerchantInput = ({userId, years}) => {
  const {data, loading, error} = useQueryWithStore({
    type: 'getUniqueMerchants',
    resource: 'expenses',
    payload: {user_id: userId, year: years},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <AutocompleteArrayInput
      choices={data}
      source="updated_merchant_name"
      optionText={(choice) => `${choice.merchant_name} - ${choice.count}`}
      optionValue="merchant_name"
      allowEmpty={true}
    />
  );
};

export const AlgoMerchantInput = ({userId, years}) => {
  const {data, loading, error} = useQueryWithStore({
    type: 'getUniqueAlgoMerchants',
    resource: 'expenses',
    payload: {user_id: userId, year: years},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <AutocompleteArrayInput
      choices={data}
      source="algo_merchant_name"
      optionText={(choice) => `${choice.algo_merchant_name} - ${choice.count}`}
      optionValue="algo_merchant_name"
      allowEmpty={true}
    />
  );
};

export const MerchantClusterInput = ({userId, years}) => {
  const {data, loading, error} = useQueryWithStore({
    type: 'getUniqueMerchantClusters',
    resource: 'expenses',
    payload: {user_id: userId, year: years},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <AutocompleteArrayInput
      choices={data}
      source="merchant_cluster"
      optionText={(choice) =>
        `${choice.count} - ${choice.merchant_cluster__metadata__mit}`
      }
      optionValue="merchant_cluster"
      allowEmpty={true}
    />
  );
};

export const ExpenseCategoryInput = ({userId, years}) => {
  const {data, loading, error} = useQueryWithStore({
    type: 'getUniqueExpenseCategories',
    resource: 'expenses',
    payload: {user_id: userId, year: years},
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <AutocompleteInput
      label="Updated Category"
      source="updated_expense_category"
      choices={data}
      optionText={(choice) => `${choice.expense_category} - ${choice.count}`}
      optionValue="expense_category"
      allowEmpty={true}
    />
  );
};

export const isTaxFiledForYear = (year, onbData) => {
  if (onbData === null) return false;
  const onbAnswer = Object.values(onbData).filter(
    (onb) => onb.question_id === 22,
  )[0]?.answer;
  return typeof onbAnswer === 'boolean'
    ? year == 2020
      ? onbAnswer
      : false
    : Boolean(onbAnswer && onbAnswer[`year${year}`]);
};

const ExpenseFilter = (props) => {
  const {onbData} = {...props};
  return (
    <Filter {...props}>
      {/* <TextInput label="User" source="user_id" alwaysOn/>
        <TextInput label="Email" source="q" alwaysOn /> */}
      <ReferenceInput
        label="User"
        source="user_id"
        reference="users"
        allowEmpty={false}
        alwaysOn>
        <SelectInput optionText="email" allowEmpty={false} />
      </ReferenceInput>
      {props.filterValues.user_id && (
        <ReferenceArrayInput
          key={props.filterValues.user_id}
          label="Card"
          source="account_id"
          reference="plaid_account"
          filter={{user_id: props.filterValues.user_id}}
          allowEmpty={true}
          alwaysOn>
          <SelectArrayInput
            optionText={(choice) =>
              `${choice.plaid_institution.name} - ${choice.name} - ${
                choice.mask
              } (${choice.card_usage_type}) - ${
                choice.unlinked
                  ? '✕'
                  : choice.auth_refresh_needed
                  ? '❗❗'
                  : '✅'
              } - ${choice.created_at.split('T')[0]}`
            }
            allowEmpty={true}
          />
        </ReferenceArrayInput>
      )}
      <SelectArrayInput
        source="year"
        choices={YearChoices}
        optionText={(choice) =>
          `${choice.name} ${
            isTaxFiledForYear(choice.name, onbData) ? ' ✅' : ''
          }`
        }
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      {props.filterValues.user_id && (
        <MerchantInput
          label="Merchant Input"
          userId={props.filterValues['user_id']}
          years={props.filterValues['year']}
          alwaysOn
        />
      )}
      {props.filterValues.user_id && (
        <AlgoMerchantInput
          userId={props.filterValues['user_id']}
          years={props.filterValues['year']}
          alwaysOn
        />
      )}
      {props.filterValues.user_id && (
        <MerchantClusterInput
          userId={props.filterValues['user_id']}
          years={props.filterValues['year']}
        />
      )}
      <SelectInput
        label="Updated Classifi.."
        source="updated_classification_status"
        choices={DeductionClassChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        source="is_reviewed"
        choices={TxnReviewedChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        source="bucket"
        choices={TxnBucketChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
      />
      <SelectInput
        source="last_modified_by"
        choices={LastModifierChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        source="current_amount_type"
        label="Current Amount"
        choices={CurrentAmountChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        label="User Classification"
        source="txnclassifierdata__user_classification_status"
        choices={DeductionClassChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      {props.filterValues.user_id && (
        <ExpenseCategoryInput
          userId={props.filterValues['user_id']}
          years={props.filterValues['year']}
          alwaysOn
        />
      )}
      <DateInput source="txn_date_after" />
      <DateInput source="txn_date_before" />
    </Filter>
  );
};

const postRowClick = (id, basePath) => {
  window.open(`#/expenses/${id}`);
};
const ExpenseEditButton = ({record, basePath}) => {
  return (
    <Button
      label="Details"
      onClick={() => postRowClick(record['id'], basePath)}
    />
  );
};

const ReviewPlaidAccount = ({accountIds}) => {
  const final_account_ids = accountIds === undefined ? [] : accountIds;
  const notify = useNotify();
  const [markReviewed, {loadingNow}] = useMutation(
    {
      type: 'mark_reviewed',
      resource: 'plaid_account',
      payload: {
        account_ids: final_account_ids,
      },
    },
    {
      onSuccess: () => {
        notify('Review Plaid Account successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Review Plaid Account failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  return (
    <Button
      label="Mark Reviewed"
      onClick={markReviewed}
      disabled={final_account_ids.length === 0}
    />
  );
};

const UrlField = ({record, source}) => (
  <a
    href={`https://www.google.com/search?q=${encodeURIComponent(
      record[source],
    )}`}
    style={{'text-decoration': 'none'}}
    target="_blank">
    {record[source]}
  </a>
);
const ListActions = (props) => {
  const config = {
    // Transform rows before anything is sent to dataprovider
    transformRows: (csvRows) =>
      csvRows.map((row) => ({...row, user_id: props?.filterValues?.user_id})),
  };
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    showFilter,
    maxResults,
    total,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* Add your custom actions */}
      {/* <Button
            onClick={() => { alert('Your custom action'); }}
            label="Show calendar"
        >
            <IconEvent />
        </Button> */}
      <ExportButton />
      <ImportButton {...props} {...config} />
      <ReviewPlaidAccount accountIds={filterValues['account_id']} />
      <ExportData userId={props.filterValues.user_id} />
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
};

const useStyles = makeStyles({
  sticky: {
    position: 'fixed',
    top: '15rem',
    left: '1rem',
    width: '170px',
  },
  addMargin: {
    marginRight: '1rem',
    marginBottom: '1rem',
  },
});

export const ExpenseBulkActionButtons = (props) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [isSticky, setSticky] = useState(false);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(
        ref.current.getBoundingClientRect().top <= 0 ||
          ref.current.getBoundingClientRect().top == 240,
      );
    }
  };

  const throttledHandleScroll = throttle(handleScroll, 300);

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', () => throttledHandleScroll);
    };
  }, []);
  return (
    <div ref={ref}>
      <div
        className={`${
          isSticky && props.selectedIds.length != 0
            ? classes.sticky
            : classes.leftAlign
        } `}>
        <BulkReviewButton
          className={classes.addMargin}
          label="Mark as Reviewed"
          {...props}
        />
        <BulkClassifyButton className={classes.addMargin} {...props} />
        <BulkNotifyButton className={classes.addMargin} {...props} />
      </div>
    </div>
  );
};

ExpenseBulkActionButtons.defaultProps = {
  selectedIds: [],
};

const getPendingDirtyName = (record) => {
  return record.plaid_txn?.pending ? record.plaid_txn.name : null;
};

const PostedDirtyName = ({record}) => {
  const postedDirtyName = !record.plaid_txn?.pending
    ? record.plaid_txn.name
    : record.posted_plaid_txn != null
    ? record.posted_plaid_txn.name
    : null;
  return (
    <a
      href={`https://www.google.com/search?q=${encodeURIComponent(
        postedDirtyName,
      )}`}
      style={{'text-decoration': 'none'}}
      target="_blank">
      {postedDirtyName}
    </a>
  );
};

export const TxnColumns = (props) => {
  const {loading} = props;
  if (loading) return <Loading />;
  return (
    <CustomizableDatagrid {...props} body={<MyDatagridBody {...props} />}>
      {/* <TextField source="id" /> */}
      {/* <TextField source="plaid_transaction_id" /> */}
      <TextField source="id" />
      <TextField label="User Id" source="fly_user_id" />
      <TextField label="User Name" source="name" />
      <UrlField label="Updated Merchant Name" source="updated_merchant_name" />
      <TextField source="no_of_txns" />
      <TextField
        source="classifier_data.algo_merchant_name"
        label="Algo Merchant Name"
      />
      <TextField
        label="Suggested Merchant Name"
        source="suggested_merchant_name"
      />
      <ExpenseEditButton source="details" />
      <MerchantSuggestionButton
        categoryMap={props.categoryMap}
        source="suggest merchant name"
      />
      <TextField
        source="classifier_data.merchant_name_reason"
        label="Merchant Reason"
      />
      <FunctionField
        source="blank_source"
        label="Pending Dirty Name"
        render={getPendingDirtyName}
      />
      <PostedDirtyName source="blank_source1" label="Posted Dirty Name" />
      <TextField source="original_amount" />
      <TextField source="current_amount" />
      <TextField source="tax_deductible_amount" />
      <DateField source="txn_date" />
      <TextField source="plaid_category" label="Plaid Category" />
      <TextField source="updated_expense_category" label="Updated Category" />
      <TextField
        source="classifier_data.algo_sub_category"
        label="Algo Category"
      />
      <TextField
        source="classifier_data.user_sub_category"
        label="User Category"
      />
      {/* <TextField source="expense_sub_category" label="Sub Category" /> */}
      <TextField
        source="classifier_data.category_reason"
        label="Category Reason"
      />
      <SelectField
        label="Updated Deduction?"
        source="updated_classification_status"
        choices={DeductionClassChoices}
      />
      <TextField
        source="classifier_data.algo_classification_status"
        label="Algo Classification Status"
      />
      <TextField
        source="classifier_data.user_classification_status"
        label="User Classification Status"
      />
      <TextField
        source="classifier_data.classification_reason"
        label="Classification Reason"
      />
      <SelectField
        label="Last Modifier"
        source="last_modified_by"
        choices={LastModifierChoices}
      />
      <SelectField label="State" source="state" choices={TxnStateChoices} />
      <BooleanField
        source="classifier_data.can_auto_notify"
        label="Auto notify"
      />
      <SelectField
        label="Notification Sent"
        source="notification_state"
        choices={NotificationStateChoices}
      />
      {/* <TextField source="plaid_txn.plaid_clean_merchant_name" label="Plaid Merchant" /> */}
      {/* <EmailField source="email" /> */}
      <FunctionField
        source="created_at"
        label="Created At(EST)"
        render={(record) =>
          moment
            .utc(record.created_at)
            .tz('EST')
            .format('DD/MM/YYYY, HH:MM:SS')
        }
      />
      <SelectField source="bucket" choices={TxnBucketChoices} />
      <BooleanField source="is_pending" />
    </CustomizableDatagrid>
  );
};

const ExpenseList = (props) => {
  const {
    data: taxonomy,
    loading: catLoading,
    error: catError,
  } = useQueryWithStore({
    type: 'getUniverse',
    resource: 'categorizer',
  });
  const [onbData, setOnbData] = useState(null);
  if (catLoading) return <Loading />;
  if (catError) return <Error />;
  if (!taxonomy) return null;
  const categoryMap = taxonomy['hierarchy'];
  const mealsCategories = getMealsCategoriesAll(
    taxonomy['expense_categories'],
    taxonomy['expense_categories_depr'],
  );
  return (
    <List
      {...props}
      sort={{field: 'merchant_name', order: 'ASC'}}
      filters={<ExpenseFilter onbData={onbData} />}
      filterDefaultValues={{user_id: '0'}}
      aside={<ExpenseAside userIdSource="user_id" setOnbData={setOnbData} />}
      actions={<ListActions />}
      bulkActionButtons={
        <ExpenseBulkActionButtons
          markReviewOnClassify={true}
          categoryMap={categoryMap}
        />
      }
      pagination={<ExpensePagination />}>
      <TxnColumns
        categoryMap={categoryMap}
        {...props}
        markReviewOnClassify={true}
        defaultColumns={[
          'updated_merchant_name',
          'details',
          'current_amount',
          'txn_date',
          'plaid_category',
          'updated_expense_category',
          // 'expense_sub_category',
          'updated_classification_status',
          'last_modified_by',
          'notification_state',
        ]}
      />
    </List>
  );
};

export default ExpenseList;
