import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {patchTaxFiling} from 'src/appApi';
import {BACKEND_RESPONSE_KEYS, EXTENSION_STATUS} from 'src/CpaCenterList/components/cpaList.utils';

interface ExtensionStatusPickerProps {
  currentExtensionStatus: EXTENSION_STATUS | '';
  taxFilingId: number | string;
  onSuccess: () => Promise<any>;
}

const ExtensionStatus = ({
  currentExtensionStatus,
  taxFilingId,
  onSuccess,
}: ExtensionStatusPickerProps) => {
  const handleOnChange = async (e: any, v: any) => {
    try {
      if (!v) {
        return;
      }
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {
          [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]:
            v.label === EXTENSION_STATUS.NULL ? null : v.label,
        },
      );
      await onSuccess();
    } catch (error) {
      alert(`Something went wrong ${error}`);
    } finally {
    }
  };

  const options = Object.values(EXTENSION_STATUS).map((label) => ({label}));

  const current =
    options.find(
      (currentOption) => currentExtensionStatus === currentOption.label,
    ) ?? null;

  return (
    <div style={{marginLeft: 20,  marginBottom: 16}}>
      <Autocomplete
        onChange={handleOnChange}
        disablePortal
        id="combo-box-demo"
        options={options}
        value={current}
        fullWidth
        sx={{width: 200}}
        renderInput={(params) => (
          <TextField {...params} label="Extension Status" />
        )}
      />
    </div>
  );
};

export default ExtensionStatus;
