import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import {
  BACKEND_RESPONSE_KEYS,
  MANNUALY_NON_ASSIGNABLE_TAX_RETURN_STATUS_IDS,
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {themmeColor} from 'src/constants/constants';
import {Autocomplete, Button, TextField, Typography} from '@mui/material';
import {patchTaxFiling} from 'src/appApi';

interface UpdateReturnStatusOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  currentReturnStatusId: TAX_FILING_STATUS;
  taxFilingId: number;
  onSuccess: () => void | Promise<void>;
}

const UpdateReturnStatusOverlay = ({
  isVisible,
  onClose,
  currentReturnStatusId,
  taxFilingId,
  onSuccess,
}: UpdateReturnStatusOverlayProps) => {
  const currentStatus = TAX_RETURN_STATUS_LABELS[currentReturnStatusId];

  const [activeStatus, setActiveStatus] = useState(currentStatus);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const taxReturnStatuses = Object.values(TAX_RETURN_STATUS_LABELS).filter(
    (status) =>
      !MANNUALY_NON_ASSIGNABLE_TAX_RETURN_STATUS_IDS.includes(status.id),
  );

  const handleOnChange = (e: any, v: any) => {
    setActiveStatus(v);
  };

  const handleStatusUpdate = async () => {
    // TODO: Add status update implementation
    setLoading(true);
    try {
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.STATUS]: activeStatus.id},
      );
      await onSuccess();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={stopDrilling}>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={isVisible}
        onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 540,
            padding: 24,
            borderRadius: 16,
            backgroundColor: themmeColor.white,
          }}>
          <Typography fontSize={20} fontWeight="600">
            Change return status
          </Typography>
          <Typography
            color={themmeColor.grey}
            style={{display: 'flex', marginTop: 12}}
            fontSize={14}>
            Do you want to change the return status from "
            <Typography
              color={themmeColor.grey}
              fontSize={14}
              fontWeight={'600'}>
              {currentStatus.label}
            </Typography>
            "
          </Typography>
          <Autocomplete
            onChange={handleOnChange}
            disablePortal
            id="combo-box-demo"
            options={taxReturnStatuses}
            value={activeStatus}
            fullWidth
            style={{marginTop: 16}}
            sx={{width: 300}}
            renderInput={(params) => (
              <TextField {...params} label="Return Status" />
            )}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              marginTop: 24,
            }}>
            <Button
              disabled={loading}
              onClick={handleClose}
              style={{flex: 0.45, height: 48}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleStatusUpdate}
              disabled={
                !activeStatus ||
                currentStatus.id === activeStatus.id ||
                loading
              }
              style={{flex: 0.45, height: 48}}
              variant="contained">
              Yes, Change it
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateReturnStatusOverlay;
