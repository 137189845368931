import {Typography} from '@mui/material';
import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import DSCheckBox from '../../DSCheckBox/DSCheckBox';

interface TransactionsDiffConfig {
  diffKeys: {0: keyof Transaction} & Array<keyof Transaction>;
  originalValues: {
    [txnId: number]: {
      [key in keyof Transaction]?: Transaction[key];
    };
  };
}

export enum SelectionMode {
  NONE = 'NONE',
  MULTI_SELECT = 'MULTI_SELECT',
}

interface TransactionListItemCommonProps {
  txn: Transaction;
  style?: React.CSSProperties;
  diffMode?: boolean;
  diffConfig?: TransactionsDiffConfig;
}

interface TransactionListItemNoSelectionProps {
  selectionMode: SelectionMode.NONE;
}
interface TransactionListItemSelectionProps {
  selectionMode: SelectionMode.MULTI_SELECT;
  isChecked: boolean;
  onChange: (newValue: boolean, txn: Transaction) => void;
  disabled?: boolean;
}

type TransactionListItemProps = TransactionListItemCommonProps &
  (TransactionListItemNoSelectionProps | TransactionListItemSelectionProps);

const TransactionListItem = ({
  txn,
  isChecked = false,
  onChange,
  style = {},
  selectionMode,
  disabled = false,
  diffMode = false,
  diffConfig,
}: TransactionListItemProps) => {
  const getDifferentialStyles = (isDifferent: boolean, isNew: boolean) => {
    if (!isDifferent) {
      return {fontSize: 13};
    }
    return {
      fontSize: 13,
      fontWeight: 600,
      color: isNew ? themmeColor.successGreen : themmeColor.errorRed,
    };
  };
  return (
    <div
      style={{
        padding: 16,
        ...style,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <div style={{display: 'flex'}}>
          {selectionMode === SelectionMode.MULTI_SELECT && (
            <DSCheckBox
              style={{marginRight: 12}}
              isChecked={isChecked}
              onChange={(isChecked) => onChange(isChecked, txn)}
              disabled={disabled}
            />
          )}
          <div>
            <Typography style={{fontWeight: 600}}>
              {txn.merchantName}
            </Typography>
            <Typography style={{fontSize: 12}}>{txn.txnDate}</Typography>
          </div>
        </div>
        <Typography>
          {CurrencyNumberFormat(txn.taxDeductibleAmount)}
        </Typography>
      </div>
      {diffMode && (
        <div style={{marginTop: 12}}>
          {diffConfig?.diffKeys.map((key) => {
            if (!diffConfig.originalValues[txn.txnId]) {
              return 'not found';
            }
            const oldValue = diffConfig.originalValues[txn.txnId][key];
            const currentValue = txn[key];
            const isDifferent = oldValue !== currentValue;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography style={getDifferentialStyles(isDifferent, false)}>
                  {oldValue}
                </Typography>
                <Typography style={getDifferentialStyles(isDifferent, true)}>
                  {currentValue}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Object.assign(TransactionListItem, {SelectionMode});
