import React from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {themmeColor} from 'src/constants/constants';
import {useCreateReturn} from './useCreateReturn';
import {CREATE_RETURN_STATUS} from './CreateReturn.types';

interface DragFilesOrManualReturnElementProps {}

const DragFilesOrManualReturnElement =
  ({}: DragFilesOrManualReturnElementProps) => {
    const {setStatus} = useCreateReturn();
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBlock: 14,
          backgroundColor: `${themmeColor.offWhite}4D`,
          marginBottom: 12,
          marginInline: 24,
          borderRadius: 12,
          borderStyle: 'dashed',
          borderWidth: 1,
          borderColor: themmeColor.grey,
        }}>
        <Text
          text="Drag and drop files here to create new user or "
          type={TEXT_TYPES.S}
          fontWeight={FONT_WEIGHTS.SemiBold}
          color={themmeColor.grey}
          containerStyle={{marginRight: 12}}
        />
        <DSButton
          text="Add details manually"
          type="primary"
          onClick={() => setStatus(CREATE_RETURN_STATUS.CREATE_MANUAL_RETURN)}
        />
      </div>
    );
  };

export default DragFilesOrManualReturnElement;
