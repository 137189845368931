import {Typography} from '@mui/material';
import React from 'react';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import TaxProfileSummary from 'src/CpaCenterV2/common/TaxProfileSummary/components/TaxProfileSummary';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';

interface SendDraftQueriesFinalPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  disabled: boolean;
}

const SendDraftQueriesFinalPopup = ({
  isOpen,
  onClose,
  onConfirm,
  disabled,
}: SendDraftQueriesFinalPopupProps) => {
  return (
    <PopUp
      primaryButtonTitle="Send to user"
      primaryButtonOnClick={onConfirm}
      primaryButtonDisabled={disabled}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={disabled}
      isOpen={isOpen}
      onClose={onClose}
      style={{width: 1000, height: 660}}>
      <Typography style={{fontSize: 20, fontWeight: 800}}>
        Send user queries
      </Typography>
      <Typography>
        Review and send the queries to the users in one go!
      </Typography>
      <div style={{display: 'flex'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 700,
            height: 500,
          }}>
          <ActionRequired
            types={[
              CreateQueryDrawerTab.TAX_PROFILE,
              CreateQueryDrawerTab.DOCUMENT,
              CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
              CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
            ]}
            onClickAddSideEffect={onClose}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            height: 500,
          }}>
          <TaxProfileSummary />
        </div>
      </div>
    </PopUp>
  );
};

export default SendDraftQueriesFinalPopup;
