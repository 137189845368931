import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {COMMON_COMPONENTS_ID, themmeColor} from 'src/constants/constants';
import {RouteName} from 'src/constants/routeName';
import Box from 'src/DesignSystem/Box/Box';
import BackIcon from 'src/icons/BackIcon';

import useNotify from 'src/DesignSystem/Notify/useNotify';
import {Mail, Smartphone} from '@mui/icons-material';
import LabelField from 'src/CpaCenterV2/common/UserSummaryBar/components/LabelField';
import {selectBoiData} from 'src/store/boi/boi.selectors';
import {SUBMIT_TYPE} from './types';
import {format} from 'date-fns';
import CollapseButton from 'src/CpaCenterV2/common/UserSummaryBar/components/CollapseButton';
import BoiStatusCell from './components/BoiStatusCell';
import {fetchBoiUserData} from 'src/store/boi/boi.actions';

const BoiSummaryBar = () => {
  const {data, loaded} = useSelector(selectBoiData);
  const {
    id: boiId,
    fly_user_id: userId,
    fly_user_name: userName,
    submit_type: submitType,
    user_submitted_at: userSubmittedAt,
    payment_status: paymentStatus,
    status: boiStatus,
    updated_at: updatedAt,
    fly_user_email: userEmail,
  } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!loaded);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const refreshInfo = () => {
    dispatch(fetchBoiUserData(userId));
  };

  useEffect(() => {
    setLoading(!loaded);
  }, [loaded]);

  const onClickBack = () => {
    navigate(`${RouteName.CpaCenterV2}${RouteName.BoiFilingCenter}`);
  };

  const summaryData = [
    {
      props: {
        label: 'User ID',
        value: userId,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Name',
        value: userName,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Mode',
        value: submitType,
      },
      Component: ({label, value}) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              alignItems: 'center',
            }}>
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 'bold',
                letterSpacing: 0.7,
                textTransform: 'uppercase',
                color: themmeColor.black60,
              }}>
              {label}
            </Typography>
            {value && (
              <div style={{flexDirection: 'row'}}>
                {value === SUBMIT_TYPE.BOI_FORM ? <Smartphone /> : <Mail />}
              </div>
            )}
          </div>
        );
      },
    },
    {
      props: {
        label: 'Email address',
        value: userEmail,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'User submitted',
        value: userSubmittedAt
          ? format(new Date(userSubmittedAt ?? ''), 'MMM d yyy')
          : '',
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Payment',
        value: paymentStatus ? 'Eligible' : 'Not Eligible',
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Status',
        value: {boiStatus, boiId},
      },
      Component: ({label, value}) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 50,
              alignItems: 'center',
            }}>
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 'bold',
                letterSpacing: 0.7,
                textTransform: 'uppercase',
                color: themmeColor.black60,
              }}>
              {label}
            </Typography>
            {value && (
              <BoiStatusCell
                onSuccess={refreshInfo}
                boiId={value.boiId}
                currentBoiStatus={value.boiStatus}
              />
            )}
          </div>
        );
      },
    },
    {
      props: {
        label: 'Status Updated',
        value: updatedAt ? format(new Date(updatedAt), 'MMM d yyy') : '',
      },
      Component: LabelField,
    },
  ];

  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_USER_SUMMARY_BAR}
      style={{
        position: 'relative',
      }}>
      {loading ? (
        <></>
      ) : (
        <>
          <Box
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingInline: 32,
              paddingBlock: isCollapsed ? 0 : 12,
              flexWrap: 'wrap',
              height: isCollapsed ? 0 : undefined,
              overflow: 'hidden',
            }}
            noBR>
            <Box
              borderColor={themmeColor.silver}
              hoverEffect
              onClick={onClickBack}
              style={{paddingBlock: 12, paddingInline: 4}}
              hoverColor={themmeColor.focusedBlue}>
              <BackIcon />
              <Typography>Users List</Typography>
            </Box>
            {summaryData.map(({Component, props}) => (
              <Component {...props} loaded={loaded} />
            ))}
          </Box>
          <CollapseButton
            setIsCollapsed={setIsCollapsed}
            isCollapsed={isCollapsed}
          />
        </>
      )}
    </div>
  );
};

export default BoiSummaryBar;
