import {Button, Modal, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useContext, useState} from 'react';
import {postDocumentStatus} from 'src/appApi';
import FullScreenLoading from 'src/common/FullScreenLoading';

import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import DocumentsContext, {
  Document,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 540,
    padding: 24,
    borderRadius: 16,
    backgroundColor: themmeColor.white,
  },
  buttonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 12,
  },
});

interface ActionRequiredOverlayProps {
  isVisible: boolean;
  handleClose: () => void;
  document: Document;
}

const IgnoreDocumentOverlay = ({
  isVisible,
  handleClose,
  document,
}: ActionRequiredOverlayProps) => {
  const {fetchDocuments} = useContext(DocumentsContext);
  const [loading, setLoading] = useState(false);

  const onSend = async () => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: document.documentId ?? -1,
        status: VALID_DOCUMENT_STATUS.IGNORED,
      });
      await fetchDocuments();
      handleClose();
    } catch (e) {
      alert('something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const styles = useStyles({});

  return (
    <>
      {loading && <FullScreenLoading open={loading} />}
      <Modal
        className={styles.modal}
        open={isVisible}
        disableEnforceFocus
        onClose={handleClose}>
        <div className={styles.modalContainer}>
          <Typography fontWeight={600} fontSize={20}>
            Ignore Documents
          </Typography>
          <Typography color={themmeColor.grey} fontSize={14}>
            If this document is not relevant please ignore this document
          </Typography>
          <div className={styles.buttonsContainer}>
            <Button
              disabled={loading}
              onClick={handleClose}
              style={{flex: 0.45}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={onSend}
              style={{flex: 0.45}}
              variant="contained">
              Ignore Document
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IgnoreDocumentOverlay;
