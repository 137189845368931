import React, {useEffect, useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {getUnsubscribeEmailCategories, postUnsubscribeEmail} from 'src/appApi';

import JSONPretty from 'react-json-pretty';
import {CircularProgress, TextField, Typography} from '@mui/material';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const CONFIRM_UNSUBSCRIBE_COPY = 'UNSUBSCRIBE USER';

const UnsubscrbeFromEmailButton = (props: any) => {
  const [unsubscribePopup, setUnsubscribePopup] = useState(false);
  const [unsubscribeData, setUnsubscribeData] = useState<any>(null);
  const [confirmationText, setConfirmationText] = useState('');

  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  const id = props.record?.id;
  const targetEmail = props.record.email;

  useEffect(() => {
    if (unsubscribePopup) {
      // call api and set data
      const fetchAndSetUnsubscribeData = async () => {
        try {
          const response = await getUnsubscribeEmailCategories(id);
          setUnsubscribeData(response.data);
        } catch (e) {
          notify(
            `Something went wrong while fetching: ${e}`,
            NotificationType.error,
          );
        }
      };
      fetchAndSetUnsubscribeData();
    } else {
      // on close popup set data to null
      setUnsubscribeData(null);
      setConfirmationText('');
    }
  }, [unsubscribePopup]);

  const primaryButtonOnClick = async () => {
    try {
      setLoading(true);
      await postUnsubscribeEmail(id);
      setUnsubscribePopup(false);
      notify(`Unsubscribed successfully`, NotificationType.success);
    } catch (e) {
      notify(`Something went wrong: ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{marginRight: 8}}>
      <DSButton
        text="Unsubscribe from emails"
        type="secondary"
        onClick={() => {
          setUnsubscribePopup(true);
        }}
      />
      <PopUp
        style={{width: 600}}
        isOpen={unsubscribePopup}
        onClose={() => {
          setUnsubscribePopup(false);
        }}
        primaryButtonDisabled={
          unsubscribeData === null ||
          loading ||
          confirmationText !== CONFIRM_UNSUBSCRIBE_COPY
        }
        primaryButtonTitle="Unsubscribe from email"
        primaryButtonOnClick={primaryButtonOnClick}>
        {unsubscribeData === null ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flex: 1,
              height: 200,
            }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Typography style={{fontSize: 24, fontWeight: 600}}>
              Unsubssribe '{targetEmail}'?
            </Typography>
            <JSONPretty
              id={`unsubsribe-email-${id}`}
              data={unsubscribeData}
              theme={JSONPrettyMon}
              style={{fontSize: 20, overflowY: 'auto', maxHeight: '50vh'}}
            />
            <p>
              Type "<strong>{CONFIRM_UNSUBSCRIBE_COPY}</strong>" to proceed
            </p>
            <TextField
              label="Confirmation Text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              disabled={loading || unsubscribeData === null}
              fullWidth
            />
          </>
        )}
      </PopUp>
    </div>
  );
};

export default UnsubscrbeFromEmailButton;
