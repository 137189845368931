import {ArrowBack, ArrowForward} from '@mui/icons-material';
import {Button} from '@mui/material';
import {parse} from 'querystring';
import React from 'react';
import {useSearchParams} from 'react-router-dom';

const Pagination = ({disableNext}: {disableNext: boolean}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultPage = searchParams.get('page') ?? '1';

  const handlePrev = () => {
    const prevPage = parseInt(defaultPage) - 1;
    setSearchParams((prev) => ({...parse(prev.toString()), page: prevPage}));
  };
  console.log({searchParams: searchParams.entries()});

  const handleNext = () => {
    const nextPage = parseInt(defaultPage) + 1;
    setSearchParams((prev) => {
      return {...parse(prev.toString()), page: nextPage};
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 12,
      }}>
      <Button
        variant="contained"
        disabled={parseInt(defaultPage) === 1}
        startIcon={<ArrowBack />}
        onClick={handlePrev}>
        Prev
      </Button>
      <div style={{marginInline: 12}}>{defaultPage}</div>
      <Button
        variant="contained"
        endIcon={<ArrowForward />}
        onClick={handleNext}
        disabled={disableNext}>
        Next
      </Button>
    </div>
  );
};

export default Pagination;
