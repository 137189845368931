import {getBoiBusinessDetails, getBoiFilingUserList} from 'src/appApi';
import {UPDATE_BOI_USER_DATA, SET_BOI_DATA} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {BoiBusinessForm, BoiFilingData} from 'src/BoiFilingCenter/types';

const setBoiData = (payload: {
  data: BoiFilingData;
  business: BoiBusinessForm;
}) => {
  return {
    type: SET_BOI_DATA,
    payload,
  };
};

const updateBoiUserData = (payload: BoiFilingData) => {
  return {
    type: UPDATE_BOI_USER_DATA,
    payload,
  };
};

export const fetchBoiUserData = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getBoiFilingUserList({fly_user_id: userId});
      dispatch(updateBoiUserData(response.data.results[0]));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch boi user Data (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};

export const fetchBoiFilingData = (userId: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getBoiFilingUserList({fly_user_id: userId});
      const userBoiData = response.data.results[0] ?? {};

      const boiBusinessResponse = await getBoiBusinessDetails({
        fly_user_id: userId,
        year: userBoiData.year,
      });
      dispatch(
        setBoiData({data: userBoiData, business: boiBusinessResponse.data}),
      );
      return userBoiData;
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch boiData (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
