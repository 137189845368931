import React from 'react';
import {Document, Page} from 'react-pdf';

interface DocumentRenderProps {
  url: string;
  _onDocumentLoadSuccess: (props: any) => void;
  numPages: any;
  _onRenderPage: (pageNumber: number) => void;
  canvasPDFRef: React.MutableRefObject<{}>;
}

const DocumentRender = ({
  url,
  _onDocumentLoadSuccess,
  numPages,
  _onRenderPage,
  canvasPDFRef,
}: DocumentRenderProps) => {
  return (
    <div style={{width: '100%', height: '50vh', overflowY: 'auto'}}>
      <Document file={url} onLoadSuccess={_onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <div
            key={`page_${index + 1}`}
            style={{
              display: 'flex',
              margin: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Page
              onRenderSuccess={() => _onRenderPage(index)}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              canvasRef={(ref) => {
                canvasPDFRef.current[index + 1] = ref;
              }}
              scale={1.2}
            />
          </div>
        ))}
      </Document>
    </div>
  );
};

const areEqual = (
  prevProps: Readonly<DocumentRenderProps>,
  nextProps: Readonly<DocumentRenderProps>,
) => {
  if (prevProps.numPages !== nextProps.numPages) {
    return false;
  }
  if (prevProps.url !== nextProps.url) {
    return false;
  }
  if (prevProps.canvasPDFRef !== nextProps.canvasPDFRef) {
    return false;
  }
  if (prevProps._onRenderPage !== nextProps._onRenderPage) {
    return false;
  }

  return true;
};
export default React.memo(DocumentRender, areEqual);
