import {getTaxProfileStatus} from 'src/appApi';
import {SET_TAX_PROFILE_STATUS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';

const setTaxProfileStatus = (payload: any) => {
  return {
    type: SET_TAX_PROFILE_STATUS,
    payload,
  };
};

export const fetchTaxProfileStatusForGivenYear = (
  userId: number,
  year: number,
) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getTaxProfileStatus(userId, year);
      const taxProfileCpaSideStatus = data.reduce((prev, section) => {
        return {
          ...prev,
          [section.name]: section.cpa_status,
        };
      }, {});
      const taxProfileUserSideStatus = data.reduce((prev, section) => {
        return {
          ...prev,
          [section.name]: {
            status: section.status,
            is_blacklisted: section.is_blacklisted,
          },
        };
      }, {});

      dispatch(
        setTaxProfileStatus({
          taxProfileCpaSideStatus,
          taxProfileUserSideStatus,
        }),
      );
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to tax-profile review status (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
