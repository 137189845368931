import {React} from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {TaxReturnType } from 'src/store/taxReturns/taxReturns.reducer';
import DownIcon from 'src/icons/DownIcon';
import UpdateReturnStatus from './UpdateReturnStatus';

interface Props {
  isOpen: boolean;
  onClose: () => void
  dataArray: {
    return_id: number;
    return_type: TaxReturnType;
    name: string;
    status: TAX_FILING_STATUS;
  }[]
  taxFilingStatusPopUp: boolean;
  loading: boolean;
  selectedReturnId: number;
  selectedReturnType: TaxReturnType
  selectedReturnStatus: TAX_FILING_STATUS
  selectedReturnName: string,
  setTaxFilingStatusPopUp: (state: boolean) => void;
  setLoading: (state: boolean) => void;
  setSelectedReturnId: (state: number) => void;
  setSelectedReturnType: (state: TaxReturnType) => void;
  setSelectedReturnStatus: (state: TAX_FILING_STATUS) => void;
  setSelectedReturnName: (state: string) => void;
  onUpdate: (returnId: number, returnType: TaxReturnType, newStatus: TAX_FILING_STATUS) => void
}

const MultiReturnStatusUpdatePopup = ({isOpen, onClose, dataArray, taxFilingStatusPopUp, loading, selectedReturnId, selectedReturnType, selectedReturnName, selectedReturnStatus, setTaxFilingStatusPopUp, setLoading, setSelectedReturnId, setSelectedReturnStatus, setSelectedReturnName, setSelectedReturnType, onUpdate}: Props) => {
  return (
    <PopUp
      style={{width: 414, height: 'auto', borderRadius: 12, border: 1, position: 'absolute', right: 350, top: 100}}
      isOpen={isOpen}
      onClose={onClose}>
      {dataArray.map((data) => {
        const return_name = data.name.length <= 15 ? data.name : data.name.substring(0, 12).concat("...");
        return (
          <div
            style={{
              backgroundColor: themmeColor.white,
              padding: 16,
              display: 'flex',
              justifyContent: 'space-between',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: themmeColor.offWhite,
            }}>
            <div
              style={{
                flex: 0.45,
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                textAlign: 'left',
                fontSize: 13,
                fontWeight: 600,
              }}>
              {return_name}
            </div>

            <div
              style={{
                backgroundColor: TAX_RETURN_STATUS_LABELS[data.status]?.backColor ?? themmeColor.silver,
                borderRadius: 20,
                flex: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                color: TAX_RETURN_STATUS_LABELS[data.status]?.textColor,
                fontSize: 13,
                fontWeight: 600,
              }}
              onClick={
                () => {
                  setTaxFilingStatusPopUp(true)
                  setSelectedReturnId(data.return_id)
                  setSelectedReturnType(data.return_type)
                  setSelectedReturnStatus(data.status)
                  setSelectedReturnName(data.name)
                }
              }>
              {data.status}
              <DownIcon />
            </div>
            <UpdateReturnStatus
              key={`returnStatus-${data.status}-${taxFilingStatusPopUp}`}
              isOpen={taxFilingStatusPopUp}
              onClose={() => setTaxFilingStatusPopUp(false)}
              returnName={selectedReturnName}
              returnId={selectedReturnId}
              returnType={selectedReturnType}
              currentReturnStatusId={selectedReturnStatus}
              loading={loading}
              onUpdate={onUpdate}
            />
          </div>
        )
      })}
    </PopUp>
  );
};

export default MultiReturnStatusUpdatePopup;
