import React, {cloneElement, useState} from 'react';
import {List, Filter} from 'react-admin';
import {
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput,
  NumberInput,
} from 'react-admin';
import {
  TopToolbar,
  sanitizeListRestProps,
  useQueryWithStore,
  Loading,
  Error,
} from 'react-admin';
import {getDefaultYears} from '../../users/UserList';
import {
  TxnColumns,
  ExpenseBulkActionButtons,
  MerchantInput,
  ExpenseCategoryInput,
  MerchantClusterInput,
  isTaxFiledForYear,
} from '../ExpenseList';
import ExpenseAside from '../ExpenseAside';
import {getMealsCategoriesAll} from '../../common/ExpenseCategoriesUtils';
import {ExpensePagination} from '../ExpensePagination';
import {getCurrentUserId} from '../../authProvider';
import {
  DeductionClassChoices,
  TxnBucketChoices,
  YearChoices,
} from '../../constants/constants';

const getYears = () => {
  const currYear = new Date().getFullYear();
  const prevYear = currYear - 1;
  return [currYear.toString(), prevYear.toString()];
};

const UnreviewedUsers = ({choices}) => {
  const filteredChoices = choices.filter((item) => item.unreviewed_txns > 0);
  return (
    <SelectInput
      source="fly_user_id"
      choices={filteredChoices}
      optionText={(choice) =>
        `${choice.id} - ${choice.name} - ${choice.unreviewed_txns}`
      }
      allowEmpty={false}
    />
  );
};

// Not used right now but might needed in future, so keeping it here
const getYearsToReview = (onbData) => {
  const que_index = onbData
    ? Object.keys(onbData).filter(
        (itm) => onbData[itm]['question_id'] === 22,
      )[0]
    : undefined;
  const isTaxFiledPrevYear = que_index ? onbData[que_index].answer : false;
  return getDefaultYears(isTaxFiledPrevYear);
};

const ExpenseFilter = (props) => {
  const {onbData, filterValues} = {...props};
  filterValues['year'] = getYearsToReview(onbData);
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Users"
        source="fly_user_id"
        reference="users"
        allowEmpty={true}
        filter={{reviewer: props.reviewerId}}
        perPage={400}
        alwaysOn>
        <UnreviewedUsers />
      </ReferenceInput>

      <NumberInput label="User Id" source="fly_user_id" min={1} alwaysOn />
      <SelectArrayInput
        source="year"
        choices={YearChoices}
        optionText={(choice) =>
          `${choice.name} ${
            isTaxFiledForYear(choice.name, onbData) ? ' ✅' : ''
          }`
        }
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      {props.filterValues.fly_user_id && (
        <ReferenceArrayInput
          key={props.filterValues.fly_user_id}
          label="Card"
          source="account_id"
          reference="plaid_account"
          filter={{user_id: props.filterValues.fly_user_id}}
          allowEmpty={true}
          alwaysOn>
          <SelectArrayInput
            optionText={(choice) =>
              `${choice.plaid_institution.name} - ${choice.name} - ${
                choice.mask
              } (${choice.card_usage_type}) - ${
                choice.is_user_onboarding_complete ? '✅' : '✕'
              }`
            }
            allowEmpty={true}
          />
        </ReferenceArrayInput>
      )}
      <SelectInput
        label="Updated Classifi.."
        source="updated_classification_status"
        choices={DeductionClassChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        source="bucket"
        choices={TxnBucketChoices}
        optionText="name"
        optionValue="id"
        allowEmpty={true}
        alwaysOn
      />

      {props.filterValues.fly_user_id && (
        <MerchantInput
          userId={props.filterValues['fly_user_id']}
          years={getYears()}
          alwaysOn
        />
      )}
      {props.filterValues.user_id && (
        <MerchantClusterInput
          userId={props.filterValues['fly_user_id']}
          years={getYears()}
          alwaysOn
        />
      )}
      {props.filterValues.fly_user_id && (
        <ExpenseCategoryInput
          userId={props.filterValues['fly_user_id']}
          years={getYears()}
          alwaysOn
        />
      )}
    </Filter>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
};

const UnreviewedExpenseList = (props) => {
  const reviewerId = getCurrentUserId();
  const [onbData, setOnbData] = useState(null);
  const {
    data: taxonomy,
    loading: catLoading,
    error: catError,
  } = useQueryWithStore({
    type: 'getUniverse',
    resource: 'categorizer',
  });
  if (catLoading) return <Loading />;
  if (catError) return <Error />;
  if (!taxonomy) return null;
  const categoryMap = taxonomy['hierarchy'];
  const mealsCategories = getMealsCategoriesAll(
    taxonomy['expense_categories'],
    taxonomy['expense_categories_depr'],
  );

  return (
    <List
      {...props}
      perPage={50}
      filters={<ExpenseFilter reviewerId={reviewerId} onbData={onbData} />}
      filter={{is_reviewed: false, reviewer: reviewerId, onboarding: true}}
      filterDefaultValues={{
        year: getDefaultYears(false),
        last_modified_by: ['AL', 'RE'],
      }}
      exporter={false}
      actions={<ListActions />}
      aside={
        <ExpenseAside userIdSource="fly_user_id" setOnbData={setOnbData} />
      }
      bulkActionButtons={
        <ExpenseBulkActionButtons
          markReviewOnClassify={false}
          categoryMap={categoryMap}
        />
      }
      pagination={<ExpensePagination />}>
      <TxnColumns
        {...props}
        markReviewOnClassify={false}
        defaultColumns={[
          'updated_merchant_name',
          'details',
          'current_amount',
          'txn_date',
          'plaid_category',
          'updated_expense_category',
          // 'expense_sub_category',
          'updated_classification_status',
          'last_modified_by',
          'notification_state',
        ]}
      />
    </List>
  );
};

export default UnreviewedExpenseList;
