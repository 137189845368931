import {CircularProgress} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLogin} from 'react-admin';
import {postAuthToken} from './appApi';
import {LocalStorageKey} from './constants/constants';

const ReactAdminPseudoLogin = ({id_token}: {id_token: string}) => {
  const login = useLogin();

  useEffect(() => {
    login({getAuthResponse: () => ({id_token})});
  }, []);
  return null;
};

const LoginPage = (props: {isReactAdmin: boolean}) => {
  const [enableReactAdminLogin, setReactAdminLogin] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSuccessGoogleLogin = async (response: any) => {
    try {
      setLoading(true);
      const creds = await postAuthToken(response.credential);
      const {token, id, email, username, first_name, last_name} = creds.data;
      localStorage.setItem(LocalStorageKey.Token, token);
      localStorage.setItem(LocalStorageKey.UserId, id);
      localStorage.setItem(LocalStorageKey.UserName, username);
      localStorage.setItem(LocalStorageKey.UserEmail, email);
      localStorage.setItem(LocalStorageKey.UserFirstName, first_name);
      localStorage.setItem(LocalStorageKey.UserLastName, last_name);

      if (props.isReactAdmin) {
        setReactAdminLogin(response.credential);
      }
      setLoading(false);
    } catch (e) {
      alert(`Login failed ${e}`);
    }
  };

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleSuccessGoogleLogin,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('signUpDiv'),
        {
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
          shape: 'circle',
          logo_alignment: 'center',
          width: 300,
        },
      );
      window.google.accounts.id.prompt();
    }
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div id="signUpDiv" />
      {enableReactAdminLogin && (
        <ReactAdminPseudoLogin id_token={enableReactAdminLogin} />
      )}
      {loading && <CircularProgress />}
    </div>
  );
};

export default LoginPage;
