import {TaxProfileSubsection} from 'src/constants/constants';
import {SET_TAX_PROFILE_STATUS} from '../actionNames';
import {ReduxActionType, REDUX_STATUS} from '../types';

export enum TaxProfileReviewStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export enum TaxProfileUserSideStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  REVIEW_PENDING = 'REVIEW_PENDING',
}

const initialState = {
  taxProfileCpaSideStatus: {} as {
    [key in TaxProfileSubsection]?: TaxProfileReviewStatus;
  },
  taxProfileUserSideStatus: {} as {
    [key in TaxProfileSubsection]?: {
      status: TaxProfileUserSideStatus;
      is_blacklisted: boolean;
    };
  },
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const taxProfileStatus = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_TAX_PROFILE_STATUS:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type TaxProfileStatusStateType = typeof initialState;
