import {TaxProfileSubsection} from 'src/constants/constants';
import {SET_APPLICABLE_DEDUCTIONS} from '../actionNames';
import {IRSCategory} from '../transactions/transactions.constants';
import {ReduxActionType, REDUX_STATUS} from '../types';

interface SectionWiseApplicableDeductions {
  applicable_de: {
    category: IRSCategory;
    l2: string;
  }[];
}

interface AllOtherApplicableDeductions {
  category: IRSCategory;
  l2: string[];
}

const initialState = {
  sectionWiseMapping: {} as {
    [key in TaxProfileSubsection]: SectionWiseApplicableDeductions;
  },
  allOtherDeductions: [] as AllOtherApplicableDeductions[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const applicableDeductions = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_APPLICABLE_DEDUCTIONS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type ApplicableDeductionStateType = typeof initialState;
