import React from 'react';
import {stringClipper} from 'src/common/utils';
import {TAX_EXTENSION_STATUS, themmeColor} from 'src/constants/constants';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import DownIcon from 'src/icons/DownIcon';
import {BusinessType} from 'src/store/businessDetails/businessDetails.types';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dataArray: {
    return_id: number;
    return_type: TaxReturnType;
    name: string;
    extension_status: TAX_EXTENSION_STATUS;
    business_type: BusinessType;
  }[];
  setTaxExtensionStatusPopup: (state: boolean) => void;
  setSelectedReturn: (
    state: {returnId: number; returnType: TaxReturnType} | null,
  ) => void;
}

const BusinessExtensionStatusPopup = ({
  isOpen,
  onClose,
  dataArray,
  setTaxExtensionStatusPopup,
  setSelectedReturn,
}: Props) => {
  return (
    <PopUp
      style={{
        width: 414,
        height: 'auto',
        borderRadius: 12,
        border: 1,
        position: 'absolute',
        right: 350,
        top: 100,
      }}
      isOpen={isOpen}
      onClose={onClose}>
      {dataArray.map((data) => {
        const return_name = stringClipper(data.name, 12);
        return (
          <div
            style={{
              backgroundColor: themmeColor.white,
              padding: 16,
              display: 'flex',
              justifyContent: 'space-between',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: themmeColor.offWhite,
            }}>
            <div
              style={{
                flex: 0.45,
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                textAlign: 'left',
                fontSize: 13,
                fontWeight: 600,
              }}>
              {`${return_name} (${data.business_type})`}
            </div>

            <div
              style={{
                backgroundColor: themmeColor.silver,
                borderRadius: 20,
                flex: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
                fontSize: 13,
                fontWeight: 600,
              }}
              onClick={() => {
                setTaxExtensionStatusPopup(true);
                setSelectedReturn({
                  returnId: data.return_id,
                  returnType: data.return_type,
                });
              }}>
              {data.extension_status ?? 'NA'}
              <DownIcon />
            </div>
          </div>
        );
      })}
    </PopUp>
  );
};

export default BusinessExtensionStatusPopup;
