import {Typography} from '@mui/material';
import {format} from 'date-fns';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {ThreadMessage as ReduxThreadMessage} from 'src/store/threads/threads.reducer';

interface ThreadMessageProps {
  message: ReduxThreadMessage;
}
export const ThreadMessage = ({message}: ThreadMessageProps) => {
  const timeString = format(message.created_at, 'dd/MM/yyyy, HH:mm');
  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{fontWeight: 600}}>
          {message.author_name}
        </Typography>
        <Typography
          style={{
            fontSize: 11,
            textTransform: 'uppercase',
            color: themmeColor.grey,
          }}>
          {timeString}
        </Typography>
      </div>
      <Typography style={{marginTop: 4, wordBreak: 'break-all'}}>
        {message.comment}
      </Typography>
    </div>
  );
};
