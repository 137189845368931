import React from 'react';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import SectionHeader from '../common/SectionHeader';
import PaymentsSection from './components/PaymentsSection';

const TaxPayments = () => {
  const {TAX_PROFILE_SECTION_HEADER_HEIGHT, spaceLeftBelowTaxFilingSteps} =
    useLayout();
  return (
    <div>
      <div style={{display: 'flex'}}>
        <div>
          <SectionHeader
            sectionName="Tax Payments"
            section={TaxProfileSection.TaxPayments}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '75vw',
              overflowY: 'auto',
              height:
                spaceLeftBelowTaxFilingSteps -
                TAX_PROFILE_SECTION_HEADER_HEIGHT,
            }}>
            <PaymentsSection />
          </div>
        </div>
        <div
          style={{
            overflowY: 'auto',
            height: spaceLeftBelowTaxFilingSteps,
            flex: 1,
            paddingRight: 10,
            width: '24vw'
          }}>
          <ActionRequired
            dontAllowAdd
            types={[CreateQueryDrawerTab.TAX_PROFILE]}
            taxProfileSection={TaxProfileSection.TaxPayments}
          />
        </div>
      </div>
    </div>
  );
};

export default TaxPayments;
