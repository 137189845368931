export enum filterFields {
  REVERSE = 'reverse',
  SORTING_KEYS = 'ordering',
  PAYMENT_PLANS = 'selectedPlan',
  RETURN_STATUS = 'selectedReturnStatus',
  CPA_ACTION_REQUIRED = 'selectedCpaActions',
  REVIEWER_ACTION_REQUIRED = 'selectedReviewerActions',
  TAX_EXTENSION_STATUS = 'selectedTaxExtension',
  NEXT_ACTION_DATE = 'selectedDates',
  REVIEWER = 'selectedReviewers',
  CPA = 'selectedCPAs',
  USER_ACTIVITY_STATUS = 'selectedActivity',
  TAX_QUERY_OPEN = 'selectedTaxQueryStatus',
  COMMS_MODE = 'selectedCommsMode',
  EXPRESS_EXTENSION_ELIGIBLE = 'selectedExpressExtensionEligible',
  TF_SEGMENT = 'selectedTFSegment',
  COLLABORATOR_EMAIL = 'selectedCollaborator',
  FILED_ELSEWHERE = 'selectedFiledElsewhere',
}
