import React from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import Box from 'src/DesignSystem/Box/Box';
import RightChveron from 'src/icons/RightChveron';
import TaxProfileIcon from 'src/icons/TaxProfileIcon';
import UploadDocumentIcon from 'src/icons/UploadDocumentIcon';
import {
  DerivedQueryType,
  QueryStatus,
} from 'src/store/queries/queries.reducer';
import {selectQuery} from 'src/store/queries/queries.selector';
import {ReduxStateType} from 'src/store/store';
import useHighValueDeduction from 'src/CpaCenterV2/hooks/useHighValueDeduction';
import {CurrencyNumberFormat} from 'src/common/utils';
import DSButton from 'src/DesignSystem/Button/Button';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';

const MessageListHeader = () => {
  const {queryId} = useCurrentQueryId();
  const query = useSelector((state: ReduxStateType) =>
    selectQuery(state, queryId),
  );
  const dispatch = useDispatch();

  const {getHighValueQueryExpenseStatus} = useHighValueDeduction();

  const openHighValueDeductionsStatusDrawer = () => {
    dispatch(
      setAppState(AppReducerStates.highValueDeductionsStatusDrawer, {
        isVisible: true,
        queryId: query.queryId,
      }),
    );
  };
  const {navigateToDocumentViewer, navigateToDistributeDeductions} =
    useCpaCenterV2Navigation();

  if (!query) {
    return null;
  }

  if (query.derivedQueryType === DerivedQueryType.REUPLOAD_DOCUMENT) {
    return (
      <Box
        hoverEffect
        hoverColor={themmeColor.black60}
        backgroundColor={themmeColor.textPurple}
        onClick={() => navigateToDocumentViewer(query.docData?.docId)}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 12, display: 'flex'}}>
            <UploadDocumentIcon color={themmeColor.white} />
          </div>
          <Typography style={{color: themmeColor.white, fontWeight: 600}}>
            {query.docData?.fileName}
          </Typography>
        </div>
        <RightChveron color={themmeColor.white} />
      </Box>
    );
  }

  if (query.derivedQueryType === DerivedQueryType.DISTRIBUTE_DEDUCTIONS) {
    return (
      <Box
        hoverEffect
        hoverColor={themmeColor.black60}
        backgroundColor={themmeColor.textPurple}
        onClick={navigateToDistributeDeductions}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <Typography style={{color: themmeColor.white, fontWeight: 600}}>
            Distribute Deductions
          </Typography>
        </div>
        <RightChveron color={themmeColor.white} />
      </Box>
    );
  }

  if (query.derivedQueryType === DerivedQueryType.UPLOAD_NEW_DOCUMENT) {
    return (
      <Box
        backgroundColor={themmeColor.textPurple}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 12, display: 'flex'}}>
            <UploadDocumentIcon color={themmeColor.white} />
          </div>
          <Typography style={{color: themmeColor.white, fontWeight: 600}}>
            You've asked to upload {query.docData?.docType}
          </Typography>
        </div>
      </Box>
    );
  }

  if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE_CPA) {
    return (
      <Box
        backgroundColor={themmeColor.textPurple}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 12, display: 'flex'}}>
            <TaxProfileIcon color={themmeColor.white} />
          </div>
          <Typography style={{color: themmeColor.white, fontWeight: 600}}>
            You've asked a query in {query.taxProfileSubsection}
          </Typography>
        </div>
      </Box>
    );
  }
  if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE) {
    return (
      <Box
        backgroundColor={themmeColor.textPurple}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: 12, display: 'flex'}}>
            <TaxProfileIcon color={themmeColor.white} />
          </div>
          <Typography style={{color: themmeColor.white, fontWeight: 600}}>
            User Raised this query during Tax Profile
          </Typography>
        </div>
      </Box>
    );
  }
  if (query.derivedQueryType === DerivedQueryType.HIGH_VALUE_DEDUCTION) {
    const {actionTakenCount, totalCount, actionTakenAmount} =
      getHighValueQueryExpenseStatus(query);
    return (
      <Box
        backgroundColor={themmeColor.textPurple}
        style={{
          paddingBlock: 20,
          paddingInline: 16,
          justifyContent: 'space-between',
          marginBottom: 12,
          alignItems: 'center',
        }}>
        <div>
          <Typography
            style={{
              color: themmeColor.offWhite,
              fontWeight: 600,
              fontSize: 13,
              textTransform: 'uppercase',
            }}>
            {query.queryStatus === QueryStatus.RESOLVED
              ? 'All'
              : `${actionTakenCount}/${totalCount}`}{' '}
            deductions reviewed by user
          </Typography>
          <Typography
            style={{fontSize: 20, fontWeight: 800, color: themmeColor.white}}>
            {CurrencyNumberFormat(actionTakenAmount)}
          </Typography>
        </div>
        <DSButton
          bgColor={themmeColor.white}
          type="secondary"
          text="View"
          onClick={openHighValueDeductionsStatusDrawer}
        />
      </Box>
    );
  }
  return null;
};

export default MessageListHeader;
