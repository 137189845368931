import React from 'react';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import {TaxProfileSection} from 'src/constants/constants';
import ActionRequired from 'src/CpaCenterV2/common/ActionRequired/ActionRequired';
import {CreateQueryDrawerTab} from 'src/store/app/app.reducer';
import BusinessBalanceSheet from './components/BusinessBalanceSheet';

const PartnerBalanceSheet = () => {
  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          section={TaxProfileSection.BalanceSheet}
          sectionName="Balance sheet"
        />
        <div style={{overflowY: 'auto'}}>
          <BusinessBalanceSheet />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.25,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <ActionRequired
          title="Comments"
          dontAllowAdd
          types={[CreateQueryDrawerTab.TAX_PROFILE]}
          taxProfileSection={TaxProfileSection.BalanceSheet}
        />
      </div>
    </div>
  );
};

export default PartnerBalanceSheet;
