import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext} from 'react';
import {
  FIELD_TYPES,
  FormFieldType,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import DocumentsContext from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/DocumentsContext';

interface FormFieldProps {
  isImage: boolean;
  fieldKey: string;
  field: FormFieldType;
  formId: number;
  page: number | null;
  setForms: any;
  handleFormValueChange: (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => void;
}

const FormField: React.FC<FormFieldProps> = ({
  isImage,
  fieldKey,
  field,
  formId,
  page,
  setForms,
  handleFormValueChange,
}) => {
  const {drawPDFBBox, drawImageBBox} = useContext(DocumentsContext);

  const onValueChange = (
    value: any,
    fieldValueIndex: number,
    valueIndex: number,
  ) => {
    handleFormValueChange(
      formId,
      valueIndex,
      fieldValueIndex,
      value,
      fieldKey,
    );
  };

  const getInput = (
    type: string,
    value: any,
    fieldValueIndex: number,
    index: number = -1,
  ) => {
    switch (type) {
      case FIELD_TYPES.NUM_INPUT:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            value={value}
            key={index}
            style={{background: 'white'}}
            type="number"
          />
        );
      case FIELD_TYPES.STR_INPUT:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            multiline={true}
            minRows={1}
            style={{background: 'white'}}
            value={value}
            key={index}
          />
        );
      case FIELD_TYPES.DROPDOWN:
        return (
          <Select
            style={{background: 'white'}}
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            value={value}
            key={index}
            MenuProps={{style: {maxHeight: '400px'}}}>
            {field.dropdown_choices?.map((choice) => {
              return <MenuItem value={choice}>{choice}</MenuItem>;
            })}
          </Select>
        );
      case FIELD_TYPES.CHECK_BOX:
        return (
          <Checkbox
            checked={value}
            onChange={(e) =>
              onValueChange(e.target.checked, fieldValueIndex, index)
            }
            key={index}
          />
        );
      case FIELD_TYPES.DATE:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            style={{background: 'white'}}
            type="date"
            value={value}
            key={index}
          />
        );
      default:
        return (
          <TextField
            size="small"
            onChange={(e) =>
              onValueChange(e.target.value, fieldValueIndex, index)
            }
            style={{background: 'white'}}
          />
        );
    }
  };

  return (
    <div style={{marginBottom: '10px'}}>
      <Typography fontWeight={500} fontSize={13} style={{marginBottom: '5px'}}>
        {field.label}
      </Typography>
      <div>
        {field.field_values.map((fieldValue, fieldValueIndex) => {
          return (
            <div
              style={{display: 'flex', justifyContent: 'space-between'}}
              onMouseOver={() =>
                isImage
                  ? drawImageBBox([
                      fieldValue.key_bounding_box,
                      fieldValue.value_bounding_box,
                    ])
                  : drawPDFBBox(page ?? 1, [
                      fieldValue.key_bounding_box,
                      fieldValue.value_bounding_box,
                    ])
              }>
              {Array.isArray(field.field_type)
                ? field.field_type.map((type, valueIndex) => {
                    return getInput(
                      type,
                      fieldValue.value[valueIndex],
                      fieldValueIndex,
                      valueIndex,
                    );
                  })
                : getInput(
                    field.field_type,
                    fieldValue.value,
                    fieldValueIndex,
                  )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormField;
