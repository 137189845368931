import React from 'react';
import {CurrencyNumberFormat} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';

interface NetAmountRowProps {
  text: string;
  amount: number;
}

const NetAmountRow = ({text, amount}: NetAmountRowProps) => {
  return (
    <Box
      borderColor={themmeColor.offWhite}
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{justifyContent: 'space-between', padding: 20, marginTop: 4}}>
      <Text
        type={TEXT_TYPES.BASE}
        text={text}
        fontWeight={FONT_WEIGHTS.SemiBold}
      />
      <Text
        type={TEXT_TYPES.BASE}
        text={CurrencyNumberFormat(amount)}
        fontWeight={FONT_WEIGHTS.SemiBold}
      />
    </Box>
  );
};

export default NetAmountRow;
