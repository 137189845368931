import React from 'react';
import {
  useEditController,
  Edit,
  useQuery,
  useRefresh,
  useGetList,
  EditButton,
} from 'react-admin';
import {Loading, useMutation, useNotify} from 'react-admin';
import {
  TextField,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  useRedirect,
  FormDataConsumer,
  RadioButtonGroupInput,
  TopToolbar,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {
  TaxOweOrRefundChoices,
  TaxFilingStatusChoices,
  USAStatesAbbreviations,
} from '../constants/constants';

const CustomToolbar = (props) => {
  const refresh = useRefresh();
  const handleClick = () => {
    window.location.replace(`/#/tax_filing_status/${props.tax_filing_id}`);
    refresh();
  };

  return (
    <Toolbar {...props}>
      <SaveButton redirect={handleClick} />
    </Toolbar>
  );
};

const StatusChangeLogs = ({tax_filing_id}) => {
  const {data, loading, error} = useQuery({
    type: 'getTaxFilingLogs',
    resource: 'tax_filing_status',
    payload: {tax_filing_id: tax_filing_id},
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <h3>Status</h3>
      </Grid>
      <Grid item xs={3}>
        <h3>Updated by</h3>
      </Grid>
      <Grid item xs={5}>
        <h3>Updated at</h3>
      </Grid>
      {data.map(({...row}) => {
        return (
          <>
            <Grid item xs={3}>
              <p>{row.status}</p>
            </Grid>
            <Grid item xs={3}>
              <p>{row.status_updated_by}</p>
            </Grid>
            <Grid item xs={5}>
              <p>{row.created_at}</p>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

const Aside = ({record}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [mutate, {loading}] = useMutation();
  const createTaxfilingEntry = (fly_user_id, year) =>
    mutate(
      {
        type: 'create',
        resource: 'tax_filing_status',
        payload: {data: {fly_user_id, year}},
      },
      {
        onSuccess: () => {
          notify('Tax Filing entry created Successfully!!', {type: 'success'});
        },
        onFailure: (error) => {
          console.log(error);
          notify(`Tax Filing entry create Failed!! : ${error}`, {
            type: 'warning',
          });
        },
      },
    );

  const taxFilingYearChoices = [
    {
      id: new Date().getFullYear() - 2,
      name: new Date().getFullYear() - 2,
    },
    {
      id: new Date().getFullYear() - 3,
      name: new Date().getFullYear() - 3,
    },
  ];

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton label="Create" />
      </Toolbar>
    );
  };

  return (
    <div style={{margin: '1em', width: '12rem'}}>
      <Typography variant="h6">File taxes for other years?</Typography>
      <SimpleForm
        save={({year}) => {
          createTaxfilingEntry(record.fly_user_id, year);
          refresh();
        }}
        toolbar={<CustomToolbar />}>
        <SelectInput
          style={{width: '1rem'}}
          source="year"
          label="Tax Filing Year"
          choices={taxFilingYearChoices}
        />
      </SimpleForm>
    </div>
  );
};

const YearFilter = ({record}) => {
  const redirect = useRedirect();
  const {data, loading, error} = useGetList(
    'tax_filing_status',
    {page: 1, perPage: 10},
    {field: 'year', order: 'ASC'},
    {fly_user_id: record.fly_user_id},
  );
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>{error}</p>;
  }

  const choices_list = Object.keys(data).map((key, index) => {
    return {id: data[key].id, name: data[key].year};
  });

  const handleYearChange = (e) => {
    redirect(`/tax_filing_status/${e.target.value}`);
  };

  return (
    <SelectInput
      source="year"
      label="Tax Filing Year"
      onChange={handleYearChange}
      choices={choices_list}
    />
  );
};

const CustomTopToolBar = ({taxFilingId}) => {
  const redirect = useRedirect();
  const redirectToTaxProfile = () => {
    redirect(`/tax_profile/${taxFilingId}`);
  };
  return (
    <TopToolbar>
      <EditButton
        color="primary"
        variant="contained"
        onClick={redirectToTaxProfile}
        label="Go To Tax Profile"
      />
    </TopToolbar>
  );
};

const TaxFilingStatusEdit = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = useEditController(props);

  const validateTaxFilingUpdate = (values) => {
    const errors = {};
    if (values.state_returns && !values.state_returns_type) {
      errors.state_returns_type = 'Required';
    }
    if (values.federal_returns && !values.federal_returns_type) {
      errors.federal_returns_type = 'Required';
    }
    if (!values.federal_returns && values.federal_returns_type) {
      errors.federal_returns = 'Refresh the Page or Enter Value';
    }
    if (!values.state_returns && values.state_returns_type) {
      errors.state_returns = 'Refresh the Page or Enter Value';
    }
    if (
      values.status === 'FILING_REVIEWED' ||
      values.status === 'SIGNED_DOC_RECEIVED' ||
      values.status === 'FILING_COMPLETED'
    ) {
      if (!values.state_returns) {
        errors.state_returns = 'Required';
      }
      if (!values.federal_returns) {
        errors.federal_returns = 'Required';
      }
      if (!values.registered_state) {
        errors.registered_state = 'Required';
      }
      if (!values.state_returns_type) {
        errors.state_returns_type = 'Required';
      }
      if (!values.federal_returns_type) {
        errors.federal_returns_type = 'Required';
      }
    }
    return errors;
  };

  const updatedAtStyle = {width: '16rem', marginLeft: '1em'};
  if (loading) return <Loading />;
  return (
    <>
      <Edit
        {...props}
        {...record}
        actions={<CustomTopToolBar taxFilingId={record.id} />}
        title={`Filing Status for ${record.name}`}
        aside={<Aside />}>
        <SimpleForm
          toolbar={<CustomToolbar tax_filing_id={record.id} />}
          validate={validateTaxFilingUpdate}>
          <YearFilter />
          <TextField source="year" label="Tax Filing Year" />
          <TextField source="fly_user_id" />
          <FormDataConsumer>
            {({formData, ...rest}) => {
              return (
                <>
                  <SelectInput
                    source="status"
                    choices={TaxFilingStatusChoices}
                  />
                  <TextInput
                    style={{marginLeft: '1em'}}
                    disabled
                    label="updated by"
                    source="status_updated_by"
                  />
                  <TextInput
                    style={updatedAtStyle}
                    disabled
                    label="updated at"
                    source="status_updated_at"
                  />
                </>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return (
                <>
                  <SelectInput
                    source="registered_state"
                    choices={USAStatesAbbreviations}
                  />
                  <TextInput
                    style={{marginLeft: '1em'}}
                    source="state_returns"
                  />
                  <RadioButtonGroupInput
                    style={{margin: '1em'}}
                    label=""
                    source="state_returns_type"
                    choices={TaxOweOrRefundChoices}
                  />
                  <TextInput
                    disabled
                    label="updated by"
                    source="state_returns_updated_by"
                  />
                  <TextInput
                    style={updatedAtStyle}
                    disabled
                    label="updated at"
                    source="state_returns_updated_at"
                  />
                </>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData, ...rest}) => {
              return (
                <>
                  <TextInput source="federal_returns" />
                  <RadioButtonGroupInput
                    style={{margin: '1em'}}
                    label=""
                    source="federal_returns_type"
                    choices={TaxOweOrRefundChoices}
                  />
                  <TextInput
                    disabled
                    label="updated by"
                    source="federal_returns_updated_by"
                  />
                  <TextInput
                    style={updatedAtStyle}
                    disabled
                    label="updated at"
                    source="federal_returns_updated_at"
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
      <Card style={{width: '80%', padding: '1rem', marginTop: '1em'}}>
        <StatusChangeLogs tax_filing_id={record.id} />
      </Card>
    </>
  );
};

export default TaxFilingStatusEdit;
