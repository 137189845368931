import {AttachFileOutlined, CloudUploadOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FileUploader} from 'react-drag-drop-files';
import {themmeColor} from 'src/constants/constants';

const useStyles = makeStyles({
  container: {
    backgroundColor: themmeColor.white,
    border: `1px solid ${themmeColor.darkBlue}`,
    borderRadius: 8,
    height: '25vh',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  topText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  bottomText: {
    backgroundColor: themmeColor.darkBlue,
    padding: '9px 50px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 8,
  },
});

interface UploadFileProps {
  fileType: string[];
  handleDocUpload: (file: any) => void;
}

const UploadFile = ({fileType, handleDocUpload}: UploadFileProps) => {
  const styles = useStyles({});

  return (
    <FileUploader
      multiple={false}
      dropMessageStyle={{backgroundColor: '#00000000'}}
      hoverTitle={<h2 color={themmeColor.white}>Drop to upload the file</h2>}
      types={fileType}
      handleChange={handleDocUpload}>
      <div className={styles.container}>
        <div className={styles.topText}>
          <CloudUploadOutlined style={{marginRight: 11}} />
          <Typography>Drag and Drop the document to Upload</Typography>
        </div>
        <div className={styles.divider}>
          <Typography fontWeight={'500'}>OR</Typography>
        </div>
        <div className={styles.bottomText}>
          <AttachFileOutlined
            style={{marginRight: 11, color: themmeColor.white}}
          />
          <Typography color={themmeColor.white}>Upload from PC</Typography>
        </div>
      </div>
    </FileUploader>
  );
};

export default UploadFile;
