import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import Switch from '@material-ui/core/Switch';
import {makeStyles} from '@material-ui/core/styles';
import {default as MaterialButton} from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getAllCategories,
  getSubCategoriesFor,
} from '../common/ExpenseCategoriesUtils';
import {
  IsPlaidCategoryCorrectChoices,
  NotSureCategoryReasonChoices,
  DeductionClassChoices,
  BlankChoice,
  SeverityChoices,
} from '../constants/constants';
import {Fragment, useState} from 'react';
import {
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
  useListContext,
  GET_MANY,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  iconPaddingStyle: {
    paddingRight: '0.5em',
  },
  dialogFieldStyle: {
    marginTop: '2em',
  },
  textFieldStyle: {
    marginTop: '2em',
    display: 'grid',
  },
}));

const BulkClassifyFormDialog = ({
  selectedIds,
  categoryMap,
  open,
  // loading,
  onClose,
  markReviewOnClassify,
}) => {
  // insert blank key into categoryMap to enable not patching in the request when field is set to blank
  const expenseCatChoices = getAllCategories(categoryMap).concat(BlankChoice);
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const {data: storedData, resource} = useListContext();
  const [expenseCat, setExpenseCat] = useState(BlankChoice);
  const [expenseSubCat, setExpenseSubCat] = useState(BlankChoice);
  const [classificationStatus, setClassificationStatus] =
    useState(BlankChoice);
  const [txnState, setTxnState] = useState(BlankChoice);
  const [merchantName, setMerchantName] = useState('');
  const [ruleText, setRuleText] = useState('');
  const [errorSeverity, setErrorSeverity] = useState(SeverityChoices[1]);
  const [categoryReason, setCategoryReason] = useState(BlankChoice);
  const [isPlaidCategoryCorrect, setIsPlaidCategoryCorrect] =
    useState(BlankChoice);
  const [unknownMerchant, setUnknownMerchant] = useState(false);
  const [notSureCategory, setNotSureCategory] = useState(false);

  const [refreshTxns, {}] = useMutation({
    type: GET_MANY,
    resource: resource,
    payload: {ids: selectedIds},
  });

  var payloadData = {};
  if (expenseCat.id !== BlankChoice.id) {
    payloadData.expense_category = expenseCat.id;
  }
  if (expenseSubCat.id !== BlankChoice.id) {
    payloadData.expense_sub_category = expenseSubCat.id;
  }
  if (classificationStatus.id !== BlankChoice.id) {
    payloadData.classification_status = classificationStatus.id;
  }
  if (txnState.id !== BlankChoice.id) {
    payloadData.state = txnState.id;
  }
  if (merchantName !== '') {
    payloadData.merchant_name = merchantName;
  }
  if (markReviewOnClassify === true)
    payloadData.is_reviewed = markReviewOnClassify;

  const [bulkClassify, {loading}] = useMutation(
    {
      type: 'bulk_partial_update',
      resource: 'expenses',
      payload: {
        is_plaid_cat_correct:
          isPlaidCategoryCorrect.id === ''
            ? undefined
            : isPlaidCategoryCorrect.id,
        ids: selectedIds,
        rule_text: ruleText,
        patch_data: payloadData,
        unknown_merchant: unknownMerchant,
        merchant_error_severity: errorSeverity.id,
        not_sure_category: notSureCategory,
        not_sure_cat_reason: categoryReason.id,
      },
    },
    {
      onSuccess: () => {
        refreshTxns();
        unselectAll(resource);
        notify('Bulk classify successful!');
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Bulk classify failed!: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );

  const clearState = () => {
    setExpenseCat(BlankChoice);
    setExpenseSubCat(BlankChoice);
    setClassificationStatus(BlankChoice);
    setTxnState(BlankChoice);
    setMerchantName('');
    setRuleText('');
  };

  const handleConfirm = () => {
    // fetchAndUpdateExpenses(dataProvider, selectedIds, payloadData, notify, goBack, mealsCategories);
    bulkClassify();
    clearState();
    onClose();
  };

  const onCancel = () => {
    clearState();
    onClose();
  };

  const selectedExpenseNames = selectedIds.map((id, i) => {
    let merchantName =
      id in storedData ? storedData[id]['merchant_name'] : null;
    return '(' + String(i + 1) + ') ' + merchantName;
  });

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      scroll="body"
      fullWidth
      onSelect={(e) => {
        e.stopPropagation();
      }}
      disableBackdropClick={true}>
      <DialogTitle id="form-dialog-title">
        Bulk Classify {selectedIds.length} Selected Expenses
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={5}>
            <TextField
              className={classes.textFieldStyle}
              label="Updated Merchant Name"
              variant="outlined"
              value={merchantName}
              disabled={unknownMerchant}
              onChange={(event) => {
                setMerchantName(event.target.value);
              }}
            />
          </Grid>
          <Grid item container alignItems="flex-end" xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={unknownMerchant}
                  onChange={(event) => {
                    if (event.target.checked && !notSureCategory) {
                      setCategoryReason(NotSureCategoryReasonChoices[0]);
                      setNotSureCategory(true);
                    } else if (
                      !event.target.checked &&
                      categoryReason.id === NotSureCategoryReasonChoices[0].id
                    ) {
                      setCategoryReason(BlankChoice);
                      setNotSureCategory(false);
                    }
                    setUnknownMerchant(event.target.checked);
                  }}
                  name="unknown"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              }
              label="Unknown"
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={SeverityChoices}
              value={errorSeverity}
              disabled={unknownMerchant}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setErrorSeverity(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Severity of error"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={5}>
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={expenseCatChoices}
              value={expenseCat}
              disabled={unknownMerchant}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setExpenseCat(newValue);
                  setExpenseSubCat(
                    getSubCategoriesFor(newValue.id, categoryMap)[0],
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Updated Expense Category"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item container alignItems="flex-end" xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={notSureCategory}
                  onChange={(event) => {
                    setNotSureCategory(event.target.checked);
                  }}
                  name="not_sure"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              }
              label="NotSure"
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={NotSureCategoryReasonChoices.concat(BlankChoice)}
              disabled={!notSureCategory}
              value={categoryReason}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setCategoryReason(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Reason" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={IsPlaidCategoryCorrectChoices.concat(BlankChoice)}
              value={isPlaidCategoryCorrect}
              disabled={unknownMerchant}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setIsPlaidCategoryCorrect(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Is Plaid Category Correct?"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              className={classes.dialogFieldStyle}
              options={DeductionClassChoices.concat(BlankChoice)}
              value={classificationStatus}
              disabled={unknownMerchant}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setClassificationStatus(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Updated Deduction?"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <TextField
          className={classes.textFieldStyle}
          label="Rule suggestion?"
          multiline
          rows={2}
          disabled={unknownMerchant}
          rowsMax={Infinity}
          variant="filled"
          value={ruleText}
          onChange={(event) => {
            setRuleText(event.target.value);
          }}
        />

        <DialogContentText>
          <br />
          Selected expenses: {selectedExpenseNames.join(', ')}. <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton onClick={onCancel}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancel
        </MaterialButton>
        <MaterialButton
          onClick={handleConfirm}
          className={classes.confirmPrimary}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirm
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
};

const BulkClassifyButton = ({
  selectedIds,
  categoryMap,
  markReviewOnClassify,
  className,
  label,
  simple,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const btnLabel = label === undefined ? 'Bulk Classify' : label;
  return (
    <Fragment>
      {simple === true ? (
        <MaterialButton className={className} onClick={handleClick}>
          {btnLabel}
        </MaterialButton>
      ) : (
        <MaterialButton
          className={className}
          color="primary"
          variant="contained"
          onClick={handleClick}>
          {btnLabel}
        </MaterialButton>
      )}
      <BulkClassifyFormDialog
        markReviewOnClassify={markReviewOnClassify}
        selectedIds={selectedIds}
        categoryMap={categoryMap}
        open={open}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkClassifyButton;
