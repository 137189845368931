import React from 'react';
import {Menu as MenuMUI, MenuItem} from '@mui/material';

interface CustomOption {
  id: any;
  Component: JSX.Element;
  style?: React.CSSProperties;
}

interface MenuProps {
  anchorEl: any;
  onClose: () => void;
  options: Array<string> | CustomOption[];
  onClick: (newValue: string) => void;
}

const Menu = ({anchorEl, onClose, onClick, options}: MenuProps) => {
  const isMenuOpen = Boolean(anchorEl);

  const onSelect = (selectedOption: string) => {
    onClick(selectedOption);
    onClose();
  };

  return (
    <MenuMUI
      id="basic-menu"
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      {options.map((option) => {
        if (typeof option === 'string') {
          return (
            <MenuItem key={option} onClick={() => onSelect(option)}>
              {option}
            </MenuItem>
          );
        }
        const {Component, style = {}} = option;
        return (
          <MenuItem
            sx={style}
            key={option.id}
            onClick={() => onSelect(option.id)}>
            {Component}
          </MenuItem>
        );
      })}
    </MenuMUI>
  );
};

export default Menu;
