import {ChevronLeft} from '@mui/icons-material';
import React, {useMemo, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import DSButton from 'src/DesignSystem/Button/Button';
import Text from 'src/DesignSystem/Form/common/Text/Text';

interface CPAContentTileParams {
  title: string;
  body: string;
  masterShowContent: boolean;
  setMessage: (newString: string) => void;
  setCpaQuestionTemplatePopup: (cpaQuestionTemplatePopup: boolean) => void;
}

const CPAContentTile = ({
  title,
  body,
  masterShowContent,
  setMessage,
  setCpaQuestionTemplatePopup,
}: CPAContentTileParams) => {
  const [showContent, setShowContent] = useState(true);

  useMemo(() => {
    setShowContent(masterShowContent);
  }, [masterShowContent]);

  return (
    <div>
      <div style={{marginBottom: 8}} />
      <div
        style={{
          borderStyle: 'ridge',
          borderWidth: 1,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          display: 'flex',
          alignItems: 'center',
          paddingInline: 24,
          paddingBlock: 12,
          justifyContent: 'space-between',
        }}>
        <Text
          type={Text.TEXT_TYPES.BASE}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          text={title}
        />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div
            onClick={() => {
              setShowContent(!showContent);
            }}
            style={{
              color: '#1F7FD8',
              fontSize: 13,
              height: 20,
              display: 'flex',
              alignItems: 'center',
            }}>
            {showContent ? 'Hide content' : 'Show content'}{' '}
            <ChevronLeft
              style={{
                marginLeft: 4,
                height: 18,
                width: 18,
                rotate: showContent ? '90deg' : '-90deg',
              }}
            />
          </div>
          <DSButton
            text="Use"
            onClick={() => {
              setMessage(body);
              setCpaQuestionTemplatePopup(false);
            }}
            type="secondary"
            style={{marginLeft: 29}}
          />
        </div>
      </div>
      {showContent && (
        <div
          style={{
            overflow: 'auto',
            borderStyle: 'ridge',
            borderWidth: 1,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopWidth: 0,
            padding: 20,
          }}>
          {ReactHtmlParser(body)}
        </div>
      )}
    </div>
  );
};

export default CPAContentTile;
