export enum IRSCategory {
  Phone = 'Phone',
  Internet = 'Internet (WiFi)',
  PhoneAndInternet = 'Phone & internet services',
  Other = 'Other',
  Transfers = 'Transfers',
  ATM = 'ATM Withdrawal',
  Personal = 'Personal',
  Insurance = 'Insurance',
  FoodAndBeverage = 'Food & beverage',
  Restaurant = 'Restaurant',
  FoodDelivery = 'Food delivery',
  Flights = 'Flights',
  PublicTransport = 'Public transport',
  Travel = 'Travel',
  Taxi = 'Taxi',
  HotelAndLodging = 'Hotels & Lodging',
  OfficeAndBusinessSupplies = 'Business supplies',
  DeviceAndEquipment = 'Devices & equipment',
  GasAndVehicle = 'Gas & vehicle expenses',
  ContractLabor = 'Contract labor',
  Utilities = 'Utilities',
  CommissionAndFees = 'Bank and card fees',
  Loan = 'Loan',
  Medical = 'Medical & dental',
  Rent = 'Rent',
  Mortgage = 'Mortgage payment',
  SoftwareAndOnline = 'Online services and software',
  Advertising = 'Advertising',
  TrainingAndEducation = 'Training & Education',
  Shipping = 'Shipping',
  Store = 'Store',
  HomeImprovement = 'Home & capital improvements',
  Furnishing = 'Furnishing',
  PersonalCare = 'Personal Care',
  ClothingAndAccessories = 'Clothing & Accessories',
  Entertainment = 'Entertainment',
  ParkingAndTolls = 'Parking & Tolls',
  ConvenienceStore = 'Convenience Store',
  DepartmentStore = 'Department Store',
  BooksAndSubscriptions = 'Books & Subscriptions',
  StudentLoan = 'Student loan payment',
  VehicleLoan = 'Vehicle loan payment',
  OtherLoan = 'Other loan payment',
  HomeInsurance = 'Home & office insurance',
  MortgageInsurance = 'Mortgage insurance',
  GasAndVehicleService = 'Gas & vehicle services',
  Vehicle = 'Vehicle purchase',
  VehicleInsurance = 'Vehicle insurance',
  VehicleRentOrLease = 'Vehicle rent/lease',
  RetirementContribution = 'Retirement contributions',
  HSAContribution = 'HSA contributions',
  TutionFees = 'Tuition fees (Credit)',
  ChildAndDependentCare = 'Child & dependent care (Credit)',
  HealthInsurance = 'Health insurance',
  AdoptionCredits = 'Adoption expenses (Credit)',
  EmployeeBenefitPrograms = 'Employee benefits programs',
  SalaryAndWages = 'Salaries & wages',
}
