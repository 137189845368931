import React from 'react';

const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6460_40399)">
        <path
          d="M3.33398 10H2.66732C2.3137 10 1.97456 9.85953 1.72451 9.60949C1.47446 9.35944 1.33398 9.0203 1.33398 8.66668V2.66668C1.33398 2.31305 1.47446 1.97392 1.72451 1.72387C1.97456 1.47382 2.3137 1.33334 2.66732 1.33334H8.66732C9.02094 1.33334 9.36008 1.47382 9.61013 1.72387C9.86018 1.97392 10.0007 2.31305 10.0007 2.66668V3.33334M7.33398 6.00001H13.334C14.0704 6.00001 14.6673 6.59696 14.6673 7.33334V13.3333C14.6673 14.0697 14.0704 14.6667 13.334 14.6667H7.33398C6.5976 14.6667 6.00065 14.0697 6.00065 13.3333V7.33334C6.00065 6.59696 6.5976 6.00001 7.33398 6.00001Z"
          stroke="#1E1E1E"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6460_40399">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CopyIcon;
