import React, {useState, useRef, useEffect} from 'react';
import {OCRBox} from './OCR.types';

interface ImageWithOCRBoxesProps {
  height?: number;
  imageUrl: string;
  ocrData?: OCRBox[];
}

const ImageWithOCRBoxes = ({
  height = 300,
  imageUrl,
  ocrData = [],
}: ImageWithOCRBoxesProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateImageDimensions = () => {
    if (imgRef.current) {
      setImageDimensions({
        width: imgRef.current.offsetWidth || 0,
        height: imgRef.current.offsetHeight || 0,
      });
    }
  };

  useEffect(() => {
    if (imgRef.current) {
      const handleImageLoad = () => {
        setImageDimensions({
          width: imgRef.current?.offsetWidth || 0,
          height: imgRef.current?.offsetHeight || 0,
        });
      };

      const imgElement = imgRef.current;
      imgElement.addEventListener('load', handleImageLoad);

      // Cleanup
      return () => imgElement.removeEventListener('load', handleImageLoad);
    }
  }, [imgRef]);

  useEffect(() => {
    // Update dimensions on window resize
    window.addEventListener('resize', updateImageDimensions);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', updateImageDimensions);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height,
        overflowY: 'auto',
      }}>
      <img
        ref={imgRef}
        src={imageUrl}
        alt="Document Preview"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}>
        {ocrData.map((box, index) => {
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                top: box.Top * imageDimensions.height,
                left: box.Left * imageDimensions.width,
                width: box.Width * imageDimensions.width,
                height: box.Height * imageDimensions.height,
                border: '2px solid red',
                boxSizing: 'border-box',
                pointerEvents: 'none',
              }}
              title={`Page: ${box.pageNumber}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageWithOCRBoxes;
