import React from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectUser} from 'src/store/user/user.selector';

const PlanNameTag = () => {
  const {taxFilingPlanName} = useSelector(selectUser);
  if (!taxFilingPlanName) return null;
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: '#FFF6E6',
        padding: 4,
      }}>
      <Typography style={{fontSize: 13}}>{taxFilingPlanName}</Typography>
    </div>
  );
};

export default PlanNameTag;
