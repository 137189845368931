import React from 'react';
import {useSelector} from 'react-redux';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import BoiSubSectionBox from './BoiSubsectionBox';
import {selectBoiBusiness, selectBoiData} from 'src/store/boi/boi.selectors';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {Typography} from '@mui/material';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {themmeColor} from 'src/constants/constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {
  BoiFilingCenterRoutesParts,
  BoiFilingCenterRoutes,
  RouteName,
} from 'src/constants/routeName';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useNavigate} from 'react-router-dom';
import {BOI_SECTION_NAMES} from '../types';

interface Props {
  style?: React.CSSProperties;
}

const BoiBusiness = ({style}: Props) => {
  const {userId} = useCurrentUserId();
  const {data: boiData, loaded: boiDataLoaded} = useSelector(selectBoiData);
  const {documents, loaded: documentsLoaded} = useSelector(selectDocuments);
  const {business: boiBusiness, loaded: boiBusinessLoaded} =
    useSelector(selectBoiBusiness);

  const isLoading = !documentsLoaded || !boiDataLoaded || !boiBusinessLoaded;

  const linkedDocument = documents.find(
    (doc) => doc.docLink === boiBusiness.ein_doc_link,
  );

  const navigate = useNavigate();

  const onDocumentClick = (doc: ReduxDocumentType) => {
    const to =
      `${RouteName.CpaCenterV2}${BoiFilingCenterRoutes.DocumentReview}/${doc.docId}`.replace(
        BoiFilingCenterRoutesParts.UserId,
        `${userId}`,
      );
    navigate(to);
  };

  return (
    <BoiSubSectionBox
      name="Company details"
      style={style}
      boiSubsection={BOI_SECTION_NAMES.BOI_BUSINESS_DETAILS}
      year={boiData.year}>
      {boiBusiness[
        TaxFormFieldIds.IS_COMPANY_FOREIGN_POOLED_INVESTMENT_VEHICLE
      ] && (
        <Typography
          style={{color: themmeColor.errorRed, fontSize: 16, fontWeight: 600}}>
          Note: Company is a foreign pooled investment vehicle
        </Typography>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 16,
        }}>
        <div style={{flex: 0.45}}>
          <SimpleKeyValueField
            name="Legal name of company"
            style={{marginBottom: 12}}
            value={boiBusiness[TaxFormFieldIds.BUSINESS_NAME]}
            loading={isLoading}
          />
          <SimpleKeyValueField
            name="Alternate names"
            style={{marginBottom: 12}}
            value={boiBusiness[TaxFormFieldIds.ALTERNATE_BUSINESS_NAME]}
            loading={isLoading}
          />
          <SimpleKeyValueField
            style={{marginBottom: 12}}
            name="Company’s tax identification type"
            loading={isLoading}
            value={
              boiBusiness[TaxFormFieldIds.BUSINESS_EIN] == null
                ? 'SSN or ITIN'
                : 'EIN'
            }
          />
          {boiBusiness[TaxFormFieldIds.BUSINESS_EIN] != null && (
            <SimpleKeyValueField
              style={{marginBottom: 12}}
              name="EIN number"
              value={boiBusiness[TaxFormFieldIds.BUSINESS_EIN]}
              loading={isLoading}
            />
          )}
          {boiBusiness[TaxFormFieldIds.BUSINESS_SSN_OR_TIN] != null && (
            <SimpleKeyValueField
              style={{marginBottom: 12}}
              name="SSN or ITIN"
              value={boiBusiness[TaxFormFieldIds.BUSINESS_SSN_OR_TIN]}
              loading={isLoading}
            />
          )}
        </div>
        <div style={{flex: 0.45}}>
          <Typography
            style={{
              color: '#212121',
              fontSize: 16,
              marginBottom: 12,
              fontWeight: 600,
            }}>
            Current U.S. address
          </Typography>
          <SimpleKeyValueField
            name="Address (number, street, & apt. or suite no.)"
            style={{marginBottom: 12}}
            value={
              boiBusiness[TaxFormFieldIds.BUSINESS_ADDRESS]?.[
                TaxFormFieldIds.NUMBER_AND_STREET
              ]
            }
            loading={isLoading}
          />
          <SimpleKeyValueField
            name="City"
            style={{marginBottom: 12}}
            value={
              boiBusiness[TaxFormFieldIds.BUSINESS_ADDRESS]?.[
                TaxFormFieldIds.CITY
              ]
            }
            loading={isLoading}
          />
          <SimpleKeyValueField
            name="State"
            style={{marginBottom: 12}}
            value={
              boiBusiness[TaxFormFieldIds.BUSINESS_ADDRESS]?.[
                TaxFormFieldIds.STATE
              ]
            }
            loading={isLoading}
          />
          <SimpleKeyValueField
            name="Zip code"
            style={{marginBottom: 12}}
            value={
              boiBusiness[TaxFormFieldIds.BUSINESS_ADDRESS]?.[
                TaxFormFieldIds.ZIP_CODE
              ]
            }
            loading={isLoading}
          />
        </div>
      </div>
      <SimpleKeyValueField
        name="Receive a unique FinCEN identifier for company"
        style={{marginBottom: 12}}
        value={boiBusiness[TaxFormFieldIds.REQUEST_FINCEN_ID]}
        loading={isLoading}
      />
      {linkedDocument && (
        <div style={{display: 'flex'}}>
          <DocumentPointer
            style={{flex: 0.5}}
            doc={linkedDocument}
            dontShowStatus
            border
            onDocumentClick={() => onDocumentClick(linkedDocument)}
          />
        </div>
      )}
    </BoiSubSectionBox>
  );
};

export default BoiBusiness;
