import React from 'react';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {ReduxStateType} from 'src/store/store';

const PhoneAndInternet = () => {
  const {activeYear} = useActiveYear();
  const phoneInternetForBusiness = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.USED_PHONE_INTERNET_FOR_BUSINESS,
      activeYear,
    ),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  return (
    <SubSectionBox
      name="Phone and internet"
      taxprofileSubsection={TaxProfileSubsection.PhoneAndInternet}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name={`Did you use phone or internet for your freelance business in ${activeYear}?`}
            value={phoneInternetForBusiness}
          />
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.PhoneAndInternet}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default PhoneAndInternet;
