import React from 'react';
import {TaxReturnBasicFieldsConfig} from '../CreateReturn.constants';
import {useFormData} from 'src/CpaCenterV2/hooks/useFormData';
import {Form} from 'src/DesignSystem/Form/Form';
interface BasicTaxReturnFieldsProps {
  fields: ReturnType<typeof useFormData>['fields'];
}

const BasicTaxReturnFields = ({fields}: BasicTaxReturnFieldsProps) => {
  const mandatoryFields = TaxReturnBasicFieldsConfig.map(
    (field) => field.path,
  );

  return fields
    .filter((field) => mandatoryFields.includes(field.path))
    .map((field) => (
      <div>
        <Form {...field} />
      </div>
    ));
};

export default BasicTaxReturnFields;
