import React, {useCallback, useState} from 'react';
import {useCreateReturn} from './useCreateReturn';
import CreateReturnDrawer from './common/CreateReturnDrawer';
import {
  BusinessReturnFieldsConfig,
  ManualReturnFieldsConfig,
} from './CreateReturn.constants';
import {createEmptyFormDataFromFormConfig} from 'src/DesignSystem/Form/Form.utils';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import _, {uniqueId} from 'lodash';
import {CREATE_RETURN_STATUS, ReturnFields} from './CreateReturn.types';
import {useSelector} from 'react-redux';
import {selectPartners} from 'src/store/partners/partners.selector';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  createEmptyManualReturnState,
  overWriteCreateReturnFields,
  transformManualReturnStateForBackend,
} from './CreateReturn.utils';
import {createPartnerReturns} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import DrawerWhiteContainer from './common/DrawerWhiteContainer';
import UserCard from './common/UserCard';

const CreateReturnManuallyDrawer = () => {
  const {setCreatedReturns, status, setStatus} = useCreateReturn();

  const isOpen = [
    CREATE_RETURN_STATUS.CREATE_MANUAL_RETURN,
    CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS,
  ].includes(status);

  const isLoading = [CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS].includes(
    status,
  );

  const {notify} = useNotify();

  const [formData, setFormData] = useState(() => {
    return createEmptyManualReturnState();
  });

  const {partners} = useSelector(selectPartners);

  const overWriteFields: OverWriteFormFieldsType = useCallback(
    (props) => {
      return overWriteCreateReturnFields({...props, partners});
    },
    [partners],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: ManualReturnFieldsConfig,
    data: formData,
    setData: setFormData,
    overWriteFields,
  });

  const onCreateReturn = async () => {
    try {
      setStatus(CREATE_RETURN_STATUS.MANUAL_RETURN_IN_PROGRESS);
      const manualTaxReturn = transformManualReturnStateForBackend(formData);
      const response = await createPartnerReturns(manualTaxReturn);
      setFormData(createEmptyManualReturnState());
      setCreatedReturns(response.data);
      setStatus(CREATE_RETURN_STATUS.RETURNS_CREATED);
      notify('Created returns successfully', NotificationType.success);
    } catch (e) {
      notify(`Failed to create return: ${e}`, NotificationType.error);
    } finally {
      // remove loader state
    }
  };

  const onAddBusiness = () => {
    setFormData((prev: any) => {
      const newState = {...prev};
      const emptyBiz = createEmptyFormDataFromFormConfig(
        BusinessReturnFieldsConfig,
      );
      emptyBiz[ReturnFields.should_file_return] = true;
      emptyBiz[ReturnFields.unique_key] = uniqueId('manual-biz');
      newState[ReturnFields.businesses] = [
        ...newState[ReturnFields.businesses],
        emptyBiz,
      ];
      return newState;
    });
  };

  return (
    <CreateReturnDrawer
      isOpen={isOpen}
      title="Add new returns"
      onClose={() => setStatus(CREATE_RETURN_STATUS.INIT)}>
      <DrawerWhiteContainer>
        <div
          style={{
            height: 'calc(100vh - 160px)',
            overflowY: 'auto',
          }}>
          <UserCard
            fields={fields}
            onAddBusiness={onAddBusiness}
            user={null}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <DSButton
            style={{paddingInline: 100, paddingBlock: 12, marginTop: 12}}
            text="Create return"
            type="primary"
            onClick={onCreateReturn}
            textType={TEXT_TYPES.BASE}
            disabled={!areAllFieldsValid || isLoading}
          />
        </div>
      </DrawerWhiteContainer>
    </CreateReturnDrawer>
  );
};

export default CreateReturnManuallyDrawer;
