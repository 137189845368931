import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from '../Box/Box';

interface TagProps {
  text: string;
  backgroundColor?: string;
  borderColor?: string;
  fontSize?: number;
  textColor?: string;
}

const Tag = ({
  text,
  backgroundColor,
  borderColor,
  fontSize = 11,
  textColor = themmeColor.black70,
}: TagProps) => {
  return (
    <div>
      <Box
        style={{
          paddingInline: 12,
          backgroundColor: backgroundColor ?? themmeColor.offGrey,
          alignItems: 'center',
          paddingBlock: 2,
          borderColor,
          borderWidth: borderColor ? 1 : 0,
          borderStyle: 'solid',
          display: 'flex',
        }}>
        <Typography
          style={{
            fontWeight: 600,
            fontSize,
            color: textColor,
            textTransform: 'uppercase',
          }}>
          {text}
        </Typography>
      </Box>
    </div>
  );
};

export default Tag;
