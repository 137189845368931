import React from 'react';

export enum MissingExpenseStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  AWAITING = 'AWAITING',
  PROCESSED = 'PROCESSED',
}

export interface BankStatementsProps {
  uploadTimeStamp: number;
  name: string;
}

export interface BankInfoProps {
  bankId: number;
  bankName: string;
  startMonth: string;
  endMonth: string;
  bankStatements: Array<BankStatementsProps>;
}

export interface BackendTransformedProps {
  userId: number;
  username: string;
  emailAddress: string;
  status: MissingExpenseStatus;
  bankInfo: Array<BankInfoProps>;
}

export interface MissingExpenseContextProps extends BackendTransformedProps {
  file: {bankId: number; bankName: string; file: any; isValid: boolean};
  setFile: (newFile: {
    bankId: number;
    bankName: string;
    file: any;
    isValid: boolean;
  }) => void;
}

const MissingExpenseContext =
  // @ts-ignore
  React.createContext<MissingExpenseContextProps>(null);

export default MissingExpenseContext;
