import {useParams} from 'react-router-dom';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

const useTaxReturnCurrentStep = () => {
  const {taxReturnSection} = useParams();
  return {
    taxReturnSection: taxReturnSection as TaxReturnStatus,
  };
};

export default useTaxReturnCurrentStep;
