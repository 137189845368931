import React, {useMemo, useState} from 'react';
import {groupBy, isNumber} from 'lodash';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {ManuallyAddableIncomeTaxForms} from 'src/store/taxForms/taxForms.constants';
import {IncomeTaxFormConfig} from 'src/store/taxForms/taxForms.constants';
import {
  IncomeEarnerType,
  TaxForm,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.types';
import DSButton from 'src/DesignSystem/Button/Button';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Form} from 'src/DesignSystem/Form/Form';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import PartnerIncomeTable from './PartnerIncomeTable';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {TEXT_TYPES} from 'src/DesignSystem/Form/common/Text/Text.types';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {CurrencyNumberFormat} from 'src/common/utils';

const PartnerPersonalIncome = () => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const [showAddTaxFormPopup, setShowAddTaxFormPopup] = useState(false);
  const {onAddTaxForm} = usePartnerTaxProfileReview();
  const taxformsByType = useMemo(
    () => groupBy(taxForms, 'formType'),
    [taxForms],
  );

  const incomeTaxForms = useMemo(() => {
    type T = keyof typeof IncomeTaxFormConfig;
    const _tf = [] as TaxForm<T>[];
    Object.keys(taxformsByType).forEach((formType) => {
      if (formType in IncomeTaxFormConfig) {
        _tf.push(...taxformsByType[formType]);
      }
    });
    return _tf;
  }, [taxformsByType]);

  const totalIncome = useMemo(() => {
    let amount = 0;
    incomeTaxForms.forEach((tf) => {
      const amt = IncomeTaxFormConfig[tf.formType].getAmount(tf);
      if (isNumber(amt)) {
        amount += amt;
      }
    });
    return amount;
  }, [incomeTaxForms]);

  const {selfIncomeForms, spouseIncomeForms} = useMemo(() => {
    const _self: typeof incomeTaxForms = [];
    const _spouse: typeof incomeTaxForms = [];
    incomeTaxForms.forEach((tf) => {
      if (
        tf.formData[TaxFormFieldIds.INCOME_EARNER] ===
        IncomeEarnerType.MY_SPOUSE
      ) {
        _spouse.push(tf);
      } else {
        _self.push(tf);
      }
    });
    return {selfIncomeForms: _self, spouseIncomeForms: _spouse};
  }, [incomeTaxForms]);

  return (
    <SubSectionBox
      name="Personal"
      customSectionActions={
        <DSButton
          text="Add Income"
          type="secondary"
          onClick={() => {
            setShowAddTaxFormPopup(true);
          }}
          style={{marginRight: 8}}
        />
      }
      taxprofileSubsection={TaxProfileSubsection.PersonalIncome}>
      <Text type={TEXT_TYPES.BASE} text={'Tax Payer Income'} />
      <PartnerIncomeTable incomeTaxForms={selfIncomeForms} />
      <Text
        type={TEXT_TYPES.BASE}
        text={'Spouse Income'}
        containerStyle={{marginTop: 12}}
      />
      <PartnerIncomeTable incomeTaxForms={spouseIncomeForms} />
      <div style={{marginTop: 32}}>
        <KeyValueTable
          keys={[
            {
              getValue: (row) => row.name,
            },
            {
              getValue: (row) => CurrencyNumberFormat(row.amount),
              cellStyle: {
                display: 'flex',
                justifyContent: 'flex-end',
              },
              textStyle: {
                fontWeight: 600,
              },
            },
          ]}
          data={[
            {
              name: 'Total Income',
              amount: totalIncome,
            },
          ]}
        />
      </div>
      <PopUp
        isOpen={showAddTaxFormPopup}
        style={{width: 400}}
        onClose={() => {
          setShowAddTaxFormPopup(false);
        }}>
        <Form.RadioField
          title="Select Income Tax form"
          options={ManuallyAddableIncomeTaxForms.map((tf) => ({
            label: IncomeTaxFormConfig[tf].incomeName,
            value: tf,
          }))}
          value={null}
          onChangeValue={(type) => {
            onAddTaxForm({
              taxFormType: type,
              title: `Add ${type}`,
            });
            setShowAddTaxFormPopup(false);
          }}
        />
      </PopUp>
    </SubSectionBox>
  );
};

export default PartnerPersonalIncome;
