import React, {useEffect, useRef, useState} from 'react';
import {Typography} from '@mui/material';
import DSButton from 'src/DesignSystem/Button/Button';
import Thread from './Thread';
import WriteComment from './WriteComment';
import {useThreadsAction} from 'src/CpaCenterV2/hooks/useThreadsAction';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {useSelector} from 'react-redux';
import {selectThreadsForCurrReturn} from 'src/store/threads/threads.selector';
import {Thread as ReduxThread} from 'src/store/threads/threads.reducer';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import {ReduxStateType} from 'src/store/store';

const Threads = () => {
  const [isNewNoteTextBoxVisible, setIsNewNoteTextBoxVisible] =
    useState(false);
  const [openThreadId, setOpenThreadId] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {currentReturn} = useCurrentTaxReturnId();

  const {threads} = useSelector((state: ReduxStateType) =>
    selectThreadsForCurrReturn(state, currentReturn),
  );

  const {refreshNotes, createNewThread, updateUnreadMessages} =
    useThreadsAction();
  const {notify} = useNotify();

  const {
    spaceLeftBelowTaxReturnSection,
    TAX_RETURN_BOTTOM_BAR,
    NOTES_SECTION_HEADER_HEIGHT,
  } = useLayout();

  const scrollRef = useRef<HTMLDivElement>(null);

  const onReset = () => {
    setIsNewNoteTextBoxVisible(false);
    setOpenThreadId(null);
  };

  useEffect(() => {
    refreshNotes();
  }, []);

  const onClickThread = (thread: ReduxThread) => {
    setOpenThreadId(thread.id);
    if (thread.unread_messages.length > 0) {
      updateUnreadMessages(thread.id, thread.unread_messages);
    }
  };

  const onRefresh = async () => {
    try {
      setIsLoading(true);
      await refreshNotes();
      notify(`Threads updated`, NotificationType.success);
    } catch (e) {
      notify(`Failed to update threads ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const openTextBoxForNewNote = () => {
    // scrollRef.current?.scrollTo({top: 0});
    setIsNewNoteTextBoxVisible(true);
  };

  const onCreateNewNote = async (text: string) => {
    try {
      setIsLoading(true);
      await createNewThread(text);
      onReset();
      notify(`Threads created`, NotificationType.success);
    } catch (e) {
      notify(`Failed to create new thread ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        paddingLeft: 8,
        height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          height: NOTES_SECTION_HEADER_HEIGHT,
        }}>
        <Typography style={{fontSize: 20, fontWeight: 600}}>Notes</Typography>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DSButton
            style={{marginRight: 12}}
            text="Reset"
            type="secondary"
            onClick={onReset}
            disabled={isLoading}
          />
          <DSButton
            style={{marginRight: 12}}
            text="Refresh"
            type="secondary"
            onClick={onRefresh}
            disabled={isLoading}
          />
          <DSButton
            text="Add note"
            type="secondary"
            onClick={openTextBoxForNewNote}
            disabled={isLoading}
          />
        </div>
      </div>
      <div
        ref={scrollRef}
        style={{
          overflowY: 'auto',
          height:
            spaceLeftBelowTaxReturnSection -
            TAX_RETURN_BOTTOM_BAR -
            NOTES_SECTION_HEADER_HEIGHT,
        }}>
        {isNewNoteTextBoxVisible && (
          <WriteComment
            style={{marginTop: 12}}
            onSend={onCreateNewNote}
            disabled={isLoading}
            autoFocus
          />
        )}
        {threads.map((thread) => (
          <Thread
            onClick={onClickThread}
            isCollapsed={openThreadId !== thread.id}
            thread={thread}
          />
        ))}
      </div>
    </div>
  );
};

export default Threads;
