import React, {useCallback, useMemo, useState} from 'react';
import {Checkbox, TextField, Typography} from '@mui/material';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Document, Page} from 'react-pdf';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {useOcrBoundingBox} from 'src/CpaCenterV2/hooks/useOcrBoundingBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {themmeColor} from 'src/constants/constants';
import {sendDocForReviewEsign} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {MANDATORY_JURISDICTION} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {
  OcrDataSectionName,
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxProfileQuestion} from 'src/store/taxProfile/taxProfile.types';
import {FILING_STATUS_ANSWER} from 'src/store/taxProfile/taxProfile.types';
import DSButton from 'src/DesignSystem/Button/Button';
import {copyToClipboard, getFullName, validateEmail} from 'src/common/utils';
import {bulkSaveTaxProfileQuestions} from 'src/store/taxProfile/taxProfile.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {selectUser} from 'src/store/user/user.selector';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import {useTaxProfile} from 'src/CpaCenterV2/hooks/useTaxProfile';
import CopyIcon from 'src/icons/CopyIcon';
import DocumentRender from './DocumentRender';

const getIsSpouseEmailRequired = (filingStatus: FILING_STATUS_ANSWER) => {
  return filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY;
};

const SignerDetails = ({
  isSpouse,
  name,
  email,
}: {
  isSpouse: boolean;
  name: string;
  email: string;
}) => {
  const {notify} = useNotify();

  const onPressCopy = (value: string) => {
    copyToClipboard(value, () => notify('Copied', NotificationType.success));
  };

  const signer = isSpouse ? 'Spouse' : 'TaxPayer';

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: themmeColor.offWhite,
          marginTop: 2,
          paddingLeft: 16,
          paddingTop: 10,
          paddingBottom: 10,
        }}>
        <Text
          type={Text.TEXT_TYPES.BASE}
          text={`E-sign ${signer} name: `}
          fontWeight={Text.FONT_WEIGHTS.Regular}
        />
        <Text
          type={Text.TEXT_TYPES.BASE}
          text={name}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          containerStyle={{marginLeft: 4}}
        />

        <div
          onClick={() => onPressCopy(name)}
          style={{cursor: 'pointer', marginLeft: 6, paddingTop: 4}}>
          <CopyIcon />
        </div>

        <Text
          type={Text.TEXT_TYPES.BASE}
          text={`email: `}
          fontWeight={Text.FONT_WEIGHTS.Regular}
          containerStyle={{marginLeft: 16}}
        />
        <Text
          type={Text.TEXT_TYPES.BASE}
          text={email}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          containerStyle={{marginLeft: 4}}
        />
      </div>
    </div>
  );
};

const BottomActionBar = ({
  primaryButtonOnClick,
  secondaryButtonOnClick,
  tertiaryButtonOnClick,
  isLoading,
  primaryButtonDisabled,
  makeSpouseAsPrimary,
  setMakeSpouseAsPrimary,
}: {
  primaryButtonOnClick: (email?: string) => void;
  secondaryButtonOnClick: (email?: string) => Promise<void>;
  tertiaryButtonOnClick: () => void;
  isLoading: boolean;
  primaryButtonDisabled: boolean;
  makeSpouseAsPrimary: boolean;
  setMakeSpouseAsPrimary: (state: boolean) => void;
}) => {
  const {email: userEmail} = useSelector(selectUser);
  const {taxProfileMap} = useTaxProfile();
  const filingStatus = taxProfileMap[TaxProfileQuestion.FILING_STATUS];
  const taxProfileSpouseEmail = taxProfileMap[TaxProfileQuestion.SPOUSE_EMAIL];

  const [spouseEmail, setSpouseEmail] = useState(taxProfileSpouseEmail);

  const taxPayerName = getFullName(
    taxProfileMap[TaxProfileQuestion.LEGAL_NAME],
  );
  const spouseLegalName = getFullName(
    taxProfileMap[TaxProfileQuestion.SPOUSE_LEGAL_NAME],
  );

  const isSpouseEmailRequired = getIsSpouseEmailRequired(filingStatus);
  const isSpouseEmailSameAsTaxpayerEmail =
    isSpouseEmailRequired && userEmail === spouseEmail;
  const isPrimaryButtonDisabled =
    primaryButtonDisabled ||
    isLoading ||
    (isSpouseEmailRequired && (spouseEmail === '' || spouseEmail == null)) ||
    isSpouseEmailSameAsTaxpayerEmail;

  const isSecondaryButtonDisabled =
    isLoading || isSpouseEmailSameAsTaxpayerEmail;

  return (
    <div>
      {isSpouseEmailRequired && (
        <div>
          <TextField
            label="Spouse email id"
            fullWidth
            type={'text'}
            style={{marginTop: 16}}
            variant="outlined"
            value={spouseEmail}
            placeholder="Spouse email id"
            onChange={(e) => setSpouseEmail(e.target.value)}
          />
        </div>
      )}
      {filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Checkbox
              style={{paddingLeft: '0'}}
              onChange={(e) => setMakeSpouseAsPrimary(e.target.checked)}
              checked={makeSpouseAsPrimary}
            />
            <Text
              type={TEXT_TYPES.BASE}
              text={'Make spouse as primary taxpayer for e-sign'}
              fontWeight={FONT_WEIGHTS.Regular}
            />
          </div>
          <SignerDetails
            isSpouse={false}
            name={makeSpouseAsPrimary ? spouseLegalName : taxPayerName}
            email={makeSpouseAsPrimary ? spouseEmail : userEmail}
          />
          <SignerDetails
            isSpouse={true}
            name={makeSpouseAsPrimary ? taxPayerName : spouseLegalName}
            email={makeSpouseAsPrimary ? userEmail : spouseEmail}
          />
        </>
      )}
      {isSpouseEmailSameAsTaxpayerEmail ? (
        <div style={{marginTop: 8}}>
          <Text
            color={themmeColor.errorRed}
            type={Text.TEXT_TYPES.S}
            text="Spouse email should not be same as taxpayer email. Please input a different spouse email by reaching out to the user."
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginTop: 12,
        }}>
        <DSButton
          type="primary"
          text={'Send automatically'}
          onClick={() => primaryButtonOnClick(spouseEmail)}
          disabled={isPrimaryButtonDisabled}
          style={{flex: 0.3}}
        />
        <DSButton
          type="secondary"
          text={'Send manually'}
          onClick={() => secondaryButtonOnClick(spouseEmail)}
          disabled={isSecondaryButtonDisabled}
          style={{flex: 0.3}}
        />
        <DSButton
          type="tertiary"
          text={'Close'}
          onClick={tertiaryButtonOnClick}
          style={{flex: 0.3}}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

interface ReviewReturnPlaceholderPopupProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  onSendManually: () => void;
  makeSpouseAsPrimary: boolean;
  setMakeSpouseAsPrimary: (state: boolean) => void;
}

const BUSINESS_RETURN_STATES_ALLOWED_AUTO_ESIGN = [
  'CA',
  'AZ',
  'CO',
  'DE',
  'FL',
  'GA',
  'IL',
  'SC',
  'TX',
  'NY',
];

const ReviewReturnPlaceholderPopup = ({
  url,
  isOpen,
  onClose,
  onSendManually,
  makeSpouseAsPrimary,
  setMakeSpouseAsPrimary,
}: ReviewReturnPlaceholderPopupProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();
  const dispatch = useDispatch();
  const {canvasPDFRef, numPages, onDocumentLoadSuccess, drawBoundingBox} =
    useOcrBoundingBox();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();

  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TaxProfileQuestion.FILING_STATUS,
      activeYear,
    ),
  );

  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();

  const isSpouseEmailRequired = useMemo(
    () => getIsSpouseEmailRequired(filingStatus),
    [filingStatus],
  );

  const _onDocumentLoadSuccess = useCallback((props: any) => {
    onDocumentLoadSuccess(props);
  }, []);

  const _onRenderPage = useCallback(
    (pageNumber: number) => {
      drawBoundingBox(
        currentReturn.ocr_data.esign_fields.filter((field) => {
          return field.page_number === pageNumber;
        }),
      );
    },
    [currentReturn],
  );

  const onClickSendManually = async (email: string = '') => {
    if (isSpouseEmailRequired) {
      if (!validateEmail(email)) {
        notify('Please provide valid spouse email', NotificationType.error);
        return;
      }
      await Promise.all([
        dispatch(
          bulkSaveTaxProfileQuestions(
            userId,
            [
              {
                question_id: TaxProfileQuestion.SPOUSE_EMAIL,
                answer: email,
              },
            ],
            activeYear,
          ),
        ),
      ]);
    }
    onSendManually();
  };

  const onSendAutomatically = async (email: string = '') => {
    try {
      setIsLoading(true);
      if (isSpouseEmailRequired) {
        if (!validateEmail(email)) {
          notify('Please provide valid spouse email', NotificationType.error);
          return;
        }
        await Promise.all([
          dispatch(
            bulkSaveTaxProfileQuestions(
              userId,
              [
                {
                  question_id: TaxProfileQuestion.SPOUSE_EMAIL,
                  answer: email,
                },
              ],
              activeYear,
            ),
          ),
        ]);
      }
      await sendDocForReviewEsign({
        user: userId,
        year: activeYear,
        filename: currentReturn.document_name,
        esign_fields: currentReturn.ocr_data.esign_fields,
        jurisdiction: MANDATORY_JURISDICTION,
        // sign_now_document_id: signNowId,
        s3_url: url,
        return_id: returnId,
        return_type: currentReturn.return_type,
        use_spouse_as_primary: makeSpouseAsPrimary,
      });
      await dispatch(fetchTaxReturns(userId, activeYear));
      onClose();
      navigateToTaxReturnDetailedView(
        returnId,
        currentReturn?.return_type,
        TaxReturnStatus.REVIEW_ESIGN,
      );
      notify('Document sent for review esign', NotificationType.success);
    } catch (e) {
      notify(`Failed to send ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const docRender = useMemo(() => {
    // Doc render has to be calculated in useMemo as it depends on numPages which takes time to load
    return (
      <DocumentRender
        url={url}
        _onDocumentLoadSuccess={_onDocumentLoadSuccess}
        numPages={numPages}
        _onRenderPage={_onRenderPage}
        canvasPDFRef={canvasPDFRef}
      />
    );
  }, [numPages, url, canvasPDFRef, _onRenderPage]);

  const isSendAutomaticallyDisabled = useMemo(() => {
    if (currentReturn.return_type === TaxReturnType.BUSINESS) {
      return Object.keys(
        currentReturn.ocr_data[OcrDataSectionName.states_return_summary],
      ).some((stateAbbr) =>
        BUSINESS_RETURN_STATES_ALLOWED_AUTO_ESIGN.includes(stateAbbr),
      );
    }

    return false;
  }, [currentReturn]);
  if (!currentReturn) {
    return null;
  }

  return (
    <PopUp
      isOpen={isOpen}
      onClose={onClose}
      style={{height: '99%', overflow: 'auto'}}>
      <Typography style={{fontWeight: 800, fontSize: 20}}>
        Review the signature & date placeholders
      </Typography>
      <Typography style={{fontSize: 13, marginTop: 4, marginBottom: 2}}>
        Make sure that we are asking user to sign at the correct places.
      </Typography>
      <Typography style={{fontSize: 13, fontWeight: 600, marginBottom: 8}}>
        If there is some issue, upload tax return on “SignNow” and use send
        manually option.
      </Typography>

      {docRender}

      <BottomActionBar
        key={`${isSpouseEmailRequired}`}
        primaryButtonDisabled={isSendAutomaticallyDisabled}
        primaryButtonOnClick={onSendAutomatically}
        secondaryButtonOnClick={onClickSendManually}
        tertiaryButtonOnClick={onClose}
        isLoading={isLoading}
        makeSpouseAsPrimary={makeSpouseAsPrimary}
        setMakeSpouseAsPrimary={setMakeSpouseAsPrimary}
      />
    </PopUp>
  );
};

export default ReviewReturnPlaceholderPopup;
