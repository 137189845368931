import React, {useEffect, useMemo, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  exportData,
  patchTaxFiling,
  updateCCHClientId,
  updateTFSegment,
  updateTaxFilingExtensionStatus,
} from 'src/appApi';
import {
  COMMON_COMPONENTS_ID,
  TAX_EXTENSION_STATUS,
  themmeColor,
} from 'src/constants/constants';
import {CpaCenterV2Route} from 'src/constants/routeName';
import {
  BACKEND_RESPONSE_KEYS,
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import BackIcon from 'src/icons/BackIcon';
import NotesIcon from 'src/icons/NotesIcon';
import TaxProfileIcon from 'src/icons/TaxProfileIcon';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {selectUser} from 'src/store/user/user.selector';
import LabelField from './components/LabelField';
import NotesPopUp from './components/NotesPopUp';
import UpdateCCHClientId from './components/UpdateCCHClientId';
import UpdateReturnStatus from './components/UpdateReturnStatus';
import DSButton from 'src/DesignSystem/Button/Button';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates, NotificationType} from 'src/store/app/app.reducer';
import {ReduxStateType} from 'src/store/store';
import {selectAppReducerState} from 'src/store/app/app.selector';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import CollapseButton from './components/CollapseButton';
import {TF_SEGMENT, USER_COMMS_MODE} from 'src/store/user/user.reducer';
import {Mail, Smartphone} from '@mui/icons-material';
import UpdateExtensionStatusPopup from './components/UpdateExtensionStatusPopup';
import UpdateTFSegmentPopup from './components/UpdateTFSegmentPopup';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import ExtraCountCell from './components/ExtraCountCell';
import MultiReturnStatusUpdatePopup from './components/MultiReturnStatusUpdatePopUp';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {ReturnType} from 'src/store/threads/threads.reducer';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';

const UserSummaryBar = () => {
  const {activeYear} = useActiveYear();
  const {
    userId,
    userName,
    email,
    lastStatusChange,
    cchClientId,
    cpaFacingRetStatus,
    taxFilingId,
    notes,
    loaded,
    comms_mode,
    esign_mode,
    tax_extension_status,
    tfSegment,
  } = useSelector(selectUser);
  const {taxReturns, loaded: taxRetunsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const isTaxProfileSummaryDrawer = useSelector<ReduxStateType>((state) =>
    selectAppReducerState(state, AppReducerStates.isTaxProfileSummaryDrawer),
  ) as boolean;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!loaded);
  const [cchPopUp, setCchPopUp] = useState(false);
  const [taxFilingStatusPopUp, setTaxFilingStatusPopUp] = useState(false);
  const [multiReturnStatusPopUp, setMultiReturnStatusPopUp] = useState(false);
  const [selectedReturnId, setSelectedReturnId] = useState(-1);
  const [selectedReturnType, setSelectedReturnType] = useState(
    TaxReturnType.INDIVIDUAL,
  );
  const [selectedReturnStatus, setSelectedReturnStatus] = useState(
    TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED,
  );
  const [selectedReturnName, setSelectedReturnName] = useState('Individual');
  const [taxExtensionStatusPopUp, setTaxExtensionStatusPopUp] =
    useState(false);
  const [tfSegmentPopUp, setTFSegmentPopUp] = useState(false);
  const [notesPopUp, setNotesPopUp] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const refreshInfo = () => {
    dispatch(fetchUserInfo(userId, activeYear));
    dispatch(fetchTaxReturns(userId, activeYear));
  };

  const {notify} = useNotify();

  useEffect(() => {
    setLoading(!loaded);
  }, [loaded]);

  const taxReturnDetails = useMemo(() => {
    const businessReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS,
    );
    const indReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
    );
    const indReturnData = indReturn
      ? {
          return_type: indReturn.return_type,
          return_id: indReturn.return_id,
          name: 'Individual',
          status: indReturn.cpa_status,
        }
      : {};
    const bizReturnData = taxReturns
      .filter((taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS)
      .map((taxReturn) => {
        {
          return {
            return_id: taxReturn.return_id,
            return_type: taxReturn.return_type,
            name: taxReturn.business_name ?? '',
            status: taxReturn.cpa_status,
          };
        }
      });
    const multiReturnStatusUpdateData = [indReturnData, ...bizReturnData];
    return {
      businessReturn: businessReturn,
      indReturn: indReturn,
      multiReturnStatusUpdateData: multiReturnStatusUpdateData,
    };
  }, [taxReturns]);

  const onUpdateCchId = async (newId: number) => {
    try {
      setLoading(true);
      await updateCCHClientId(userId, newId);
    } catch (e) {
      alert('Failed to update CCH Id');
    } finally {
      await refreshInfo();
      setLoading(false);
      setCchPopUp(false);
    }
  };
  const onUpdateTaxFilingStatus = async (
    returnId: number,
    returnType: TaxReturnType,
    newId: TAX_FILING_STATUS,
  ) => {
    try {
      setLoading(true);
      if (returnType === TaxReturnType.INDIVIDUAL) {
        await patchTaxFiling(
          {tax_filing_id: taxFilingId},
          {[BACKEND_RESPONSE_KEYS.STATUS]: newId},
        );
      } else {
        await patchTaxFiling(
          {tax_filing_id: returnId},
          {
            [BACKEND_RESPONSE_KEYS.CPA_STATUS]: newId,
            [BACKEND_RESPONSE_KEYS.RETURN_TYPE]: TaxReturnType.BUSINESS,
          },
        );
      }
    } catch (e) {
      alert('Failed to update Return status');
    } finally {
      await refreshInfo();
      setLoading(false);
      setTaxFilingStatusPopUp(false);
    }
  };
  const onUpdateNote = async (newNote: string) => {
    try {
      setLoading(true);
      await patchTaxFiling(
        {tax_filing_id: taxFilingId},
        {[BACKEND_RESPONSE_KEYS.NOTE]: newNote},
      );
    } catch (e) {
      alert('Failed to update Note');
    } finally {
      await refreshInfo();
      setLoading(false);
      setNotesPopUp(false);
    }
  };

  const onExportData = async () => {
    try {
      setLoading(true);
      await exportData({fly_user_id: userId, year: activeYear});
      notify('Export data success', NotificationType.success);
    } catch (e) {
      notify(`Export data failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateExtensionStatus = async (
    selectedStatus: TAX_EXTENSION_STATUS,
  ) => {
    try {
      setLoading(true);
      await updateTaxFilingExtensionStatus({
        fly_user_id_list: [userId],
        year: activeYear,
        tax_extension_status: selectedStatus,
      });
      await dispatch(fetchUserInfo(userId, activeYear));
      setTaxExtensionStatusPopUp(false);
      notify('Extension status updated', NotificationType.success);
    } catch (e) {
      notify(`Extension status update failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateTFSegment = async (selectedSegment: TF_SEGMENT) => {
    try {
      setLoading(true);
      await updateTFSegment({
        user_ids: [userId],
        segment_name: selectedSegment,
      });
      await dispatch(fetchUserInfo(userId, activeYear));
      setTFSegmentPopUp(false);
      notify('Tax Filing Segment updated', NotificationType.success);
    } catch (e) {
      notify(`Tax Filing Segment update failed ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBack = () => {
    navigate(CpaCenterV2Route.CpaCenterV2List);
  };

  const data = [
    {
      props: {
        label: 'User ID',
        value: userId,
      },
      Component: LabelField,
    },
    // {
    //   props: {
    //     label: 'Mode',
    //     value: {
    //       comms_mode,
    //       esign_mode,
    //     },
    //   },
    //   Component: ({label, value}) => {
    //     return (
    //       <>
    //         <div
    //           style={{
    //             display: 'flex',
    //             flexDirection: 'column',
    //             height: 50,
    //             alignItems: 'center',
    //           }}>
    //           <Typography
    //             style={{
    //               fontSize: 11,
    //               fontWeight: 'bold',
    //               letterSpacing: 0.7,
    //               textTransform: 'uppercase',
    //               color: themmeColor.black60,
    //             }}>
    //             {label}
    //           </Typography>
    //           {value && (
    //             <div style={{flexDirection: 'row'}}>
    //               {value.comms_mode === USER_COMMS_MODE.APP ? (
    //                 <Smartphone />
    //               ) : (
    //                 <Mail />
    //               )}
    //               {value.esign_mode === USER_COMMS_MODE.APP ? (
    //                 <Smartphone />
    //               ) : (
    //                 <Mail />
    //               )}
    //             </div>
    //           )}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      props: {
        label: 'Name',
        value: userName,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Email address',
        value: email,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'CCH Client ID',
        value: cchClientId,
        onClick: () => setCchPopUp(true),
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Status Change',
        value: lastStatusChange,
      },
      Component: LabelField,
    },
    {
      props: {
        label: 'Return status',
        value: TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.label,
        onClick: () => setTaxFilingStatusPopUp(true),
        backgroundColor:
          TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.backColor,
        valueColor: TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.textColor,
      },
      Component: () => (
        <Box
          backgroundColor="#0D0F0F0D"
          style={{
            borderColor: themmeColor.silver,
            gap: '8px',
            padding: 12,
            alignItems: 'center',
          }}>
          {taxReturnDetails.indReturn && (
            <LabelField
              label="PERSONAL (RS)"
              value={TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.label}
              onClick={() => {
                setTaxFilingStatusPopUp(true);
                setSelectedReturnId(taxReturnDetails.indReturn?.return_id);
                setSelectedReturnType(TaxReturnType.INDIVIDUAL);
                setSelectedReturnStatus(
                  taxReturnDetails.indReturn?.cpa_status,
                );
                setSelectedReturnName('Individual');
              }}
              backgroundColor={
                TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.backColor
              }
              valueColor={
                TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.textColor
              }
            />
          )}
          {taxReturnDetails.businessReturn && (
            <LabelField
              label={
                (
                  taxReturnDetails.businessReturn.business_name ??
                  'Business return'
                ).length > 15
                  ? (
                      taxReturnDetails.businessReturn.business_name ??
                      'Business return'
                    )
                      .substring(0, 12)
                      .concat('...')
                  : taxReturnDetails.businessReturn.business_name ??
                    'Business return'
              }
              value={
                TAX_RETURN_STATUS_LABELS[
                  taxReturnDetails.businessReturn.cpa_status
                ]?.label
              }
              onClick={() => {
                setTaxFilingStatusPopUp(true);
                setSelectedReturnId(
                  taxReturnDetails.businessReturn?.return_id,
                );
                setSelectedReturnType(TaxReturnType.BUSINESS);
                setSelectedReturnStatus(
                  taxReturnDetails.businessReturn?.cpa_status,
                );
                setSelectedReturnName(
                  taxReturnDetails.businessReturn.business_name ?? 'Business',
                );
              }}
              backgroundColor={
                TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.backColor
              }
              valueColor={
                TAX_RETURN_STATUS_LABELS[cpaFacingRetStatus]?.textColor
              }
            />
          )}
          {taxReturns.length > 2 && (
            <ExtraCountCell
              onClick={() => setMultiReturnStatusPopUp(true)}
              value={taxReturns.length - 2}
            />
          )}
        </Box>
      ),
    },
    {
      props: {
        label: 'Extension Status',
        value: tax_extension_status,
        onClick: () => setTaxExtensionStatusPopUp(true),
      },
      Component: LabelField,
    },
    {
      props: {
        label: '',
        onClick: () => setNotesPopUp(true),
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<NotesIcon />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="secondary"
        />
      ),
    },
    // {
    //   props: {
    //     label: 'TF Segment',
    //     value: tfSegment,
    //     onClick: () => setTFSegmentPopUp(true),
    //   },
    //   Component: LabelField,
    // },
    {
      props: {
        label: `Export`,
        onClick: onExportData,
      },
      Component: ({label, onClick}) => (
        <DSButton
          startIcon={<UploadCloudIcon color={themmeColor.white} />}
          text={label}
          disabled={loading}
          onClick={onClick}
          type="primary"
        />
      ),
    },
    {
      props: {},
      Component: () => (
        <Box
          hoverEffect
          onClick={() =>
            dispatch(
              setAppState(
                AppReducerStates.isTaxProfileSummaryDrawer,
                !isTaxProfileSummaryDrawer,
              ),
            )
          }
          backgroundColor={
            isTaxProfileSummaryDrawer ? themmeColor.black : '#F0F0F0'
          }
          style={{borderColor: themmeColor.silver, padding: 12}}>
          <TaxProfileIcon />
        </Box>
      ),
    },
  ];

  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_USER_SUMMARY_BAR}
      style={{
        position: 'relative',
      }}>
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingInline: 32,
          paddingBlock: isCollapsed ? 0 : 12,
          flexWrap: 'wrap',
          height: isCollapsed ? 0 : undefined,
          overflow: 'hidden',
        }}
        noBR>
        <Box
          borderColor={themmeColor.silver}
          hoverEffect
          onClick={onClickBack}
          style={{paddingBlock: 12, paddingInline: 4}}
          hoverColor={themmeColor.focusedBlue}>
          <BackIcon />
          <Typography>Users List</Typography>
        </Box>
        {data.map(({Component, props}) => (
          <Component {...props} loaded={loaded} />
        ))}
      </Box>
      <CollapseButton
        setIsCollapsed={setIsCollapsed}
        isCollapsed={isCollapsed}
      />
      <UpdateCCHClientId
        key={`cchUpdatePopUp-${cchClientId}-${cchPopUp}`}
        isOpen={cchPopUp}
        onClose={() => setCchPopUp(false)}
        cchClientId={cchClientId}
        loading={loading}
        onUpdate={onUpdateCchId}
      />
      <UpdateReturnStatus
        key={`returnStatus-${cpaFacingRetStatus}-${taxFilingStatusPopUp}`}
        isOpen={taxFilingStatusPopUp}
        onClose={() => setTaxFilingStatusPopUp(false)}
        returnName={selectedReturnName}
        returnId={selectedReturnId}
        returnType={selectedReturnType}
        currentReturnStatusId={selectedReturnStatus}
        loading={loading}
        onUpdate={onUpdateTaxFilingStatus}
      />
      {taxRetunsLoaded && (
        <MultiReturnStatusUpdatePopup
          key={`multiReturnStatusPopup-${multiReturnStatusPopUp}`}
          isOpen={multiReturnStatusPopUp}
          dataArray={taxReturnDetails.multiReturnStatusUpdateData}
          loading={loading}
          taxFilingStatusPopUp={taxFilingStatusPopUp}
          selectedReturnId={selectedReturnId}
          selectedReturnType={selectedReturnType}
          selectedReturnStatus={selectedReturnStatus}
          selectedReturnName={selectedReturnName}
          setLoading={setLoading}
          setTaxFilingStatusPopUp={setTaxFilingStatusPopUp}
          setSelectedReturnId={setSelectedReturnId}
          setSelectedReturnStatus={setSelectedReturnStatus}
          setSelectedReturnName={setSelectedReturnName}
          setSelectedReturnType={setSelectedReturnType}
          onClose={() => setMultiReturnStatusPopUp(false)}
          onUpdate={onUpdateTaxFilingStatus}
        />
      )}
      <NotesPopUp
        key={`notesPopUp-${notes}-${notesPopUp}`}
        isOpen={notesPopUp}
        onClose={() => setNotesPopUp(false)}
        loading={loading}
        onUpdate={onUpdateNote}
        notes={notes}
      />
      <UpdateExtensionStatusPopup
        key={`extensionStatus-${tax_extension_status}-${taxExtensionStatusPopUp}`}
        isOpen={taxExtensionStatusPopUp}
        onClose={() => setTaxExtensionStatusPopUp(false)}
        currentExtensionStatus={tax_extension_status}
        loading={loading}
        onUpdateStatus={onUpdateExtensionStatus}
      />
      <UpdateTFSegmentPopup
        key={`extensionStatus-${tfSegment}-${tfSegmentPopUp}`}
        isOpen={tfSegmentPopUp}
        onClose={() => setTFSegmentPopUp(false)}
        currentSegment={tfSegment}
        loading={loading}
        onUpdateSegment={onUpdateTFSegment}
      />
    </div>
  );
};

export default UserSummaryBar;
