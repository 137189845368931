import React, {useMemo} from 'react';
import PartnershipUserListHeader from './components/PartnershipUserListHeader';
import {
  PartnershipUserListProvider,
  usePartnershipUserList,
} from './usePartnershipUserList';
import Table from 'src/DesignSystem/Table/Table';
import {PartnershipReturnFields} from 'src/appApi.types';
import {PartnershipFilters} from './PartnershipUserList.types';
import DragFilesOrManualReturnElement from './components/CreateReturnComponents/DragFilesOrManualReturnElement';
import {
  CreateReturnProvider,
  useCreateReturn,
} from './components/CreateReturnComponents/useCreateReturn';
import CreateReturnManuallyDrawer from './components/CreateReturnComponents/CreateReturnManuallyDrawer';
import CreatedReturnsPopup from './components/CreateReturnComponents/CreatedReturnsPopup';
import Dropzone from 'src/DesignSystem/Dropzone/Dropzone';
import {CREATE_RETURN_STATUS} from './components/CreateReturnComponents/CreateReturn.types';
import {PartnershipUserTableColumns} from './PartnershipUserList.constants';
import CreateReturnFromDocDrawer from './components/CreateReturnComponents/CreateReturnFromDocDrawer';

const PartnershipUserListContent = () => {
  const {
    partnershipReturns,
    loadingReturns,
    changePageNumber,
    changePageSize,
    appliedFilters,
    onUpdateRow,
  } = usePartnershipUserList();

  const {onUploadDocument, status} = useCreateReturn();

  return (
    <div
      style={{
        flex: 1,
        flexFlow: 'column',
        height: '100vh',
        display: 'flex',
      }}>
      <PartnershipUserListHeader />
      <Dropzone
        onDropFiles={onUploadDocument}
        disabled={status !== CREATE_RETURN_STATUS.INIT}>
        <DragFilesOrManualReturnElement />
        <Table
          autoHeight={true}
          columns={PartnershipUserTableColumns}
          rows={partnershipReturns.results}
          getRowId={(row) => row[PartnershipReturnFields.tax_filing_id]}
          loading={loadingReturns}
          rowCount={partnershipReturns.count}
          page={(appliedFilters[PartnershipFilters.page] ?? 1) - 1}
          onPageChange={(pageNumber) => changePageNumber(pageNumber + 1)}
          pageSize={appliedFilters[PartnershipFilters.page_size] ?? 10}
          onPageSizeChange={(pageSize) => changePageSize(pageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          paginationMode="server"
          pagination
          processRowUpdate={onUpdateRow}
          experimentalFeatures={{newEditingApi: true}}
          disableSelectionOnClick
        />
      </Dropzone>

      <CreateReturnManuallyDrawer />
      <CreateReturnFromDocDrawer />
      <CreatedReturnsPopup />
    </div>
  );
};

const PartnershipUserList = () => {
  return (
    <PartnershipUserListProvider>
      <CreateReturnProvider>
        <PartnershipUserListContent />
      </CreateReturnProvider>
    </PartnershipUserListProvider>
  );
};

export default PartnershipUserList;
