import React, {useContext} from 'react';
import {updateTaxForm} from 'src/appApi';
import EditTaxForm from 'src/common/EditTaxForm/EditTaxForm';
import {useEditTaxForm} from 'src/common/EditTaxForm/useEditTaxForm';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import ViewDocumentContext from 'src/CpaCenterV2/ViewDocument/ViewDocument.context';
import DSButton from 'src/DesignSystem/Button/Button';
import {GenericTaxForm} from 'src/store/taxForms/taxForms.types';

interface RenderTaxFormProps {
  taxform: GenericTaxForm;
}

const RenderTaxForm = ({taxform}: RenderTaxFormProps) => {
  const {document, refreshDocuments: fetchDocuments} =
    useContext(ViewDocumentContext);
  const {fields, linkedBusinessId, onUpdate, areAllFieldsValid, isLoading} =
    useEditTaxForm({
      taxFormId: taxform.taxFormId,
      taxFormType: taxform.formType,
      prefilledFormData: null,
      multi_tax_forms_entity_id: taxform.linked_business_id,
    });
  const {userId} = useCurrentUserId();

  const completeDocumentReview = async () => {
    await updateTaxForm({
      fly_user_id: userId,
      tfd_info_id: document.docId,
      forms: [],
    });

    await fetchDocuments();
  };
  return (
    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 36}}>
      <EditTaxForm fields={fields} linkedBusinessId={linkedBusinessId} />
      <DSButton
        disabled={!areAllFieldsValid || isLoading}
        onClick={() => onUpdate(completeDocumentReview)}
        text="Update"
        type="primary"
      />
    </div>
  );
};

export default RenderTaxForm;
