import React from 'react';
import {themmeColor} from 'src/constants/constants';
interface CellProps {
  cellStyle?: React.CSSProperties;
  backgroundColor?: string;
  children: any;
}

const Cell = ({
  backgroundColor = themmeColor.white,
  cellStyle = {},
  children,
}: CellProps) => {
  return (
    <div
      style={{
        backgroundColor,
        flex: 1,
        height: 'auto',
        padding: 4,
        ...cellStyle,
      }}>
      {children}
    </div>
  );
};

export default Cell;
