import React, {createContext} from 'react';
import {CPA_CALL_STATUS} from 'src/constants/constants';

export interface Message {
  text: string;
  fromUs: boolean;
  timeStamp: number;
  tagged_documents: {
    name: string;
    document_type_id: number;
    document_id: number;
    doc_type: string;
    document_exists: boolean;
    document_type_exists: boolean;
  };
}

export interface Query {
  query_id: number;
  resolved: boolean;
  query_type: string;
  title: string;
  query_status: string;
  messages: Array<Message>;
  actionRequired: boolean;
  call_status?: CPA_CALL_STATUS | null;
  start_time?: string | null;
  end_time?: string | null;
}

interface TaxQueriesContextProps {
  queriesData: Array<Query>;
  fetchTaxQueries: () => Promise<any>;
  postingQuery: boolean;
  setPostingQuery: (newValue: boolean) => void;
}

// @ts-ignore
const TaxQueriesContext = createContext<TaxQueriesContextProps>(undefined);

export default TaxQueriesContext;
