import {
  TaxReturnStepsSequence,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {useSelector} from 'react-redux';
import {
  TaxReturn,
  TaxReturnStatus,
} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {TAX_FILING_STATUS} from 'src/constants/constants';

const useTaxReturnStep = (returnId: number, returnType: TaxReturnType) => {
  const {taxReturns, loaded} = useSelector(selectTaxReturnsReducer);
  const currentReturn = taxReturns.find(
    (taxReturn) =>
      taxReturn.return_id === returnId && taxReturn.return_type === returnType,
  ) as TaxReturn;
  const currentStep = loaded
    ? currentReturn.status
    : TaxReturnStatus.ADD_RETURN;

  const isCompleteReviewEsignStep = (step: TaxReturnStatus) => {
    return (
      TaxReturnStepsSequence.indexOf(step) <
      TaxReturnStepsSequence.indexOf(currentStep)
    );
  };

  const isDisabledReviewEsignStep = (step: TaxReturnStatus) => {
    return (
      TaxReturnStepsSequence.indexOf(step) >
      TaxReturnStepsSequence.indexOf(currentStep)
    );
  };

  let taxReturnSteps = [
    {
      label: 'Send to CCH',
      props: {
        step: TaxReturnStatus.SEND_TO_CCH,
      },
      disabled: isDisabledReviewEsignStep(TaxReturnStatus.SEND_TO_CCH),
      isCompleted: isCompleteReviewEsignStep(TaxReturnStatus.SEND_TO_CCH),
    },
    {
      label: 'Add return',
      props: {
        step: TaxReturnStatus.ADD_RETURN,
      },
      disabled:
        isDisabledReviewEsignStep(TaxReturnStatus.ADD_RETURN) ||
        isCompleteReviewEsignStep(TaxReturnStatus.ADD_RETURN),
      isCompleted: isCompleteReviewEsignStep(TaxReturnStatus.ADD_RETURN),
    },
    {
      label: 'review Summary',
      props: {
        step: TaxReturnStatus.REVIEW_SUMMARY,
      },
      disabled: isDisabledReviewEsignStep(TaxReturnStatus.REVIEW_SUMMARY),
      isCompleted: isCompleteReviewEsignStep(TaxReturnStatus.REVIEW_SUMMARY),
    },
    {
      label: 'CPA Review',
      props: {
        step: TaxReturnStatus.CPA_REVIEW,
      },
      disabled: isDisabledReviewEsignStep(TaxReturnStatus.CPA_REVIEW),
      isCompleted: isCompleteReviewEsignStep(TaxReturnStatus.CPA_REVIEW),
    },
    {
      label: 'review & e-sign',
      props: {
        step: TaxReturnStatus.REVIEW_ESIGN,
      },
      disabled: isDisabledReviewEsignStep(TaxReturnStatus.REVIEW_ESIGN),
      isCompleted: isCompleteReviewEsignStep(TaxReturnStatus.REVIEW_ESIGN),
    },
    {
      label: 'E-file status',
      props: {
        step: TaxReturnStatus.EFILE_STATUS,
      },
      disabled: isDisabledReviewEsignStep(TaxReturnStatus.EFILE_STATUS),
      isCompleted:
        isCompleteReviewEsignStep(TaxReturnStatus.EFILE_STATUS) ||
        currentReturn?.cpa_status === TAX_FILING_STATUS.APPROVED_FROM_IRS,
    },
  ];


  return {
    taxReturnSteps,
    currentStep,
  };
};

export default useTaxReturnStep;
