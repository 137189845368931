import React, {useCallback, useMemo} from 'react';
import {useCreateReturn} from '../useCreateReturn';
import {DocumentAssignmentStatus, ReturnFields} from '../CreateReturn.types';
import {DOCUMENT_TYPES, themmeColor} from 'src/constants/constants';
import PDFWithOcrBoxes from '../../../../../DesignSystem/OCR/PDFWithOcrBoxes';
import ImageWithOCRBoxes from '../../../../../DesignSystem/OCR/ImageWithOCRBoxes';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {OCRBox} from 'src/DesignSystem/OCR/OCR.types';
import {getUsersToAssignDoc} from '../CreateReturn.utils';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import {useArrayField} from 'src/DesignSystem/Form/common/ArrayField/useArrayField';
import {SingleDocumentAssignmentConfig} from '../CreateReturn.constants';
import {Form} from 'src/DesignSystem/Form/Form';
import {PartnershipReturnFields} from 'src/appApi.types';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {cloneDeep} from 'lodash';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';
import Text from 'src/DesignSystem/Form/common/Text/Text';
import {
  FONT_WEIGHTS,
  TEXT_TYPES,
} from 'src/DesignSystem/Form/common/Text/Text.types';
import DSButton from 'src/DesignSystem/Button/Button';
import TickIcon from 'src/icons/TickIcon';

const PendingDocumentStatus = ({
  status,
  onMarkAsReviewed,
}: {
  status: DocumentAssignmentStatus;
  onMarkAsReviewed: () => void;
}) => {
  if (status === DocumentAssignmentStatus.PENDING) {
    return null;
  }
  if (status === DocumentAssignmentStatus.REVIEW_PENDING) {
    return (
      <DSButton
        text="Mark as reviewed"
        type="secondary"
        onClick={onMarkAsReviewed}
      />
    );
  }
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <TickIcon />
      <Text
        type={TEXT_TYPES.S}
        fontWeight={FONT_WEIGHTS.SemiBold}
        text={
          status === DocumentAssignmentStatus.ASSIGNED
            ? 'Assigned'
            : 'Reviewed'
        }
        containerStyle={{marginLeft: 8}}
      />
    </div>
  );
};

interface PendingDocumentData {
  doc: ReturnType<typeof useCreateReturn>['pendingDocs'][0];
  users: ReturnType<typeof getUsersToAssignDoc>;
  userIdMap: {[key: number]: ReturnType<typeof getUsersToAssignDoc>[0]};
  valueArray: any;
  onChangeValue: any;
  arrayObjectIndex: number;
}

const height = 300;

const PendingDocument = ({
  doc,
  users,
  userIdMap,
  valueArray,
  onChangeValue,
  arrayObjectIndex,
}: PendingDocumentData) => {
  const isPDF = doc[ReturnFields.filename].toLowerCase().endsWith('.pdf');
  const filepath = doc[ReturnFields.read_url];
  const {arrayObject, updateObject} = useArrayField(
    valueArray,
    onChangeValue,
    arrayObjectIndex,
  );

  const overwriteFields: OverWriteFormFieldsType = useCallback(
    ({fields: rawFields, data}) => {
      const fields = cloneDeep(rawFields);
      const selectedUserId = data[PartnershipReturnFields.fly_user_id];
      const isUserSelected = typeof selectedUserId === 'number';
      const selectedUser = isUserSelected ? userIdMap[selectedUserId] : null;

      const fieldsToRemove: string[] = [];

      if (!isUserSelected) {
        fieldsToRemove.push(ReturnFields.years);
        fieldsToRemove.push(ReturnFields.belongs_to_spouse);
      }

      const shouldAskSpouse =
        isUserSelected &&
        doc.doc_type === DOCUMENT_TYPES.DL_OR_STATE_ISSUED_ID &&
        selectedUser !== null &&
        selectedUser[ReturnFields.tax_profile_questions][
          TAX_PROFILE_FIELDS.FILING_STATUS
        ] === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY;

      if (!shouldAskSpouse) {
        fieldsToRemove.push(ReturnFields.belongs_to_spouse);
      }

      return fields
        .filter((field) => !fieldsToRemove.includes(field.path))
        .map((field) => {
          if (
            field.path === PartnershipReturnFields.fly_user_id &&
            field.inputType === FORM_INPUT_TYPE.SingleSelect
          ) {
            field.options = users.map((u) => {
              return {
                label: `${u[PartnershipReturnFields.name]} (${
                  u[ReturnFields.tax_profile_questions][TAX_PROFILE_FIELDS.SSN]
                })`,
                value: u[PartnershipReturnFields.fly_user_id],
              };
            });
            field.onChangeValue = (newVal) => {
              const updateUserAssignment = (oldState: any) => {
                return {
                  ...oldState,
                  [PartnershipReturnFields.fly_user_id]: newVal,
                  [ReturnFields.years]: [],
                  [ReturnFields.belongs_to_spouse]: false,
                  [ReturnFields.document_assignment_status]:
                    DocumentAssignmentStatus.ASSIGNED,
                };
              };
              updateObject(updateUserAssignment);
            };
          }
          if (
            field.path === ReturnFields.years &&
            field.inputType === FORM_INPUT_TYPE.MultiSelect &&
            isUserSelected &&
            selectedUser !== null
          ) {
            field.options = selectedUser[ReturnFields.years].map((year) => ({
              label: year,
              value: year,
            }));
          }

          return field;
        });
    },
    [users, userIdMap, updateObject],
  );

  const {fields} = useFormData({
    data: arrayObject,
    setData: updateObject,
    config: SingleDocumentAssignmentConfig,
    overWriteFields: overwriteFields,
  });

  const ocrFields: OCRBox[] = useMemo(() => {
    const boxes: OCRBox[] = [];
    Object.entries(doc.personal_details).forEach(([key, value]) => {
      boxes.push({...value.key_bounding_box, pageNumber: value.field_page});
      boxes.push({...value.value_bounding_box, pageNumber: value.field_page});
    });
    return boxes;
  }, [doc]);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        marginBottom: 32,
        backgroundColor: themmeColor.cpaCenterV2Bg,
        padding: 12,
        borderRadius: 12,
      }}>
      <div
        style={{
          display: 'flex',
          flex: 0.7,
          overflowX: 'auto',
        }}>
        {isPDF ? (
          <PDFWithOcrBoxes
            ocrData={ocrFields}
            height={height}
            pdfUrl={filepath}
            scale={1}
          />
        ) : (
          <ImageWithOCRBoxes
            ocrData={ocrFields}
            imageUrl={filepath}
            height={height}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.3,
          flexFlow: 'column',
          marginLeft: 12,
        }}>
        <div
          style={{
            backgroundColor: themmeColor.white,
            padding: 12,
            borderRadius: 12,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBlock: 4,
            }}>
            <Text
              type={TEXT_TYPES.BASE}
              fontWeight={FONT_WEIGHTS.SemiBold}
              text={'Document belongs to'}
            />
            <PendingDocumentStatus
              status={arrayObject[ReturnFields.document_assignment_status]}
              onMarkAsReviewed={() => {
                const getUpdatedStatus = (oldState: any) => ({
                  ...oldState,
                  [ReturnFields.document_assignment_status]:
                    DocumentAssignmentStatus.REVIEWED,
                });
                updateObject(getUpdatedStatus);
              }}
            />
          </div>
          {fields.map((field) => (
            <div style={{marginBottom: 8}}>
              <Form {...field} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PendingDocument;
