export const DEDUCTION_OPTIONS = {
  SCHEDULE_A: 'SCHEDULE_A',
  STANDARDIZE: 'STANDARDIZE',
} as const;

export enum WFH_ANSWER {
  YES = 'Yes',
  NO = 'No',
}

export const CONTRIBUTION_TYPE = {
  HSA: 'HSA_CONTRIBUTION',
  TRADITIONAL_IRA: 'TRADITIONAL_IRA',
  SEP_SIMPLE_QUALIFIED_SE_PLANS: 'SEP_SIMPLE_QUALIFIED_SE_PLANS',
};

export enum BUSINESS_VEHICLE_USAGE_CHOICES {
  LEASE = 'leased_or_rented',
  LOAN_INTEREST = 'paid_interest_on_loan',
  OWN_VEHICLE = 'used_own_vehicle',
}
