import _ from 'lodash';
import {getPath} from 'src/common/utils';
import {
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {BUSINESS_DETAILS_FIELD_CONFIG} from './EditBusinessDetails.config';

const getFieldsForBusinessEntity = (
  have_ein: boolean,
  entity_type: BusinessType,
) => {
  if (!have_ein) {
    // Unreg. business
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.professions,
      BusinessDetailsField.owner_or_proprietor,
      BusinessDetailsField.start_date,
      BusinessDetailsField.is_spouse_business_partner,
      BusinessDetailsField.is_work_continuing,
      BusinessDetailsField.work_stop_date,
    ];
  }

  const addressFields = [
    getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.number_and_street,
    ),
    getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.apartment_number,
    ),
    getPath(BusinessDetailsField.address, BusinessDetailsField.city),
    getPath(BusinessDetailsField.address, BusinessDetailsField.state),
    getPath(BusinessDetailsField.address, BusinessDetailsField.zip_code),
  ];

  if (entity_type === BusinessType.SOLE_PROPRIETORSHIP) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.owner_or_proprietor,
      BusinessDetailsField.ein,
      BusinessDetailsField.start_date,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
    ];
  }

  if (entity_type === BusinessType.S_CORP) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.ein,
      BusinessDetailsField.incorporation_date,
      BusinessDetailsField.s_corp_effective_date,
      BusinessDetailsField.phone_number,
      BusinessDetailsField.accounting_method,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
      BusinessDetailsField.share_holders,
      BusinessDetailsField.signing_authority_id,
    ];
  }

  if (
    entity_type === BusinessType.C_CORP ||
    entity_type === BusinessType.PARTNERSHIP
  ) {
    return [
      BusinessDetailsField.have_ein,
      BusinessDetailsField.entity_type,
      BusinessDetailsField.name,
      BusinessDetailsField.professions,
      BusinessDetailsField.ein,
      BusinessDetailsField.incorporation_date,
      BusinessDetailsField.phone_number,
      BusinessDetailsField.accounting_method,
      BusinessDetailsField.is_registered_with_state,
      BusinessDetailsField.is_address_same_as_home,
      ...addressFields,
      BusinessDetailsField.share_holders,
      BusinessDetailsField.signing_authority_id,
    ];
  }
  return [];
};

export const getBusinessDetailFormConfig = (
  have_ein: boolean,
  entity_type: BusinessType,
) => {
  const config = _.cloneDeep(BUSINESS_DETAILS_FIELD_CONFIG);
  const fieldsToShow = new Set(
    getFieldsForBusinessEntity(have_ein, entity_type),
  );
  return config.filter((field) => fieldsToShow.has(field.path));
};
