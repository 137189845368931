import React, {useMemo} from 'react';
import {Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {
  DOCUMENT_TYPES,
  VALID_DOCUMENT_STATUS,
  themmeColor,
} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import {selectDocuments} from 'src/store/documents/documents.selector';
import useBoiSteps from '../hooks/useBoiSteps';
import {selectBoiBusiness, selectBoiData} from 'src/store/boi/boi.selectors';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import DocumentsList from 'src/CpaCenterV2/DocumentsReview/components/DocumentsList';
import CompletedRibbon from 'src/CpaCenterV2/common/CompletedRibbon/CompletedRibbon';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {useNavigate} from 'react-router-dom';
import {
  BoiFilingCenterRoutesParts,
  BoiFilingCenterRoutes,
  RouteName,
} from 'src/constants/routeName';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileCpaSideStatus} from 'src/store/taxProfileStatus/taxProfileStatus.selector';
import {BOI_SECTION_NAMES} from '../types';
import {TaxProfileReviewStatus} from 'src/store/taxProfileStatus/taxProfileStatus.reducer';

const DocumentsReviewBoi = () => {
  const {userId} = useCurrentUserId();
  const navigate = useNavigate();
  const {isLoading: boiStepsLoading, markStepComplete} = useBoiSteps();

  const {documents, loaded: documentsLoaded} = useSelector(selectDocuments);
  const {taxForms, loaded: taxFormsLoaded} = useSelector(
    selectTaxFormsReducer,
  );
  const {loaded: boiDataLoaded} = useSelector(selectBoiData);
  const {business: boiBusiness} = useSelector(selectBoiBusiness);
  const {taxProfileCpaSideStatus, loaded: taxProfileCpaSideStatusLoaded} =
    useSelector(selectTaxProfileCpaSideStatus);

  const isLoading =
    boiStepsLoading ||
    !documentsLoaded ||
    !boiDataLoaded ||
    !taxFormsLoaded ||
    !taxProfileCpaSideStatusLoaded;

  const isDocumentReviewDone =
    taxProfileCpaSideStatus[BOI_SECTION_NAMES.BOI_DOCUMENT_REVIEW] ===
    TaxProfileReviewStatus.COMPLETE;

  const filterDocFunction = (doc: ReduxDocumentType) => {
    if (
      [
        DOCUMENT_TYPES.BOIR_INCORPORATION_DOC,
        DOCUMENT_TYPES.BOIR_INCORPORATION_CERTIFICATE,
        DOCUMENT_TYPES.BOIR_OWNERSHIP_LEASE_DOC,
        DOCUMENT_TYPES.BOIR_PAYROLL_REPORT,
        DOCUMENT_TYPES.BOIR_TAX_RETURN,
      ].includes(doc.docType)
    ) {
      return true;
    }
    if (
      [DOCUMENT_TYPES.DL_OR_STATE_ISSUED_ID, DOCUMENT_TYPES.PASSPORT].includes(
        doc.docType,
      )
    ) {
      const linkedTaxForm = taxForms.find(
        (taxForm) => taxForm.docLink === doc.docLink,
      );
      return linkedTaxForm != null;
    }
    if ([DOCUMENT_TYPES.BUSINESS_REGISTRATION_DOC].includes(doc.docType)) {
      return doc.docLink === boiBusiness.ein_doc_link;
    }

    return false;
  };

  const onDocumentClick = (doc: ReduxDocumentType) => {
    const to =
      `${RouteName.CpaCenterV2}${BoiFilingCenterRoutes.DocumentReview}/${doc.docId}`.replace(
        BoiFilingCenterRoutesParts.UserId,
        `${userId}`,
      );
    navigate(to);
  };

  const {spaceLeftBelowTaxFilingSteps, DOCUMENT_BOTTOM_BAR_HEIGHT} =
    useLayout();

  const filteredDocuments = useMemo(() => {
    return documents.filter(filterDocFunction);
  }, [documents]);

  return (
    <div>
      <div
        style={{
          height: spaceLeftBelowTaxFilingSteps - DOCUMENT_BOTTOM_BAR_HEIGHT,
          width: '70vw',
          paddingRight: '2vw',
        }}>
        <DocumentsList
          filterDoc={filterDocFunction}
          onDocumentClick={onDocumentClick}
        />
      </div>
      {!isDocumentReviewDone ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingInline: 40,
            alignItems: 'center',
            backgroundColor: themmeColor.offWhite,
            height: DOCUMENT_BOTTOM_BAR_HEIGHT,
          }}>
          <Typography style={{fontSize: 16}}>
            Once you have reviewed all the documents you will be able to
            proceed to next section
          </Typography>
          <DSButton
            disabled={
              isLoading ||
              filteredDocuments.some(
                (doc) =>
                  doc.docStatus === VALID_DOCUMENT_STATUS.PENDING_FOR_REVIEW,
              )
            }
            type="primary"
            onClick={() =>
              markStepComplete(BoiFilingCenterRoutesParts.DocumentReview)
            }
            text="Review complete"
            style={{width: 400}}
          />
        </div>
      ) : (
        <CompletedRibbon
          height={DOCUMENT_BOTTOM_BAR_HEIGHT}
          text="Document review is complete"
        />
      )}
    </div>
  );
};

export default DocumentsReviewBoi;
