import {Typography} from '@mui/material';
import DSButton from 'src/DesignSystem/Button/Button';
import CrossIcon from 'src/icons/CrossIcon';

interface RephrasedTextPreviewProps {
  isLoading: boolean;
  rephrasedMessage: string;
  setIsVisible: (newValue: boolean) => void;
  setMessage: (newString: string) => void;
}

const RephrasedTextPreview = ({
  isLoading,
  rephrasedMessage,
  setIsVisible,
  setMessage,
}: RephrasedTextPreviewProps) => {
  const onClose = () => {
    setIsVisible(false);
  };

  const onUse = () => {
    setMessage(rephrasedMessage);
    setIsVisible(false);
  };
  return (
    <div style={{backgroundColor: '#E0E3E7', padding: 12}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 12,
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography style={{fontWeight: 600}}>Chat GPT rephrase</Typography>
          <DSButton
            text="Use"
            onClick={onUse}
            disabled={isLoading}
            type="primary"
            style={{marginLeft: 8}}
          />
        </div>
        <div onClick={onClose}>
          <CrossIcon />
        </div>
      </div>
      <div>
        <Typography>{rephrasedMessage}</Typography>
      </div>
    </div>
  );
};

export default RephrasedTextPreview;
