import React from 'react';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import CommonDeductionTable from './CommonDeductionTable';
import {useSelector} from 'react-redux';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {CONTRIBUTION_TYPE} from '../Deductions.constants';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import Box from 'src/DesignSystem/Box/Box';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const IRAContributions = () => {
  const {activeYear} = useActiveYear();
  const {documents} = useSelector(selectDocuments);
  const {loaded: taxFormsLoaded, taxForms} = useSelector(
    selectTaxFormsReducer,
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const iraForms = taxFormsLoaded
    ? taxForms.filter(
        (form) =>
          form.formType === ALL_FORM_TYPES.FORM_SA_5498 &&
          [
            CONTRIBUTION_TYPE.SEP_SIMPLE_QUALIFIED_SE_PLANS,
            CONTRIBUTION_TYPE.TRADITIONAL_IRA,
          ].includes(form.formData[TaxFormFieldIds.ContributionType]),
      )
    : [];

  const iraDocs = documents.reduce((obj, doc) => {
    const formFound = iraForms.find((form) => form.docId === doc.docId);
    if (formFound) {
      return {
        ...obj,
        [formFound.formData[TaxFormFieldIds.ContributionType]]: [
          ...(obj[formFound.formData[TaxFormFieldIds.ContributionType]] ?? []),
          doc,
        ],
      };
    }

    return obj;
  }, {} as {[key: string]: ReduxDocumentType[]});

  return (
    <SubSectionBox
      name="IRA contributions"
      taxprofileSubsection={TaxProfileSubsection.IRAContributions}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 400,
              marginBottom: 4,
            }}>{`Select all type of IRA contributions that you and/or your spouse have made in ${activeYear}?`}</Typography>
          {Object.keys(iraDocs).map((contribution) => {
            return (
              <Box style={{flexDirection: 'column', marginTop: 20}}>
                <Typography style={{fontSize: 16, fontWeight: 600}}>
                  {contribution}
                </Typography>
                <Typography
                  style={{
                    fontSize: 11,
                    color: themmeColor.black60,
                    marginTop: 10,
                    marginBottom: 4,
                  }}>
                  Related document
                </Typography>
                {iraDocs[contribution].map((doc) => (
                  <DocumentPointer doc={doc} border />
                ))}
              </Box>
            );
          })}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.IRAContributions}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default IRAContributions;
