import * as React from 'react';
import {Fragment, useState} from 'react';
import {Button, Confirm, useMutation, useNotify} from 'react-admin';

const CreateDuplicateButton = ({plaidTxnId}) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [createDuplicate, {loading}] = useMutation(
    {
      // dynamically select type based on classification
      type: 'duplicate',
      resource: 'plaid_txn',
      payload: {plaidTxnId: plaidTxnId},
    },
    {
      onSuccess: () => {
        notify(`Duplicate txn created!`);
      },
      onFailure: (error) => {
        console.log(error);
        notify(
          `Failed to create duplicate: ${error.body['display_msg']}`,
          'warning',
        );
      },
    },
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    createDuplicate();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Duplicate" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Duplicate Expense"
        content="Are you sure you want to create a duplicate for this expense?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default CreateDuplicateButton;
