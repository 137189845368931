import {Button, Modal, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {getRelativeDate} from 'src/common/utils';

interface UpdateNoteOverlayProps {
  isVisible: boolean;
  handleClose: () => void;
  updateNote: (newNote: string) => void;
  value: string;
  timeStamp: number;
  whom: string;
}

const UpdateNoteOverlay = ({
  isVisible,
  handleClose,
  updateNote,
  value,
  timeStamp,
  whom,
}: UpdateNoteOverlayProps) => {
  const [text, setText] = useState(value ?? '');
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isVisible}
      onClose={handleClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 540,
          padding: 24,
          borderRadius: 16,
          backgroundColor: themmeColor.white,
        }}>
        <Typography
          style={{display: 'flex', justifyContent: 'space-between'}}
          fontSize={20}
          fontWeight={600}>
          Notes{' '}
          <Typography
            style={{marginLeft: '2px', alignSelf: 'center'}}
            color={themmeColor.silver}>
            Last updated: {getRelativeDate(timeStamp)} by {whom}
          </Typography>
        </Typography>
        <TextField
          onChange={(e) => setText(e.target.value)}
          value={text}
          multiline
          placeholder="Add note"
          rows={4}
          style={{marginTop: 12, marginBottom: 24}}
        />
        <Button variant={'contained'} onClick={() => updateNote(text)}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateNoteOverlay;
