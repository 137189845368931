import React from 'react';
import {Typography} from '@mui/material';
import Box from 'src/DesignSystem/Box/Box';
import {useDistributDeductions} from '../useDistributeDeductions';
import {DistributeDeductionCategoryGrouping} from 'src/store/distributeDeductions/distributeDeductions.reducer';
import CategoryGroupTable from './CategoryGroupTable/CategoryGroupTable';
import {themmeColor} from 'src/constants/constants';
import RightChveron from 'src/icons/RightChveron';
import {useDispatch} from 'react-redux';
import {WarningRounded} from '@mui/icons-material';
import {SEPERATE_OFFICE_ANSWER} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {setTaxFormDrawer} from 'src/store/app/app.actions';

const AllDistributeDeductionsTable = () => {
  const {
    groupOrder,
    categoryGroupHeader,
    categoryGroupWiseDeductionsSplit,
    onChangeSplitValue,
    seperateOffice,
  } = useDistributDeductions();
  const dispatch = useDispatch();

  return (
    <Box col style={{padding: 12, overflowY: 'auto', height: '75vh'}}>
      <Typography style={{fontWeight: 600}}>Deductions distributed</Typography>
      {seperateOffice === SEPERATE_OFFICE_ANSWER.no && (
        <div
          style={{
            background: '#FFFAED',
            borderRadius: 4,
            padding: '3px 8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 24,
          }}>
          <WarningRounded style={{color: themmeColor.flyfinYellow}} />
          <Typography style={{fontSize: 13, marginLeft: 4}}>
            User mentioned that they don’t have a separate office. Handle
            “Office deductions” through CPA queries
          </Typography>
        </div>
      )}
      <Box col style={{}}>
        {groupOrder.map((groupName) => {
          if (
            groupName !== DistributeDeductionCategoryGrouping.VEHICLE_DEDUCTION
          ) {
            return (
              <div
                key={`${groupName}-deduction-table`}
                style={{
                  borderRadius: 12,
                  overflow: 'hidden',
                  marginBlock: 16,
                }}>
                <CategoryGroupTable
                  categoryHeaderRowData={categoryGroupHeader[groupName]}
                  categoryWiseData={
                    categoryGroupWiseDeductionsSplit[groupName]
                  }
                  onChangeSplitHeader={({newValue, splitIndex}) =>
                    onChangeSplitValue({
                      groupName,
                      rowIndex: -1,
                      newValue,
                      splitIndex,
                    })
                  }
                  onChangeSplit={({newValue, splitIndex, rowIndex}) =>
                    onChangeSplitValue({
                      groupName,
                      rowIndex,
                      newValue,
                      splitIndex,
                    })
                  }
                />
              </div>
            );
          }
          return (
            // All Vehiicles
            categoryGroupWiseDeductionsSplit[groupName].length > 0 ? (
              <div
                style={{
                  backgroundColor: themmeColor.cpaCenterV2Bg,
                  paddingBlock: 12,
                  paddingInline: 8,
                  borderRadius: 12,
                }}>
                <Typography style={{fontWeight: 600, fontSize: 20}}>
                  Vehicle Deductions
                </Typography>
                <div style={{paddingInline: 12}}>
                  {categoryGroupWiseDeductionsSplit[groupName].map(
                    (vehicleInfo, vehicleIndex) => {
                      // Single Vehicle
                      return (
                        <div
                          style={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: themmeColor.silver,
                            overflow: 'hidden',
                            borderRadius: 12,
                            marginBottom: 8,
                          }}>
                          <CategoryGroupTable
                            categoryHeaderRowData={
                              categoryGroupHeader[groupName][vehicleIndex]
                                .category_wise_info
                            }
                            categoryWiseData={vehicleInfo.category_wise_info}
                            onChangeSplitHeader={({newValue, splitIndex}) =>
                              onChangeSplitValue({
                                groupName,
                                rowIndex: -1,
                                newValue,
                                splitIndex,
                                vehicleIndex,
                              })
                            }
                            onChangeSplit={({
                              newValue,
                              splitIndex,
                              rowIndex,
                            }) =>
                              onChangeSplitValue({
                                groupName,
                                rowIndex,
                                newValue,
                                splitIndex,
                                vehicleIndex,
                              })
                            }
                          />
                          <div
                            onClick={() =>
                              dispatch(
                                setTaxFormDrawer({
                                  isVisible: true,
                                  taxformId: vehicleInfo.vehicle_id,
                                }),
                              )
                            }
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingBlock: 12,
                            }}>
                            <Typography
                              style={{fontWeight: 600, marginLeft: 12}}>
                              View Vehicle {vehicleIndex + 1} details
                            </Typography>
                            <RightChveron />
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            ) : null
          );
        })}
      </Box>
    </Box>
  );
};

export default AllDistributeDeductionsTable;
