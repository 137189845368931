import React from 'react';
import {Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {themmeColor} from 'src/constants/constants';
import {setDistributeDeductionsQueryDrawer} from 'src/store/app/app.actions';
import {useDistributDeductions} from '../useDistributeDeductions';

const UserNotSubmittedView = () => {
  const dispatch = useDispatch();
  const {querySent} = useDistributDeductions();
  return (
    <Box
      col
      backgroundColor={themmeColor.white}
      style={{alignItems: 'center', justifyContent: 'center', height: 600}}>
      {querySent ? (
        <Typography style={{fontWeight: 600, marginBottom: 16}}>
          ✅ Query sent to user, User is yet to submit
        </Typography>
      ) : (
        <>
          <Typography style={{fontWeight: 600, marginBottom: 16}}>
            Raise the query for distributing deductions
          </Typography>
          <DSButton
            text="Ask Question"
            type="primary"
            onClick={() => dispatch(setDistributeDeductionsQueryDrawer(true))}
            style={{paddingBlock: 12, paddingInline: 128}}
          />
        </>
      )}
    </Box>
  );
};
export default UserNotSubmittedView;
