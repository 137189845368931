import React, {useContext, useEffect, useMemo, useState} from 'react';
import {VALID_DOCUMENT_STATUS, themmeColor} from 'src/constants/constants';
import {Typography} from '@mui/material';
import {getDocumentOcrData} from 'src/appApi';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {
  FormType,
  OCR_STATUS,
  backendFormsResponseMapper,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import {makeStyles} from '@mui/styles';
import ViewDocumentContext from '../../ViewDocument.context';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import AssignBusinessToDoc from '../AssignBusinessToDoc';
import LegacyRenderOcrData from './components/LegacyRenderOcrData';
import {FormTypesRenderOcrDataFromTaxForm} from 'src/store/taxForms/taxForms.constants';
import NewRenderOcrData from './components/NewRenderOcrData';
import AssignYearToBalanceSheet from './components/AssignYearToBalanceSheet';

const useStyles = makeStyles({
  header: {
    padding: '10px',
    background: ({status}) =>
      status === VALID_DOCUMENT_STATUS.ACCEPTED
        ? themmeColor.offWhite
        : themmeColor.successGreen,
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    height: '16px',
    color: themmeColor.grey,
    alignSelf: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  addForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  reviewComplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '10px 0px',
  },
});

interface RenderOcrDataProps {
  showAssignBusiness?: boolean;
  showAssignYearToBalanceSheet?: boolean;
}

const RenderOcrData = ({
  showAssignBusiness = false,
  showAssignYearToBalanceSheet = false,
}: RenderOcrDataProps) => {
  const {
    userId: flyfinUserId,
    document,
    documentsLoading,
  } = useContext(ViewDocumentContext);
  const [forms, setForms] = useState<{[id: number]: FormType}>({});
  const [isLoading, setLoading] = useState(true);
  const [ocrStatus, setOcrStatus] = useState<string>('');

  const [selectedBusiness, setSelectedBusiness] = useState(
    document?.linkedBusinessId,
  );

  useEffect(() => {
    setSelectedBusiness(document?.linkedBusinessId);
  }, [document?.linkedBusinessId]);

  const {notify} = useNotify();

  const getForms = async () => {
    try {
      setLoading(true);
      const res = await getDocumentOcrData({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
      });
      if (res.data.ocr_status === OCR_STATUS.DONE) {
        const formData = backendFormsResponseMapper(res.data.forms);
        setForms(formData);
      }
      setOcrStatus(res.data.ocr_status);
    } catch (e) {
      notify(`Failed to fetch OCR data ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!documentsLoading) {
      getForms();
    }
  }, [document, documentsLoading]);

  const styles = useStyles({status: document?.docStatus});

  const taxFormIds = useMemo(() => {
    return Object.keys(forms)
      .map((i) => parseInt(i))
      .sort((a, b) => a - b);
  }, [forms]);

  const formTypes = useMemo(() => {
    return Object.values(forms).map((form) => form.form_type);
  }, [forms]);

  const shouldUseNewRenderer = useMemo(
    () =>
      formTypes.some((formType) =>
        FormTypesRenderOcrDataFromTaxForm.includes(formType as any),
      ),
    [formTypes],
  );

  if (documentsLoading) {
    return <div></div>;
  }

  const isReviewComplete = [
    VALID_DOCUMENT_STATUS.ACCEPTED,
    VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA,
  ].includes(document.docStatus);

  return (
    <>
      {isLoading && <FullScreenLoading open={isLoading} />}
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={styles.content}>
          {isReviewComplete && (
            <div
              style={{
                backgroundColor: themmeColor.successGreen,
                padding: 16,
              }}>
              <Typography style={{fontSize: 20, fontWeight: 600}}>
                Review complete
              </Typography>
            </div>
          )}
          {showAssignBusiness && (
            <AssignBusinessToDoc
              docType={document.docType}
              selectedBusiness={selectedBusiness}
              setSelectedBusiness={setSelectedBusiness}
              setLoading={setLoading}
            />
          )}
          {showAssignYearToBalanceSheet && (
            <AssignYearToBalanceSheet
              taxFormIds={taxFormIds}
              setLoading={setLoading}
            />
          )}
          {shouldUseNewRenderer ? (
            <NewRenderOcrData taxFormIds={taxFormIds} />
          ) : (
            <LegacyRenderOcrData
              ocrStatus={ocrStatus}
              forms={forms}
              setForms={setForms}
              isLoading={isLoading}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RenderOcrData;
