import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';

interface ContentToggleSwitchProps {
  value: string;
  options: Array<string>;
  onClick: (newOption: string) => void;
  disabled?: boolean;
}

const ContentToggleSwitch = ({
  value,
  options,
  onClick,
  disabled,
}: ContentToggleSwitchProps) => {
  return (
    <div style={{display: 'flex', flex: 1}}>
      {options.map((option) => {
        const isSelected = value === option;
        return (
          <div
            onClick={() => {
              if (disabled) {
                return;
              }
              onClick(option);
            }}
            style={{
              display: 'flex',
              flex: 1,
              textAlign: 'center',
              justifyContent: 'center',
              backgroundColor: isSelected
                ? themmeColor.black
                : themmeColor.cpaCenterV2Bg,
              borderRadius: 4,
              paddingBlock: 4,
            }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 600,
                color: !isSelected
                  ? themmeColor.black
                  : themmeColor.cpaCenterV2Bg,
              }}>
              {option}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default ContentToggleSwitch;
