import {Typography} from '@mui/material';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  tableData: {
    userId: number;
    userName: string;
  }[];
  onCofirm: () => void;
}

const ConfirmFileExtensionPopUp = ({
  isOpen,
  onClose,
  tableData,
  loading,
  onCofirm,
}: Props) => {
  const tableKeys = [
    {
      getValue: (row, index) => {
        return row.userId;
      },
      cellStyle: {display: 'flex'},
      textStyle: {fontWeight: 600},
    },
    {
      getValue: (row, index) => {
        return row.userName;
      },
      cellStyle: {display: 'flex', justifyContent: 'flex-end'},
      textStyle: {fontWeight: 600},
    },
  ];
  return (
    <PopUp
      primaryButtonTitle="Yes, Change it"
      primaryButtonOnClick={onCofirm}
      primaryButtonDisabled={loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 540}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography
        style={{
          fontSize: 15,
          marginBottom: 4,
        }}>
        Please confirm the users you want to update extension status for
      </Typography>
      <KeyValueTable keys={tableKeys} data={tableData} />
    </PopUp>
  );
};

export default ConfirmFileExtensionPopUp;
