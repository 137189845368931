import React from 'react';
import {useDispatch} from 'react-redux';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import {switchActiveYear} from 'src/store/globals/globals.actions';
import {SUPPORTED_YEARS} from 'src/store/globals/globals.reducer';
import {useActiveYear} from '../hooks/useActiveYear';

const YearSwitchDropdown = () => {
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  return (
    <Dropdown
      options={SUPPORTED_YEARS}
      value={activeYear}
      placeholder="Active year"
      maxWidth
      onChange={(newVal) => dispatch(switchActiveYear({year: newVal}))}
    />
  );
};

export default YearSwitchDropdown;
