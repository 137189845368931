import React from 'react';
import {SET_HIGH_VALUE_DEDUCTIONS} from '../actionNames';
import {Transaction} from '../transactions/transactions.reducer';
import {ReduxActionType, REDUX_STATUS} from '../types';

const initialState = {
  deductions: [] as Transaction[],
  status: REDUX_STATUS.INIT,
  loaded: false,
};

export const highValueDeductions = (
  state = initialState,
  action: ReduxActionType,
) => {
  switch (action.type) {
    case SET_HIGH_VALUE_DEDUCTIONS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type HighValueDeductionStateType = typeof initialState;
