import {getBusinessDetails} from 'src/appApi';
import {SET_BUSINESS_DETAILS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {BusinessDetail} from './businessDetails.types';

const setBusinessDetails = (payload: any) => {
  return {
    type: SET_BUSINESS_DETAILS,
    payload,
  };
};

export const fetchBusinessDetails = (fly_user_id: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const {data} = await getBusinessDetails(fly_user_id, year);
      const businesses: BusinessDetail[] = data;
      dispatch(setBusinessDetails({businesses}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch business details (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
