import {ComponentProps} from 'react';
import {Form} from 'src/DesignSystem/Form/Form';
import {
  AccountingMethods,
  BusinessOwner,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';

export const BUSINESS_ENTITY_OPTIONS: ComponentProps<
  (typeof Form)['RadioField']
>['options'] = [
  {
    value: BusinessType.SOLE_PROPRIETORSHIP,
    label: 'Sole Propreitorship',
  },
  {
    value: BusinessType.PARTNERSHIP,
    label: 'Partnership',
  },
  {
    value: BusinessType.S_CORP,
    label: 'S Corp',
  },
  {
    value: BusinessType.C_CORP,
    label: 'C Corp',
  },
];

export const OWNER_OR_PROPRIETOR_OPTIONS: ComponentProps<
  (typeof Form)['RadioField']
>['options'] = [
  {
    value: BusinessOwner.SELF,
    label: 'Self',
  },
  {
    value: BusinessOwner.SPOUSE,
    label: 'Spouse',
  },
];

export const BUSINESS_ACCOUNTING_METHOD_OPTIONS: ComponentProps<
  (typeof Form)['RadioField']
>['options'] = [
  {
    value: AccountingMethods.CASH,
    label: AccountingMethods.CASH,
  },
  {
    value: AccountingMethods.ACCRUAL,
    label: AccountingMethods.ACCRUAL,
  },
  {
    value: AccountingMethods.OTHER,
    label: AccountingMethods.OTHER,
  },
];
