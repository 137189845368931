import {Box, Button, CircularProgress, IconButton, Modal, TextField, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {useEffect, useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import CloseIcon from '@mui/icons-material/Close';
import {getCpaConversationRating, patchConversationRating, postConversationRating} from 'src/appApi';

enum RatingFieldsKey {
  CORRECTNESS = 'correctness_rating',
  CLARITY = 'clarity_rating',
  GRAMMAR = 'grammar_rating',
  COMMENT = 'comment',
}

const ratingFields = [
  {
    id: RatingFieldsKey.CORRECTNESS,
    title: 'Is the answer correct',
    subTitle: 'Facts conveyed in the answer are correct.',
  },
  {
    id: RatingFieldsKey.GRAMMAR,
    title: 'Grammar',
    subTitle: 'Check for grammatical correctness.',
  },
  {
    id: RatingFieldsKey.CLARITY,
    title: 'Is the answer to the point',
    subTitle: 'The response was in lines with what user asked',
  },
];

const feedbackInit = {visible: false, feedback: 'hideFeedback'};

const RateConversationModal = ({closeModal, ratingId: _ratingId, open, queryId}) => {
  const [ratingId, setRatingId] = useState(_ratingId);
  const [rate, setRate] = useState({
    [RatingFieldsKey.CORRECTNESS]: null,
    [RatingFieldsKey.GRAMMAR]: null,
    [RatingFieldsKey.CLARITY]: null,
    [RatingFieldsKey.COMMENT]: '',
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showFeedback, setShowFeedback] = useState(feedbackInit);

  useEffect(() => {
    if (!ratingId) return;
    setFetchLoading(true);
    getCpaConversationRating(ratingId)
      .then((response) => {
        setRate(response.data);
      })
      .finally(() => setFetchLoading(false));
  }, [ratingId]);

  useEffect(() => {
    if (!open) {
      setShowFeedback(feedbackInit);
    }
  }, [open]);



  const isSubmitDisabled = rate[RatingFieldsKey.CORRECTNESS] === null ||
    rate[RatingFieldsKey.GRAMMAR] === null ||
    rate[RatingFieldsKey.CLARITY] === null;

  const submitRating = async () => {
    if (isSubmitDisabled) return;
    try {
      setSubmitLoading(true);
      const ratingData = {
        cpa_question_id: queryId,
        correctness_rating: rate[RatingFieldsKey.CORRECTNESS] as unknown as number,
        clarity_rating: rate[RatingFieldsKey.CLARITY] as unknown as number,
        grammar_rating: rate[RatingFieldsKey.GRAMMAR] as unknown as number,
        comment: rate[RatingFieldsKey.COMMENT],
      };
      if (!ratingId) {
        const response = await postConversationRating(ratingData);
        setRatingId(response.data);
        setShowFeedback({visible: true, feedback: 'Your rating has been submitted!'});
      } else {
        await patchConversationRating({ratingId, ...ratingData});
        setShowFeedback({visible: true, feedback: 'Your rating has been updated!'});
      }
      setSubmitLoading(false);
    } catch (e) {
      alert(`Something went wrong, please try again. \n\n${e}`);
    }
  };
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
      onClose={closeModal}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 24,
          borderRadius: 16,
          backgroundColor: themmeColor.white,
        }}>
        {showFeedback.visible ? (
          <Box p={3} display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} justifyContent={'center'}>
            <Typography fontWeight={'bold'}>{showFeedback.feedback}</Typography>
            <Button variant={'contained'} onClick={closeModal}>Ok</Button>
          </Box>
        ) : (
          <>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <IconButton style={{width: 20, height: 20}} onClick={closeModal}>
                <CloseIcon
                  style={{width: 18, height: 19, color: themmeColor.darkBlue}}
                />
              </IconButton>
            </div>
            <Typography fontSize={20} fontWeight={'bold'}>
              Rate the conversation
            </Typography>
            <Typography
              fontSize={14}
              color={themmeColor.grey}
              style={{marginBottom: 36}}>
              Review the conversation
            </Typography>
            {fetchLoading ? (
              <CircularProgress sx={{alignSelf: 'center'}} />
            ) : (
              <>
                {ratingFields.map((fields) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 16,
                      gap: 32,
                    }}
                    key={fields.id}>
                    <div>
                      <Typography fontSize={20} fontWeight={'bold'}>{fields.title}</Typography>
                      <Typography fontSize={16} maxWidth={'95%'} color={themmeColor.grey}>
                        {fields.subTitle}
                      </Typography>
                    </div>
                    <div>
                      <Stack direction='row' alignItems='center' spacing={1} component={'div'}>
                        {[1, 2, 3, 4, 5].map((point) => (
                          <Button
                            variant={
                              point <= rate[fields.id] ? 'contained' : 'outlined'
                            }
                            onClick={() =>
                              setRate((prev) => ({
                                ...prev,
                                [fields.id]: point,
                              }))
                            }
                            style={{minWidth: 12}}>
                            {point}
                          </Button>
                        ))}
                      </Stack>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: 2,
                        }}>
                        <Typography fontSize={12} color={themmeColor.grey}>
                          Not statisfied
                        </Typography>
                        <Typography fontSize={12} color={themmeColor.grey}>
                          Very statisfied
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
                <div>
                  <Typography mb={0.8} fontWeight={'bold'}>Comment</Typography>
                  <TextField
                    multiline
                    minRows={3}
                    maxRows={15}
                    style={{width: '100%'}}
                    value={rate[RatingFieldsKey.COMMENT]}
                    onChange={(e) =>
                      setRate((prev) => ({
                        ...prev,
                        [RatingFieldsKey.COMMENT]: e.target.value,
                      }))
                    }
                  />
                </div>
                <div
                  style={{display: 'flex', justifyContent: 'flex-end', marginTop: 36}}>
                  <Button
                    variant='contained'
                    onClick={submitRating}
                    style={{width: '40%'}}
                    disabled={isSubmitDisabled}
                    startIcon={submitLoading ? <CircularProgress size={16} /> : undefined}
                  >
                    Submit
                  </Button>
                </div>
              </>)}
          </>
        )}
      </div>
    </Modal>
  );
};

export default RateConversationModal;
