import {useRef, useState} from 'react';
import {
  createPlaidAccount,
  dummmyPlaidAccount,
  patchTaxFilingJourney,
  patchUserProfile,
  postLevel,
  postTaxProfile,
  saveBulkTaxProfile,
  sendOtp,
  verifyOtp,
} from './api';
import {
  APP_LEVELS,
  BULK_TAX_PROFILE_POST_DATA,
  MEANWHILE_QUESTIONS,
  getTaxProfileJourneyData,
  TAX_REMINDER_TAX_PROFILE,
} from './dummy.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

enum STATES {
  INIT = 'INIT',
  OTP_SENT = 'OTP_SENT',
  OTP_VERIFID = 'OTP_VERIFIED',
  CREATING_ACCOUNT = 'CREATING_ACCOUNT',
}

const useCreateDummyAccount = () => {
  const {activeYear} = useActiveYear();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [status, setStatus] = useState(STATES.INIT);
  const [skipPayment, setSkipPayment] = useState(false);
  const [skipTaxProfile, setSkipTaxProfile] = useState(false);

  const toggleSkipPayment = (checked: boolean) => setSkipPayment(checked);
  const toggleSkipTaxProfile = (checked: boolean) =>
    setSkipTaxProfile(checked);

  const userId = useRef(null);

  const [loading, setLoading] = useState(false);

  const resetToInitState = () => {
    setEmail('');
    setOtp('');
    setStatus(STATES.INIT);
    setLoading(false);
    localStorage.removeItem('appToken');
    userId.current = null;
    setSkipPayment(false);
    setSkipTaxProfile(false);
  };

  const sendOtpHandler = async () => {
    try {
      setLoading(true);
      await sendOtp(email);
      setLoading(false);
      setStatus(STATES.OTP_SENT);
    } catch (e) {
      alert(`Something went wrong ${e}`);
      resetToInitState();
    }
  };

  const verifyOtpHandler = async () => {
    try {
      setLoading(true);
      const response = await verifyOtp(email, otp);
      const {token, id} = response.data;
      userId.current = id;
      localStorage.setItem('appToken', token);
      setStatus(STATES.OTP_VERIFID);
      setLoading(false);
    } catch (e) {
      alert(`Something went wrong ${e}`);
      resetToInitState();
    }
  };

  const createAccount = async () => {
    const token = localStorage.getItem('appToken');
    if (!token) return;
    try {
      setLoading(true);
      setStatus(STATES.CREATING_ACCOUNT);
      await saveBulkTaxProfile(BULK_TAX_PROFILE_POST_DATA);
      await postLevel(APP_LEVELS.EnterName);
      await patchUserProfile(userId.current ?? '', 'RDB', 'Account');
      await postTaxProfile(TAX_REMINDER_TAX_PROFILE);
      await postLevel(APP_LEVELS.CardType);
      const {
        data: {new_accounts},
      } = await dummmyPlaidAccount();
      await createPlaidAccount({
        accounts: [
          {
            ...new_accounts?.[0],
            card_usage_type: 'PL_BU',
          },
        ],
      });
      await postLevel(APP_LEVELS.LinkMoreAccounts);
      await postLevel(APP_LEVELS.FetchingExpenses);
      await Promise.all(
        MEANWHILE_QUESTIONS.map((postBody) => postTaxProfile(postBody)),
      );
      await postLevel(APP_LEVELS.ClassificationComplete);
      if (skipPayment) {
        await postLevel(APP_LEVELS.tabBarScreens);
      }
      if (skipTaxProfile) {
        await patchTaxFilingJourney({app_year: activeYear});
        await patchTaxFilingJourney({
          app_year: activeYear,
          completed_return_status: 1,
          completed_return_sub_status: 1,
        });
        await saveBulkTaxProfile(getTaxProfileJourneyData(activeYear));
        await patchTaxFilingJourney({
          app_year: activeYear,
          completed_return_status: 3,
        });
      }
      setLoading(false);
      alert(`Success, Account Created for ${email}`);
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      resetToInitState();
    }
  };

  const getButtonCopy = () => {
    switch (status) {
      case STATES.INIT:
        return 'Send Otp';
      case STATES.OTP_SENT:
        return 'Verify Otp';
      case STATES.OTP_VERIFID:
        return 'Otp Verified';
      case STATES.CREATING_ACCOUNT:
        return 'Creating account';
    }
  };

  const onClickHandler = async () => {
    switch (status) {
      case STATES.INIT:
        await sendOtpHandler();
        break;
      case STATES.OTP_SENT:
        await verifyOtpHandler();
        await createAccount();
        break;
    }
  };

  const buttonDisabled =
    (status === STATES.INIT && email.length === 0) ||
    (status === STATES.OTP_SENT && otp.length !== 6) ||
    loading;

  const otpDisabled = loading || status !== STATES.OTP_SENT;

  const emailDisabled = loading || status !== STATES.INIT;

  return {
    getButtonCopy,
    email,
    setEmail,
    otp,
    setOtp,
    onClickHandler,
    buttonDisabled,
    otpDisabled,
    emailDisabled,
    toggleSkipPayment,
    skipPayment,
    skipTaxProfile,
    toggleSkipTaxProfile,
  };
};

export default useCreateDummyAccount;
