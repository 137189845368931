import React from 'react';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {GenericTaxForm, TaxForm} from 'src/store/taxForms/taxForms.types';
import {IncomeTaxFormConfig} from 'src/store/taxForms/taxForms.constants';
import NotesIcon from 'src/icons/NotesIcon';
import {usePartnerTaxProfileReview} from 'src/Partnership/PartnerReturnInfo/hooks/usePartnerTaxProfileReview';
import {usePartnershipNavigation} from 'src/CpaCenterV2/hooks/usePartnershipNavigation';
import {CurrencyNumberFormat, stringClipper} from 'src/common/utils';
import {isNumber} from 'lodash';
import {Edit} from '@mui/icons-material';
import {themmeColor} from 'src/constants/constants';

type T = keyof typeof IncomeTaxFormConfig;
interface PartnerIncomeTableProps {
  incomeTaxForms: TaxForm<T>[];
}

const PartnerIncomeTable = ({incomeTaxForms}: PartnerIncomeTableProps) => {
  const {onEditTaxForm} = usePartnerTaxProfileReview();
  const {navigateToDocumentReview} = usePartnershipNavigation();
  return (
    <KeyValueTable
      keys={[
        {
          getValue: (row) =>
            row.formData[IncomeTaxFormConfig[row.formType]?.incomeSourceKey],
        },
        {
          getValue: (row) =>
            CurrencyNumberFormat(
              IncomeTaxFormConfig[row.formType]?.getAmount?.(row),
            ),
          textStyle: {fontWeight: 600},
          cellStyle: {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
        {
          getValue: (row: GenericTaxForm) => {
            if (isNumber(row.docId)) {
              // navigate to doc review
              return (
                <div onClick={() => navigateToDocumentReview(row.docId)}>
                  <NotesIcon />
                </div>
              );
            } else {
              return (
                <div
                  onClick={() =>
                    onEditTaxForm({
                      taxFormId: row.taxFormId,
                      taxFormType: row.formType,
                      title: stringClipper(`Edit ${row.formType}`, 20),
                    })
                  }>
                  <Edit />
                </div>
              );
            }
          },
          cellStyle: {
            display: 'flex',
            justifyContent: 'center',
            maxWidth: 80,
          },
        },
        {
          getValue: (row) => IncomeTaxFormConfig[row.formType]?.incomeName,
          cellStyle: {
            backgroundColor: themmeColor.white,
            borderRadius: 8,
            paddingInline: 12,
            paddingBlock: 8,
            border: `solid 1px ${themmeColor.offWhite}`,
          },
        },
      ]}
      data={incomeTaxForms}
    />
  );
};
export default PartnerIncomeTable;
