import React from 'react';
import SimpleLabel from '../CellsInternal/SimpleLabel';
import Cell from './Cell';
import {Typography} from '@mui/material';

interface TotalCellProps {
  backgroundColor?: string;
  cellStyle?: React.CSSProperties;
  value: string;
  subvalue?: string;
  textStyle?: React.CSSProperties;
  subValueStyle?: React.CSSProperties;
}

const TotalCell = ({
  value,
  subvalue,
  textStyle = {},
  subValueStyle = {},
  backgroundColor,
  cellStyle = {},
}: TotalCellProps) => {
  return (
    <Cell backgroundColor={backgroundColor} cellStyle={cellStyle}>
      <SimpleLabel
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: !subvalue ? '100%' : undefined,
        }}
        textStyle={textStyle}
        value={value}
      />
      {subvalue && (
        <Typography style={{fontSize: 13, ...subValueStyle}}>
          {subvalue}
        </Typography>
      )}
    </Cell>
  );
};

export default TotalCell;
