import {TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface UpdateCCHClientIdProps {
  isOpen: boolean;
  onClose: () => void;
  cchClientId: number;
  loading: boolean;
  onUpdate: (newValue: number) => void;
}

const UpdateCCHClientId = ({
  isOpen,
  onClose,
  cchClientId,
  loading,
  onUpdate,
}: UpdateCCHClientIdProps) => {
  const [clientId, setClientId] = useState(cchClientId);
  return (
    <PopUp
      primaryButtonTitle="Yes, Change it"
      primaryButtonOnClick={() => onUpdate(clientId)}
      primaryButtonDisabled={cchClientId === clientId || loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 400}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography fontSize={20} fontWeight="600">
        Change CCH Client Id
      </Typography>
      <TextField
        fullWidth
        label="CCH Client ID"
        style={{marginTop: 16}}
        value={clientId}
        type="number"
        onChange={(e) => setClientId(parseInt(e.target.value))}
        disabled={loading}
      />
    </PopUp>
  );
};

export default UpdateCCHClientId;
