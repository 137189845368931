import {FIELD_ID} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {ValueField} from 'src/DesignSystem/OCR/OCR.types';

export enum ReturnFields {
  partner = 'partner',
  years = 'years',
  should_file_individual_return = 'should_file_individual_return',
  businesses = 'businesses',
  should_file_return = 'should_file_return',
  unique_key = 'unique_key',

  has_spouse = 'has_spouse',
  spouse_id = 'spouse_id',
  users = 'users',
  id = 'id',
  other_fields_location = 'other_fields_location',
  biz_fields_location = 'biz_fields_location',
  read_url = 'read_url',
  ocr_job_id = 'ocr_job_id',
  ocr_job_ids = 'ocr_job_ids',
  filename = 'filename',
  extra_data = 'extra_data',
  sole_prop_businesses = 'sole_prop_businesses',
  tax_profile_questions = 'tax_profile_questions',
  documents = 'documents',

  belongs_to_spouse = 'belongs_to_spouse',
  personal_details = 'personal_details',

  document_assignment_status = 'document_assignment_status',
}

export enum CREATE_RETURN_STATUS {
  INIT = 'INIT',
  UPLOADING_DOCUMENT = 'UPLOADING_DOCUMENT',
  OCR_IN_PROGRESS = 'OCR_IN_PROGRESS',
  CONFIRM_USER_RELATION = 'CONFIRM_USER_RELATION',
  CREATE_RETURNS_FROM_DOC = 'CREATE_RETURNS_FROM_DOC',
  RETURN_CREATION_IN_PROGRESS = 'RETURN_CREATION_IN_PROGRESS',
  ASSIGN_DOCUMENTS = 'ASSIGN_DOCUMENTS',
  ASSIGNING_DOCUMENTS = 'ASSIGNING_DOCUMENTS',

  CREATE_MANUAL_RETURN = 'CREATE_MANUAL_RETURN',
  MANUAL_RETURN_IN_PROGRESS = 'MANUAL_RETURN_IN_PROGRESS',

  RETURNS_CREATED = 'RETURNS_CREATED',
}

export interface UserRelationType {
  [FIELD_ID.FILING_STATUS]: FILING_STATUS_ANSWER;
  [FIELD_ID.FIRST_NAME]: string;
  [FIELD_ID.LAST_NAME]: string;
  [FIELD_ID.SPOUSE_SSN]: any;
  [FIELD_ID.SSN]: string;
  [ReturnFields.businesses]: {
    [ReturnFields.id]: string;
    [ReturnFields.unique_key]: string;
    [BusinessDetailsField.name]: string;
    [BusinessDetailsField.ein]: string;
    [BusinessDetailsField.entity_type]: string;
    [ReturnFields.should_file_return]: boolean;
    [ReturnFields.extra_data]: any;
  }[];
  [ReturnFields.sole_prop_businesses]: any;
  [ReturnFields.has_spouse]: boolean;
  [ReturnFields.should_file_individual_return]: boolean;
  [ReturnFields.spouse_id]: number;
  [ReturnFields.id]: number;
  [ReturnFields.other_fields_location]: {
    [key: string]: ValueField;
  };
  [ReturnFields.biz_fields_location]: {
    [bizId: string]: {[bizField: string]: ValueField};
  };
  [ReturnFields.read_url]: string;
}

export enum DocumentAssignmentStatus {
  PENDING = 'PENDING',
  REVIEW_PENDING = 'REVIEW_PENDING',
  ASSIGNED = 'ASSIGNED',
  REVIEWED = 'REVIEWED',
}
