import { Typography } from "@mui/material";

const FractionCell = ({isActionable = false, num, den}: {isActionable?: boolean, num: number, den: number}) => {
    return (
      <div style={{
        width: '30px',
        height: '19px',
        borderRadius: '20px',
        backgroundColor: `${isActionable ? '#D44444': '#555555'}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography style={{
          color: 'white',
          fontSize: '13px',
          margin: 0,
          fontWeight: 600,
        }}>
          {num}/{den}
        </Typography>
      </div>
    );
  }
  
export default FractionCell;
