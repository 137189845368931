import {
  DeleteOutline,
  ExpandMoreOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import React, {useMemo, useRef, useState} from 'react';
import {FormType} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import {themmeColor} from 'src/constants/constants';
import FormField from './FormField';
import useOnScreen from 'src/CpaCenterV2/hooks/useOnScreen';
import TickIcon from 'src/icons/TickIcon';
import StatusPendingIcon from 'src/icons/StatusPendingIcon';

interface FormProps {
  form: FormType;
  isImage: boolean;
  setForms: any;
  setLoading: any;
  handleDelete: (formId: number) => Promise<void>;
  handleFormValueChange: (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => void;
  isSeen: boolean;
  index: number;
  updateSeenForms: (formId: number) => void;
  isReviewComplete: boolean;
}

const Form: React.FC<FormProps> = ({
  form,
  isImage,
  setForms,
  setLoading,
  handleDelete,
  handleFormValueChange,
  isSeen,
  index,
  updateSeenForms,
  isReviewComplete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expandAccordion, setExpandAccordion] = useState(index === 0);
  const isOpen = Boolean(anchorEl);

  const scrollEndRef = useRef(null);
  const isVisible = useOnScreen(scrollEndRef);

  useMemo(() => {
    if (isVisible && expandAccordion) {
      updateSeenForms(form.id);
    }
  }, [isVisible, expandAccordion, form.id]);

  const toggleAcordion = () => {
    setExpandAccordion((prev) => !prev);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setLoading(true);
    await handleDelete(form.id);
    setLoading(false);
  };

  return (
    <Accordion expanded={expandAccordion}>
      <AccordionSummary
        onClick={(e) => toggleAcordion()}
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel1a-content">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography fontWeight={700} fontSize={16}>
            {`Document (${form.form_type})`}
          </Typography>
          <IconButton
            aria-label="more"
            aria-controls={isOpen ? 'long-menu' : undefined}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertOutlined />
          </IconButton>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}>
            <div
              style={{display: 'flex', padding: '5px'}}
              onClick={onDeleteClick}>
              <DeleteOutline style={{color: themmeColor.errorRed}} />
              <Typography>Delete</Typography>
            </div>
          </Menu>
          {isSeen || isReviewComplete ? <TickIcon /> : <StatusPendingIcon />}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {Object.keys(form.form_data).map((fieldKey) => {
            return (
              <FormField
                isImage={isImage}
                fieldKey={fieldKey}
                field={form.form_data[fieldKey]}
                key={`${form.id}-${fieldKey}`}
                page={form.page_number}
                formId={form.id}
                setForms={setForms}
                handleFormValueChange={handleFormValueChange}
                formType={form.form_type}
                formData={form.form_data}
              />
            );
          })}
          <div
            ref={scrollEndRef}
            style={{height: 2, backgroundColor: 'red', width: '100%'}}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Form;
