import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import KeyValueTable from 'src/CpaCenterV2/common/KeyValueTable/KeyValueTable';
import {useIPPinDetails} from 'src/CpaCenterV2/hooks/useIPPinDetails';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import CrossIcon from 'src/icons/CrossIcon';
import {setIpPinDetailsDrawer} from 'src/store/app/app.actions';
import {selectIpPinDetailsDrawer} from 'src/store/app/app.selector';

const IPPinDetailsDrawer = () => {
  const isVisible = useSelector(selectIpPinDetailsDrawer);
  const dispatch = useDispatch();

  const {hasSubmittedIpPins, ipPins} = useIPPinDetails();

  if (!hasSubmittedIpPins) {
    return null;
  }

  const hasAnyIpPin = ipPins.length > 0;

  const onClose = () => {
    dispatch(setIpPinDetailsDrawer(false));
  };

  return (
    <Drawer
      backgroundColor={'white'}
      direction="left"
      width={400}
      height={'85vh'}
      isOpen={isVisible}>
      <div style={{paddingInline: 16, paddingBlock: 20}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}>
          <Typography style={{fontSize: 20, fontWeight: 600}}>
            IP PIN Details
          </Typography>
          <div onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
        {hasAnyIpPin ? (
          <>
            <KeyValueTable
              data={ipPins}
              keys={[
                {
                  getValue: (row) => row.name,
                },
                {
                  getValue: (row) => row.pin,
                  cellStyle: {
                    justifyContent: 'flex-end',
                    display: 'flex',
                  },
                  textStyle: {fontWeight: 600},
                },
              ]}
            />
          </>
        ) : (
          <Typography>User said he doesn't have IP PIN</Typography>
        )}
      </div>
    </Drawer>
  );
};
export default IPPinDetailsDrawer;
