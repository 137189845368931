import React from 'react';
import {
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  SelectArrayInput,
  useRefresh,
  useNotify,
  useQuery,
  Loading,
  useGetList,
  useGetOne,
} from 'react-admin';
import {
  TaxProfileQuestionTypes,
  EstimatedTaxPaymentChoices,
  TaxProfileQuestions,
  USAStates,
  IncomeScourcesChoices,
  RetirementPlanContriChoices,
  ItemizeDeductionChoices,
  StandardDeductionChoices,
} from '../constants/constants';
import {makeStyles} from '@material-ui/core/styles';

import {Typography} from '@material-ui/core';

const useStyles = makeStyles({
  MlabelInput: {
    width: '21em',
  },
  LlabelInput: {
    width: '23em',
  },
  XLlabelInput: {
    width: '25em',
  },
  XXLlabelInput: {
    width: '27em',
  },
});

const OnboardingQuestions = ({fly_user_id}) => {
  const {data: onb_data, loading: onb_loading} = useGetList(
    'onb_questions',
    {page: 1, perPage: 50},
    {field: 'question_id', order: 'ASC'},
    {fly_user_id},
  );

  const {data: userData, loading: isUserLoading} = useGetOne(
    'users',
    fly_user_id,
  );

  const keys = Object.keys(onb_data ?? {});

  return (
    <div>
      <Typography>Username: {userData?.id}</Typography>
      <Typography>Flyfin User ID: {userData?.name}</Typography>
      <Typography>Email: {userData?.email}</Typography>
      <ul>
        {keys.map((k) => {
          return (
            <li>
              <Typography>
                {JSON.stringify(onb_data[k].answer).slice(0, 50)}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const TaxProfileEdit = (props) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = () => {
    notify(`Changes saved`);
    refresh();
  };

  const {data: tax_filing_data, loading: tax_loading} = useQuery({
    type: 'getOne',
    resource: 'tax_filing_status',
    payload: {id: props.id},
  });

  return (
    <Edit
      {...props}
      aside={
        <div>
          {tax_loading ? (
            <Loading />
          ) : (
            <OnboardingQuestions fly_user_id={tax_filing_data.fly_user_id} />
          )}
        </div>
      }
      title="Tax Profile"
      mutationMode="pessimistic"
      onSuccess={onSuccess}>
      <TabbedForm>
        <FormTab label="Personal Details">
          <ArrayInput
            source={TaxProfileQuestionTypes.LEGAL_NAME}
            label={TaxProfileQuestions.LEGAL_NAME}>
            <SimpleFormIterator {...props} disableRemove>
              <TextInput
                source="first_name_and_middle_initial"
                label="First Name & Middle Initial"
              />
              <TextInput source="last_name" label="Last Name" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput
            source={TaxProfileQuestionTypes.SSN}
            label={TaxProfileQuestions.SSN}
          />
          <ArrayInput
            source={TaxProfileQuestionTypes.HOME_ADDRESS}
            label={TaxProfileQuestions.HOME_ADDRESS}>
            <SimpleFormIterator {...props} disableRemove>
              <TextInput source="number_and_street" label="Number & Street" />
              <TextInput source="apartment_number" label="Apartment Number" />
              <TextInput source="state" label="State" />
              <NumberInput source="zip_code" label="Zipcode" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput
            source={TaxProfileQuestionTypes.CLAIM_YOU_DEPENDENT}
            label={TaxProfileQuestions.CLAIM_YOU_DEPENDENT}
            className={classes.XXLlabelInput}
          />
          <TextInput
            source={TaxProfileQuestionTypes.FILING_STATUS}
            label={TaxProfileQuestions.FILING_STATUS}
            className={classes.XXLlabelInput}
          />
        </FormTab>
        <FormTab label="Spouse & Dependent">
          <ArrayInput
            source={TaxProfileQuestionTypes.SPOUSE_LEGAL_NAME}
            label={TaxProfileQuestions.SPOUSE_LEGAL_NAME}>
            <SimpleFormIterator {...props} disableRemove>
              <TextInput
                source="first_name_and_middle_initial"
                label="First Name & Middle Initial"
              />
              <TextInput source="last_name" label="Last Name" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput
            source={TaxProfileQuestionTypes.SPOUSE_SSN}
            label={TaxProfileQuestions.SPOUSE_SSN}
            className={classes.XXLlabelInput}
          />
          <TextInput
            source={TaxProfileQuestionTypes.SPOUSE_CLAIMED_DEPENDENT}
            label={TaxProfileQuestions.SPOUSE_CLAIMED_DEPENDENT}
            className={classes.XXLlabelInput}
          />
          <ArrayInput
            source={TaxProfileQuestionTypes.DEPENDENT_DETAILS}
            label={TaxProfileQuestions.DEPENDENT_DETAILS}>
            <SimpleFormIterator {...props} disableRemove>
              <NumberInput
                source="no_of_dependents"
                label="No. of Dependents"
              />
              <ArrayInput source="dependent_details" label="">
                <SimpleFormIterator {...props}>
                  <TextInput
                    source="dependent_first_name"
                    label="First Name"
                  />
                  <TextInput source="dependent_last_name" label="Last Name" />
                  <TextInput
                    source="dependent_social_security_number"
                    label="SSN"
                  />
                  <TextInput
                    source="relationship_to_you"
                    label="Relation to you"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Income Sources">
          <SelectArrayInput
            source={TaxProfileQuestionTypes.EARNING_STATES}
            label={TaxProfileQuestions.EARNING_STATES}
            choices={USAStates}
            className={classes.XXLlabelInput}
          />
          <SelectArrayInput
            source={TaxProfileQuestionTypes.INCOME_SOURCES}
            label={TaxProfileQuestions.INCOME_SOURCES}
            choices={IncomeScourcesChoices}
            className={classes.XXLlabelInput}
          />
          <SelectArrayInput
            source={TaxProfileQuestionTypes.YOUR_N_SPOUSE_INCOME_SOURCES}
            label={TaxProfileQuestions.YOUR_N_SPOUSE_INCOME_SOURCES}
            choices={IncomeScourcesChoices}
            className={classes.XXLlabelInput}
          />
          <NumberInput
            source={TaxProfileQuestionTypes.FREELANCE_EARNINGS}
            label={TaxProfileQuestions.FREELANCE_EARNINGS}
            className={classes.XXLlabelInput}
          />
        </FormTab>

        <FormTab label="Tax Savings">
          <SelectArrayInput
            source={TaxProfileQuestionTypes.RETIREMENT_PLAN_CONTRIBUTIONS}
            label={TaxProfileQuestions.RETIREMENT_PLAN_CONTRIBUTIONS}
            choices={RetirementPlanContriChoices}
            className={classes.XXLlabelInput}
          />
          <TextInput
            source={TaxProfileQuestionTypes.DEDUCTION_OPTION}
            label={TaxProfileQuestions.DEDUCTION_OPTION}
            className={classes.XXLlabelInput}
          />
          <SelectArrayInput
            source={TaxProfileQuestionTypes.ITEMIZE_DEDUCTION}
            label={TaxProfileQuestions.ITEMIZE_DEDUCTION}
            choices={ItemizeDeductionChoices}
            className={classes.XXLlabelInput}
          />
          <SelectArrayInput
            source={TaxProfileQuestionTypes.STANDARD_DEDUCTION}
            label={TaxProfileQuestions.STANDARD_DEDUCTION}
            choices={StandardDeductionChoices}
            className={classes.XXLlabelInput}
          />
          <SelectArrayInput
            source={TaxProfileQuestionTypes.ESTIMATED_TAX_PAYMENTS}
            label={TaxProfileQuestions.ESTIMATED_TAX_PAYMENTS}
            choices={EstimatedTaxPaymentChoices}
            className={classes.XXLlabelInput}
          />
          <TextInput
            source={TaxProfileQuestionTypes.RECEIVED_ADV_CHILD_TAX_CREDITS}
            label={TaxProfileQuestions.RECEIVED_ADV_CHILD_TAX_CREDITS}
            className={classes.XXLlabelInput}
          />
          <ArrayInput
            source={TaxProfileQuestionTypes.BUSINESS_DETAILS}
            label={TaxProfileQuestions.BUSINESS_DETAILS}
            className={classes.XXLlabelInput}>
            <SimpleFormIterator {...props} disableRemove>
              <TextInput source="business_name" label="Name" />
              <BooleanInput
                source="is_your_business_registered"
                label="Is Registered?"
              />
              <TextInput source="EIN" label="EIN" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default TaxProfileEdit;
