import {themmeColor} from 'src/constants/constants';
import ReviewAndEsignNotes from './ReviewAndEsignNotes/ReviewAndEsignNotes';
import {Button, Typography} from '@mui/material';
import {FileUploader} from 'react-drag-drop-files';
import {RefreshOutlined} from '@mui/icons-material';
import ReviewAndEsignContext, {
  ReviewEsignDocumentType,
} from '../ReviewAndEsignContext';
import {useContext, useRef, useState} from 'react';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {makeStyles} from '@mui/styles';
import EnterSignNowIdOverlay from './EnterSignNowIdOverlay';
import {sendDocForReviewEsign} from 'src/appApi';
import CpaReviewDocUploadOverlay from './CpaReviewDocUploadOverlay';
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {ESIGN_FIELD_TYPE} from '../ReviewAndEsign.utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '92%',
    display: 'flex',
    overflow: 'hidden',
  },
  documentView: {
    width: '65vw',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  documentViewHeader: {
    backgroundColor: themmeColor.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    border: '1px solid black',
    borderRight: '0px',
  },
  documentViewContent: {
    background: themmeColor.backgroundOffWhite,
    overflowX: 'scroll',
    padding: '10px',
    height: '100%',
    width: '100%',
  },
  documentPage: {
    display: 'flex',
    margin: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notesView: {width: '35vw', height: '100%', overflow: 'hidden'},
  reUploadButton: {
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    position: 'relative',
    height: '8%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    background: themmeColor.backgroundOffWhite,
    borderTop: '1px solid black',
  },
});

const RECpaReview = () => {
  const {activeYear} = useActiveYear();
  const fileType = ['pdf'];
  const {
    handleDocReUpload,
    documents,
    fetchDocsData,
    fetchUserDetails,
    flyfin_user_id,
  } = useContext(ReviewAndEsignContext);

  const [enterSignNowOverlay, setEnterSignNowOverlay] = useState(false);
  const [reUploadDocOverlay, setReUploadDocOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAllResolved, setIsAllResolved] = useState(false);
  const [sendForReviewEsign, setSendForReviewEsign] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const reviewDoc = documents.filter(
    (document) =>
      document.documentType === ReviewEsignDocumentType.TAX_RETURNS,
  )[0];
  const canvasPDFRef = useRef({});

  const openSignNowOverlay = () => {
    setEnterSignNowOverlay(true);
  };

  const openReUploadDocOverlay = () => {
    setReUploadDocOverlay(true);
  };

  const reUploadDoc = async (file: any) => {
    setLoading(true);
    await handleDocReUpload(file, reviewDoc.documentId);
    setLoading(false);
  };

  const sendDocumentToUser = async (
    isManual: boolean,
    signowId: string = '',
  ) => {
    try {
      setLoading(true);
      if (isManual) {
        if (!signowId) {
          alert('Signow Id is required');
          return;
        }
        const body = {
          user: flyfin_user_id ?? '',
          year: activeYear,
          filename: reviewDoc.filename,
          jurisdiction: reviewDoc.extras.jurisdiction,
          sign_now_document_id: signowId,
          s3_url: reviewDoc.documentURI,
        };
        await sendDocForReviewEsign(body);
      } else {
        const body = {
          user: flyfin_user_id ?? '',
          year: activeYear,
          filename: reviewDoc.filename,
          jurisdiction: reviewDoc.extras.jurisdiction,
          esign_fields: reviewDoc.esignFields,
          s3_url: reviewDoc.documentURI,
        };
        await sendDocForReviewEsign(body);
      }
      await fetchDocsData();
      await fetchUserDetails();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
      setEnterSignNowOverlay(false);
    }
  };

  const drawBoundingBox = () => {
    if (reviewDoc.esignFields) {
      reviewDoc.esignFields.forEach((BBData) => {
        if (BBData.applicable) {
          const canvas = canvasPDFRef.current[BBData.page_number + 1];
          const canvasContext = canvas?.getContext('2d');
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          canvasContext.strokeStyle =
            BBData.field_type === ESIGN_FIELD_TYPE.DATE ? 'blue' : 'red';
          canvasContext.lineWidth = 5;
          canvasContext?.strokeRect(
            BBData.x * canvasWidth,
            BBData.y * canvasHeight,
            BBData.width * canvasWidth,
            BBData.height * canvasHeight,
          );
        }
      });
    }
    setSendForReviewEsign(true);
  };

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const styles = useStyles({windowWidth: window.outerWidth});

  if (!reviewDoc) return null;

  return loading ? (
    <FullScreenLoading open={loading} />
  ) : (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
      <div className={styles.container}>
        <div className={styles.documentView}>
          <div className={styles.documentViewHeader}>
            <Typography
              fontWeight={'600'}
              fontSize={'16px'}
              fontStyle={'Inter'}>
              {reviewDoc.filename}
            </Typography>
            <div style={{display: 'flex'}}>
              <div>
                <FileUploader
                  multiple={false}
                  dropMessageStyle={{backgroundColor: '#00000000'}}
                  hoverTitle={
                    <h2 color={themmeColor.white}>Drop to upload the file</h2>
                  }
                  types={fileType}
                  handleChange={reUploadDoc}>
                  <div className={styles.reUploadButton}>
                    <RefreshOutlined
                      style={{
                        color: themmeColor.darkBlue,
                        marginRight: '10px',
                      }}
                    />
                    <Typography
                      fontWeight={'500'}
                      fontSize={'16px'}
                      fontStyle={'Inter'}
                      color={themmeColor.darkBlue}>
                      Re-upload
                    </Typography>
                  </div>
                </FileUploader>
              </div>
            </div>
          </div>
          <div className={styles.documentViewContent}>
            <Document
              file={reviewDoc.documentURI}
              onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <div className={styles.documentPage}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    canvasRef={(ref) => {
                      canvasPDFRef.current[index + 1] = ref;
                    }}
                    scale={1.2}
                  />
                </div>
              ))}
            </Document>
          </div>
        </div>
        <div className={styles.notesView}>
          <ReviewAndEsignNotes
            setIsAllResolved={setIsAllResolved}
            doc={reviewDoc}
            setReUploadDocOverlay={setReUploadDocOverlay}
          />
        </div>
      </div>
      <div className={styles.footer}>
        {isAllResolved && (
          <div style={{marginRight: '20px', display: 'flex'}}>
            {sendForReviewEsign ? (
              <>
                <div>
                  <Button
                    style={{
                      borderRadius: 8,
                      marginRight: '10px',
                      textTransform: 'none',
                    }}
                    onClick={openSignNowOverlay}
                    variant="outlined"
                    disabled={loading}>
                    Send Manually
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      borderRadius: 8,
                      textTransform: 'none',
                    }}
                    onClick={() => sendDocumentToUser(false)}
                    disabled={loading}
                    variant="contained">
                    Send for Review and e-sign
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Button
                    style={{
                      borderRadius: 8,
                      marginRight: '10px',
                      textTransform: 'none',
                    }}
                    onClick={drawBoundingBox}
                    variant="outlined"
                    disabled={loading}>
                    Prepare for Review and e-sign
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      borderRadius: 8,
                      textTransform: 'none',
                    }}
                    onClick={openReUploadDocOverlay}
                    variant="contained"
                    disabled={loading}>
                    Re-Upload Document
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <EnterSignNowIdOverlay
        isOpen={enterSignNowOverlay}
        onClose={() => setEnterSignNowOverlay(false)}
        onSend={(signowId) => sendDocumentToUser(true, signowId)}
        loading={loading}
      />
      <CpaReviewDocUploadOverlay
        isOpen={reUploadDocOverlay}
        onClose={() => setReUploadDocOverlay(false)}
        handleReUpload={(file) => reUploadDoc(file)}
        loading={loading}
      />
    </div>
  );
};

export default RECpaReview;
