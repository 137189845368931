import {TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {getRelativeDate} from 'src/common/utils';
import {themmeColor} from 'src/constants/constants';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface NotesPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  notes: string;
  onUpdate: (newValue: string) => void;
  loading?: boolean;
}

const NotesPopUp = ({
  onClose,
  isOpen,
  notes,
  onUpdate,
  loading,
}: NotesPopUpProps) => {
  const [text, setText] = useState(notes);
  return (
    <PopUp
      primaryButtonTitle="Save"
      primaryButtonOnClick={() => onUpdate(text)}
      primaryButtonDisabled={notes === text || loading}
      secondaryButtonTitle='Cancel'
      secondaryButtonDisabled={loading}
      secondaryButtonOnClick={onClose}
      style={{width: 540}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography
        style={{display: 'flex', justifyContent: 'space-between'}}
        fontSize={20}
        fontWeight={600}>
        Notes
      </Typography>
      <TextField
        onChange={(e) => setText(e.target.value)}
        value={text}
        multiline
        placeholder="Add note"
        rows={4}
        style={{marginTop: 12, marginBottom: 24}}
        fullWidth
      />
    </PopUp>
  );
};

export default NotesPopUp;
