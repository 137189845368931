import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Tag from 'src/DesignSystem/Tag/Tag';
import HollowTick from 'src/icons/HollowTick';
import TickIcon from 'src/icons/TickIcon';
import WriteComment from './WriteComment';
import {
  Thread as ReduxThread,
  ThreadStatus,
} from 'src/store/threads/threads.reducer';
import {DashedLine} from './DashedLine';
import {ThreadMessage} from './ThreadMessage';
import {useThreadsAction} from 'src/CpaCenterV2/hooks/useThreadsAction';
import {NotificationType} from 'src/store/app/app.reducer';
import useNotify from 'src/DesignSystem/Notify/useNotify';

interface ThreadProp {
  isCollapsed: boolean;
  thread: ReduxThread;
  onClick: (thread: ReduxThread) => void;
}

const Thread = ({isCollapsed, thread, onClick}: ThreadProp) => {
  const isResolved = thread.status === ThreadStatus.RESOLVED;
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const {postMessageInThread, updateThreadStatus} = useThreadsAction();
  const {notify} = useNotify();

  const onThreadStatusChange = async () => {
    try {
      setIsSendingMessage(true);
      await updateThreadStatus(
        thread.id,
        isResolved ? ThreadStatus.OPEN : ThreadStatus.RESOLVED,
      );
      notify(
        `Updated thread status to ${
          isResolved ? ThreadStatus.OPEN : ThreadStatus.RESOLVED
        }`,
        NotificationType.success,
      );
    } catch (e) {
      notify(`Failed to update thread status ${e}`, NotificationType.error);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const onAddComment = async (text: string) => {
    try {
      setIsSendingMessage(true);
      await postMessageInThread(thread.id, text);
    } catch (e) {
      notify(`Failed to post message in thread ${e}`, NotificationType.error);
    } finally {
      setIsSendingMessage(false);
    }
  };
  return (
    <div
      style={{
        padding: 12,
        borderRadius: 12,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isResolved
          ? themmeColor.successGreen
          : themmeColor.flyfinYellow,
        backgroundColor: isResolved ? '#F0FFF9' : '#FFF4D7',
        marginBottom: 8,
        marginTop: 12,
      }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Tag
          backgroundColor={
            isResolved ? themmeColor.darkGreen : themmeColor.flyfinYellow
          }
          textColor={themmeColor.white}
          text={thread.status}
        />
        <div style={{display: 'flex'}}>
          {thread.unread_messages.length > 0 && (
            <Tag
              backgroundColor={themmeColor.black}
              textColor={themmeColor.white}
              text={`${thread.unread_messages.length} replies`}
            />
          )}
          <div onClick={onThreadStatusChange} style={{marginLeft: 8}}>
            {!isResolved ? <HollowTick /> : <TickIcon />}
          </div>
        </div>
      </div>
      <div onClick={() => onClick(thread)} style={{marginTop: 8}}>
        {isCollapsed ? (
          <ThreadMessage message={thread.messages[0]} />
        ) : (
          <div>
            {thread.messages.map((message, index) => {
              const isLast = index === thread.messages.length - 1;
              return (
                <div style={{paddingBottom: 16, display: 'flex'}}>
                  <DashedLine isLast={isLast} />
                  <ThreadMessage message={message} />
                </div>
              );
            })}
          </div>
        )}
        {!isCollapsed && !isResolved && (
          <WriteComment disabled={isSendingMessage} onSend={onAddComment} />
        )}
        {!isCollapsed && isResolved && (
          <Box
            style={{
              paddingBlock: 12,
              paddingInline: 16,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            borderColor={themmeColor.offWhite}
            backgroundColor={themmeColor.cpaCenterV2Bg}>
            <Typography>
              This conversation was resolved by{' '}
              <span style={{fontWeight: 600}}>{thread.resolved_by_name}</span>
            </Typography>
            <DSButton
              style={{minWidth: 100}}
              type="secondary"
              onClick={onThreadStatusChange}
              text="Re-open"
              disabled={isSendingMessage}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Thread;
