import {BlankChoice} from '../constants/constants';

/**
 * Returns array of expense categories
 *
 * @param {object} categoryMap - {category -> list of sub categories}
 *
 * @returns {array} - array of {id, name} objects
 */
export const getAllCategories = (categoryMap) => {
  var choices = [];
  for (const cat in categoryMap) {
    choices.push({id: cat, name: cat});
  }
  return choices;
};

const getMealsCategories = (taxonomy) => {
  var meals_cats = new Set();
  for (const [cat, props] of Object.entries(taxonomy)) {
    if (props['deductibility_tags'].includes('meals')) {
      meals_cats.add(cat);
    }
  }
  return meals_cats;
};

export const getMealsCategoriesAll = (taxonomyCur, taxonomiesDepr) => {
  let all_meals_cats = getMealsCategories(taxonomyCur);
  for (const [ver, curCategoryMap] of Object.entries(taxonomiesDepr)) {
    for (let elem of getMealsCategories(curCategoryMap)) {
      all_meals_cats.add(elem);
    }
  }
  return all_meals_cats;
};

/**
 * Returns sub categories for a given expense category. Returns an array containing
 * single element BlankChoice if category is not found
 *
 * @param {string} category - expense category
 * @param {object} categoryMap - {category -> list of sub categories}
 *
 * @returns {array} array if {id, name} objects corresponding to sub categories
 */
export const getSubCategoriesFor = (category, categoryMap) => {
  if (category in categoryMap) {
    var subCategories = categoryMap[category].map(function (
      value,
      index,
      array,
    ) {
      return {id: value, name: value};
    });
    return subCategories;
  } else {
    // return Blank Choice if nothing is found. This will help in not modifying categories in api call
    return [BlankChoice];
  }
};
