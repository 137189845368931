export const BATCH_NOTIFICATION_THRESHOLD = 4;
export const DeductionClassChoices = [
  {id: 'DE', name: 'Deductible'},
  {id: 'ND', name: 'Non Deductible'},
  {id: 'PS', name: 'Possible Deduction'},
  {id: 'UN', name: 'Unclassified'},
  {id: 'IG', name: 'Ignored'},
];
export const DefaultDeductionChoice = {id: 'PS', name: 'Possible Deduction'};

export const LastModifierChoices = [
  {id: 'AL', name: 'Algo'},
  {id: 'RE', name: 'Reviewer'},
];

export const TxnStateChoices = [
  {id: 'INIT', name: 'INIT'},
  {id: 'PENDING_REVIEW', name: 'PENDING_REVIEW'},
  {id: 'PRE_READY', name: 'PRE_READY'},
  {id: 'DONT_SHOW', name: 'DONT_SHOW'},
  {id: 'READY', name: 'READY'},
  {id: 'USER_ACCEPTED', name: 'USER_ACCEPTED'},
];

export const TxnBucketChoices = [
  {id: 'NORMAL', name: 'NORMAL'},
  {id: 'LATER', name: 'LATER'},
  {id: 'CPA', name: 'CPA'},
];

export const BlankChoice = {id: '', name: '(none)'};

export const YearChoices = [
  {id: '2026', name: '2026'},
  {id: '2025', name: '2025'},
  {id: '2024', name: '2024'},
  {id: '2023', name: '2023'},
  {id: '2022', name: '2022'},
  {id: '2021', name: '2021'},
  {id: '2020', name: '2020'},
  {id: '2019', name: '2019'},
  {id: '2018', name: '2018'},
];

export const NotificationStateChoices = [
  {id: 'NONE', name: '✕'},
  {id: 'SENT', name: '✅'},
  {id: 'SCHEDULED', name: '🕑'},
  {id: 'ERRORED', name: '⚠'},
];

export const MerchantRuleLabel = [
  {id: 'MERCHANT_DB', name: 'Merchant - Merchant Database'},
  {id: 'MERCHANT_CLEAN', name: 'Merchant - Name Clean Up'},
];

export const CategoryRuleLabel = [
  {id: 'CAT_PLAID', name: 'Category - Plaid Category Rule'},
];
export const LandingScreenChoices = [
  {id: 'CLASSIFICATION_STATUS_BUCKETS', name: 'Classification Status Buckets'},
  {id: 'FILE_TAX_RETURN', name: 'File Tax Return'},
  {id: 'EXPORT_DATA', name: 'Export Data'},
  {id: 'MY_CARDS', name: 'My Cards'},
  {id: 'QTC', name: 'Quarterly Tax Calculator'},
];

export const AccountLinkStatusChoices = [
  {id: 'LINKED', name: 'Linked'},
  {id: 'NOT_LINKED', name: 'Not Linked'},
];

export const AppInstallStatusChoices = [
  {id: 'INSTALLED', name: 'Installed'},
  {id: 'UNINSTALLED', name: 'Uninstalled'},
];

export const TxnReviewedChoices = [
  {id: 'True', name: 'Reviewed'},
  {id: 'False', name: 'Not Reviewed'},
];

export const CurrentAmountChoices = [
  {id: 'POSITIVE', name: 'Positive'},
  {id: 'NEGATIVE', name: 'Negative'},
];
export const SeverityChoices = [
  {id: 'SEVERE', name: 'Severe'},
  {id: 'NOT_SEVERE', name: 'Not-Severe'},
];
export const NotSureCategoryReasonChoices = [
  {id: 'UNRECOGNIZED_MERCHANT', name: 'Unrecognized merchant'},
  {id: 'MORE_THAN_1_CATEGORY', name: 'More than 1 category seems correct'},
  {id: 'NO_CATEGORY_MATCH', name: 'None of the categories match exactly'},
  {id: 'OTHER', name: 'Other'},
];

export const IsPlaidCategoryCorrectChoices = [
  {id: 'True', name: 'Yes'},
  {id: 'False', name: 'No'},
];

export const TaxFilingStatusChoices = [
  {id: 'TAX_PROFILE_PENDING', name: 'Tax Profile Pending'},
  {id: 'TAX_PROFILE_SUBMIT', name: 'Tax Profile Submit'},
  {id: 'DOCUMENTS_RECEIVED', name: 'Documents Received'},
  {id: 'FILING_REVIEWED', name: 'Filing Reviewed'},
  {id: 'SIGNED_DOC_RECEIVED', name: 'Signed Doc Received'},
  {id: 'FILING_COMPLETED', name: 'Filing Completed'},
];

export const TaxOweOrRefundChoices = [
  {id: 'Owe', name: 'Owe'},
  {id: 'Refund', name: 'Refund'},
];

export const noStateTaxValidStates = [
  'AK',
  'FL',
  'SD',
  'NV',
  'TX',
  'WA',
  'WY',
];

export const USAStatesAbbreviations = [
  {id: 'AL', name: 'Alabama(AL)'},
  {id: 'AK', name: 'Alaska(AK)'},
  {id: 'AZ', name: 'Arizona(AZ)'},
  {id: 'AR', name: 'Arkansas(AR)'},
  {id: 'CA', name: 'California(CA)'},
  {id: 'CO', name: 'Colorado(CO)'},
  {id: 'CT', name: 'Connecticut(CT)'},
  {id: 'DE', name: 'Delaware(DE)'},
  {id: 'FL', name: 'Florida(FL)'},
  {id: 'GA', name: 'Georgia(GA)'},
  {id: 'HI', name: 'Hawaii(HI)'},
  {id: 'ID', name: 'Idaho(ID)'},
  {id: 'IL', name: 'Illinois(IL)'},
  {id: 'IN', name: 'Indiana(IN)'},
  {id: 'IA', name: 'Iowa(IA)'},
  {id: 'KS', name: 'Kansas(KS)'},
  {id: 'KY', name: 'Kentucky(KY)'},
  {id: 'LA', name: 'Louisiana(LA)'},
  {id: 'ME', name: 'Maine(ME)'},
  {id: 'MD', name: 'Maryland(MD)'},
  {id: 'MA', name: 'Massachusetts(MA)'},
  {id: 'MI', name: 'Michigan(MI)'},
  {id: 'MN', name: 'Minnesota(MN)'},
  {id: 'MS', name: 'Mississippi(MS)'},
  {id: 'MO', name: 'Missouri(MO)'},
  {id: 'MT', name: 'Montana(MT)'},
  {id: 'NE', name: 'Nebraska(NE)'},
  {id: 'NV', name: 'Nevada(NV)'},
  {id: 'NH', name: 'New Hampshire(NH)'},
  {id: 'NJ', name: 'New Jersey(NJ)'},
  {id: 'NM', name: 'New Mexico(NM)'},
  {id: 'NY', name: 'New York(NY)'},
  {id: 'NC', name: 'North Carolina(NC)'},
  {id: 'ND', name: 'North Dakota(ND)'},
  {id: 'OH', name: 'Ohio(OH)'},
  {id: 'OK', name: 'Oklahoma(OK)'},
  {id: 'OR', name: 'Oregon(OR)'},
  {id: 'PA', name: 'Pennsylvania(PA)'},
  {id: 'RI', name: 'Rhode Island(RI)'},
  {id: 'SC', name: 'South Carolina(SC)'},
  {id: 'SD', name: 'South Dakota(SD)'},
  {id: 'TN', name: 'Tennessee(TN)'},
  {id: 'TX', name: 'Texas(TX)'},
  {id: 'UT', name: 'Utah(UT)'},
  {id: 'VT', name: 'Vermont(VT)'},
  {id: 'VA', name: 'Virginia(VA)'},
  {id: 'WA', name: 'Washington(WA)'},
  {id: 'WV', name: 'West Virginia(WV)'},
  {id: 'WI', name: 'Wisconsin(WI)'},
  {id: 'WY', name: 'Wisconsin(WY)'},
  {id: 'DC', name: 'District of Columbia(DC)'},
];

export const USAStates = [
  {id: 'Alabama', name: 'Alabama(AL)'},
  {id: 'Alaska', name: 'Alaska(AK)'},
  {id: 'Arizona', name: 'Arizona(AZ)'},
  {id: 'Arkansas', name: 'Arkansas(AR)'},
  {id: 'California', name: 'California(CA)'},
  {id: 'Colorado', name: 'Colorado(CO)'},
  {id: 'Connecticut', name: 'Connecticut(CT)'},
  {id: 'Delaware', name: 'Delaware(DE)'},
  {id: 'Florida', name: 'Florida(FL)'},
  {id: 'Georgia', name: 'Georgia(GA)'},
  {id: 'Hawaii', name: 'Hawaii(HI)'},
  {id: 'Idaho', name: 'Idaho(ID)'},
  {id: 'Illinois', name: 'Illinois(IL)'},
  {id: 'Indiana', name: 'Indiana(IN)'},
  {id: 'Iowa', name: 'Iowa(IA)'},
  {id: 'Kansas', name: 'Kansas(KS)'},
  {id: 'Kentucky', name: 'Kentucky(KY)'},
  {id: 'Louisiana', name: 'Louisiana(LA)'},
  {id: 'Maine', name: 'Maine(ME)'},
  {id: 'Maryland', name: 'Maryland(MD)'},
  {id: 'Massachusetts', name: 'Massachusetts(MA)'},
  {id: 'Michigan', name: 'Michigan(MI)'},
  {id: 'Minnesota', name: 'Minnesota(MN)'},
  {id: 'Mississippi', name: 'Mississippi(MS)'},
  {id: 'Missouri', name: 'Missouri(MO)'},
  {id: 'Montana', name: 'Montana(MT)'},
  {id: 'Nebraska', name: 'Nebraska(NE)'},
  {id: 'Nevada', name: 'Nevada(NV)'},
  {id: 'New Hampshire', name: 'New Hampshire(NH)'},
  {id: 'New Jersey', name: 'New Jersey(NJ)'},
  {id: 'New Mexico', name: 'New Mexico(NM)'},
  {id: 'New York', name: 'New York(NY)'},
  {id: 'North Carolina', name: 'North Carolina(NC)'},
  {id: 'North Dakota', name: 'North Dakota(ND)'},
  {id: 'Ohio', name: 'Ohio(OH)'},
  {id: 'Oklahoma', name: 'Oklahoma(OK)'},
  {id: 'Oregon', name: 'Oregon(OR)'},
  {id: 'Pennsylvania', name: 'Pennsylvania(PA)'},
  {id: 'Rhode Island', name: 'Rhode Island(RI)'},
  {id: 'South Carolina', name: 'South Carolina(SC)'},
  {id: 'South Dakota', name: 'South Dakota(SD)'},
  {id: 'Tennessee', name: 'Tennessee(TN)'},
  {id: 'Texas', name: 'Texas(TX)'},
  {id: 'Utah', name: 'Utah(UT)'},
  {id: 'Vermont', name: 'Vermont(VT)'},
  {id: 'Virginia', name: 'Virginia(VA)'},
  {id: 'Washington', name: 'Washington(WA)'},
  {id: 'West Virginia', name: 'West Virginia(WV)'},
  {id: 'Wisconsin', name: 'Wisconsin(WI)'},
  {id: 'Wisconsin', name: 'Wisconsin(WY)'},
  {id: 'District of Columbia', name: 'District of Columbia(DC)'},
];

export const TaxProfileQuestionTypes = {
  LEGAL_NAME: 'LEGAL_NAME',
  SSN: 'SSN',
  HOME_ADDRESS: 'HOME_ADDRESS',
  CLAIM_YOU_DEPENDENT: 'CLAIM_YOU_DEPENDENT',
  FILING_STATUS: 'FILING_STATUS',
  SPOUSE_LEGAL_NAME: 'SPOUSE_LEGAL_NAME',
  SPOUSE_SSN: 'SPOUSE_SSN',
  SPOUSE_CLAIMED_DEPENDENT: 'SPOUSE_CLAIMED_DEPENDENT',
  DEPENDENT_DETAILS: 'DEPENDENT_DETAILS',
  EARNING_STATES: 'EARNING_STATES',
  INCOME_SOURCES: 'INCOME_SOURCES',
  YOUR_N_SPOUSE_INCOME_SOURCES: 'YOUR_N_SPOUSE_INCOME_SOURCES',
  FREELANCE_EARNINGS: 'FREELANCE_EARNINGS',
  RETIREMENT_PLAN_CONTRIBUTIONS: 'RETIREMENT_PLAN_CONTRIBUTIONS',
  DEDUCTION_OPTION: 'DEDUCTION_OPTION',
  ITEMIZE_DEDUCTION: 'ITEMIZE_DEDUCTION',
  STANDARD_DEDUCTION: 'STANDARD_DEDUCTION',
  ESTIMATED_TAX_PAYMENTS: 'ESTIMATED_TAX_PAYMENTS',
  RECEIVED_ADV_CHILD_TAX_CREDITS: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
  BUSINESS_DETAILS: 'BUSINESS_DETAILS',
};

export const TaxProfileQuestions = {
  LEGAL_NAME: 'What is your legal name?',
  SSN: 'What is your SSN?',
  HOME_ADDRESS: 'What is your home address?',
  CLAIM_YOU_DEPENDENT: 'Can someone claim you as their dependent?',
  FILING_STATUS: 'What is your filing status for tax year 2021?',
  SPOUSE_LEGAL_NAME: 'Your spouse’s legal name?',
  SPOUSE_SSN: 'Your spouse’s Social Security Number?',
  SPOUSE_CLAIMED_DEPENDENT:
    'Can your spouse be claimed as someone’s dependent?',
  DEPENDENT_DETAILS: 'Your dependent details?',
  EARNING_STATES: 'Select all the state you earned income from',
  INCOME_SOURCES: 'Select all the income sources that apply to you',
  YOUR_N_SPOUSE_INCOME_SOURCES:
    'Income sources that apply to you & your spouse',
  FREELANCE_EARNINGS: 'How much did you earn from freelancing in year 2021?',
  RETIREMENT_PLAN_CONTRIBUTIONS:
    'Select all the retirement plan contibutions that apply to you',
  DEDUCTION_OPTION: 'Do you want to itemize your deductions?',
  ITEMIZE_DEDUCTION: 'Select the itemize deductions that apply to you',
  STANDARD_DEDUCTION: 'Select the standard deductions that apply to you',
  ESTIMATED_TAX_PAYMENTS:
    'Have you made any estimated tax payments in tax year 2021?',
  RECEIVED_ADV_CHILD_TAX_CREDITS:
    'Did you receive advance child tax credits in year 2021?',
  BUSINESS_DETAILS: 'What is the name of your business?',
};

export const IncomeScourcesChoices = [
  {id: 'W2 income', name: 'W2 income'},
  {id: 'Capital gains', name: 'Capital gains'},
  {id: 'Income from interest', name: 'Income from interest'},
  {id: 'Income from dividends', name: 'Income from dividends'},
  {id: 'Government benefits', name: 'Government benefits'},
  {id: 'Income from other sources', name: 'Income from other sources'},
];

export const RetirementPlanContriChoices = [
  {id: 'IRA', name: 'IRA'},
  {id: 'Keogh', name: 'Keogh'},
  {id: 'Simple', name: 'Simple'},
  {id: 'SEP', name: 'SEP'},
  {id: '401(k)', name: '401(k)'},
];

export const ItemizeDeductionChoices = [
  {id: 'Mortgage interest', name: 'Mortgage interest'},
  {id: 'Mortgage insurance premium', name: 'Mortgage insurance premium'},
  {id: 'Student loan interest', name: 'Student loan interest'},
  {id: 'Paid income and sales tax', name: 'Paid income and sales tax'},
  {id: 'Paid real estate tax', name: 'Paid real estate tax'},
  {id: 'Charity contributions', name: 'Charity contributions'},
  {id: 'Unreimbursed medical expenses', name: 'Unreimbursed medical expenses'},
  {id: 'Gambling losses', name: 'Gambling losses'},
];

export const StandardDeductionChoices = [
  {
    id: 'Self-employed health insurance',
    name: 'Self-employed health insurance',
  },
  {id: 'Student loan interest', name: 'Student loan interest'},
  {id: 'Charity contributions', name: 'Charity contributions'},
  {id: 'HSA contributions', name: 'HSA contributions'},
];

export const EstimatedTaxPaymentChoices = [
  {id: 'Federal taxes', name: 'Federal taxes'},
  {id: 'State taxes', name: 'State taxes'},
  {id: 'None of the above', name: 'None of the above'},
];

export const CPAQuestionStatusChoices = [
  {id: 'OPEN', name: 'Open'},
  {id: 'SENT', name: 'Sent'},
  {id: 'RESOLVED', name: 'Resolved'},
];

export const CPAQuestionTypeChoices = [
  {id: 'GENERAL', name: 'General'},
  {id: 'TXN_RELATED', name: 'Txn Related'},
];

export const LocalStorageKey = {
  Token: 'token',
  UserId: 'id',
  UserName: 'user_name',
  UserEmail: 'user_email',
  UserFirstName: 'user_first_name',
  UserLastName: 'user_last_name',
  AdminToken: 'admin_token',
};

export const ROUTES_PLACEHOLDERS = {
  FLYFIN_USER_ID_PLACEHOLDER: 'FLYFIN_USER_ID_PLACEHOLDER',
  DOCUMENT_TYPE_ID_PLACEHOLDER: 'DOCUMENT_TYPE_ID_PLACEHOLDER',
  DOCUMENT_ID_PLACEHOLDER: 'DOCUMENT_ID_PLACEHOLDER',
  QUERY_ID_PLACEHOLDER: 'QUERY_ID_PLACEHOLDER',
};

export enum CLASSIFICATION_STATUS {
  POSSIBLE = 'PS',
  DEDUCTION = 'DE',
  NON_DEDUCTION = 'ND',
  ALL_CLASSIFIED = 'DE,PS,ND',
  POSSIBLE_INCOME = 'PI',
  NOT_INCOME = 'NI',
  INCOME = 'IN',
}

export enum BUCKET {
  LATER = 'LATER',
  CPA = 'CPA',
  NORMAL = 'NORMAL',
}

export const DOCUMENT_STATUS = {
  ACCEPT: 'Accept',
  ACTION_REQUIRED: 'Action required',
};

export enum VALID_DOCUMENT_STATUS {
  PENDING_FOR_REVIEW = 'PENDING_FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  IGNORED = 'IGNORED',
  REVIEWED_OCR_DATA = 'REVIEWED_OCR_DATA',
}

export enum VALID_DOCUMENT_REASON {
  WRONG_DOC = 'WRONG_DOC',
  DOC_NOT_CLEAR = 'DOC_NOT_CLEAR',
  INFO_MISMATCH = 'INFO_MISMATCH',
  ADDITIONAL_DOC = 'ADDITIONAL_DOC',
  INCOMPLETE_DOC = 'INCOMPLETE_DOC',
  GENERAL = 'GENERAL',
  TAX_PROFILE_CPA = 'TAX_PROFILE_CPA',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
}

export enum QUERY_STATUS {
  RESOLVED = 'RESOLVED',
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
}

export enum AuthorType {
  END_USER = 'END_USER',
  CPA = 'CPA',
  SYSTEM = 'SYSTEM',
}

export enum CPAQuestionMessageType {
  CHAT = 'CHAT',
  INFO = 'INFO',
  DOC_ATTACHED = 'DOC_ATTACHED',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  DOCUMENT_PROOF = 'DOCUMENT_PROOF',
  CALL_INVITE = 'CALL_INVITE',
  CALL_CANCELLATION = 'CALL_CANCELLATION',
  GENERAL = 'GENERAL',
  DEEPLINK = 'DEEPLINK',
}

export enum CPA_CALL_STATUS {
  NA = 'NA',
  CALL_MESSAGE_SENT = 'CALL_MESSAGE_SENT',
  CALL_SCHEDULED = 'CALL_SCHEDULED',
  CALL_CANCELLED = 'CALL_CANCELLED',
  CALL_OVER = 'CALL_OVER',
}

export enum CPAQuestionType {
  GENERAL = 'GENERAL',
  TXN_RELATED = 'TXN_RELATED',
  TAX_FILING = 'TAX_FILING',
  QTC = 'QTC',

  /**
   * @deprecated this is migrated to type: TAX_FILING & sub_type: TAX_PROFILE
   */
  TAX_PROFILE = 'TAX_PROFILE',

  /**
   * @info type used only for filtering queries in return center queries
   */
  TAX_FILING_N_TAX_PROFILE = 'TAX_FILING_N_TAX_PROFILE',
}

export const CPAQuestionTypeName = {
  [CPAQuestionType.QTC]: 'Qtc',
  [CPAQuestionType.TAX_FILING]: 'Tax Filing',
  [CPAQuestionType.TXN_RELATED]: 'Expense',
  [CPAQuestionType.GENERAL]: 'General',
  [CPAQuestionType.TAX_PROFILE]: 'Tax Profile',
  [CPAQuestionType.TAX_FILING_N_TAX_PROFILE]: 'Tax Filing',
};

export enum DOCUMENT_TYPES {
  DL_OR_STATE_ISSUED_ID = 'DL_OR_STATE_ISSUED_ID',
  LAST_YEAR_TAX_RETURN_FORM_1040 = 'LAST_YEAR_TAX_RETURN_FORM_1040',
  W2_FORM = 'W2_FORM',
  FORM_1099_INT = 'FORM_1099_INT',
  FORM_1099_DIV = 'FORM_1099_DIV',
  FORM_1099_G = 'FORM_1099_G',
  FORM_1099_B = 'FORM_1099_B',
  FORM_1098_T = 'FORM_1098_T',
  FORM_1095_A = 'FORM_1095_A',
  FORM_1120 = 'FORM_1120',
  FORM_1120_S = 'FORM_1120_S',
  FORM_1065 = 'FORM_1065',
  CHARITABLE_CONTRI_DOC = 'CHARITABLE_CONTRI_DOC',
  BUSINESS_REGISTRATION_DOC = 'BUSINESS_REGISTRATION_DOC',
  FORM_401_K = 'FORM_401_K',
  IRA_CONTRI_PROOF = 'IRA_CONTRI_PROOF',
  ESTIMATED_TAX_PAYMENT_PROOF = 'ESTIMATED_TAX_PAYMENT_PROOF',
  OTHER = 'OTHER',
  MORTGAGE = 'MORTGAGE',
  STATE_N_LOCAL_TAX = 'STATE_N_LOCAL_TAX',
  STATE_N_LOCAL_REAL_ESTATE_TAX = 'STATE_N_LOCAL_REAL_ESTATE_TAX',
  PERSONAL_PROPERTY_TAX = 'PERSONAL_PROPERTY_TAX',
  GAMBLING_LOSS = 'GAMBLING_LOSS',
  CASUALTY_N_THEFT_LOSS = 'CASUALTY_N_THEFT_LOSS',
  D1099_R = 'D1099_R',
  D1099_SA = 'D1099_SA',
  K_1_1120S_1065 = 'K_1_1120S_1065',
  MEDICAL_EXPENSE_PROOF = 'MEDICAL_EXPENSE_PROOF',
  STUDENT_LOAN_INTEREST = 'STUDENT_LOAN_INTEREST',
  VEHICLE_LOAN = 'VEHICLE_LOAN',
  SPOUSE_DL = 'SPOUSE_DL',
  K_1 = 'K_1',
  STATE_TAX_RETURN = 'STATE_TAX_RETURN',
  OTHER_1 = 'OTHER_1',
  OTHER_2 = 'OTHER_2',
  OTHER_3 = 'OTHER_3',
  OTHER_4 = 'OTHER_4',
  OTHER_5 = 'OTHER_5',
  OTHER_6 = 'OTHER_6',
  OTHER_7 = 'OTHER_7',
  OTHER_8 = 'OTHER_8',
  OTHER_9 = 'OTHER_9',
  OTHER_10 = 'OTHER_10',
  FORM_1099_NEC_K_MISC = 'FORM_1099_NEC_K_MISC',
  TAX_RETURNS = 'TAX_RETURNS',
  ESIGN_FORM = 'ESIGN_FORM',
  OTHER_LOAN_DOCUMENT = 'OTHER_LOAN_DOCUMENT',
  SA_5498 = 'SA_5498',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  S_CORP_ELECTION = 'S_CORP_ELECTION',
  DIGITAL_ASSETS = 'DIGITAL_ASSETS',
  FORM_1099_MISC = 'FORM_1099_MISC',
  FORM_1099_SSA = 'FORM_1099_SSA',
  FORM_1099_C = 'FORM_1099_C',
  FORM_1099_OID = 'FORM_1099_OID',
  FOREIGN_INCOME_DOCUMENT = 'FOREIGN_INCOME_DOCUMENT',
  EMPLOYEES_W2_FORM = 'EMPLOYEES_W2_FORM',
  DEPRECIATION_REPORT = 'DEPRECIATION_REPORT',
  BOIR_INCORPORATION_DOC = 'BOIR_INCORPORATION_DOC',
  BOIR_BENEFICIAL_OWNER_ID = 'BOIR_BENEFICIAL_OWNER_ID',
  PASSPORT = 'PASSPORT',
  BOIR_INCORPORATION_CERTIFICATE = 'BOIR_INCORPORATION_CERTIFICATE',
  BOIR_PAYROLL_REPORT = 'BOIR_PAYROLL_REPORT',
  BOIR_TAX_RETURN = 'BOIR_TAX_RETURN',
  BOIR_OWNERSHIP_LEASE_DOC = 'BOIR_OWNERSHIP_LEASE_DOC',
  DEPENDENT_CHILD_CARE = 'DEPENDENT_CHILD_CARE',
}

export const additionalDocumentsList = [
  DOCUMENT_TYPES.LAST_YEAR_TAX_RETURN_FORM_1040,
  DOCUMENT_TYPES.FORM_1120,
  DOCUMENT_TYPES.FORM_1120_S,
  DOCUMENT_TYPES.FORM_1065,
  DOCUMENT_TYPES.K_1_1120S_1065,
  DOCUMENT_TYPES.MORTGAGE,
  DOCUMENT_TYPES.STUDENT_LOAN_INTEREST,
  DOCUMENT_TYPES.FORM_1098_T,
  DOCUMENT_TYPES.ESTIMATED_TAX_PAYMENT_PROOF,
  DOCUMENT_TYPES.FORM_1099_SSA,
  DOCUMENT_TYPES.FORM_1099_C,
  DOCUMENT_TYPES.FORM_1099_OID,
  DOCUMENT_TYPES.FOREIGN_INCOME_DOCUMENT,
  DOCUMENT_TYPES.EMPLOYEES_W2_FORM,
  DOCUMENT_TYPES.DEPRECIATION_REPORT,
  DOCUMENT_TYPES.STATE_TAX_RETURN,
];

export const ALL_DOCS_TYPE = Object.values(DOCUMENT_TYPES);

export enum ALL_FORM_TYPES {
  FORM_1099_B = 'FORM_1099_B',
  W2_G_FORM = 'W2_G_FORM',
  FORM_1099_SA = 'FORM_1099_SA',
  MANUAL_FREELANCE_INCOME_FORM = 'MANUAL_FREELANCE_INCOME_FORM',
  W2_FORM = 'W2_FORM',
  FORM_1099_NEC = 'FORM_1099_NEC',
  FORM_1099_MISC = 'FORM_1099_MISC',
  FORM_1099_K = 'FORM_1099_K',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  FORM_1099_INT = 'FORM_1099_INT',
  FORM_1098_E = 'FORM_1098_E',
  FORM_1098_T = 'FORM_1098_T',
  FORM_1099_DIV = 'FORM_1099_DIV',
  FORM_1099_R = 'FORM_1099_R',
  FORM_1099_G = 'FORM_1099_G',
  FORM_1095_A = 'FORM_1095_A',
  FORM_1120 = 'FORM_1120',
  FORM_1120_S = 'FORM_1120_S',
  FORM_1065 = 'FORM_1065',
  OTHER = 'OTHER',
  FORM_1040_NORMAL = 'FORM_1040_NORMAL',
  FORM_1040_SCHEDULE_1 = 'FORM_1040_SCHEDULE_1',
  FORM_1040_SCHEDULE_C = 'FORM_1040_SCHEDULE_C',
  FORM_1040_SCH_K1_1120 = 'FORM_1040_SCH_K1_1120',
  FORM_1040_SCH_K1_1065 = 'FORM_1040_SCH_K1_1065',
  FORM_1040_SCHEDULE_K1 = 'FORM_1040_SCHEDULE_K1',
  BUSINESS_REG_FORM = 'BUSINESS_REG_FORM',
  DEPENDENT = 'DEPENDENT',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  DEPRECIATION = 'DEPRECIATION',
  FORM_1098_MIS = 'FORM_1098_MIS',
  BUSINESS_VEHICLE = 'BUSINESS_VEHICLE',
  HOME_DEPRECIATION = 'HOME_DEPRECIATION',
  CHARITY_CONTRIBUTION_RECEIPT = 'CHARITY_CONTRIBUTION_RECEIPT',
  FORM_SA_5498 = 'FORM_SA_5498',
  STATES_LIVED = 'STATES_LIVED',
  QUARTERLY_TAX_PAYMENTS = 'QUARTERLY_TAX_PAYMENTS',
  RENTAL_INCOME = 'RENTAL_INCOME',
  FORM_K1_1120S = 'FORM_K1_1120S',
  FORM_K1_1065 = 'FORM_K1_1065',
  BOIR_BENEFICIAL_OWNER_DETAILS = 'BOIR_BENEFICIAL_OWNER_DETAILS',
  CHILD_CARE_EXPENSE = 'CHILD_CARE_EXPENSE',
}

export const INCOME_FORMS = [
  ALL_FORM_TYPES.FORM_1099_MISC,
  ALL_FORM_TYPES.FORM_1099_NEC,
  ALL_FORM_TYPES.FORM_1099_K,
  ALL_FORM_TYPES.W2_FORM,
  ALL_FORM_TYPES.FORM_1099_INT,
  ALL_FORM_TYPES.FORM_1099_DIV,
  ALL_FORM_TYPES.FORM_1099_B,
  ALL_FORM_TYPES.W2_G_FORM,
  ALL_FORM_TYPES.FORM_1099_G,
  ALL_FORM_TYPES.FORM_1099_R,
  ALL_FORM_TYPES.FORM_1099_SA,
  ALL_FORM_TYPES.MANUAL_FREELANCE_INCOME_FORM,
];

export const STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const DEFAULT_PER_PAGE = 10;
export const MAX_PER_PAGE_LIMIT = 2000;
export const RESOLVED_OPACITY = 0.5;

export const CPA_CENTER_ACTIVE_YEAR = 2023;

export const themmeColor = {
  grey: '#555555',
  errorRed: '#FF4B4B',
  offWhite: '#E1E1E1',
  white: '#FFFFFF',
  black: '#000000',
  focusedBlue: '#EEF8FF',
  black60: '#4C4C4C',
  darkBlue: '#1167CB',
  successGreen: '#0CAB4E',
  lightGreen: '#D4FFD9',
  flyfinOrange: '#FF6E4A',
  flyfinYellow: '#C28C00',
  silver: '#A5A5A5',
  backgroundOffWhite: '#F5F5F5',
  black80: '#212121',
  translucentYellow: '#EAEC9F42',
  translucentRed: '#FFF0EC',
  translucentGreen: '#EBFFD7',
  lightGrey: '#D9D9D9',
  skyBlue: '#8CC0FF',
  lightSkyBlue: '#D7E9FF',
  antiqueWhite: '#FFEAD7',
  hitPink: '#FFAF8C',
  linen: '#FFEAEA',
  deleteRed: '#A23921',
  statusFlyfinYellow: '#FFC94A',
  lightYellow: '#FFF4D7',
  lightBlue: '#E0EBFF',
  hoverColor: '#F4F9FF',
  lightFlyfinOrange: '#FFECEC',
  lightSuccessGreen: '#ECFFF8',
  darkYellow: '#685633',
  dashboardRed: '#AC7777',
  resolvedGreen: '#77AC82',
  sentGrey: '#9B9B9B',
  black70: '#333333',
  cpaCenterV2Bg: '#F5F6F8',
  offGrey: '#E7E8EB',
  cpaCenterV2QueryBox: '#F3F3F3',
  darkGreen: '#397B21',
  selectedQueryBackground: '#FBF9FF',
  textPurple: '#745699',
  deductionGreen: '#DDFFE0',
  draftQueryBgColor: '#F9F1F1',
  dashboardBlue: '#1F7FD8',
};

export enum ReturnSummaryKeys {
  agi = 'agi',
  income_tax = 'income_tax',
  income_tax_withheld = 'income_tax_withheld',
  itemized_deduction = 'itemized_deduction',
  self_employment_tax = 'self_employment_tax',
  taxable_income = 'taxable_income',
  tax_due_amount = 'tax_due_amount',
  tax_refund_amount = 'tax_refund_amount',
  standard_deduction = 'standard_deduction',
  net_short_term_gain = 'net_short_term_gain',
  net_short_term_loss = 'net_short_term_loss',
  net_long_term_gain = 'net_long_term_gain',
  net_long_term_loss = 'net_long_term_loss',
  sec_1231_gain = 'sec_1231_gain',
  sec_1231_loss = 'sec_1231_loss',
  other_income = 'other_income',
  other_loss = 'other_loss',
  ord_bus_income = 'ord_bus_income',
  ord_bus_loss = 'ord_bus_loss',
  rental_estate_income = 'rental_estate_income',
  rental_estate_loss = 'rental_estate_loss',
  other_rental_income = 'other_rental_income',
  other_rental_loss = 'other_rental_loss',
  guaranteed_payments = 'guaranteed_payments',
  interest_income = 'interest_income',
  dividends = 'dividends',
  royalties = 'royalties',
  gross_income = 'gross_income',
  cost_of_goods = 'cost_of_goods',
  total_income = 'total_income',
  total_loss = 'total_loss',
  payments_and_credits = 'payments_and_credits',
  tax_to_pay = 'tax_to_pay',
  tax_refund = 'tax_refund',
  franchise_tax = 'franchise_tax',
  total_deductions = 'total_deductions',
  total_tax = 'total_tax',
}

export const RETURN_SUMMARY_CATEGORY = {
  [ReturnSummaryKeys.agi]: 'Adjusted gross income',
  [ReturnSummaryKeys.income_tax]: 'Income tax',
  [ReturnSummaryKeys.income_tax_withheld]: 'Income tax withheld',
  [ReturnSummaryKeys.itemized_deduction]: 'Itemize deduction',
  [ReturnSummaryKeys.self_employment_tax]: 'Self employment tax',
  [ReturnSummaryKeys.taxable_income]: 'Taxable income',
  [ReturnSummaryKeys.tax_due_amount]: 'Tax due amount',
  [ReturnSummaryKeys.tax_refund_amount]: 'Tax refund amount',
  [ReturnSummaryKeys.standard_deduction]: 'Standard deduction',
  [ReturnSummaryKeys.net_short_term_gain]: 'Net Short Term Gain',
  [ReturnSummaryKeys.net_short_term_loss]: 'Net Short Term Loss',
  [ReturnSummaryKeys.net_long_term_gain]: 'Net Long Term Gain',
  [ReturnSummaryKeys.net_long_term_loss]: 'Net Long Term Loss',
  [ReturnSummaryKeys.sec_1231_gain]: 'Sec 1231 Gain',
  [ReturnSummaryKeys.sec_1231_loss]: 'Sec 1231 Loss',
  [ReturnSummaryKeys.other_income]: 'Other Income',
  [ReturnSummaryKeys.other_loss]: 'Other Loss',
  [ReturnSummaryKeys.ord_bus_income]: 'Ordinary business Income',
  [ReturnSummaryKeys.ord_bus_loss]: 'Ordinary Business Loss',
  [ReturnSummaryKeys.rental_estate_income]: 'Rental Estate Income',
  [ReturnSummaryKeys.rental_estate_loss]: 'Rental Estate Loss',
  [ReturnSummaryKeys.other_rental_income]: 'Other Rental Income',
  [ReturnSummaryKeys.other_rental_loss]: 'Other Rental Loss',
  [ReturnSummaryKeys.interest_income]: 'Interest Income',
  [ReturnSummaryKeys.dividends]: 'Dividends',
  [ReturnSummaryKeys.royalties]: 'Royalties',
  [ReturnSummaryKeys.gross_income]: 'Gross Income',
  [ReturnSummaryKeys.cost_of_goods]: 'Cost of Goods',
  [ReturnSummaryKeys.total_income]: 'Total Income',
  [ReturnSummaryKeys.total_loss]: 'Total Loss',
  [ReturnSummaryKeys.payments_and_credits]: 'Payments and Credits',
  [ReturnSummaryKeys.tax_to_pay]: 'Tax To Pay',
  [ReturnSummaryKeys.tax_refund]: 'Tax Refund',
  [ReturnSummaryKeys.franchise_tax]: 'Franchise Tax',
  [ReturnSummaryKeys.guaranteed_payments]: 'Guaranteed Payments',
  [ReturnSummaryKeys.total_deductions]: 'Total Deductions',
  [ReturnSummaryKeys.total_tax]: 'Total Tax',
};

export enum VALID_CPA_EMAILS {
  Varsha = 'varsha@flyfin.org',
  Aishwarya = 'aishwarya@flyfin.org',
  Sreeraj = 'sreeraj@flyfin.org',
  Sachin = 'sachin@flyfin.org',
  Kuldeep = 'kuldeep@flyfin.org',
  Anshul = 'anshul@flyfin.org',
  Prem = 'premsoni@flyfin.org',
  Mayur = 'mayurinagrecha@flyfin.org',
  Tawqeer = 'tawqeer@flyfin.org',
  Tilak = 'tilak@flyfin.org',
  Libin = 'libin@flyfin.org',
  Mohit = 'mohit@flyfin.org',
}

export const TAX_DUE_PAYMENT_METHOD = {
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  OTHER: 'OTHER',
} as const;

export const PROD_ALLOW_USER_DELETION_EMAILS = [
  'aman@flyfin.org',
  'amber@flyfin.org',
  'sachin@flyfin.org',
  'varun@flyfin.org',
  'lakshyabang@flyfin.org',
  'tirth@flyfin.org',
  'ishaan@flyfin.org',
  'spandey@flyfin.org',
  'raj.shah@flyfin.org',
];

export enum TaxProfileSection {
  BasicDetails = 'Basic-details',
  Income = 'Income',
  Deductions = 'Deductions',
  Credits = 'Credits',
  TaxPayments = 'Tax-payments',
  StateTaxes = 'State-taxes',
}

export const TaxProfileSectionSequence = [
  {
    stepName: 'Basic details',
    section: TaxProfileSection.BasicDetails,
  },
  {
    stepName: 'Income',
    section: TaxProfileSection.Income,
  },
  {
    stepName: 'Deduction',
    section: TaxProfileSection.Deductions,
  },
  {
    stepName: 'Credits',
    section: TaxProfileSection.Credits,
  },
  {
    stepName: 'Tax payments',
    section: TaxProfileSection.TaxPayments,
  },
  {
    stepName: 'State taxes',
    section: TaxProfileSection.StateTaxes,
  },
];

export enum TaxProfileSubsection {
  // Basic Details
  UserDetails = 'USER_DETAILS',
  HomeAddress = 'HOME_ADDRESS',
  SpouseDetails = 'SPOUSE_DETAILS',
  DependentDetails = 'DEPENDENT_DETAILS',
  BusinessDetails = 'BUSINESS_DETAILS',

  // Deductions
  HomeOfficeDetails = 'HOME_OFFICE_DEDUCTIONS',
  BusinessVehicleDetails = 'BUSINESS_VEHICLE_DEDUCTIONS',
  PhoneAndInternet = 'PHONE_AND_INTERNET_DEDUCTIONS',
  OfficeMortgage = 'OFFICE_MORTGAGE_DEDUCTIONS',
  StudentLoan = 'STUDENT_LOAN_DEDUCTIONS',
  BusinessLoans = 'BUSINESS_LOAN_DEDUCTIONS',
  SalaryAndWagesPaid = 'SALARY_AND_WAGES_DEDUCTIONS',
  OtherDepreciations = 'DEPRECIATION_DEDUCTIONS',
  HSAContributions = 'HSA_DEDUCTIONS',
  IRAContributions = 'IRA_DEDUCTIONS',
  HealthInsurancePremium = 'HEALTH_INSURANCE_PREMIUM_DEDUCTIONS',
  PersonalDeductions = 'PERSONAL_DEDUCTIONS',
  AllOtherDeductions = 'ALL_OTHER_DEDUCTIONS',

  // Income
  Freelance = 'FREELANCE_INCOME',
  W2Income = 'W2_INCOME',
  Interest = 'INTEREST_INCOME',
  Dividend = 'DIVIDEND_INCOME',
  Investment = 'INVESTMENT_INCOME',
  Gambling = 'GAMBLING_INCOME',
  Government = 'GOVERNMENT_INCOME',
  Retirement = 'RETIREMENT_INCOME',
  HSA = 'HSA_INCOME',
  RentalIncome = 'RENTAL_INCOME',
  DigitalAssetIncome = 'DIGITAL_ASSETS_INCOME',
  K1_INCOME = 'K1_INCOME',

  // Credits
  DependentCredits = 'DEPENDENT_CREDITS',
  EducationalCredits = 'EDUCATIONAL_CREDITS',
  AdoptionCredits = 'ADOPTION_CREDITS',

  // Tax Payments
  Payments = 'PAYMENTS',

  // States
  StatesTaxFilingInformation = 'STATES_TAX_FILING_INFORMATION',
}

export const TaxProfileSectionToSubsectionMapping = {
  [TaxProfileSection.BasicDetails]: [
    TaxProfileSubsection.UserDetails,
    TaxProfileSubsection.HomeAddress,
    TaxProfileSubsection.SpouseDetails,
    TaxProfileSubsection.DependentDetails,
    TaxProfileSubsection.BusinessDetails,
  ],
  [TaxProfileSection.Deductions]: [
    TaxProfileSubsection.HomeOfficeDetails,
    TaxProfileSubsection.BusinessVehicleDetails,
    TaxProfileSubsection.PhoneAndInternet,
    TaxProfileSubsection.OfficeMortgage,
    TaxProfileSubsection.StudentLoan,
    TaxProfileSubsection.BusinessLoans,
    TaxProfileSubsection.SalaryAndWagesPaid,
    // TaxProfileSubsection.OtherDepreciations,
    TaxProfileSubsection.HSAContributions,
    TaxProfileSubsection.IRAContributions,
    TaxProfileSubsection.HealthInsurancePremium,
    TaxProfileSubsection.PersonalDeductions,
    TaxProfileSubsection.AllOtherDeductions,
  ],
  [TaxProfileSection.Income]: [
    TaxProfileSubsection.Freelance,
    TaxProfileSubsection.W2Income,
    TaxProfileSubsection.Interest,
    TaxProfileSubsection.Dividend,
    TaxProfileSubsection.Investment,
    TaxProfileSubsection.RentalIncome,
    TaxProfileSubsection.DigitalAssetIncome,
    TaxProfileSubsection.Investment,
    TaxProfileSubsection.Gambling,
    TaxProfileSubsection.Government,
    TaxProfileSubsection.Retirement,
    TaxProfileSubsection.HSA,
    TaxProfileSubsection.K1_INCOME,
  ],
  [TaxProfileSection.Credits]: [
    TaxProfileSubsection.DependentCredits,
    TaxProfileSubsection.EducationalCredits,
    TaxProfileSubsection.AdoptionCredits,
  ],
  [TaxProfileSection.TaxPayments]: [TaxProfileSubsection.Payments],
  [TaxProfileSection.StateTaxes]: [
    TaxProfileSubsection.StatesTaxFilingInformation,
  ],
};

export enum DependentRelations {
  Son = 'Son',
  Daughter = 'Daughter',
  AdoptedChild = 'Adopted child',
  FosterChild = 'Foster child',
  Stepchild = 'Stepchild',
  Grandchild = 'Grandchild',
  Brother = 'Brother',
  Sister = 'Sister',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Uncle = 'Uncle',
  Aunt = 'Aunt',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Mother = 'Mother',
  Father = 'Father',
  UnrelatedDependent = 'Unrelated Dependent',
  FosterParent = 'Foster Parent',
  Cousin = 'Cousin',
  Friend = 'Friend',
}

export enum COMMON_COMPONENTS_ID {
  CPA_CENTER_V2_USER_SUMMARY_BAR = 'CPA_CENTER_V2_USER_SUMMARY_BAR',
  CPA_CENTER_V2_TAX_FILING_STEPS = 'CPA_CENTER_V2_TAX_FILING_STEPS',
  CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER = 'CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER',
  CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER = 'CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER',
  BOI_CENTER_USER_SUMMARY_BAR = 'BOI_CENTER_USER_SUMMARY_BAR',
}

export enum TAX_FILING_STATUS {
  NOT_INITIATED = 'NOT_INITIATED',
  TAX_PROFILE_SUBMITTED = 'TAX_PROFILE_SUBMITTED',
  TAX_DOCS_REVIEWED = 'TAX_DOCS_REVIEWED',
  TAX_PROFILE_REVIEWED = 'TAX_PROFILE_REVIEWED',
  USER_QUERIES_SENT = 'USER_QUERIES_SENT',
  PREP_STARTED = 'PREP_STARTED',
  DETAILS_SENT_TO_CCH = 'DETAILS_SENT_TO_CCH',
  RETURN_ADDED = 'RETURN_ADDED',
  SUMMARY_REVIEWED = 'SUMMARY_REVIEWED',
  RETURN_SENT_TO_USER = 'RETURN_SENT_TO_USER',
  USER_ESIGNED = 'USER_ESIGNED',
  BANK_DETAILS_SUBMITTED = 'BANK_DETAILS_SUBMITTED',
  EFILED = 'EFILED',
  REJECTED_FROM_IRS = 'REJECTED_FROM_IRS',
  APPROVED_FROM_IRS = 'APPROVED_FROM_IRS',
}

export enum CPA_ACK_TEMPLATE_NAME {
  SUBMIT_TO_CPA_WEEKDAYS = 'SUBMIT_TO_CPA_WEEKDAYS',
  SUBMIT_TO_CPA_WEEKENDS = 'SUBMIT_TO_CPA_WEEKENDS',
}

export const CPA_TEMPLATE_SAMPLE_MESSAGE = {
  [CPA_ACK_TEMPLATE_NAME.SUBMIT_TO_CPA_WEEKDAYS]:
    'Hi, thank you for submitting your documents. Please allow us one business day to get back to you either for clarifications or to confirm that everything looks good. Once all the information is looking good, we will prepare your return - generally takes 3 days.',
  [CPA_ACK_TEMPLATE_NAME.SUBMIT_TO_CPA_WEEKENDS]:
    'Hi, thank you for submitting your tax documents. Please note that we are away on the weekends and would be reviewing your documents as soon as we are back.',
};

export const BROWER_LOCAL_STORAGE_KEYS = {
  DRAWER_WIDTH: 'drawer-width', // stores the side drawer width set by user
};

export enum TAX_EXTENSION_STATUS {
  REQUEST = 'REQUEST',
  FILED = 'FILED',
  /**
   * @deprecated old tax filing extension status
   */
  AWAITING_DETAILS = 'AWAITING_DETAILS',
}

export enum FILED_TAX_EXTENSION_ANSWER {
  YES = 'Yes',
  NO = 'No',
}

export const EXTENSION_DEADLINE = new Date('2024-04-15');

export const DATE_FORMATS = {
  DB_FORMAT: 'yyyy-MM-dd',
  DISPLAY_FORMAT: 'MMM d, yyyy',
  DISPLAY_FORMAT_2: 'do MMM',
  DEFAULT_FORMAT: "yyyy-MM-dd'T'HH:mm:ssxxx",
  TIME_FORMAT: 'HH:mm',
  DAY_OF_MONTH: 'do',
  RANGE_FORMAT: "MMM d ''yy",
};

export const BOOLEAN_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const IP_PIN_LENGTH = 6;
export const STATE_COUNTY_TO_CODE: {
  [state: string]: {[county: string]: number};
} = {
  California: {
    'Marin County': 6041,
    'San Benito County': 6069,
    'Napa County': 6055,
    'Santa Clara County': 6085,
    'Orange County': 6059,
    'Santa Cruz County': 6087,
    'Amador County': 6005,
    'Kings County': 6031,
    'Mono County': 6051,
    'Contra Costa County': 6013,
    'Modoc County': 6049,
    'Riverside County': 6065,
    'Colusa County': 6011,
    'Inyo County': 6027,
    'Yuba County': 6115,
    'Santa Barbara County': 6083,
    'San Bernardino County': 6071,
    'Alpine County': 6003,
    'Del Norte County': 6015,
    'Sierra County': 6091,
    'Monterey County': 6053,
    'Sacramento County': 6067,
    'Trinity County': 6105,
    'Yolo County': 6113,
    'Sutter County': 6101,
    'Kern County': 6029,
    'Lake County': 6033,
    'San Francisco County': 6075,
    'Tulare County': 6107,
    'Fresno County': 6019,
    'Mendocino County': 6045,
    'Stanislaus County': 6099,
    'El Dorado County': 6017,
    'Humboldt County': 6023,
    'Tehama County': 6103,
    'Calaveras County': 6009,
    'Glenn County': 6021,
    'Madera County': 6039,
    'Merced County': 6047,
    'Plumas County': 6063,
    'Mariposa County': 6043,
    'Shasta County': 6089,
    'Sonoma County': 6097,
    'Lassen County': 6035,
    'Placer County': 6061,
    'Siskiyou County': 6093,
    'Butte County': 6007,
    'Imperial County': 6025,
    'San Diego County': 6073,
    'San Luis Obispo County': 6079,
    'Tuolumne County': 6109,
    'Alameda County': 6001,
    'Nevada County': 6057,
    'San Mateo County': 6081,
    'Solano County': 6095,
    'Los Angeles County': 6037,
    'San Joaquin County': 6077,
    'Ventura County': 6111,
  },
  Illinois: {
    'Jersey County': 17083,
    'Stephenson County': 17177,
    'Champaign County': 17019,
    'Christian County': 17021,
    'Hamilton County': 17065,
    'Henry County': 17073,
    'Scott County': 17171,
    'Marion County': 17121,
    'Logan County': 17107,
    'Carroll County': 17015,
    'Cumberland County': 17035,
    'Hancock County': 17067,
    'Kendall County': 17093,
    'Mason County': 17125,
    'Edgar County': 17045,
    'Greene County': 17061,
    'Lake County': 17097,
    'Marshall County': 17123,
    'Perry County': 17145,
    'Tazewell County': 17179,
    'DeKalb County': 17037,
    'Piatt County': 17147,
    'Washington County': 17189,
    'Clay County': 17025,
    'Effingham County': 17049,
    'Jo Daviess County': 17085,
    'Livingston County': 17105,
    'Morgan County': 17137,
    'Sangamon County': 17167,
    'Fulton County': 17057,
    'McHenry County': 17111,
    'Moultrie County': 17139,
    'Winnebago County': 17201,
    'Franklin County': 17055,
    'Putnam County': 17155,
    'Wayne County': 17191,
    'Coles County': 17029,
    'Gallatin County': 17059,
    'Jasper County': 17079,
    'Madison County': 17119,
    'Pope County': 17151,
    'Alexander County': 17003,
    'Bond County': 17005,
    'Randolph County': 17157,
    'Wabash County': 17185,
    'Brown County': 17009,
    'Douglas County': 17041,
    'Macon County': 17115,
    'Macoupin County': 17117,
    'St. Clair County': 17163,
    'McDonough County': 17109,
    'Saline County': 17165,
    'Vermilion County': 17183,
    'Calhoun County': 17013,
    'Cook County': 17031,
    'Grundy County': 17063,
    'Hardin County': 17069,
    'De Witt County': 17039,
    'DuPage County': 17043,
    'Iroquois County': 17075,
    'Jackson County': 17077,
    'Lee County': 17103,
    'Union County': 17181,
    'Fayette County': 17051,
    'Kankakee County': 17091,
    'Bureau County': 17011,
    'Henderson County': 17071,
    'Jefferson County': 17081,
    'Adams County': 17001,
    'Boone County': 17007,
    'Edwards County': 17047,
    'Knox County': 17095,
    'Clinton County': 17027,
    'Monroe County': 17133,
    'Ford County': 17053,
    'LaSalle County': 17099,
    'Pike County': 17149,
    'Shelby County': 17173,
    'Will County': 17197,
    'Williamson County': 17199,
    'Mercer County': 17131,
    'Montgomery County': 17135,
    'Rock Island County': 17161,
    'Kane County': 17089,
    'Peoria County': 17143,
    'Menard County': 17129,
    'Ogle County': 17141,
    'Stark County': 17175,
    'White County': 17193,
    'Crawford County': 17033,
    'Massac County': 17127,
    'Pulaski County': 17153,
    'Richland County': 17159,
    'Schuyler County': 17169,
    'Cass County': 17017,
    'Clark County': 17023,
    'McLean County': 17113,
    'Johnson County': 17087,
    'Lawrence County': 17101,
    'Warren County': 17187,
    'Whiteside County': 17195,
    'Woodford County': 17203,
  },
  Indiana: {
    'Carroll County': 18015,
    'Howard County': 18067,
    'Jasper County': 18073,
    'St. Joseph County': 18141,
    'Scott County': 18143,
    'Fountain County': 18045,
    'Greene County': 18055,
    'Harrison County': 18061,
    'Montgomery County': 18107,
    'Ripley County': 18137,
    'Switzerland County': 18155,
    'Brown County': 18013,
    'Cass County': 18017,
    'Delaware County': 18035,
    'Spencer County': 18147,
    'Warrick County': 18173,
    'Allen County': 18003,
    'Washington County': 18175,
    'Morgan County': 18109,
    'Newton County': 18111,
    'Parke County': 18121,
    'Rush County': 18139,
    'Vigo County': 18167,
    'Clark County': 18019,
    'Fayette County': 18041,
    'Knox County': 18083,
    'LaPorte County': 18091,
    'Ohio County': 18115,
    'Starke County': 18149,
    'Benton County': 18007,
    'DeKalb County': 18033,
    'Orange County': 18117,
    'Pike County': 18125,
    'Hancock County': 18059,
    'Madison County': 18095,
    'Franklin County': 18047,
    'Jennings County': 18079,
    'Posey County': 18129,
    'Shelby County': 18145,
    'Tippecanoe County': 18157,
    'Kosciusko County': 18085,
    'Owen County': 18119,
    'Vermillion County': 18165,
    'Wells County': 18179,
    'Marshall County': 18099,
    'Perry County': 18123,
    'Vanderburgh County': 18163,
    'Floyd County': 18043,
    'Noble County': 18113,
    'Porter County': 18127,
    'Fulton County': 18049,
    'Blackford County': 18009,
    'Boone County': 18011,
    'Decatur County': 18031,
    'Elkhart County': 18039,
    'Miami County': 18103,
    'Monroe County': 18105,
    'Union County': 18161,
    'Adams County': 18001,
    'Dearborn County': 18029,
    'Huntington County': 18069,
    'Jefferson County': 18077,
    'Lake County': 18089,
    'Martin County': 18101,
    'Wayne County': 18177,
    'White County': 18181,
    'Bartholomew County': 18005,
    'Putnam County': 18133,
    'Clay County': 18021,
    'Clinton County': 18023,
    'Johnson County': 18081,
    'Wabash County': 18169,
    'Dubois County': 18037,
    'Sullivan County': 18153,
    'Tipton County': 18159,
    'Grant County': 18053,
    'Jackson County': 18071,
    'Steuben County': 18151,
    'Daviess County': 18027,
    'Hamilton County': 18057,
    'LaGrange County': 18087,
    'Lawrence County': 18093,
    'Pulaski County': 18131,
    'Jay County': 18075,
    'Whitley County': 18183,
    'Gibson County': 18051,
    'Randolph County': 18135,
    'Warren County': 18171,
    'Crawford County': 18025,
    'Hendricks County': 18063,
    'Henry County': 18065,
    'Marion County': 18097,
  },
  Iowa: {
    'Iowa County': 19095,
    'Polk County': 19153,
    'Ringgold County': 19159,
    'Clayton County': 19043,
    'Davis County': 19051,
    'Floyd County': 19067,
    'Kossuth County': 19109,
    'Muscatine County': 19139,
    'Winnebago County': 19189,
    'Delaware County': 19055,
    'Henry County': 19087,
    'Webster County': 19187,
    'Worth County': 19195,
    'Wright County': 19197,
    'Dickinson County': 19059,
    'Emmet County': 19063,
    'Franklin County': 19069,
    'Monona County': 19133,
    'Woodbury County': 19193,
    'Harrison County': 19085,
    'Ida County': 19093,
    'Jackson County': 19097,
    'Poweshiek County': 19157,
    'Louisa County': 19115,
    'Madison County': 19121,
    'Osceola County': 19143,
    'Palo Alto County': 19147,
    'Pottawattamie County': 19155,
    'Adair County': 19001,
    'Decatur County': 19053,
    'Fremont County': 19071,
    'Greene County': 19073,
    'Mills County': 19129,
    'Benton County': 19011,
    'Black Hawk County': 19013,
    'Bremer County': 19017,
    'Clay County': 19041,
    'Adams County': 19003,
    'Allamakee County': 19005,
    'Cherokee County': 19035,
    'Sac County': 19161,
    'Appanoose County': 19007,
    'Warren County': 19181,
    'Wayne County': 19185,
    'Hancock County': 19081,
    'Jones County': 19105,
    'Wapello County': 19179,
    'Buchanan County': 19019,
    'Hardin County': 19083,
    'Mahaska County': 19123,
    'Taylor County': 19173,
    'Union County': 19175,
    'Cerro Gordo County': 19033,
    'Clarke County': 19039,
    "O'Brien County": 19141,
    'Buena Vista County': 19021,
    'Chickasaw County': 19037,
    'Fayette County': 19065,
    'Hamilton County': 19079,
    'Mitchell County': 19131,
    'Grundy County': 19075,
    'Guthrie County': 19077,
    'Jasper County': 19099,
    'Jefferson County': 19101,
    'Lee County': 19111,
    'Lucas County': 19117,
    'Winneshiek County': 19191,
    'Audubon County': 19009,
    'Dubuque County': 19061,
    'Humboldt County': 19091,
    'Cedar County': 19031,
    'Keokuk County': 19107,
    'Boone County': 19015,
    'Calhoun County': 19025,
    'Cass County': 19029,
    'Lyon County': 19119,
    'Marion County': 19125,
    'Marshall County': 19127,
    'Story County': 19169,
    'Butler County': 19023,
    'Scott County': 19163,
    'Shelby County': 19165,
    'Carroll County': 19027,
    'Crawford County': 19047,
    'Des Moines County': 19057,
    'Pocahontas County': 19151,
    'Sioux County': 19167,
    'Van Buren County': 19177,
    'Clinton County': 19045,
    'Howard County': 19089,
    'Monroe County': 19135,
    'Plymouth County': 19149,
    'Tama County': 19171,
    'Dallas County': 19049,
    'Johnson County': 19103,
    'Linn County': 19113,
    'Montgomery County': 19137,
    'Page County': 19145,
    'Washington County': 19183,
  },
  Kansas: {
    'Douglas County': 20045,
    'Edwards County': 20047,
    'McPherson County': 20113,
    'Ness County': 20135,
    'Clay County': 20027,
    'Decatur County': 20039,
    'Hamilton County': 20075,
    'Kingman County': 20095,
    'Nemaha County': 20131,
    'Osage County': 20139,
    'Osborne County': 20141,
    'Allen County': 20001,
    'Chase County': 20017,
    'Harper County': 20077,
    'Labette County': 20099,
    'Norton County': 20137,
    'Pottawatomie County': 20149,
    'Woodson County': 20207,
    'Crawford County': 20037,
    'Wichita County': 20203,
    'Brown County': 20013,
    'Gove County': 20063,
    'Greenwood County': 20073,
    'Jackson County': 20085,
    'Cloud County': 20029,
    'Coffey County': 20031,
    'Meade County': 20119,
    'Butler County': 20015,
    'Ford County': 20057,
    'Grant County': 20067,
    'Montgomery County': 20125,
    'Ellsworth County': 20053,
    'Greeley County': 20071,
    'Kiowa County': 20097,
    'Lane County': 20101,
    'Phillips County': 20147,
    'Pratt County': 20151,
    'Riley County': 20161,
    'Stanton County': 20187,
    'Chautauqua County': 20019,
    'Elk County': 20049,
    'Haskell County': 20081,
    'Morton County': 20129,
    'Pawnee County': 20145,
    'Rush County': 20165,
    'Cherokee County': 20021,
    'Clark County': 20025,
    'Ellis County': 20051,
    'Lincoln County': 20105,
    'Miami County': 20121,
    'Wyandotte County': 20209,
    'Shawnee County': 20177,
    'Atchison County': 20005,
    'Barber County': 20007,
    'Bourbon County': 20011,
    'Marshall County': 20117,
    'Mitchell County': 20123,
    'Republic County': 20157,
    'Lyon County': 20111,
    'Thomas County': 20193,
    'Trego County': 20195,
    'Barton County': 20009,
    'Hodgeman County': 20083,
    'Jewell County': 20089,
    'Rice County': 20159,
    'Scott County': 20171,
    'Dickinson County': 20041,
    'Jefferson County': 20087,
    'Morris County': 20127,
    'Saline County': 20169,
    'Russell County': 20167,
    'Wabaunsee County': 20197,
    'Cheyenne County': 20023,
    'Finney County': 20055,
    'Franklin County': 20059,
    'Graham County': 20065,
    'Harvey County': 20079,
    'Johnson County': 20091,
    'Linn County': 20107,
    'Neosho County': 20133,
    'Rawlins County': 20153,
    'Sheridan County': 20179,
    'Stafford County': 20185,
    'Doniphan County': 20043,
    'Geary County': 20061,
    'Gray County': 20069,
    'Marion County': 20115,
    'Ottawa County': 20143,
    'Rooks County': 20163,
    'Wilson County': 20205,
    'Anderson County': 20003,
    'Reno County': 20155,
    'Sedgwick County': 20173,
    'Wallace County': 20199,
    'Washington County': 20201,
    'Cowley County': 20035,
    'Kearny County': 20093,
    'Leavenworth County': 20103,
    'Seward County': 20175,
    'Sherman County': 20181,
    'Stevens County': 20189,
    'Comanche County': 20033,
    'Logan County': 20109,
    'Smith County': 20183,
    'Sumner County': 20191,
  },
  Mississippi: {
    'Benton County': 28009,
    'Hancock County': 28045,
    'Rankin County': 28121,
    'Claiborne County': 28021,
    'Monroe County': 28095,
    'Alcorn County': 28003,
    'Covington County': 28031,
    'Holmes County': 28051,
    'Lamar County': 28073,
    'Lee County': 28081,
    'Issaquena County': 28055,
    'Tippah County': 28139,
    'Sharkey County': 28125,
    'Stone County': 28131,
    'Walthall County': 28147,
    'Winston County': 28159,
    'DeSoto County': 28033,
    'Forrest County': 28035,
    'Oktibbeha County': 28105,
    'Tate County': 28137,
    'Tunica County': 28143,
    'Warren County': 28149,
    'George County': 28039,
    'Jones County': 28067,
    'Pike County': 28113,
    'Washington County': 28151,
    'Lauderdale County': 28075,
    'Panola County': 28107,
    'Amite County': 28005,
    'Lafayette County': 28071,
    'Leflore County': 28083,
    'Marshall County': 28093,
    'Quitman County': 28119,
    'Clay County': 28025,
    'Simpson County': 28127,
    'Coahoma County': 28027,
    'Itawamba County': 28057,
    'Webster County': 28155,
    'Harrison County': 28047,
    'Union County': 28145,
    'Copiah County': 28029,
    'Smith County': 28129,
    'Wayne County': 28153,
    'Carroll County': 28015,
    'Franklin County': 28037,
    'Humphreys County': 28053,
    'Lincoln County': 28085,
    'Tallahatchie County': 28135,
    'Kemper County': 28069,
    'Yazoo County': 28163,
    'Chickasaw County': 28017,
    'Lowndes County': 28087,
    'Yalobusha County': 28161,
    'Greene County': 28041,
    'Pearl River County': 28109,
    'Prentiss County': 28117,
    'Adams County': 28001,
    'Jackson County': 28059,
    'Marion County': 28091,
    'Neshoba County': 28099,
    'Newton County': 28101,
    'Tishomingo County': 28141,
    'Madison County': 28089,
    'Montgomery County': 28097,
    'Pontotoc County': 28115,
    'Attala County': 28007,
    'Hinds County': 28049,
    'Perry County': 28111,
    'Calhoun County': 28013,
    'Choctaw County': 28019,
    'Grenada County': 28043,
    'Jefferson County': 28063,
    'Wilkinson County': 28157,
    'Scott County': 28123,
    'Lawrence County': 28077,
    'Leake County': 28079,
    'Noxubee County': 28103,
    'Sunflower County': 28133,
    'Bolivar County': 28011,
    'Clarke County': 28023,
    'Jasper County': 28061,
    'Jefferson Davis County': 28065,
  },
  Ohio: {
    'Erie County': 39043,
    'Mercer County': 39107,
    'Allen County': 39003,
    'Belmont County': 39013,
    'Lake County': 39085,
    'Sandusky County': 39143,
    'Shelby County': 39149,
    'Summit County': 39153,
    'Wood County': 39173,
    'Huron County': 39077,
    'Jefferson County': 39081,
    'Franklin County': 39049,
    'Logan County': 39091,
    'Preble County': 39135,
    'Tuscarawas County': 39157,
    'Union County': 39159,
    'Washington County': 39167,
    'Adams County': 39001,
    'Fulton County': 39051,
    'Perry County': 39127,
    'Stark County': 39151,
    'Brown County': 39015,
    'Clark County': 39023,
    'Cuyahoga County': 39035,
    'Gallia County': 39053,
    'Hamilton County': 39061,
    'Knox County': 39083,
    'Montgomery County': 39113,
    'Noble County': 39121,
    'Richland County': 39139,
    'Scioto County': 39145,
    'Hancock County': 39063,
    'Hardin County': 39065,
    'Pickaway County': 39129,
    'Harrison County': 39067,
    'Putnam County': 39137,
    'Columbiana County': 39029,
    'Madison County': 39097,
    'Crawford County': 39033,
    'Greene County': 39057,
    'Morrow County': 39117,
    'Williams County': 39171,
    'Delaware County': 39041,
    'Holmes County': 39075,
    'Lawrence County': 39087,
    'Warren County': 39165,
    'Wyandot County': 39175,
    'Carroll County': 39019,
    'Lucas County': 39095,
    'Pike County': 39131,
    'Geauga County': 39055,
    'Ashtabula County': 39007,
    'Clinton County': 39027,
    'Trumbull County': 39155,
    'Clermont County': 39025,
    'Jackson County': 39079,
    'Defiance County': 39039,
    'Guernsey County': 39059,
    'Hocking County': 39073,
    'Medina County': 39103,
    'Wayne County': 39169,
    'Auglaize County': 39011,
    'Coshocton County': 39031,
    'Miami County': 39109,
    'Morgan County': 39115,
    'Ottawa County': 39123,
    'Licking County': 39089,
    'Marion County': 39101,
    'Muskingum County': 39119,
    'Portage County': 39133,
    'Lorain County': 39093,
    'Paulding County': 39125,
    'Butler County': 39017,
    'Highland County': 39071,
    'Mahoning County': 39099,
    'Champaign County': 39021,
    'Fairfield County': 39045,
    'Fayette County': 39047,
    'Meigs County': 39105,
    'Monroe County': 39111,
    'Athens County': 39009,
    'Darke County': 39037,
    'Ross County': 39141,
    'Vinton County': 39163,
    'Ashland County': 39005,
    'Henry County': 39069,
    'Seneca County': 39147,
    'Van Wert County': 39161,
  },
  Pennsylvania: {
    'Berks County': 42011,
    'Mifflin County': 42087,
    'Schuylkill County': 42107,
    'Bradford County': 42015,
    'Cumberland County': 42041,
    'Huntingdon County': 42061,
    'Snyder County': 42109,
    'Susquehanna County': 42115,
    'Union County': 42119,
    'Wyoming County': 42131,
    'Beaver County': 42007,
    'Erie County': 42049,
    'Juniata County': 42067,
    'Cameron County': 42023,
    'Columbia County': 42037,
    'Delaware County': 42045,
    'McKean County': 42083,
    'Venango County': 42121,
    'York County': 42133,
    'Cambria County': 42021,
    'Potter County': 42105,
    'Clearfield County': 42033,
    'Centre County': 42027,
    'Clarion County': 42031,
    'Dauphin County': 42043,
    'Luzerne County': 42079,
    'Pike County': 42103,
    'Armstrong County': 42005,
    'Carbon County': 42025,
    'Clinton County': 42035,
    'Greene County': 42059,
    'Lancaster County': 42071,
    'Washington County': 42125,
    'Forest County': 42053,
    'Lycoming County': 42081,
    'Bedford County': 42009,
    'Northumberland County': 42097,
    'Philadelphia County': 42101,
    'Sullivan County': 42113,
    'Blair County': 42013,
    'Bucks County': 42017,
    'Franklin County': 42055,
    'Butler County': 42019,
    'Fulton County': 42057,
    'Lehigh County': 42077,
    'Monroe County': 42089,
    'Westmoreland County': 42129,
    'Adams County': 42001,
    'Fayette County': 42051,
    'Montour County': 42093,
    'Indiana County': 42063,
    'Lawrence County': 42073,
    'Lebanon County': 42075,
    'Mercer County': 42085,
    'Chester County': 42029,
    'Elk County': 42047,
    'Somerset County': 42111,
    'Jefferson County': 42065,
    'Allegheny County': 42003,
    'Lackawanna County': 42069,
    'Montgomery County': 42091,
    'Northampton County': 42095,
    'Wayne County': 42127,
    'Crawford County': 42039,
    'Perry County': 42099,
    'Tioga County': 42117,
    'Warren County': 42123,
  },
  'South Carolina': {
    'Jasper County': 45053,
    'Kershaw County': 45055,
    'Laurens County': 45059,
    'Dillon County': 45033,
    'Spartanburg County': 45083,
    'Newberry County': 45071,
    'Pickens County': 45077,
    'Charleston County': 45019,
    'Florence County': 45041,
    'Allendale County': 45005,
    'Calhoun County': 45017,
    'Marlboro County': 45069,
    'Cherokee County': 45021,
    'Greenville County': 45045,
    'Saluda County': 45081,
    'Chester County': 45023,
    'Lancaster County': 45057,
    'Union County': 45087,
    'Greenwood County': 45047,
    'Chesterfield County': 45025,
    'Georgetown County': 45043,
    'Anderson County': 45007,
    'Clarendon County': 45027,
    'McCormick County': 45065,
    'Bamberg County': 45009,
    'Fairfield County': 45039,
    'Oconee County': 45073,
    'Beaufort County': 45013,
    'Lee County': 45061,
    'Lexington County': 45063,
    'Marion County': 45067,
    'Darlington County': 45031,
    'Richland County': 45079,
    'Aiken County': 45003,
    'Williamsburg County': 45089,
    'Abbeville County': 45001,
    'Dorchester County': 45035,
    'Edgefield County': 45037,
    'Horry County': 45051,
    'York County': 45091,
    'Orangeburg County': 45075,
    'Hampton County': 45049,
    'Sumter County': 45085,
    'Barnwell County': 45011,
    'Berkeley County': 45015,
    'Colleton County': 45029,
  },
  Missouri: {
    'Ray County': 29177,
    'Washington County': 29221,
    'Bollinger County': 29017,
    'Clark County': 29045,
    'Crawford County': 29055,
    'Dade County': 29057,
    'Pemiscot County': 29155,
    'Christian County': 29043,
    'Lincoln County': 29113,
    'Mississippi County': 29133,
    'New Madrid County': 29143,
    'Osage County': 29151,
    'Carter County': 29035,
    'Vernon County': 29217,
    'Holt County': 29087,
    'Lafayette County': 29107,
    'Shelby County': 29205,
    'Cole County': 29051,
    'Iron County': 29093,
    'Jasper County': 29097,
    'Macon County': 29121,
    'Worth County': 29227,
    'Harrison County': 29081,
    'Stoddard County': 29207,
    'Cass County': 29037,
    'Jackson County': 29095,
    'Stone County': 29209,
    'Andrew County': 29003,
    'Boone County': 29019,
    'Clay County': 29047,
    'Clinton County': 29049,
    'McDonald County': 29119,
    'Monroe County': 29137,
    'Nodaway County': 29147,
    'Platte County': 29165,
    'Sullivan County': 29211,
    'Madison County': 29123,
    'Montgomery County': 29139,
    'Pettis County': 29159,
    'Schuyler County': 29197,
    'Cape Girardeau County': 29031,
    'Scotland County': 29199,
    'Camden County': 29029,
    'Carroll County': 29033,
    'Douglas County': 29067,
    'Linn County': 29115,
    'Maries County': 29125,
    'Perry County': 29157,
    'Pulaski County': 29169,
    'Shannon County': 29203,
    'Texas County': 29215,
    'Benton County': 29015,
    'Dunklin County': 29069,
    'Laclede County': 29105,
    'Pike County': 29163,
    'Scott County': 29201,
    'Wayne County': 29223,
    'Cedar County': 29039,
    'Chariton County': 29041,
    'Daviess County': 29061,
    'Dent County': 29065,
    'Greene County': 29077,
    'Hickory County': 29085,
    'Knox County': 29103,
    'Newton County': 29145,
    'Saline County': 29195,
    'Bates County': 29013,
    'Henry County': 29083,
    'Marion County': 29127,
    'Miller County': 29131,
    'Morgan County': 29141,
    'Reynolds County': 29179,
    'Adair County': 29001,
    'Randolph County': 29175,
    'Webster County': 29225,
    'St. Clair County': 29185,
    'Cooper County': 29053,
    'Grundy County': 29079,
    'Livingston County': 29117,
    'St. Charles County': 29183,
    'Barton County': 29011,
    'Howard County': 29089,
    'Ripley County': 29181,
    'Buchanan County': 29021,
    'Dallas County': 29059,
    'Lawrence County': 29109,
    'Mercer County': 29129,
    'Putnam County': 29171,
    'Taney County': 29213,
    'Franklin County': 29071,
    'St. Francois County': 29187,
    'St. Louis County': 29189,
    'Atchison County': 29005,
    'Barry County': 29009,
    'Gentry County': 29075,
    'Oregon County': 29149,
    'Ozark County': 29153,
    'Ste. Genevieve County': 29186,
    'Gasconade County': 29073,
    'Moniteau County': 29135,
    'Phelps County': 29161,
    'Wright County': 29229,
    'Callaway County': 29027,
    'DeKalb County': 29063,
    'Polk County': 29167,
    'Audrain County': 29007,
    'Caldwell County': 29025,
    'Butler County': 29023,
    'Howell County': 29091,
    'Jefferson County': 29099,
    'Lewis County': 29111,
    'Ralls County': 29173,
    'St. Louis city': 29510,
    'Johnson County': 29101,
    'Warren County': 29219,
  },
  Nebraska: {
    'Scotts Bluff County': 31157,
    'Arthur County': 31005,
    'Boyd County': 31015,
    'Franklin County': 31061,
    'Kimball County': 31105,
    'Cheyenne County': 31033,
    'Holt County': 31089,
    'Red Willow County': 31145,
    'Gage County': 31067,
    'Lincoln County': 31111,
    'Adams County': 31001,
    'Dixon County': 31051,
    'Hall County': 31079,
    'Perkins County': 31135,
    'Richardson County': 31147,
    'Dodge County': 31053,
    'Fillmore County': 31059,
    'Garfield County': 31071,
    'Johnson County': 31097,
    'Lancaster County': 31109,
    'Washington County': 31177,
    'Antelope County': 31003,
    'Dawson County': 31047,
    'Grant County': 31075,
    'Madison County': 31119,
    'Nance County': 31125,
    'Nuckolls County': 31129,
    'Boone County': 31011,
    'Custer County': 31041,
    'Cuming County': 31039,
    'Harlan County': 31083,
    'Platte County': 31141,
    'Clay County': 31035,
    'Colfax County': 31037,
    'Gosper County': 31073,
    'Valley County': 31175,
    'Cass County': 31025,
    'Otoe County': 31131,
    'Stanton County': 31167,
    'Brown County': 31017,
    'Kearney County': 31099,
    'Polk County': 31143,
    'Sheridan County': 31161,
    'Cedar County': 31027,
    'Sarpy County': 31153,
    'Wayne County': 31179,
    'Deuel County': 31049,
    'Hayes County': 31085,
    'Blaine County': 31009,
    'Box Butte County': 31013,
    'Frontier County': 31063,
    'Hooker County': 31091,
    'Merrick County': 31121,
    'Thayer County': 31169,
    'Chase County': 31029,
    'Dundy County': 31057,
    'Furnas County': 31065,
    'Nemaha County': 31127,
    'Rock County': 31149,
    'Thomas County': 31171,
    'Banner County': 31007,
    'Douglas County': 31055,
    'Knox County': 31107,
    'Seward County': 31159,
    'Thurston County': 31173,
    'Saline County': 31151,
    'Garden County': 31069,
    'Hitchcock County': 31087,
    'Logan County': 31113,
    'Pawnee County': 31133,
    'Saunders County': 31155,
    'Burt County': 31021,
    'Cherry County': 31031,
    'Howard County': 31093,
    'Jefferson County': 31095,
    'McPherson County': 31117,
    'York County': 31185,
    'Buffalo County': 31019,
    'Dakota County': 31043,
    'Keith County': 31101,
    'Loup County': 31115,
    'Pierce County': 31139,
    'Sioux County': 31165,
    'Wheeler County': 31183,
    'Morrill County': 31123,
    'Keya Paha County': 31103,
    'Sherman County': 31163,
    'Greeley County': 31077,
    'Dawes County': 31045,
    'Phelps County': 31137,
    'Webster County': 31181,
    'Butler County': 31023,
    'Hamilton County': 31081,
  },
  'New Jersey': {
    'Gloucester County': 34015,
    'Ocean County': 34029,
    'Warren County': 34041,
    'Cape May County': 34009,
    'Mercer County': 34021,
    'Camden County': 34007,
    'Monmouth County': 34025,
    'Somerset County': 34035,
    'Atlantic County': 34001,
    'Cumberland County': 34011,
    'Essex County': 34013,
    'Middlesex County': 34023,
    'Passaic County': 34031,
    'Burlington County': 34005,
    'Morris County': 34027,
    'Sussex County': 34037,
    'Union County': 34039,
    'Hudson County': 34017,
    'Hunterdon County': 34019,
    'Salem County': 34033,
    'Bergen County': 34003,
  },
  'New York': {
    'Allegany County': 36003,
    'Franklin County': 36033,
    'Greene County': 36039,
    'Montgomery County': 36057,
    'Sullivan County': 36105,
    'Oneida County': 36065,
    'Putnam County': 36079,
    'Ulster County': 36111,
    'Cayuga County': 36011,
    'Wyoming County': 36121,
    'Kings County': 36047,
    'Livingston County': 36051,
    'Oswego County': 36075,
    'Queens County': 36081,
    'Tompkins County': 36109,
    'Erie County': 36029,
    'St. Lawrence County': 36089,
    'Rockland County': 36087,
    'Madison County': 36053,
    'Warren County': 36113,
    'Richmond County': 36085,
    'Essex County': 36031,
    'Schoharie County': 36095,
    'Genesee County': 36037,
    'Herkimer County': 36043,
    'Nassau County': 36059,
    'Albany County': 36001,
    'Steuben County': 36101,
    'Cattaraugus County': 36009,
    'Chenango County': 36017,
    'Fulton County': 36035,
    'Monroe County': 36055,
    'Schenectady County': 36093,
    'Tioga County': 36107,
    'Wayne County': 36117,
    'Westchester County': 36119,
    'Orange County': 36071,
    'Otsego County': 36077,
    'Seneca County': 36099,
    'New York County': 36061,
    'Washington County': 36115,
    'Yates County': 36123,
    'Chemung County': 36015,
    'Columbia County': 36021,
    'Hamilton County': 36041,
    'Lewis County': 36049,
    'Broome County': 36007,
    'Clinton County': 36019,
    'Cortland County': 36023,
    'Saratoga County': 36091,
    'Schuyler County': 36097,
    'Ontario County': 36069,
    'Rensselaer County': 36083,
    'Delaware County': 36025,
    'Onondaga County': 36067,
    'Jefferson County': 36045,
    'Orleans County': 36073,
    'Bronx County': 36005,
    'Chautauqua County': 36013,
    'Niagara County': 36063,
    'Suffolk County': 36103,
    'Dutchess County': 36027,
  },
  'North Carolina': {
    'Alexander County': 37003,
    'Avery County': 37011,
    'Burke County': 37023,
    'Clay County': 37043,
    'Madison County': 37115,
    'Pasquotank County': 37139,
    'Pender County': 37141,
    'Cabarrus County': 37025,
    'Martin County': 37117,
    'Alamance County': 37001,
    'Camden County': 37029,
    'Gaston County': 37071,
    'Orange County': 37135,
    'Rockingham County': 37157,
    'Stokes County': 37169,
    'Bertie County': 37015,
    'Buncombe County': 37021,
    'Mecklenburg County': 37119,
    'Pamlico County': 37137,
    'Robeson County': 37155,
    'Currituck County': 37053,
    'Davidson County': 37057,
    'Davie County': 37059,
    'Granville County': 37077,
    'Mitchell County': 37121,
    'Chatham County': 37037,
    'Iredell County': 37097,
    'Johnston County': 37101,
    'Moore County': 37125,
    'Yadkin County': 37197,
    'Franklin County': 37069,
    'Gates County': 37073,
    'Polk County': 37149,
    'Randolph County': 37151,
    'Wayne County': 37191,
    'Wilkes County': 37193,
    'Cherokee County': 37039,
    'Durham County': 37063,
    'McDowell County': 37111,
    'Wake County': 37183,
    'Brunswick County': 37019,
    'Edgecombe County': 37065,
    'Transylvania County': 37175,
    'Hoke County': 37093,
    'Caldwell County': 37027,
    'Halifax County': 37083,
    'Surry County': 37171,
    'Craven County': 37049,
    'Pitt County': 37147,
    'Rowan County': 37159,
    'Dare County': 37055,
    'Hyde County': 37095,
    'Macon County': 37113,
    'Nash County': 37127,
    'Harnett County': 37085,
    'Wilson County': 37195,
    'Yancey County': 37199,
    'Beaufort County': 37013,
    'Caswell County': 37033,
    'Lee County': 37105,
    'Montgomery County': 37123,
    'Richmond County': 37153,
    'Stanly County': 37167,
    'Watauga County': 37189,
    'Columbus County': 37047,
    'Jackson County': 37099,
    'Catawba County': 37035,
    'Cleveland County': 37045,
    'Rutherford County': 37161,
    'Ashe County': 37009,
    'Haywood County': 37087,
    'Hertford County': 37091,
    'Anson County': 37007,
    'Bladen County': 37017,
    'Sampson County': 37163,
    'Warren County': 37185,
    'Carteret County': 37031,
    'Guilford County': 37081,
    'New Hanover County': 37129,
    'Swain County': 37173,
    'Washington County': 37187,
    'Henderson County': 37089,
    'Chowan County': 37041,
    'Lincoln County': 37109,
    'Union County': 37179,
    'Greene County': 37079,
    'Scotland County': 37165,
    'Tyrrell County': 37177,
    'Alleghany County': 37005,
    'Cumberland County': 37051,
    'Jones County': 37103,
    'Graham County': 37075,
    'Lenoir County': 37107,
    'Northampton County': 37131,
    'Onslow County': 37133,
    'Duplin County': 37061,
    'Forsyth County': 37067,
    'Perquimans County': 37143,
    'Person County': 37145,
    'Vance County': 37181,
  },
  'West Virginia': {
    'Kanawha County': 54039,
    'Roane County': 54087,
    'Mineral County': 54057,
    'Nicholas County': 54067,
    'Pendleton County': 54071,
    'Monongalia County': 54061,
    'Raleigh County': 54081,
    'Webster County': 54101,
    'Lewis County': 54041,
    'Mercer County': 54055,
    'Morgan County': 54065,
    'Cabell County': 54011,
    'Fayette County': 54019,
    'Gilmer County': 54021,
    'Mason County': 54053,
    'Upshur County': 54097,
    'Clay County': 54015,
    'Taylor County': 54091,
    'Jefferson County': 54037,
    'Mingo County': 54059,
    'Pocahontas County': 54075,
    'Ritchie County': 54085,
    'Tyler County': 54095,
    'Pleasants County': 54073,
    'Barbour County': 54001,
    'Randolph County': 54083,
    'Harrison County': 54033,
    'Lincoln County': 54043,
    'Putnam County': 54079,
    'Wetzel County': 54103,
    'Jackson County': 54035,
    'Marshall County': 54051,
    'Marion County': 54049,
    'Berkeley County': 54003,
    'Braxton County': 54007,
    'Grant County': 54023,
    'Monroe County': 54063,
    'Wyoming County': 54109,
    'Boone County': 54005,
    'Hardy County': 54031,
    'Logan County': 54045,
    'McDowell County': 54047,
    'Ohio County': 54069,
    'Hancock County': 54029,
    'Tucker County': 54093,
    'Calhoun County': 54013,
    'Greenbrier County': 54025,
    'Hampshire County': 54027,
    'Preston County': 54077,
    'Wayne County': 54099,
    'Brooke County': 54009,
    'Wirt County': 54105,
    'Wood County': 54107,
    'Doddridge County': 54017,
    'Summers County': 54089,
  },
  Hawaii: {
    'Honolulu County': 15003,
    'Hawaii County': 15001,
    'Kalawao County': 15005,
    'Kauai County': 15007,
    'Maui County': 15009,
  },
};

const county_code_to_county_name: {[key: number]: string} = {};

Object.keys(STATE_COUNTY_TO_CODE).forEach((state) => {
  Object.keys(STATE_COUNTY_TO_CODE[state]).forEach((countyName: any) => {
    const code = STATE_COUNTY_TO_CODE[state][countyName];
    county_code_to_county_name[code] = countyName;
  });
});

export const COUNTY_CODE_TO_COUNTY_NAME = county_code_to_county_name;

export enum UserActivenessStatus {
  ACTIVE = 'ACTIVE',
  DORMANT = 'DORMANT',
}
