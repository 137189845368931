import {CheckCircleOutlineOutlined, SendSharp} from '@mui/icons-material';
import {Button, TextField, Typography} from '@mui/material';
import {useContext, useState} from 'react';
import Message from './Message';
import {makeStyles} from '@mui/styles';
import {themmeColor} from 'src/constants/constants';
import {
  CPA_REVIEW_THREAD_STATUS,
  CpaReviewMessage,
  CpaReviewThread,
} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {addMessage, setThreadStatus} from 'src/appApi';
import UserInfoContext from 'src/CpaCenterUserInfo/UserInfoContext';

const useStyles = makeStyles({
  container: {
    border: 'solid',
    borderRadius: '5px',
    margin: '15px',
    maxHeight: '90%',
  },
  header: {
    display: 'flex',
    background: ({status}) =>
      status === CPA_REVIEW_THREAD_STATUS.OPEN
        ? themmeColor.lightYellow
        : themmeColor.offWhite,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  headerText: {
    borderRadius: '43px',
    background: ({status}) =>
      status === CPA_REVIEW_THREAD_STATUS.OPEN
        ? themmeColor.statusFlyfinYellow
        : themmeColor.successGreen,
    padding: '4px 10px',
  },
  content: {
    position: 'relative',
    background: ({isEditing, status}) =>
      isEditing
        ? 'white'
        : status === CPA_REVIEW_THREAD_STATUS.OPEN
        ? themmeColor.lightYellow
        : themmeColor.offWhite,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    paddingBottom: '10px',
    maxHeight: '70%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  reply: {
    display: 'flex',
    alignItems: 'center',
    background: themmeColor.black,
    borderRadius: '20px',
    padding: '5px 10px',
  },
  addNote: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  resolvedFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    background: themmeColor.offWhite,
    borderRadius: '8px',
  },
});

const TextBox = ({styles, comment, setComment, addNewMessage}) => {
  return (
    <div className={styles.addNote}>
      <TextField
        onClick={(e) => e.stopPropagation()}
        variant="outlined"
        placeholder="Type your comment here"
        value={comment}
        type="text"
        multiline={true}
        rows={3}
        size="small"
        onChange={(event) => {
          setComment(event.target.value);
        }}
        style={{width: '85%'}}
      />
      <Button
        variant="contained"
        size="medium"
        style={{padding: '7.5px 0px', width: '10%', marginLeft: '10px'}}
        onClick={addNewMessage}>
        <SendSharp />
      </Button>
    </div>
  );
};

const ResolvedFooter = ({
  styles,
  threadData,
  onThreadStatusChange,
  threadId,
}) => {
  return (
    <div style={{padding: '10px'}}>
      <div className={styles.resolvedFooter}>
        <div>
          <Typography
            fontWeight={'400'}
            fontSize={'16px'}
            fontStyle={'Inter'}
            style={{color: themmeColor.grey}}>
            This conversation was resolved by
          </Typography>
          <Typography
            fontWeight={'700'}
            fontSize={'17px'}
            fontStyle={'Inter'}
            style={{color: themmeColor.grey}}>
            {threadData?.resolved_by_name}
          </Typography>
        </div>
        <Button
          variant="contained"
          size="medium"
          onClick={() =>
            onThreadStatusChange(CPA_REVIEW_THREAD_STATUS.OPEN, threadId)
          }
          style={{
            backgroundColor: themmeColor.white,
            border: `1px solid ${themmeColor.successGreen}`,
            borderRadius: '8px',
          }}>
          <Typography
            fontWeight={'600'}
            fontSize={'13px'}
            fontStyle={'Inter'}
            style={{color: themmeColor.successGreen}}>
            Re-Open
          </Typography>
        </Button>
      </div>
    </div>
  );
};

interface ThreadProps {
  threadData?: CpaReviewThread;
  isEditing: boolean;
  isLoading: boolean;
  setIsLoading: any;
  onThreadClick?: (id: number) => void;
  sendNewThread?: (comment: string) => void;
  setThreads: any;
}

const Thread: React.FC<ThreadProps> = ({
  threadData,
  isEditing = false,
  setIsLoading,
  sendNewThread = (comment: string) => {},
  onThreadClick = (id: number) => {},
  setThreads,
}) => {
  const {userInfo} = useContext(UserInfoContext);
  const [comment, setComment] = useState('');

  const addNewMessage = () => {
    if (comment) {
      if (threadData) {
        setIsLoading(true);
        const body = {
          thread_id: threadData.id,
          comment: comment,
        };
        addMessage(body)
          .then((res) => {
            const newMessage: CpaReviewMessage = res.data;
            setThreads((prevState) => {
              const thread = prevState[threadData.id];
              const messages = thread.messages;
              thread.messages = [...messages, newMessage];
              thread.resolved_by_name = userInfo.fly_user_name;

              const prevThreads = prevState;
              prevThreads[thread.id] = thread;

              return prevThreads;
            });
          })
          .catch((e) => {
            alert(`Error Occured ${e}`);
          })
          .finally(() => {
            setIsLoading(false);
            setComment('');
          });
      } else {
        sendNewThread(comment);
        setComment('');
      }
    } else {
      alert('Error : Need to provide message');
    }
  };

  const onThreadStatusChange = (
    status: CPA_REVIEW_THREAD_STATUS,
    threadId: number,
  ) => {
    const body = {
      status: status,
    };
    const params = {
      thread_id: threadId,
    };
    setIsLoading(true);
    setThreadStatus(body, params)
      .then((res) => {
        const resolvedByName = res.data.updated_by;
        setThreads((prevState) => {
          const thread = prevState[threadId];
          thread.status = status;
          if (status === CPA_REVIEW_THREAD_STATUS.RESOLVED)
            thread.resolved_by_name = resolvedByName;

          const prevThreads = prevState;
          prevThreads[threadId] = thread;

          return {...prevThreads};
        });
        setIsLoading(false);
      })
      .catch((e) => {
        alert(`Error Occured ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const styles = useStyles({
    status: threadData ? threadData.status : CPA_REVIEW_THREAD_STATUS.OPEN,
    isEditing: isEditing,
  });

  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        onThreadClick(threadData ? threadData.id : -1);
      }}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <Typography fontWeight={'600'} fontSize={'13px'} fontStyle={'Inter'}>
            {threadData
              ? threadData.status === CPA_REVIEW_THREAD_STATUS.OPEN
                ? 'Open'
                : 'Resolved'
              : 'Open'}
          </Typography>
        </div>
        {threadData && (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {threadData.unread_messages.length !== 0 && (
              <div className={styles.reply}>
                <Typography
                  fontWeight={'500'}
                  fontSize={'13px'}
                  fontStyle={'Inter'}
                  color={themmeColor.white}>
                  {`${threadData.unread_messages.length} Reply`}
                </Typography>
              </div>
            )}
            {threadData.status === CPA_REVIEW_THREAD_STATUS.OPEN && (
              <div
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() =>
                  onThreadStatusChange(
                    CPA_REVIEW_THREAD_STATUS.RESOLVED,
                    threadData.id,
                  )
                }>
                <CheckCircleOutlineOutlined
                  style={{color: themmeColor.successGreen}}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {!threadData ? (
          <Message key={`${-1}--1`} isCollapsed={!isEditing} />
        ) : isEditing ? (
          threadData.messages.map((message, messageIndex) => {
            return (
              <Message
                key={`${threadData.id}-${messageIndex}`}
                messageData={message}
                isCollapsed={!isEditing}
              />
            );
          })
        ) : (
          <Message
            key={`${threadData.id}-${-1}`}
            messageData={threadData.messages[0]}
            isCollapsed={!isEditing}
          />
        )}
      </div>
      <div>
        {!threadData || threadData.status === CPA_REVIEW_THREAD_STATUS.OPEN
          ? isEditing && (
              <TextBox
                comment={comment}
                setComment={setComment}
                styles={styles}
                addNewMessage={addNewMessage}
              />
            )
          : isEditing && (
              <ResolvedFooter
                styles={styles}
                threadData={threadData}
                onThreadStatusChange={onThreadStatusChange}
                threadId={threadData.id}
              />
            )}
      </div>
    </div>
  );
};

export default Thread;
