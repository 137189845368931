import {
  FORM_INPUT_TYPE,
  FormConfigProps,
} from 'src/DesignSystem/Form/Form.types';
import {VALIDATOR} from 'src/DesignSystem/Form/Form.validators';
import {BusinessDetailsField} from 'src/store/businessDetails/businessDetails.types';
import {
  BUSINESS_ACCOUNTING_METHOD_OPTIONS,
  BUSINESS_ENTITY_OPTIONS,
  OWNER_OR_PROPRIETOR_OPTIONS,
} from './EditBusinessDetails.constants';
import {BOOLEAN_OPTIONS} from 'src/constants/constants';
import {getPath} from 'src/common/utils';
import {TRANSFORMERS} from 'src/DesignSystem/Form/Forms.transformers';

export const BUSINESS_DETAILS_FIELD_CONFIG: FormConfigProps[] = [
  {
    title: 'Have EIN',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.have_ein,
    options: BOOLEAN_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.VALIDATE_BOOLEAN],
  },
  {
    title: 'Business entity',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.entity_type,
    options: BUSINESS_ENTITY_OPTIONS,
    validators: [VALIDATOR.NON_NULL_VALUE],
  },
  {
    title: 'Business name',
    inputType: FORM_INPUT_TYPE.String,
    path: BusinessDetailsField.name,
    validators: [VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Type of Business',
    inputType: FORM_INPUT_TYPE.Profession,
    path: BusinessDetailsField.professions,
    isSingle: false,
    validators: [VALIDATOR.NON_EMPTY_ARRAY],
  },
  {
    title: 'Owner or proprietor',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.owner_or_proprietor,
    options: OWNER_OR_PROPRIETOR_OPTIONS,
  },
  {
    title: 'Business EIN',
    inputType: FORM_INPUT_TYPE.String,
    path: BusinessDetailsField.ein,
    validators: [VALIDATOR.NON_EMPTY_STRING, VALIDATOR.VALIDATE_EIN],
    transformers: TRANSFORMERS.EIN,
  },
  {
    title: 'Business start date',
    inputType: FORM_INPUT_TYPE.Date,
    path: BusinessDetailsField.start_date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Business Incorporation date',
    inputType: FORM_INPUT_TYPE.Date,
    path: BusinessDetailsField.incorporation_date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'S-corp effective date',
    inputType: FORM_INPUT_TYPE.Date,
    path: BusinessDetailsField.s_corp_effective_date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
  {
    title: 'Phone number',
    inputType: FORM_INPUT_TYPE.String,
    path: BusinessDetailsField.phone_number,
    validators: [VALIDATOR.VALIDATE_PHONE_NUMBER],
    transformers: TRANSFORMERS.PHONE_NUMBER,
  },
  {
    title: 'Accounting method',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.accounting_method,
    validators: [VALIDATOR.NON_NULL_VALUE],
    options: BUSINESS_ACCOUNTING_METHOD_OPTIONS,
  },
  {
    title: 'Is registered with state',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.is_registered_with_state,
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
    options: BOOLEAN_OPTIONS,
  },
  {
    title: 'Is address same as home',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.is_address_same_as_home,
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
    options: BOOLEAN_OPTIONS,
  },
  {
    title: 'Home address (number & street)',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.number_and_street,
    ),
    validators: [VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'Apartment number',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(
      BusinessDetailsField.address,
      BusinessDetailsField.apartment_number,
    ),
  },
  {
    title: 'City',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(BusinessDetailsField.address, BusinessDetailsField.city),
    validators: [VALIDATOR.NON_EMPTY_STRING],
  },
  {
    title: 'State',
    inputType: FORM_INPUT_TYPE.State,
    path: getPath(BusinessDetailsField.address, BusinessDetailsField.state),
    isSingle: true,
    validators: [VALIDATOR.NON_NULL_VALUE],
    useAbbrevation: true,
  },
  {
    title: 'Zip code',
    inputType: FORM_INPUT_TYPE.String,
    path: getPath(BusinessDetailsField.address, BusinessDetailsField.zip_code),
    validators: [VALIDATOR.VALIDATE_ZIP_CODE],
    transformers: TRANSFORMERS.ZIP,
  },
  {
    inputType: FORM_INPUT_TYPE.Array,
    path: BusinessDetailsField.share_holders,
    title: 'Share holders',
    childTitle: 'Shareholder',
    childProps: [
      {
        inputType: FORM_INPUT_TYPE.String,
        title: 'Shareholder first name',
        path: BusinessDetailsField.first_name_and_middle_initial,
        validators: [VALIDATOR.NON_EMPTY_STRING],
      },
      {
        inputType: FORM_INPUT_TYPE.String,
        title: 'Shareholder last name',
        path: BusinessDetailsField.last_name,
        validators: [VALIDATOR.NON_EMPTY_STRING],
      },
      {
        inputType: FORM_INPUT_TYPE.String,
        title: 'Shareholder SSN',
        path: BusinessDetailsField.ssn,
        validators: [VALIDATOR.VALIDATE_SSN],
        transformers: TRANSFORMERS.SSN,
      },
      {
        inputType: FORM_INPUT_TYPE.Number,
        title: 'Ownership percentage',
        path: BusinessDetailsField.ownership_pct,
        validators: [VALIDATOR.NO_MORE_THAN_100],
      },
      {
        inputType: FORM_INPUT_TYPE.SingleSelect,
        title: 'Another individual act on behalf',
        path: BusinessDetailsField.is_another_individual_act_on_behalf,
        options: BOOLEAN_OPTIONS,
        validators: [],
      },
      {
        inputType: FORM_INPUT_TYPE.Number,
        title: 'Cash/property contributed to the business',
        path: BusinessDetailsField.property_contributed_to_business,
      },
      {
        inputType: FORM_INPUT_TYPE.Number,
        title: 'Cash/property taken out from the business',
        path: BusinessDetailsField.property_taken_out_of_business,
      },
      {
        title: 'Home address (number & street)',
        inputType: FORM_INPUT_TYPE.String,
        path: getPath(
          BusinessDetailsField.address,
          BusinessDetailsField.number_and_street,
        ),
        validators: [VALIDATOR.NON_EMPTY_STRING],
      },
      {
        title: 'Apartment number',
        inputType: FORM_INPUT_TYPE.String,
        path: getPath(
          BusinessDetailsField.address,
          BusinessDetailsField.apartment_number,
        ),
      },
      {
        title: 'City',
        inputType: FORM_INPUT_TYPE.String,
        path: getPath(BusinessDetailsField.address, BusinessDetailsField.city),
        validators: [VALIDATOR.NON_EMPTY_STRING],
      },
      {
        title: 'State',
        inputType: FORM_INPUT_TYPE.State,
        path: getPath(
          BusinessDetailsField.address,
          BusinessDetailsField.state,
        ),
        isSingle: true,
        validators: [VALIDATOR.NON_NULL_VALUE],
        useAbbrevation: true,
      },
      {
        title: 'Zip code',
        inputType: FORM_INPUT_TYPE.String,
        path: getPath(
          BusinessDetailsField.address,
          BusinessDetailsField.zip_code,
        ),
        validators: [VALIDATOR.VALIDATE_ZIP_CODE],
        transformers: TRANSFORMERS.ZIP,
      },
    ],
  },
  {
    title: 'Signing authority id',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.signing_authority_id,
    validators: [VALIDATOR.NON_NULL_VALUE],
    options: [],
  },
  {
    title: 'Is spouse business partner',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.is_spouse_business_partner,
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
    options: BOOLEAN_OPTIONS,
  },
  {
    title: 'Is work continuing',
    inputType: FORM_INPUT_TYPE.SingleSelect,
    path: BusinessDetailsField.is_work_continuing,
    validators: [VALIDATOR.VALIDATE_BOOLEAN],
    options: BOOLEAN_OPTIONS,
  },
  {
    title: 'Work stop date',
    inputType: FORM_INPUT_TYPE.Date,
    path: BusinessDetailsField.work_stop_date,
    validators: [VALIDATOR.NON_NULL_VALUE, VALIDATOR.NO_FUTURE_DATE],
  },
];
