import {GoogleSpreadsheet} from 'google-spreadsheet';
import {getEnvBasedConfig} from './utils';

const env = getEnvBasedConfig();
const SPREADSHEET_ID = env.REACT_APP_SPREADSHEET_ID;
const SHEET_ID = env.REACT_APP_SHEET_ID;
const CLIENT_EMAIL = env.REACT_APP_GOOGLE_SHEETS_CLIENT_EMAIL;
const PRIVATE_KEY = env.REACT_APP_GOOGLE_SHEETS_PRIVATE_KEY;

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export const appendSpreadsheet = async (row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
    console.log(result);
  } catch (e) {
    console.error('Error: ', e);
  }
};
