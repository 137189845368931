import {Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {postDocumentStatus} from 'src/appApi';
import {themmeColor, VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import Menu from 'src/DesignSystem/Menu/Menu';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import Tag from 'src/DesignSystem/Tag/Tag';
import BackIcon from 'src/icons/BackIcon';
import MoreIcon from 'src/icons/MoreIcon';
import Skeleton from 'src/icons/Skeleton';
import {setReuploadDocumentQueryDrawer} from 'src/store/app/app.actions';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {QuerySubType} from 'src/store/queries/queries.reducer';
import ViewDocumentContext from '../ViewDocument.context';
import DSButton from 'src/DesignSystem/Button/Button';
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {useNavigate} from 'react-router-dom';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const DocumentActions = () => {
  const dispatch = useDispatch();
  const {
    userId,
    document,
    documentsLoading,
    getNextDocument,
    getPreviousDocument,
  } = useContext(ViewDocumentContext);
  const {navigateToDocumentViewer, navigateToDocumentReview} =
    useCpaCenterV2Navigation();

  const {activeYear} = useActiveYear();

  const menuOptions = [
    QuerySubType.INCOMPLETE_DOC,
    QuerySubType.WRONG_DOC,
    QuerySubType.DOC_NOT_CLEAR,
    VALID_DOCUMENT_STATUS.IGNORED,
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const {DOCUMENTS_ACTION_BAR_HEIGHT} = useLayout();

  const {notify} = useNotify();

  const onOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSelectMenuOption = (selectedOption: string) => {
    if (selectedOption === VALID_DOCUMENT_STATUS.IGNORED) {
      setSelectedOption(selectedOption);
    } else if (
      [
        QuerySubType.INCOMPLETE_DOC,
        QuerySubType.WRONG_DOC,
        QuerySubType.DOC_NOT_CLEAR,
      ].includes(selectedOption)
    ) {
      dispatch(
        setReuploadDocumentQueryDrawer({
          isVisible: true,
          querySubType: selectedOption as QuerySubType,
          docId: document.docId,
          docTypeId: document.docTypeId,
        }),
      );
    }
  };

  const onClosePopup = () => {
    setSelectedOption(null);
  };

  const onIgnoreDocument = async () => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: document.docId,
        status: VALID_DOCUMENT_STATUS.IGNORED,
      });
      dispatch(fetchDocumentsForGivenYear(userId, activeYear));
      notify('Document ignored for tax-filing', NotificationType.success);
      onClosePopup();
    } catch (e) {
      notify(`Failed to ignore ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const onClickBack = () => {
    navigate(-1);
  };

  if (documentsLoading) {
    return (
      <div style={{display: 'flex', marginTop: 8, alignItems: 'center'}}>
        <Skeleton style={{marginRight: 20}} width={36} height={36} />
        <Skeleton style={{marginRight: 20}} width={100} height={20} />
        <Skeleton width={100} height={20} />
      </div>
    );
  }

  const nextDocument = getNextDocument(document.docId);
  const previousDocument = getPreviousDocument(document.docId);

  const isReviewComplete = [
    VALID_DOCUMENT_STATUS.ACCEPTED,
    VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA,
  ].includes(document.docStatus);

  return (
    <div
      style={{
        height: DOCUMENTS_ACTION_BAR_HEIGHT,
        display: 'flex',
        marginTop: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingInline: 12,
      }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          onClick={onClickBack}
          style={{
            backgroundColor: themmeColor.black,
            width: 36,
            height: 36,
            borderRadius: 100,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <BackIcon color={themmeColor.white} />
        </div>
        <div style={{display: 'flex', marginLeft: 12}}>
          <Tag fontSize={20} text={document.docType} />
        </div>
        <Typography style={{fontSize: 20, fontWeight: 800, marginLeft: 8}}>
          {document.filename}
        </Typography>
        {previousDocument && (
          <DSButton
            startIcon={<ArrowBackIos />}
            text={`${previousDocument.docType}`}
            type={'secondary'}
            style={{marginLeft: 8}}
            onClick={() => navigateToDocumentViewer(previousDocument.docId)}
          />
        )}
        {nextDocument && (
          <DSButton
            endIcon={<ArrowForwardIos />}
            text={`${nextDocument.docType}`}
            type={'secondary'}
            style={{marginLeft: 8}}
            onClick={() => navigateToDocumentViewer(nextDocument.docId)}
          />
        )}
      </div>
      <div
        onClick={isReviewComplete ? () => {} : onOpenMenu}
        style={{
          backgroundColor: isReviewComplete
            ? themmeColor.silver
            : themmeColor.black,
          width: 36,
          height: 36,
          borderRadius: 100,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <MoreIcon />
      </div>
      <Menu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        onClick={onSelectMenuOption}
        options={menuOptions}
      />
      <PopUp
        primaryButtonTitle="Confirm"
        primaryButtonOnClick={onIgnoreDocument}
        primaryButtonDisabled={loading}
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={onClosePopup}
        secondaryButtonDisabled={loading}
        isOpen={[VALID_DOCUMENT_STATUS.IGNORED].includes(selectedOption)}
        onClose={onClosePopup}>
        <h4>Are you sure, Ignore document for tax filing?</h4>
      </PopUp>
    </div>
  );
};

export default DocumentActions;
