import {useDispatch} from 'react-redux';
import useCurrentUserId from './useCurrentUserId';
import {
  addMessage,
  addThread,
  setThreadStatus,
  updateThreadUnreadMessages,
} from 'src/appApi';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import {fetchThreads} from 'src/store/threads/threads.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCurrentTaxReturnId from './useCurrentTaxReturnId';

export const useThreadsAction = () => {
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn} = useCurrentTaxReturnId();

  const refreshNotes = async () => {
    await dispatch(fetchThreads(userId, activeYear));
  };

  const createNewThread = async (comment: string) => {
    await addThread({
      comment,
      fly_user_id: userId,
      year: activeYear,
      biz_return_id: returnId,
      assigned_return_type: currentReturn.return_type,
      doc_id: currentReturn?.document_id,
    });
    await refreshNotes();
  };

  const postMessageInThread = async (thread_id: number, comment: string) => {
    await addMessage({thread_id, comment});
    await refreshNotes();
  };

  const updateThreadStatus = async (
    thread_id: number,
    status: ThreadStatus,
  ) => {
    await setThreadStatus({status}, {thread_id});
    await refreshNotes();
  };

  const updateUnreadMessages = async (
    thread_id: number,
    unread_messages: number[],
  ) => {
    await updateThreadUnreadMessages({unread_messages}, {thread_id});
    await refreshNotes();
  };

  return {
    refreshNotes,
    createNewThread,
    postMessageInThread,
    updateThreadStatus,
    updateUnreadMessages,
  };
};
