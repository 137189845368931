import {useNavigate} from 'react-router-dom';
import {PartnershipRoutes, RouteParts} from 'src/constants/routeName';

export const usePartnershipNavigation = () => {
  const navigate = useNavigate();

  const navigateToPartnersList = () => {
    navigate(PartnershipRoutes.PartnersList);
  };

  const navigateToTaxReturnsList = () => {
    navigate(PartnershipRoutes.UsersList);
  };

  const navigateToReturnDetails = (userId: number, year: number) => {
    navigate(
      PartnershipRoutes.User.replace(RouteParts.UserId, `${userId}`).replace(
        RouteParts.Year,
        `${year}`,
      ),
    );
  };

  return {
    navigateToPartnersList,
    navigateToTaxReturnsList,
    navigateToReturnDetails,
  };
};
