import {RouteName, UserInfoRoute} from 'src/constants/routeName';

export const getDocumentOcrPath = (params: {docTypeId?: number, docId?: number, userId: number | string}) => {
  const path = `${RouteName.TaxFiling}/${params.userId}/${UserInfoRoute.DocumentsOCR}`;
  if (params.docTypeId && params.docId) {
    return `${path}/${params.docTypeId}/${params.docId}`;
  }
  if (params.docTypeId) {
    return `${path}/${params.docTypeId}`;
  }
  return path;
}
