import React from 'react';
import {themmeColor} from 'src/constants/constants';

const DrawerWhiteContainer = ({children, style = {}}: any) => {
  return (
    <div
      style={{
        marginTop: 20,
        backgroundColor: themmeColor.white,
        display: 'flex',
        flex: 1,
        flexFlow: 'column',
        width: '50%',
        padding: 12,
        ...style,
      }}>
      {children}
    </div>
  );
};

export default DrawerWhiteContainer;
