import {useContext, useEffect, useState} from 'react';
import {Button, Typography} from '@mui/material';
import ConfirmDocumentUpload from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/UploadEsignDocuments/ConfirmDocumentUploadOverlay';
import FullScreenLoading from 'src/common/FullScreenLoading';
import ReviewAndEsignContext, {
  ReviewAndEsignDocument,
  ReviewEsignDocumentType,
} from '../ReviewAndEsignContext';
import {themmeColor} from 'src/constants/constants';
import {makeStyles} from '@mui/styles';
import SingleFileUploadSection from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/UploadEsignDocuments/SingleFileUpload/SingleFileUploadSection';
import {sendDocForCpaReview} from 'src/appApi';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import UserInfoContext from 'src/CpaCenterUserInfo/UserInfoContext';

const useStyles = makeStyles({
  mainContainer: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '87%',
    width: '100%',
    overflow: 'hidden',
  },
  uploadDocHeader: {
    padding: '10px',
    background: themmeColor.lightSkyBlue,
  },
  footer: {
    position: 'relative',
    height: '13%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    background: themmeColor.backgroundOffWhite,
    borderTop: '1px solid black',
  },
});

const UploadEsignDocuments = () => {
  const {
    documents,
    handleDocUpload,
    handleDocReUpload,
    fetchDocsData,
    fetchUserDetails,
  } = useContext(ReviewAndEsignContext);

  const {userInfo} = useContext(UserInfoContext);

  const [reviewDoc, setReviewDoc] = useState<ReviewAndEsignDocument | null>(
    null,
  );
  const [confirmOverlay, setConfirmOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (documents.length === 0) setReviewDoc(null);
    else
      setReviewDoc(
        documents.filter(
          (document) =>
            document.documentType === ReviewEsignDocumentType.TAX_RETURNS,
        )[0],
      );
  }, [documents]);

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const handleUpload = async (file: any) => {
    setLoading(true);
    await handleDocUpload(file);
    setLoading(false);
  };

  const handleReUpload = async (file: any) => {
    setLoading(true);
    await handleDocReUpload(file, reviewDoc?.documentId ?? -1);
    setLoading(false);
  };

  const sendForCpaReview = async () => {
    try {
      setLoading(true);
      const params = {
        tax_filing_id: userInfo.id,
      };
      const body = {
        [BACKEND_RESPONSE_KEYS.STATUS]: 5,
        states_return_summary: reviewDoc.state,
        federal_return_summary: reviewDoc.federal,
      };
      await sendDocForCpaReview(params, body);
      await fetchDocsData();
      await fetchUserDetails();

      setConfirmOverlay(false);
      setLoading(false);
    } catch (e) {
      alert(`Error Ocurred ${e}`);
      setConfirmOverlay(false);
      setLoading(false);
    }
  };

  const styles = useStyles({});

  return loading ? (
    <FullScreenLoading open={loading} />
  ) : (
    <>
      <div className={styles.uploadDocHeader}>
        <Typography fontWeight={'600'} fontSize={'16px'} fontStyle={'Inter'}>
          Tax Return
        </Typography>
      </div>
      <div style={{position: 'relative', height: '100%', width: '100%'}}>
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <SingleFileUploadSection
              reviewDoc={reviewDoc}
              setReviewDoc={setReviewDoc}
              handleDocUpload={handleUpload}
              handleDocReUpload={handleReUpload}
              loading={loading}
            />
          </div>
          {documents.length !== 0 ? (
            <div className={styles.footer}>
              <div style={{marginRight: '20px'}}>
                <Button
                  style={{
                    borderRadius: 8,
                    textTransform: 'none',
                  }}
                  onClick={openOverlay}
                  variant="contained">
                  Send for CPA Review
                </Button>
              </div>
            </div>
          ) : null}
          <ConfirmDocumentUpload
            isOpen={confirmOverlay}
            onClose={() => setConfirmOverlay(false)}
            filesNamesList={reviewDoc?.filename ? [reviewDoc?.filename] : []}
            onSend={sendForCpaReview}
            confirmationText="Confirm uploaded documents and send for CPA Review"
            sendButtonText="Send for CPA Review"
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default UploadEsignDocuments;
