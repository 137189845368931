import {getTransactions} from 'src/appApi';
import {SET_TRANSACTIONS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {Transaction} from './transactions.reducer';
import {parseSingleTransaction} from './transactions.utils';

const setTransactions = (payload: any) => {
  return {
    type: SET_TRANSACTIONS,
    payload,
  };
};

export const fetchTransactions = (userId: number, year: number) => {
  return async (dispatch: any) => {
    try {
      const response = await getTransactions({
        user_id: userId,
        year: [year],
      });

      const transactionIdMap: {[key: number]: Transaction} = {};
      const transactions: Transaction[] = response.data.map((backendTxn) => {
        const txn = parseSingleTransaction(backendTxn);
        transactionIdMap[txn.txnId] = txn;
        return txn;
      });
      dispatch(setTransactions({transactions, transactionIdMap}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch transactions (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
