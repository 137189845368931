import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {themmeColor, VALID_CPA_EMAILS} from 'src/constants/constants';
import {Button, Divider, Modal, Typography} from '@mui/material';
import {parse} from 'querystring';
import {BOI_FILTER_PARAMS, BOI_STATUS, SUBMIT_TYPE} from '../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tableData: any;
}

const PAYMENT_STATUS_FILTER_OPTIONS = ['ELIGIBLE', 'NOT_ELIGIBLE'];

const BOI_MODE_FILTER_OPTION = [SUBMIT_TYPE.CPA_QUERY, SUBMIT_TYPE.BOI_FORM];

const BOI_MODE_FITER_COPIES = {
  [SUBMIT_TYPE.CPA_QUERY]: 'Email',
  [SUBMIT_TYPE.BOI_FORM]: 'App',
};

const BoiListFilterModal = ({isOpen, onClose, tableData}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultSelectedPaymentStatus = searchParams.get(
    BOI_FILTER_PARAMS.PAYMENT_STATUS,
  );

  const defaultSelectedBoiStatus = searchParams.getAll(
    BOI_FILTER_PARAMS.STATUS,
  );
  const defaultSelectedReviewers = searchParams.getAll(
    BOI_FILTER_PARAMS.ASSIGNED_REVIEWER,
  );
  const defaultSelectedSubmitType = searchParams.getAll(
    BOI_FILTER_PARAMS.SUBMIT_TYPE,
  );

  const [filterStates, setFilterStates] = useState({
    [BOI_FILTER_PARAMS.STATUS]: defaultSelectedBoiStatus ?? [],
    [BOI_FILTER_PARAMS.ASSIGNED_REVIEWER]: defaultSelectedReviewers ?? [],
    [BOI_FILTER_PARAMS.SUBMIT_TYPE]: defaultSelectedSubmitType ?? [],
    [BOI_FILTER_PARAMS.PAYMENT_STATUS]: defaultSelectedPaymentStatus ?? '',
  });

  const handleSingleSelector = (key: BOI_FILTER_PARAMS, value: any) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMultiSelector = (key: BOI_FILTER_PARAMS, value: any) => {
    setFilterStates((prev) => ({
      ...prev,
      [key]: filterStates[key].includes(value)
        ? filterStates[key].filter((option) => option !== value)
        : [...filterStates[key], value],
    }));
  };

  const handleApply = () => {
    setSearchParams((prevParams) => ({
      ...parse(prevParams.toString()),
      [BOI_FILTER_PARAMS.STATUS]: filterStates[BOI_FILTER_PARAMS.STATUS],
      [BOI_FILTER_PARAMS.ASSIGNED_REVIEWER]:
        filterStates[BOI_FILTER_PARAMS.ASSIGNED_REVIEWER],
      [BOI_FILTER_PARAMS.SUBMIT_TYPE]:
        filterStates[BOI_FILTER_PARAMS.SUBMIT_TYPE],
      [BOI_FILTER_PARAMS.PAYMENT_STATUS]:
        filterStates[BOI_FILTER_PARAMS.PAYMENT_STATUS],
      pageCount: 1,
    }));
    onClose();
  };

  const BubbleButton = ({
    label,
    onClick,
    selected = false,
    spaceAround,
  }: {
    label: string;
    onClick: () => void;
    selected: boolean;
    spaceAround?: boolean;
  }) => {
    return (
      <Button
        variant={'outlined'}
        style={{
          borderRadius: 8,
          margin: spaceAround ? 5 : 0,
          textTransform: 'none',
          borderColor: selected ? themmeColor.darkBlue : themmeColor.grey,
          color: selected ? themmeColor.darkBlue : themmeColor.grey,
          borderWidth: selected ? 2 : 1,
          fontWeight: selected ? 'bold' : 'normal',
        }}
        onClick={onClick}>
        {label}
      </Button>
    );
  };

  return (
    <Modal
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      open={isOpen}
      onClose={onClose}>
      <div
        style={{
          padding: '20px',
          backgroundColor: themmeColor.white,
          width: '40%',
          overflow: 'scroll',
          maxHeight: '90vh',
        }}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography fontSize={'20px'} fontWeight={'bold'} padding={'10px'}>
            Filters
          </Typography>
        </div>
        <Divider style={{marginTop: '10px'}} />
        {/* Filters */}
        <div>
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Boi Status
            </Typography>
            <div style={{marginTop: '10px', gap: '10px'}}>
              {Object.values(BOI_STATUS).map((status) => (
                <BubbleButton
                  label={status}
                  onClick={() =>
                    handleMultiSelector(BOI_FILTER_PARAMS.STATUS, status)
                  }
                  selected={filterStates[BOI_FILTER_PARAMS.STATUS].includes(
                    status,
                  )}
                  spaceAround
                />
              ))}
            </div>
          </div>
          <Divider style={{marginTop: '10px'}} />
          <div style={{marginTop: '10px'}}>
            <Typography fontSize={'16px'} fontWeight={'600'}>
              Assigned Reviewer
            </Typography>
            <div style={{marginTop: '10px'}}>
              {Object.values(VALID_CPA_EMAILS).map((cpa) => (
                <BubbleButton
                  label={cpa}
                  onClick={() =>
                    handleMultiSelector(
                      BOI_FILTER_PARAMS.ASSIGNED_REVIEWER,
                      cpa,
                    )
                  }
                  selected={filterStates[
                    BOI_FILTER_PARAMS.ASSIGNED_REVIEWER
                  ].includes(cpa)}
                  spaceAround
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{marginTop: '10px'}}>
          <Typography fontSize={'16px'} fontWeight={'600'}>
            Boi Mode
          </Typography>
          <div style={{marginTop: '10px', gap: '10px'}}>
            {BOI_MODE_FILTER_OPTION.map((submitType) => (
              <BubbleButton
                label={BOI_MODE_FITER_COPIES[submitType] ?? ''}
                onClick={() =>
                  handleMultiSelector(
                    BOI_FILTER_PARAMS.SUBMIT_TYPE,
                    submitType,
                  )
                }
                selected={filterStates[BOI_FILTER_PARAMS.SUBMIT_TYPE].includes(
                  submitType,
                )}
                spaceAround
              />
            ))}
          </div>
        </div>
        <Divider style={{marginTop: '10px'}} />
        <div style={{marginTop: '10px'}}>
          <Typography fontSize={'16px'} fontWeight={'600'}>
            Boi Payment Status
          </Typography>
          <div style={{marginTop: '10px', gap: '10px'}}>
            {PAYMENT_STATUS_FILTER_OPTIONS.map((paymentStatus) => (
              <BubbleButton
                label={paymentStatus}
                onClick={() =>
                  handleSingleSelector(
                    BOI_FILTER_PARAMS.PAYMENT_STATUS,
                    paymentStatus,
                  )
                }
                selected={
                  filterStates[BOI_FILTER_PARAMS.PAYMENT_STATUS] ===
                  paymentStatus
                }
                spaceAround
              />
            ))}
          </div>
        </div>
        <Divider style={{marginTop: '10px'}} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}>
          <Button
            onClick={onClose}
            style={{color: themmeColor.errorRed, marginRight: 5}}
            variant="text">
            Cancel
          </Button>
          <Button
            style={{color: themmeColor.black}}
            onClick={handleApply}
            variant="text">
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BoiListFilterModal;
