import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {FONT_WEIGHTS, TEXT_TYPES} from './Text.types';
import {TEXT_TYPES_TO_PROPS} from './Text.constants';

const defaultTextStyles: React.CSSProperties = {
  color: themmeColor.black,
};

interface TextProps {
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  type?: TEXT_TYPES;
  text: string | number;
  color?: string;
  fontWeight?: FONT_WEIGHTS;
}

const Text = ({
  containerStyle,
  textStyle = defaultTextStyles,
  type = TEXT_TYPES.BASE,
  text,
  color = undefined,
  fontWeight = FONT_WEIGHTS.Regular,
}: TextProps) => {
  const commonTextStyles = TEXT_TYPES_TO_PROPS[type];
  return (
    <div style={containerStyle}>
      <div
        style={{
          ...commonTextStyles,
          ...textStyle,
          color,
          fontWeight,
        }}>
        {text}
      </div>
    </div>
  );
};

export default Object.assign(Text, {TEXT_TYPES, FONT_WEIGHTS});
