import React from 'react';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const Partnership = () => {
  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  return (
    <div>
      <h1>
        Partnership user tax return details for user_id: {userId} Year:{' '}
        {activeYear}
      </h1>
    </div>
  );
};

export default Partnership;
