import {useRef, useState} from 'react';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {
  EsignFieldLocation,
  EsignFieldType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const useOcrBoundingBox = () => {
  const canvasPDFRef = useRef({});
  const [numPages, setNumPages] = useState(null);
  const {notify} = useNotify();

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const drawBoundingBox = (fields: EsignFieldLocation[]) => {
    if (fields) {
      try {
        fields.forEach((BBData) => {
          if (BBData.applicable) {
            const canvas = canvasPDFRef.current[BBData.page_number + 1];
            const canvasContext = canvas?.getContext('2d');
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            canvasContext.strokeStyle =
              BBData.field_type === EsignFieldType.DATE ? 'blue' : 'red';
            canvasContext.lineWidth = 5;
            canvasContext?.strokeRect(
              BBData.x * canvasWidth,
              BBData.y * canvasHeight,
              BBData.width * canvasWidth,
              BBData.height * canvasHeight,
            );
          }
        });
      } catch (e) {
        notify(`Closed before rendering ${e}`, NotificationType.warning);
      }
    }
  };

  return {
    canvasPDFRef,
    onDocumentLoadSuccess,
    numPages,
    drawBoundingBox,
  };
};
