import {Autocomplete, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {TF_SEGMENT} from 'src/store/user/user.reducer';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentSegment: TF_SEGMENT | null;
  loading: boolean;
  onUpdateSegment: (extensionStatus: TF_SEGMENT) => void;
}

const UpdateTFSegmentPopup = ({
  isOpen,
  onClose,
  currentSegment,
  loading,
  onUpdateSegment,
}: Props) => {
  const [selectedSegment, setSelectedSegment] = useState(currentSegment);

  const handleOnChange = (e: any, v: any) => {
    setSelectedSegment(v);
  };

  return (
    <PopUp
      primaryButtonTitle="Yes, Change it"
      primaryButtonOnClick={() => onUpdateSegment(selectedSegment)}
      primaryButtonDisabled={selectedSegment == null || loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 540}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography fontSize={20} fontWeight="600">
        Change tax filing segment
      </Typography>
      <Autocomplete
        onChange={handleOnChange}
        disablePortal
        id="combo-box-demo"
        options={Object.values(TF_SEGMENT)}
        value={selectedSegment}
        fullWidth
        style={{marginTop: 16}}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="TF segment" />}
      />
    </PopUp>
  );
};

export default UpdateTFSegmentPopup;
