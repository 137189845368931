import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import {themmeColor} from 'src/constants/constants';
import {Button, TextField, Typography} from '@mui/material';
import {updateCCHClientId} from 'src/appApi';

interface UpdateCCHClientIdOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  currentCCHClientId: string;
  flyUserId: number;
  onSuccess: () => void | Promise<void>;
}

const UpdateCCHClientIdOverlay = ({
  isVisible,
  onClose,
  currentCCHClientId,
  flyUserId,
  onSuccess,
}: UpdateCCHClientIdOverlayProps) => {

  const [activeClientId, setActiveClientId] = useState(currentCCHClientId);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const stopDrilling = (e: any) => {
    e?.stopPropgation?.();
    e?.preventDefault?.();
  };

  const handleOnChange = (v: any) => {
    setActiveClientId(v);
  };

  const handleIdUpdate = async () => {
    // TODO: Add status update implementation
    setLoading(true);
    try {
      await updateCCHClientId(
        flyUserId,
        activeClientId,
      );
      await onSuccess();
    } catch {
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={stopDrilling}>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={isVisible}
        onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 540,
            padding: 24,
            borderRadius: 16,
            backgroundColor: themmeColor.white,
          }}>
          <Typography fontSize={20} fontWeight="600">
            Change CCH Client Id
          </Typography>
          <TextField
            label="CCH Client ID"
            style={{marginTop: 16}}
            value={activeClientId}
            type="number"
            onChange={(e) => handleOnChange(e.target.value)}
            disabled={loading}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              marginTop: 24,
            }}>
            <Button
              disabled={loading}
              onClick={handleClose}
              style={{flex: 0.45, height: 48}}
              variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleIdUpdate}
              disabled={
                !activeClientId ||
                currentCCHClientId === activeClientId ||
                loading
              }
              style={{flex: 0.45, height: 48}}
              variant="contained">
              Yes, Change it
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateCCHClientIdOverlay;
